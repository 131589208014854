import { AttachedIcon } from "Assets";
import { useToolTip } from "Hooks/useToolTip";

interface AttachmentReferenceProps {
  isReferenced: boolean;
  tooltipText?: string;
  fileId?: string;
}

export const AttachmentReference = ({ isReferenced, tooltipText }: AttachmentReferenceProps) => {
  const { makeToolTipDataAttr } = useToolTip();
  return (
    <span className="self-start h-2 w-2">
      {isReferenced && (
        <AttachedIcon {...(tooltipText ? makeToolTipDataAttr({ text: tooltipText }) : {})} />
      )}
    </span>
  );
};
