import { QueryClient, QueryClientConfig } from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { authenticationClient, eolasLogger, userStore } from "@eolas-medical/core";

export const refreshIfAuthError = (error: unknown) => {
  if (isAxiosError(error)) {
    const status = error.response?.status ?? 0;
    if ([401, 403].includes(status) && userStore.userSession.isLoggedIn) {
      authenticationClient.getToken({ forceRefresh: true });
    }
  }
};

export const defaultReactQueryRetryCallback = (failureCount: number, error: unknown) => {
  if (isAxiosError(error) && error.response?.status === 504) {
    return false;
  }
  if (!authenticationClient.isAuthenticated) {
    return false;
  }
  return failureCount < 3;
};

export const defaultReactQueryOnError = (error: unknown) => {
  refreshIfAuthError(error);
  eolasLogger.error(error);
  return error;
};

const reactQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: defaultReactQueryRetryCallback,
      onError: defaultReactQueryOnError,
    },
    mutations: {
      onError: defaultReactQueryOnError,
    },
  },
};

export const queryClient = new QueryClient(reactQueryConfig);
