/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm, ValidationMode } from "react-hook-form";

export interface FormConfig {
  mode?: keyof ValidationMode;
  reValidateMode?: "onBlur" | "onChange" | "onSubmit";
  validationSchema: any;
  defaultValues?: any;
}

export const useEolasForm = <T extends FieldValues>({
  validationSchema,
  defaultValues,
  reValidateMode,
  mode,
}: FormConfig) => {
  const methods = useForm<T>({
    mode: mode || "onBlur",
    reValidateMode: reValidateMode || "onChange",
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  return methods;
};
