import { InfoIconMonocolored } from "Assets";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { WarningModal } from "./WarningModal";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents, AskEolasWarningPillPayload } from "@eolas-medical/core";
import { AskEolasVariants } from "Components/AskEolas/AskEolasHome";

export type WarningPillProps = {
  variant: "general" | "webFallback";
  type: AskEolasVariants;
  questionID: string;
};
export const WarningPill = ({ variant, type, questionID }: WarningPillProps) => {
  const { t } = useTranslation();

  const isWebVariant = variant === "webFallback";
  const handleClick = () => {
    modalStore.openModal({
      name: "warningModal",
      variant: "component",
      Component: <WarningModal variant={variant} />,
    });
    const eventMap = {
      knowledge: AnalyticsEvents.CLINICAL_QUESTION_WARNING_PILL_CLICKED,
      space: AnalyticsEvents.ASK_EOLAS_SPACES_WARNING_PILL_CLICKED,
    };
    trackEvent<AskEolasWarningPillPayload>(eventMap[type], { questionID, variant });
  };

  return (
    <button
      className={`flex border gap-2 rounded-lg items-center p-4 h-10 mb-2 ${
        isWebVariant ? "border-yellow-500 bg-yellow-100" : "border-red-300 bg-red-100"
      }`}
      onClick={handleClick}
    >
      <div className="h-5 w-5">
        <InfoIconMonocolored />
      </div>
      {t(isWebVariant ? "ecq_webfallback_pill" : "ecq_general_pill")}
    </button>
  );
};
