import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, Pill, Tag } from "UIKit";
import { Sizes } from "UIKit/types";
import { useTranslation } from "react-i18next";

export interface FeatureGuideData {
  backgroundImg: string;
  animation?: React.ReactNode;
  title: string;
  description: string;
  shouldShowBetaTag?: boolean;
}
export const FeatureGuideModal = ({
  backgroundImg,
  title,
  description,
  animation,
  shouldShowBetaTag,
}: FeatureGuideData) => {
  const { t } = useTranslation();
  const closeModal = () => {
    modalStore.closeModal();
  };
  return (
    <InnerModalWrapper className="xl:w-50vw h-full min-h-90vh 2xl:min-h-50vh max-w-screen-lg relative">
      <div className="flex-1 flex">
        <img
          src={backgroundImg}
          alt=""
          className="w-full flex-1 object-fit"
          style={{
            borderBottomLeftRadius: "50% 15%",
            borderBottomRightRadius: "50% 15%",
          }}
        />
        {animation}
      </div>

      <div className="flex-1 flex flex-col justify-center items-center overflow-auto mb-4 px-16">
        <Pill
          value={t("general_feature_guide")}
          className="text-xs bg-blue-50 text-blue-500 font-bold px-4"
          size={Sizes.SMALL}
        />
        <div className="text-center flex font-bold text-xl lg:text-2xl my-4 gap-1 flex-col sm:flex-row items-center sm:items-start">
          {title}

          {shouldShowBetaTag ? (
            <Tag className="bg-green-500 text-white" size="sm" label={t("general_beta")} />
          ) : null}
        </div>

        <div className="text-md lg:text-lg font-semibold text-center">{description}</div>
        <Button
          size="sm"
          color="blue"
          variant="solid"
          onClick={closeModal}
          className="w-full sm:w-1/4 self-center mt-4 sm:mt-8"
          data-testid="delete-modal-button"
        >
          {t("whats_new_button")}
        </Button>
      </div>
    </InnerModalWrapper>
  );
};
