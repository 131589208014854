import { validate as uuidValidate } from "uuid";
import { ContentItem, EolasFile, hasStringProp } from "@eolas-medical/core";
import { ContentSection, MedicationHighlightFlashcard } from "./types";

export const isContentSection = (object: unknown): object is ContentSection => {
  return (
    hasStringProp(object, "routeId") &&
    hasStringProp(object, "iconUrl") &&
    hasStringProp(object, "iconName")
  );
};

export const isEolasFile = (object: unknown): object is EolasFile => {
  return (
    hasStringProp(object, "id") &&
    hasStringProp(object, "ownerID") &&
    hasStringProp(object, "parentID") &&
    hasStringProp(object, "mainSectionID") &&
    hasStringProp(object, "sharedID") &&
    hasStringProp(object, "deleted") &&
    hasStringProp(object, "createdAt") &&
    hasStringProp(object, "updatedAt") &&
    hasStringProp(object, "name")
  );
};

/**
 * ! This typeguard will not work for EolasFiles that have been mapped to a contentItem, as it checks that ID is a valid UUID
 * use isMappedContentItem instead
 */
export const isContentItem = (object: unknown): object is ContentItem => {
  return (
    hasStringProp(object, "id") &&
    hasStringProp(object, "ownerId") &&
    hasStringProp(object, "parentId") &&
    hasStringProp(object, "mainSectionId") &&
    hasStringProp(object, "name") &&
    uuidValidate(object.id)
  );
};

/**
 * Web will sometimes map EolasFiles to ContentItems, which will have a different shape than the EolasFile type but will not have a valid UUID for the ID
 */
export const isMappedContentItem = (object: unknown): object is ContentItem => {
  return (
    hasStringProp(object, "id") &&
    hasStringProp(object, "ownerId") &&
    hasStringProp(object, "parentId") &&
    hasStringProp(object, "mainSectionId") &&
    hasStringProp(object, "name")
  );
};

export const isMedicationHighlight = (object: unknown): object is MedicationHighlightFlashcard =>
  isMappedContentItem(object) && object.isSponsored === true;
