import {
  DraftFile,
  EolasFile,
  FileExtensionMediaType,
  getObjectEntries,
} from "@eolas-medical/core";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { observer } from "mobx-react-lite";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import { isVideoType } from "Utilities/fileHelpers";
import VideoViewer from "./components/VideoViewer/VideoViewer";
import { useContentVersion } from "shared/hooks/useContentVersion/useContentVersion";
import { Loader } from "UIKit";
import { FileViewerUnauthorizedError } from "./components/FileViewerUnauthorizedError/FileViewerUnauthorizedError";
import { useGetDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useGetDraftFile";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { ContentItemParams } from "shared/pages/ContentRepository/context/types";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";

export const FileViewer = observer(() => {
  const { fileId, versionNo } = useParamsDecoded<{ fileId: string; versionNo?: string }>();
  const { fileIdOfDraftFile, shadowCopyContentId, shouldShowBreadcrumbs } = useGetSearchParams({
    paramList: getObjectEntries(ContentItemParams).map(([, v]) => v),
  });
  const { data, isFetching } = useGetDraftFile(fileIdOfDraftFile);

  if (versionNo) {
    return (
      <FileVersionViewer
        fileId={fileId}
        versionNo={+versionNo}
        fileIdOfDraftFile={fileIdOfDraftFile}
      />
    );
  }

  const { file: eolasFile } = useGetFile({ id: fileId });

  const file: EolasFile | DraftFile | null = data?.draftFile ?? eolasFile;

  if (isFetching || !file) {
    return <Loader className="h-100vh" />;
  }

  return (
    <FileViewerInternal
      file={file}
      shadowCopyContentId={shadowCopyContentId || undefined}
      fileIdOfDraftFile={fileIdOfDraftFile}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs === "true"}
    />
  );
});

export const FileVersionViewer = ({
  fileId,
  versionNo,
  shadowCopyContentId,
  fileIdOfDraftFile,
  shouldShowBreadcrumbs,
}: {
  fileId: string;
  versionNo: number;
  shadowCopyContentId?: string;
  fileIdOfDraftFile: string | null;
  shouldShowBreadcrumbs?: boolean;
}) => {
  const { fileVersion, isLoadingContentVersions, isUserAllowed } = useContentVersion({
    contentId: fileId,
    versionNo,
  });

  if (isLoadingContentVersions) return <Loader className="h-100vh" />;

  if (!isUserAllowed) return <FileViewerUnauthorizedError />;

  return (
    <FileViewerInternal
      file={{
        ...fileVersion,
        id: fileId,
      }}
      versionNo={versionNo}
      shadowCopyContentId={shadowCopyContentId}
      fileIdOfDraftFile={fileIdOfDraftFile}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
    />
  );
};

const FileViewerInternal = ({
  file,
  versionNo,
  shadowCopyContentId,
  fileIdOfDraftFile,
  shouldShowBreadcrumbs,
}: {
  file: EolasFile | DraftFile;
  versionNo?: number;
  shadowCopyContentId?: string;
  fileIdOfDraftFile: string | null;
  shouldShowBreadcrumbs?: boolean;
}) => {
  if (isVideoType(file.type as FileExtensionMediaType)) {
    return <VideoViewer video={file} versionNo={versionNo} />;
  }

  return (
    <DocumentViewer
      file={file}
      versionNo={versionNo}
      shadowCopyContentId={shadowCopyContentId}
      originalFileId={fileIdOfDraftFile}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
    />
  );
};
