import { eolasLogger, hasProp, userStore } from "@eolas-medical/core";
import {
  AccessRequestStatus,
  ShouldAllowUserAccessResponse,
} from "@eolas-medical/core/lib/src/clients/access/access.types";
import { useQueryClient } from "@tanstack/react-query";
import { useCheckOrgAccess } from "Hooks/useCheckOrgAccess";
import { useCheckSpaceAccess } from "Hooks/useCheckSpaceAccess";
import { useIdentifyOwnerId } from "Hooks/useIdentifyOwnerId";
import { useSignoutSpace } from "Hooks/useSignoutSpace";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";
import { EditEmailsModal } from "Pages/MeTab/pages/MyProfile/components";
import { RequestAccessModal } from "Pages/Spaces/components/RequestAccessModal/RequestAccessModal";
import { useCallback, useMemo, useState } from "react";
import { modalStore } from "Stores/ModalStore";

type Props = {
  ownerId: string;
  onAccessGranted: (spaceId: string) => void;
};

type RenderCondition =
  | "loading"
  | "no-access"
  | "access-pending"
  | "requires-trusted-email"
  | "error"
  | "continue";

export const useLinkedDocumentsNoAccess = ({ ownerId, onAccessGranted }: Props) => {
  const [didRequestAccess, setDidRequestAccess] = useState(false);
  const { signoutSpace } = useSignoutSpace({ shouldPushNewRoute: false });
  const [idForAccess, setIdForAccess] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const isInSpaceOrg = userStore.userSession.isInOrganisation;

  const onShouldAllowAccessSuccess = useCallback(
    async (data: ShouldAllowUserAccessResponse) => {
      // If the user is granted access, we should run a sync to ensure the web app data is up to date
      const { status, data: accessData } = data;
      if (
        status === AccessRequestStatus.APP_USER_CREATED ||
        status === AccessRequestStatus.EXISTING_APP_USER ||
        status === AccessRequestStatus.PUBLIC_ACCESS_GRANTED ||
        status === AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED
      ) {
        if (!isInSpaceOrg) {
          await queryClient.invalidateQueries(spacesKeys.list());
          onAccessGranted(accessData.app.id);
        } else {
          setIdForAccess(accessData.app.id);
        }
      }
    },
    [onAccessGranted, queryClient, isInSpaceOrg],
  );

  const {
    data: identifyOwnerIdRes,
    isLoading: isIdentifyingOwnerId,
    isError: didErrorIdentifyingOwnerId,
  } = useIdentifyOwnerId({
    ownerId,
  });

  const {
    isFetching: isCheckingSpaceAccess,
    data: checkSpaceAccessRes,
    isError: isCheckingSpaceAccessError,
    refetch: refetchCheckSpaceAccess,
  } = useCheckSpaceAccess({
    spaceId: ownerId,
    isEnabled: !isIdentifyingOwnerId && identifyOwnerIdRes?.type === "space",
    onSuccess: onShouldAllowAccessSuccess,
  });

  const {
    isFetching: isCheckingOrgAccess,
    data: checkOrgAccessRes,
    isError: isCheckingOrgAccessError,
    refetch: refetchCheckOrgAccess,
  } = useCheckOrgAccess({
    organisationId: ownerId,
    isEnabled: !isIdentifyingOwnerId && identifyOwnerIdRes?.type === "organisation",
    onSuccess: onShouldAllowAccessSuccess,
  });

  const refetchAccessChecks = () => {
    if (!identifyOwnerIdRes) {
      return;
    }

    if (identifyOwnerIdRes?.type === "space") {
      refetchCheckSpaceAccess();
    }

    if (identifyOwnerIdRes?.type === "organisation") {
      refetchCheckOrgAccess();
    }
  };

  const handleCloseAddEmailModal = (didAddEmail?: boolean) => {
    if (didAddEmail) {
      refetchAccessChecks();
    }
    modalStore.closeModal();
  };

  const handleManualSwitchSpace = async () => {
    if (!idForAccess) {
      eolasLogger.error(new Error("No idForAccess found when trying to switch space"));
      return;
    }

    await signoutSpace();
    await queryClient.invalidateQueries(spacesKeys.list());
    onAccessGranted(idForAccess);
  };

  const handleAddAnotherEmail = () => {
    modalStore.openModal({
      name: "addAnotherEmail",
      Component: (
        <EditEmailsModal
          closeModal={handleCloseAddEmailModal}
          shouldCloseOnComplete
          initialShowAddAnotherEmailInput
        />
      ),
      variant: "component",
    });
  };

  const handleOpenAccessRequestModal = (spaceOrgName: string) => {
    let spaceId = ownerId;

    // If the checkOrgAccessRes has limitedData, it means the user has no access to the org and we should use the admin spaceId
    if (hasProp(checkOrgAccessRes, "limitedData")) {
      spaceId = checkOrgAccessRes.limitedData.departmentId;
    }

    modalStore.openModal({
      name: "requestAccessModal",
      Component: (
        <RequestAccessModal
          spaceId={spaceId}
          spaceOrgName={spaceOrgName}
          onSuccessfulRequest={() => setDidRequestAccess(true)}
        />
      ),
      variant: "component",
    });
  };

  const renderCondition: RenderCondition = useMemo(() => {
    if (didRequestAccess) {
      return "access-pending";
    }

    if (isIdentifyingOwnerId || isCheckingSpaceAccess || isCheckingOrgAccess) {
      return "loading";
    }

    if (
      didErrorIdentifyingOwnerId ||
      !identifyOwnerIdRes ||
      isCheckingSpaceAccessError ||
      isCheckingOrgAccessError
    ) {
      return "error";
    }

    const accessStatus = checkSpaceAccessRes?.status || checkOrgAccessRes?.status;

    if (!accessStatus) {
      return "error";
    }

    if (accessStatus === AccessRequestStatus.ACCESS_REQUESTED) {
      return "access-pending";
    }

    if (accessStatus === AccessRequestStatus.NO_APP_ACCESS) {
      return "no-access";
    }

    if (accessStatus === AccessRequestStatus.NO_TRUSTED_EMAIL) {
      return "requires-trusted-email";
    }

    if (
      accessStatus === AccessRequestStatus.APP_USER_CREATED ||
      accessStatus === AccessRequestStatus.EXISTING_APP_USER ||
      accessStatus === AccessRequestStatus.PUBLIC_ACCESS_GRANTED ||
      accessStatus === AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED
    ) {
      return "continue";
    }

    return "error";
  }, [
    isIdentifyingOwnerId,
    didErrorIdentifyingOwnerId,
    identifyOwnerIdRes,
    isCheckingSpaceAccess,
    checkSpaceAccessRes,
    isCheckingOrgAccess,
    checkOrgAccessRes,
    isCheckingSpaceAccessError,
    isCheckingOrgAccessError,
    didRequestAccess,
  ]);

  return {
    ownerId,
    renderCondition,
    ownerName: identifyOwnerIdRes?.name,
    ownerType: identifyOwnerIdRes?.type,
    handleOpenAccessRequestModal,
    handleAddAnotherEmail,
    isInSpaceOrg,
    handleManualSwitchSpace,
  };
};
