import { useLocalSearch } from "shared/hooks/useLocalSearch";
import {
  AdditionalClickSearchProps,
  AdditionalDebounceSearchProps,
  DragResult,
  SearchSortList,
  SearchSortListItem,
  SortFn,
} from "../SearchSortList";
import { useCallback, useMemo, useState } from "react";
import { ListOrderByOption } from "Components/EolasFileList/ListOrderBy";
import { useLocalClickSearch } from "shared/hooks/useLocalSearch/useLocalClickSearch";
import { OnSearchAnalytics } from "shared/hooks/useLocalSearch/types";
import { FuseSearchablePropertiesParam } from "shared/hooks/useLocalSearch/fuseSearch";

type LocalItemsListProps<T extends object> = LocalItemsListBaseProps<T> &
  (AdditionalClickSearchProps | AdditionalDebounceSearchProps);

type LocalItemsListBaseProps<T extends object> = {
  items: T[];
  defaultSort?: ListOrderByOption;
  renderItem: (item: T) => React.ReactNode;
  keysToSearch?: FuseSearchablePropertiesParam<T>;
  isLoading?: boolean;
  isSortable?: boolean;
  isDraggable?: boolean;
  hasFavourites?: boolean;
  isSearchable?: boolean;
  onDragItem?: ({ newPrevItem, reorderedItem, newNextItem, dropResult }: DragResult<T>) => void;
  onSearchAnalytics?: OnSearchAnalytics<T>;
};

const emptyArr: never[] = [];

export const LocalItemsList = <T extends SearchSortListItem>({
  items,
  defaultSort,
  isLoading = false,
  keysToSearch = [],
  isSortable = false,
  isDraggable = false,
  hasFavourites = false,
  renderItem,
  onDragItem,
  isSearchable = true,
  ...rest
}: LocalItemsListProps<T>) => {
  const isClickSearch = rest.searchType === "click";

  const debouncedPayload = useLocalSearch<T>({
    data: isClickSearch ? emptyArr : items,
    keysToSearch,
  });

  const clickPayload = useLocalClickSearch<T>({
    data: isClickSearch ? items : emptyArr,
    keysToSearch,
    ...rest,
  });

  const searchInput = isClickSearch ? clickPayload.searchInput : debouncedPayload.searchInput;

  const searchResult = isClickSearch ? clickPayload.searchResult : debouncedPayload.searchResult;

  const shouldShowSearchResults =
    (isClickSearch && clickPayload.isSearchClicked) || (!isClickSearch && searchInput);

  const onSetSearchInput = isClickSearch
    ? clickPayload.onSetSearchInput
    : debouncedPayload.onSetSearchInput;

  const [sortMethod, setSortMethod] = useState<SortFn>();

  const itemsForDisplay = useMemo(() => {
    if (shouldShowSearchResults) {
      return searchResult;
    }
    return items.slice().sort(sortMethod);
  }, [sortMethod, searchResult, items, shouldShowSearchResults]);

  const handleRenderItem = useCallback(
    (item: T) => {
      return renderItem(item);
    },
    [renderItem],
  );

  const handleDragItem = useCallback(
    (dragResult: DragResult<T>) => {
      if (onDragItem && isDraggable) {
        onDragItem(dragResult);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDragItem],
  );

  return (
    <SearchSortList<T>
      defaultSort={defaultSort}
      items={itemsForDisplay}
      isSearchable={isSearchable}
      renderItem={handleRenderItem}
      onSearchInputChange={onSetSearchInput}
      onSortMethodChange={(sortMethod?: SortFn) => setSortMethod(() => sortMethod)}
      isSortable={isSortable}
      hasFavourites={hasFavourites}
      isLoading={isLoading}
      isDragable={searchInput?.length === 0 && isDraggable}
      onDragEnd={handleDragItem}
      value={isClickSearch ? clickPayload.searchInput : undefined}
      {...(rest.searchType === "click"
        ? {
            searchType: "click",
            onClickSearch: clickPayload.onClickSearch,
            onClearSearch: clickPayload.onClearSearch,
          }
        : { searchType: "debounced" })}
    />
  );
};
