import { MyFile } from "modules/myFiles/types";
import { SuccessModal, Title } from "UIKit";
import { InnerModalWrapper, ModalHeader, ModalBody } from "../components";
import { BlobForm } from "./components/BlobForm";
import { LinkForm } from "./components/LinkForm";
import { PatientInfoDisclaimer } from "UIKit/PatientInfoDisclaimer";

interface FileModalProps {
  onCloseModal(): void;
  onSubmit(args: { name: string; url?: string }): void;
  isLoading: boolean;
  existingFileNames: string[];
  file: MyFile;
  title?: string;
  errorMessage?: string;
  isSuccessful?: boolean;
}

export const EditFileModal = ({
  isLoading,
  isSuccessful,
  existingFileNames,
  errorMessage = "",
  title = "",
  file,
  onCloseModal,
  onSubmit,
}: FileModalProps) => {
  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text={"general_add_file_modal_success"} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{title}</Title>
      </ModalHeader>

      <PatientInfoDisclaimer />

      <ModalBody className="add-file__modal-body">
        {file.type === "LINK" && file.link ? (
          <LinkForm
            onSubmit={onSubmit}
            onBack={onCloseModal}
            isLoading={isLoading}
            existingFileNames={existingFileNames}
            errorMessage={errorMessage}
            url={file.link.url}
            name={file.name}
          />
        ) : null}

        {file.type === "BLOB" ? (
          <BlobForm
            initialName={file.name}
            isEdit
            currentStep={1}
            onSubmit={(name) => onSubmit({ name })}
            onBack={onCloseModal}
            isLoading={isLoading}
            existingFileNames={existingFileNames}
            errorMessage={errorMessage}
          />
        ) : null}
      </ModalBody>
    </InnerModalWrapper>
  );
};
