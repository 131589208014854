import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import envConfig from "../env-config";
import { eolasLogger } from "@eolas-medical/core";

export const apiErrorsToIgnore = [
  "File already seen!",
  "An account with the given email already exists.",
  "Bad verification code!",
];

// For the rest errors, the name of the property should be a regex
export const restErrorsToIgnore: Record<string, number[]> = {
  "content-repository/v1/content/.*/complete": [409],
  "national-resources/v1/documents/.*": [404],
};

Sentry.init({
  dsn: envConfig.REACT_APP_SENTRY_DSN,
  enabled: envConfig.REACT_APP_SENTRY_ENABLED,
  integrations: [new Integrations.BrowserTracing()],
  release: envConfig.REACT_APP_VERSION,
  environment: envConfig.REACT_APP_APP_ENV,
  tracesSampleRate: envConfig.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
});

Sentry.addBreadcrumb({
  category: "Sentry",
  message: "Initialised",
});

Sentry.setTag("app-version", envConfig.REACT_APP_VERSION);

eolasLogger.init({
  errorsToIgnore: apiErrorsToIgnore,
  restErrorsToIgnore,
  onSentryException: (exception, data) => {
    Sentry.captureException(exception, { extra: data });
  },
  onSentryWarning: (message, data) => {
    Sentry.captureMessage(message, { extra: data, level: Sentry.Severity.Warning });
  },
  onSentryBreadCrumb: (message, data) => {
    Sentry.addBreadcrumb({
      category: "Bread crumb",
      message: message,
      data,
    });
  },
});

export { Sentry };
