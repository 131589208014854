import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { MedicationSummaryItem, MonographType } from "@eolas-medical/core";
import { InnerPageWrapper, Modal } from "UIKit";
import { LocalItemsList } from "shared/components/Lists";
import BnfSectionTile from "../../../components/BnfSectionTile/BnfSectionTile";
import useGetMedicationList from "modules/bnf/data/useGetMedicationList";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { makeBnfRoute } from "../../../routes/BnfRoutes";
import { titleLookup } from "./DrugsList.strings";
import { isSupportedBNFMedicationType } from "deeplinking/functions/typeguards";
import { useShareFile } from "shared/hooks/useShareFile";
import useMedicationsMainSection from "modules/medications/data/useMedicationsMainSection";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useBnfSearchAnalytics } from "../../../hooks/useBnfSearchAnalytics";

const DrugsList = ({ type }: { type: MonographType }) => {
  const { push } = useHistory();
  const { isLoading, summaryList } = useGetMedicationList({ type });
  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();
  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();
  const onBnfSearch = useBnfSearchAnalytics(
    type === "bnf" ? "adultMedicationsBnf" : "paediatricMedicationsBnfc",
  );
  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);
  const medicationSection = useMedicationsMainSection();

  const handleOnClickMedication = useCallback(
    (item: MedicationSummaryItem) => {
      if (!type) {
        return;
      }
      push(
        makeBnfRoute({
          routeKey: type,
          idOrSid: item.id,
          title: item.name,
        }),
      );
    },
    [push, type],
  );

  const renderMedication = useCallback(
    (item: MedicationSummaryItem) => {
      const canShare = isShareFileEnabled && isSupportedBNFMedicationType(item.type);
      return (
        <BnfSectionTile
          item={item}
          onClick={() => handleOnClickMedication(item)}
          onShare={
            canShare
              ? () => handleOnShareFile(item, { mainSectionIdOverride: medicationSection?.id })
              : undefined
          }
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      );
    },
    [
      handleOnClickMedication,
      handleOnShareFile,
      isShareFileEnabled,
      medicationSection,
      showRemoveFavouriteModal,
    ],
  );

  return (
    <InnerPageWrapper>
      <Modal open={isModalOpen} onClose={onCloseModal}>
        {shareModal || removeFavouriteModal}
      </Modal>
      <BnfHeader type={type} title={titleLookup[type]} goBackBehaviourPreference="removeFromPath" />
      <LocalItemsList<MedicationSummaryItem>
        items={summaryList}
        isLoading={isLoading}
        keysToSearch={["name", "keywords"]}
        renderItem={renderMedication}
        searchType="click"
        onSearchAnalytics={onBnfSearch}
      />
    </InnerPageWrapper>
  );
};

export const DrugsListAdult = () => <DrugsList type="bnf" />;
export const DrugsListChild = () => <DrugsList type="bnfc" />;
