import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { contentClient, EolasFile } from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications } from "Components/Notifications";

import { useFileOperations } from "./useFileOperations";
import { useRefetchAppData } from "./useAppSync";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

/**
 *
 * @deprecated This feature should be removed
 */
export const useHighlightFile = () => {
  const { t } = useTranslation();
  const { showNotification, updateNotification } = useNotifications();
  const { isLoading, setRequestStatus } = useRequestStatus();
  const { refetch } = useRefetchAppData();

  const { updateFile } = useFileOperations();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const toggleHighlight = useCallback(
    (eolasFile: EolasFile) => {
      const isHighlighted = !!eolasFile.highlighted && eolasFile.highlighted !== "false";

      const notification = showNotification({
        type: "loading",
        description: t(isHighlighted ? "highlights_remove_loading" : "highlights_add_loading"),
      });
      setRequestStatus({ status: "pending" });

      (useAppServicesEndpoints
        ? updateFile({
            variables: {
              input: {
                id: eolasFile.id,
                highlighted: isHighlighted ? "false" : new Date().toISOString(),
              },
              parentID: eolasFile.parentID,
            },
          })
        : contentClient.updateContentItem({
            mainSectionId: eolasFile.mainSectionID,
            contentId: eolasFile.id,
            contentDto: { highlighted: isHighlighted ? "false" : new Date().toISOString() },
          })
      )
        .then(async () => {
          refetch();
          setRequestStatus({ status: "success" });
          updateNotification({
            type: "success",
            id: notification.id,
            autoHideTimeout: 3000,
            description: t(isHighlighted ? "highlights_remove_success" : "highlights_add_success"),
          });
        })
        .catch((error) => {
          errorStore.captureError({
            error,
            source: "user",
          });
          setRequestStatus({ status: "error" });
          updateNotification({
            type: "error",
            id: notification.id,
            autoHideTimeout: 3000,
            description: t(isHighlighted ? "highlights_remove_failure" : "highlights_add_failure"),
          });
        });
    },
    [
      t,
      setRequestStatus,
      showNotification,
      updateFile,
      updateNotification,
      useAppServicesEndpoints,
      refetch,
    ],
  );

  return {
    highlightLoading: isLoading,
    toggleHighlight,
  };
};
