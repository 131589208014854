import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { AppLevelSection } from "@eolas-medical/core";
import { RouteComponentProps } from "react-router-dom";

import { Button, NavButton, FormElement, Input, PageTitle, InnerPageWrapper } from "UIKit";

import { AddContactsSection, SelectFacilitiesSection } from "../components";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";

const labelStyle = "mb-1 text-lg font-semibold";
const smallLabelStyles = "mb-1 text-md font-semibold";

export const AdminAddNewHospital: React.FC<RouteComponentProps> = ({
  match: { url },
  history: { push },
}: RouteComponentProps) => {
  const { t } = useTranslation();

  const goBack = useCallback(() => {
    push(url.replace("/add", ""));
  }, [push, url]);

  const { control, formMethods, onSubmit, isLoading, isFormComplete, error } = useAddEolasFile({
    afterSubmitFunction: goBack,
    mainSectionID: AppLevelSection.hospitalList,
  });

  return (
    <InnerPageWrapper>
      <PageTitle title={t("hospitalsAdmin_add_hospital_title")} />
      <NavButton to={url.replace("/add", "")} />

      <FormProvider {...formMethods}>
        <form className="flex flex-col items-stretch pb-8 space-y-4" onSubmit={onSubmit}>
          <FormElement
            required
            id="name"
            control={control}
            labelClassName={labelStyle}
            label={t("hospitalsAdmin_hospital_name_label")}
          >
            {(field) => (
              <Input
                {...field}
                disabled={isLoading}
                data-testid="hospitalNameInput"
                placeholder={t("hospitalsAdmin_hospital_name_placeholder")}
              />
            )}
          </FormElement>

          <FormElement
            required
            id="description"
            control={control}
            labelClassName={labelStyle}
            label={t("hospitalsAdmin_hospital_address_label")}
          >
            {(field) => (
              <Input
                {...field}
                disabled={isLoading}
                data-testid="hospitalAddressInput"
                placeholder={t("hospitalsAdmin_hospital_address_placeholder")}
              />
            )}
          </FormElement>

          <AddContactsSection />

          <span className={`${labelStyle}`}>{t("hospitalsAdmin_landing_site_section_title")}</span>

          <div
            className={`
              flex flex-col items-stretch p-4 space-y-4
              bg-grey-100 shadow-sm rounded-lg
            `}
          >
            <FormElement
              required
              control={control}
              id="moreInfo.coordinates"
              labelClassName={smallLabelStyles}
              label={t("hospitalsAdmin_landing_coordinates_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  disabled={isLoading}
                  className="bg-white"
                  data-testid="landingSiteCoordsInput"
                  placeholder={t("hospitalsAdmin_landing_coordinates_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              control={control}
              id="moreInfo.lighting"
              labelClassName={smallLabelStyles}
              label={t("hospitalsAdmin_landing_lighting_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  disabled={isLoading}
                  className="bg-white"
                  data-testid="landingSiteLightingInput"
                  placeholder={t("hospitalsAdmin_landing_lighting_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              control={control}
              id="moreInfo.transfer"
              labelClassName={smallLabelStyles}
              label={t("hospitalsAdmin_landing_transfer_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  disabled={isLoading}
                  className="bg-white"
                  data-testid="landingSiteTransferInput"
                  placeholder={t("hospitalsAdmin_landing_transfer_placeholder")}
                />
              )}
            </FormElement>
          </div>

          <SelectFacilitiesSection />

          {isFormComplete && (
            <Button
              color="blue"
              type="submit"
              isLoading={isLoading}
              className="self-center w-full sm:w-1/3"
            >
              {t("hospitalsAdmin_save_hospital")}
            </Button>
          )}

          {error && <span className="text-red text-center">{error}</span>}
        </form>
      </FormProvider>
    </InnerPageWrapper>
  );
};
