/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { motion, Variants } from "framer-motion";
import { overrideTailwindClasses as tw } from "tailwind-override";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

export interface SortableListProps {
  children: React.ReactNode;
  droppableId: string;
  isDragDisabled?: boolean;
  droppableClassname?: string;
  onDragEnd(dropResult: DropResult): void;
}

const containerVariants: Variants = {
  out: {
    opacity: 0.5,
  },
  in: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const SortableList: React.FC<SortableListProps> = ({
  children,
  onDragEnd,
  droppableId,
  isDragDisabled = false,
  droppableClassname = "",
}: SortableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {({ innerRef, droppableProps, placeholder }) => (
          <motion.div
            ref={innerRef}
            {...droppableProps}
            animate="in"
            initial="out"
            variants={containerVariants}
            data-testid="contentManagement-droppableList"
            transition={{ type: "spring", stiffness: 98, damping: 10 }}
            className={tw(
              `flex flex-col space-y-4 divide-y divide-grey-light ${droppableClassname}`,
            )}
          >
            {React.Children.map(children, (child: any, index) => {
              if (!child) return null;

              return (
                <Draggable
                  index={index}
                  key={child.key}
                  draggableId={child.key}
                  isDragDisabled={isDragDisabled}
                >
                  {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
                    return (
                      <div ref={innerRef} {...dragHandleProps} {...draggableProps}>
                        {React.cloneElement(child, { isDragging })}
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}

            {placeholder}
          </motion.div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
