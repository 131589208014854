import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { SectionSponsoredSlotNonAdminTileProps } from "../EolasSectionTile.types";

export const SectionSponsoredSlotNonAdminTile = ({
  title,
  onClick,
  isDisabled = false,
  rightAction,
  description,
}: SectionSponsoredSlotNonAdminTileProps) => {
  return (
    <EolasTile
      isDisabled={isDisabled}
      variant="noIcon"
      primaryText={title}
      secondaryText={description}
      rightAction={rightAction}
      onClick={onClick}
    />
  );
};
