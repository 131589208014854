import { useMutation } from "@tanstack/react-query";
import { XIcon } from "Assets/Icons/monocolored";
import { ModalComponentProps, Modal, modalStore, ModalDialogueProps } from "Stores/ModalStore";
import { Button, Text } from "UIKit";
import { IconButton } from "UIKit/Button/IconButton";
import { InnerModalWrapper } from "UIKit/Modal/components/InnerModalWrapper";
import { Title } from "UIKit/Typography/Title";
import { expectNever } from "Utilities/helpers";
import { uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { DialogueModalIcon } from "./components/DialogueModalIcon";

type BaseMobxModalProps<T extends Modal> = T & { isOpen: boolean } & { onClose: () => void };
type ComponentModalProps = BaseMobxModalProps<ModalComponentProps>;
type DialogueModalProps = BaseMobxModalProps<ModalDialogueProps>;

export const MobxModal = () => {
  return (
    <>
      <NormalModals /> <StackedModals />
    </>
  );
};

const NormalModals = observer(() => {
  const isOpen = modalStore.modalState.isOpen;
  const modalProps = modalStore.modal;

  const onClose = () => {
    modalStore.closeModal("normal");
  };

  if (!modalProps) {
    return null;
  }

  const { variant } = modalProps;

  if (variant === "component") {
    return <ComponentModal {...modalProps} isOpen={isOpen} onClose={onClose} />;
  }

  if (variant === "dialogue") {
    return <DialogueModal {...modalProps} isOpen={isOpen} onClose={onClose} />;
  }

  // If we reach this point, it means we have an unexpected modal variant, did you add a new one?
  expectNever(variant);

  return null;
});

const StackedModals = observer(() => {
  const isOpen = modalStore.modalState.isStackedModalOpen;
  const modalProps = modalStore.stackedModal;

  const onClose = () => {
    modalStore.closeModal("stacked");
  };

  if (!modalProps) {
    return null;
  }

  const { variant } = modalProps;

  if (variant === "component") {
    return <ComponentModal {...modalProps} isOpen={isOpen} onClose={onClose} />;
  }

  if (variant === "dialogue") {
    return <DialogueModal {...modalProps} isOpen={isOpen} onClose={onClose} />;
  }

  // If we reach this point, it means we have an unexpected modal variant, did you add a new one?
  expectNever(variant);

  return null;
});

const ComponentModal = ({
  isOpen,
  Component,
  onClose,
  isDismissible = true,
  shouldRenderCloseButton = true,
}: ComponentModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      preventScroll
      ariaHideApp={false}
      onRequestClose={onClose}
      shouldCloseOnEsc={isDismissible}
      className={`
      modal relative overflow-hidden rounded-b-sm rounded-t-sm md:rounded-b-lg md:rounded-t-lg
    `}
      shouldCloseOnOverlayClick={isDismissible}
      overlayClassName={`
    flex items-center md:items-center justify-center
    fixed inset-0 z-40
  `}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.45)",
        },
      }}
    >
      {isDismissible || shouldRenderCloseButton ? (
        <IconButton
          size="sm"
          type="button"
          icon={<XIcon />}
          variant="rounded"
          onClick={onClose}
          data-testid="modal-close-button"
          className="absolute top-4 right-4 z-10"
        />
      ) : null}

      {typeof Component === "function" ? <Component /> : Component}
    </ReactModal>
  );
};

const DialogueModal = (props: DialogueModalProps) => {
  const {
    title,
    message,
    onConfirm,
    onConfirmAsync,
    icon,
    isOpen,
    onClose,
    onModalClosed,
    confirmText,
    isDismissible = false,
  } = props;
  const { t } = useTranslation();
  const [id] = useState(uniqueId("dialogue-modal-"));

  const onCloseCallback = () => {
    onClose();
    onModalClosed?.();
  };

  const { isLoading, isError, mutate } = useMutation({
    mutationFn: async () => {
      await onConfirmAsync?.();
    },
    mutationKey: [id],
    onSuccess: onCloseCallback,
  });

  const handleDismiss = () => {
    if (!props.dialogType || props.dialogType === "withDismissButton") {
      props.onDismiss?.();
    }
    onCloseCallback();
  };

  const handleConfirm = async () => {
    if (onConfirmAsync) {
      mutate();
    } else {
      onConfirm?.();
      onCloseCallback();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      preventScroll
      ariaHideApp={false}
      onRequestClose={onCloseCallback}
      shouldCloseOnEsc={isDismissible}
      className={`
    modal relative overflow-hidden rounded-b-sm rounded-t-sm md:rounded-b-lg md:rounded-t-lg
  `}
      shouldCloseOnOverlayClick={isDismissible}
      overlayClassName={`
  flex items-center md:items-center justify-center
  fixed inset-0 z-40
`}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.45)",
        },
      }}
    >
      <InnerModalWrapper>
        <div className="flex p-4 lg:p-10 flex-col space-y-4 items-center justify-center">
          <DialogueModalIcon iconName={icon} />
          <Title level={5}>{title}</Title>
          {message ? (
            <Text level={1} className="text-grey-700 pb-4 text-center">
              {message}
            </Text>
          ) : null}
          <div className="flex flex-row items-center justify-center gap-4 w-full">
            <Button color="blue" className="w-1/3" onClick={handleConfirm} isLoading={isLoading}>
              {confirmText || t("general_yes")}
            </Button>
            {!props.dialogType || props.dialogType === "withDismissButton" ? (
              <Button className="w-1/3" color="grey" onClick={handleDismiss}>
                {props.dismissText || t("general_no")}
              </Button>
            ) : null}
          </div>
          {isError ? (
            <Text level={1} className="text-red-500">
              {t("general_error")}
            </Text>
          ) : null}
        </div>
      </InnerModalWrapper>
    </ReactModal>
  );
};
