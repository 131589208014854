import { sectionStore } from "@eolas-medical/core";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useMemo } from "react";
import { useGetLimitedAccess } from "../../ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { makeShouldIncludeSectionHelpers } from "../../ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";

export const useLimitedAccessHelpers = ({ sectionId }: { sectionId: string }) => {
  const { isAdmin: isOnAdminView } = sectionStore;

  const { activeTab } = useEolasNavigation();
  const limitedAccess = useGetLimitedAccess({ activeTab });
  const adminStatus = useGetAdminStatus({ activeTab });

  return useMemo(() => {
    if (!isOnAdminView) {
      return null;
    }
    if (adminStatus === "limitedAdmin") {
      return makeShouldIncludeSectionHelpers(limitedAccess, sectionId);
    }
    return {
      filterFnForChildren: () => true,
      shouldCurrentSectionAllowAdminRights: adminStatus === "admin",
    };
  }, [limitedAccess, adminStatus, sectionId, isOnAdminView]);
};
