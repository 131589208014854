import { ContentItem, sectionStore } from "@eolas-medical/core";
import { ContentRepositoryId } from "../types";
import { mapToContentItem } from "../helpers";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

interface UseContentItemsProps {
  contentRepositoryId: ContentRepositoryId;
  subSectionId?: string;
}

interface UseContentItems {
  contentItems: ContentItem[];
  contentItemsLoading: boolean;
}
const useContentItems = ({
  contentRepositoryId,
  subSectionId,
}: UseContentItemsProps): UseContentItems => {
  const sectionId = subSectionId || contentRepositoryId;
  const childrenOrder = sectionStore.getChildrenOrder(sectionId);

  const { files: items, isFetching } = useGetFiles({
    overrideChildrenOrder: childrenOrder,
    sectionId,
  });

  return {
    contentItems: items.map(mapToContentItem),
    contentItemsLoading: isFetching,
  };
};

export default useContentItems;
