/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultValuesMapper, InputMapperCreator, ValidationSchemaCreator } from "./types";

import * as inputMappers from "./inputMappers";
import * as defaultMappers from "./defaultValuesMappers";
import * as validationSchemas from "./validationSchemas";
import { EolasMainSection } from "@eolas-medical/core";

interface SectionConfiguration {
  createInputMapper: InputMapperCreator;
  defaultValuesMapper: DefaultValuesMapper;
  validationSchema: ValidationSchemaCreator<any>;
}

export const sectionConfigurations: Record<EolasMainSection, SectionConfiguration> = {
  completedChecklist: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  admissionGuides: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  checklists: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  clinicalPathways: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    createInputMapper: inputMappers.createItemWithOrganisationMapper,
    validationSchema: validationSchemas.guidelinesSchema,
  },
  contacts: {
    validationSchema: validationSchemas.contactSchema,
    createInputMapper: inputMappers.createContactInputMapper,
    defaultValuesMapper: defaultMappers.contactDefaultValuesMapper,
  },
  educationPortal: {
    validationSchema: validationSchemas.itemWithExpirationInMonths,
    createInputMapper: inputMappers.createItemWithExpirationInMonthsMapper,
    defaultValuesMapper: defaultMappers.defaultValuesForItemWithExpirationInMonths,
  },
  emergencyActionCards: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
    createInputMapper: inputMappers.createItemWithFileMapper,
  },
  equipmentLocations: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createItemWithAttachmentMapper,
  },
  guidelines: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    createInputMapper: inputMappers.createItemWithOrganisationMapper,
    validationSchema: validationSchemas.guidelinesSchema,
  },
  clinicalDocuments: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    createInputMapper: inputMappers.createItemWithOrganisationMapper,
    validationSchema: validationSchemas.guidelineDocumentsSchema,
  },
  policyDocuments: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    createInputMapper: inputMappers.createItemWithOrganisationMapper,
    validationSchema: validationSchemas.guidelineDocumentsSchema,
  },
  highlights: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  hospitalList: {
    validationSchema: validationSchemas.hospitalSchema,
    createInputMapper: inputMappers.createHospitalInputMapper,
    defaultValuesMapper: defaultMappers.hospitalDefaultValuesMapper,
  },
  howTo: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createItemWithAttachmentMapper,
  },
  importantLinks: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  incidentReporting: {
    validationSchema: validationSchemas.incidentReportingSchema,
    createInputMapper: inputMappers.createIncidentReportingInputMapper,
    defaultValuesMapper: defaultMappers.incidentReportingDefaultValuesMapper,
  },
  majorIncidents: {
    defaultValuesMapper: defaultMappers.majorIncidentsMapper,
    validationSchema: validationSchemas.majorIncidentsSchema,
    createInputMapper: inputMappers.createMajorIncidentDocumentInputMapper,
  },
  meetTheTeam: {
    validationSchema: validationSchemas.itemMeetTheTeam,
    createInputMapper: inputMappers.createItemWithAttachmentMapper,
    defaultValuesMapper: defaultMappers.meetTheTeamDefaulValuesMapper,
  },
  newsFeed: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createNewsItemMapper,
  },
  patientLeaflets: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  rotas: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  surveys: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  theDepartment: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.departmentAreaSchema,
    createInputMapper: inputMappers.createDepartmentAreaInputMapper,
  },
  wellbeing: {
    validationSchema: validationSchemas.itemWithExpirationInMonths,
    createInputMapper: inputMappers.createItemWithExpirationInMonthsMapper,
    defaultValuesMapper: defaultMappers.defaultValuesForItemWithExpirationInMonths,
  },
  researchAndAudit: {
    validationSchema: validationSchemas.itemWithExpirationInMonths,
    createInputMapper: inputMappers.createItemWithExpirationInMonthsMapper,
    defaultValuesMapper: defaultMappers.defaultValuesForItemWithExpirationInMonths,
  },
  // hospital tab files
  drugMonitoring: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
    createInputMapper: inputMappers.createDrugMonitoringInputMapper,
  },
  hospitalGuidelines: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    createInputMapper: inputMappers.createItemWithOrganisationMapper,
    validationSchema: validationSchemas.guidelinesSchema,
  },
  hospitalContacts: {
    validationSchema: validationSchemas.contactSchema,
    createInputMapper: inputMappers.createContactInputMapper,
    defaultValuesMapper: defaultMappers.contactDefaultValuesMapper,
  },
  learningManagement: {
    validationSchema: validationSchemas.itemWithExpirationInMonths,
    createInputMapper: inputMappers.createItemWithExpirationInMonthsMapper,
    defaultValuesMapper: defaultMappers.defaultValuesForItemWithExpirationInMonths,
  },
  communicationPortal: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createNewsItemMapper,
  },
  genericOrganisationGuidelines: {
    defaultValuesMapper: defaultMappers.defaultMapper,
    createInputMapper: inputMappers.createItemWithOrganisationMapper,
    validationSchema: validationSchemas.guidelinesSchema,
  },
  organisationWellbeing: {
    validationSchema: validationSchemas.itemWithExpirationInMonths,
    createInputMapper: inputMappers.createItemWithExpirationInMonthsMapper,
    defaultValuesMapper: defaultMappers.defaultValuesForItemWithExpirationInMonths,
  },
  orgPatientLeaflets: {
    createInputMapper: inputMappers.createItemWithFileMapper,
    defaultValuesMapper: defaultMappers.defaultMapper,
    validationSchema: validationSchemas.itemWithFileSchema,
  },
  clinicalCalculators: {} as any,
  majorIncidentDocuments: {} as any,
  medusaMedications: {} as any,

  // community
  communityPatientLeaflets: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createNewsItemMapper,
  },
  nationalGuidelines: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createNewsItemMapper,
  },
  communityMedications: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createNewsItemMapper,
  },
  clinicalQuestions: {
    defaultValuesMapper: defaultMappers.newsItemMapper,
    validationSchema: validationSchemas.newsItemSchema,
    createInputMapper: inputMappers.createNewsItemMapper,
  },
  myFiles: {} as any,
  myPortfolio: {} as any,
  channels: {} as any,
  activityFeed: {} as any,
  wikiEm: {} as any,
  niceGuidelines: {} as any,
  myFavourites: {} as any,
  sponsoredSlots: {} as any,
  askEolasSpaces: {} as any,
};
