import {
  documentClient,
  CreateSectionMapPdfBody,
  sectionStore,
  FileExtensionMediaType,
} from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import {
  isChildReferenceFile,
  isChildReferenceSection,
} from "shared/pages/ContentRepository/ContentItems/functions/typeguards";

type MutationParams = {
  ownerId: string;
  pdfName: string;
  content: CreateSectionMapPdfBody["content"];
};

const mutationFn = async ({ ownerId, content, pdfName }: MutationParams) => {
  return documentClient.createSectionMapPdf({ ownerId, content, name: pdfName });
};

export const useDownloadSectionMap = ({ mainSectionId }: { mainSectionId: string }) => {
  const { mutateAsync, isLoading, isError, error, data } = useMutation({
    mutationFn: async () => {
      const mainSection = sectionStore.getSection(mainSectionId);
      const mainSectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(mainSectionId);

      if (!mainSection || !mainSection.ownerID) {
        throw new Error("Main section not found");
      }

      if (mainSectionIdentity !== "genericContentRepository") {
        throw new Error(
          "Only generic content repository is supported when downloading section map",
        );
      }

      const sectionStructure = await getDeepSectionStructure(mainSectionId);

      if (!sectionStructure) {
        throw new Error("Unable to generate section structure");
      }

      const content: CreateSectionMapPdfBody["content"] = {
        rootName: sectionStructure.name,
        children: sectionStructure.children,
      };

      return mutationFn({
        ownerId: mainSection.ownerID,
        content,
        pdfName: mainSection.name || "",
      });
    },
    mutationKey: ["downloadSectionMap", mainSectionId],
  });

  return {
    downloadSectionStructure: mutateAsync,
    s3DownloadUrl: data?.s3DownloadUrl,
    isLoading,
    isError,
    error,
  };
};

type ItemReference = {
  name: string;
  children: ItemReference[];
};

export const getDeepSectionStructure = async (
  sectionId: string,
  depth: number = 0,
  maxDepth: number = 1000,
): Promise<ItemReference | null> => {
  if (depth > maxDepth) {
    throw new Error("Maximum recursion depth exceeded");
  }

  const childRef = sectionStore.getChildReferenceOfSection(sectionId);
  const children = sectionStore.getChildrenOrder(sectionId);

  if (!childRef || !children) {
    return null;
  }

  const item: ItemReference = {
    name: childRef.name || "",
    children: [],
  };

  for (const child of children) {
    if (isChildReferenceSection(child)) {
      const nextChildren = await getDeepSectionStructure(child.id, depth + 1, maxDepth);
      if (nextChildren) {
        item.children.push(nextChildren);
      }
    } else if (isChildReferenceFile(child)) {
      const file = await contentDbStore.getItem(child.id);

      if (!file) {
        continue;
      }

      const isSupportedBlobFileExtension =
        file.type && Object.values<string>(FileExtensionMediaType).includes(file.type);
      let name = file.name;

      if (isSupportedBlobFileExtension) {
        name = `${file.name}.${file.type}`;
      }

      if (child.type === "deepLink") {
        name = `${name} (Shortcut)`;
      }

      item.children.push({
        name: name || "",
        children: [],
      });
    }
  }

  return item;
};
