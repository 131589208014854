import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import medicationsKeys from "./medications.queryKeys";
import {
  AnalyticsEvents,
  PharmaFlashcardEventPayload,
  SpecialtyValue,
  contentClient,
  isFlashCardContentItem,
} from "@eolas-medical/core";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { trackEvent } from "API/Analytics";

interface UseMedicationsHighlightsItemsProps {
  medicationsHighlightsSectionId: string;
  specialties?: SpecialtyValue[];
}

const useMedicationHighlightsItems = ({
  medicationsHighlightsSectionId,
  specialties,
}: UseMedicationsHighlightsItemsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: medicationsKeys.highlights(specialties),
    queryFn: () =>
      contentClient.getFlashcardsByMainSectionId(medicationsHighlightsSectionId, specialties),
    onSuccess: (data) => {
      const items = data?.map(mapToContentItem).filter(isFlashCardContentItem) || [];
      if (items.length > 0) {
        const { name, id } = items[0];
        if (name && id) {
          trackEvent<PharmaFlashcardEventPayload>(
            AnalyticsEvents.MEDICATION_HIGHLIGHT_THUMBNAIL_SEEN,
            { name, id },
          );
        }
      }
    },
  });

  const medicationsHighlightsItems = useMemo(() => {
    return data?.map(mapToContentItem).filter(isFlashCardContentItem) || [];
  }, [data]);

  const trackSeen = useCallback(
    (currentSlide: number) => {
      if (!medicationsHighlightsItems.length || currentSlide < 0) {
        return;
      }

      const { name, id } = medicationsHighlightsItems[currentSlide - 1] || {};
      if (name && id) {
        trackEvent<PharmaFlashcardEventPayload>(
          AnalyticsEvents.MEDICATION_HIGHLIGHT_THUMBNAIL_SEEN,
          { name, id },
        );
      }
    },
    [medicationsHighlightsItems],
  );

  return {
    medicationsHighlightsItems,
    medicationsHighlightsItemsLoading: isLoading,
    trackSeen,
  };
};

export default useMedicationHighlightsItems;
