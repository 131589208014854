import { FavouriteToggle } from "UIKit/Button";
import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";
import { ShareFileButton } from "./shared/ShareFileButton";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { OrganisationLevelSection } from "@eolas-medical/core";

interface MedusaFileTileProps extends FileTileProps {
  isMedusaFile?: boolean;
}

export const MedusaFileTile: React.FC<MedusaFileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  isMedusaFile,
  onShareFile,
  onSelectFile = () => {
    return;
  },
  showRemoveFavouriteModal,
  ...handlers
}: MedusaFileTileProps) => {
  const { description, name } = eolasFile;
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);
  const shouldShowShareButton = !!onShareFile && !isAdmin;
  const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;

  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      isMedusaFile={isMedusaFile}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1">
        <span className="text-lg font-semibold mb-1">{name}</span>
        {description && <span className="text-sm text-grey-darker capitalize">{description}</span>}
      </div>

      {shouldShowFavouriteToggle || shouldShowShareButton ? (
        <div className="w-fit self-start flex flex-col items-center justify-start h-full">
          {shouldShowFavouriteToggle && (
            <FavouriteToggle
              entityId={eolasFile.id}
              isLoading={!!isMarkingAsFavourite}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: eolasFile.id,
                      favouriteId,
                      title: eolasFile.name,
                      entityType: FavouriteEntityType.FILE,
                      originForAnalytics: OrganisationLevelSection.medusaMedications,
                    })
                  : handleAddFavourite({
                      item: eolasFile,
                      entityType: FavouriteEntityType.FILE,
                    })
              }
            />
          )}
          {shouldShowShareButton && (
            <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
          )}
        </div>
      ) : null}

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
