import { ApiClient, ApiClientParams } from "@eolas-medical/core";

class MedusaService extends ApiClient {
  private path = "/medusa/v1";

  public async enableSection({ sectionId, apiKey }: { sectionId: string; apiKey: string }) {
    return this.http.patch(`${this.path}/medusa-section/${encodeURIComponent(sectionId)}/enable`, {
      apiKey,
    });
  }

  public async disableSection({ sectionId }: { sectionId: string }) {
    return this.http.patch(`${this.path}/medusa-section/${encodeURIComponent(sectionId)}/disable`);
  }
}

export let medusaService: MedusaService;

export const initialiseMedusaService = (params: ApiClientParams) => {
  medusaService = new MedusaService(params);
};
