import { InfoIconMonocolored, WarningIcon } from "Assets";
import { DialogModalIconName } from "Stores/ModalStore";

export const DialogueModalIcon = ({ iconName }: { iconName?: DialogModalIconName }) => {
  switch (iconName) {
    case "warning":
      return <WarningIcon className="w-10 h-10" />;
    case "info":
      return <InfoIconMonocolored className="h-10 w-10" />;
    default:
      return null;
  }
};
