import React from "react";
import { format } from "date-fns";

import { FileTileWrapper, FileTileProps, DefaultFileControls } from "./shared";

import { DownloadFileButton } from "Components";
import { ShareFileButton } from "./shared/ShareFileButton";
import { FavouriteToggle } from "UIKit/Button";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { AppLevelSection } from "@eolas-medical/core";

export const EmergencyActionCardFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  className,
  isDragging,
  onShareFile,
  onSelectFile = () => {
    return;
  },
  showRemoveFavouriteModal,
  ...controlProps
}: FileTileProps) => {
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);
  const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;
  const canShare = !!onShareFile && !isAdmin;

  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
      className={`${className} items-stretch h-40`}
    >
      <div
        style={{ minHeight: "6rem" }}
        title={eolasFile.name as string}
        className="flex flex-col justify-between flex-1"
      >
        <span className="text-lg font-semibold line-clamp-2" title={eolasFile.name ?? ""}>
          {eolasFile.name}
        </span>
        <span className="text-md text-grey-dark line-clamp-2">
          {format(new Date(eolasFile.updatedAt!), "io LLLL yyyy, kk:mm")}
        </span>
      </div>
      <div>
        {shouldShowFavouriteToggle && (
          <FavouriteToggle
            entityId={eolasFile.id}
            isLoading={!!isMarkingAsFavourite}
            onClick={() =>
              favouriteId && showRemoveFavouriteModal
                ? showRemoveFavouriteModal({
                    entityId: eolasFile.id,
                    favouriteId,
                    title: eolasFile.name,
                    entityType: FavouriteEntityType.FILE,
                    originForAnalytics: AppLevelSection.emergencyActionCards,
                  })
                : handleAddFavourite({
                    item: eolasFile,
                    entityType: FavouriteEntityType.FILE,
                  })
            }
          />
        )}
        <DownloadFileButton eolasFile={eolasFile} textClass="xl:hidden" />
        {canShare && (
          <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
        )}
      </div>
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...controlProps} />}
    </FileTileWrapper>
  );
};
