import { AppLevelSection, EolasMainSection } from "@eolas-medical/core";
import {
  isSupportedOrganisationContentDeepLinkType,
  isSupportedSpaceContentDeepLinkType,
} from "deeplinking/functions/typeguards";

export const isShareFileSupportedForDynamicPage = ({
  isShareFileEnabled,
  mainSectionType,
}: {
  mainSectionType: EolasMainSection | null;
  isShareFileEnabled: boolean;
}): boolean => {
  if (!isShareFileEnabled || !mainSectionType) {
    return false;
  }
  if (isSupportedSpaceContentDeepLinkType(mainSectionType)) {
    return true;
  }

  return false;
};

export const isFavouriteFileSupportedForDynamicPage = ({
  mainSectionType,
}: {
  mainSectionType: EolasMainSection | null;
}) => {
  if (!mainSectionType) {
    return false;
  }
  // My Favourites 1.0 does not support newsFeed and checklists
  if (
    (isSupportedSpaceContentDeepLinkType(mainSectionType) &&
      mainSectionType !== AppLevelSection.newsFeed &&
      mainSectionType !== AppLevelSection.checklists) ||
    isSupportedOrganisationContentDeepLinkType(mainSectionType)
  ) {
    return true;
  }

  if (isSupportedOrganisationContentDeepLinkType(mainSectionType)) {
    return true;
  }
  return false;
};
