import { Text, Title } from "../../../../../../../../../../../UIKit/Typography";
import {
  ModalHeader,
  InnerModalWrapper,
} from "../../../../../../../../../../../UIKit/Modal/components";
import { AttentionIcon, CheckIcon, NoResultsIcon, SearchIcon, SendEmailIcon } from "Assets";
import { Pill } from "UIKit/Pill";
import { Button } from "UIKit/Button";
import { useForm } from "react-hook-form";
import { FormElement, Input, Toggle } from "UIKit/FormElements";
import { format } from "date-fns";
import AvatarList from "UIKit/AvatarList/AvatarList";
import { FileTypeItemIcon } from "UIKit/FileTypeIcon";
import {
  AnalyticsEvents,
  AppLevelSection,
  EolasFile,
  SupportedFileType,
} from "@eolas-medical/core";
import { useState } from "react";
import useContentItemAnalytics from "modules/contentRepository/data/useGetContentItemAnalytics";
import useFilteredAudienceUsers from "./helpers";
import { SuccessModal } from "../../../../../../../../../../../UIKit/Modal/modals";
import { Loader } from "UIKit/Loader";
import { trackEvent } from "API/Analytics";
import { useMainSectionAnalytics } from "Pages/Spaces/pages/Space/pages/MiniApp/NewsFeed/hooks/useMainSectionAnalytics";
import { formatDate } from "Utilities";
import { useTranslation } from "react-i18next";

type ItemAudienceModal = {
  item: EolasFile;
};

/**
 * Will required a slight refactor for communication portal
 */
const ItemAudienceModal = ({ item }: ItemAudienceModal) => {
  const [showConfirmReminder, setShowConfirmReminder] = useState(false);
  const { t } = useTranslation();
  const { contentItemAnalytics, isLoadingContentItemAnalytics } = useContentItemAnalytics({
    mainSectionId: item.mainSectionID,
    contentId: item.id,
  });

  const {
    mainSectionAnalytics,
    sendContentItemReminder,
    sendingContentItemReminder: isSendingItemReminder,
    sendContentItemReminderSuccess: didSendItemReminder,
    resetContentItemReminder,
    downloadItemRateReport,
    downloadingItemRateReport: isDownloadingItemRateReport,
  } = useMainSectionAnalytics({ mainSectionId: item.mainSectionID });

  const { userCompletion, totalUnread: _omit, ...itemAnalytics } = contentItemAnalytics;

  const { name, info, createdAt, type } = item;

  const canSendReminder = mainSectionAnalytics?.readRatio !== "100";

  const handleSendContentItemReminder = (contentId: string) => {
    sendContentItemReminder({ mainSectionId: item.mainSectionID, contentId });
    trackEvent(AnalyticsEvents.MAIN_SECTION_STATS_SEND_READ_ITEM_REMINDER, {
      mainSection: AppLevelSection.newsFeed,
    });
  };

  const handlePrintReport = () => {
    const itemRate = {
      ...itemAnalytics,
      fileName: item.name,
      author: item.info || "",
      createdAt: formatDate(item.createdAt),
      totalUsers: contentItemAnalytics?.totalUsers?.toString(),
    };

    downloadItemRateReport({
      mainSectionId: item.mainSectionID,
      contentId: item.id,
      itemRate,
    });
    trackEvent(AnalyticsEvents.MAIN_SECTION_STATS_PRINT_REPORT, {
      mainSection: AppLevelSection.newsFeed,
    });
  };

  const { control, setValue, watch } = useForm({
    defaultValues: {
      searchText: "",
      showUnread: false,
    },
  });

  const handleShowUnreadToggle = (showUnread: boolean) => {
    setValue("showUnread", showUnread);
  };

  const searchText = watch("searchText");
  const showUnread = watch("showUnread");

  const filteredAudienceUsers = useFilteredAudienceUsers({
    userList: userCompletion,
    searchText,
    showUnread,
  });

  if (didSendItemReminder) {
    return (
      <SuccessModal onComplete={resetContentItemReminder} text={t("item_audience_reminder_sent")} />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col justify-start items-start p-10 border-b border-b-grey-300">
        <div className="flex">
          <FileTypeItemIcon type={type as SupportedFileType} className="w-8 h-8 mr-1" />
          <Title level={5}>{name}</Title>
        </div>
        <Text level={2} className="text-grey-600">
          {info}
        </Text>
        <Text level={2} className="text-grey-600 mb-8">
          {createdAt ? format(new Date(createdAt), "do MMMM yyyy") : null}
        </Text>
      </ModalHeader>

      <div
        className="flex flex-col
        p-6 sm:p-8 bg-grey-50 max-h-80vh overflow-y-auto"
      >
        {showConfirmReminder ? (
          <div className="space-y-4">
            <div className="flex flex-col w-2/3 mx-auto justify-center items-center">
              <SendEmailIcon className="h-24 w-24" />
              <Title level={9} className="mx-auto text-center">
                {t("item_audience_modal_confirm_reminder")}
              </Title>
            </div>
            <div className="flex justify-center items-center mx-auto space-x-2 w-full md:w-2/4">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setShowConfirmReminder(false)}
                className="w-full"
              >
                {t("general_cancel")}
              </Button>
              <Button
                iconLeft="ShareIcon"
                data-testid="send-reminder-button"
                size="sm"
                isLoading={isSendingItemReminder}
                onClick={() => handleSendContentItemReminder(item.id)}
                className="w-full"
              >
                <Text level={2} className="font-bold ml-2 text-center">
                  {t("send")}
                </Text>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-4 w-full min-h-20vh">
            <div className="flex flex-col sm:flex-row justify-between items-center gap-y-4 ">
              <FormElement required id="searchText" control={control} className="w-full sm:w-4/6">
                {(fieldProps) => {
                  const { value: _omit, ...rest } = fieldProps;
                  return (
                    <Input
                      {...rest}
                      className="input h-10 pl-4 box-content rounded-3xl"
                      placeholder={t("item_audience_modal_search_placeholder")}
                      iconLeft={<SearchIcon />}
                    />
                  );
                }}
              </FormElement>
              {canSendReminder ? (
                <FormElement required id="showUnread" control={control}>
                  {(fieldProps) => {
                    const { value, ...rest } = fieldProps;
                    return (
                      <Toggle
                        {...rest}
                        label={
                          value
                            ? t("item_audience_modal_show_read")
                            : t("item_audience_modal_hide_read")
                        }
                        checked={value}
                        labelPosition="left"
                        onChange={handleShowUnreadToggle}
                      />
                    );
                  }}
                </FormElement>
              ) : null}
            </div>
            {filteredAudienceUsers && filteredAudienceUsers?.length > 0 ? (
              <AvatarList
                usersList={filteredAudienceUsers}
                RightComponent={RightComponent}
                isLoading={isLoadingContentItemAnalytics}
              />
            ) : searchText.trim() !== "" ? (
              <div
                className="flex flex-col items-center justify-center space-y-2"
                data-testid="master-search-no-results"
              >
                <NoResultsIcon />
                <Title level={9} className="text-grey-700">
                  {t("general_no_results", { searchText })}
                </Title>
              </div>
            ) : (
              <Loader />
            )}

            <div className="flex justify-center items-center space-x-2 mt-8">
              <Button
                size="sm"
                iconLeft="PrintIcon"
                variant="outline"
                data-testid="print-report-button"
                isLoading={isDownloadingItemRateReport}
                disabled={isLoadingContentItemAnalytics || isDownloadingItemRateReport}
                onClick={handlePrintReport}
              >
                <Text level={2} className="font-bold ml-2">
                  {t("item_audience_modal_print_report")}
                </Text>
              </Button>
              <Button
                size="sm"
                data-testid="confirm-reminder-button"
                disabled={isLoadingContentItemAnalytics || !canSendReminder}
                iconLeft="ShareIcon"
                onClick={() => setShowConfirmReminder(true)}
              >
                <Text level={2} className="font-bold ml-2 text-center">
                  {t("item_audience_modal_send_read_reminder")}
                </Text>
              </Button>
            </div>
            <div className="flex justify-center items-center mx-auto text-center">
              <Text level={3} className="text-blue-500">
                {t("item_audience_modal_info_text")}
              </Text>
            </div>
          </div>
        )}
      </div>
    </InnerModalWrapper>
  );
};

const RightComponent = ({ isRead }: { isRead: boolean }) => {
  const { t } = useTranslation();
  const status = isRead ? t("general_read") : t("general_unread");
  return (
    <Pill
      value={status}
      className={`font-bold text-xs ${
        isRead ? "bg-green-50 text-green-500" : "bg-red-50 text-red-500"
      }`}
      valueClassname="text-3xs sm:text-2xs"
      icon={
        isRead ? (
          <CheckIcon className="h-1.5 w-1.5  sm:h-2 sm:w-2 text-green-400" />
        ) : (
          <AttentionIcon className="h-1.5 w-1.5  sm:h-2 sm:w-2 text-red-500" />
        )
      }
    />
  );
};

export default ItemAudienceModal;
