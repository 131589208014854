import { accessClient } from "@eolas-medical/core";
import { ShouldAllowUserAccessResponse } from "@eolas-medical/core/lib/src/clients/access/access.types";
import { useQuery } from "@tanstack/react-query";

type Props = {
  spaceId: string;
  isEnabled?: boolean;
  onSuccess?: (data: ShouldAllowUserAccessResponse) => void;
};

export const useCheckSpaceAccess = ({ spaceId, isEnabled = true, onSuccess }: Props) => {
  const { data, isFetching, isError, error, refetch } = useQuery({
    queryFn: () => accessClient.shouldAllowUserAccess(spaceId),
    queryKey: ["checkSpaceAccess", spaceId],
    enabled: isEnabled,
    onSuccess,
  });

  return {
    isFetching,
    data: data || null,
    isError,
    error,
    refetch,
  };
};
