/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { pick } from "lodash";

import { UpdateFileInput } from "@eolas-medical/core/lib/src/graphql";
import { useFileOperations } from "Hooks";
import { useRequestStatus } from "Utilities";
import { IncidentReportingResolveStatus } from "Utilities/types";
import { errorStore } from "Stores/ErrorStore";

const updateProps = [
  "id",
  "name",
  "description",
  "type",
  "key",
  "info",
  "infoTwo",
  "moreInfo",
  "moreInfoTwo",
  "searchField",
  "expiryDate",
  "metadata",
];

export const useIncidentReport = () => {
  const { removeFile, updateFile } = useFileOperations();
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();

  const onResolveReport = useCallback(
    ({ parentID, ...incidentReport }: UpdateFileInput) =>
      async () => {
        setRequestStatus({ status: "pending", error: "" });
        try {
          await updateFile({
            variables: {
              parentID,
              input: {
                ...pick(incidentReport, updateProps),
                moreInfoTwo: IncidentReportingResolveStatus.resolved,
              },
            },
          });
          setRequestStatus({ status: "success", error: "" });
        } catch (error: any) {
          const errorMessage = errorStore.captureError({
            error,
            source: "user",
            retryCallback: updateFile,
            retryParameters: {
              variables: {
                parentID,
                input: {
                  ...pick(incidentReport, updateProps),
                  moreInfoTwo: IncidentReportingResolveStatus.resolved,
                },
              },
            },
          });

          setRequestStatus({ status: "error", error: errorMessage });
        }
      },
    [setRequestStatus, updateFile],
  );

  const onDeleteReport = useCallback(
    (incidentReport: UpdateFileInput) => async () => {
      setRequestStatus({ status: "pending", error: "" });
      try {
        await removeFile({
          variables: {
            id: incidentReport.id,
            parentID: incidentReport.parentID,
          },
        });
        setRequestStatus({ status: "success", error: "" });
      } catch (error: any) {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: removeFile,
          retryParameters: {
            variables: {
              id: incidentReport.id,
              parentID: incidentReport.parentID,
            },
          },
        });
        setRequestStatus({ status: "error", error: errorMessage });
      }
    },
    [removeFile, setRequestStatus],
  );

  return {
    error,
    isLoading,
    isSuccessful,
    onDeleteReport,
    onResolveReport,
  };
};
