import { useQuery } from "@tanstack/react-query";
import contentKeys from "./content.queryKeys";
import { mapToContentItem } from "../helpers";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

interface UseContentItem {
  mainSectionId: string;
  contentId: string;
  enabled?: boolean;
}
const useFetchContentItem = ({ contentId, enabled = true }: UseContentItem) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: contentKeys.detail(contentId),
    queryFn: () => contentDbStore.getItem(contentId),
    enabled,
  });

  return {
    contentItem: data ? mapToContentItem(data) : undefined,
    isContentItemLoading: isLoading && enabled,
    isFetchContentItemError: isError,
    fetchContentItemError: error,
  };
};

export default useFetchContentItem;
