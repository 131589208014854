import { useCallback, useEffect, useMemo, useState } from "react";
import { OnSearchAnalytics, UseLocalSearchProps } from "./types";
import { AdditionalClickSearchProps, AdditionalDebounceSearchProps } from "shared/components/Lists";
import { isEqual } from "lodash";
import { fuseSearch } from "./fuseSearch";

export const useLocalClickSearch = <T extends object>({
  data,
  keysToSearch: givenKeysToSearch,
  onSearchAnalytics,
  ...rest
}: UseLocalSearchProps<T> &
  (AdditionalClickSearchProps | AdditionalDebounceSearchProps) & {
    onSearchAnalytics?: OnSearchAnalytics<T>;
  }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedSearchText, setSelectedSearchText] = useState("");

  const [keysToSearch, setKeysToSearch] = useState(givenKeysToSearch);

  if (!isEqual(keysToSearch, givenKeysToSearch)) {
    setKeysToSearch(givenKeysToSearch);
  }

  const onClickCallback =
    rest.searchType === "click" && rest.onClickSearch ? rest.onClickSearch : null;

  const onClearSearchCallback =
    rest.searchType === "click" && rest.onClearSearch ? rest.onClearSearch : null;

  const onClickSearch = useCallback(() => {
    setSelectedSearchText(searchText);
    onClickCallback?.();
  }, [onClickCallback, searchText]);

  const onClearSearch = useCallback(() => {
    onClearSearchCallback?.();
    setSelectedSearchText("");
    setSearchText("");
  }, [onClearSearchCallback]);

  const { results } = useMemo(() => {
    return fuseSearch(selectedSearchText, data, keysToSearch);
  }, [data, keysToSearch, selectedSearchText]);

  useEffect(() => {
    if (selectedSearchText.length && onSearchAnalytics) {
      onSearchAnalytics(selectedSearchText, results);
    }
  }, [selectedSearchText, onSearchAnalytics, results]);

  return {
    searchInput: searchText,
    isSearchClicked: Boolean(selectedSearchText),
    searchResult: results,
    onSetSearchInput: setSearchText,
    onClickSearch,
    onClearSearch,
  };
};
