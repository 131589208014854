import { ApiClient, ApiClientParams } from "@eolas-medical/core";
import { EventType } from "modules/portfolio/types";
import { WizardFormElements } from "../types";

export interface CompleteWizardProps {
  ownerId: string;
  eventDate: string;
  eventType: EventType;
  prompt: string;
  formElements: WizardFormElements[];
}
class PortfolioWizardService extends ApiClient {
  private path = "/portfolio-wizard/v1";

  public async completeWizard({
    ownerId,
    eventDate,
    eventType,
    prompt,
    formElements,
  }: CompleteWizardProps) {
    const response = await this.http.post(`${this.path}/completions`, {
      ownerId,
      eventDate,
      eventType,
      prompt,
      formElements,
    });
    return response.data;
  }
}

export let portfolioWizardService: PortfolioWizardService;

export const initialisePortfolioWizardService = (params: ApiClientParams) => {
  portfolioWizardService = new PortfolioWizardService(params);
};
