import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useRunOnMountUnmount } from "Hooks";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { EolasFile } from "@eolas-medical/core";

type Props = {
  onSelectFile: (file: EolasFile) => void;
};

export const useSelectNewsFeedItemOnMount = ({ onSelectFile }: Props) => {
  const { fileId } = useGetSearchParams({ paramList: ["fileId"] });

  useRunOnMountUnmount({
    onMount: async () => {
      if (fileId) {
        const file = await contentDbStore.getItem(fileId);
        if (file) {
          onSelectFile(file);
        }
      }
    },
  });
};
