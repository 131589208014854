export const medusaIconsMapper = (iconName: string) => {
  switch (iconName) {
    case "paediatric":
    case "paediatricIntravenous":
    case "paedIntravenous":
      return "IntraVenousPaediatricIcon";
    case "adult":
      return "AdultIcon";
    case "adultIntravenous":
      return "IntraVenousAdultIcon";
    case "paedSubcutaneous":
      return "PaedSubcutaneous";
    case "adultSubcutaneous":
      return "SubcutaneousIcon";
    case "adultIntramuscular":
      return "IntraMuscularIcon";
    case "paedIntramuscular":
      return "PaedIntraMuscular";
    case "paedOcularInjection":
      return "PaedIntraOcular";
    case "adultOcularInjection":
      return "AdultIntraOcular";
    case "intrathecal":
      return "IntrathecalIcon";
    default:
      return "MedusaIcon";
  }
};
