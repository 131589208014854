import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import {
  NationalResourceEntityType,
  EolasFile,
  AnalyticsEvents,
  NationalResourcesClickPayload,
} from "@eolas-medical/core";

import { NavButton, PageTitle, InnerPageWrapper, Modal, Loader } from "UIKit";

import { snakeCase } from "lodash";
import { EOLAS_CALCULATOR, CommunitySectionType } from "../types";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { useSelectFile } from "Hooks";
import { trackEvent } from "API/Analytics";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useGetNationalResourceByNationalBody } from "modules/nationalResources/data/useGetNationalResourceByNationalBody";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";

const ClinicalCalculatorsBody: React.FC<RouteComponentProps> = observer(() => {
  const { onSelectFile } = useSelectFile();

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);

  const history = useHistory();

  const location = useLocation();
  const { body } = (location.state as { body: string }) || {};
  const { t } = useTranslation();

  const {
    searchInstanceId,
    handleClearSearch,
    handleSearchClick,
    flatListData,
    gettingNationalResources,
    isSearchLoading,
  } = useGetNationalResourceByNationalBody({
    entityType: NationalResourceEntityType.CALCULATOR,
    nationalBody: body,
  });

  const handleClickCalculator = React.useCallback(
    (calculator: EolasFile) => {
      const calculatorName = snakeCase(calculator.name as string);

      trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
        nationalResourceType: NationalResourceEntityType.CALCULATOR,
        resourceName: calculator.name,
      } as NationalResourcesClickPayload);

      if (body === EOLAS_CALCULATOR) {
        history.push({ pathname: `eolas_calculator/${calculatorName}/${calculator.id}` });
      } else {
        onSelectFile(calculator);
      }
    },
    [body, history, onSelectFile],
  );

  const renderItem = React.useCallback(
    ({ item }: { item: EolasFile }) => (
      <div className="mb-3">
        <CommunityListItem
          eolasFile={item}
          onSelectFile={handleClickCalculator}
          sectionType={CommunitySectionType.NATIONAL_RESOURCE}
          onShareFile={isShareFileEnabled ? () => handleOnShareFile(item) : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      </div>
    ),
    [handleClickCalculator, handleOnShareFile, isShareFileEnabled, showRemoveFavouriteModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <Modal open={isModalOpen} onClose={onCloseModal}>
          {shareModal || removeFavouriteModal}
        </Modal>
        <PageTitle title={t("clinicalCalculators_title")} />
        <NavButton onClick={() => history.goBack()} />
        {gettingNationalResources ? (
          <Loader className="p-16" />
        ) : (
          <EolasVersatileList
            className="space-y-6"
            items={flatListData}
            isSearchLoading={isSearchLoading}
            renderItem={renderItem}
            isSearchable
            droppableId="content-section-list"
            searchMode="click"
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            searchInstanceId={searchInstanceId}
          />
        )}
      </InnerPageWrapper>
    </>
  );
});

export default ClinicalCalculatorsBody;
