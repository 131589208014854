import { useTranslation } from "react-i18next";

export const useExampleQuestions = (type: "knowledge" | "space") => {
  const { t } = useTranslation();
  let questions: string[] = [];

  if (type === "knowledge") {
    questions = [t("example_qn_1"), t("example_qn_2"), t("example_qn_3")];
  }
  if (type === "space") {
    questions = [t("example_qn_1_space"), t("example_qn_2_space"), t("example_qn_3_space")];
  }
  return questions;
};
