import { AppLevelSection } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { generateS3FileKey } from "Utilities";
import { LDFlagNames } from "Utilities/types";

interface UseUploadTeamMemberPhoto {
  uploadTeamMemberPhoto: (photo: string | File | null, mainSectionId: string) => Promise<string>;
}
const useUploadTeamMemberPhoto = (): UseUploadTeamMemberPhoto => {
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const uploadTeamMemberPhoto = async (photo: string | File | null, mainSectionId: string) => {
    let s3Key = "";

    if (!photo) return s3Key;

    if (typeof photo !== "string") {
      if (useAppServicesEndpoints) {
        s3Key = generateS3FileKey({
          isPublic: true,
          addExtension: true,
          fileName: photo.name,
          fileFormat: photo.type,
          mainSectionId: AppLevelSection.meetTheTeam,
        });
        await uploadFile(s3Key, photo);
      } else {
        const { publicUrl } = await uploadFileV2({
          file: photo,
          mainSectionId,
          isPublic: true,
        });
        if (!publicUrl) throw new Error("publicUrl missing when uploading team member photo");
        s3Key = publicUrl;
      }
    } else {
      s3Key = photo;
    }

    return s3Key;
  };

  return { uploadTeamMemberPhoto };
};

export default useUploadTeamMemberPhoto;
