import React from "react";
import { MedusaItem, OrganisationLevelSection } from "@eolas-medical/core";
import { TileContainer, Text, FavouriteToggle } from "UIKit";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";

export const MedusaFileTile = ({
  medusaItem,
  onSelectMedusaFile,
  isShareFileEnabled,
  showRemoveFavouriteModal,
  handleOnShareFile,
}: {
  medusaItem: MedusaItem;
  onSelectMedusaFile: (params: { id: string; name: string; url: string }) => void;
  isShareFileEnabled: boolean;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
  handleOnShareFile: (medusaItem: MedusaItem) => void;
}) => {
  const { id, name, url } = medusaItem;
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(id);

  return (
    <TileContainer
      onClick={() => onSelectMedusaFile({ id, name, url: url.value })}
      className="h-20 md:h-24 justify-between"
    >
      <Text level={1}>{name}</Text>
      {isMarkFavouriteEnabled || isShareFileEnabled ? (
        <div>
          {isMarkFavouriteEnabled ? (
            <FavouriteToggle
              entityId={id}
              isLoading={!!isMarkingAsFavourite}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: id,
                      favouriteId,
                      title: name,
                      entityType: FavouriteEntityType.MEDUSA,
                      originForAnalytics: OrganisationLevelSection.medusaMedications,
                    })
                  : handleAddFavourite({
                      item: medusaItem,
                      entityType: FavouriteEntityType.MEDUSA,
                    })
              }
            />
          ) : null}
          {isShareFileEnabled ? (
            <ShareFileButton onShareFile={() => handleOnShareFile(medusaItem)} id={name} />
          ) : null}
        </div>
      ) : null}
    </TileContainer>
  );
};
