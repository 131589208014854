import { Space, SpaceRest } from "@eolas-medical/core";
import { createContext } from "react";

export interface SpacesContextValue {
  isAdmin: boolean;
  selectedSpaceId?: string;
  isInSpace: boolean;
  isPublic: boolean;
  selectedSpace?: Space;
  encodedSelectedSpaceName?: string;
  onSelectSpace: (space: Space | SpaceRest) => void;
  onSwitchToAdmin: (isAdmin: boolean) => void;
  onClearSpace: () => void;
}

const SpacesContext = createContext<SpacesContextValue>({
  isAdmin: false,
  selectedSpaceId: undefined,
  isInSpace: false,
  isPublic: false,
  selectedSpace: undefined,
  encodedSelectedSpaceName: undefined,
  onSelectSpace: () => {
    return;
  },
  onSwitchToAdmin: () => {
    return;
  },
  onClearSpace: () => {
    return;
  },
});

export default SpacesContext;
