import {
  AppLevelSection,
  EolasFile,
  eolasLogger,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";
import fileDownload from "js-file-download";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { extractFileNameFromLegacyS3Key, generateS3PublicImageURL } from "Utilities";
import { getPDFUrl } from "Hooks/useDocumentCache";
import { isFileExtensionMediaType } from "modules/contentRepository/helpers";
import { errorStore } from "Stores/ErrorStore";
import { getFileExtensionFromString } from "Utilities/helpers";

export const useDownloadFile = (eolasFile: EolasFile) => {
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const mainSection = eolasFile?.mainSectionID
    ? sectionStore.getMainSectionTypeFromMainSectionID(eolasFile.mainSectionID)
    : null;
  const isDownloadable = eolasFile?.type ? isFileExtensionMediaType(eolasFile?.type) : false;

  const isDownloadConditionMet = useCallback(() => {
    // TEMP FIX: Include check for 'ms-office' until tech debt is addressed.
    return isDownloadable || eolasFile?.type === "ms-office";
  }, [isDownloadable, eolasFile?.type]);

  const downloadFileUrl = useCallback(
    async (key: string): Promise<string> => {
      if (
        mainSection === AppLevelSection.patientLeaflets ||
        mainSection === OrganisationLevelSection.orgPatientLeaflets
      ) {
        return generateS3PublicImageURL(key);
      } else {
        return getPDFUrl({ fileId: eolasFile.id, key });
      }
    },
    [mainSection, eolasFile.id],
  );

  useEffect(() => {
    let isCancelled = false;

    if (eolasFile?.key && isDownloadConditionMet()) {
      downloadFileUrl(eolasFile.key).then((url) => {
        if (!isCancelled) {
          setDownloadUrl(url);
        }
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [downloadFileUrl, eolasFile?.key, isDownloadConditionMet]);

  const download = useCallback(
    (url: string, filename: string) => {
      if (isDownloadConditionMet()) {
        axios
          .get(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, filename);
          })
          .catch((error) => {
            errorStore.captureError({
              error: `Download error: ${error.message}`,
              source: "network",
            });
            console.error("Download error:", error);
          });
      }
    },
    [isDownloadConditionMet],
  );

  const saveFile = useCallback(
    (e: MouseEvent) => {
      if (!eolasFile?.key) return;

      let fileName: string | null = null;

      if (eolasFile.mediaName) {
        fileName = eolasFile.mediaName;
      } else if (eolasFile.key) {
        if (eolasFile.key.startsWith("public/")) {
          fileName = extractFileNameFromLegacyS3Key(eolasFile.key);
        } else {
          const fileExt = getFileExtensionFromString(eolasFile.key);
          fileName = `${encodeURIComponent(eolasFile.name)}.${fileExt}`;
        }
      }

      if (!fileName) {
        eolasLogger.error(new Error("Error getting file name for download"), { eolasFile });
        return;
      }

      e.stopPropagation();
      e.preventDefault();
      download(downloadUrl, fileName);
    },
    [download, downloadUrl, eolasFile],
  );

  return { saveFile };
};
