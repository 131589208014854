import { useSpacesContext } from "modules/spaces";
import ContentItems from "shared/pages/ContentRepository/ContentItems/ContentItems";
import useSpaceContentRepositoryParams from "../../hooks/useSpaceContentRepositoryParams";
import { useRecordMainSectionVisit } from "Hooks";

const SpaceContentItems = () => {
  const { contentRepositoryId, subSectionId } = useSpaceContentRepositoryParams();
  const { isAdmin } = useSpacesContext();

  useRecordMainSectionVisit({ mainSectionId: contentRepositoryId, visitFrom: "directNavigation" });

  return (
    <ContentItems
      contentRepositoryId={contentRepositoryId}
      subSectionId={subSectionId}
      isAdmin={isAdmin}
      showExpiredItems
      allowedAdminActions={["shadowCopy", "delete"]}
    />
  );
};

export default SpaceContentItems;
