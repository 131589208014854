import { ContentItem } from "@eolas-medical/core";
import {
  EolasVersatileListContext,
  EolasVersatileListRenderItemProps,
} from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { IsCopyableStatus } from "../../ContentItemInstance/types";
import ContentItemInstance from "../../ContentItemInstance/ContentItemInstance";

type FullFeatures = {
  isInAdminMode: boolean;
  doFlashcardsHaveSpecialties: boolean;
  handleRemoveFromSelection: (id: string) => void;
  handleAddToSelection: (id: string, item: ContentItem) => void;
  isCopyableStatus?: IsCopyableStatus;
  mode: "fullFeatures";
};

type MinimalAdmin = {
  isInAdminMode: true;
  mode: "minimalAdmin";
};

export type ContentItemListContext = EolasVersatileListContext<
  ContentItem,
  FullFeatures | MinimalAdmin
>;

const ContentListItem = ({
  item,
  context,
}: EolasVersatileListRenderItemProps<ContentItem, ContentItemListContext>) => {
  let doFlashcardsHaveSpecialties: FullFeatures["doFlashcardsHaveSpecialties"] = false;
  let isListDraggable: boolean | undefined = false;
  let isListSelectable: boolean | undefined = false;
  let selectedItems: Record<string, ContentItem> | undefined = undefined;
  let handleAddToSelection: FullFeatures["handleAddToSelection"] | null = null;
  let handleRemoveFromSelection: FullFeatures["handleRemoveFromSelection"] | null = null;
  let isCopyableStatus: IsCopyableStatus | undefined = undefined;

  if (context.mode === "fullFeatures") {
    doFlashcardsHaveSpecialties = context.doFlashcardsHaveSpecialties;
    isListDraggable = context.isListDraggable;
    isListSelectable = context.isListSelectable;
    selectedItems = context.selectedItems;
    handleAddToSelection = context.handleAddToSelection;
    handleRemoveFromSelection = context.handleRemoveFromSelection;
    isCopyableStatus = context.isCopyableStatus;
  }

  const { isInAdminMode } = context;

  const isSelected = selectedItems && !!selectedItems[item.id];

  const handleCheckboxClick = () => {
    if (isSelected) {
      handleRemoveFromSelection?.(item.id);
    } else {
      handleAddToSelection?.(item.id, item);
    }
  };

  return (
    <div className="pb-4">
      <ContentItemInstance
        contentItem={item}
        data-testid={`content-item-${item.id}`}
        isAdminMode={isInAdminMode}
        flashcardsHaveSpecialties={doFlashcardsHaveSpecialties}
        isDraggable={isListDraggable}
        isSelected={isSelected}
        onCheckboxClick={isListSelectable ? handleCheckboxClick : undefined}
        isCopyableStatus={isCopyableStatus}
      />
    </div>
  );
};

export const renderContentItems = (
  props: EolasVersatileListRenderItemProps<ContentItem, ContentItemListContext>,
) => {
  return <ContentListItem {...props} />;
};
