import { useTranslation } from "react-i18next";
import { avatar } from "Assets";
import { Text } from "UIKit";
import { generateS3PublicImageURL } from "Utilities";
import { TeamMember } from "../../../../types";

interface TeamMemberCardProps {
  teamMember: TeamMember;
}

const TeamMemberCard = ({ teamMember }: TeamMemberCardProps) => {
  const { t } = useTranslation();

  const withBioClass = teamMember.bio ? "cursor-pointer hover:shadow-sm hover:border-blue-100" : "";

  const imgSrc = teamMember.photoUrl ? generateS3PublicImageURL(teamMember.photoUrl) : avatar;

  return (
    <div
      key={teamMember.id}
      className={`flex flex-col justify-center items-center w-full bg-white rounded-lg 
      border border-grey-300 p-6 h-68 shadow-xs ${withBioClass}`}
      data-testid="team-member-card"
    >
      <img alt="" className="rounded-full object-cover h-32 w-32" src={imgSrc} srcSet={imgSrc} />

      <div className="flex flex-col text-center space-y-2 mt-6">
        <Text level={1}>{teamMember?.name}</Text>
        <Text level={2} className="text-grey-600 line-clamp-2">
          {teamMember?.role}
        </Text>
        {teamMember.bio && (
          <Text level={2} className="text-blue-600">
            {t("meetTheTeam_read_more")}
          </Text>
        )}
      </div>
    </div>
  );
};

export default TeamMemberCard;
