import { useCallback, useState } from "react";
import fileDownload from "js-file-download";
import axios from "axios";
import { hasProp } from "@eolas-medical/core";

export type GetFileAsync = (
  fileId: string,
) => Promise<{ fileName: string; fileUrl: string | undefined; fileExt: string | undefined }>;

interface UseFileDownloadProps {
  getFileAsync: GetFileAsync;
}

export interface OnDownloadActions {
  onSuccess?: () => void;
  onError?: () => void;
}

interface UseFileDownload {
  downloadFile: (fileId: string, onDownloadActions?: OnDownloadActions) => void;
  preparingDownload: boolean;
  downloadError: string;
}

const useFileDownload = ({ getFileAsync }: UseFileDownloadProps): UseFileDownload => {
  const [state, setState] = useState({
    preparingDownload: false,
    downloadError: "",
  });

  const downloadFile = useCallback(
    async (fileId: string, onDownloadActions?: OnDownloadActions) => {
      const { onSuccess, onError } = onDownloadActions || {};
      try {
        setState({ preparingDownload: true, downloadError: "" });
        const { fileName, fileUrl, fileExt } = await getFileAsync(fileId);
        if (fileUrl) {
          const res = await axios.get(fileUrl, {
            responseType: "blob",
          });

          await fileDownload(res.data, `${fileName}.${fileExt}`);
        } else {
          throw new Error("Invalid file type");
        }
        setState((prev) => ({ ...prev, preparingDownload: false }));
        onSuccess && onSuccess();
      } catch (ex: unknown) {
        const downloadError = hasProp(ex, "message") ? (ex.message as string) : "";
        setState({ preparingDownload: false, downloadError });
        onError && onError();
      }
    },
    [getFileAsync],
  );

  return {
    ...state,
    downloadFile,
  };
};

export default useFileDownload;
