import { Button, ButtonProps } from "./Button";

import {
  CopyIcon,
  DeleteIcon,
  EditMonoIcon,
  HeartFilledIcon,
  HeartIcon,
  OperationsIcon,
} from "Assets";

export const ControlButton: React.FC<ButtonProps> = ({
  onClick,
  children,
  className,
  ...props
}: ButtonProps) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick(e);
      }}
      {...props}
      size={props.size ?? "sm"}
      color="grey"
      className={`${className}`}
    >
      {children}
    </Button>
  );
};

export const DeleteButton: React.FC<ButtonProps> = (props) => {
  return (
    <ControlButton {...props}>
      <DeleteIcon width={18} height={18} />
    </ControlButton>
  );
};

export const EditButton: React.FC<ButtonProps> = (props) => {
  return (
    <ControlButton {...props}>
      <EditMonoIcon width={18} height={18} />
    </ControlButton>
  );
};

export const CopyButton: React.FC<ButtonProps> = (props) => {
  return (
    <ControlButton {...props}>
      <CopyIcon width={18} height={18} />
    </ControlButton>
  );
};

export const FavouriteButton: React.FC<ButtonProps> = (props) => {
  return (
    <ControlButton {...props}>
      <HeartIcon width={18} height={18} />
    </ControlButton>
  );
};

export const FavouriteButtonFilled: React.FC<ButtonProps> = (props) => {
  return (
    <ControlButton {...props}>
      <HeartFilledIcon width={18} height={18} />
    </ControlButton>
  );
};

export const OperationsButton: React.FC<ButtonProps> = (props) => {
  return (
    <ControlButton {...props}>
      <OperationsIcon width={18} height={18} />
    </ControlButton>
  );
};
