import { FavouriteToggle } from "UIKit/Button";
import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";
import { ShareFileButton } from "./shared/ShareFileButton";
import { getNatResourceEntityTypeBasedOnMainSectionId } from "modules/myFavourites/functions/helpers";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { CommunityLevelSection, parseMetadata } from "@eolas-medical/core";

export const NationalGuidelineFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  onSelectFile = () => {
    return;
  },
  onShareFile,
  showRemoveFavouriteModal,
  ...handlers
}: FileTileProps) => {
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);

  const shouldShowShareButton = !!onShareFile && !isAdmin;
  const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;
  const nationalBody = eolasFile.metadata ? parseMetadata(eolasFile.metadata)?.nationalBody : "";
  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col justify-between flex-3 h-32">
        <span className="text-lg font-semibold line-clamp-2" title={eolasFile.name ?? ""}>
          {eolasFile.name}
        </span>
        <span className="text-md text-grey-dark line-clamp-2">
          {eolasFile.description || nationalBody}
        </span>
      </div>

      {shouldShowShareButton || shouldShowFavouriteToggle ? (
        <div className="w-fit self-start flex flex-col items-center justify-start h-full">
          {shouldShowFavouriteToggle ? (
            <FavouriteToggle
              entityId={eolasFile.id}
              isLoading={!!isMarkingAsFavourite}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: eolasFile.id,
                      favouriteId,
                      title: eolasFile.name,
                      originForAnalytics: CommunityLevelSection.nationalGuidelines,
                      entityType: getNatResourceEntityTypeBasedOnMainSectionId(
                        eolasFile.mainSectionID,
                      ),
                    })
                  : handleAddFavourite({
                      item: eolasFile,
                      entityType: getNatResourceEntityTypeBasedOnMainSectionId(
                        eolasFile.mainSectionID,
                      ),
                    })
              }
            />
          ) : null}
          {shouldShowShareButton ? (
            <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
          ) : null}
        </div>
      ) : null}

      {isAdmin ? <DefaultFileControls eolasFile={eolasFile} {...handlers} /> : null}
    </FileTileWrapper>
  );
};
