import { useMemo } from "react";

import { UrineIcon } from "Assets";
import { CreatinineClearanceReturnValues } from "@eolas-medical/core";

import { ResultBox } from "./ResultBox";

interface CreatinineClearanceResultsProps {
  results: CreatinineClearanceReturnValues;
  actualBodyWeight: number;
}

export const CreatinineClearanceResults = ({
  results,
  actualBodyWeight,
}: CreatinineClearanceResultsProps) => {
  const {
    actualWeightCrCl,
    idealWeightCrCl,
    adjustedWeightCrCl,
    idealBodyWeight,
    adjustedBodyWeight,
    error,
    ibwError,
  } = results;

  const details = useMemo(() => {
    return makeDetails(actualBodyWeight);
  }, [actualBodyWeight]);

  return (
    <>
      <ResultBox
        error={error}
        details={details}
        detailsTitle="Details"
        title="Based on actual weight:"
        text={`${actualWeightCrCl} mL/min`}
        icon={<UrineIcon className="w-6 h-6 mr-4" />}
      />

      <>
        <ResultBox
          error={ibwError}
          detailsTitle="Details"
          title="Based on ideal weight:"
          text={idealWeightCrCl ? `${idealWeightCrCl} mL/min` : "Not calculated"}
          details={makeDetails(idealBodyWeight)}
          icon={<UrineIcon className="w-6 h-6 mr-4" />}
        />
        <ResultBox
          error={ibwError}
          detailsTitle="Details"
          title="Based on adjusted weight:"
          text={adjustedWeightCrCl ? `${adjustedWeightCrCl} mL/min` : "Not calculated"}
          details={makeDetails(adjustedBodyWeight)}
          icon={<UrineIcon className="w-6 h-6 mr-4" />}
        />
      </>
    </>
  );
};

const makeDetails = (weight: number | null | string) => {
  return [`Weight used: ${weight + "kg"}.`, "CrCl calculated using Cockcroft-Gault formula."];
};
