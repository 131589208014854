import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  EolasFile,
  sectionStore,
  AppLevelSection,
  ChecklistRecord,
  OrganisationLevelSection,
  CommunityLevelSection,
  CompletedContentStatus,
  MonographType,
  isClinicalQuestionMetadata,
  isFlashCardContentItem,
  fromAwsJSON,
  KnowledgeContentDomains,
} from "@eolas-medical/core";

import { VideoModal } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { useSelectFile, useCompleteFile } from "Hooks";

import { ClinicalCalculatorsSearchResult } from "../types";
import { spaceRoutes } from "Pages/Spaces/pages/Space/SpaceRoutes";
import { ContactDetailsModal } from "Pages/Spaces/pages/Space/pages/MiniApp/Contacts/components";
import { PatientLeafletQRModal } from "Pages/Spaces/pages/Space/pages/MiniApp/PatientLeaflets/components";
import { HospitalPreviewModal } from "Pages/Spaces/pages/Space/pages/MiniApp/Hospitals/components";
import { SimpleSectionFileModal } from "Pages/Spaces/pages/Space/pages/SimpleSections/components";
import { NewsItemViewModal } from "Pages/Spaces/components/NewsItems";
import { BlobTypes } from "modules/generic.types";
import { isBnfMetadata, isNiceResultMetadata, isSmpcMetadata } from "./typeguards";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";
import { errorStore } from "Stores/ErrorStore";
import useGetBnfAllSummaryData from "modules/bnf/data/useGetBnfAllSummaryData";
import { makeBnfRoute } from "Pages/Knowledge/pages/Medications/pages/Bnf/routes/BnfRoutes";
import { constructNiceUrl } from "Pages/Knowledge/pages/NiceGuidelines/routes/makeNiceGuidelinesRoute";
interface Options {
  from?: "masterSearch" | "notificationCenter";
}

export const useSelectFileModal = (options: Options = {}) => {
  const { push } = useHistory();
  const { onSelectFile: selectFile } = useSelectFile();
  const [modal, setModal] = useState<React.ReactNode>(null);
  const { onCompleteFile } = useCompleteFile();
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const { data: bnfData, isInitialLoading: isBnfLoading } = useGetBnfAllSummaryData();

  const onCloseModal = () => setModal(null);

  // TODO: remove this redirect when having new clinical calculators screen.
  const onSelectClinicalCalculator = (item: ClinicalCalculatorsSearchResult | EolasFile) => {
    const section = sectionStore.getSection(item.id!);

    push(`${BaseAppRoute.knowledge}/clinicalCalculators/${section.type}/${item.id}`, {
      from: options.from,
    });
    return;
  };

  const onSelectChecklist = (checklist: ChecklistRecord) => {
    const { id } = sectionStore.getChildReferenceOfSection(checklist.parentID!)!;

    push(
      `${BaseAppRoute.spaces}/checklists/${encodeURIComponent(id)}/details/${encodeURIComponent(
        checklist.id,
      )}`,
      {
        from: options.from,
      },
    );
    return;
  };

  const onSelectFlashcard = (flashcard: EolasFile, shadowCopyContentId?: string) => {
    push(
      `/${BaseAppRoute.viewer}/${encodeURIComponent(
        flashcard.mainSectionID,
      )}/flash-cards/${encodeURIComponent(flashcard.id)}`,
      {
        from: options.from,
        shadowCopyContentId,
      },
    );
    return;
  };

  const onSelectEolasEditor = (contentItem: EolasFile, shadowCopyContentId?: string) => {
    push(
      `/${BaseAppRoute.viewer}/${encodeURIComponent(
        contentItem.mainSectionID,
      )}/eolas-editor/${encodeURIComponent(contentItem.id)}`,
      {
        from: options.from,
        shadowCopyContentId,
      },
    );
    return;
  };

  const onSelectDsm = (contentItem: EolasFile, shadowCopyContentId?: string) => {
    push(
      `/${BaseAppRoute.viewer}/${encodeURIComponent(
        contentItem.mainSectionID,
      )}/dsm-viewer/${encodeURIComponent(contentItem.id)}`,
      {
        from: options.from,
        shadowCopyContentId,
      },
    );
    return;
  };

  const onSelectMedications = (medication: EolasFile) => {
    const metadata = medication.metadata ? fromAwsJSON(medication.metadata) : null;

    if (isSmpcMetadata(metadata)) {
      push(
        `/${BaseAppRoute.knowledge}/communityMedications/pharmaResources/${encodeURIComponent(
          metadata.smpcSection.parentId,
        )}/${encodeURIComponent(metadata.smpcSection.id)}`,
        {
          from: options.from,
        },
      );
      return;
    }
    if (isBnfMetadata(metadata)) {
      if (bnfData) {
        const monographType: MonographType =
          metadata.medicationMonographType === "BNF" ? "bnf" : "bnfc";
        const data = [
          ...bnfData[monographType].items,
          ...bnfData[monographType].guidanceItems,
          ...bnfData[monographType].treatmentSummaryItems,
          ...bnfData[monographType].additionalContentItems,
        ];
        const summaryItem = data.find(({ id }) => id === metadata.bnfId);
        if (summaryItem) {
          push(
            makeBnfRoute({
              routeKey: summaryItem.type,
              idOrSid: summaryItem.id,
              title: medication.name,
            }),
          );
          return;
        }
      }
    }
    errorStore.captureError({
      error: "Medication data is missing, on malformed",
      source: "user",
      data: { metadata, isBnfDataPresent: Boolean(bnfData) },
    });
    showNotification({
      type: "error",
      description: t("smpc_open_error"),
      autoHideTimeout: 8000,
    });
  };

  const onSelectNiceSummaryItem = (item: EolasFile) => {
    const metadata = item.metadata ? fromAwsJSON(item.metadata) : null;
    if (isNiceResultMetadata(metadata)) {
      const { niceGuidanceId, niceSectionId, niceSubsectionId, nicePageNo } = metadata;
      const route = constructNiceUrl({
        niceSummaryId: niceGuidanceId,
        niceSectionId,
        niceSubsectionId,
        pageNo: nicePageNo,
        type: CommunityLevelSection.niceGuidelines,
      });
      push(route, { from: options.from });
      return;
    }
  };
  const onSelectFile = (file: EolasFile, shadowCopyContentId?: string) => {
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID);

    if (mainSectionType === CommunityLevelSection.clinicalQuestions) {
      const metadata = fromAwsJSON(file.metadata);
      if (isClinicalQuestionMetadata(metadata)) {
        const contentItem = metadata.clinicalQuestionContent;
        if (isFlashCardContentItem(contentItem)) {
          push(
            `/${BaseAppRoute.knowledge}/${
              KnowledgeContentDomains.clinicalQuestionsContent
            }/${encodeURIComponent(contentItem.mainSectionId)}/${encodeURIComponent(
              contentItem.parentId,
            )}/${encodeURIComponent(contentItem.id)}`,
            {
              from: options.from,
            },
          );
        } else {
          errorStore.captureError({
            error: new Error("Expected clinical question to be flashcard"),
            source: "user",
            data: { contentItem },
          });
        }
        return;
      }
    }

    if (mainSectionType === AppLevelSection.meetTheTeam) {
      push(spaceRoutes.meetTheTeam, {
        from: options.from,
      });
      return;
    }

    if (
      mainSectionType === AppLevelSection.contacts ||
      mainSectionType === OrganisationLevelSection.hospitalContacts
    ) {
      setModal(<ContactDetailsModal contact={file} />);
      return;
    }

    if (
      mainSectionType === AppLevelSection.patientLeaflets ||
      mainSectionType === OrganisationLevelSection.orgPatientLeaflets
    ) {
      setModal(<PatientLeafletQRModal file={file} />);
      return;
    }

    if (mainSectionType === AppLevelSection.hospitalList) {
      setModal(<HospitalPreviewModal hospital={file} />);
      return;
    }

    if (mainSectionType === AppLevelSection.newsFeed) {
      setModal(<NewsItemViewModal newsItem={file} />);
      return;
    }

    if (
      mainSectionType === AppLevelSection.howTo ||
      mainSectionType === AppLevelSection.equipmentLocations
    ) {
      setModal(<SimpleSectionFileModal file={file} />);
      return;
    }

    if (mainSectionType === AppLevelSection.educationPortal) {
      onCompleteFile(file.id, CompletedContentStatus.SEEN);
    }

    if (mainSectionType === AppLevelSection.checklists) {
      onSelectChecklist(file);
    }

    if (mainSectionType === CommunityLevelSection.clinicalCalculators) {
      onSelectClinicalCalculator(file);
    }

    if (mainSectionType === CommunityLevelSection.communityPatientLeaflets) {
      setModal(<PatientLeafletQRModal file={file} />);
      return;
    }

    if (mainSectionType === CommunityLevelSection.communityMedications) {
      onSelectMedications(file);
      return;
    }
    if (mainSectionType === CommunityLevelSection.niceGuidelines) {
      const metadata = file.metadata ? fromAwsJSON(file.metadata) : null;
      if (isNiceResultMetadata(metadata)) {
        onSelectNiceSummaryItem(file);
      } else {
        // treat as national resource if no niceGuidanceId present in metadata
        selectFile(file);
      }
      return;
    }

    if (file.type === "mp4") {
      selectFile(file).then((url) => {
        setModal(<VideoModal file={file} videoURL={url} />);
      });
      return;
    }

    if (file.type === "flashcard") {
      onSelectFlashcard(file, shadowCopyContentId);
      return;
    }

    if (file.type === "eolas-editor") {
      onSelectEolasEditor(file, shadowCopyContentId);
      return;
    }

    if (file.type === "dsm") {
      onSelectDsm(file, shadowCopyContentId);
      return;
    }

    if (file.type && file.type.includes(file.type as BlobTypes)) {
      selectFile(file, shadowCopyContentId);
    }
  };

  return {
    modal,
    onCloseModal,
    onSelectFile,
    onSelectChecklist,
    onSelectClinicalCalculator,
    onSelectNiceSummaryItem,
    isBnfLoading,
  };
};
