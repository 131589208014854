import { SectionTileProps } from "./EolasSectionTile.types";
import { expectNever } from "Utilities/helpers";
import { SectionTileWithChildDetails } from "./components/SectionTileWithChildDetails";
import { SectionAdminTile } from "./components/SectionAdminTile";
import { SectionBasicTile } from "./components/SectionBasicTile";
import { SectionSponsoredSlotNonAdminTile } from "./components/SectionSponsoredSlotNonAdminTile";
import { SectionNationalBodyTile } from "./components/SectionNationalBodyTile";

export const EolasSectionTile = (props: SectionTileProps) => {
  const { variant } = props;

  if (variant === "withChildDetails") {
    return <SectionTileWithChildDetails {...props} />;
  }

  if (variant === "admin") {
    return <SectionAdminTile {...props} />;
  }

  if (variant === "basic") {
    return <SectionBasicTile {...props} />;
  }

  if (variant === "sponsoredSlotNonAdmin") {
    return <SectionSponsoredSlotNonAdminTile {...props} />;
  }

  if (variant === "nationalBody") {
    return <SectionNationalBodyTile {...props} />;
  }

  expectNever(variant);
};
