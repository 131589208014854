import { ApiClient, ApiClientParams } from "@eolas-medical/core";
import { makeBffApolloClient } from "API/functions/makeBffApolloClient";
import { queryApollo } from "API/functions/queryApollo";
import {
  FileSection,
  FileSectionDto,
  FileSectionPatchDto,
  FileSectionUpdateDto,
  MyFile,
  FileDto,
  FileUpdateDto,
  FilePatchDto,
  FilesBySection,
} from "../types";
import { FilesBySectionDocument } from "./myFiles.graphql";

export class MyFilesService extends ApiClient {
  private path = "/my-files/v1";
  private myFilesBffClient;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(args: any) {
    super(args);
    this.myFilesBffClient = makeBffApolloClient("my-files");
  }

  public async getAllSections(userId: string): Promise<FileSection> {
    const response = await this.http.get(`${this.path}/sections?userId=${userId}`);
    return response.data;
  }

  public async addFile(fileDto: FileDto) {
    const response = await this.http.post<MyFile>(`${this.path}/files`, fileDto);
    return response.data;
  }

  public async getAllFiles(userId: string) {
    const response = await this.http.get<MyFile[]>(`${this.path}/files?userId=${userId}`);
    return response.data;
  }

  public async createSection(sectionDto: FileSectionDto): Promise<FileSection> {
    const response = await this.http.post(`${this.path}/sections`, sectionDto);
    return response.data;
  }

  public async updateSection({ id, ...rest }: FileSectionUpdateDto): Promise<FileSection> {
    const response = await this.http.put(`${this.path}/sections/${id}`, rest);
    return response.data;
  }

  public async updateFile({ id, ...dto }: FileUpdateDto): Promise<MyFile> {
    const response = await this.http.put(`${this.path}/files/${id}`, dto);
    return response.data;
  }

  public async patchSection({ sectionId, prevSectionId }: FileSectionPatchDto) {
    return this.http.patch(`${this.path}/sections/${sectionId}`, { prevSectionId });
  }

  public async deleteSection(id: string) {
    return this.http.delete(`${this.path}/sections/${id}`);
  }

  public async deleteFile(id: string) {
    return this.http.delete(`${this.path}/files/${id}`);
  }

  public async patchFile({ fileId, prevFileId }: FilePatchDto) {
    return this.http.patch(`${this.path}/files/${fileId}`, { prevFileId });
  }

  public async getFile(id: string, getDownloadUrl = false) {
    const response = await this.http.get<MyFile>(
      `${this.path}/files/${id}?includeGetURL=${getDownloadUrl}`,
    );

    return response.data;
  }

  public async getAllFilesBySection() {
    const filesBySection: FilesBySection[] = await queryApollo(this.myFilesBffClient, {
      query: FilesBySectionDocument,
      variables: {},
    });

    return filesBySection;
  }
}

export let myFilesService: MyFilesService;

export const initialiseMyFilesService = (params: ApiClientParams) => {
  myFilesService = new MyFilesService(params);
};
