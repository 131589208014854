import { useMainSectionFlags } from "Hooks";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { ActiveTab } from "Utilities/types";
import { useGetMainSectionAdminAccess } from "./useGetMainSectionAdminAccess";
import {
  ChildReference,
  eolasLogger,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";
import { useCallback, useMemo } from "react";
import { mapToMainSection } from "modules/mainSections/helpers";
import { MainSection } from "modules/mainSections/types";

export type UseSpaceOrgMainSectionsReturn = {
  activatedMainSections: MainSection[];
  deactivatedMainSections: MainSection[];
  hiddenMainSections: MainSection[];
  hasDeactivatedSections: boolean;
};

export const useSpaceOrgMainSections = ({
  isOnAdminView = false,
  activeTab,
}: {
  isOnAdminView?: boolean;
  activeTab: Extract<ActiveTab, "spaces" | "organisation">;
}): UseSpaceOrgMainSectionsReturn => {
  const allMainSections: ChildReference[] =
    activeTab === "spaces"
      ? sectionStore.departmentSectionsOrder
      : sectionStore.hospitalSectionsOrder;

  const { isMainSectionEnabled } = useMainSectionFlags();
  const { getIconUrl } = useGetIconUrl();
  const { filterByAdminStatus } = useGetMainSectionAdminAccess({
    activeTab,
    isOnAdminView,
  });

  const mapSectionList = useCallback(
    (sections: ChildReference[]) => {
      return sections
        .map(mapToMainSection)
        .map((s) => ({ ...s, iconUrl: s.iconName ? getIconUrl(s.iconName) : undefined }));
    },
    [getIconUrl],
  );

  return useMemo(() => {
    const filteredMainSectionsBasedOnAdmin = isOnAdminView
      ? allMainSections.filter(filterByAdminStatus)
      : allMainSections;

    const filteredBasedOnFlagStatus = filteredMainSectionsBasedOnAdmin.filter(
      ({ icon, identity }) => {
        if (identity === "genericContentRepository") {
          return true;
        }
        return isMainSectionEnabled(icon ?? "");
      },
    );

    const activatedMainSections = filteredBasedOnFlagStatus.filter(({ disabled, icon }) => {
      return icon === OrganisationLevelSection.medusaMedications
        ? disabled !== "true" && Boolean(sectionStore.data.organisation?.medusaKey)
        : disabled !== "true";
    });

    const deactivatedMainSections = filteredBasedOnFlagStatus.filter(({ disabled, icon }) => {
      if (icon === OrganisationLevelSection.medusaMedications) {
        // The activation/deactivation of this section is managed by us through retool, if the section is not active we want it hidden
        return false;
      }
      return disabled === "true" || (disabled as unknown as boolean) === true;
    });

    const hiddenMainSections = allMainSections.filter(
      ({ id }) =>
        !activatedMainSections.some((childRef) => childRef.id === id) &&
        !deactivatedMainSections.some((childRef) => childRef.id === id),
    );

    const hasDeactivatedSections = deactivatedMainSections.length > 0;

    if (
      activatedMainSections.length + deactivatedMainSections.length + hiddenMainSections.length !==
      allMainSections.length
    ) {
      eolasLogger.error(
        new Error(
          "useSpaceOrgMainSections: 3 main section arrays do not add up to total. This should not happen",
        ),
        { allMainSections, activatedMainSections, deactivatedMainSections, hiddenMainSections },
      );
    }

    return {
      activatedMainSections: mapSectionList(activatedMainSections),
      deactivatedMainSections: mapSectionList(deactivatedMainSections),
      hiddenMainSections: mapSectionList(hiddenMainSections),
      hasDeactivatedSections,
    };
  }, [allMainSections, filterByAdminStatus, mapSectionList, isMainSectionEnabled, isOnAdminView]);
};
