import { useTranslation } from "react-i18next";

import { Title, ModalHeader, InnerModalWrapper, SuccessModal } from "UIKit";

import { UploadFileContent } from "./UploadFileContent";
import { EolasFile } from "@eolas-medical/core";
import { AddItemModalProps } from "../AddEolasFile/types";
import { FilePickerAllowedTypes } from "Utilities/types";

export interface AddItemWithFileModalProps extends AddItemModalProps {
  file?: EolasFile;
  error: string;
  progress: number;
  fileType?: FilePickerAllowedTypes;
  isLoading: boolean;
  pickerValue?: string;
  isSuccessful: boolean;
  withKeywords?: boolean;
  allowedFileTypes: FilePickerAllowedTypes[];
  handleSubmit: () => void;
  handleSelectType?: () => void;
  handleFileChange: (file: File) => void;
  onCloseModal: () => void;
}

export const UploadFileModal: React.FC<AddItemWithFileModalProps> = ({
  file,
  error,
  fileType,
  progress,
  isLoading,
  pickerValue,
  isSuccessful,
  allowedFileTypes,
  onCloseModal,
  handleSubmit,
  handleFileChange,
  handleSelectType,
}: AddItemWithFileModalProps) => {
  const { t } = useTranslation();

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>
          {t(file ? "general_edit_file_modal_title" : "general_add_file_modal_title")}
        </Title>
      </ModalHeader>

      <UploadFileContent
        error={error}
        fileType={fileType}
        value={pickerValue}
        progress={progress}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onChange={handleFileChange}
        onSelectType={handleSelectType}
        allowedFileTypes={allowedFileTypes}
      />
    </InnerModalWrapper>
  );
};
