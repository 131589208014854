import { useMemo, useState } from "react";
import { ListItem } from "../../SelectTileList/hooks/useLocalChildrenList";
import { SectionsMap, sectionStore } from "@eolas-medical/core";
import uniq from "lodash/uniq";
import {
  isAncestorSectionIdInLimitedAccessArray,
  makeFilterFnForChildren,
  makeLimitedAdminSectionLookupMap,
} from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { useGetLimitedAccess } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { ActiveTab } from "Utilities/types";
import { MutationParams, Results } from "modules/contentRepository/data/useBulkActionItems";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { BulkResponses, SelectableItem, ValidItem } from "../types";

export type BulkActionModalCommonProps = {
  initialItems: SelectableItem[];
};

export type BulkActionItemsModalProps<T extends BulkResponses> = BulkActionModalCommonProps & {
  isError: boolean;
  results: Results<T> | null;
  bulkAction: UseMutateAsyncFunction<
    | {
        type: "flat";
        data: T;
      }
    | {
        type: "allSettled";
        data: PromiseSettledResult<T>[];
      },
    unknown,
    MutationParams<T>,
    unknown
  >;
  isValidItem: (item: SelectableItem) => item is ValidItem<T>;
};

export const useBulkActionItemsModal = <T extends BulkResponses>({
  initialItems,
  results,
  isError,
  bulkAction,
  isValidItem,
}: BulkActionItemsModalProps<T>) => {
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(null);
  const validItems = useMemo(() => initialItems.filter(isValidItem), [initialItems, isValidItem]);
  const invalidItems = initialItems.filter((i) => !validItems.find(({ id }) => i.id === id));
  const [shouldShowInvalidItemsModal, setShouldShowInvalidItemsModal] = useState(
    invalidItems.length > 0,
  );
  const [didRetryFailedItems, setDidRetryFailedItems] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const spaceLimitedAccess = useGetLimitedAccess({ activeTab: "spaces" });
  const orgLimitedAccess = useGetLimitedAccess({ activeTab: "organisation" });
  const { sectionsMap } = sectionStore;
  const shouldHideOrgContent = initialItems[0]?.ownerId !== sectionStore.organisationID;

  const orgFullAccessLevel = useGetAdminStatus({ activeTab: "organisation" });
  const fullAccessLevel = useGetAdminStatus({ activeTab: "spaces" });

  const isUserOrgAdmin = isAccessLevelInAdminGroup(orgFullAccessLevel);
  const isUserSpaceAdmin = isAccessLevelInAdminGroup(fullAccessLevel);

  const shouldShowOrgItems = !shouldHideOrgContent && isUserOrgAdmin;
  const shouldShowSpaceItems = isUserSpaceAdmin;

  const shouldShowSuccess = results?.wasCompleteSuccess && !isError;
  const shouldShowError = results?.wasCompleteFailure || isError;
  const shouldShowPartialSuccess =
    !!results?.hasFailedItems && !shouldShowError && !shouldShowSuccess;
  const hasRetryFailed = (results?.hasFailedItems || isError) && didRetryFailedItems;

  const disabledSectionIds = useMemo(() => {
    const sectionIdsOfOriginals = validItems.map((item) => item.parentId);
    const spaceSectionIdsToDisable =
      shouldShowSpaceItems && spaceLimitedAccess.length
        ? generateSectionIdsToDisable(
            spaceLimitedAccess,
            getRelevantSectionMap(sectionsMap, "spaces"),
          )
        : [];
    const orgSectionIdsToDisable =
      shouldShowOrgItems && orgLimitedAccess.length
        ? generateSectionIdsToDisable(
            orgLimitedAccess,
            getRelevantSectionMap(sectionsMap, "organisation"),
          )
        : [];
    return uniq([...sectionIdsOfOriginals, ...spaceSectionIdsToDisable, ...orgSectionIdsToDisable]);
  }, [
    validItems,
    sectionsMap,
    shouldShowSpaceItems,
    shouldShowOrgItems,
    spaceLimitedAccess,
    orgLimitedAccess,
  ]);

  const handleSelectionChange = (selectedIds: Record<string, ListItem>) => {
    const selectedIdsArray = Object.keys(selectedIds);
    if (selectedIdsArray.length === 1) {
      setSelectedSectionId(selectedIdsArray[0]);
    } else {
      setSelectedSectionId(null);
    }
  };

  const handleBulkActionItems = async () => {
    if (selectedSectionId) {
      await bulkAction({
        contentItems: validItems,
        destinationSectionId: selectedSectionId,
      });
    }
  };

  const retryFailedItems = async (failedItems: ValidItem<T>[]) => {
    if (selectedSectionId) {
      await bulkAction({ contentItems: failedItems, destinationSectionId: selectedSectionId });
      setDidRetryFailedItems(true);
    }
  };

  const onInvalidItemsContinue = () => setShouldShowInvalidItemsModal(false);

  return {
    selectedSectionId,
    handleSelectionChange,
    isSearching,
    setIsSearching,
    handleBulkActionItems,
    shouldShowSuccess,
    shouldShowPartialSuccess,
    shouldShowError,
    retryFailedItems,
    hasRetryFailed,
    failedItems: results?.failedItems ?? [],
    shouldShowInvalidItemsModal,
    onInvalidItemsContinue,
    disabledSectionIds,
    shouldShowOrgItems,
    shouldShowSpaceItems,
    fullAccessLevel,
    orgFullAccessLevel,
    validItems,
    invalidItems,
  };
};

const generateSectionIdsToDisable = (limitedAccessArray: string[], sectionsMap: SectionsMap) => {
  const idsToDisable: string[] = [];

  const shouldIncludeAsDisabledSectionFn = makeLimitedAccessDisabledIdsFilterFn(limitedAccessArray);

  for (const section of Object.values(sectionsMap)) {
    if (shouldIncludeAsDisabledSectionFn(section.id)) {
      idsToDisable.push(section.id);
    }
  }

  return idsToDisable;
};

const makeLimitedAccessDisabledIdsFilterFn = (
  limitedAccessArray: string[],
): ((sectionId: string) => boolean) => {
  const limitedAdminSectionLookupMap = makeLimitedAdminSectionLookupMap(limitedAccessArray);
  const limitedAdminFilterFn = makeFilterFnForChildren(limitedAdminSectionLookupMap, false);
  return (sectionId: string) => {
    const shouldIncludeBasedOnPath = limitedAdminFilterFn(sectionId);
    if (shouldIncludeBasedOnPath) {
      return false;
    }
    const isAncestorLimitedAdmin = isAncestorSectionIdInLimitedAccessArray(
      sectionId,
      limitedAdminSectionLookupMap,
    );
    if (isAncestorLimitedAdmin) {
      return false;
    }
    return true;
  };
};

const getRelevantSectionMap = (
  sectionMap: SectionsMap,
  activeTab: Extract<ActiveTab, "spaces" | "organisation">,
) => {
  const newSectionMap: SectionsMap = {};
  const sectionsOrder =
    activeTab === "spaces"
      ? sectionStore.departmentSectionsOrder
      : sectionStore.hospitalSectionsOrder;
  for (const section of Object.values(sectionMap)) {
    if (sectionsOrder.some(({ id }) => id === section.mainSectionID)) {
      newSectionMap[section.id] = section;
    }
  }
  return newSectionMap;
};
