import { AddButton, Button, InnerPageWrapper, Loader, Modal, PageTitle, Text } from "UIKit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AddContentSectionWizard from "./components/AddContentSectionWizard/AddContentSectionWizard";
import ContentSectionList from "./components/ContentSectionList/ContentSectionList";
import { observer } from "mobx-react-lite";
import { ContentSectionVariants } from "../types";
import useContentRepositoryContext from "../context/useContentRepositoryContext";
import PaginatedContentSectionList from "./components/PaginatedContentSectionList/PaginatedContentSectionList";
import CreateContentRepositoryWizard from "Pages/Spaces/pages/Space/pages/ContentManagement/components/CreateContentRepositoryWizard/CreateContentRepositoryWizard";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import { NavBackButton } from "shared/components/LogicComponents";
import { modalStore } from "Stores/ModalStore";
import { DownloadSectionMapModal } from "Pages/Spaces/components/DownloadSectionMapModal/DownloadSectionMapModal";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { useShareFile } from "shared/hooks/useShareFile";
import { eolasLogger, sectionStore } from "@eolas-medical/core";
import { useNotifications } from "Components/Notifications";

export interface LegacyContentSectionsProps {
  contentRepositoryId: string;
  isAdmin?: boolean;
  showExpiredItems?: boolean;
  variant: ContentSectionVariants;
  backUrlString?: string;
  disableReorder?: boolean;
  isPaginated?: boolean;
  title?: string | null;
}

/**
 * @deprecated
 * No longer used for Spaces functionality, although spaces functionality may remain in the code
 * If need to extend knowledge functionality, strongly consider refactoring to remove this code from here
 * and add to a separate component. IMPORTANT NOTE: Knowledge tab currently uses 2 services, one for medication highlights and everything
 * else uses the new content-service (knowledge content service) so be careful when refactoring knowledge functionality
 */
export const LegacyContentSections = observer(
  ({
    isAdmin = false,
    showExpiredItems,
    variant = "titleAndIcon",
    backUrlString,
    disableReorder = false,
    isPaginated = false,
    title,
    contentRepositoryId,
  }: LegacyContentSectionsProps) => {
    const { t } = useTranslation();
    const { push, location } = useHistory();
    const { showNotification } = useNotifications();
    const { flags } = useLaunchDarkly();

    const {
      useContentRepositoryHook,
      isSubsection,
      useAddContentSectionHook,
      canOnlyCreateFileSection,
      useContentSectionHook,
      limitedAccessHelpers,
      contentSectionConfig,
      ...rest
    } = useContentRepositoryContext();
    const { contentRepository, contentRepositoryLoading } = useContentRepositoryHook;
    const { contentSection } = useContentSectionHook;
    const { addContentSection, addingContentSection } = useAddContentSectionHook;

    const [showModal, setShowModal] = useState(false);

    const isShareSectionEnabled = !!flags[LDFlagNames.GENERATE_DEEPLINKS_TO_SECTIONS];

    const { isShareFileEnabled, handleOnShareFile } = useShareFile({ shouldUseMobxModal: true });

    const canShareSection = isShareSectionEnabled && isShareFileEnabled;

    const isSponsoredSlots =
      rest.contextDomain === "knowledge" &&
      (rest.knowledgeContentDomain === "sponsoredSlots" ||
        rest.knowledgeContentDomain === "sponsoredSlotsContent");

    const handleShareSection = () => {
      if (contentSection) {
        const section = sectionStore.getSection(contentSection.id);
        if (section?.id) {
          handleOnShareFile(section);
          return;
        }
      }

      eolasLogger.error(new Error("Failed to get section for sharing"), { contentSection });
      showNotification({
        type: "error",
        autoHideTimeout: 3000,
        description: t("share_item_link_failure"),
      });
    };

    const handleAddSection = () => {
      setShowModal(true);
    };

    const handleCloseModal = () => {
      setShowModal(false);
    };

    const handleManageExpired = () => {
      push(`${location.pathname}/expired-items`);
    };

    const shouldShowAdmin = limitedAccessHelpers
      ? Boolean(limitedAccessHelpers.shouldCurrentSectionAllowAdminRights)
      : isAdmin;

    const handleDownloadSectionStructure = () => {
      modalStore.openModal({
        name: "downloadSectionMap",
        variant: "component",
        Component: <DownloadSectionMapModal mainSectionId={contentRepositoryId} />,
      });
    };

    if (contentRepositoryLoading) {
      return (
        <InnerPageWrapper>
          <div className="flex flex-col justify-center">
            <PageTitle
              className="my-4"
              title={title || contentSection?.name || contentRepository?.name || ""}
            />
            <NavBackButton modePreference="goBackHistory" />
            <Loader className="bg-transparent mt-5" />
          </div>
        </InnerPageWrapper>
      );
    }

    return (
      <>
        <Modal open={!!showModal} onClose={handleCloseModal}>
          {canOnlyCreateFileSection || !flags[LDFlagNames.MULTIPLE_SUBSECTIONS] ? (
            <AddContentSectionWizard
              variant={variant}
              onClose={handleCloseModal}
              isDescriptionRequired={rest.contextDomain !== "knowledge"}
            />
          ) : (
            <CreateContentRepositoryWizard
              onCreate={({ childrenType, icon, name, description }) => {
                addContentSection(
                  { iconName: icon, name, description, childrenType },
                  { onSuccess: handleCloseModal },
                );
              }}
              isLoading={addingContentSection}
              onClose={handleCloseModal}
              isCreatingMainSection={false}
            />
          )}
        </Modal>
        <InnerPageWrapper>
          <div className="flex flex-col justify-center">
            {contentSectionConfig?.shouldShowBreadcrumbs ? (
              <FileBreadcrumbs
                shouldShowSpaceName={true}
                mainSectionId={contentRepositoryId}
                parentId={contentSection?.id}
              />
            ) : null}

            <PageTitle
              className="my-4"
              title={title || contentSection?.name || contentRepository?.name || ""}
            />
            {isSponsoredSlots && contentRepository?.description ? (
              <div className="text-center text-sm text-grey-500 mb-4">
                <Text level={2} className={"text-black"}>
                  {contentRepository.description}
                </Text>
              </div>
            ) : null}
            <NavBackButton
              segmentsToRemove={isSubsection ? 1 : 2}
              modePreference={isSponsoredSlots ? "goBackHistory" : "removeFromPath"}
              overrideUrl={backUrlString}
            />
          </div>

          {shouldShowAdmin ? (
            <div className="flex justify-center items-center space-x-4">
              <AddButton onClick={handleAddSection} data-testid="add-file-section">
                {t("my_files_add_section")}
              </AddButton>
              {showExpiredItems && !isSubsection ? (
                <Button variant="outline" color="white" size="lg" onClick={handleManageExpired}>
                  {t("repository_manage_expired")}
                </Button>
              ) : null}
              {contentSectionConfig?.canDownloadSectionMap && !isSubsection ? (
                <Button
                  onClick={handleDownloadSectionStructure}
                  iconLeft="DownloadIcon"
                  color="white"
                  size="lg"
                >
                  {t("repository_content_map")}
                </Button>
              ) : null}
              {contentSectionConfig?.shouldAllowSharing && !isSubsection && canShareSection ? (
                <Button
                  onClick={handleShareSection}
                  iconLeft="SendOutlineIcon"
                  color="white"
                  size="lg"
                >
                  {t("repository_share_main_section")}
                </Button>
              ) : null}
            </div>
          ) : (
            <div className="flex justify-center items-center space-x-4">
              {contentSectionConfig?.shouldAllowSharing && !isSubsection && canShareSection ? (
                <Button
                  onClick={handleShareSection}
                  iconLeft="SendOutlineIcon"
                  color="white"
                  size="lg"
                >
                  {t("repository_share_main_section")}
                </Button>
              ) : null}
            </div>
          )}

          {isPaginated ? (
            <PaginatedContentSectionList isAdmin={shouldShowAdmin} variant={variant} />
          ) : (
            <ContentSectionList
              contentRepositoryId={contentRepository?.id ?? ""}
              isAdmin={shouldShowAdmin}
              variant={variant}
              disableReorder={disableReorder}
              currentSectionId={contentSection?.id}
            />
          )}
        </InnerPageWrapper>
      </>
    );
  },
);
