import { ChildrenType } from "modules/contentRepository/types";
import SectionTypeForm from "./SectionTypeForm";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Step } from "UIKit";

interface useSectionTypeStepProps {
  onSubmit: (childrenType: ChildrenType) => void;
  onPrev: () => void;
  isCreatingMainSection: boolean;
}

export const useSectionTypeStep = ({
  onSubmit,
  onPrev,
  isCreatingMainSection,
}: useSectionTypeStepProps): Step<"type"> => {
  const { t } = useTranslation();
  const [childrenType, setChildrenType] = useState<ChildrenType>("file");

  const handleSubmitSectionType = () => {
    onSubmit(childrenType);
  };

  const handleChangeAccessType = (childrenType: ChildrenType) => {
    setChildrenType(childrenType);
  };

  return {
    name: "type",
    title: t("create_section_type_step_title"),
    component: (
      <SectionTypeForm
        onChangeSectionType={handleChangeAccessType}
        selectedType={childrenType}
        isCreatingMainSection={isCreatingMainSection}
      />
    ),
    onSubmit: handleSubmitSectionType,
    backLabel: t("general_cancel"),
    onPrev,
  };
};
