import localforage from "localforage";
import { CONTENT_DB_KEY } from "./constants";
import { EolasFile, eolasLogger, sectionStore, userStore } from "@eolas-medical/core";
import { eolasFileNormaliser } from "Utilities/helpers";

let dbInstance: LocalForage | null = null;

export const initialiseDb = () => {
  if (!sectionStore.appID || !userStore.userID) {
    throw new Error("Attempted to initialise SpaceOrgFileDb when not in space");
  }
  if (dbInstance) {
    return dbInstance;
  }
  dbInstance = localforage.createInstance({ name: CONTENT_DB_KEY });
  return dbInstance;
};

export const deleteDb = async () => {
  try {
    await dbInstance?.clear();
  } catch (error) {
    eolasLogger.error("ContentDB: error deleting database", { error });
  }
  dbInstance = null;
};

export const addItem = async (file: EolasFile) => {
  try {
    const db = initialiseDb();
    await db.setItem(file.id, file);
  } catch (error) {
    eolasLogger.error("ContentDB: error adding item", { error });
  }
};

export const getItem = async (id: string): Promise<EolasFile | null> => {
  const db = initialiseDb();
  const item = await db.getItem(id);
  if (!item) {
    return null;
  }
  const { file } = eolasFileNormaliser(item);
  return file;
};

export const deleteItem = async (id: string) => {
  try {
    const db = initialiseDb();
    await db.removeItem(id);
  } catch (error) {
    eolasLogger.error("ContentDB: error deleting item", { error });
  }
};

export const iterateOverAllItems = async ({
  callback,
}: {
  callback: (value: EolasFile, key: string) => void;
}) => {
  try {
    const db = initialiseDb();
    await db.iterate(callback);
  } catch (error) {
    eolasLogger.error("ContentDB: error iterating over all items", { error });
  }
};
