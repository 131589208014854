import { useCallback, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { AppLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { SearchSortList, SortFn } from "shared/components/Lists";

import TeamMemberCard from "./components/TeamMemberCard/TeamMemberCard";
import TeamMemberModal from "./components/TeamMemberModal/TeamMemberModal";

import { TeamMember } from "../../types";
import useTeamMembers from "../../data/useTeamMembers";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const MeetTheTeam = observer(() => {
  const [sortMethod, setSortMethod] = useState<SortFn>();
  const { url } = useRouteMatch();
  useMainSectionVisit({ mainSectionId: AppLevelSection.meetTheTeam });

  const { t } = useTranslation();
  const { spaceSection } = useSpaceSection(AppLevelSection.meetTheTeam);
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { teamMembers } = useTeamMembers();

  const { searchResult, onSetSearchInput } = useLocalSearch({
    data: teamMembers,
    keysToSearch: ["name", "role"],
  });

  const items = useMemo(() => searchResult.slice().sort(sortMethod), [sortMethod, searchResult]);

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const handleClickTeamMember = useCallback((teamMember: TeamMember) => {
    if (teamMember.bio) {
      setModal(<TeamMemberModal teamMember={teamMember} />);
    }
  }, []);

  const renderTeamMemberCard = (teamMember: TeamMember) => (
    <div onClick={() => handleClickTeamMember(teamMember)}>
      <TeamMemberCard teamMember={teamMember} />
    </div>
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("meetTheTeam_title")} />
        <NavButton to={url.replace(`/${AppLevelSection.meetTheTeam}`, "")} />

        <SearchSortList<TeamMember>
          items={items}
          defaultSort="dragAndDrop"
          renderItem={renderTeamMemberCard}
          displayMode="grid"
          isSearchable
          isSortable
          onSearchInputChange={onSetSearchInput}
          onSortMethodChange={(sortMethod?: SortFn) => setSortMethod(() => sortMethod)}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={handleCloseModal} shouldCloseOnOverlayClick={false}>
        {modal}
      </Modal>
    </>
  );
});
