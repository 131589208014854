import {
  EolasRegions,
  UserData,
  getEolasRegionFromProfileLocation,
  getObjectEntries,
  isEolasRegions,
  userStore,
} from "@eolas-medical/core";
import { regionLookup } from "./regionLookup";
import { useMemo } from "react";
import { getBrowserCountry } from "Hooks/useBrowserCountry/useBrowserCountry";

export const useSupportedRegion = () => {
  const { userData } = userStore;

  const supportedEolasRegion = useMemo(() => getSupportedRegion(userData), [userData]);

  return { supportedEolasRegion, getSupportedEolasRegion: () => getSupportedRegion(userData) };
};

export const getSupportedRegion = (userData: UserData) => {
  let supportedEolasRegion = EolasRegions.RestOfWorld;

  const { location } = userData ?? userStore.userData;

  if (location) {
    const region = getEolasRegionFromProfileLocation(location);
    const countryRegion = region?.[0] ?? null;
    if (isEolasRegions(countryRegion)) {
      supportedEolasRegion = countryRegion;
    }
  } else {
    const browserCountry = getBrowserCountry();
    const foundLookup = getObjectEntries(regionLookup).find(
      ([, { region, firstLanguage }]) =>
        browserCountry === region || browserCountry === firstLanguage,
    );

    if (foundLookup) {
      const [maybeEolasRegion] = foundLookup;
      if (isEolasRegions(maybeEolasRegion)) {
        supportedEolasRegion = maybeEolasRegion;
      }
    }
  }
  return supportedEolasRegion;
};
