import { userStore } from "@eolas-medical/core";

// This hook should only be used within an observer component
export const useSpaceTabName = () => {
  const { appUserData, userDepartments } = userStore;

  const loggedSpace = userDepartments.find((d) => d.id === appUserData.appID);

  return {
    spacesTabName: loggedSpace?.tabName?.spaces,
    organisationTabName: loggedSpace?.tabName?.organisation,
  };
};
