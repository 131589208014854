import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Title } from "UIKit";
import { useMediaQuery } from "Hooks";

import { WebNotification } from "modules/webNotifications/types";
import useMarkAsRead from "modules/webNotifications/data/useMarkAsRead";
import useDismissNotification from "modules/webNotifications/data/useDismissNotification";

import NotificationCard from "../NotificationCard/NotificationCard";
import NoNotifications from "../NoNotifications/NoNotifications";
import useWebNotificationsConfig from "../../hooks/useWebNotificationsConfig";

interface NotificationsListProps {
  webNotifications: WebNotification[];
  webNotificationsLoading: boolean;
  onClose: () => void;
  onClickNotification: (webNotification: WebNotification) => void;
}
const NotificationsList = ({
  webNotifications,
  webNotificationsLoading,
  onClickNotification,
  onClose,
}: NotificationsListProps) => {
  const { t } = useTranslation();
  const { getWebNotificationProps } = useWebNotificationsConfig();
  const { markNotificationAsRead } = useMarkAsRead();
  const { dismissNotification } = useDismissNotification();
  const media = useMediaQuery();
  const isMobile = media === "xs";

  const handleClickNotification = useCallback(
    async (webNotification: WebNotification) => {
      if (!webNotification.isRead) {
        await markNotificationAsRead(webNotification.id);
      }
      onClickNotification(webNotification);
      onClose();
    },
    [markNotificationAsRead, onClickNotification, onClose],
  );

  const handleDismissNotification = useCallback(
    (notificationId: string) => {
      dismissNotification(notificationId);
    },
    [dismissNotification],
  );

  const renderNotifications = () => {
    if (webNotificationsLoading) {
      return <Loader className="p-16" />;
    }

    if (webNotifications.length === 0) {
      return <NoNotifications />;
    }

    return webNotifications.map((n) => {
      const { sectionName, subSectionName, pillTextColor, pillBgColor, color, pillIcon } =
        getWebNotificationProps(n);

      const Icon = n.icon;

      const PillIcon = pillIcon;

      return (
        <div key={n.id} onClick={() => handleClickNotification(n)}>
          <NotificationCard
            id={n.id}
            icon={Icon && <Icon className="h-6 w-6" />}
            title={n.title}
            body={n.body}
            createdAt={n.createdAt}
            sectionName={sectionName ? t(sectionName) : undefined}
            subSectionName={subSectionName}
            pillBgColor={`${color}-${pillBgColor}`}
            pillTextColor={`${color}-${pillTextColor}`}
            pillIcon={PillIcon && <PillIcon className={`w-4 h-4 text-${pillTextColor}`} />}
            showBlueCircle={!n.isRead}
            onClickAnchor={handleDismissNotification}
          />
        </div>
      );
    });
  };

  const containerClass = !isMobile
    ? "absolute top-8 lg:top-12 right-0 rounded-xl w-108 mt-2"
    : "w-100vw";

  return (
    <div className={`${containerClass} pt-8 pb-4 px-1 bg-white shadow-md`}>
      <Title level={5} className="px-5">
        {t("notification_center_title")}
      </Title>
      <div
        data-testid="notifications-list"
        className="mt-4 max-h-60vh overflow-y-auto p-2 space-y-2"
      >
        {renderNotifications()}
      </div>
    </div>
  );
};

export default NotificationsList;
