import { EolasFile } from "@eolas-medical/core";

import { ContactType } from "./types";

export const handleContact = (contact: EolasFile) => {
  const prefix = contact.info ?? "";

  switch (contact.type as ContactType) {
    case "email":
      return `mailto:${contact.description}`;
    case "telephone":
      return `tel:${contact.description}`;
    case "extension":
      return `tel:${prefix}${contact.description}`;
    case "pager":
    default:
      return "#";
  }
};
