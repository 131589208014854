export default {
  v: "5.5.7",
  meta: { g: "LottieFiles AE 0.1.21", a: "", k: "", d: "", tc: "#000000" },
  fr: 24,
  ip: 0,
  op: 104,
  w: 350,
  h: 250,
  nm: "Search",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Fields - 1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { t: 8, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.23, y: 1 },
                  o: { x: 0.77, y: 0 },
                  t: 0,
                  s: [176, 47.951, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 25, s: [176, 127.951, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-12, 18.951, 0], ix: 1 },
            s: { a: 0, k: [194, 194, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.711, 0],
                        [0, 0],
                        [0, -1.711],
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                      ],
                      o: [
                        [0, -1.711],
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                        [0, 0],
                        [-1.711, 0],
                        [0, 0],
                      ],
                      v: [
                        [-38.5, 14.098],
                        [-35.402, 11],
                        [11.773, 11],
                        [14.871, 14.098],
                        [14.871, 14.098],
                        [11.773, 17.195],
                        [-35.402, 17.195],
                        [-38.5, 14.098],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.796078443527, 0.796078443527, 0.796078443527, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.711, 0],
                        [0, 0],
                        [0, -1.711],
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                        [0, 0],
                        [-1.711, 0],
                        [0, 0],
                        [0, -1.711],
                      ],
                      v: [
                        [-35.402, 21.777],
                        [22.421, 21.777],
                        [25.519, 24.875],
                        [25.519, 24.875],
                        [22.421, 27.973],
                        [-35.402, 27.973],
                        [-38.5, 24.875],
                        [-38.5, 24.875],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.796078443527, 0.796078443527, 0.796078443527, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.105, 0],
                        [0, 0],
                        [0, -1.105],
                        [0, 0],
                        [1.105, 0],
                        [0, 0],
                        [0, 1.105],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.105, 0],
                        [0, 0],
                        [0, 1.105],
                        [0, 0],
                        [-1.105, 0],
                        [0, 0],
                        [0, -1.105],
                      ],
                      v: [
                        [-57.5, 11],
                        [-44.5, 11],
                        [-42.5, 13],
                        [-42.5, 26],
                        [-44.5, 28],
                        [-57.5, 28],
                        [-59.5, 26],
                        [-59.5, 13],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.729411780834, 0.729411780834, 0.729411780834, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.352, 0],
                        [0, 0],
                        [0, -2.352],
                        [0, 0],
                        [2.352, 0],
                        [0, 0],
                        [0, 2.352],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.352, 0],
                        [0, 0],
                        [0, 2.352],
                        [0, 0],
                        [-2.352, 0],
                        [0, 0],
                        [0, -2.352],
                      ],
                      v: [
                        [-62.241, 3.333],
                        [38.241, 3.333],
                        [42.5, 7.593],
                        [42.5, 30.309],
                        [38.241, 34.568],
                        [-62.241, 34.568],
                        [-66.5, 30.309],
                        [-66.5, 7.593],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.909803926945, 0.909803926945, 0.909803926945, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 222,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Fields - 2",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [100] },
                { t: 20, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.23, y: 1 },
                  o: { x: 0.77, y: 0 },
                  t: 0,
                  s: [176, 127.951, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 25, s: [176, 197.951, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-12, 18.951, 0], ix: 1 },
            s: { a: 0, k: [194, 194, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.711, 0],
                        [0, 0],
                        [0, -1.711],
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                      ],
                      o: [
                        [0, -1.711],
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                        [0, 0],
                        [-1.711, 0],
                        [0, 0],
                      ],
                      v: [
                        [-38.5, 14.098],
                        [-35.402, 11],
                        [11.773, 11],
                        [14.871, 14.098],
                        [14.871, 14.098],
                        [11.773, 17.195],
                        [-35.402, 17.195],
                        [-38.5, 14.098],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.796078443527, 0.796078443527, 0.796078443527, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.711, 0],
                        [0, 0],
                        [0, -1.711],
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.711, 0],
                        [0, 0],
                        [0, 1.711],
                        [0, 0],
                        [-1.711, 0],
                        [0, 0],
                        [0, -1.711],
                      ],
                      v: [
                        [-35.402, 21.777],
                        [22.421, 21.777],
                        [25.519, 24.875],
                        [25.519, 24.875],
                        [22.421, 27.973],
                        [-35.402, 27.973],
                        [-38.5, 24.875],
                        [-38.5, 24.875],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.796078443527, 0.796078443527, 0.796078443527, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.105, 0],
                        [0, 0],
                        [0, -1.105],
                        [0, 0],
                        [1.105, 0],
                        [0, 0],
                        [0, 1.105],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.105, 0],
                        [0, 0],
                        [0, 1.105],
                        [0, 0],
                        [-1.105, 0],
                        [0, 0],
                        [0, -1.105],
                      ],
                      v: [
                        [-57.5, 11],
                        [-44.5, 11],
                        [-42.5, 13],
                        [-42.5, 26],
                        [-44.5, 28],
                        [-57.5, 28],
                        [-59.5, 26],
                        [-59.5, 13],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.729411780834, 0.729411780834, 0.729411780834, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.352, 0],
                        [0, 0],
                        [0, -2.352],
                        [0, 0],
                        [2.352, 0],
                        [0, 0],
                        [0, 2.352],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.352, 0],
                        [0, 0],
                        [0, 2.352],
                        [0, 0],
                        [-2.352, 0],
                        [0, 0],
                        [0, -2.352],
                      ],
                      v: [
                        [-62.241, 3.333],
                        [38.241, 3.333],
                        [42.5, 7.593],
                        [42.5, 30.309],
                        [38.241, 34.568],
                        [-62.241, 34.568],
                        [-66.5, 30.309],
                        [-66.5, 7.593],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.909803926945, 0.909803926945, 0.909803926945, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 222,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Glass - 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.74], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 32, s: [92] },
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 64, s: [2.99] },
            { t: 98, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.74, y: 0 },
              t: 0,
              s: [288.578, 98.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.8, y: 0 },
              t: 32,
              s: [74.578, 167.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.8, y: 0 },
              t: 64,
              s: [249.631, 165.989, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 98, s: [288.578, 98.008, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [47.578, -14.992, 0], ix: 1 },
        s: { a: 0, k: [201, 201, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.779, -0.771],
                    [0, 0],
                    [0.791, -0.783],
                    [0, 0],
                    [0.779, 0.771],
                    [0, 0],
                    [-0.791, 0.783],
                  ],
                  o: [
                    [0.779, -0.771],
                    [0, 0],
                    [0.79, 0.783],
                    [0, 0],
                    [-0.779, 0.771],
                    [0, 0],
                    [-0.79, -0.783],
                    [0, 0],
                  ],
                  v: [
                    [54.493, -11.608],
                    [57.306, -11.607],
                    [64.563, -4.422],
                    [64.562, -1.579],
                    [61.005, 1.938],
                    [58.192, 1.938],
                    [50.935, -5.248],
                    [50.936, -8.091],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.27450980392156865, 0.34901960784313724, 0.6431372549019608, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [47.91, -14.59],
                    [54.804, -7.695],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.27450980392156865, 0.34901960784313724, 0.6431372549019608, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [22.033, 22.033], ix: 2 },
              p: { a: 0, k: [41.016, -21.484], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.27450980392156865, 0.34901960784313724, 0.6431372549019608, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.960784316063, 0.960784316063, 0.960784316063, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 222,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "Loop Container - 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [175, 125, 0], ix: 2 },
        a: { a: 0, k: [175, 125, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [300.914, 68.5],
                [54, 68.5],
                [54, 180],
                [300.914, 180],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      w: 350,
      h: 250,
      ip: 65,
      op: 110,
      st: 65,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Loop Container - 2",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [175, 125, 0], ix: 2 },
        a: { a: 0, k: [175, 125, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [300.914, 68.5],
                [54, 68.5],
                [54, 180],
                [300.914, 180],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      w: 350,
      h: 250,
      ip: 32,
      op: 65,
      st: 32,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: "Loop Container - 3",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [175, 125, 0], ix: 2 },
        a: { a: 0, k: [175, 125, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [300.914, 68.5],
                [54, 68.5],
                [54, 180],
                [300.914, 180],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      w: 350,
      h: 250,
      ip: 0,
      op: 33,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Sparkle - 2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 93, s: [100] },
            { t: 103, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 78, s: [0] },
            { t: 103, s: [-180] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [225.524, 69.17, 0], ix: 2 },
        a: { a: 0, k: [32.024, -44.33, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.19, 0.19, 0.19], y: [1, 1, 1] },
              o: { x: [0.81, 0.81, 0.81], y: [0, 0, 0] },
              t: 78,
              s: [0, 0, 100],
            },
            { t: 98, s: [49, 49, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.337, 1.205],
                    [0, 0],
                    [0.127, 0.038],
                    [0, 0],
                    [-0.357, 1.259],
                    [-0.791, 0.224],
                    [0, 0],
                    [-0.035, 0.13],
                    [0, 0],
                    [-0.854, 0.175],
                    [-0.337, -1.205],
                    [0, 0],
                    [-0.13, -0.031],
                    [0, 0],
                    [-0.029, -1.028],
                    [0.999, -0.329],
                    [0, 0],
                    [0.041, -0.127],
                    [0, 0],
                    [0.869, -0.167],
                  ],
                  o: [
                    [-1.221, 0.275],
                    [0, 0],
                    [-0.043, -0.125],
                    [0, 0],
                    [-1.259, -0.357],
                    [0.224, -0.791],
                    [0, 0],
                    [0.128, -0.045],
                    [0, 0],
                    [0.225, -0.842],
                    [1.221, -0.275],
                    [0, 0],
                    [0.036, 0.129],
                    [0, 0],
                    [0.995, 0.258],
                    [0.039, 1.051],
                    [0, 0],
                    [-0.124, 0.05],
                    [0, 0],
                    [-0.217, 0.858],
                    [0, 0],
                  ],
                  v: [
                    [32.61, -30.16],
                    [29.83, -31.82],
                    [27.61, -39.6],
                    [27.34, -39.86],
                    [19.54, -41.99],
                    [17.907, -44.917],
                    [19.54, -46.55],
                    [27.28, -48.77],
                    [27.54, -49.05],
                    [29.67, -56.84],
                    [31.43, -58.5],
                    [34.21, -56.84],
                    [36.43, -49.06],
                    [36.7, -48.8],
                    [44.5, -46.67],
                    [46.23, -44.5],
                    [44.61, -42.17],
                    [36.78, -39.94],
                    [36.52, -39.66],
                    [34.39, -31.87],
                    [32.61, -30.19],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.96862745098, 0.717647058824, 0.2, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 78,
      op: 110,
      st: 78,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Sparkle - 3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 57, s: [100] },
            { t: 73, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 42, s: [0] },
            { t: 73, s: [-180] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [295.024, 175.67, 0], ix: 2 },
        a: { a: 0, k: [32.024, -44.33, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.19, 0.19, 0.19], y: [1, 1, 1] },
              o: { x: [0.81, 0.81, 0.81], y: [0, 0, 0] },
              t: 42,
              s: [0, 0, 100],
            },
            { t: 62, s: [49, 49, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.337, 1.205],
                    [0, 0],
                    [0.127, 0.038],
                    [0, 0],
                    [-0.357, 1.259],
                    [-0.791, 0.224],
                    [0, 0],
                    [-0.035, 0.13],
                    [0, 0],
                    [-0.854, 0.175],
                    [-0.337, -1.205],
                    [0, 0],
                    [-0.13, -0.031],
                    [0, 0],
                    [-0.029, -1.028],
                    [0.999, -0.329],
                    [0, 0],
                    [0.041, -0.127],
                    [0, 0],
                    [0.869, -0.167],
                  ],
                  o: [
                    [-1.221, 0.275],
                    [0, 0],
                    [-0.043, -0.125],
                    [0, 0],
                    [-1.259, -0.357],
                    [0.224, -0.791],
                    [0, 0],
                    [0.128, -0.045],
                    [0, 0],
                    [0.225, -0.842],
                    [1.221, -0.275],
                    [0, 0],
                    [0.036, 0.129],
                    [0, 0],
                    [0.995, 0.258],
                    [0.039, 1.051],
                    [0, 0],
                    [-0.124, 0.05],
                    [0, 0],
                    [-0.217, 0.858],
                    [0, 0],
                  ],
                  v: [
                    [32.61, -30.16],
                    [29.83, -31.82],
                    [27.61, -39.6],
                    [27.34, -39.86],
                    [19.54, -41.99],
                    [17.907, -44.917],
                    [19.54, -46.55],
                    [27.28, -48.77],
                    [27.54, -49.05],
                    [29.67, -56.84],
                    [31.43, -58.5],
                    [34.21, -56.84],
                    [36.43, -49.06],
                    [36.7, -48.8],
                    [44.5, -46.67],
                    [46.23, -44.5],
                    [44.61, -42.17],
                    [36.78, -39.94],
                    [36.52, -39.66],
                    [34.39, -31.87],
                    [32.61, -30.19],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.96862745098, 0.717647058824, 0.2, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 42,
      op: 74,
      st: 42,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Sparkle - 4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [100] },
            { t: 47, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 16, s: [0] },
            { t: 47, s: [-180] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [41.024, 157.17, 0], ix: 2 },
        a: { a: 0, k: [32.024, -44.33, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.19, 0.19, 0.19], y: [1, 1, 1] },
              o: { x: [0.81, 0.81, 0.81], y: [0, 0, 0] },
              t: 16,
              s: [0, 0, 100],
            },
            { t: 36, s: [49, 49, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.337, 1.205],
                    [0, 0],
                    [0.127, 0.038],
                    [0, 0],
                    [-0.357, 1.259],
                    [-0.791, 0.224],
                    [0, 0],
                    [-0.035, 0.13],
                    [0, 0],
                    [-0.854, 0.175],
                    [-0.337, -1.205],
                    [0, 0],
                    [-0.13, -0.031],
                    [0, 0],
                    [-0.029, -1.028],
                    [0.999, -0.329],
                    [0, 0],
                    [0.041, -0.127],
                    [0, 0],
                    [0.869, -0.167],
                  ],
                  o: [
                    [-1.221, 0.275],
                    [0, 0],
                    [-0.043, -0.125],
                    [0, 0],
                    [-1.259, -0.357],
                    [0.224, -0.791],
                    [0, 0],
                    [0.128, -0.045],
                    [0, 0],
                    [0.225, -0.842],
                    [1.221, -0.275],
                    [0, 0],
                    [0.036, 0.129],
                    [0, 0],
                    [0.995, 0.258],
                    [0.039, 1.051],
                    [0, 0],
                    [-0.124, 0.05],
                    [0, 0],
                    [-0.217, 0.858],
                    [0, 0],
                  ],
                  v: [
                    [32.61, -30.16],
                    [29.83, -31.82],
                    [27.61, -39.6],
                    [27.34, -39.86],
                    [19.54, -41.99],
                    [17.907, -44.917],
                    [19.54, -46.55],
                    [27.28, -48.77],
                    [27.54, -49.05],
                    [29.67, -56.84],
                    [31.43, -58.5],
                    [34.21, -56.84],
                    [36.43, -49.06],
                    [36.7, -48.8],
                    [44.5, -46.67],
                    [46.23, -44.5],
                    [44.61, -42.17],
                    [36.78, -39.94],
                    [36.52, -39.66],
                    [34.39, -31.87],
                    [32.61, -30.19],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.96862745098, 0.717647058824, 0.2, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 16,
      op: 48,
      st: 16,
      bm: 0,
    },
  ],
  markers: [],
};
