import { MP4Icon, PDFIcon, DomainIcon, MSOfficeIcon } from "Assets";
import { DownloadFileButton } from "Components";
import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";
import { ShareFileButton } from "./shared/ShareFileButton";
import { FavouriteToggle } from "UIKit/Button";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { AppLevelSection } from "@eolas-medical/core";

const iconSize = { height: 40, width: 40 };

type LeftIcon = "pdf" | "mp4" | "link" | "ms-office";

const leftIcon: Record<LeftIcon, React.ReactNode> = {
  pdf: <PDFIcon {...iconSize} />,
  mp4: <MP4Icon {...iconSize} />,
  link: <DomainIcon {...iconSize} />,
  "ms-office": <MSOfficeIcon {...iconSize} />,
};

export const WellbeingFileTile: React.FC<FileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  onShareFile,
  onSelectFile = () => {
    return;
  },
  showRemoveFavouriteModal,
  ...handlers
}: FileTileProps) => {
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);

  const shouldShowShareButton = !!onShareFile && !isAdmin;
  const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;

  return (
    <FileTileWrapper
      className="h-40"
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <span className="flex justify-center items-center">
        {leftIcon[eolasFile.type as LeftIcon]}
      </span>

      <div className="flex flex-col flex-1 h-full self-start">
        <span className="font-semibold text-md lg:text-lg line-clamp-1 leading-10">
          {eolasFile.name}
        </span>
        <span className="text-grey-dark text-sm lg:text-md line-clamp-3">
          {eolasFile.description}
        </span>
      </div>
      <div className="w-fit self-start flex flex-col items-center justify-start h-full">
        {shouldShowFavouriteToggle && (
          <FavouriteToggle
            entityId={eolasFile.id}
            onClick={() =>
              favouriteId && showRemoveFavouriteModal
                ? showRemoveFavouriteModal({
                    entityId: eolasFile.id,
                    favouriteId,
                    title: eolasFile.name,
                    originForAnalytics: AppLevelSection.wellbeing,
                    entityType: FavouriteEntityType.FILE,
                  })
                : handleAddFavourite({
                    item: eolasFile,
                    entityType: FavouriteEntityType.FILE,
                  })
            }
            isLoading={!!isMarkingAsFavourite}
          />
        )}
        <DownloadFileButton eolasFile={eolasFile} />
        {shouldShowShareButton && (
          <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
        )}
      </div>
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
