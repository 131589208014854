import { useMutation } from "@tanstack/react-query";
import { AppLevelSection, sectionStore, contentClient } from "@eolas-medical/core";
import { removeFile } from "clients/files/files.client";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useRefetchAppData } from "Hooks";

const useRemoveTeamMember = () => {
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const handleRemoveTeamMember = async (teamMemberId: string) => {
    const parentID = sectionStore.getChildReferenceByMainSectionType(
      AppLevelSection.meetTheTeam,
    )!.id;

    return useAppServicesEndpoints
      ? removeFile({
          parentID,
          fileId: teamMemberId,
        })
      : contentClient.deleteContentItem({ mainSectionId: parentID, itemId: teamMemberId });
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(handleRemoveTeamMember, {
    onSuccess: refetch,
  });

  return {
    removeTeamMember: mutate,
    removingTeamMember: isLoading,
    removeTeamMemberError: error instanceof Error ? error.message : "",
    removeTeamMemberSuccessful: isSuccess,
  };
};

export default useRemoveTeamMember;
