import { useMemo } from "react";
import SessionContext from "./session.context";
import { useLaunchDarkly } from "Contexts";
import { useIdleTimer } from "react-idle-timer";
import { authenticationClient, userStore } from "@eolas-medical/core";
import { logout } from "Pages/Authentication/auth.actions";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { webStore } from "Stores/WebStore";

// 8 hours
export const SESSION_TIMEOUT = 28800000;

interface SessionProviderProps {
  children: React.ReactNode;
}

export const SessionProvider = observer(({ children }: SessionProviderProps) => {
  const { client } = useLaunchDarkly();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    if (!userStore.userSession.isLoggedIn) {
      return;
    }
    client?.close();
    webStore.setAppLastFetchTimestamp(null);
    if (authenticationClient.isUserFederated || !userStore.userData.email) {
      logout();
      queryClient.invalidateQueries();
    } else {
      userStore.setLoggedStatus(false);
      if (document.hidden) {
        webStore.setShouldReloadWindow(true);
      }
    }
  };

  useIdleTimer({
    timeout: SESSION_TIMEOUT,
    onIdle: handleLogout,
    debounce: 1000,
  });

  const contextValue = useMemo(() => {
    return {
      clearSession: () => {
        logout();
        queryClient.clear();
      },
    };
  }, [queryClient]);

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
});
