import { DownloadFileButton } from "Components";
import { format } from "date-fns";

import { DefaultFileControls, RegularFileWrapper, FileTileProps } from "./shared";
import { ShareFileButton } from "./shared/ShareFileButton";
import { FavouriteToggle } from "UIKit/Button";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { AppLevelSection } from "@eolas-medical/core";

/**
 * This component is used for space patient leaflets
 */
export const PatientLeafletFileTile: React.FC<FileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  onShareFile,
  showRemoveFavouriteModal,
  onSelectFile = () => {
    return;
  },
  ...handlers
}: FileTileProps) => {
  const { name, info, description, updatedAt } = eolasFile;
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);

  const shouldShowShareButton = !isAdmin && onShareFile;
  const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;
  return (
    <RegularFileWrapper
      className="h-40"
      canDrag={canDrag}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col justify-between flex-1 h-full">
        <span className="font-semibold text-lg line-clamp-1 leading-10">{name}</span>
        <span className="text-grey-dark text-sm lg:text-md line-clamp-2">{description}</span>
        <div className="flex flex-col pt-2">
          <span className="text-sm text-grey-dark line-clamp-1">{info}</span>
          <span className="text-sm text-grey-dark">
            {format(new Date(updatedAt as string), "do MMMM yyyy, HH:mm")}
          </span>
        </div>
      </div>

      <div className="w-fit self-start flex flex-col items-center justify-start h-full">
        {shouldShowFavouriteToggle ? (
          <FavouriteToggle
            entityId={eolasFile.id}
            isLoading={!!isMarkingAsFavourite}
            onClick={() =>
              favouriteId && showRemoveFavouriteModal
                ? showRemoveFavouriteModal({
                    entityId: eolasFile.id,
                    favouriteId,
                    title: eolasFile.name,
                    entityType: FavouriteEntityType.FILE,
                    originForAnalytics: AppLevelSection.patientLeaflets,
                  })
                : handleAddFavourite({
                    item: eolasFile,
                    entityType: FavouriteEntityType.FILE,
                  })
            }
          />
        ) : null}
        <DownloadFileButton eolasFile={eolasFile} />
        {shouldShowShareButton ? (
          <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
        ) : null}
      </div>

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </RegularFileWrapper>
  );
};
