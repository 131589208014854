import { OrganisationLevelSection, sectionStore } from "@eolas-medical/core";
import { removeFromFileDbCallback } from "Pages/Spaces/pages/Space/functions/fetchCallbacks";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { localSearchStore } from "Stores/localSearch/localSearch.store";

export const deleteLegacyMedusaFiles = async () => {
  const medusaMainSectionId = sectionStore.getChildReferenceByMainSectionType(
    OrganisationLevelSection.medusaMedications,
  )?.id;
  const res = (await contentDbStore.getAllItemsAsSummaries()).filter(
    ({ mainSectionId }) => mainSectionId === medusaMainSectionId,
  );
  if (res.length) {
    await removeFromFileDbCallback(await contentDbStore.getMultipleItems(res));
    await localSearchStore.persistDb();
  }
};
