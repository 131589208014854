import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { AppLevelSection, sectionStore, contentClient, userStore } from "@eolas-medical/core";
import { publishFile } from "clients/files/files.client";
import useUploadTeamMemberPhoto from "./useUploadTeamMemberPhoto";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useRefetchAppData } from "Hooks";

interface AddTeamMemberInput {
  name: string;
  role: string;
  bio: string;
  photo: string | File | null;
}

const useAddTeamMember = () => {
  const { uploadTeamMemberPhoto } = useUploadTeamMemberPhoto();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const handleAddTeamMember = useCallback(
    async ({ name, role, bio, photo }: AddTeamMemberInput) => {
      const parentID = sectionStore.getChildReferenceByMainSectionType(
        AppLevelSection.meetTheTeam,
      )!.id;

      const s3Key = await uploadTeamMemberPhoto(photo, parentID);

      return useAppServicesEndpoints
        ? publishFile({
            parentID,
            input: { name, description: role, infoTwo: bio, key: s3Key },
          })
        : contentClient.addContentItem(parentID, {
            name,
            description: role,
            infoTwo: bio,
            key: s3Key,
            parentId: parentID,
            createdBy: userStore.userID,
            type: "meetTheTeam",
          });
    },
    [uploadTeamMemberPhoto, useAppServicesEndpoints],
  );

  const { mutate, isLoading, isSuccess, error } = useMutation(handleAddTeamMember, {
    onSuccess: refetch,
  });

  return {
    addTeamMember: mutate,
    addingTeamMember: isLoading,
    addTeamMemberSuccessful: isSuccess,
    addTeamMemberError: error instanceof Error ? error.message : "",
  };
};

export default useAddTeamMember;
