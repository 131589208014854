import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BlobContent } from "@eolas-medical/core";
import { Button, Modal, NavButton } from "UIKit";
import { isVideoType } from "Utilities/fileHelpers";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useFileDownload } from "shared/hooks/useFileDownload";
import VideoViewer from "./VideoViewer/VideoViewer";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import { EolasDocumentViewer } from "UIKit/EolasDocumentViewer/EolasDocumentViewer";

interface BlobViewerProps {
  blob: BlobContent;
  backBehaviour?: "goBack" | "goHome";
}

const BlobViewer = ({ blob, backBehaviour = "goBack" }: BlobViewerProps) => {
  const { t } = useTranslation();
  const { toggleDrawerHidden, drawerHidden } = useEolasNavigation();
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });

  useEffect(() => {
    toggleDrawerHidden(true);

    return () => {
      toggleDrawerHidden(false);
    };
  }, [toggleDrawerHidden]);

  const getFile = async () => {
    return {
      fileName: blob.name,
      fileUrl: blob.url || "",
      fileExt: blob.media.type,
    };
  };

  const { downloadFile } = useFileDownload({ getFileAsync: getFile });

  const handleDownloadFile = () => {
    downloadFile(blob.id, {
      onSuccess: () => {
        return;
      },
    });
  };

  if (!drawerHidden) {
    return null;
  }

  return (
    /* FIXME: this hack to wrap in a Modal is copied from DocumentViewer (used
     * for Space / Org files). It seems to prevent some other elements from interfering with the viewer,
     * specifically <BackgroundCover />. I do not want to change the rendering hierarchy at this time for
     * fear of breaking other parts of the app.
     */
    <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={handleGoBack}>
      <div className="h-100vh w-100vw" data-testid="blob-viewer">
        <div className="bg-grey-light py-1 px-2 flex flex-col justify-center gap-2">
          <div className="flex justify-center items-center space-x-3">
            <NavButton onClick={handleGoBack} />
            <Button size="sm" onClick={handleDownloadFile}>
              {t("general_download_original")}
            </Button>
          </div>
        </div>

        <div className="bg-grey-light max-h-90vh overflow-y-auto">
          {isVideoType(blob.media.type) ? (
            <VideoViewer video={blob} />
          ) : (
            <div className="h-90vh" data-testid="document-viewer">
              <EolasDocumentViewer
                reRenderBehaviour="onceOnMount"
                url={blob.url}
                fileName={`${blob.name}.${blob.media?.type || "pdf"}`}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BlobViewer;
