import { useCallback, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  AnalyticsEvents,
  AppLevelSection,
  CompletedContentStatus,
  EolasFile,
  EolasSearchPayload,
  sectionStore,
} from "@eolas-medical/core";

import { useCompleteFile, useRecordMainSectionVisit } from "Hooks";
import { Modal, NavButton, PageTitle, InnerPageWrapper, Button } from "UIKit";

import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { useNewsFeeds } from "../../data/useNewsFeeds";
import { NewsItemViewModal } from "Pages/Spaces/components/NewsItems";
import NewsFeedTile from "./components/NewsFeedTile/NewsFeedTile";
import MarkAllAsReadModal from "./components/MarkAllAsReadModal/MarkAllAsReadModal";
import { useShareFile } from "shared/hooks/useShareFile";
import { useSelectNewsFeedItemOnMount } from "../../hooks/useSelectNewsFeedItemOnMount";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import {
  EolasVersatileListContext,
  EolasVersatileListRenderItemProps,
} from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import EmptyLocalList from "shared/components/Lists/EmptyLocalList/EmptyLocalList";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";
import { trackEvent } from "API/Analytics";

type NewsFeedListContext = EolasVersatileListContext<
  EolasFile,
  {
    isShareItemEnabled: boolean;
    showRemoveFavouriteModal: (props: HandleClickRemoveFavouriteProps) => void;
    onClick: (newsItem: EolasFile) => void;
    handleOnShareFile: (newsItem: EolasFile) => void;
  }
>;

export const NewsFeed = observer(() => {
  const { url } = useRouteMatch();

  const { id: mainSectionId } = sectionStore.getChildReferenceByMainSectionType(
    AppLevelSection.newsFeed,
  ) ?? {
    id: "",
  };

  useRecordMainSectionVisit({ mainSectionId, visitFrom: "directNavigation" });

  const { t } = useTranslation();
  const { spaceSection } = useSpaceSection(AppLevelSection.newsFeed);
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { onCompleteFile } = useCompleteFile();

  const { files, isFetching } = useNewsFeeds();

  const handleClickNewsFeed = useCallback(
    (newsItem: EolasFile) => {
      // If this condition is true, there are no attachments and we can mark the news item as seen
      if (newsItem.type === "newsFeed" || !newsItem.type) {
        onCompleteFile(newsItem.id, CompletedContentStatus.SEEN);
      }
      setModal(<NewsItemViewModal newsItem={newsItem} />);
    },
    [onCompleteFile],
  );

  useSelectNewsFeedItemOnMount({ onSelectFile: handleClickNewsFeed });

  const { searchInstanceId, handleClearSearch, handleSearchClick, searchResults, isSearchActive } =
    useLocalMobxSearch({
      data: files,
      keysToSearch: ["name", "createdBy"],
      analyticsCallback: (text, hits) => {
        trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
          searchString: text,
          searchType: "Item",
          origin: AppLevelSection.newsFeed,
          hitCount: hits,
        });
      },
    });

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

  const handleCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }

    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
    setModal(null);
  };

  const listContext: NewsFeedListContext = useMemo(
    () => ({
      isInitialLoading: isFetching,
      showRemoveFavouriteModal,
      isShareItemEnabled: isShareFileEnabled,
      onClick: handleClickNewsFeed,
      handleOnShareFile,
    }),
    [
      isFetching,
      showRemoveFavouriteModal,
      isShareFileEnabled,
      handleClickNewsFeed,
      handleOnShareFile,
    ],
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("newsFeed_title")} />
        <NavButton to={url.replace(`/${AppLevelSection.newsFeed}`, "")} />

        <Button
          color="blue"
          variant="outline"
          iconLeft="TickIcon"
          onClick={() =>
            setModal(
              <MarkAllAsReadModal onClose={handleCloseModal} mainSectionId={spaceSection.id} />,
            )
          }
          className="self-center w-full sm:w-1/3 "
        >
          {t("newsFeed_complete_all")}
        </Button>

        <div data-testid="news-item-list">
          <EolasVersatileList
            context={listContext}
            items={isSearchActive ? searchResults : files}
            disabledSortMethods={["favourites", "dragAndDrop"]}
            renderItem={ListItem}
            isSearchable
            isSortable={!isSearchActive}
            searchInstanceId={searchInstanceId}
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            searchMode="click"
            defaultSortMethod={"date"}
            sortDateBy="createdAt"
            className="space-y-6"
            ListEmptyComponent={isSearchActive ? NoSearchResults : undefined}
          />
        </div>
      </InnerPageWrapper>

      <Modal open={isModalOpen} onClose={handleCloseModal} shouldCloseOnOverlayClick={false}>
        {modal || shareModal || removeFavouriteModal}
      </Modal>
    </>
  );
});

const ListItem = ({
  item,
  context,
}: EolasVersatileListRenderItemProps<EolasFile, NewsFeedListContext>) => {
  const { handleOnShareFile, showRemoveFavouriteModal, isShareItemEnabled, onClick } = context;
  return (
    <NewsFeedTile
      newsFeed={item}
      onClick={onClick}
      onShare={isShareItemEnabled ? () => handleOnShareFile(item) : undefined}
      showRemoveFavouriteModal={showRemoveFavouriteModal}
    />
  );
};

const NoSearchResults = () => <EmptyLocalList isSearchResult />;
