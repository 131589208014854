import React from "react";

import { NotificationsContextProps } from "./types";

export const NotificationsContext = React.createContext<NotificationsContextProps>({
  notifications: [],
  hideNotification: () => {
    return;
  },
  updateNotification: () => {
    return;
  },
  showNotification: () => ({ id: "abc" }),
});

export const useNotifications = () => {
  const context = React.useContext(NotificationsContext);
  if (!context) {
    throw new Error("useNotifications must be used within a Notifications Provider");
  }

  return context;
};
