import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "UIKit/Button/Button";
import { FormElement } from "UIKit/FormElements/FormElement";
import { Input } from "UIKit/FormElements/Input/Input";
import * as Yup from "yup";

export type FormValues = {
  spaceName: string;
  organisationName: string;
};

const DEFAULT_VALUES: FormValues = {
  spaceName: "",
  organisationName: "",
};

type Props = {
  entity: "space" | "organisation";
  onValidSubmit: (formValues: FormValues) => void;
  isLoading: boolean;
};

export const CreateSpaceZapierForm = ({ onValidSubmit, entity, isLoading }: Props) => {
  const { t } = useTranslation();

  const validationSchema: Yup.SchemaOf<FormValues> = useMemo(
    () =>
      Yup.object({
        spaceName: Yup.string()
          .trim()
          .required(t("form_field_required", { field: t("selectSpace_create_space_name_label") }))
          .min(
            3,
            t("form_field_min_length", {
              field: t("selectSpace_create_space_name_label"),
              length: 3,
            }),
          ),
        organisationName: Yup.string()
          .trim()
          .required(t("form_field_required", { field: t("selectSpace_create_org_name_label") }))
          .min(
            3,
            t("form_field_min_length", {
              field: t("selectSpace_create_org_name_label"),
              length: 3,
            }),
          ),
      }),
    [t],
  );

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  return (
    <form onSubmit={handleSubmit(onValidSubmit)} className="flex flex-col justify-center w-full">
      <FormElement
        required
        label={t("selectSpace_create_space_name_label")}
        id="spaceName"
        control={control}
        renderError
      >
        {(field) => (
          <Input placeholder={`${t("selectSpace_create_space_name_label")}...`} {...field} />
        )}
      </FormElement>
      <FormElement
        required
        label={t("selectSpace_create_org_name_label")}
        id="organisationName"
        control={control}
        renderError
      >
        {(field) => (
          <Input placeholder={`${t("selectSpace_create_org_name_label")}...`} {...field} />
        )}
      </FormElement>
      <Button
        type="submit"
        variant="solid"
        size="lg"
        className="mt-4"
        isLoading={isLoading}
        disabled={isLoading}
      >
        {t("selectSpace_create_button", { entity })}
      </Button>
    </form>
  );
};
