import { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { contentDbStore } from "../stores/contentDb/contentDb.store";
import {
  ChildReference,
  EolasFile,
  hasProp,
  hasStringProp,
  sectionStore,
} from "@eolas-medical/core";
import { uniqueId } from "lodash";
import { stringToHash } from "Utilities/helpers";

const getQueryKey = ({
  id,
  type,
  hookId,
  timestamp,
}: {
  id?: string | null;
  hookId: string;
  type: "single" | "multiple";
  timestamp: string | null;
}) => ["getContentFileFromDb", id, hookId, type, timestamp];

const emptyFileArr: EolasFile[] = [];

export const useGetFiles = (
  params:
    | {
        overrideChildrenOrder?: ChildReference[];
        sectionId: string;
      }
    | { childrenOrder: ChildReference[] },
) => {
  const [hookId] = useState(uniqueId);

  const { lastUpdatedTimestamp: contentDbTimestamp } = contentDbStore;
  const {
    timestamps: { general },
  } = sectionStore;

  const lastUpdatedTimestamp =
    contentDbTimestamp && general ? contentDbTimestamp + general : general ?? contentDbTimestamp;

  let sectionId: string | null = null;
  let overrideChildrenOrder: ChildReference[] | null = null;
  let childrenOrder: ChildReference[] = [];

  if (hasStringProp(params, "sectionId")) {
    sectionId = params.sectionId;
    overrideChildrenOrder = params.overrideChildrenOrder ?? null;
  }

  if (hasProp(params, "childrenOrder") && params.childrenOrder.length && lastUpdatedTimestamp) {
    sectionId = stringToHash(
      params.childrenOrder.reduce((acc, current) => {
        return acc + current.id;
      }, ""),
    );
    childrenOrder = params.childrenOrder;
  }

  const queryClient = useQueryClient();

  const { data: filesFromQuery, isFetching } = useQuery({
    enabled: Boolean((sectionId || childrenOrder.length) && lastUpdatedTimestamp),
    queryKey: getQueryKey({
      hookId,
      id: sectionId,
      type: "multiple",
      timestamp: lastUpdatedTimestamp,
    }),
    queryFn: async () => {
      if (!lastUpdatedTimestamp || !sectionId) {
        return null;
      }
      const sectionChildrenOrder = childrenOrder.length
        ? childrenOrder
        : sectionStore.getChildrenOrder(sectionId);
      if (!sectionChildrenOrder.length) {
        return { ordered: [], asObjectMap: {} };
      }
      const ordered = await contentDbStore.getMultipleItems(sectionChildrenOrder);
      return {
        ordered,
        asObjectMap: childrenOrder.length
          ? {}
          : ordered.reduce<Record<string, EolasFile>>((acc, current) => {
              return { ...acc, [current.id]: current };
            }, {}),
      };
    },
  });

  useEffect(() => {
    return () =>
      queryClient.removeQueries(
        getQueryKey({ id: sectionId, type: "multiple", timestamp: lastUpdatedTimestamp, hookId }),
      );
  }, [queryClient, sectionId, lastUpdatedTimestamp, hookId]);

  const files = useMemo(() => {
    /* This logic exists to allow for reordering via drag and drop,
     * as otherwise the UI experience is very jarring due to the nature
     * of waiting for the new query to refetch the files. If overrideChildrenOrder is given
     * by a parent component, this order sets the order of the files returned, not what is
     * saved in the stores.
     */
    if (!filesFromQuery) {
      return null;
    }
    if (!overrideChildrenOrder?.length) {
      return filesFromQuery.ordered;
    }
    const newOrdered: EolasFile[] = [];
    for (const ref of overrideChildrenOrder) {
      const file = filesFromQuery.asObjectMap[ref.id];
      if (file) {
        newOrdered.push(file);
      }
    }
    return newOrdered;
  }, [filesFromQuery, overrideChildrenOrder]);

  return { files: files ?? emptyFileArr, isFetching };
};

type UseGetFileProps = {
  id?: string | null;
  options?: { shouldFetchOnMountOnly?: boolean; isEnabled?: boolean };
};

export const useGetFile = ({ id, options }: UseGetFileProps) => {
  const [hookId] = useState(uniqueId);
  const { lastUpdatedTimestamp } = contentDbStore;

  const queryClient = useQueryClient();

  const timestampForQueryKey = options?.shouldFetchOnMountOnly ? null : lastUpdatedTimestamp;
  const { isEnabled = true } = options ?? {};

  const { data, isFetching } = useQuery({
    enabled: Boolean(id && lastUpdatedTimestamp && isEnabled),
    queryKey: getQueryKey({ id, type: "single", timestamp: timestampForQueryKey, hookId }),
    queryFn: async () => {
      if (!id || !lastUpdatedTimestamp) {
        return null;
      }
      return await contentDbStore.getItem(id);
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries(
        getQueryKey({ id, type: "single", timestamp: timestampForQueryKey, hookId }),
      );
    };
  }, [id, queryClient, timestampForQueryKey, hookId]);

  return { file: data ?? null, isFetching };
};
