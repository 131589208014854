import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

import { ChooseFileTypeItem, SupportedPickerType, WizardState } from "../types";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { DomainIcon, DsmIcon, EolasEditorIcon, FilesIcon, FlashcardIcon } from "Assets";
import { SelectTypeComponent } from "../components/SelectTypeComponent";
import { makeInitialWizardState } from "../functions/makeInitialWizardState";
import { modalStore } from "Stores/ModalStore";
import { getInitialType } from "../functions/getInitialType";
import { ManageContentItemChildModalProps } from "../ManageContentItemChildModal";

export const useSelectTypeComponent = ({
  setWizardState,
  wizardState,
  ...modalProps
}: {
  setWizardState: Dispatch<SetStateAction<WizardState | null>>;
  wizardState: WizardState | null;
} & ManageContentItemChildModalProps) => {
  const [selectedType, updateSelectedTypeState] = useState<SupportedPickerType>(() => {
    const initialState = makeInitialWizardState(modalProps);
    if (initialState?.isExistingFileWithNewType === false) {
      return getInitialType(initialState?.type);
    }
    return "blob";
  });

  const { flags } = useLaunchDarkly();

  const { t } = useTranslation();

  const isFlashcardsActive = Boolean(flags[LDFlagNames.FLASHCARDS]);
  const isEditorActive = Boolean(flags[LDFlagNames.EOLAS_EDITOR]);
  const isDsmEditingAllowed = Boolean(flags[LDFlagNames.DSM_EDITING]);

  const typesToRender: ChooseFileTypeItem[] = useMemo(() => {
    const newTypes: ChooseFileTypeItem[] = [
      { type: "blob", icon: FilesIcon, label: t("filePicker_blob") },
      {
        type: "link",
        icon: DomainIcon,
        label: t("filePicker_link"),
      },
    ];
    if (isFlashcardsActive) {
      newTypes.push({
        type: "flashcard",
        icon: FlashcardIcon,
        label: t("filePicker_flashcard"),
      });
    }
    if (isEditorActive) {
      newTypes.push({
        type: "eolas-editor",
        icon: EolasEditorIcon,
        label: t("filePicker_editor"),
      });
    }
    if (isDsmEditingAllowed) {
      newTypes.push({
        type: "dsm",
        icon: DsmIcon,
        label: t("filePicker_dsm"),
      });
    }
    return newTypes;
  }, [isFlashcardsActive, isEditorActive, isDsmEditingAllowed, t]);

  const isUnavailableType = !typesToRender.find(({ type }) => type === selectedType);

  const setSelectedType = (newType: SupportedPickerType) => {
    if (newType === selectedType) {
      return;
    }
    if (wizardState?.existingDraftStatus === "parallel") {
      modalStore.openModal({
        variant: "dialogue",
        dialogType: "confirmOnly",
        layoutType: "stacked",
        name: "cannotSwitchTypeModal",
        icon: "warning",
        title: t("repository__cannot_change_type_title"),
        message: t("repository_cannot_change_type_desc"),
        confirmText: t("general_ok"),
        isDismissible: true,
      });
      return;
    }
    if (modalProps.file || !isEqual(wizardState, makeInitialWizardState(modalProps))) {
      modalStore.openModal({
        variant: "dialogue",
        layoutType: "stacked",
        name: "switchTypeModal",
        icon: "warning",
        title: t("repository_change_type_title"),
        message: t("repository_change_type_desc"),
        onConfirm: () => {
          const newState = makeInitialWizardState({
            ...modalProps,
            resetType: modalProps.file ? newType : undefined,
          });
          if (!newState) {
            return;
          }
          if (wizardState?.isExistingFileWithNewType === false && wizardState?.dto.name) {
            if (newState.isExistingFileWithNewType) {
              newState.dto.newProperties.name = wizardState.dto.name;
            } else {
              newState.dto.name = wizardState.dto.name;
            }
          }
          setWizardState(newState);
          updateSelectedTypeState(newType);
        },
      });
      return;
    }
    updateSelectedTypeState(newType);
  };

  const selectTypeComponent = isUnavailableType ? null : (
    <SelectTypeComponent
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      typesToRender={typesToRender}
    />
  );

  return { selectTypeComponent, selectedType };
};
