import { useCallback } from "react";
import { SearchSortList, SearchSortListItem } from "../SearchSortList";
import { LoadMore } from "UIKit";

interface PaginatedItemsListProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onSearchInputChange: (searchText: string) => void;
  onClickSearch: () => void;
  onClearSearch: () => void;
  isLoading?: boolean;
  hasMoreItems?: boolean;
  isFetchingMoreItems?: boolean;
  onFetchMoreItems?: () => void;
  searchValue: string;
}

export const PaginatedItemsList = <T extends SearchSortListItem>({
  items,
  isLoading = false,
  hasMoreItems = false,
  isFetchingMoreItems = false,
  searchValue,
  renderItem,
  onClickSearch,
  onSearchInputChange,
  onClearSearch,
  onFetchMoreItems,
}: PaginatedItemsListProps<T>) => {
  const handleRenderItem = useCallback(
    (item: T) => {
      return renderItem(item);
    },
    [renderItem],
  );

  return (
    <div data-testid="paginated-items-list">
      <SearchSortList<T>
        items={items}
        isSearchable
        searchType="click"
        renderItem={handleRenderItem}
        onSearchInputChange={onSearchInputChange}
        onClickSearch={onClickSearch}
        onClearSearch={onClearSearch}
        isLoading={isLoading}
        value={searchValue}
      />

      {hasMoreItems && (
        <LoadMore
          isLoading={isFetchingMoreItems || false}
          onLoadMore={() => onFetchMoreItems && onFetchMoreItems()}
        />
      )}
    </div>
  );
};
