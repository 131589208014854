import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import {
  NationalResourceEntityType,
  EolasFile,
  NationalResourcesClickPayload,
  AnalyticsEvents,
} from "@eolas-medical/core";

import { useSelectFile } from "Hooks";
import { NavButton, PageTitle, InnerPageWrapper, Modal, Loader } from "UIKit";

import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { CommunitySectionType } from "../../ClinicalCalculators/types";
import { trackEvent } from "API/Analytics";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useGetNationalResourceByNationalBody } from "modules/nationalResources/data/useGetNationalResourceByNationalBody";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";

const NationalGuidelinesBody: React.FC<RouteComponentProps> = observer(() => {
  const { onSelectFile } = useSelectFile();

  const history = useHistory();

  const location = useLocation();
  const { body } = (location.state as { body: string }) || {};

  const { t } = useTranslation();

  const {
    searchInstanceId,
    handleClearSearch,
    handleSearchClick,
    flatListData,
    gettingNationalResources,
    isSearchLoading,
  } = useGetNationalResourceByNationalBody({
    entityType: NationalResourceEntityType.GUIDELINE,
    nationalBody: body,
  });

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();
  const {
    showRemoveFavouriteModal,
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);

  const handleClickGuideline = React.useCallback(
    (guideline: EolasFile) => {
      trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
        nationalResourceType: NationalResourceEntityType.GUIDELINE,
        resourceName: guideline.name,
      } as NationalResourcesClickPayload);
      onSelectFile(guideline);
    },
    [onSelectFile],
  );

  const renderItem = useCallback(
    ({ item }: { item: EolasFile }) => (
      <div className="mb-3">
        <CommunityListItem
          eolasFile={item}
          onSelectFile={handleClickGuideline}
          sectionType={CommunitySectionType.NATIONAL_RESOURCE}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
          onShareFile={isShareFileEnabled ? () => handleOnShareFile(item) : undefined}
        />
      </div>
    ),
    [handleClickGuideline, showRemoveFavouriteModal, isShareFileEnabled, handleOnShareFile],
  );

  return (
    <>
      <InnerPageWrapper>
        <Modal open={isModalOpen} onClose={onCloseModal}>
          {shareModal || removeFavouriteModal}
        </Modal>
        <PageTitle title={t("community_nationalGuidelines_title")} />
        <NavButton onClick={() => history.goBack()} />
        {gettingNationalResources ? (
          <Loader className="p-16" />
        ) : (
          <EolasVersatileList
            keyExtractor={(item) => item.id}
            className="space-y-6"
            isSearchLoading={isSearchLoading}
            items={flatListData}
            renderItem={renderItem}
            isSearchable
            droppableId="content-section-list"
            searchMode="click"
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            searchInstanceId={searchInstanceId}
          />
        )}
      </InnerPageWrapper>
    </>
  );
});

export default NationalGuidelinesBody;
