import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { DebounceInput } from "react-debounce-input";
import { SearchMonoIcon } from "Assets";
import { twMerge } from "tailwind-merge";
import { SearchBoxSize } from "./types";

export interface SearchBoxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onChangeText: (text: string) => void;
  value?: string;
  placeholder?: string;
  icon?: React.ReactNode;
  showIcon?: boolean;
  autoFocus?: boolean;
  className?: string;
  "data-testid"?: string;
  rightSection?: React.ReactNode;
  debounceTimeout?: number;
  searchBoxSize?: SearchBoxSize;
  iconClassName?: string;
  inputClassName?: string;
}

const searchBoxSizes: Record<SearchBoxSize, string> = {
  md: "h-12",
  lg: "h-14",
  xl: "h-16",
};

export const SearchBox = forwardRef<HTMLInputElement, SearchBoxProps>(function SearchBox(
  {
    onChangeText,
    placeholder = "SearchBarPlaceholder",
    icon,
    showIcon = true,
    value,
    "data-testid": dataTestId = "searchbox-input",
    autoFocus,
    className,
    ref: _legacyRef,
    rightSection,
    debounceTimeout = 300,
    searchBoxSize = "md",
    iconClassName,
    inputClassName,
    ...otherProps
  }: SearchBoxProps,
  ref,
) {
  const { t } = useTranslation();

  const inputHeight = searchBoxSizes[searchBoxSize];
  const iconSize = searchBoxSize === "lg" ? "w-5 h-5" : "w-6 h-6";

  const iconComponent = (
    <div className="mr-2">
      {icon ? (
        icon
      ) : (
        <SearchMonoIcon
          className={twMerge("text-grey-600 self-center my-auto", iconSize, iconClassName)}
        />
      )}
    </div>
  );

  return (
    <div
      className={twMerge(
        "w-full border bg-white border-grey-300 h-12 rounded-lg items-center flex flex-row px-5",
        inputHeight,
        className,
      )}
    >
      {showIcon ? iconComponent : null}
      <DebounceInput
        inputRef={ref}
        autoFocus={autoFocus}
        minLength={1}
        debounceTimeout={debounceTimeout}
        value={value}
        data-testid={dataTestId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeText(e.target.value);
        }}
        className={twMerge("flex-1 bg-transparent self-center my-auto", inputClassName)}
        placeholder={t(placeholder)}
        {...otherProps}
      />

      {rightSection}
    </div>
  );
});
