import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  AnalyticsEvents,
  eolasLogger,
  EolasMainSection,
  hasProp,
  hasStringProp,
  Maybe,
  sectionStore,
  VisitPayload,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useRunOnMountUnmount } from "./useRunOnMountUnmount";

/**
 * @deprecated Change to useRecordMainSectionVisit or consider using the function recordMainSectionVisit in the onClick callback
 */
export const useMainSectionVisit = (payload: Omit<VisitPayload, "visitFrom">) => {
  const location = useLocation<{ from: VisitPayload["visitFrom"] }>();

  useEffect(() => {
    trackEvent(AnalyticsEvents.MAIN_SECTION_VISIT, {
      ...payload,
      visitFrom: location?.state?.from || "directNavigation",
    });
    // We only ever want this to be triggered once per main section load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

type Params = Partial<VisitPayload> &
  ({ mainSectionId: string } | { mainSectionType: EolasMainSection });

export const recordMainSectionVisit = (params: Params) => {
  let typeForAnalytics: string | null = null;
  if (hasProp(params, "mainSectionType")) {
    typeForAnalytics = params.mainSectionType;
  } else if (hasStringProp(params, "mainSectionId")) {
    typeForAnalytics = sectionStore.getMainSectionTypeFromMainSectionID(params.mainSectionId);
    const mainSectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(
      params.mainSectionId,
    );
    if (mainSectionIdentity === "genericContentRepository") {
      typeForAnalytics = mainSectionIdentity;
    }
  }
  if (!typeForAnalytics) {
    eolasLogger.warn("RecordMainSectionVisit: No main section type for analytics", {
      params,
    });
    typeForAnalytics = "unknownMainSection";
  }

  trackEvent(AnalyticsEvents.MAIN_SECTION_VISIT, {
    ...params,
    mainSectionId: typeForAnalytics,
    visitFrom: params.visitFrom ?? "directNavigation",
  });
};

export const useRecordMainSectionVisit = (params: Params) => {
  const location = useLocation<{ from?: VisitPayload["visitFrom"] }>();

  useRunOnMountUnmount({
    onMount: () => {
      let visitFrom: VisitPayload["visitFrom"] | null = null;
      if (isVisitFrom(location.state?.from)) {
        visitFrom = location.state.from;
      }
      const paramsToSend: Params = visitFrom ? { ...params, visitFrom } : params;
      recordMainSectionVisit(paramsToSend);
    },
  });
};

enum VisitFrom {
  "directNavigation" = "directNavigation",
  "masterSearch" = "masterSearch",
  "highlights" = "highlights",
  "pushNotification" = "pushNotification",
  "myFavourites" = "myFavourites",
  "deeplink" = "deeplink",
}

const isVisitFrom = (str: Maybe<string>): str is `${VisitFrom}` => {
  if (!str) {
    return false;
  }
  return Object.values<string>(VisitFrom).includes(str);
};
