import { sectionStore, Space, spacesClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";

interface UseGetSpace {
  space?: Space;
  isLoadingSpace: boolean;
}

/**
 * @deprecated
 * Get this data from mobx store instead (this is just duplicating logic)
 */
const useSpace = (spaceId: string | null): UseGetSpace => {
  const { data, isLoading } = useQuery(
    spacesKeys.detail(spaceId),
    () => {
      if (!spaceId) {
        throw new Error("useSpace: no spaceID. This should not happen");
      }
      return spacesClient.getSpace(spaceId);
    },
    {
      enabled: !!spaceId,
    },
  );

  /**
   * Temp: this is to prevent UI lags while fetching this data. This hook is still used
   * in many places but the data can just be retrieved from the persisted stores.
   */
  const mobxSpace = sectionStore.data.app as Space | undefined;

  return {
    space: data || mobxSpace,
    isLoadingSpace: isLoading,
  };
};

export default useSpace;
