/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { useIcons } from "Hooks";

import {
  EolasFile,
  sectionStore,
  AppLevelSection,
  ChecklistRecord,
  EolasMainSection,
  OrganisationLevelSection,
} from "@eolas-medical/core";

import { FileTileWrapper } from "UIKit";
import { HighlightedFileIcon } from "Assets";
import { formatDate } from "Utilities/general";

interface HighlightFileProps {
  file: EolasFile;
  onClick(file: EolasFile): void;
}

interface HighlightChecklistProps {
  checklist: ChecklistRecord;
  onClick(file: ChecklistRecord): void;
}

const iconSize = { height: 25, width: 25 };

export const HighlightFile: React.FC<HighlightFileProps> = ({
  file,
  onClick,
}: HighlightFileProps) => {
  const { getMainSectionIcon } = useIcons();

  const type: EolasMainSection = useMemo(() => {
    return sectionStore.getMainSectionTypeFromMainSectionID(
      file.mainSectionID!,
    )! as EolasMainSection;
  }, [file.mainSectionID]);

  const Icon = getMainSectionIcon(type);

  const { subSection, mainSection } = useMemo(() => {
    const sectionRef = sectionStore.getChildReferenceOfSection(file.parentID);
    let subSection, mainSection;
    if (sectionRef?.type === "subsection") {
      subSection = sectionRef.name;
    }
    if (sectionRef?.type === "mainsection") {
      mainSection = sectionRef.name;
    }
    return { subSection, mainSection };
  }, [file]);

  return (
    <FileTileWrapper
      eolasFile={file}
      className="relative"
      onClick={() => onClick(file)}
      isMedusaFile={type === OrganisationLevelSection.medusaMedications}
    >
      {Icon && <Icon width={32} height={32} />}
      <div className="flex flex-col flex-1 p-4">
        <span className="font-semibold text-lg">{file.name}</span>
        {mainSection && <span className="text-sm text-grey-dark">{mainSection}</span>}
        {subSection && <span className="text-sm text-grey-dark">{subSection}</span>}
        <span className="text-sm text-grey-dark">
          {file?.updatedAt ? formatDate(file?.updatedAt) : ""}
        </span>
      </div>
      <div className="absolute top-0 right-0">
        <CornerIcon highlighted={file?.highlighted || false} />
      </div>
    </FileTileWrapper>
  );
};

export const HighlightChecklist: React.FC<HighlightChecklistProps> = ({
  checklist,
  onClick,
}: HighlightChecklistProps) => {
  const { getMainSectionIcon } = useIcons();
  const Icon = getMainSectionIcon(AppLevelSection.checklists);

  const { subSection, mainSection } = useMemo(() => {
    const sectionRef = sectionStore.getChildReferenceOfSection(checklist.parentID!);
    let subSection, mainSection;
    if (sectionRef?.type === "subsection") {
      subSection = sectionRef.name;
    }
    if (sectionRef?.type === "mainsection") {
      mainSection = sectionRef.name;
    }
    return { subSection, mainSection };
  }, [checklist]);

  const { updatedAt, highlighted } = sectionStore.getSection(checklist.id!);

  return (
    <FileTileWrapper eolasFile={{} as any} className="relative" onClick={() => onClick(checklist)}>
      <Icon width={32} height={32} />
      <div className="flex flex-col flex-1 p-4">
        <span className="font-semibold text-lg">{checklist?.checklistName}</span>
        {mainSection && <span className="text-sm text-grey-dark">{mainSection}</span>}
        {subSection && <span className="text-sm text-grey-dark">{subSection}</span>}
        <span className="text-sm text-grey-dark">{formatDate(updatedAt!)}</span>
      </div>
      <div className="absolute top-0 right-0">
        <CornerIcon highlighted={highlighted || "false"} />
      </div>
    </FileTileWrapper>
  );
};

const CornerIcon = ({ highlighted }: { highlighted: string | boolean }) => {
  return (
    <HighlightedFileIcon className={highlighted ? "text-yellow-600" : "text-green"} {...iconSize} />
  );
};
