import { AppLevelSection, sectionStore } from "@eolas-medical/core";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

export const useNewsFeeds = () => {
  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.newsFeed) ?? {
    id: "",
  };

  const { files, isFetching } = useGetFiles({ sectionId: id });

  return { files, isFetching };
};
