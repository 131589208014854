import { contentClient, sectionStore } from "@eolas-medical/core";
import { contentDbStore } from "../stores/contentDb/contentDb.store";

export const getPreSignUrl = async ({
  fileId,
  versionNo,
  draftId,
}: {
  fileId: string;
  versionNo?: number;
  draftId?: string;
}) => {
  const file = await contentDbStore.getItem(fileId);

  const { getUrl } = await contentClient.queryGetPresignedUrl({
    organisationId: sectionStore.organisationID,
    spaceId: sectionStore.appID,
    sectionType:
      (file ? sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID) : "") ||
      "otherSectionType",
    fileId,
    versionNo,
    draftId,
  });
  return getUrl;
};
