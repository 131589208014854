/**
 * Transforms IDs in the HTML string by appending the question ID to reference IDs.
 * This is used to differentiate between identical reference numbers from different responses.
 *
 */

export const transformIdsInHtml = (htmlString: string, questionID: string): string => {
  return htmlString
    .replace(/href="#ref([^"]*)"/g, (_, p1) => {
      return `href="#${questionID}ref${p1}"`;
    })
    .replace(/id="ref([^"]*)"/g, (_, p1) => {
      return `id="${questionID}ref${p1}"`;
    });
};
