import { spacesClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";

type Props = {
  ownerId: string;
};

export const useIdentifyOwnerId = ({ ownerId }: Props) => {
  const { isLoading, data, isError, error } = useQuery({
    queryFn: () => spacesClient.identifyOwnerId({ ownerId }),
    queryKey: ["identifyOwnerId", ownerId],
  });

  return {
    isLoading,
    data: data || null,
    isError,
    error,
  };
};
