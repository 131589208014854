import { useHighlightFile } from "Hooks";
import { useCallback } from "react";
import { TeamMember } from "../types";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

const useHighLightTeamMember = () => {
  const { toggleHighlight, highlightLoading } = useHighlightFile();

  const handleToggleHighlight = useCallback(
    async (teamMember: TeamMember) => {
      const originalFile = await contentDbStore.getItem(teamMember.id);

      if (originalFile) {
        toggleHighlight(originalFile);
      }
    },
    [toggleHighlight],
  );

  return {
    toggleHighlight: handleToggleHighlight,
    highlightLoading,
  };
};

export default useHighLightTeamMember;
