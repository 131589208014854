import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, sectionStore } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const Checklists: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const { t } = useTranslation();
  const isAdmin = sectionStore.isAdmin;
  const { name, id: parentID } = sectionStore.getChildReferenceByMainSectionType(
    AppLevelSection.checklists,
  )!;
  const { modal, onAddSection, onCloseModal, onEditSection, onDeleteSection } =
    useSectionManagement(parentID);

  useMainSectionVisit({ mainSectionId: AppLevelSection.checklists });

  return (
    <InnerPageWrapper>
      <FileBreadcrumbs shouldShowSpaceName mainSectionId={parentID} />
      <PageTitle title={name ?? t(AppLevelSection.checklists)} />
      <NavButton to={url.replace(`/${AppLevelSection.checklists}`, "")} />
      {isAdmin ? (
        <AddButton onClick={onAddSection} data-testid="add-new-subSection">
          {t("addNewSection_button")}
        </AddButton>
      ) : null}
      <SectionList
        isAdmin={isAdmin}
        sectionID={parentID}
        onEdit={onEditSection}
        onAddItem={onAddSection}
        onDelete={onDeleteSection}
      />
      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </InnerPageWrapper>
  );
});
