import { PathOf } from "Utilities/types";
import Fuse from "fuse.js";

export type FuseSearchablePropertiesParam<T extends object> = Extract<
  /**
   * Children is deliberately omitted here as its common used within recursive code to reference an object of the same type as T,
   * this is not useful for search as it would be a circular reference and in fact typescript complains about it
   */
  PathOf<Omit<T, "children">>,
  string
>[];

const baseFuseConfig = {
  ignoreLocation: true,
  minMatchCharLength: 2,
  distance: 90,
  threshold: 0.15,
} as const;

export const fuseSearch = <T extends object>(
  searchQuery: string,
  filesToFilter: T[],
  searchableProperties: FuseSearchablePropertiesParam<T>,
) => {
  if (!filesToFilter.length) {
    return {
      results: filesToFilter,
      resultsWithMatches: filesToFilter.map((d) => ({ result: d, matches: [] })),
    };
  }

  const options: Fuse.IFuseOptions<T> = {
    keys: searchableProperties,
    ...baseFuseConfig,
  };

  const fuse = new Fuse<T>(filesToFilter, options);
  const fuseSearch = fuse.search(searchQuery);

  return {
    results: fuseSearch.map(({ item }) => item),
    resultsWithMatches: fuseSearch.map(({ item, matches }) => ({
      result: item,
      matches: matches,
    })),
  };
};
