/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, TypedDocumentNode } from "@apollo/client";
import { addBreadcrumb } from "@sentry/react";

type DefaultType = { [key: string]: any };

export const queryApollo = async <
  Result extends DefaultType,
  Variables extends DefaultType,
  Client extends ApolloClient<any>,
>(
  client: Client,
  {
    query,
    variables,
  }: {
    query: TypedDocumentNode<Result, Variables>;
    variables: Variables;
  },
) => {
  const { data } = await client.query({ query, variables });

  const queryName = (query.definitions[0] as any)?.selectionSet?.selections?.[0]?.name
    ?.value as string;
  if (!data) {
    addBreadcrumb({
      category: "Apollo query",
      message: `No data returned from apollo query, ${queryName}`,
    });
    throw new Error("Apollo query: no data.");
  }
  if (!queryName) {
    addBreadcrumb({
      category: "Apollo query",
      message: `Apollo query", "Query name not found, ${data}`,
    });
    throw new Error("Apollo query: no query name");
  }
  const result = data[queryName];

  if (!result) {
    addBreadcrumb({
      category: "Apollo query",
      message: `Apollo query", "No data returned from apollo query, ${data} , ${queryName}`,
    });
    throw new Error("Apollo query: no data");
  }
  return result;
};
