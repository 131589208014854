import { modalStore } from "Stores/ModalStore";
import { HandleClickRemoveFavouriteProps, RemoveFavouriteModal } from "../RemoveFavouriteModal";
import { useModalState } from "shared/hooks/useModalState/useModalState";

const modalName = "RemoveFavouriteModal";

export function useRemoveFavouriteModal(props: { shouldUseMobxModal: true }): {
  showRemoveFavouriteModal: (props: HandleClickRemoveFavouriteProps) => void;
};
export function useRemoveFavouriteModal(props: { shouldUseMobxModal: false }): {
  showRemoveFavouriteModal: (props: HandleClickRemoveFavouriteProps) => void;
  removeFavouriteModal: JSX.Element | null;
  closeFavouriteModal: () => void;
  isFavouriteModalOpen: boolean;
};
export function useRemoveFavouriteModal(): {
  showRemoveFavouriteModal: (props: HandleClickRemoveFavouriteProps) => void;
  removeFavouriteModal: JSX.Element | null;
  closeFavouriteModal: () => void;
  isFavouriteModalOpen: boolean;
};

export function useRemoveFavouriteModal({
  shouldUseMobxModal,
}: { shouldUseMobxModal?: boolean } = {}) {
  const { modal, openModal, closeModal, isOpen } = useModalState();

  const showRemoveFavouriteModal = (props: HandleClickRemoveFavouriteProps) => {
    if (shouldUseMobxModal) {
      modalStore.openModal({
        variant: "component",
        name: modalName,
        Component: (
          <RemoveFavouriteModal
            {...props}
            onCloseModal={() => {
              if (modalStore.modal?.name === modalName) {
                modalStore.closeModal();
              }
            }}
          />
        ),
      });
      return;
    }
    return openModal(<RemoveFavouriteModal {...props} onCloseModal={closeModal} />);
  };

  return {
    showRemoveFavouriteModal,
    removeFavouriteModal: modal,
    closeFavouriteModal: closeModal,
    isFavouriteModalOpen: isOpen,
  };
}
