import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import { searchBoxStore } from "Stores/SearchBoxStore";
import { ClickSearchBox } from "UIKit/SearchBox/ClickSearchBox/ClickSearchBox";
import { SearchBox } from "UIKit/SearchBox/SearchBox";
import { useTranslation } from "react-i18next";

type SearchMode = "onClick" | "debounced";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type BaseProps<M extends SearchMode, P extends Record<string, unknown> = {}> = {
  searchInstanceId: string;
  searchMode: M;
  placeholder?: string;
  isLoading?: boolean;
  onClearSearch?: () => void;
} & P;

type DebouncedProps = BaseProps<"debounced">;
type ClickProps = BaseProps<"onClick", { onClickSearch: (text: string) => void }>;

type SearchBoxProps = DebouncedProps | ClickProps;

export const SearchBoxMobx = observer((props: SearchBoxProps) => {
  const { searchInstanceId, searchMode, placeholder, isLoading = false, onClearSearch } = props;

  const { t } = useTranslation();
  const defaultPlaceHolder = t("SearchBarPlaceholder");

  const text = searchBoxStore.getText(searchInstanceId);

  const onChangeText = (newText: string) => {
    if (newText.length === 0 && onClearSearch) {
      onClearSearch();
    }
    searchBoxStore.setText(newText, searchInstanceId);
  };

  const onFocus = useCallback(() => {
    searchBoxStore.setIsFocused(true, searchInstanceId);
  }, [searchInstanceId]);

  const onBlur = useCallback(() => {
    searchBoxStore.setIsFocused(false, searchInstanceId);
  }, [searchInstanceId]);

  const handleClearSearch = () => {
    searchBoxStore.setText("", searchInstanceId);
    if (onClearSearch) {
      onClearSearch();
    }
  };

  if (searchMode === "onClick") {
    return (
      <ClickSearchBox
        value={text}
        onChangeText={onChangeText}
        onClickSearch={() => props.onClickSearch(text)}
        onClearSearch={handleClearSearch}
        placeholder={placeholder ?? defaultPlaceHolder}
        isLoading={isLoading}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  }

  return (
    <SearchBox
      onChangeText={onChangeText}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
});
