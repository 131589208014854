import { motion } from "framer-motion";

import { ClinicalQuestionIcon } from "Assets";
import { NewQuestionProps } from "./NewQuestionView";
import { FormElement, IconButton, Input, Loader } from "UIKit";
import { useFormState } from "react-hook-form";
import "../styles/Ecqs.css";

type QuestionBarProps = Pick<NewQuestionProps, "control" | "onSubmit" | "errorMessage"> & {
  placeholder: string;
  fieldName: string;
  onFocus?: () => void;
  onBlur?: () => void;
  isFocused: boolean;
  shouldShowBorderAnimation?: boolean;
};

export const QuestionBar = ({
  fieldName,
  control,
  onSubmit,
  errorMessage,
  placeholder,
  onFocus,
  onBlur,
  shouldShowBorderAnimation,
  isFocused,
}: QuestionBarProps) => {
  const { isSubmitting } = useFormState({ control });
  return (
    <form className="flex flex-col mb-8" onSubmit={onSubmit}>
      <FormElement required id={fieldName} control={control} errorMessage={errorMessage}>
        {(fieldProps, { isValid }) => (
          <motion.div
            onFocus={onFocus}
            onBlur={onBlur}
            transition={{ duration: 0.3 }}
            className={`rounded-md bg-white ${
              shouldShowBorderAnimation
                ? "border-animation"
                : isFocused
                ? "shadow-md border-blue-100 border-2"
                : "shadow-md border-grey-200 border-2"
            }`}
          >
            <Input
              {...fieldProps}
              size="xl"
              className={"border-transparent"}
              inputClassName="text-sm sm:text-base"
              disabled={isSubmitting}
              placeholder={placeholder}
              iconRight={
                <IconComponent
                  isSubmitting={Boolean(isSubmitting)}
                  isValid={Boolean(isValid)}
                  onSubmit={onSubmit}
                />
              }
            />
          </motion.div>
        )}
      </FormElement>
    </form>
  );
};

const IconComponent = ({
  onSubmit,
  isSubmitting,
}: {
  onSubmit: () => void;
  isValid: boolean;
  isSubmitting: boolean;
}) => {
  if (isSubmitting) {
    return <Loader className="mr-2" size={20} />;
  }
  return (
    <IconButton
      className="bg-blue-900"
      icon={<ClinicalQuestionIcon height={30} width={40} />}
      onClick={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    />
  );
};
