import { useFileOperations, useRefetchAppData } from "Hooks";
import { contentClient, EolasFile, eolasLogger } from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const useDeleteDepartmentArea = (department?: EolasFile) => {
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { removeFile } = useFileOperations();
  const { refetch } = useRefetchAppData();

  const {
    error: deleteError,
    isLoading: isDeleteLoading,
    isSuccessful: isDeleteSuccessful,
    setRequestStatus: setDeleteStatus,
  } = useRequestStatus();

  const onDelete = async () => {
    setDeleteStatus({ status: "pending", error: "" });
    try {
      useAppServicesEndpoints
        ? await removeFile({
            variables: {
              id: department?.id,
              parentID: department?.parentID,
            },
          })
        : contentClient.deleteContentItem({
            mainSectionId: department?.mainSectionID ?? "",
            itemId: department?.id ?? "",
          });
      refetch();
      setDeleteStatus({ status: "success", error: "" });
    } catch (err) {
      eolasLogger.error(err);
      setDeleteStatus({
        status: "error",
        error: err instanceof Error ? err.message : "An error ocurred",
      });
    }
  };

  return {
    onDelete,
    deleteError,
    isDeleteLoading,
    isDeleteSuccessful,
  };
};
