import {
  spaceSupportedContentDeepLinkTypes,
  orgSupportedContentDeepLinkTypes,
  knowledgeSupportedContentDeepLinkTypes,
} from "deeplinking/constants";
import {
  omittedOrgDeepLinkTypes,
  omittedSpaceDeepLinkTypes,
  omittedKnowledgeDeepLinkTypes,
} from "../constants";

/**
 * Defines the types of content that can be favourited in the space domain on web
 * Note: An item may still appear in the user favourites list even if it is not supported as it could be supported on mobile
 */
export const isSupportedSpaceContentFavouriteType = (type: string) => {
  if (
    Object.values<string>(spaceSupportedContentDeepLinkTypes).includes(type) &&
    !Object.values<string>(omittedSpaceDeepLinkTypes).includes(type)
  ) {
    return true;
  }
  return false;
};

/**
 * Defines the types of content that can be favourited in the organisation domain on web
 * Note: An item may still appear in the user favourites list even if it is not supported as it could be supported on mobile
 */
export const isSupportedOrganisationContentFavouriteType = (type: string) => {
  if (
    Object.values<string>(orgSupportedContentDeepLinkTypes).includes(type) &&
    !Object.values<string>(omittedOrgDeepLinkTypes).includes(type)
  ) {
    return true;
  }
  return false;
};

/**
 * Defines the types of content that can be favourited in the knowledge domain on web
 * Note: An item may still appear in the user favourites list even if it is not supported as it could be supported on mobile
 */
export const isSupportedKnowledgeContentFavouriteType = (type: string) => {
  if (
    Object.values<string>(knowledgeSupportedContentDeepLinkTypes).includes(type) &&
    !Object.values<string>(omittedKnowledgeDeepLinkTypes).includes(type)
  ) {
    return true;
  }
  return false;
};
