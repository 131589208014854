import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {
  AppLevelSection,
  EolasFile,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";

import { EolasFileList } from "Components";
import { SubSectionRouteParams } from "Utilities/types";
import { InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { useMainSectionVisit } from "Hooks";
import { ContactDetailsModal } from "../components";
import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useSelectContactItemOnMount } from "../hooks/useSelectContactItemOnMount";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const ClientContactsSection: React.FC<RouteComponentProps<SubSectionRouteParams>> = observer(
  ({ match: { url } }) => {
    const { activeTab } = useEolasNavigation();
    const [modal, setModal] = useState<React.ReactNode>(null);
    const { t } = useTranslation();
    const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
      useShareFile();

    const onSelectFile = useCallback((contact: EolasFile) => {
      setModal(<ContactDetailsModal contact={contact} />);
    }, []);

    useSelectContactItemOnMount({ onSelectFile });

    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();

    const { isOrganisationTab, mainSectionId } = useMemo(() => {
      const isOrganisationTab = activeTab === "organisation";
      return {
        isOrganisationTab,
        mainSectionId: isOrganisationTab
          ? OrganisationLevelSection.hospitalContacts
          : AppLevelSection.contacts,
      };
    }, [activeTab]);

    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const handleCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      setModal(null);
    };

    const { spaceSubSection } = useSpaceSubSection(mainSectionId);

    useMainSectionVisit({ mainSectionId, subSectionId: spaceSubSection.subSectionId });
    return (
      <>
        <InnerPageWrapper>
          <FileBreadcrumbs
            shouldShowSpaceName={true}
            mainSectionId={sectionStore.getChildReferenceByMainSectionType(mainSectionId)?.id}
            parentId={spaceSubSection.subSectionId}
          />
          <PageTitle title={spaceSubSection.subSectionName ?? ""} />
          <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

          <EolasFileList
            isAdmin={false}
            defaultSort="alphabetical"
            draggable={false}
            subSectionId={spaceSubSection.subSectionId}
            infoText={t("contacts_contact_order")}
            onSelectFile={onSelectFile}
            mainSectionId={
              isOrganisationTab
                ? OrganisationLevelSection.hospitalContacts
                : AppLevelSection.contacts
            }
            onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
            showRemoveFavouriteModal={showRemoveFavouriteModal}
          />

          <Modal open={isModalOpen} onClose={handleCloseModal}>
            {modal || shareModal || removeFavouriteModal}
          </Modal>
        </InnerPageWrapper>
      </>
    );
  },
);
