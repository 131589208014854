import { useCallback, useState, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Modal, NavButton } from "UIKit";
import { VideoPlayer } from "Components";
import useAttachmentDownload from "modules/portfolio/data/useAttachmentDownload";
import { isVideoType } from "Pages/MeTab/pages/MyFiles/helpers";
import { AttachmentItem } from "modules/portfolio/types";
import useMyFileDownload from "modules/myFiles/data/useMyFileDownload";
import { useTranslation } from "react-i18next";
import { EolasDocumentViewerProps } from "UIKit/EolasDocumentViewer/types";
import { useRunOnMountUnmount } from "Hooks";
import { EolasDocumentViewer } from "UIKit/EolasDocumentViewer/EolasDocumentViewer";
import { Notification, useNotifications } from "Components/Notifications";
import { eolasLogger } from "@eolas-medical/core";

export type DocumentRouteParams = {
  attachmentId: string;
};

export const AttachmentFileViewer = ({
  history: { goBack, length },
  match: {
    params: { attachmentId },
  },
}: RouteComponentProps<DocumentRouteParams>) => {
  const { getAttachment } = useAttachmentDownload();
  const { download } = useMyFileDownload();
  const { t } = useTranslation();

  const [file, setFile] = useState<Pick<AttachmentItem, "id" | "blob" | "name"> | null>(null);

  const handleDownloadOriginal = useCallback(() => {
    if (file) {
      download(file.id);
    }
  }, [download, file]);

  const notificationRef = useRef<Notification>();

  const { showNotification, hideNotification, updateNotification } = useNotifications();

  const onFileError = useCallback(
    (e: Error) => {
      if (!notificationRef.current) {
        notificationRef.current = showNotification({
          type: "error",
          description: t("fileViewer_error"),
        });
      } else {
        updateNotification({
          type: "error",
          id: notificationRef.current.id,
          description: t("fileViewer_error"),
        });
      }

      eolasLogger.error(e);
    },
    [t, updateNotification, showNotification],
  );

  const onFileLoaded = useCallback(() => {
    if (notificationRef.current) {
      hideNotification(notificationRef.current.id);
    }
  }, [hideNotification]);

  let viewerParams: EolasDocumentViewerProps | null = null;

  if (file?.blob?.getURL && !isVideoType(file?.blob?.type)) {
    viewerParams = {
      url: file.blob.getURL,
      fileName: `${file.name}.${file.blob.type}`,
      reRenderBehaviour: "onceOnMount",
    };
  }

  useRunOnMountUnmount({
    onMount: async () => {
      const file = await getAttachment({ attachmentId });
      if (file) setFile(file);
    },
  });

  return (
    <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={goBack}>
      <div className="h-100vh w-100vw">
        {length > 1 ? (
          <div className="bg-grey-light py-1">
            <div className="flex justify-center space-x-3">
              <NavButton onClick={goBack} />
              <Button size="sm" onClick={handleDownloadOriginal}>
                {t("general_download_original")}
              </Button>
            </div>
          </div>
        ) : null}
        <div className="bg-grey-light overflow-auto h-full">
          {isVideoType(file?.blob?.type) && file?.blob?.getURL ? (
            <div className="p-4">
              <VideoPlayer
                url={file.blob.getURL}
                title={file.name}
                onLoad={onFileLoaded}
                onError={onFileError}
              />
            </div>
          ) : viewerParams ? (
            <EolasDocumentViewer {...viewerParams} />
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
