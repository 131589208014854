import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { BulkMoveItemsResponse } from "@eolas-medical/core";
import { BulkActionItemsModal } from "../BulkActionItemsModal/BulkActionItemsModal";
import { useMoveItems } from "modules/contentRepository/data/useMoveItems";
import { SelectableItem, ValidItem } from "../BulkActionItemsModal/types";

type Props = {
  initialItems: SelectableItem[];
  onMoveSuccess?: () => void;
};

const isMoveValidItem = (item: SelectableItem): item is ValidItem<BulkMoveItemsResponse> => {
  return true;
};

export const MoveItemsModal = observer(({ initialItems, onMoveSuccess }: Props) => {
  const { t } = useTranslation();

  const { mutateAsync, isError, isLoading, results } = useMoveItems({ onMoveSuccess });

  return (
    <BulkActionItemsModal
      allInvalidFilesDescription={t("move_files_all_invalid_files_description")}
      invalidFilesDescription={t("move_files_invalid_files_description")}
      invalidFilesTitle={t("move_files_invalid_files_title")}
      partialSuccessErrorMessage={t("move_files_modal_partial_success_error")}
      retryFailedMessage={t("move_files_modal_retry_failed")}
      customQueryKey="moveItemsSuccessPathToSection"
      successMessage={t("move_files_modal_success")}
      modalTitle={t("move_files_modal_title")}
      modalDescription={t("move_files_modal_description")}
      genericErrorMessage={t("move_files_modal_error")}
      bulkAction={mutateAsync}
      isError={isError}
      isLoading={isLoading}
      initialItems={initialItems}
      results={results}
      isValidItem={isMoveValidItem}
    />
  );
});
