import { useMemo } from "react";
import { ContentSection } from "modules/contentRepository/types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DropdownOption } from "UIKit";

import { ContentSectionVariants } from "shared/pages/ContentRepository/types";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { EolasSectionTile } from "UIKit/EolasSectionTile/EolasSectionTile";
import { DeleteIcon, EditMonoIcon, SendOutlineIcon } from "Assets/Icons/monocolored";
import { modalStore } from "Stores/ModalStore";
import { useTranslation } from "react-i18next";
import { useShareFile } from "shared/hooks/useShareFile";
import { eolasLogger, sectionStore } from "@eolas-medical/core";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { observer } from "mobx-react-lite";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts/LaunchDarklyContext";
import { useNotifications } from "Components/Notifications/NotificationsContext";
import { DeleteSpaceOrgSectionModal } from "Pages/Spaces/components/DeleteSpaceOrgSectionModal/DeleteSpaceOrgSectionModal";
import { DeleteKnowledgeSectionModal } from "Pages/Knowledge/components/DeleteKnowledgeSectionModal/DeleteKnowledgeSectionModal";
import { EditSpaceOrgContentSectionWizard } from "Pages/Spaces/components/EditSpaceOrgContentSectionWizard/EditSpaceOrgContentSectionWizard";
import { EditKnowledgeContentSectionWizard } from "Pages/Knowledge/components/EditKnowledgeContentSectionWizard/EditKnowledgeContentSectionWizard";
import { useGetFileCount } from "Pages/Spaces/hooks/useGetFileCount";
import { SpaceOrgShareSectionShareButton } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgShareSectionShareButton/SpaceOrgShareSectionShareButton";
import { useGetLoneFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgShareSectionShareButton/hooks/useGetLoneFile";
import { useHandleClickContent } from "shared/pages/ContentRepository/ContentItems/components/ContentItemInstance/hooks/useHandleClickContent";
import { mapToContentItem } from "modules/contentRepository/helpers";

interface ContentSectionItemProps {
  contentSection: ContentSection;
  "data-testid"?: string;
  isAdmin: boolean;
  variant?: ContentSectionVariants;
  isDraggable?: boolean;
}

const ICON_SIZE = 16;

export const ContentSectionItem = observer(
  ({ contentSection, isAdmin, variant, isDraggable }: ContentSectionItemProps) => {
    const { url } = useRouteMatch();
    const { push } = useHistory();
    const { t } = useTranslation();
    const { flags } = useLaunchDarkly();
    const { showNotification } = useNotifications();
    const { onClickContentSection, contentSectionConfig, ...rest } = useContentRepositoryContext();
    const isShareSectionEnabled = !!flags[LDFlagNames.GENERATE_DEEPLINKS_TO_SECTIONS];
    const counts = useGetFileCount(contentSection.id);
    const loneChild = useGetLoneFile({ counts, sectionId: contentSection.id });

    const knowledgeDomain =
      rest.contextDomain === "knowledge" ? rest.knowledgeContentDomain : undefined;
    const isSponsoredSlots =
      knowledgeDomain === "sponsoredSlots" || knowledgeDomain === "sponsoredSlotsContent";

    const { isShareFileEnabled, handleOnShareFile } = useShareFile({ shouldUseMobxModal: true });

    const canShareSection = isShareSectionEnabled && isShareFileEnabled;

    const shouldShowShareButton = canShareSection && contentSectionConfig?.shouldAllowSharing;
    const shouldShowDescription =
      variant === "titleAndDescription" || variant === "titleIconAndDescription";

    const { handleClickItem } = useHandleClickContent({
      id: loneChild ? loneChild.id : null,
      isAdminMode: false,
    });

    const handleClickSection = () => {
      if (loneChild && !isAdmin) {
        handleClickItem(mapToContentItem(loneChild));
        return;
      }

      if (onClickContentSection) {
        onClickContentSection(contentSection);
      }
      push(`${url}/${encodeURIComponent(contentSection.routeId)}`);
    };

    const handleEditSection = () =>
      modalStore.openModal({
        variant: "component",
        name: "EditContentSectionModal",
        Component:
          rest.contextDomain === "spaceOrg" ? (
            <EditSpaceOrgContentSectionWizard variant={variant} contentSection={contentSection} />
          ) : (
            <EditKnowledgeContentSectionWizard
              contentSection={contentSection}
              domain={rest.knowledgeContentDomain}
              variant={variant}
            />
          ),
      });

    const handleDeleteSection = () =>
      modalStore.openModal({
        variant: "component",
        name: "EditContentSectionModal",
        Component:
          rest.contextDomain === "spaceOrg" ? (
            <DeleteSpaceOrgSectionModal contentSection={contentSection} />
          ) : (
            <DeleteKnowledgeSectionModal
              contentSection={contentSection}
              domain={rest.knowledgeContentDomain}
            />
          ),
      });

    const handleShareSection = () => {
      const section = sectionStore.getSection(contentSection.id);
      if (section?.id) {
        handleOnShareFile(section);
        return;
      }

      eolasLogger.error(new Error("Failed to get section for sharing"), { contentSection });
      showNotification({
        type: "error",
        autoHideTimeout: 3000,
        description: t("share_item_link_failure"),
      });
    };

    const adminMenuOptions = useMemo(() => {
      const options: DropdownOption[] = [];

      if (isAdmin) {
        options.push({
          label: t("general_edit"),
          value: "edit",
          icon: <EditMonoIcon width={ICON_SIZE} height={ICON_SIZE} />,
        });
        if (shouldShowShareButton) {
          options.push({
            label: t("component_eolasVersatileList_create_link"),
            value: "share",
            icon: <SendOutlineIcon width={ICON_SIZE} height={ICON_SIZE} />,
          });
        }

        options.push({
          label: t("general_delete"),
          value: "delete",
          icon: <DeleteIcon width={ICON_SIZE} height={ICON_SIZE} />,
        });
      }

      return options;
    }, [isAdmin, t, shouldShowShareButton]);

    const handleMenuAction = (option: DropdownOption) => {
      switch (option.value) {
        case "edit":
          handleEditSection();
          break;
        case "delete":
          handleDeleteSection();
          break;
        case "share":
          handleShareSection();
          break;
      }
    };

    if (isAdmin) {
      return (
        <EolasSectionTile
          variant="admin"
          title={contentSection.name ?? ""}
          iconUrl={contentSection.iconUrl}
          menuActions={adminMenuOptions}
          onMenuActionSelect={handleMenuAction}
          isDraggable={isDraggable}
          onClick={handleClickSection}
          numOfFiles={counts?.allFiles}
          description={isSponsoredSlots ? contentSection.description ?? undefined : undefined}
        />
      );
    }

    if (isSponsoredSlots) {
      return (
        <div id={contentSection.id} data-testid="content-section-list-item">
          <EolasSectionTile
            variant="sponsoredSlotNonAdmin"
            title={contentSection.name ?? ""}
            description={contentSection.description ?? undefined}
            onClick={handleClickSection}
          />
        </div>
      );
    }

    let rightAction: JSX.Element | undefined = undefined;

    if (rest.contextDomain === "spaceOrg") {
      rightAction = (
        <SpaceOrgShareSectionShareButton
          shouldShowShareButton={shouldShowShareButton || false}
          file={loneChild}
          sectionId={contentSection.id}
        />
      );
    } else if (shouldShowShareButton) {
      rightAction = <ShareFileButton id={contentSection.id} onShareFile={handleShareSection} />;
    }

    return (
      <div id={contentSection.id} data-testid="content-section-list-item">
        <EolasSectionTile
          variant="basic"
          title={contentSection.name ?? ""}
          description={shouldShowDescription ? contentSection.description ?? undefined : undefined}
          iconUrl={contentSection.iconUrl}
          onClick={handleClickSection}
          numOfFiles={loneChild ? undefined : counts?.publishedFiles}
          rightAction={rightAction}
        />
      </div>
    );
  },
);
