import {
  AppLevelSection,
  EolasFile,
  MedusaSectionWithItems,
  Section,
  sectionStore,
} from "@eolas-medical/core";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { localSearchStore } from "Stores/localSearch/localSearch.store";

export const addToFileDbCallback = async (files: EolasFile[], isInitialAdd?: boolean) => {
  const items = await contentDbStore.addMultipleItems(files);
  await localSearchStore.updateDb({ filesAdded: items, isInitialPopulation: isInitialAdd });
};

export const removeFromFileDbCallback = async (files: EolasFile[]) => {
  await contentDbStore.deleteMultipleItems(files);
  const ids = files.map(({ id }) => id);
  await localSearchStore.updateDb({ fileIdsRemoved: ids });
};

export const clearFiles = async () => {
  contentDbStore.clearStore();
  await localSearchStore.initialiseDb({ isNew: true });
};

export const upsertChecklistsToSearchDb = async (sections?: Section[]) => {
  const checklistIdsAdded: string[] = [];
  const checklistIdsRemoved: string[] = [];
  sections?.forEach((section) => {
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(section.mainSectionID);
    if (mainSectionType !== AppLevelSection.checklists) {
      return;
    }
    if (section.deleted !== "true") {
      checklistIdsAdded.push(section.id);
    } else {
      checklistIdsRemoved.push(section.id);
    }
  });

  const hasUpdated = Boolean(checklistIdsAdded.length || checklistIdsRemoved.length);

  if (hasUpdated) {
    await localSearchStore.updateDb({ checklistIdsAdded, checklistIdsRemoved });
  }

  return { hasUpdated };
};

export const updateMedusaSections = async (medusaSectionsWithItems: MedusaSectionWithItems[]) => {
  await localSearchStore.updateDb({ medusaSectionsWithItems });
};
