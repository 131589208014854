import React, { useCallback, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  AnalyticsEvents,
  EnrichedFavourite,
  EolasSearchPayload,
  FavouriteTappedPayload,
  MeLevelSection,
} from "@eolas-medical/core";
import {
  isEntityAvailability,
  isEntityOwnerType,
  isFavouriteEntityType,
} from "@eolas-medical/core/lib/src/clients/myFavourites/typeguards";
import { useMainSectionVisit, useRunOnMountUnmount } from "Hooks";
import { SearchSortList, SortFn } from "shared/components/Lists";
import { useLocalClickSearch } from "shared/hooks/useLocalSearch/useLocalClickSearch";
import { InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { useTranslation } from "react-i18next";
import { useGetMyFavourites } from "modules/portfolio/data/useGetMyFavourites";
import { removeLastUrlPath } from "Utilities";
import MyFavouritesTile from "./components/MyFavouritesTile";
import { getMainSectionOfMyFavourite } from "./functions/helpers";
import { MyFavouriteTileIcon } from "./components/MyFavouriteTileIcon";
import { useFeatureGuideModal } from "Components/FeatureGuideModal/hooks/useFeatureGuideModal";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { trackEvent } from "API/Analytics";
import { useShareFile } from "shared/hooks/useShareFile";
import { ExtractRouteParams, LDFlagNames } from "Utilities/types";
import { meRoutes } from "Pages/MeTab";
import { findMedusaItemAndSection } from "Components/MasterSearch/helpers";
import { useLaunchDarkly } from "Contexts";

type Props = RouteComponentProps<ExtractRouteParams<typeof meRoutes.myFavourites>>;

const canShare = ({
  isShareFileEnabled,
  isShareMedusaItemsEnabled,
  isMedusaItem,
}: {
  isShareFileEnabled: boolean;
  isShareMedusaItemsEnabled: boolean;
  isMedusaItem: boolean;
}) => {
  return isShareFileEnabled && ((isShareMedusaItemsEnabled && isMedusaItem) || !isMedusaItem);
};

const MyFavourites = ({ match: { url } }: Props): JSX.Element => {
  const {
    isFavouriteModalOpen,
    removeFavouriteModal,
    closeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const { handleOnShareFile, shareModal, isShareFileEnabled, isShareModalOpen, closeShareModal } =
    useShareFile();

  const { flags } = useLaunchDarkly();
  const isShareMedusaItemsEnabled = isShareFileEnabled && !!flags[LDFlagNames.SHARE_MEDUSA_ITEMS];

  const { t } = useTranslation();

  useMainSectionVisit({
    mainSectionId: MeLevelSection.myFavourites,
  });

  const {
    featureGuideModal,
    closeFeatureGuide,
    isFeatureGuideOpen,
    shouldShowFeatureGuide,
    showFeatureGuide,
  } = useFeatureGuideModal(MeLevelSection.myFavourites);
  const [sortMethod, setSortMethod] = useState<SortFn>();
  useRunOnMountUnmount({
    onMount: () => {
      if (shouldShowFeatureGuide) {
        showFeatureGuide();
      }
    },
  });

  const { myFavouritesList, myFavouritesLoading } = useGetMyFavourites();

  const clickPayload = useLocalClickSearch<EnrichedFavourite>({
    data: myFavouritesList,
    keysToSearch: ["title", "ownerName"],
    onSearchAnalytics: () => {
      trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
        searchString: searchInput,
        hitCount: searchResult.length,
        origin: MeLevelSection.myFavourites,
      });
    },
  });

  const searchInput = clickPayload.searchInput;
  const searchResult = clickPayload.searchResult;

  const itemsForDisplay = useMemo(() => {
    if (clickPayload.isSearchClicked) {
      return searchResult;
    }
    return myFavouritesList.slice().sort(sortMethod);
  }, [clickPayload.isSearchClicked, myFavouritesList, searchResult, sortMethod]);

  const onCloseModal = useCallback(() => {
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
    if (isFeatureGuideOpen) {
      closeFeatureGuide();
      return;
    }
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
  }, [
    isFavouriteModalOpen,
    closeFavouriteModal,
    isFeatureGuideOpen,
    closeFeatureGuide,
    isShareModalOpen,
    closeShareModal,
  ]);

  const isModalOpen = isFavouriteModalOpen || isFeatureGuideOpen || isShareModalOpen;

  const favouriteClickedAnalyticsCallback = (favourite: EnrichedFavourite) => {
    trackEvent<FavouriteTappedPayload>(AnalyticsEvents.FAVOURITE_TAPPED, {
      fileId: favourite.id,
      origin: "My favourites",
      fileType: favourite.fileType || "",
      title: favourite.title,
    });
  };

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("my_favourites_homepage")} />
        <NavButton to={removeLastUrlPath(url)} />
        <SearchSortList<EnrichedFavourite>
          value={searchInput}
          placeholderSearchText={t("my_favourites_search_placeholder")}
          items={itemsForDisplay}
          isSearchable
          isSortable
          sortDateBy="favouritedOn"
          defaultSort="alphabetical"
          onSortMethodChange={(sortMethod?: SortFn) => setSortMethod(() => sortMethod)}
          searchType="click"
          isLoading={myFavouritesLoading}
          onSearchInputChange={clickPayload.onSetSearchInput}
          onClickSearch={clickPayload.onClickSearch}
          onClearSearch={clickPayload.onClearSearch}
          emptySearchPlaceholderText={t("my_favourites_empty_search_placeholder")}
          hasEmptyListNavigation={false}
          renderItem={(favouriteItem) => {
            let component = null;
            if (
              isEntityOwnerType(favouriteItem.entityOwnerType) &&
              isFavouriteEntityType(favouriteItem.entityType)
            ) {
              const mainSection = getMainSectionOfMyFavourite(favouriteItem.entityType);
              const { maybeMedusaItem } = findMedusaItemAndSection(favouriteItem.entityId);
              component = (
                <a
                  key={favouriteItem.id}
                  href={maybeMedusaItem ? maybeMedusaItem.url.value : favouriteItem.deeplinkValue}
                  target="_self"
                >
                  <MyFavouritesTile
                    entityAvailability={
                      isEntityAvailability(favouriteItem.entityAvailability)
                        ? favouriteItem.entityAvailability
                        : undefined
                    }
                    analyticsCallback={() => favouriteClickedAnalyticsCallback(favouriteItem)}
                    Icon={
                      <MyFavouriteTileIcon
                        entityOwnerType={favouriteItem.entityOwnerType}
                        fileType={favouriteItem.fileType ?? ""}
                        mainSectionIconUrl={mainSection?.icon ?? ""}
                      />
                    }
                    entityOwnerType={favouriteItem.entityOwnerType}
                    entityType={favouriteItem.entityType}
                    entityId={favouriteItem.entityId}
                    title={favouriteItem.title}
                    mainSectionName={favouriteItem.mainSectionName ?? mainSection?.name ?? ""}
                    subSectionName={favouriteItem.parentSectionName ?? ""}
                    ownerName={favouriteItem.ownerName ?? ""}
                    showRemoveFavouriteModal={showRemoveFavouriteModal}
                    favouriteId={favouriteItem.id}
                    onShare={
                      canShare({
                        isMedusaItem: Boolean(maybeMedusaItem),
                        isShareFileEnabled,
                        isShareMedusaItemsEnabled,
                      })
                        ? () => handleOnShareFile(favouriteItem)
                        : undefined
                    }
                  />
                </a>
              );
            }
            return component;
          }}
        />
      </InnerPageWrapper>
      <Modal open={isModalOpen} onClose={onCloseModal}>
        {removeFavouriteModal || featureGuideModal || shareModal}
      </Modal>
    </>
  );
};

export default MyFavourites;
