import { myFavouritesStore } from "@eolas-medical/core";
import { FavouriteButton, FavouriteButtonFilled } from "UIKit";

interface FavouriteToggleProps {
  entityId: string;
  // @TODO: old 'My Files' favourites that will need incorporated into My Favourites
  isMyFilesFavourite?: boolean;
  isLoading: boolean;
  onClick: () => void;
  "data-testid"?: string;
}

export const FavouriteToggle = ({
  entityId,
  isMyFilesFavourite,
  isLoading,
  onClick,
  "data-testid": dataTestId,
}: FavouriteToggleProps) => {
  const isMyFavourite = Boolean(myFavouritesStore.favouritesMap[entityId]);
  const Favorite = isMyFavourite || isMyFilesFavourite ? FavouriteButtonFilled : FavouriteButton;

  const handleFavourite = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };
  return (
    <Favorite
      data-testid={dataTestId}
      className="bg-transparent border-none"
      size="xs"
      onClick={handleFavourite}
      isLoading={isLoading}
    />
  );
};
