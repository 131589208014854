import { MyFile } from "modules/myFiles/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OperationsButton, RegularFileWrapper, FavouriteToggle, Text, FileReference } from "UIKit";
import { Dropdown, DropdownOption } from "UIKit/FormElements/Dropdown/Dropdown";
import { formatBytes } from "Utilities/helpers";
import { Action } from "Utilities/types";
import { FileTypeIcon } from "./FileTypeIcon";
import { useToolTip } from "Hooks/useToolTip";

export interface FileTileProps {
  file: MyFile;
  isDraggable?: boolean;
  isMenuDisabled?: boolean;
  menuOptions: DropdownOption[];
  updatingFavourites?: boolean;
  tooltipText?: string;
  onFavouriteClick: () => void;
  onActionMenuSelect: (option: DropdownOption) => void;
}

export const FileTile = ({
  file,
  menuOptions,
  tooltipText,
  isDraggable = false,
  isMenuDisabled = false,
  updatingFavourites = false,
  onActionMenuSelect,
  onFavouriteClick,
}: FileTileProps) => {
  const { t } = useTranslation();
  const { makeToolTipDataAttr } = useToolTip();

  const reference: FileReference | undefined = useMemo(() => {
    if (file.referencesCount > 0) {
      return {
        fileId: file.id,
        tooltipText: t("my_files_attachment_reference_tooltip"),
      };
    }
  }, [file.referencesCount, file.id, t]);

  const filteredMenuOptions = useMemo(() => {
    return file.referencesCount > 0
      ? menuOptions.filter((o) => o.label === Action.DOWNLOAD)
      : menuOptions;
  }, [file.referencesCount, menuOptions]);

  return (
    <RegularFileWrapper canDrag={isDraggable} reference={reference}>
      <FileTypeIcon type={file.blob?.type} className="w-10 h-10" />
      <Text
        level={1}
        className="flex flex-col justify-between flex-1 w-2 overflow-hidden line-clamp-2"
      >
        <div className="truncate">{file.name}</div>
      </Text>
      {file.blob && (
        <p className="text-sm text-grey-dark font-semibold">{formatBytes(file.blob.size || 0)}</p>
      )}
      <FavouriteToggle
        entityId=""
        onClick={onFavouriteClick}
        isMyFilesFavourite={file.isFavourite}
        isLoading={updatingFavourites}
      />
      <Dropdown
        disabled={isMenuDisabled}
        dropdownToggle={<OperationsButton className="static" size="xs" />}
        options={filteredMenuOptions}
        onSelect={onActionMenuSelect}
        {...(isMenuDisabled && tooltipText ? makeToolTipDataAttr({ text: tooltipText }) : {})}
      />
    </RegularFileWrapper>
  );
};
