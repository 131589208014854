import { emailSent } from "Assets";
import { LottieWithHeader } from "../../LottieWithHeader";
import { InnerModalWrapper } from "../components";

type Props = {
  text?: string;
  onComplete?: () => void;
};

export const NotifiedModal = ({ onComplete, text }: Props) => {
  return (
    <InnerModalWrapper>
      <LottieWithHeader
        className="py-24"
        animationSize="20%"
        animation={emailSent}
        onComplete={onComplete}
        text={text}
        textClassName="mx-4"
      />
    </InnerModalWrapper>
  );
};
