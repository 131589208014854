import {
  sectionStore,
  CompletedChecklist,
  contentClient,
  AppLevelSection,
} from "@eolas-medical/core";

import { useFileOperations, useRefetchAppData } from "Hooks";
import { errorStore } from "Stores/ErrorStore";
import { fromAwsJSON, toAwsJSON, useRequestStatus } from "Utilities";

import { EolasSubmittedChecklist } from "../../types";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

export const useChecklistIssues = (checklistID: string) => {
  const childrenOrder = sectionStore.getChildrenOrder(checklistID);
  const { files: checklistSubmissions } = useGetFiles({ childrenOrder });

  const checklistsWithIssues = checklistSubmissions
    .map((submission) => {
      const { completedChecklist }: { completedChecklist: CompletedChecklist } = fromAwsJSON(
        // eslint-disable-next-line quotes
        submission?.metadata ?? '"{}"',
      );

      return { ...submission, metadata: completedChecklist } as EolasSubmittedChecklist;
    })
    .filter((item) => item.metadata.checklistIssues);

  return {
    checklistsWithIssues,
  };
};

export const useResolveIssue = (checklist: EolasSubmittedChecklist) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { updateFile } = useFileOperations();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const onResolve = () => {
    const metadata = toAwsJSON({
      completedChecklist: {
        ...checklist.metadata,
        additionalInfo: null,
        checklistIssues: null,
      },
    });

    const input = {
      id: checklist.id,
      metadata,
    };

    setRequestStatus({ status: "pending", error: "" });

    const updateFilePromise = useAppServicesEndpoints
      ? updateFile({ variables: { input, parentID: checklist.parentID } })
      : contentClient.updateContentItem({
          mainSectionId: sectionStore.getChildReferenceByMainSectionType(AppLevelSection.checklists)
            ?.id as string,
          contentId: checklist.id,
          contentDto: {
            metadata,
          },
        });

    updateFilePromise
      .then(async () => {
        refetch();
        setRequestStatus({ status: "success", error: "" });
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errorMessage = errorStore.captureError({ error, source: "user" });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  };

  return {
    error,
    isLoading,
    onResolve,
  };
};
