/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
import { CaptureContext } from "@sentry/types";

/**
 * @deprecated user eolasLogger from core instead
 */
function useSentry() {
  return {
    captureException: (exception: any, captureContext?: CaptureContext) => {
      const isError = exception instanceof Error;
      const err = isError
        ? exception
        : new Error(`${exception.message || exception.name || exception.code} `);

      const context = isError
        ? captureContext
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { ...captureContext, extra: { ...captureContext?.extra, exception } };

      return Sentry.captureException(err, context as any);
    },
    addBreadcrumb: Sentry.addBreadcrumb,
  };
}

export { useSentry, Sentry };
