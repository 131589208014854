import { formatBytes } from "Utilities/helpers";
import { FileTypeIcon } from "Pages/MeTab/pages/MyFiles/pages/Files/components";
import { Text } from "UIKit";
import { AttachmentItem } from "modules/portfolio/types";

interface AttachmentTileProps {
  attachment: AttachmentItem;
  isSelected?: boolean;
}
export const AttachmentTile: React.FC<AttachmentTileProps> = ({
  attachment,
  isSelected,
}: AttachmentTileProps) => {
  const selectedStyles = "border-blue-500 bg-blue-50";
  const defaultStyles = "border-grey-300 bg-white hover:bg-grey-50";
  return (
    <div
      className={`flex flex-row items-center border 
      space-x-4 space-y-0 rounded-md p-4 shadow-sm cursor-pointer ${
        isSelected ? selectedStyles : defaultStyles
      }`}
    >
      <FileTypeIcon type={attachment.blob?.type} className="h-6 w-6" />
      <Text level={1} className="flex flex-col justify-between flex-1 h-full line-clamp-1">
        {attachment.name}
      </Text>
      {attachment.blob && (
        <p className="text-sm text-grey-dark font-semibold">
          {formatBytes(attachment.blob.size || 0)}
        </p>
      )}
    </div>
  );
};
