import {
  ListItem,
  useLocalChildrenList,
} from "Pages/Spaces/components/SelectTileList/hooks/useLocalChildrenList";
import { InnerModalWrapper } from "UIKit/Modal/components/InnerModalWrapper";
import { useLocalDocumentsList } from "../hooks/useLocalDocumentsList";
import { IconButton } from "UIKit/Button/IconButton";
import { ArrowLeftIcon } from "Assets/Icons/monocolored";
import { hasProp } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { Title } from "UIKit/Typography/Title";
import { Button } from "UIKit/Button/Button";
import { Text } from "UIKit/Typography/Text";
import { SelectTileList } from "Pages/Spaces/components/SelectTileList/SelectTileList";
import { LocalDocumentsListItem } from "./LocalDocumentsListItem";
import { observer } from "mobx-react-lite";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useDetermineVisibleOrganisationTab } from "modules/spaces/data/useDetermineVisibleOrganisationTab";

type Props = {
  onInsertLink: (link: string) => void;
  disabledIds?: string[];
};

export const LocalDocumentsList = observer(({ onInsertLink, disabledIds }: Props) => {
  const { t } = useTranslation();
  const {
    isSearching,
    selectedEntity,
    setIsSearching,
    handleGenerateLink,
    handleSelectionChange,
    didError,
  } = useLocalDocumentsList({ onInsertLink });
  const { activeTab } = useEolasNavigation();
  const { flags } = useLaunchDarkly();

  const { adminOf, isHiddenForNonAdmins } = useDetermineVisibleOrganisationTab();

  const isUserOrgAdmin = adminOf === "organisation" || adminOf === "both";
  const isUserSpaceAdmin = adminOf === "space" || adminOf === "both";

  // We can only show org items if the org tab is not hidden for non-admins
  const shouldShowOrgItems = (isUserSpaceAdmin || isUserOrgAdmin) && !isHiddenForNonAdmins;

  const { items, handleNavigateDown, handleNavigateUp, parentItem, searchData } =
    useLocalChildrenList({
      disabledIds: disabledIds,
      leafType: "files",
      shouldFilterShadowCopies: true,
      otherSectionsToInclude: "legacyContentRepos",
      shouldShowOrgItems: shouldShowOrgItems,
      shouldShowSpaceItems: isUserSpaceAdmin && activeTab === "spaces",
    });

  const isAtRoot = items.length > 0 && items[0].hasParent === false;
  const isShareSectionEnabled = !!flags[LDFlagNames.GENERATE_DEEPLINKS_TO_SECTIONS];

  const onNavigateUp = () => {
    // For this modal, we want to clear the selection when navigating
    if (selectedEntity) {
      handleSelectionChange({});
    }
    handleNavigateUp();
  };

  return (
    <InnerModalWrapper>
      <div className="flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 h-80vh w-full">
        <div>
          {isAtRoot || isSearching ? null : (
            <IconButton
              className="absolute inset-6 sm:inset-12"
              color="white"
              variant="rounded"
              onClick={onNavigateUp}
              icon={<ArrowLeftIcon className="w-5 h-5" />}
            />
          )}

          <Title level={4} className="text-center px-9 line-clamp-2">
            {parentItem ? getNameFromListItem(parentItem) : t("in_app_link_text")}
          </Title>
        </div>

        <Text level={2} className="text-gray-500">
          {t("in_app_link_sub_text")}
        </Text>

        <SelectTileList
          data={items}
          selectionMode="single"
          onSelectionChange={handleSelectionChange}
          renderTile={LocalDocumentsListItem}
          handleNavigateDown={handleNavigateDown}
          searchKeys={["item.name"]}
          searchData={searchData}
          onClearSearch={() => setIsSearching(false)}
          onSearch={() => setIsSearching(true)}
          areNavigableSectionsSelectable={isShareSectionEnabled}
        />
      </div>
      <Button
        size="lg"
        color="blue"
        variant="solid"
        disabled={!selectedEntity}
        onClick={handleGenerateLink}
        className={"w-1/2 self-center xs:w-full absolute bottom-6 sm:bottom-12"}
      >
        {t(selectedEntity?.type === "section" ? "copy_link_section" : "copy_link_file")}
      </Button>
      {didError ? (
        <Text level={2} className="text-red-500 flex flex-col items-center p-6 w-full">
          {t("link_to_file_error")}
        </Text>
      ) : null}
    </InnerModalWrapper>
  );
});

const getNameFromListItem = (listItem: ListItem): string => {
  const item = listItem.item;

  if (hasProp(item, "name")) {
    return item.name;
  }

  if (hasProp(item, "title")) {
    return item.title;
  }

  return "";
};
