import { useQuery } from "@tanstack/react-query";
import contentKeys from "./content.queryKeys";
import { isFlashCardContentItem } from "@eolas-medical/core";
import { mapToContentItem } from "../helpers";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

interface UseContentItem {
  mainSectionId: string;
  flashcardId: string;
  enabled?: boolean;
}
const useFlashcardItem = ({ flashcardId, enabled = true }: UseContentItem) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: contentKeys.flashcard(flashcardId),
    queryFn: () => contentDbStore.getItem(flashcardId),
    enabled,
  });

  const flashcardData = data ? mapToContentItem(data) : undefined;
  const flashcard =
    flashcardData && isFlashCardContentItem(flashcardData) ? flashcardData : undefined;

  return {
    flashcard,
    flashcardLoading: isLoading && enabled,
    isError,
    error,
  };
};

export default useFlashcardItem;
