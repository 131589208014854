import { clinicalQuestionStore, sectionStore, userStore } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, ModalBody, ModalHeader, Text, Title } from "UIKit";

export const DisclaimerModal = ({ type }: { type: "knowledge" | "space" }) => {
  const { t } = useTranslation();

  const consentToDisclaimer = () => {
    if (type === "knowledge") {
      clinicalQuestionStore.setTermsAccepted(userStore.userID);
    }
    if (type === "space") {
      clinicalQuestionStore.setSpacesTermsAccepted(sectionStore.appID);
    }

    modalStore.closeModal();
  };
  const description =
    type === "knowledge" ? t("ecq_disclaimer_description") : t("ecq_disclaimer_description_space");

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("general_attention")}</Title>
      </ModalHeader>

      <ModalBody>
        <Text className="mb-8 sm:mb-12" level={1}>
          {description}
        </Text>
        <Button className="w-36 lg:w-56 rounded-xl self-center py-3" onClick={consentToDisclaimer}>
          {t("general_i_understand")}
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
