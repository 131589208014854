import { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import { fuseSearch } from "./fuseSearch";
import { UseLocalSearchProps } from "./types";
import { ContentItem, sectionStore } from "@eolas-medical/core";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { useLaunchDarkly } from "Contexts";
import { eolasFileNormaliser } from "Utilities/helpers";
import { mapToContentItem } from "modules/contentRepository/helpers";

/**
 * NOTE: this is to be used for search as you type debounced searches only.
 * The debouncing is performed in text input component
 */
const useLocalSearch = <T extends object>({
  data,
  keysToSearch: givenKeysToSearch,
}: UseLocalSearchProps<T>) => {
  const [searchText, setSearchText] = useState("");
  const [keysToSearch, setKeysToSearch] = useState(givenKeysToSearch);

  if (!isEqual(keysToSearch, givenKeysToSearch)) {
    setKeysToSearch(givenKeysToSearch);
  }

  const processedData = useMemo(() => {
    return fuseSearch(searchText, data, keysToSearch);
  }, [data, keysToSearch, searchText]);

  return {
    searchInput: searchText,
    searchResult: searchText ? processedData.results : data,
    searchResultWithMatches: processedData.resultsWithMatches,
    onSetSearchInput: setSearchText,
  };
};

/**
 * NOTE: this is to be used for search as you type debounced searches only when searching
 * space org content.
 * @TODO: move to search and click
 */
export const useLocalDbSearch = ({ idsToInclude }: { idsToInclude: Record<string, string> }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<ContentItem[]>([]);
  const { flags } = useLaunchDarkly();
  const searchTextRef = useRef("");

  useEffect(() => {
    if (searchText !== searchTextRef.current) {
      searchTextRef.current = searchText;
      if (searchText.length < 2) {
        setSearchResults([]);
        return;
      }
      localSearchStore
        .search({
          shouldSearchOrganisation: sectionStore.organisationActiveHospitalTab,
          term: searchText,
          ldFlags: flags,
          isInAdminMode: sectionStore.isAdmin,
          idsToInclude,
        })
        .then((results) => {
          if (searchText === searchTextRef.current) {
            const mappedToContentItems = results.map((item) => {
              const { file } = eolasFileNormaliser(item);
              return mapToContentItem(file);
            });
            setSearchResults(mappedToContentItems);
          }
        });
    }
  }, [searchText, idsToInclude, flags]);

  return { searchResult: searchResults, searchInput: searchText, onSetSearchInput: setSearchText };
};

export default useLocalSearch;
