import { motion } from "framer-motion";
import { Title } from "UIKit";

const DOT = ".";
export const LoadingDots = () => {
  const dots = [0, 1, 2];

  return (
    <div className="flex items-center">
      {dots.map((dotIndex) => (
        <motion.span
          key={dotIndex}
          initial={{ y: 0, opacity: 0.5 }}
          animate={{ y: [0, -8, 0], opacity: [1, 0.5, 1] }}
          transition={{
            duration: 1.0,
            repeat: Infinity,
            ease: "easeInOut",
            delay: dotIndex * 0.2,
          }}
          className="inline-block mr-1"
        >
          <Title level={4} className="inline-block">
            {DOT}
          </Title>
        </motion.span>
      ))}
    </div>
  );
};
