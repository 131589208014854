import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasFile,
  EolasSearchPayload,
  NationalResourceEntityType,
  searchClient,
  sectionStore,
} from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useQuery } from "@tanstack/react-query";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { trackEvent } from "API/Analytics";
import { EOLAS_CALCULATOR } from "Pages/Knowledge/pages/ClinicalCalculators/types";
import { getEolasCalcs } from "./getEolasCalcs";
import { useGetClinicalCalcStatus } from "./useGetClinicalCalcStatus";

export interface NationalBodiesState {
  name: string;
  id: string;
}

type Props = {
  entityType: NationalResourceEntityType;
  nationalBody: string;
  isEnabled?: boolean;
};

export const useGetNationalResourceByNationalBody = ({
  entityType,
  nationalBody,
  isEnabled = true,
}: Props) => {
  const result = useGetClinicalCalcStatus();

  const { supportedEolasRegion } = useSupportedRegion();
  const { data, isLoading, isError, error } = useQuery(
    ["nationalResource", entityType, nationalBody],
    () =>
      searchClient.searchNationalResources({
        query: nationalBody,
        mode: "searchByNationalBody",
        nationalResourceType: entityType,
        region: supportedEolasRegion,
        organisationId: sectionStore.organisationID,
      }),
    { enabled: isEnabled },
  );
  const nationalResources = nationalBody === EOLAS_CALCULATOR ? getEolasCalcs(result) : data ?? [];

  const {
    searchResults,
    searchInstanceId,
    handleClearSearch,
    handleSearchClick,
    isSearchLoading,
    isSearchActive,
  } = useLocalMobxSearch<EolasFile>({
    data: nationalResources ?? [],
    keysToSearch: ["name"],
    analyticsCallback: (text, hits) => {
      trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
        searchString: text,
        hitCount: hits,
        origin: CommunityLevelSection.nationalGuidelines,
      });
    },
  });

  const flatListData = isSearchActive ? searchResults : nationalResources;
  return {
    searchResults,
    searchInstanceId,
    handleClearSearch,
    handleSearchClick,
    flatListData,
    isSearchLoading,
    gettingNationalResources: isLoading,
    error,
    isError,
  };
};
