import React from "react";

export interface DocumentCacheContextValue {
  getDocumentUrl: ({ fileId, key }: { fileId: string; key?: string }) => Promise<string>;
}

const DocumentCacheContext = React.createContext<DocumentCacheContextValue>({
  getDocumentUrl: () => Promise.resolve(""),
});

export default DocumentCacheContext;
