import { eolasLogger, sectionStore } from "@eolas-medical/core";
import { useNotifications } from "Components/Notifications";
import { useLaunchDarkly } from "Contexts";
import { useTranslation } from "react-i18next";
import { useShareFile } from "shared/hooks/useShareFile";
import { Button } from "UIKit";
import { LDFlagNames } from "Utilities/types";

export const ShareButton = ({
  activeSectionId,
  isSubsection,
}: {
  activeSectionId: string;
  isSubsection: boolean;
}) => {
  const { flags } = useLaunchDarkly();
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  const isShareSectionEnabled = !!flags[LDFlagNames.GENERATE_DEEPLINKS_TO_SECTIONS];

  const { isShareFileEnabled, handleOnShareFile } = useShareFile({ shouldUseMobxModal: true });

  const canShareSection = isShareSectionEnabled && isShareFileEnabled;

  const handleShareSection = () => {
    const section = sectionStore.getSection(activeSectionId);
    if (section?.id) {
      handleOnShareFile(section);
      return;
    }

    eolasLogger.error(new Error("Failed to get section for sharing"), { activeSectionId });
    showNotification({
      type: "error",
      autoHideTimeout: 3000,
      description: t("share_item_link_failure"),
    });
  };

  return !isSubsection && canShareSection ? (
    <Button onClick={handleShareSection} iconLeft="SendOutlineIcon" color="white" size="lg">
      {t("repository_share_main_section")}
    </Button>
  ) : null;
};
