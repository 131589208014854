import {
  AnalyticsEvents,
  ContentItem,
  isEolasEditorContentItem,
  isFlashCardContentItem,
  isLinkContentItem,
  ShadowCopyOpenedPayload,
} from "@eolas-medical/core";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { useCallback } from "react";
import {
  BaseContentItemSearchParams,
  ContentItemParams,
} from "shared/pages/ContentRepository/context/types";
import { isShadowCopyContentItem } from "../../../functions/typeguards";
import { getOriginalFromShadowCopy } from "../../../functions/getOriginalFromShadowCopy";
import { trackEvent } from "API/Analytics";
import { convertToLink } from "Utilities";
import { modalStore } from "Stores/ModalStore";
import {
  defaultGetEolasEditorViewerUrl,
  defaultGetFlashcardViewerUrl,
} from "shared/pages/ContentRepository/context/contentRepository.context";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useGetDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useGetDraftFile";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export const useHandleClickContent = ({
  id,
  isAdminMode,
}: {
  id: string | null;
  isAdminMode: boolean;
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { lastUpdatedTimestamp } = contentDbStore;

  const { getBlobViewerUrl, onLoadContentItem, contentItemConfig, contextDomain } =
    useContentRepositoryContext();

  const isSpaceOrg = contextDomain === "spaceOrg";

  const { file: eolasFile } = useGetFile({
    id: isSpaceOrg ? id : null,
  });

  const { data: draftFileData } = useGetDraftFile(
    isAdminMode && isSpaceOrg ? id : null,
    lastUpdatedTimestamp,
  );

  const handleClickItem = useCallback(
    (originalItem: ContentItem, versionNo?: number, shadowCopyContentId?: string) => {
      const onClick = async ({
        item,
        shouldOpenDraftFile,
      }: {
        item: ContentItem;
        shouldOpenDraftFile?: boolean;
      }) => {
        if (!id) {
          return;
        }

        let content = item;

        const params: BaseContentItemSearchParams = {
          [ContentItemParams.shadowCopyContentId]: shadowCopyContentId,
          [ContentItemParams.shouldShowBreadcrumbs]:
            contentItemConfig?.shouldShowBreadcrumbs !== undefined
              ? contentItemConfig?.shouldShowBreadcrumbs.toString()
              : undefined,
        };

        if (shouldOpenDraftFile && draftFileData?.draftFile) {
          content = mapToContentItem(draftFileData.draftFile);
          params[ContentItemParams.fileIdOfDraftFile] = item.id;
        }

        if (isShadowCopyContentItem(content)) {
          const maybeOriginalFile = await getOriginalFromShadowCopy(content);
          const maybeOriginalContentItem = maybeOriginalFile
            ? mapToContentItem(maybeOriginalFile)
            : null;
          if (maybeOriginalContentItem) {
            trackEvent<ShadowCopyOpenedPayload>(AnalyticsEvents.SHADOW_COPY_OPENED, {
              origin: "directNavigation",
              fileId: item.id,
            });
            handleClickItem(maybeOriginalContentItem, undefined, item.id);
            return;
          }

          // If we can't find the original content item, use the deeplink on the shadow copy
          push(convertToLink(content.deepLinkUrl));
          return;
        }

        if (isEolasEditorContentItem(content)) {
          modalStore.closeModal();
          push(defaultGetEolasEditorViewerUrl({ eolasEditor: content, versionNo, ...params }));
          return;
        }

        if (isLinkContentItem(content)) {
          if (onLoadContentItem) {
            onLoadContentItem(content);
          }
          window.open(convertToLink(content.url), "_blank");
          return;
        }

        if (isFlashCardContentItem(content)) {
          modalStore.closeModal();
          push(defaultGetFlashcardViewerUrl({ flashcard: content, versionNo, ...params }));
          return;
        }

        push(getBlobViewerUrl({ blob: content, versionNo, ...params }));
      };

      if (isAdminMode && eolasFile && eolasFile.hasDrafts) {
        modalStore.openModal({
          icon: "warning",
          variant: "dialogue",
          layoutType: "stacked",
          name: "confirmAdminView",
          title: t("repository_admin_view_draft_title"),
          message: t("repository_admin_view_draft_desc"),
          onConfirm: () => onClick({ item: originalItem }),
          onDismiss: () => onClick({ item: originalItem, shouldOpenDraftFile: true }),
          confirmText: t("view_published"),
          dismissText: t("view_draft"),
          isDismissible: true,
        });
        return;
      }
      onClick({ item: originalItem });
    },
    [
      push,
      getBlobViewerUrl,
      onLoadContentItem,
      isAdminMode,
      eolasFile,
      t,
      draftFileData,
      contentItemConfig,
      id,
    ],
  );

  return { handleClickItem };
};
