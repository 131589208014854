import { DropdownOption } from "UIKit/FormElements";

// Note: Leaving space for other variants i.e. Non Admin, Guideline, this will help us keep track of all the variants
type Variant = "admin" | "basic";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type BaseProps<V extends Variant, P extends {} = {}> = {
  variant: V;
  iconComponent: React.ReactElement;
  title: string;
  onClick?: (event: React.MouseEvent) => void;
  rightAction?: React.ReactElement;
  isDisabled?: boolean;
  isDraggable?: boolean;
  isShadowCopy?: boolean;
} & P;

const fileStatusArr = ["published", "unpublished", "parallelDraft"] as const;
export type FileStatus = (typeof fileStatusArr)[number];

export const isFileStatus = (value: string): value is FileStatus => {
  return Object.values<string>(fileStatusArr).includes(value);
};

export type AdminFileTile = BaseProps<
  "admin",
  {
    expiryDate?: Date;
    menuActions: DropdownOption[];
    onMenuActionSelect: (option: DropdownOption) => void;
    status: FileStatus;
    rightLabelTagTooltipText?: Partial<Record<FileStatus, string>>;
    isSelected?: boolean;
    onToggleSelect?: () => void;
    isSearchResult?: boolean;
    mainSectionId?: string;
    parentSectionId?: string;
  }
>;

export type BasicFileTile = BaseProps<"basic", { description?: string }>;

export type FileTileProps = AdminFileTile | BasicFileTile;
