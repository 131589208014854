import { EolasFile } from "@eolas-medical/core";

export const constructFileBreadcrumbProps = (
  file: EolasFile | null,
): { mainSectionId?: string; parentId?: string; fileName: string } => {
  const couldNotFindFile = { mainSectionId: "", parentId: "", fileName: "" };
  if (file) {
    return { mainSectionId: file.mainSectionID, parentId: file.parentID, fileName: file.name };
  }
  return couldNotFindFile;
};
