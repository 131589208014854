import { getCreateChecklistPdfBody, documentClient } from "@eolas-medical/core";
import axios from "axios";
import fileDownload from "js-file-download";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { uniqueId } from "lodash";

export const useDownloadChecklistPDF = () => {
  const [id] = useState(uniqueId);

  const {
    mutate: downloadPDF,
    isLoading,
    error,
  } = useMutation({
    mutationKey: ["downloadChecklistPdf", id],
    mutationFn: async (checklistID: string) => {
      const file = await contentDbStore.getItem(checklistID);
      if (!file) {
        throw new Error("Download checklist PDF: no checklist found in contentDB");
      }
      const { s3DownloadUrl } = await documentClient.createChecklistPdf(
        getCreateChecklistPdfBody(file),
      );
      const fileName = file.name
        ? `${file.name} ${file.createdAt}.pdf`
        : `Completed Checklist ${file.createdAt}.pdf`;
      const res = await axios.get(s3DownloadUrl, { responseType: "blob" });
      fileDownload(res.data, fileName);
    },
  });

  return {
    error: error instanceof Error ? error.message : "",
    isLoading,
    downloadPDF,
  };
};
