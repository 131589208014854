import { eolasLogger, sectionStore } from "@eolas-medical/core";
import { useRefetchAppData } from "Hooks";
import useEditContentSection from "modules/contentRepository/data/useEditContentSection";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { isSortMethod, SortMethods } from "UIKit/EolasVersatileList/EolasVersatileList.types";

export const useSectionSortMethod = ({
  sectionId,
  isInAdminMode,
}: {
  sectionId: string;
  isInAdminMode: boolean;
}) => {
  const { refetch } = useRefetchAppData();
  const { t } = useTranslation();

  const onSuccessCallback = useCallback(async () => {
    refetch();
  }, [refetch]);
  const { editContentSection } = useEditContentSection({ onSuccess: onSuccessCallback });

  const sectionDefaultSortMethod = sectionStore.getSection(sectionId).defaultSortMethod;

  const defaultSortMethod =
    sectionDefaultSortMethod && isSortMethod(sectionDefaultSortMethod)
      ? sectionDefaultSortMethod
      : "alphabetical";

  const onSortMethodChange = useCallback(
    (defaultSortMethod: SortMethods) => {
      // If the user is not an admin nothing to do when changing the sort method
      if (!isInAdminMode) return;
      modalStore.openModal({
        icon: "warning",
        name: "confirmSaveSortingChanges",
        variant: "dialogue",
        title: t("repository_admin_change_sorting_method_title"),
        message: t("repository_admin_change_sorting_method_message"),
        confirmText: t("repository_admin_change_sorting_method_confirm"),
        dismissText: t("repository_admin_change_sorting_method_dismiss"),
        onConfirmAsync: async () => {
          if (defaultSortMethod !== "favourites") {
            editContentSection({
              id: sectionId,
              defaultSortMethod,
            });
          } else {
            eolasLogger.warn(
              "Cannot change the sort method, the favourites sort method has been selected",
            );
          }
        },
      });
    },
    [sectionId, editContentSection, isInAdminMode, t],
  );

  return { defaultSortMethod, onSortMethodChange };
};
