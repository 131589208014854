interface IconProps {
  className?: string;
  onClick?(): void;
}

export const EolasIcon: React.FC<IconProps> = ({ className = "" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="eolas"
      x={0}
      y={0}
      className={className}
      viewBox="0 0 800 800"
    >
      <style>
        {
          ".st1{fill:url(#SVGID_00000114793045810408604490000014101330161775612054_)}.st2{fill:url(#SVGID_00000138538303330429365100000013587836787832456618_)}.st3{fill:url(#SVGID_00000093867596029789039250000003727232682301914525_)}.st4{fill:url(#SVGID_00000023994117038218593010000000826467001556821168_)}.st5{fill:url(#SVGID_00000105398194514548916610000008586147883050223257_)}.st6{fill:url(#SVGID_00000023243180469139841300000011293094184267062969_)}.st7{fill:url(#SVGID_00000021817585405346284470000008693499580041212291_)}.st8{fill:url(#SVGID_00000002363791793971237480000012721391362675273905_)}.st9{fill:url(#SVGID_00000145772661423409584970000014601973333240932015_)}.st10{fill:url(#SVGID_00000047782110005845743030000014931660954909892498_)}.st11{fill:url(#SVGID_00000082358559023778575630000015870715134240697220_)}"
        }
      </style>
      <linearGradient
        id="SVGID_1_"
        x1={3101.245}
        x2={3422.051}
        y1={-1484.646}
        y2={-1484.646}
        gradientTransform="rotate(90 971.005 -2056.08)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0068ff",
          }}
        />
        <stop
          offset={0.397}
          style={{
            stopColor: "#0087ff",
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#008dff",
          }}
        />
        <stop
          offset={0.759}
          style={{
            stopColor: "#0076ff",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
          }}
        />
      </linearGradient>
      <path
        d="M362.5 395 301 333.5l37.1-37.1-20.3-20.3c-23.1-22.3-35.8-52.3-35.8-84.4 0-64.8 52.7-117.5 117.5-117.5S517 126.9 517 191.7c0 32.1-12.7 62.1-35.8 84.4L362.5 395zm37.1-233.9c-16.9 0-30.6 13.7-30.6 30.6 0 8.4 3.3 16.3 9.4 22.1l.6.6 20.6 20.6 21.2-21.2c6.1-5.8 9.4-13.7 9.4-22.1 0-16.9-13.7-30.6-30.6-30.6z"
        style={{
          fill: "url(#SVGID_1_)",
        }}
      />
      <linearGradient
        id="SVGID_00000179630143513182252270000011072787088217963934_"
        x1={355.899}
        x2={333.643}
        y1={269.57}
        y2={200.329}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0052da",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="m379 214.4-.6-.6c-6.1-5.8-9.4-13.7-9.4-22.1 0-1.6.1-3.2.4-4.8l-77.5-42.2c-6.3 14.4-9.8 30.3-9.8 47 0 32.1 12.7 62.1 35.8 84.4l20.3 20.3 61.5-61.5-20.7-20.5z"
        style={{
          fill: "url(#SVGID_00000179630143513182252270000011072787088217963934_)",
        }}
      />
      <linearGradient
        id="SVGID_00000103962806871016992530000008105006819289297309_"
        x1={296.901}
        x2={494.732}
        y1={364.713}
        y2={154.517}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#005cda",
          }}
        />
        <stop
          offset={0.201}
          style={{
            stopColor: "#006cff",
            stopOpacity: 0.4,
          }}
        />
        <stop
          offset={0.244}
          style={{
            stopColor: "#0087ff",
            stopOpacity: 0.448,
          }}
        />
        <stop
          offset={0.305}
          style={{
            stopColor: "#00a5ff",
            stopOpacity: 0.5143,
          }}
        />
        <stop
          offset={0.364}
          style={{
            stopColor: "#0bf",
            stopOpacity: 0.5794,
          }}
        />
        <stop
          offset={0.421}
          style={{
            stopColor: "#00c8ff",
            stopOpacity: 0.6422,
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#0cf",
            stopOpacity: 0.7,
          }}
        />
        <stop
          offset={0.549}
          style={{
            stopColor: "#00c9ff",
            stopOpacity: 0.5165,
          }}
        />
        <stop
          offset={0.604}
          style={{
            stopColor: "#00c0ff",
            stopOpacity: 0.3828,
          }}
        />
        <stop
          offset={0.653}
          style={{
            stopColor: "#00b0ff",
            stopOpacity: 0.2649,
          }}
        />
        <stop
          offset={0.698}
          style={{
            stopColor: "#09f",
            stopOpacity: 0.1561,
          }}
        />
        <stop
          offset={0.739}
          style={{
            stopColor: "#007dff",
            stopOpacity: 0.05450225,
          }}
        />
        <stop
          offset={0.762}
          style={{
            stopColor: "#006aff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="M517.1 191.7c0 32.1-12.7 62.1-35.8 84.4L362.5 395 301 333.5l98.5-98.5 21.2-21.2c6.1-5.8 9.4-13.7 9.4-22.1 0-9.1-4-17.3-10.3-22.9l35.8-80.4c36.6 20 61.5 58.8 61.5 103.3z"
        style={{
          fill: "url(#SVGID_00000103962806871016992530000008105006819289297309_)",
        }}
      />
      <linearGradient
        id="SVGID_00000110454409606484364610000004249713029127537555_"
        x1={4711.19}
        x2={4946.272}
        y1={-5887.072}
        y2={-5887.072}
        gradientTransform="rotate(90 -446.663 -4875.811)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0068ff",
          }}
        />
        <stop
          offset={0.397}
          style={{
            stopColor: "#0087ff",
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#008dff",
          }}
        />
        <stop
          offset={0.759}
          style={{
            stopColor: "#0076ff",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
          }}
        />
      </linearGradient>
      <path
        d="m404.2 362.5 61.5-61.5 37.1 37.1 20.3-20.3c22.3-23.1 52.3-35.8 84.4-35.8 64.8 0 117.5 52.7 117.5 117.5S672.3 517 607.5 517c-32.1 0-62.1-12.7-84.4-35.8L404.2 362.5zm233.9 37.1c0-16.9-13.7-30.6-30.6-30.6-8.4 0-16.3 3.3-22.1 9.4l-.6.6-20.6 20.6 21.2 21.2c5.8 6.1 13.7 9.4 22.1 9.4 16.8 0 30.6-13.7 30.6-30.6z"
        style={{
          fill: "url(#SVGID_00000110454409606484364610000004249713029127537555_)",
        }}
      />
      <linearGradient
        id="SVGID_00000182489535684916005330000016925313058704056231_"
        x1={4785.06}
        x2={4762.804}
        y1={-5852.064}
        y2={-5921.305}
        gradientTransform="rotate(90 -446.663 -4875.811)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0052da",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="m584.7 379 .6-.6c5.8-6.1 13.7-9.4 22.1-9.4 1.6 0 3.2.1 4.8.4l42.2-77.5c-14.4-6.3-30.3-9.8-47-9.8-32.1 0-62.1 12.7-84.4 35.8l-20.3 20.3 61.5 61.5 20.5-20.7z"
        style={{
          fill: "url(#SVGID_00000182489535684916005330000016925313058704056231_)",
        }}
      />
      <linearGradient
        id="SVGID_00000026849198682873569350000004729787124235056018_"
        x1={4726.061}
        x2={4923.893}
        y1={-5756.922}
        y2={-5967.118}
        gradientTransform="rotate(90 -446.663 -4875.811)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#005cda",
          }}
        />
        <stop
          offset={0.201}
          style={{
            stopColor: "#006cff",
            stopOpacity: 0.4,
          }}
        />
        <stop
          offset={0.244}
          style={{
            stopColor: "#0087ff",
            stopOpacity: 0.448,
          }}
        />
        <stop
          offset={0.305}
          style={{
            stopColor: "#00a5ff",
            stopOpacity: 0.5143,
          }}
        />
        <stop
          offset={0.364}
          style={{
            stopColor: "#0bf",
            stopOpacity: 0.5794,
          }}
        />
        <stop
          offset={0.421}
          style={{
            stopColor: "#00c8ff",
            stopOpacity: 0.6422,
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#0cf",
            stopOpacity: 0.7,
          }}
        />
        <stop
          offset={0.549}
          style={{
            stopColor: "#00c9ff",
            stopOpacity: 0.5165,
          }}
        />
        <stop
          offset={0.604}
          style={{
            stopColor: "#00c0ff",
            stopOpacity: 0.3828,
          }}
        />
        <stop
          offset={0.653}
          style={{
            stopColor: "#00b0ff",
            stopOpacity: 0.2649,
          }}
        />
        <stop
          offset={0.698}
          style={{
            stopColor: "#09f",
            stopOpacity: 0.1561,
          }}
        />
        <stop
          offset={0.739}
          style={{
            stopColor: "#007dff",
            stopOpacity: 0.05450225,
          }}
        />
        <stop
          offset={0.762}
          style={{
            stopColor: "#006aff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="M607.5 517.1c-32.1 0-62.1-12.7-84.4-35.8L404.2 362.5l61.5-61.5 98.5 98.5 21.2 21.2c5.8 6.1 13.7 9.4 22.1 9.4 9.1 0 17.3-4 22.9-10.3l80.4 35.8c-20 36.7-58.8 61.5-103.3 61.5z"
        style={{
          fill: "url(#SVGID_00000026849198682873569350000004729787124235056018_)",
        }}
      />
      <linearGradient
        id="SVGID_00000097491295904284626740000010066941008151062967_"
        x1={-1261.524}
        x2={-1026.443}
        y1={-9822.456}
        y2={-9822.456}
        gradientTransform="rotate(180 -371.783 -4628.51)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0068ff",
          }}
        />
        <stop
          offset={0.397}
          style={{
            stopColor: "#0087ff",
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#008dff",
          }}
        />
        <stop
          offset={0.759}
          style={{
            stopColor: "#0076ff",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
          }}
        />
      </linearGradient>
      <path
        d="m437.5 405 61.5 61.5-37.1 37.1 20.3 20.3c23.1 22.3 35.8 52.3 35.8 84.4 0 64.8-52.7 117.5-117.5 117.5S283 673.1 283 608.3c0-32.1 12.7-62.1 35.8-84.4L437.5 405zm-37.1 233.9c16.9 0 30.6-13.7 30.6-30.6 0-8.4-3.3-16.3-9.4-22.1l-.6-.6-20.6-20.6-21.2 21.2c-6.1 5.8-9.4 13.7-9.4 22.1 0 16.9 13.7 30.6 30.6 30.6z"
        style={{
          fill: "url(#SVGID_00000097491295904284626740000010066941008151062967_)",
        }}
      />
      <linearGradient
        id="SVGID_00000170242415792378954070000001490615913956956336_"
        x1={-1187.655}
        x2={-1209.91}
        y1={-9787.448}
        y2={-9856.69}
        gradientTransform="rotate(180 -371.783 -4628.51)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0052da",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="m421 585.6.6.6c6.1 5.8 9.4 13.7 9.4 22.1 0 1.6-.1 3.2-.4 4.8l77.5 42.2c6.3-14.4 9.8-30.3 9.8-47 0-32.1-12.7-62.1-35.8-84.4l-20.3-20.3-61.4 61.4 20.6 20.6z"
        style={{
          fill: "url(#SVGID_00000170242415792378954070000001490615913956956336_)",
        }}
      />
      <linearGradient
        id="SVGID_00000008843997595292828890000015908378729781505460_"
        x1={-1246.653}
        x2={-1048.821}
        y1={-9692.307}
        y2={-9902.502}
        gradientTransform="rotate(180 -371.783 -4628.51)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#005cda",
          }}
        />
        <stop
          offset={0.201}
          style={{
            stopColor: "#006cff",
            stopOpacity: 0.4,
          }}
        />
        <stop
          offset={0.244}
          style={{
            stopColor: "#0087ff",
            stopOpacity: 0.448,
          }}
        />
        <stop
          offset={0.305}
          style={{
            stopColor: "#00a5ff",
            stopOpacity: 0.5143,
          }}
        />
        <stop
          offset={0.364}
          style={{
            stopColor: "#0bf",
            stopOpacity: 0.5794,
          }}
        />
        <stop
          offset={0.421}
          style={{
            stopColor: "#00c8ff",
            stopOpacity: 0.6422,
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#0cf",
            stopOpacity: 0.7,
          }}
        />
        <stop
          offset={0.549}
          style={{
            stopColor: "#00c9ff",
            stopOpacity: 0.5165,
          }}
        />
        <stop
          offset={0.604}
          style={{
            stopColor: "#00c0ff",
            stopOpacity: 0.3828,
          }}
        />
        <stop
          offset={0.653}
          style={{
            stopColor: "#00b0ff",
            stopOpacity: 0.2649,
          }}
        />
        <stop
          offset={0.698}
          style={{
            stopColor: "#09f",
            stopOpacity: 0.1561,
          }}
        />
        <stop
          offset={0.739}
          style={{
            stopColor: "#007dff",
            stopOpacity: 0.05450225,
          }}
        />
        <stop
          offset={0.762}
          style={{
            stopColor: "#006aff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="M282.9 608.3c0-32.1 12.7-62.1 35.8-84.4L437.5 405l61.5 61.5-98.6 98.5-21.2 21.2c-6.1 5.8-9.4 13.7-9.4 22.1 0 9.1 4 17.3 10.3 22.9l-35.8 80.4c-36.6-20-61.4-58.8-61.4-103.3z"
        style={{
          fill: "url(#SVGID_00000008843997595292828890000015908378729781505460_)",
        }}
      />
      <linearGradient
        id="SVGID_00000000938977673764488450000004489790172704170656_"
        x1={-4537.75}
        x2={-4302.668}
        y1={-3000.377}
        y2={-3000.377}
        gradientTransform="rotate(-90 -391.998 -3627.778)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0068ff",
          }}
        />
        <stop
          offset={0.397}
          style={{
            stopColor: "#0087ff",
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#008dff",
          }}
        />
        <stop
          offset={0.759}
          style={{
            stopColor: "#0076ff",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
          }}
        />
      </linearGradient>
      <path
        d="M395.8 437.5 334.3 499l-37.1-37.1-20.2 20.3c-22.3 23.1-52.3 35.8-84.4 35.8C127.7 518 75 465.2 75 400.4s52.7-117.5 117.5-117.5c32.1 0 62.1 12.7 84.4 35.8l118.9 118.8zm-233.9-37.1c0 16.9 13.7 30.6 30.6 30.6 8.4 0 16.3-3.3 22.1-9.4l.6-.6 20.6-20.6-21.2-21.2c-5.8-6.1-13.7-9.4-22.1-9.4-16.8 0-30.6 13.7-30.6 30.6z"
        style={{
          fill: "url(#SVGID_00000000938977673764488450000004489790172704170656_)",
        }}
      />
      <linearGradient
        id="SVGID_00000047048658978803885090000005689056555152863889_"
        x1={-4463.88}
        x2={-4486.136}
        y1={-2965.369}
        y2={-3034.61}
        gradientTransform="rotate(-90 -391.998 -3627.778)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0052da",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0068ff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="m215.3 421-.6.6c-5.8 6.1-13.7 9.4-22.1 9.4-1.6 0-3.2-.1-4.8-.4l-42.2 77.5c14.4 6.3 30.3 9.8 47 9.8 32.1 0 62.1-12.7 84.4-35.8l20.3-20.3-61.5-61.5-20.5 20.7z"
        style={{
          fill: "url(#SVGID_00000047048658978803885090000005689056555152863889_)",
        }}
      />
      <linearGradient
        id="SVGID_00000084529305277950719770000014962138758779584956_"
        x1={-4522.878}
        x2={-4325.047}
        y1={-2870.227}
        y2={-3080.423}
        gradientTransform="rotate(-90 -391.998 -3627.778)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#005cda",
          }}
        />
        <stop
          offset={0.201}
          style={{
            stopColor: "#006cff",
            stopOpacity: 0.4,
          }}
        />
        <stop
          offset={0.244}
          style={{
            stopColor: "#0087ff",
            stopOpacity: 0.456,
          }}
        />
        <stop
          offset={0.305}
          style={{
            stopColor: "#00a5ff",
            stopOpacity: 0.5333,
          }}
        />
        <stop
          offset={0.364}
          style={{
            stopColor: "#0bf",
            stopOpacity: 0.6093,
          }}
        />
        <stop
          offset={0.421}
          style={{
            stopColor: "#00c8ff",
            stopOpacity: 0.6825,
          }}
        />
        <stop
          offset={0.473}
          style={{
            stopColor: "#0cf",
            stopOpacity: 0.75,
          }}
        />
        <stop
          offset={0.549}
          style={{
            stopColor: "#00c9ff",
            stopOpacity: 0.5534,
          }}
        />
        <stop
          offset={0.604}
          style={{
            stopColor: "#00c0ff",
            stopOpacity: 0.4102,
          }}
        />
        <stop
          offset={0.653}
          style={{
            stopColor: "#00b0ff",
            stopOpacity: 0.2838,
          }}
        />
        <stop
          offset={0.698}
          style={{
            stopColor: "#09f",
            stopOpacity: 0.1672,
          }}
        />
        <stop
          offset={0.739}
          style={{
            stopColor: "#007dff",
            stopOpacity: 0.05839527,
          }}
        />
        <stop
          offset={0.762}
          style={{
            stopColor: "#006aff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <path
        d="M192.5 282.9c32.1 0 62.1 12.7 84.4 35.8l118.8 118.8-61.4 61.5-98.5-98.5-21.2-21.2c-5.8-6.1-13.7-9.4-22.1-9.4-9.1 0-17.3 4-22.9 10.3l-80.4-35.8c20-36.6 58.8-61.5 103.3-61.5z"
        style={{
          fill: "url(#SVGID_00000084529305277950719770000014962138758779584956_)",
        }}
      />
    </svg>
  );
};
