import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";

import { WizardState } from "../../../types";
import { useRefetchAppData, useRunOnMountUnmount } from "Hooks";
import {
  contentClient,
  sectionStore,
  DraftFile,
  NewContentType,
  ChangeContentTypeBody,
  ChangeToFileBody,
  ChangeToDSMBody,
} from "@eolas-medical/core";
import { makeMutableWizardState } from "../../../functions/makeMutableWizardState";
import { useUploadBlob } from "../../../hooks/useUploadBlob";
import { intermediateUpdateForUi } from "../../../../../functions/intermediateUpdateForUi";
import { isSupportedBlobType } from "../../../typeguards";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export const useConfirmAndUpload = (state: WizardState, sectionId: string) => {
  const isMountedRef = useRef(true);
  const { refetch } = useRefetchAppData();

  const { uploadBlob, setUploadProgress, uploadProgress, isLocationPublic } = useUploadBlob();

  useRunOnMountUnmount({
    onUnmount: () => {
      isMountedRef.current = false;
    },
  });

  const { isLoading, isError, isSuccess, mutate } = useMutation({
    mutationKey: ["mutateContentItem", state.fileId, sectionId, isLocationPublic],
    mutationFn: async () => {
      const newState = makeMutableWizardState(state);
      const mainSectionId = sectionStore.getSection(sectionId)?.mainSectionID;
      if (!mainSectionId) {
        throw new Error("Unable to get mainSectionId in  mutateContentItem");
      }

      /**
       * NOTE: only data related to a new blob item in S3, or changes related to draft status
       * should be added to the dto here. Any other mutations to the dto should be done
       * in the relevant step
       */

      if (newState.blob) {
        let mediaName: string | undefined = undefined;

        if (!newState.isExistingFileWithNewType) {
          mediaName = newState.dto.mediaName;
        } else if (isNewTypeBlobSupportingType(newState.dto)) {
          mediaName = newState.dto.newProperties.mediaName;
        }

        const result = await uploadBlob({
          mainSectionId,
          blob: newState.blob,
          mediaName,
        });

        if (isMountedRef.current === false) {
          return;
        }

        if (result.type === "public") {
          if (!newState.isExistingFileWithNewType) {
            newState.dto.key = result.url;
          } else if (isNewTypeBlobSupportingType(newState.dto)) {
            newState.dto.newProperties.key = result.url;
          }
        } else {
          if (!newState.isExistingFileWithNewType) {
            newState.dto.key = result.s3Key;
          } else if (isNewTypeBlobSupportingType(newState.dto)) {
            newState.dto.newProperties.key = result.s3Key;
          }
          if (result.isLegacy) {
            if (!newState.isExistingFileWithNewType) {
              delete newState.dto.mediaId;
            }
          } else {
            if (!newState.isExistingFileWithNewType) {
              newState.dto.mediaId = result.s3Key;
            } else if (isNewTypeBlobSupportingType(newState.dto)) {
              newState.dto.newProperties.mediaId = result.s3Key;
            }
          }
        }
      } else {
        setUploadProgress(50);
      }

      if (newState.fileId === null) {
        // Creating a new item:

        if (newState.nextDraftStatus === "unpublished") {
          newState.dto.isDraft = true;
        }
        const response = await contentClient.addContentItem(mainSectionId, {
          ...newState.dto,
          type: newState.type,
        });
        intermediateUpdateForUi({ type: "file", action: "update", file: response });
        return;
      }

      if (newState.isExistingFileWithNewType) {
        // Changing the type on the item:

        if (newState.existingDraftStatus === "parallel") {
          throw new Error(
            "mutateContentItem: attempting to change type on existing parallel draft. This is not allowed",
          );
        }

        const shouldSaveAsDraft = newState.nextDraftStatus === "parallel";

        const response = await contentClient.changeContentType({
          contentId: newState.fileId,
          mainSectionId,
          changeContentTypeDto: newState.dto,
          shouldSaveAsDraft,
        });

        const existingFile = await contentDbStore.getItem(newState.fileId);

        intermediateUpdateForUi({
          type: "file",
          action: "update",
          file: shouldSaveAsDraft ? { ...existingFile, hasDrafts: true } : response,
        });
        return;
      }

      const updateContentDto =
        newState.blob && isSupportedBlobType(newState.type)
          ? { ...newState.dto, type: newState.type }
          : newState.dto;

      if (!newState.existingDraftStatus && !newState.nextDraftStatus) {
        const response = await contentClient.updateContentItem({
          mainSectionId,
          contentId: newState.fileId,
          contentDto: updateContentDto,
        });
        intermediateUpdateForUi({ type: "file", action: "update", file: response });
        return;
      }

      const existingFile = await contentDbStore.getItem(newState.fileId);

      if (newState.nextDraftStatus === "parallel") {
        // Create parallel draft file:

        let draftFile: DraftFile | null = null;

        if (!newState.existingDraftStatus) {
          draftFile = await contentClient.createDraftFile({
            contentId: newState.fileId,
            mainSectionId,
            draftFileDto: updateContentDto,
          });
        }

        if (newState.existingDraftStatus === "parallel") {
          draftFile = await contentClient.updateDraftFile({
            contentId: newState.fileId,
            mainSectionId,
            draftFileDto: updateContentDto,
          });
        }

        if (newState.existingDraftStatus === "unpublished" || !draftFile) {
          // Don't allow creation of a draft file from an unpublished file
          throw new Error(
            "mutateContentItem: old draft status unpublished, new status parallel. This is not allowed",
          );
        }

        intermediateUpdateForUi({
          type: "file",
          action: "update",
          file: { ...existingFile, hasDrafts: true },
        });

        return;
      }

      if (newState.nextDraftStatus === "unpublished") {
        if (newState.existingDraftStatus === "parallel") {
          const response = await contentClient.updateDraftFile({
            contentId: newState.fileId,
            mainSectionId,
            draftFileDto: { ...updateContentDto, isDraft: true },
            shouldMergeChanges: true,
          });
          intermediateUpdateForUi({ type: "file", action: "update", file: response });
          return;
        }
        const response = await contentClient.updateContentItem({
          mainSectionId,
          contentId: newState.fileId,
          contentDto: { ...updateContentDto, isDraft: true },
        });
        intermediateUpdateForUi({ type: "file", action: "update", file: response });
        return;
      }

      if (!newState.nextDraftStatus) {
        if (newState.existingDraftStatus === "parallel") {
          // Publish draft file changes to EolasFile:

          const response = await contentClient.updateDraftFile({
            contentId: newState.fileId,
            mainSectionId,
            draftFileDto: updateContentDto,
            shouldMergeChanges: true,
          });

          intermediateUpdateForUi({
            type: "file",
            action: "update",
            file: { ...response, hasDrafts: false },
          });

          return;
        }
        if (newState.existingDraftStatus === "unpublished") {
          const response = await contentClient.updateContentItem({
            mainSectionId,
            contentId: newState.fileId,
            contentDto: { ...updateContentDto, isDraft: false },
          });
          intermediateUpdateForUi({ type: "file", action: "update", file: response });
          return;
        }
      }

      throw new Error("useConfirmAndUpload: unrecognised file state.");
    },
    onSuccess: refetch,
  });

  return { isLoading, isError, isSuccess, mutate, uploadProgress };
};

const isNewTypeBlobSupportingType = (
  dto: ChangeContentTypeBody,
): dto is ChangeToFileBody | ChangeToDSMBody => {
  return [NewContentType.FILE, NewContentType.DSM].includes(dto.newType);
};
