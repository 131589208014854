import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";
import { DownloadFileButton } from "Components";
import { ShareFileButton } from "./shared/ShareFileButton";
import { FavouriteToggle } from "UIKit/Button";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";

export const RegularFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  className = "h-40",
  onShareFile,
  onSelectFile = () => {
    return;
  },
  showRemoveFavouriteModal,
  ...handlers
}: FileTileProps) => {
  const { t } = useTranslation();
  const { info, updatedAt, name } = eolasFile;
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);

  const shouldShowShareButton = !!onShareFile && !isAdmin;

  const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled && showRemoveFavouriteModal;
  return (
    <FileTileWrapper
      className={className}
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1 h-full justify-between">
        <span className="text-lg font-semibold mb-1 line-clamp-1 leading-10">{name}</span>
        <div className="flex flex-col">
          {info && (
            <span className="text-sm text-grey-darker capitalize line-clamp-2">
              {t("fileTile_updated_by", { user: info })}
            </span>
          )}

          {updatedAt && (
            <span className="text-sm text-grey-darker">
              {t("fileTile_last_update", {
                date: format(new Date(updatedAt as string), "do MMMM yyyy, HH:mm"),
              })}
            </span>
          )}
        </div>
      </div>
      {shouldShowFavouriteToggle || shouldShowShareButton ? (
        <div className="w-fit self-start flex flex-col items-center justify-start h-full">
          {shouldShowFavouriteToggle ? (
            <FavouriteToggle
              entityId={eolasFile.id}
              isLoading={!!isMarkingAsFavourite}
              onClick={() =>
                favouriteId
                  ? showRemoveFavouriteModal({
                      entityId: eolasFile.id,
                      favouriteId,
                      title: eolasFile.name,
                      entityType: FavouriteEntityType.FILE,
                    })
                  : handleAddFavourite({
                      item: eolasFile,
                      entityType: FavouriteEntityType.FILE,
                    })
              }
            />
          ) : null}
          <DownloadFileButton eolasFile={eolasFile} />
          {shouldShowShareButton && (
            <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
          )}
        </div>
      ) : null}
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
