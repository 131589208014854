import React from "react";
import { SimpleButton, TextLevel } from "UIKit";

export type ToggleColor = "green" | "yellow" | "red" | "blue";

interface ToggleButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  isSelected?: boolean;
  color?: ToggleColor;
  textLevel?: TextLevel;
  unselectedBackground?: string;
  buttonClassName?: string;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  onClick,
  text,
  isSelected = false,
  color = "blue",
  textLevel,
  unselectedBackground = "bg-grey-100",
  buttonClassName,
}: ToggleButtonProps) => {
  const backgroundColors: Record<ToggleColor, string> = {
    green: "bg-green-500",
    yellow: "bg-yellow-500",
    red: "bg-red-500",
    blue: "bg-blue-500",
  };

  return (
    <SimpleButton
      onClick={(e) => {
        onClick(e);
        e.preventDefault();
      }}
      text={text}
      backgroundColor={isSelected ? backgroundColors[color] : unselectedBackground}
      textColor={isSelected ? "text-white" : "text-black"}
      textLevel={textLevel}
      buttonClassName={buttonClassName}
    />
  );
};
