import { DeleteModal } from "UIKit";
import { ChildReference } from "Utilities/types";

import { useDeleteSection } from "./hooks";
import { useTranslation } from "react-i18next";

export interface DeleteSectionModalProps {
  sectionReference: ChildReference;
  onCloseModal(): void;
}

export const DeleteSectionModal: React.FC<DeleteSectionModalProps> = ({
  sectionReference,
  onCloseModal,
}: DeleteSectionModalProps) => {
  const { error, isLoading, isSuccessful, onDeleteSection } = useDeleteSection(sectionReference);
  const { t } = useTranslation();

  return (
    <DeleteModal
      error={error}
      isLoading={isLoading}
      onDelete={onDeleteSection}
      onCloseModal={onCloseModal}
      isSuccessful={isSuccessful}
      title={sectionReference.name ?? ""}
      description={t("deleteModal_delete_section_description")}
    />
  );
};
