import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useAutoEntryAccess } from "../hooks/useAutoEntryAccess";
import { userStore } from "@eolas-medical/core";
import { CreateSpaceUserReturn } from "../functions/createSpaceUser";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";
import { useHistory } from "react-router-dom";
import { BaseAppRoute } from "Utilities/types";
import { AutoAccessBaseModal, ModalState } from "./AutoAccessBaseModal";

export const AutoAccessModalAfterLogin = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}) => {
  const [state, setState] = useState<ModalState>("loading");
  const queryClient = useQueryClient();
  const { push } = useHistory();

  const onCompletion = ({ spaceId, outcome }: CreateSpaceUserReturn): boolean => {
    queryClient.invalidateQueries(spacesKeys.list());
    switch (outcome) {
      case "success":
      case "alreadyExists":
        if (spaceId) {
          // This will also autoclose the modal, as it remains open by a token being present:
          userStore.setAccessLinkId(null);
          userStore.setInviteId(null);
        } else {
          push(`/${BaseAppRoute.selectSpace}`);
          setState("explanation");
        }
        return true;
      case "wrongAccount":
      case "error":
      case "deleted":
      case "inviteCompleted":
        push(`/${BaseAppRoute.selectSpace}`);
        setState(outcome);
        return false;
      case "nil":
        // Shouldn't arrive here, as modal only shows if a either access link or invite link used
        setState("error");
        return false;
    }
  };

  useAutoEntryAccess({ onCompletion });

  // Setting a link type of access here, as its just used to show a different message pre login so has no effect here
  return (
    <AutoAccessBaseModal linkType="access" modalState={state} handleCloseModal={handleCloseModal} />
  );
};
