import MasterSearchResults from "../MasterSearchResults";
import useKnowledgeCopilotSearch from "Components/MasterSearch/hooks/useKnowledgeCopilotSearch";
import { MasterSearchResultsParams } from "Components/MasterSearch/types";
import { LottieWithHeader } from "UIKit";
import { searchLoading } from "Assets";
import { useDebounce } from "Hooks";
import useCopilotSuggestedSearch from "Components/MasterSearch/hooks/useCopilotSuggestedSearch";
import SuggestedSearches from "./components/SuggestedSearches";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

const MasterSearchResultsKnowledgeCopilot = observer((params: MasterSearchResultsParams) => {
  const { t } = useTranslation();
  const { searchValue, searchInput, knowledgeSearchDisabled, onChangeText } = params;

  const debouncedSearchText = useDebounce(searchInput, 600);

  const {
    isFetching,
    knowledgeFiles,
    relatedQueries,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isCopilotError,
  } = useKnowledgeCopilotSearch({
    searchValue,
    knowledgeSearchDisabled,
  });

  const { suggestedSearch } = useCopilotSuggestedSearch({
    searchValue: debouncedSearchText,
  });

  const clickOnSuggestedSearch = (query: string) => {
    onChangeText(query);
    params.onClickSearch(query);
  };

  const renderCopilotLoading = () => (
    <LottieWithHeader
      animationSize="100%"
      animation={searchLoading}
      lottieOptions={{ loop: true }}
      text={""}
    />
  );

  const renderSuggestedSearch = () => (
    <SuggestedSearches
      title={t("copilot_suggested_searches")}
      onClick={clickOnSuggestedSearch}
      suggestedSearch={suggestedSearch}
    />
  );

  const renderAlsoAskedSuggestions = () =>
    relatedQueries?.length ? (
      <SuggestedSearches
        title={t("copilot_also_asked")}
        onClick={clickOnSuggestedSearch}
        suggestedSearch={relatedQueries as string[]}
      />
    ) : null;

  return (
    <>
      <MasterSearchResults
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        searchLoading={isFetching && searchInput.length > 0 ? true : false}
        searchResult={knowledgeFiles}
        knowledgeSearchDisabled={knowledgeSearchDisabled}
        isFetchingNextPage={isFetchingNextPage}
        {...params}
        isCopilotError={isCopilotError}
        renderLoadingCopilot={renderCopilotLoading}
        renderSuggestedSearch={renderSuggestedSearch}
        renderAlsoAskedSuggestions={renderAlsoAskedSuggestions}
      />
    </>
  );
});

export default MasterSearchResultsKnowledgeCopilot;
