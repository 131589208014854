import {
  AppLevelSection,
  CommunityLevelSection,
  OrganisationLevelSection,
} from "@eolas-medical/core";

/* Supported Content deeplinks */

export const spaceSupportedContentDeepLinkTypes = [
  AppLevelSection.newsFeed,
  AppLevelSection.admissionGuides,
  AppLevelSection.clinicalPathways,
  AppLevelSection.emergencyActionCards,
  AppLevelSection.equipmentLocations,
  AppLevelSection.guidelines,
  AppLevelSection.howTo,
  AppLevelSection.rotas,
  AppLevelSection.surveys,
  AppLevelSection.wellbeing,
  AppLevelSection.researchAndAudit,
  AppLevelSection.clinicalDocuments,
  AppLevelSection.policyDocuments,
  AppLevelSection.activityFeed,
  AppLevelSection.patientLeaflets,
  AppLevelSection.checklists,
  AppLevelSection.contacts,
  AppLevelSection.importantLinks,
  AppLevelSection.educationPortal,
  "null", // string null is used for generic content repositories
] as const;

export const orgSupportedContentDeepLinkTypes = [
  OrganisationLevelSection.communicationPortal,
  OrganisationLevelSection.hospitalGuidelines,
  OrganisationLevelSection.drugMonitoring,
  OrganisationLevelSection.genericOrganisationGuidelines,
  OrganisationLevelSection.organisationWellbeing,
  OrganisationLevelSection.medusaMedications,
  OrganisationLevelSection.hospitalContacts,
  OrganisationLevelSection.learningManagement,
  OrganisationLevelSection.orgPatientLeaflets,
  "null", // string null is used for generic content repositories
] as const;

export const knowledgeSupportedClinicalResourcesDeepLinkTypes = [
  CommunityLevelSection.nationalGuidelines,
  CommunityLevelSection.clinicalCalculators,
  CommunityLevelSection.communityPatientLeaflets,
] as const;

export const knowledgeSupportedContentDeepLinkTypes = [
  CommunityLevelSection.communityMedications,
  CommunityLevelSection.wikiEm,
  CommunityLevelSection.niceGuidelines,
  ...knowledgeSupportedClinicalResourcesDeepLinkTypes,
] as const;

export const spaceOrgSupportedContentDeepLinkTypes = [
  ...spaceSupportedContentDeepLinkTypes,
  ...orgSupportedContentDeepLinkTypes,
] as const;

export const meSupportedContentDeepLinkTypes = ["portfolioEvent", "portfolioSupervision"] as const;

/* Supported action deeplinks */

export const supportedActionDeepLinkTypes = [
  "chatChannelInvite",
  "spaceOrgInvite",
  "spaceOrgAccessToken",
  "portfolioEventFeedback",
] as const;
