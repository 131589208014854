import envConfig from "env-config";
import { forwardRef } from "react";
import { Editor, IAllProps as EditorProps } from "@tinymce/tinymce-react";
import { DEFAULT_MCE_CONFIG } from "./EolasMCEEditor.constants";
import { ITinyEvents } from "@tinymce/tinymce-react/lib/cjs/main/ts/Events";
import { combinePlugins, removePlugins } from "./functions/helpers";
import { DeeplinkConfig, useDeeplinkingSetup } from "./hooks/useDeeplinkingSetup";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { TINY_MCE_SOURCE } from "config/tinymce.constants";
import { errorHandling } from "./functions/errorHandling";

type AllowedProps = Omit<
  EditorProps,
  | "onInit"
  | "apiKey"
  | "tinymceScriptSrc"
  | "onSkinLoadError"
  | "onIconsLoadError"
  | "onModelLoadError"
  | "onPluginLoadError"
  | "onThemeLoadError"
  | "onScriptsLoadError"
  | "onLanguageLoadError"
>;

export type Props = {
  onEditorInit: (...args: Parameters<ITinyEvents["onInit"]>) => void;
  deeplinkConfig?: DeeplinkConfig;
} & AllowedProps;

export type EolasMCERef = Editor;

export const EolasMCEEditor = forwardRef<EolasMCERef, Props>(
  ({ onEditorInit, deeplinkConfig, ...rest }, ref) => {
    const { flags } = useLaunchDarkly();
    const shouldUseTinymceCloud = flags[LDFlagNames.SHOULD_ENABLE_TINYMCE_CLOUD] || false;
    const plugins = rest.init?.plugins
      ? combinePlugins(DEFAULT_MCE_CONFIG.plugins, rest.init.plugins)
      : DEFAULT_MCE_CONFIG.plugins;
    const adjustedPlugins = deeplinkConfig?.isEnabled ? removePlugins(plugins, ["link"]) : plugins;
    const { createDeepLinkSetup } = useDeeplinkingSetup(deeplinkConfig);

    return (
      <Editor
        ref={ref}
        apiKey={shouldUseTinymceCloud ? envConfig.REACT_APP_TINY_CLOUD_KEY : undefined}
        tinymceScriptSrc={shouldUseTinymceCloud ? undefined : TINY_MCE_SOURCE}
        licenseKey={shouldUseTinymceCloud ? undefined : envConfig.REACT_APP_TINY_MCE_LICENSE_KEY}
        onInit={(evt, editor) => {
          if (typeof onEditorInit === "function") {
            onEditorInit(evt, editor);
          }
        }}
        {...rest}
        init={{
          ...DEFAULT_MCE_CONFIG.init,
          ...rest.init,
          plugins: adjustedPlugins,
          // eslint-disable-next-line camelcase
          quickbars_selection_toolbar: !deeplinkConfig?.isEnabled,
          setup: (editor) => {
            if (deeplinkConfig?.isEnabled) {
              createDeepLinkSetup(editor);
            }
          },
        }}
        {...errorHandling}
      />
    );
  },
);
