import { DownloadFileButton } from "Components";
import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";
import { ShareFileButton } from "./shared/ShareFileButton";
import { FavouriteToggle } from "UIKit/Button";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { sectionStore } from "@eolas-medical/core";
import { observer } from "mobx-react-lite";
import { isBefore } from "date-fns";
import { useTranslation } from "react-i18next";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import { useToolTip } from "Hooks/useToolTip";

export interface GuidelineFileTileProps extends FileTileProps {
  organisationImageURL?: string;
}

export const GuidelineFileTile = observer(
  ({
    isAdmin,
    canDrag,
    eolasFile,
    isDragging,
    organisationImageURL,
    onShareFile,
    showRemoveFavouriteModal,
    onSelectFile = () => {
      return;
    },
    ...handlers
  }: GuidelineFileTileProps) => {
    const { makeToolTipDataAttr } = useToolTip();
    const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
      useAddFavourite(eolasFile.id);
    const shouldShowShareButton = !!onShareFile && !isAdmin;
    const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(
      eolasFile.mainSectionID,
    );
    const { t } = useTranslation();
    const { flags } = useLaunchDarkly();

    const hasExpired =
      eolasFile?.expiryDate && isBefore(new Date(eolasFile?.expiryDate), new Date());
    const isExpiryWarningFeatureEnabled =
      flags[LDFlagNames.SHOW_LEGACY_GUIDELINE_EXPIRY_WARNING] || false;
    const shouldShowExpiryWarning = isExpiryWarningFeatureEnabled && hasExpired;

    return (
      <FileTileWrapper
        className="h-40 relative"
        canDrag={canDrag}
        eolasFile={eolasFile}
        isDragging={isDragging}
        onClick={() => onSelectFile(eolasFile)}
      >
        <div className="flex flex-col justify-between flex-3 h-full">
          <div>
            <span
              className="text-lg font-semibold line-clamp-2 leading-10"
              title={eolasFile.name ?? ""}
            >
              {eolasFile.name}
            </span>
            <span className="text-md text-grey-dark line-clamp-2">{eolasFile.info}</span>
          </div>
        </div>

        {organisationImageURL && (
          <div className="w-fit self-start flex flex-col items-center justify-center h-full ">
            <div className="h-20">
              <img
                alt="guideline-icon"
                src={organisationImageURL}
                srcSet={organisationImageURL}
                className="h-full w-36 lg:w-48 rounded-sm object-cover"
              />
            </div>
          </div>
        )}
        <div className="w-fit self-start flex flex-col justify-between h-full items-end">
          {shouldShowFavouriteToggle ? (
            <FavouriteToggle
              entityId={eolasFile.id}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: eolasFile.id,
                      favouriteId,
                      title: eolasFile.name,
                      entityType: FavouriteEntityType.FILE,
                      originForAnalytics: mainSectionType ?? undefined,
                    })
                  : handleAddFavourite({
                      item: eolasFile,
                      entityType: FavouriteEntityType.FILE,
                    })
              }
              isLoading={!!isMarkingAsFavourite}
            />
          ) : null}
          <DownloadFileButton eolasFile={eolasFile} />
          {shouldShowShareButton ? (
            <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
          ) : null}
        </div>

        {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}

        {shouldShowExpiryWarning && (
          <div className="absolute bottom-2 left-0">
            <span
              className="h-3 w-3 text-red-500"
              {...makeToolTipDataAttr({ text: t("general_expired") })}
            >
              ❗
            </span>
          </div>
        )}
      </FileTileWrapper>
    );
  },
);
