import localforage from "localforage";
import { handleApiErrors, handleAuthErrors, AuthFunctionNames } from "@eolas-medical/core";

import { logout } from "Pages/Authentication/auth.actions";
import { isDev } from "Utilities/helpers";
import { Sentry } from "Contexts";

type sourceType = "user" | "network";

type CaptureErrorParams = {
  error: Error | string;
  source: sourceType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  retryCallback?: (args?: any) => unknown;
  retryParameters?: Record<string, unknown>;
  authFunctionName?: AuthFunctionNames;
  data?: Record<string, unknown>;
};

export const sessionExpired = () => {
  Sentry.addBreadcrumb({
    category: "Sentry",
    message: "Forced logout on networkError 401 - auto logout",
  });

  // TODO: required to relog users after the new auth change

  localforage.clear().catch();
  sessionStorage.clear();

  logout();
};

class ErrorStore {
  public captureError = ({
    error,
    source,
    retryCallback,
    retryParameters,
    authFunctionName,
    data,
  }: CaptureErrorParams) => {
    if (!error) {
      return;
    }

    if (retryCallback) {
      Sentry.addBreadcrumb({
        category: "Function Name",
        message: retryCallback.name || retryCallback.toString(),
      });
    }
    if (retryParameters) {
      Sentry.addBreadcrumb({
        category: "Function Params",
        message: JSON.stringify(retryParameters),
      });
    }

    const { sendErrorToSentry, errorMessageForDisplay } = authFunctionName
      ? handleAuthErrors(error, authFunctionName)
      : handleApiErrors(error);

    if (isDev()) {
      // eslint-disable-next-line no-console
      console.log(
        sendErrorToSentry
          ? "Error (will be sent to sentry):"
          : "Error (should not be sent to sentry):",
        error,
      );
    }

    if (sendErrorToSentry) {
      Sentry.captureException(error, { extra: { source, ...data } });
    }

    return errorMessageForDisplay;
  };
}

/**
 * @deprecated use eolasLogger from core instead
 */
export const errorStore = new ErrorStore();
