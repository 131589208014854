import { useTranslation } from "react-i18next";

import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { Loader } from "UIKit";
import { useGetDraftFile } from "./hooks/useGetDraftFile";
import { ErrorComponent } from "./components/ErrorComponent";
import { ManageContentItemChildModal } from "./ManageContentItemChildModal";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";

export type ManageContentItemWizardModalProps = {
  sectionId: string;
  fileId: string | null;
};

export const ManageContentItemWizardModal = (props: ManageContentItemWizardModalProps) => {
  const {
    data,
    isFetching: isDraftFileFetching,
    isError,
  } = useGetDraftFile(props.fileId, null, { shouldFetchOnMountOnly: true });

  const { file: existingFile, isFetching: isFileFetching } = useGetFile({
    id: props.fileId,
    options: { shouldFetchOnMountOnly: true },
  });

  const { t } = useTranslation();

  if ((!data && isDraftFileFetching) || (!existingFile && isFileFetching)) {
    return (
      <EolasWizardModalWrapper header={{ title: t("general_loading") }}>
        <div className="flex flex-col items-center justify-center width-full">
          <div className="mt-6">
            <Loader />
          </div>
        </div>
      </EolasWizardModalWrapper>
    );
  }

  if (isError) {
    return <ErrorComponent />;
  }

  if (props.fileId && existingFile) {
    if (data?.hasDrafts) {
      return (
        <ManageContentItemChildModal
          sectionId={props.sectionId}
          file={existingFile}
          draftFile={data.draftFile}
        />
      );
    }
    return (
      <ManageContentItemChildModal
        sectionId={props.sectionId}
        file={existingFile}
        draftFile={null}
      />
    );
  }

  return <ManageContentItemChildModal sectionId={props.sectionId} file={null} />;
};
