import React, { useMemo, useEffect } from "react";
import { Loader } from "UIKit";
import { useTranslation } from "react-i18next";
import LZString from "lz-string";
import {
  AnalyticsEvents,
  ContentItem,
  objectIsEolasEditorMetadata,
  sectionStore,
  fromAwsJSON,
} from "@eolas-medical/core";
import { transformLinksToOpenInNewTabs } from "Pages/FileViewer/functions/helpers";
import { trackEvent } from "API/Analytics";
import { EolasIFrame } from "UIKit/EolasIFrame/EolasIFrame";

const overrideCss = `div, ul, li, table, td { width: auto !important; overflow: visible !important; }
img { overflow: visible !important; }`;

interface EolasEditorRendererProps {
  contentItem: ContentItem | undefined;
  contentItemLoading: boolean;
}

export const EolasEditorRenderer: React.FC<EolasEditorRendererProps> = ({
  contentItem,
  contentItemLoading,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!contentItem) return;

    const mainSectionType = contentItem.mainSectionId
      ? sectionStore.getMainSectionTypeFromMainSectionID(contentItem.mainSectionId)
      : null;

    const mainSectionIdentity = contentItem.mainSectionId
      ? sectionStore.getMainSectionIdentityByMainSectionId(contentItem.mainSectionId)
      : null;

    // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
    const idForTracking = mainSectionType ?? mainSectionIdentity;

    if (idForTracking) {
      trackEvent(AnalyticsEvents.OPEN_FILE, {
        mainSectionId: idForTracking,
        fileId: contentItem.id,
        fileType: contentItem.type || "",
        fileName: contentItem.name,
      });
    }
  }, [contentItem]);

  const htmlContent = useMemo(() => {
    if (contentItemLoading || !contentItem?.metadata) {
      return null;
    }

    const metadata = fromAwsJSON(contentItem.metadata);
    if (!objectIsEolasEditorMetadata(metadata)) {
      return null;
    }

    const decodedContent = LZString.decompressFromEncodedURIComponent(metadata.eolasEditorContent);
    if (!decodedContent) {
      return null;
    }

    return transformLinksToOpenInNewTabs(decodedContent);
  }, [contentItem, contentItemLoading]);

  if (contentItemLoading) {
    return <Loader className="relative top-32 bg-transparent" />;
  }

  if (!htmlContent) {
    return <div>{t("error_loading_content")}</div>;
  }

  return (
    <div className="rounded-lg p-4 bg-white" style={{ marginTop: 10 }}>
      <EolasIFrame
        internalHtmlContent={htmlContent}
        overrideCss={overrideCss}
        overrideContainerClass="w-full flex flex-grow border-0 bg-white h-60vh lg:h-70vh"
      />
    </div>
  );
};
