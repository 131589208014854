import { modalStore } from "Stores/ModalStore";
import {
  WholeAppLoaderModal,
  WholeAppLoaderModalProps,
} from "UIKit/WholeAppLoaderModal/WholeAppLoaderModal";

const WHOLE_APP_MODAL_NAME = "wholeAppLoading";

export const openWholeAppLoaderModal = (props: WholeAppLoaderModalProps = {}) => {
  modalStore.openModal({
    variant: "component",
    Component: () => <WholeAppLoaderModal {...props} />,
    name: WHOLE_APP_MODAL_NAME,
    isDismissible: false,
  });
};

export const closeWholeAppLoaderModal = () => {
  if (modalStore.modal?.name === WHOLE_APP_MODAL_NAME) {
    modalStore.closeModal();
  }
};
