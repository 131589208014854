import { useCallback, useMemo, useState } from "react";
import { DragResult, SearchSortList, SortFn } from "shared/components/Lists";
import { useLocalSearch } from "shared/hooks/useLocalSearch";

import TeamMemberTile from "../TeamMemberTile/TeamMemberTile";
import useReorderTeamMembers from "../../../../data/useReorderTeamMembers";
import useTeamMembers from "../../../../data/useTeamMembers";
import { TeamMember } from "../../../../types";
import { observer } from "mobx-react-lite";

interface TeamMemebersList {
  onClickEditTeamMember: (teamMember: TeamMember) => void;
  onClickDeleteTeamMember: (teamMember: TeamMember) => void;
}
const TeamMembersList = observer(
  ({ onClickEditTeamMember, onClickDeleteTeamMember }: TeamMemebersList) => {
    const { teamMembers } = useTeamMembers();
    const [sortMethod, setSortMethod] = useState<SortFn>();
    const { reorderTeamMembers } = useReorderTeamMembers();

    const { searchResult, onSetSearchInput } = useLocalSearch({
      data: teamMembers,
      keysToSearch: ["name", "role"],
    });

    const items = useMemo(() => searchResult.slice().sort(sortMethod), [sortMethod, searchResult]);

    const handleDragTeamMember = useCallback(
      ({ dropResult }: DragResult<TeamMember>) => {
        if (dropResult) {
          reorderTeamMembers(dropResult);
        }
      },
      [reorderTeamMembers],
    );

    const renderTeamMemberTile = useCallback(
      (teamMember: TeamMember, isDragging?: boolean) => {
        return (
          <TeamMemberTile
            teamMember={teamMember}
            isDragging={isDragging || false}
            onEditTeamMember={onClickEditTeamMember}
            onDeleteTeamMember={onClickDeleteTeamMember}
          />
        );
      },
      [onClickEditTeamMember, onClickDeleteTeamMember],
    );

    return (
      <SearchSortList<TeamMember>
        items={items}
        isSearchable
        isDragable
        isSortable
        onSearchInputChange={onSetSearchInput}
        onSortMethodChange={(sortMethod?: SortFn) => setSortMethod(() => sortMethod)}
        onDragEnd={handleDragTeamMember}
        renderItem={renderTeamMemberTile}
      />
    );
  },
);

export default TeamMembersList;
