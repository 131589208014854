import { useCallback, useRef, useState } from "react";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { observer } from "mobx-react-lite";
import { Modal } from "UIKit";
import { trackEvent } from "API/Analytics";
import { useClickOutside, useMediaQuery } from "Hooks";

import useWebNotifications from "modules/webNotifications/data/useWebNotifications";
import NotificationBell from "./components/NotificationBell/NotificationBell";
import NotificationsList from "./components/NotificationsList/NotificationsList";
import useClickNotification from "./hooks/useClickNotification";
import { useSpacesContext } from "modules/spaces";

const NotificationCenter = observer(() => {
  const user = userStore.userData;
  const { selectedSpace } = useSpacesContext();
  const { webNotifications, webNotificationsLoading, webNotificationCount } = useWebNotifications({
    userId: user.id || "",
    spaceId: selectedSpace?.id,
    organisationId: selectedSpace?.organisationID,
  });

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const { onClickNotification } = useClickNotification();
  const media = useMediaQuery();
  const isMobile = media === "xs";

  const listRef = useRef(null);

  const handleClickNotificationBell = useCallback(() => {
    setIsOpened((prevState) => !prevState);
    trackEvent(AnalyticsEvents.NOTIFICATION_BUBBLE_CLICKED);
  }, []);

  const handleCloseNotificationList = useCallback(() => {
    setIsOpened(false);
  }, []);

  const handleClickOutside = useCallback(() => {
    if (!isMobile) {
      setIsOpened(false);
    }
  }, [isMobile]);

  useClickOutside(listRef, handleClickOutside);

  const renderNotificationList = () => (
    <NotificationsList
      webNotificationsLoading={webNotificationsLoading}
      webNotifications={webNotifications}
      onClose={handleClickNotificationBell}
      onClickNotification={onClickNotification}
    />
  );

  // TODO: add animation
  return (
    <div ref={listRef}>
      <NotificationBell
        notificationCount={webNotificationCount}
        onClick={handleClickNotificationBell}
      />
      {isMobile ? (
        <Modal open={!!isOpened} onClose={handleCloseNotificationList}>
          {renderNotificationList()}
        </Modal>
      ) : (
        isOpened && renderNotificationList()
      )}
    </div>
  );
});

export default NotificationCenter;
