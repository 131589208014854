import { useMemo } from "react";
import * as yup from "yup";

export const useSectionValidationSchema = ({
  validationItems,
  requiredNameError,
  requiredDescriptionError,
  minLengthError,
  existsError,
  showDescription = false,
  isDescriptionRequired = true,
}: {
  validationItems: string[];
  requiredNameError: string;
  requiredDescriptionError?: string;
  minLengthError: string;
  existsError: string;
  showDescription?: boolean;
  isDescriptionRequired?: boolean;
}) => {
  let sectionSchema = yup.object().shape({
    name: yup
      .string()
      .required(requiredNameError)
      .min(3, minLengthError)
      .test("name-exists", existsError, function (newSection) {
        return !validationItems.some(
          (section) => section.toLowerCase() === newSection?.toLowerCase(),
        );
      }),
  });

  if (showDescription) {
    if (isDescriptionRequired) {
      sectionSchema = sectionSchema.shape({
        description: yup.string().required(requiredDescriptionError).min(3, minLengthError),
      });
    } else {
      sectionSchema = sectionSchema.shape({
        description: yup
          .string()
          .matches(/.{3,}/, { excludeEmptyString: true, message: minLengthError }),
      });
    }
  }

  const sectionValidationSchema = useMemo(() => sectionSchema, [sectionSchema]);

  return sectionValidationSchema;
};
