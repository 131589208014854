import { Button, InnerModalWrapper, Loader, ModalBody, Text, Title } from "UIKit";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useExportToImage from "Hooks/useExportToImage";
import { SelectFileToggle } from "UIKit/Button/SelectFileToggle";
import { useLoginTokens } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/LoginToken/useLoginTokens";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { eolasLogger } from "@eolas-medical/core";

type Props = {
  onCopyLink?: () => void;
  onExportToImage?: () => void;
  exportedQRCodeName: string;
  link: string;
  titleText?: string;
  additionalText?: string;
  isLoading?: boolean;
};

export const GenericShareLinkModal = ({
  onCopyLink,
  onExportToImage,
  titleText,
  additionalText,
  isLoading = false,
  link: linkProp,
  exportedQRCodeName,
}: Props) => {
  const [didClickCopy, setDidClickCopy] = useState(false);
  const [link, setLink] = useState(linkProp);
  const [isAttachLoginTokenSelected, setIsAttachLoginTokenSelected] = useState(false);

  const { t } = useTranslation();
  const { activeTab } = useEolasNavigation();
  const fullAccessLevel = useGetAdminStatus({ activeTab });
  const isFullAdmin = fullAccessLevel === "admin";
  const isInSpaceOrOrg = activeTab === "spaces" || activeTab === "organisation";

  const shouldFetchLoginTokens = isFullAdmin && isInSpaceOrOrg;

  const { loginTokens, isLoading: isLoadingLoginTokens } = useLoginTokens({
    isEnabled: shouldFetchLoginTokens,
  });

  const firstToken = loginTokens && loginTokens[0];

  const { parentRef, exportToImage } = useExportToImage();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setDidClickCopy(true);
    if (onCopyLink) {
      onCopyLink();
    }
  };

  const handleExportToImage = () => {
    exportToImage(exportedQRCodeName);
    if (onExportToImage) {
      onExportToImage();
    }
  };

  const handleAttachLoginToken = () => {
    if (!firstToken) {
      eolasLogger.error("No login tokens available to attach to the link - this should not happen");
      return;
    }

    setIsAttachLoginTokenSelected(!isAttachLoginTokenSelected);
    if (isAttachLoginTokenSelected) {
      setLink(linkProp);
    } else {
      // Note: safe to just use & here as the linkProp is guaranteed to have a query string
      setLink(`${linkProp}&loginToken=${encodeURIComponent(firstToken)}`);
    }
    if (didClickCopy) {
      setDidClickCopy(false);
    }
  };

  const copyLinkButtonText = didClickCopy ? t("copy_to_clipboard_tooltip") : t("copy_to_clipboard");

  return (
    <InnerModalWrapper>
      <ModalBody>
        <Title level={5} className="text-center overflow-ellipsis line-clamp-2 width-full">
          {titleText || t("share_item_link_modal_title")}
        </Title>
        <Text level={1} className="text-center">
          {t("share_item_link_modal_description")}
        </Text>
        {additionalText ? (
          <Text level={1} className="text-center">
            {additionalText}
          </Text>
        ) : null}

        <div className="my-4 flex flex-col align-center justify-center">
          <div ref={parentRef} className="flex align-center justify-center">
            {isLoading ? (
              <Loader className="bg-transparent h-20vh" />
            ) : (
              <QRCode size={220} value={link} />
            )}
          </div>
          <div className="text-center flex flex-1 flex-row gap-2 align-center justify-center mt-4">
            <Text className="text-center self-center" level={4}>
              {link}
            </Text>
          </div>
        </div>

        {shouldFetchLoginTokens ? (
          <div className="text-center flex flex-1 flex-row gap-2 align-center justify-center mb-4 mt-2">
            {isLoadingLoginTokens ? (
              <Loader className="bg-transparent" size={32} />
            ) : (
              <>
                <Text className="text-center self-center" level={1}>
                  {t(firstToken ? "share_item_attach_login_token" : "share_item_no_login_tokens")}
                </Text>
                {firstToken ? (
                  <SelectFileToggle
                    isSelected={isAttachLoginTokenSelected}
                    onCheckboxClick={handleAttachLoginToken}
                  />
                ) : null}
              </>
            )}
          </div>
        ) : null}

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
          <Button
            data-testid="copy-clipboard-button"
            size="sm"
            iconLeft={didClickCopy ? undefined : "CopyIcon"}
            color="grey"
            variant="outline"
            className="order-1 sm:order-0"
            onClick={handleCopyLink}
          >
            <Text className="ml-2 text-sm sm:text-base" level={1}>
              {copyLinkButtonText}
            </Text>
          </Button>

          <Button
            data-testid="export-png-button"
            size="sm"
            iconLeft="ExportIcon"
            color="grey"
            variant="outline"
            className="order-0 sm:order-1"
            onClick={handleExportToImage}
          >
            <Text className="ml-2 text-sm sm:text-base" level={1}>
              {t("export_as_png")}
            </Text>
          </Button>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
