import * as BaseTypes from "../EolasRadio.types";
import React from "react";
import { isDefined } from "@eolas-medical/core";

type Size = "xs" | "sm" | "md" | "lg";

const sizeMap: { [k in Size]: string } = {
  xs: "h-4 w-4",
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-12 w-12",
};

export type Props = BaseTypes.BaseProps<
  "checkCircle",
  {
    size: Size;
  }
>;

/**
 * Note: The design for this component shows a check mark rather than a circle when active but this goes against
 * recommended accessibility guidelines and practices. Adding a check mark to the circle would make it too similar to
 * a checkbox which has very different behaviour and meaning. Therefore, the circle will remain as the active state.
 */
export const CheckCircle = ({ isChecked, onToggle, size, isDisabled = false, value }: Props) => {
  const [defaultValue, setDefaultValue] = React.useState(isChecked);

  const handleChange = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (onToggle) {
      onToggle(!isChecked);
    }
    if (!isDefined(value)) {
      setDefaultValue(!isChecked);
    }
  };
  return (
    <div className="rounded">
      <input
        onClick={handleChange}
        disabled={isDisabled}
        checked={isChecked}
        type="radio"
        value={isDefined(value) ? value : defaultValue.toString()}
        className={`${
          sizeMap[size]
        } form-radio bg-grey-50 border-none text-green-500 ring-grey-400 ring-2 ring-offset-2 focus:ring-offset-2 focus:ring-grey-400 ${
          isDisabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      />
    </div>
  );
};
