import { MonographType } from "@eolas-medical/core";
import { InnerPageWrapper } from "UIKit";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { titleLookup } from "./AdditionalContentHome.strings";
import {
  GroupedSectionWithId,
  useGetAdditionalContentSummary,
} from "./hooks/useGetAdditionalContentSummary";
import { LocalItemsList } from "shared/components/Lists";
import { useCallback } from "react";
import { AdditionalContentGroup } from "./components/AdditionalContentGroup";
import { useBnfSearchAnalytics } from "../../../hooks/useBnfSearchAnalytics";

const AdditionalContentHome = ({ type }: { type: MonographType }) => {
  const { data, isInitialLoading } = useGetAdditionalContentSummary({ type });
  const onBnfSearch = useBnfSearchAnalytics(
    type === "bnf" ? "additionalContentAdult" : "additionalContentChild",
  );
  const renderMedication = useCallback((item: GroupedSectionWithId) => {
    return <AdditionalContentGroup group={item} />;
  }, []);

  return (
    <InnerPageWrapper>
      <BnfHeader type={type} goBackBehaviourPreference="removeFromPath" title={titleLookup[type]} />
      <LocalItemsList
        items={data ?? []}
        isLoading={isInitialLoading}
        renderItem={renderMedication}
        isSearchable={false}
        onSearchAnalytics={onBnfSearch}
      />
    </InnerPageWrapper>
  );
};

export const AdditionalContentHomeAdult = () => <AdditionalContentHome type="bnf" />;
export const AdditionalContentHomeChild = () => <AdditionalContentHome type="bnfc" />;
