import { clinicalQuestionsClient, RecentQuestion, userStore } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { AskEolasVariants } from "Components/AskEolas/AskEolasHome";
import { useMemo } from "react";

const sortByDate = (recentQuestions: RecentQuestion[]) =>
  [...recentQuestions].sort((a, b) => b.createdAt.localeCompare(a.createdAt));

export const useGetRecentQuestions = (type: AskEolasVariants) => {
  const { data, error, isFetching } = useQuery({
    queryKey: ["getRecentQuestions"],
    queryFn: () =>
      clinicalQuestionsClient.getRecentQuestionsByUser({
        userID: userStore.userID,
      }),
    // @TODO: enable for question history for spaces when api available
    enabled: Boolean(userStore.userID) && type === "knowledge",
  });

  const sortedData = useMemo(() => sortByDate(data?.recentQuestions ?? []), [data]);

  return { list: sortedData, error, isFetching };
};
