import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { SectionNationalBodyTileProps } from "../EolasSectionTile.types";

export const SectionNationalBodyTile = ({
  title,
  onClick,
  isDisabled = false,
}: SectionNationalBodyTileProps) => {
  return (
    <EolasTile isDisabled={isDisabled} variant="noIcon" primaryText={title} onClick={onClick} />
  );
};
