import { IconButton, SectionIcon, Text } from "UIKit";
import { ContentSection } from "modules/contentRepository/types";
import { useCallback } from "react";
import { Variants, motion } from "framer-motion";
import { DeleteIcon, DragIcon, EditMonoIcon } from "Assets";
import { ContentSectionVariants } from "shared/pages/ContentRepository/types";
import { twMerge } from "tailwind-merge";
import { sectionStore } from "@eolas-medical/core";
import { useRoleSwitcher } from "Components/Navigation/hooks";
import { useGetFileCount } from "Pages/Spaces/hooks/useGetFileCount";

export interface ContentSectionTileProps {
  section: ContentSection;
  isDragging?: boolean;
  isDraggable?: boolean;
  deleteDisabled?: boolean;
  onClick?: (section: ContentSection) => void;
  onEdit?: (section: ContentSection) => void;
  onDelete?: (section: ContentSection) => void;
  "data-testid"?: string;
  variant?: ContentSectionVariants;
}

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

/**
 * @deprecated This component is deprecated and should not be used in new code.
 * Use the `EolasSectionTile` component instead.
 */
const ContentSectionTile = ({
  section,
  isDragging = false,
  isDraggable = false,
  deleteDisabled,
  onClick,
  onEdit,
  onDelete,
  "data-testid": datTestId,
  variant,
  ...props
}: ContentSectionTileProps) => {
  const { childrenType } = sectionStore.getSection(section.id);
  const { isOnAdminPanel } = useRoleSwitcher();

  const counts = useGetFileCount(section.id);

  const fileCount = (isOnAdminPanel ? counts?.allFiles : counts?.publishedFiles) ?? 0;

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onClick && onClick(section);
    },
    [section, onClick],
  );

  const handleEdit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onEdit && onEdit(section);
    },
    [section, onEdit],
  );

  const handleDelete = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete && onDelete(section);
    },
    [section, onDelete],
  );

  return (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={`
        grid grid-cols-12 transition-all bg-white h-24 md:h-32
        p-5 rounded-lg shadow-sm
      ${onClick ? "cursor-pointer" : ""}
      ${isDragging ? "tile-border__dragging" : "tile-border"}
    `}
      onClick={handleClick}
      data-testid={datTestId}
      {...props}
    >
      {isDraggable ? (
        <div className="row-start-1 col-start-1">
          <DragIcon
            data-testid="drag-icon"
            className="col-span-1 text-grey-500 self-start h-4 w-4"
          />
        </div>
      ) : null}

      <div
        className={twMerge(
          "row-start-1 col-start-1 col-end-11 flex items-center",
          isDraggable || variant === "titleAndIcon" || variant === "titleIconAndDescription"
            ? "ml-6 md:ml-8"
            : "",
        )}
      >
        {variant === "titleAndIcon" || variant === "titleIconAndDescription" ? (
          <SectionIcon iconClassname="w-8 h-8 md:w-10 md:h-10" iconURL={section.iconUrl || ""} />
        ) : null}
        <div className="flex flex-col">
          <p className="text-sm font-bold md:text-xl ml-5 flex-1 line-clamp-2">{section.name}</p>
          {variant === "titleAndDescription" ||
            (variant === "titleIconAndDescription" ? (
              <p className="text-xs text-grey-700 md:text-lg font-semibold ml-5 flex-1 line-clamp-2 mt-2">
                {section.description}
              </p>
            ) : null)}
        </div>
      </div>
      {childrenType === "file" ? (
        <div className="row-start-1 col-start-9 col-end-11 sm:col-start-11 sm:col-end-12 self-start justify-end flex py-1">
          <Text level={1} className="font-semibold text-grey-600">{`${fileCount} file${
            fileCount === 1 ? "" : "s"
          }`}</Text>
        </div>
      ) : null}
      <div className="row-start-1 row-end-2 col-start-12 flex flex-col justify-between">
        {onEdit ? (
          <div className="self-end">
            <IconButton
              size="sm"
              onClick={handleEdit}
              icon={<EditMonoIcon />}
              data-testid={`edit-section-${section.name}`}
            />
          </div>
        ) : null}
        {onDelete ? (
          <div className="self-end">
            <IconButton
              disabled={deleteDisabled}
              size="sm"
              onClick={handleDelete}
              icon={<DeleteIcon />}
              data-tip
              data-for={`${section.id}_section_with_files`}
              data-testid={`delete-section-${section.name}`}
            />
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default ContentSectionTile;
