import { FC } from "react";
import { Text, TextLevel } from "UIKit";

export interface SimpleButtonProps {
  backgroundColor: string;
  text?: string;
  textColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  textLevel?: TextLevel;
  buttonClassName?: string;
}

export const SimpleButton: FC<SimpleButtonProps> = ({
  backgroundColor,
  onClick,
  textColor,
  text,
  textLevel = 3,
  buttonClassName,
}: SimpleButtonProps) => {
  return (
    <button onClick={onClick} className={buttonClassName}>
      <div
        className={`flex flex-row flex-shrink-0 items-center justify-center h-10 rounded-3xl ${textColor} ${backgroundColor} mx-1 my-0.5 sm:my-3 px-3 py-5 border border-grey-300`}
      >
        <Text className="self-center mx-1" level={textLevel}>
          {text}
        </Text>
      </div>
    </button>
  );
};
