import { FullAccessLevel, userStore } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { ActiveTab, LDFlagNames } from "Utilities/types";

export const isAccessLevelInAdminGroup = (level: FullAccessLevel | null) =>
  level === "admin" || level === "limitedAdmin";

export const useGetAdminStatus = ({
  activeTab,
}: {
  activeTab: ActiveTab;
}): FullAccessLevel | null => {
  const { appUserData, orgUserData } = userStore;
  const { flags } = useLaunchDarkly();
  if (flags[LDFlagNames.READ_ONLY_ACTIVATED]) {
    return null;
  }

  if (activeTab === "spaces") {
    if (appUserData.accessLevel === "admin") {
      if (appUserData.limitedAccess?.length) {
        return "limitedAdmin";
      }
      return "admin";
    }
    return "User";
  }

  if (activeTab === "organisation") {
    if (!orgUserData) {
      return null;
    }
    if (orgUserData.role === "admin") {
      if (orgUserData.limitedAccess?.length) {
        return "limitedAdmin";
      }
      return "admin";
    }
    return "User";
  }

  return null;
};
