import {
  CommunityLevelSection,
  NationalResourceEntityType,
  searchClient,
  sectionStore,
} from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useQuery } from "@tanstack/react-query";
import communityKeys from "./community.queryKeys";
import { useGetClinicalCalcStatus } from "./useGetClinicalCalcStatus";
import { EOLAS_CALCULATOR } from "Pages/Knowledge/pages/ClinicalCalculators/types";

export interface NationalBody {
  name: string;
  id: string;
}

type Props = {
  entityType: NationalResourceEntityType;
  isEnabled?: boolean;
  bodiesToInject?: { id: string; name: string }[];
};

const nationalBodyMapper = (data?: string[]): NationalBody[] => {
  return (data ?? []).map((name, index) => ({ name, id: index.toString() }));
};
const useGetNationalBodies = ({ entityType, isEnabled = true }: Props) => {
  const { supportedEolasRegion } = useSupportedRegion();
  const {
    isEolasCreatinineCalcEnabled,
    isEolasGentCalcEnabled,
    isEolasIdealBodyWeightCalcEnabled,
  } = useGetClinicalCalcStatus();

  const areAllEolasCalcsDisabled =
    !isEolasGentCalcEnabled && !isEolasCreatinineCalcEnabled && !isEolasIdealBodyWeightCalcEnabled;

  const { data, isFetching, isError, error } = useQuery(
    communityKeys.bodies(entityType),
    () =>
      searchClient.searchNationalBodies(
        entityType,
        supportedEolasRegion,
        sectionStore.organisationID,
      ),
    { enabled: isEnabled },
  );

  let onlineNationalBodies = nationalBodyMapper(data ?? []);

  if (entityType === NationalResourceEntityType.CALCULATOR) {
    const clinicalCalcsMainSectionID = sectionStore
      .getMainSectionList("community")
      .find(({ icon }) => icon === CommunityLevelSection.clinicalCalculators)?.id;

    const shouldDisplayEolasCalculators = clinicalCalcsMainSectionID
      ? sectionStore
          .getChildrenOrder(clinicalCalcsMainSectionID)
          // This is to hide eolas calculators that have been excluded for the current organisation
          .filter(({ icon }) => icon && !sectionStore.excludedEolasCalculatorIcons.includes(icon))
          .length > 0
      : true;

    onlineNationalBodies =
      shouldDisplayEolasCalculators && !areAllEolasCalcsDisabled
        ? [{ name: EOLAS_CALCULATOR, id: "eolasCalculator" }, ...onlineNationalBodies]
        : onlineNationalBodies ?? [];
  }

  const filteredNationalBodies = onlineNationalBodies
    ? onlineNationalBodies.sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return {
    nationalBodies: filteredNationalBodies,
    gettingNationalBodies: isFetching,
    error,
    isError,
  };
};

export default useGetNationalBodies;
