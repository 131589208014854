import { ActiveTab } from "Utilities/types";
import { createContext } from "react";

export interface NavigationContextValue {
  activeTab: ActiveTab;
  drawerExpanded: boolean;
  drawerHidden: boolean;
  hasSpacesTab: boolean;
  hasOrganisationTab: boolean;
  hasSelectedSpace: boolean;
  hasKnowledgeTab: boolean;
  isOnSpacesRoot: boolean;
  isOnSelectSpaceRoot: boolean;
  isOnOrganisationRoot: boolean;
  isOnMeRoot: boolean;
  isOnKnowledgeRoot: boolean;
  goMeTab: () => void;
  goToOrganisationTab: () => void;
  goToSpaceTab: () => void;
  goToSelectSpace: () => void;
  goToKnowledgeTab: () => void;
  toggleDrawerExpanded: (expanded: boolean) => void;
  toggleDrawerHidden: (hide: boolean) => void;
}

const NavigationContext = createContext<NavigationContextValue>({
  activeTab: "select-space",
  drawerExpanded: false,
  drawerHidden: false,
  hasSpacesTab: true,
  hasOrganisationTab: false,
  hasSelectedSpace: false,
  hasKnowledgeTab: false,
  isOnSpacesRoot: true,
  isOnSelectSpaceRoot: false,
  isOnOrganisationRoot: false,
  isOnMeRoot: false,
  isOnKnowledgeRoot: false,
  goMeTab: () => {
    return;
  },
  goToOrganisationTab: () => {
    return;
  },
  goToSpaceTab: () => {
    return;
  },
  goToSelectSpace: () => {
    return;
  },
  goToKnowledgeTab: () => {
    return;
  },
  toggleDrawerExpanded: () => {
    return;
  },
  toggleDrawerHidden: () => {
    return;
  },
});

export default NavigationContext;
