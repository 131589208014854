export const titleColors = [
  // Blue
  "rgba(59, 130, 246)",
  // Green
  "rgba(148, 211, 162)",
  // Yellow
  "rgba(251, 191, 36)",
  // Orange
  "rgba(249, 115, 22)",
  // Pink
  "rgba(231, 129, 215)",
  // Blue
  "rgba(59, 130, 246)",
];
