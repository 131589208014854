import { EolasMainSection, NotificationType } from "@eolas-medical/core";
import { useIcons } from "Hooks";
import { SpaceIcon } from "Assets";
import { NotificationTypeMapResult } from "../types";

const useWebNotificationMapper = (): Record<NotificationType, NotificationTypeMapResult> => {
  const { getMeTabIcon, getMainSectionIcon } = useIcons();

  return {
    accessRequestApproved: {
      icon: SpaceIcon,
      notificationSection: "generic",
    },
    checklistComplete: {
      icon: getMainSectionIcon("completedChecklist" as EolasMainSection),
      notificationSection: "space",
      notificationSubSection: "completedChecklist" as EolasMainSection,
    },
    checklistIssue: {
      icon: getMainSectionIcon("checklists" as EolasMainSection),
      notificationSection: "space",
      notificationSubSection: "checklists" as EolasMainSection,
    },
    communicationPortal: {
      icon: getMainSectionIcon("communicationPortal" as EolasMainSection),
      notificationSection: "organisation",
      notificationSubSection: "communicationPortal" as EolasMainSection,
    },
    departmentAcceptedInvitation: {
      icon: SpaceIcon,
      notificationSection: "generic",
    },
    departmentAccessRequest: {
      icon: SpaceIcon,
      notificationSection: "generic",
    },
    incidents: {
      icon: getMainSectionIcon("majorIncidents" as EolasMainSection),
      notificationSection: "organisation",
      notificationSubSection: "majorIncidents" as EolasMainSection,
    },
    myPortfolioFeedbackDeclined: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    myPortfolioFeedbackReceived: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    myPortfolioFeedbackRequest: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    myPortfolioWizard: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    newsFeed: {
      icon: getMainSectionIcon("newsFeed" as EolasMainSection),
      notificationSection: "space",
      notificationSubSection: "newsFeed" as EolasMainSection,
    },
    supervisorRemoved: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    supervisorRequest: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    supervisorRequestAccepted: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    supervisorRequestDeclined: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
    superviseeRemoved: {
      icon: getMeTabIcon("MyPortfolioIcon"),
      notificationSection: "me",
      notificationSubSection: "myPortfolio" as EolasMainSection,
    },
  };
};

export default useWebNotificationMapper;
