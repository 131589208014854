import { useTranslation } from "react-i18next";
import { EolasProgressBar } from "UIKit/EolasProgressBar/EolasProgressBar";
import { Loader } from "UIKit/Loader";
import { InnerModalWrapper, ModalBody, ModalHeader } from "UIKit/Modal";
import { Title } from "UIKit/Typography";

export type WholeAppLoaderModalProps = { text?: string; progressBarId?: string | null };

export const WholeAppLoaderModal = ({ text, progressBarId }: WholeAppLoaderModalProps) => {
  const { t } = useTranslation();
  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{text || t("whole_app_loading")}</Title>
      </ModalHeader>
      <ModalBody className="items-center justify-center">
        {progressBarId ? (
          <EolasProgressBar progressBarId={progressBarId} shouldFillParentContainerWidth />
        ) : (
          <Loader />
        )}
      </ModalBody>
    </InnerModalWrapper>
  );
};
