import { ContentRepositoryId } from "modules/contentRepository/types";
import { ContentItem, wait } from "@eolas-medical/core";

import { useSpacesContext } from "modules/spaces";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useGetLimitedAccess } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { useQuery } from "@tanstack/react-query";
import { getIdMapToIncludeForContentSearch } from "shared/functions/getIdMapToIncludeForContentSearch";

export enum ContentType {
  Section = "section",
  Item = "item",
}

export enum ValidFileChildren {
  deepLink = "deepLink",
  file = "file",
}

export type UseGetAllContentRepositoryProps = {
  contentRepositoryId: ContentRepositoryId;
  mode: "list" | "map";
  shouldDebounce?: boolean;
};

const blankData: { map: Record<string, string>; list: ContentItem[] } = { map: {}, list: [] };

export function useGetAllContentRepository(props: {
  contentRepositoryId: string;
  mode: "map";
  shouldDebounce?: boolean;
}): {
  data: { map: Record<string, string> };
  isFetching: boolean;
};
export function useGetAllContentRepository(props: {
  contentRepositoryId: string;
  mode: "list";
  shouldDebounce?: boolean;
}): {
  data: { list: ContentItem[] };
  isFetching: boolean;
};
export function useGetAllContentRepository({
  contentRepositoryId,
  mode,
  shouldDebounce,
}: UseGetAllContentRepositoryProps) {
  const { isAdmin: isInAdminMode } = useSpacesContext();
  const { activeTab } = useEolasNavigation();
  const adminStatus = useGetAdminStatus({ activeTab });
  const limitedAccess = useGetLimitedAccess({ activeTab });

  const { data, isFetching } = useQuery({
    queryKey: [
      "getAllContent",
      contentRepositoryId,
      limitedAccess,
      isInAdminMode,
      adminStatus,
      shouldDebounce,
    ],
    queryFn: async () => {
      if (shouldDebounce) {
        await wait(200);
      }
      if (mode === "list") {
        return getIdMapToIncludeForContentSearch({
          isInAdminMode,
          adminStatus,
          limitedAccess,
          sectionId: contentRepositoryId,
          mode: "list",
        });
      }
      return getIdMapToIncludeForContentSearch({
        isInAdminMode,
        adminStatus,
        limitedAccess,
        sectionId: contentRepositoryId,
        mode: "map",
      });
    },
    networkMode: "always",
  });

  return { data: data ?? blankData, isFetching };
}
