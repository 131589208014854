import { useMutation, useQueryClient } from "@tanstack/react-query";
import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { loginWithToken, logout } from "Pages/Authentication/auth.actions";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { LDFlagNames } from "Utilities/types";
import useUserDetails from "Hooks/useUserDetails";
import { hasStringProp, sectionStore, userStore } from "@eolas-medical/core";
import { syncWebAppData } from "Hooks";
import { useNavigateToSpace } from "Pages/Spaces/pages/SelectSpace/hooks/useNavigateToSpace";
import { useHistory } from "react-router-dom";

type Props = {
  callbackUrl?: string;
};

export const useAutoLogin = (props?: Props) => {
  const { onSelectSpace } = useSelectSpaceActions();
  const { isSharedAccount: isCurrentAccountDataSharedAccount } = useUserDetails();
  const { navigateToSpace } = useNavigateToSpace();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { flags } = useLaunchDarkly();
  const getContentInParallel = flags[LDFlagNames.MAIN_SECTION_CONTENT_IN_PARALLEL];

  const {
    mutate: autoLogin,
    isError,
    isLoading,
  } = useMutation({
    mutationKey: ["autoLogin"],
    mutationFn: async (token: string) => {
      const { data: loginData } = await loginTokenService.loginWithToken({
        token,
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });

      let shouldLoginWithToken = true;

      if (sectionStore.appID === loginData.appID && userStore.userID === loginData.userID) {
        try {
          await syncWebAppData({ isFromSoftLogoutState: true, queryClient, getContentInParallel });
          if (!userStore.userSession.isLoggedIn) {
            userStore.setLoggedStatus(true);
          }
          navigateToSpace(
            hasStringProp(sectionStore.data.app, "name") ? sectionStore.data.app.name : "",
          );
          shouldLoginWithToken = false;
        } catch {
          // We are not interested in logging anything here, as the logic will simply fall back to login with token
        }
      }

      if (shouldLoginWithToken) {
        await logout();
        await loginWithToken(loginData);
        await onSelectSpace(loginData.appID);
      }

      if (props?.callbackUrl) {
        history.replace(props.callbackUrl);
      }
    },
  });

  return { autoLogin, isError, isLoading, isCurrentAccountDataSharedAccount };
};
