import { useTranslation } from "react-i18next";
import { DeleteModal, DeleteModalProps, SuccessModal } from "UIKit";
import useRemoveFavourite from "modules/myFavourites/data/useRemoveFavourite";
import { trackEvent } from "API/Analytics";
import {
  AnalyticsEvents,
  FavouriteRemovedPayload,
  PossibleFavouriteOrigins,
  favouriteEntityTypeToMainSectionTypeMapper,
} from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";

export type HandleClickRemoveFavouriteProps = {
  entityId: string;
  favouriteId: string;
  entityType: FavouriteEntityType | null;
  title: string;
  // this can be derived from entityType except for FavouriteEntityType.FILE
  originForAnalytics?: PossibleFavouriteOrigins;
  modalTitle?: string;
  modalDescription?: string;
  wasFileDeleted?: boolean;
};

export const RemoveFavouriteModal = ({
  onCloseModal,
  entityId,
  favouriteId,
  entityType,
  title,
  originForAnalytics,
  modalTitle,
  modalDescription,
  wasFileDeleted,
  ...rest
}: Omit<DeleteModalProps, "title" | "onDelete"> & HandleClickRemoveFavouriteProps) => {
  const { t } = useTranslation();
  const { removeFavourite, isRemovingFavourite, didRemoveFavouriteSuccess, removeFavouriteError } =
    useRemoveFavourite({ entityId, entityType });

  if (didRemoveFavouriteSuccess) {
    return <SuccessModal onComplete={onCloseModal} text={t("my_favourites_removed_successful")} />;
  }

  let origin: PossibleFavouriteOrigins = "unknownMainSectionType";
  if (originForAnalytics) {
    origin = originForAnalytics;
  } else if (entityType) {
    origin = favouriteEntityTypeToMainSectionTypeMapper(entityType) ?? "unknownMainSectionType";
  }

  return (
    <div data-testid="delete-section-modal">
      <DeleteModal
        title={modalTitle ?? t("my_favourites_remove_confirmation_title")}
        description={modalDescription ?? t("my_favourites_remove_confirmation_description")}
        onCloseModal={onCloseModal}
        onDelete={() => {
          trackEvent<FavouriteRemovedPayload>(AnalyticsEvents.FAVOURITE_REMOVED, {
            fileId: entityId,
            origin,
            wasFileDeleted,
            title,
          });
          removeFavourite(favouriteId);
        }}
        isLoading={isRemovingFavourite}
        confirmBtnTxt={t("general_remove")}
        iconType="HeartBrokenIcon"
        error={removeFavouriteError ? t("my_favourites_remove_error") : undefined}
        {...rest}
      />
    </div>
  );
};
