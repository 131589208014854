import { useTranslation } from "react-i18next";
import * as icons from "Assets/Icons";
import { Text } from "UIKit";
import { EolasIcons } from "Assets/Icons";
import useEolasNavigation from "../../context/useEolasNavigation";
import useUserDetails from "Hooks/useUserDetails";
import { useSpaceTabName } from "Components/Navigation/hooks/useSpaceTabName";
import { observer } from "mobx-react-lite";

export const MobileNavigation = observer(() => {
  const { t } = useTranslation();
  const { isSharedAccount } = useUserDetails();
  const {
    activeTab,
    hasSpacesTab,
    hasOrganisationTab,
    hasSelectedSpace,
    hasKnowledgeTab,
    goMeTab,
    goToOrganisationTab,
    goToSpaceTab,
    goToSelectSpace,
    goToKnowledgeTab,
  } = useEolasNavigation();

  const { spacesTabName, organisationTabName } = useSpaceTabName();

  return (
    <div
      style={{ left: "50%", transform: "translate(-50%)" }}
      className={`
        flex items-center space-x-6 py-2 px-8 bg-white shadow-lg rounded-full
        fixed bottom-4 z-40
      `}
    >
      {!isSharedAccount && (
        <NavigationItem
          onClick={goMeTab}
          activeClassname="text-black"
          label={t("navigation_me_tab")}
          pillActiveClassname="bg-yellow-100"
          isActive={activeTab === "my-profile"}
          icon="PersonIcon"
        />
      )}

      {!hasSelectedSpace && (
        <NavigationItem
          activeClassname="text-black"
          pillActiveClassname="bg-primary-focus"
          onClick={goToSelectSpace}
          isActive={activeTab === "select-space"}
          label={t("navigation_department_tab")}
          icon="SpacesIcon"
        />
      )}

      {hasSelectedSpace && (
        <>
          {hasSpacesTab && (
            <NavigationItem
              onClick={goToSpaceTab}
              activeClassname="text-black"
              pillActiveClassname="bg-primary-focus"
              isActive={activeTab === "spaces"}
              label={spacesTabName || t("navigation_department_tab")}
              icon="SpacesIcon"
            />
          )}

          {hasOrganisationTab && (
            <NavigationItem
              onClick={goToOrganisationTab}
              activeClassname="text-black"
              isActive={activeTab === "organisation"}
              label={organisationTabName || t("navigation_hospital_tab")}
              pillActiveClassname="bg-orange-100"
              icon="OrganisationIcon"
            />
          )}
        </>
      )}

      {hasKnowledgeTab && (
        <NavigationItem
          onClick={goToKnowledgeTab}
          label={t("navigation_knowledge_tab")}
          isActive={activeTab === "knowledge"}
          activeClassname="text-black"
          pillActiveClassname="bg-green-100"
          icon="KnowledgeHubIcon"
        />
      )}
    </div>
  );
});

interface NavigationItemProps {
  label: string;
  testId?: string;
  icon: EolasIcons;
  isActive: boolean;
  pillActiveClassname?: string;
  activeClassname?: string;
  onClick(): void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  icon,
  label,
  isActive,
  activeClassname = "",
  pillActiveClassname = "",
  onClick,
}: NavigationItemProps) => {
  const IconComponent = icon ? icons[icon] : null;
  return (
    <div
      onClick={onClick}
      className={`flex flex-col items-center space-y-1 cursor-pointer ${
        isActive ? activeClassname : "text-grey-600"
      }`}
    >
      <div
        className={`
        flex items-center justify-center h-10 w-10 rounded-full
        ${isActive ? pillActiveClassname : ""}
      `}
      >
        {IconComponent && <IconComponent className="h-5 w-5" />}
      </div>

      <Text level={3}>{label}</Text>
    </div>
  );
};
