import { useState } from "react";
import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import {
  Input,
  Title,
  Toggle,
  Textarea,
  FormError,
  ModalBody,
  DatePicker,
  FilePicker,
  FormElement,
  SuccessModal,
  ModalHeader,
  WizardProgress,
  InnerModalWrapper,
  ModalWizardButtons,
} from "UIKit";

import { FilePickerAllowedTypes, LDFlagNames } from "Utilities/types";
import { useIsScrollable, useAllowedFileTypes } from "Hooks";

import { AddItemModalProps } from "../types";
import { AddOrganisation } from "./AddOrganisation";
import { useAddEolasFile } from "../useAddEolasFile";
import { SelectOrganisation } from "./SelectOrganisation";
import { useLaunchDarkly } from "Contexts";

interface AddItemWithOrganisationModalProps extends AddItemModalProps {
  isExpired?: boolean;
  setShouldCloseOnOverlayClick: (shouldClose: boolean) => void;
}

const fileTypes: FilePickerAllowedTypes[] = ["pdf", "link", "ms-office"];

export const AddItemWithOrganisationModal: React.FC<AddItemWithOrganisationModalProps> = ({
  file,
  onCloseModal,
  subSectionId,
  mainSectionID,
}: AddItemWithOrganisationModalProps) => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();

  const [isAddingGuideline, setIsAddingGuideline] = useState(false);
  const allowedFilesTypes = useAllowedFileTypes(fileTypes);
  const isGuidelineExpiryMandatory = flags[LDFlagNames.MAKE_GUIDELINE_EXPIRY_MANDATORY];

  const {
    step,
    error,
    control,
    onSubmit,
    isLoading,
    onStepBack,
    formErrors,
    formMethods,
    isSuccessful,
    hasExpiryDate,
    filePickerType,
    filePickerValue,
    onSelectFileType,
    onFilePickerChange,
  } = useAddEolasFile({
    maxSteps: 3,
    subSectionId,
    mainSectionID,
    eolasFile: file,
  });

  const { ref, ScrollHint } = useIsScrollable();

  if (isSuccessful) {
    return (
      <SuccessModal
        onComplete={onCloseModal}
        text={
          !file ? `${mainSectionID}_add_new_file_success` : `${mainSectionID}_edit_file_success`
        }
      />
    );
  }

  if (isAddingGuideline) {
    return (
      <AddOrganisation mainSectionId={mainSectionID} onClose={() => setIsAddingGuideline(false)} />
    );
  }

  return (
    <InnerModalWrapper>
      <WizardProgress maxSteps={3} currentStep={step} />

      <ModalHeader>
        <Title level={5} className="text-center">
          {t("guidelines_add_file_modal_title")}
        </Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <FormProvider {...formMethods}>
          <form onSubmit={onSubmit} className={"add-file-form"}>
            <div className="add-file-form__content max-h-60vh" ref={ref}>
              {step === 0 && (
                <FilePicker
                  required
                  isDisabled={isLoading}
                  value={filePickerValue}
                  onChange={onFilePickerChange}
                  error={formErrors.key?.message}
                  onSelectType={onSelectFileType}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  fileType={filePickerType as any}
                  allowedFileTypes={allowedFilesTypes}
                />
              )}

              {step === 1 && (
                <div className="space-y-2">
                  {!isGuidelineExpiryMandatory && (
                    <FormElement
                      control={control}
                      id="hasExpiryDate"
                      label={t(`${mainSectionID}_expiry_label`)}
                    >
                      {({ value, ...field }) => {
                        return (
                          <Toggle
                            checked={value}
                            {...field}
                            label={t(`${mainSectionID}_expiry_checkbox_text`)}
                          />
                        );
                      }}
                    </FormElement>
                  )}

                  {hasExpiryDate && (
                    <FormElement
                      required={hasExpiryDate}
                      id="expiryDate"
                      control={control}
                      label={t(`${mainSectionID}_expiry_picker_label`)}
                    >
                      {({ value, onChange }, { error }) => {
                        return (
                          <DatePicker
                            error={error}
                            onChange={onChange}
                            disabled={isLoading}
                            selected={isValid(value) && value}
                          />
                        );
                      }}
                    </FormElement>
                  )}

                  <FormElement
                    required
                    id="name"
                    control={control}
                    label={t(`${mainSectionID}_file_name_label`)}
                  >
                    {(field) => (
                      <Input
                        {...field}
                        size="lg"
                        disabled={isLoading}
                        data-testid="fileName-input"
                        placeholder={t(`${mainSectionID}_file_name_placeholder`)}
                      />
                    )}
                  </FormElement>

                  <FormElement
                    required
                    id="description"
                    control={control}
                    label={t(`${mainSectionID}_keywords_label`)}
                  >
                    {(field) => (
                      <Textarea
                        {...field}
                        disabled={isLoading}
                        data-testid="fileDescription-input"
                        placeholder={t(`${mainSectionID}_keywords_placeholder`)}
                      />
                    )}
                  </FormElement>
                </div>
              )}

              {step === 2 && (
                <SelectOrganisation onAddOrganization={() => setIsAddingGuideline(true)} />
              )}
            </div>

            <ModalWizardButtons
              maxSteps={3}
              currentStep={step}
              onBack={onStepBack}
              isLoading={isLoading}
              backLabel={t("addFile_wizard_back_button")}
              nextLabel={t(
                step === 2 ? "addFile_wizard_submit_button" : "addFile_wizard_next_button",
              )}
            />

            <FormError error={error} />
          </form>
        </FormProvider>
      </ModalBody>
    </InnerModalWrapper>
  );
};
