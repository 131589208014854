import { SendOutlineIcon } from "Assets";
import { IconButton } from "UIKit/Button";

type Props = {
  id: string;
  onShareFile: () => void;
};
export const ShareFileButton = ({ id, onShareFile }: Props) => {
  const handleShare = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onShareFile();
  };
  return (
    <IconButton
      type="button"
      variant="rounded"
      icon={<SendOutlineIcon />}
      data-testid={`share-button-${id}`}
      onClick={handleShare}
      // Rotated svgs can make the svg look off-center, so we need to adjust the position slightly
      className="[&_svg]:-translate-x-px [&_svg]:translate-y-px bg-transparent"
    />
  );
};
