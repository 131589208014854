import {
  userStore,
  AnalyticsEvents,
  sectionStore,
  authenticationClient,
  profilesClient,
  eolasLogger,
  myFavouritesStore,
} from "@eolas-medical/core";
import { addBreadcrumb, setUser as setSentryUser } from "@sentry/react";
import Smartlook from "smartlook-client";
import { identifyUser, resetAnalytics, trackEvent } from "API/Analytics";
import { LoginWithTokenResponse } from "API/LoginTokenService";
import { webStore } from "Stores/WebStore";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { localSearchStore } from "Stores/localSearch/localSearch.store";

interface LoginData {
  email: string;
  password: string;
}

interface SignUpData extends LoginData {
  givenName: string;
  familyName: string;
}

export const login = async ({
  email,
  password,
  isFederated,
  isFederatedFeatureEnabled,
}: {
  email: string;
  password?: string;
  isFederated?: boolean;
  isFederatedFeatureEnabled?: boolean;
}) => {
  await authenticationClient.signIn({
    email,
    password,
    isFederated,
    isFederatedFeatureEnabled,
  });
  const completeSignInPromise = new Promise<void>((resolve, reject) => {
    promiseCallbacks = { resolve, reject };
    setTimeout(() => {
      promiseCallbacks?.reject(new Error("Login timeout"));
    }, 20000);
  });
  await completeSignInPromise;
};

let promiseCallbacks: { resolve: () => void; reject: (error: unknown) => void } | null = null;

export const onSuccessfulLogin = async (sub: string) => {
  try {
    const user = await profilesClient.getUserProfile(sub);

    if (!user.id || !user.email) {
      addBreadcrumb({
        category: "login",
        message: "Failed login after successful Auth with cognito",
      });

      authenticationClient.signOut();

      throw new Error("getUserQuery returned null");
    }

    identifyUser(user);

    setSentryUser({ id: user.id });

    if (process.env.REACT_APP_SENTRY_ENABLED !== "false" && userStore.appUserData.appID) {
      Smartlook.identify(user.id, {
        email: user.email,
        departmentId: userStore.appUserData.appID,
      });
    }

    const markCompletedTour = localStorage.getItem("acceptedTermsAndCond") === "true";
    localStorage.removeItem("acceptedTermsAndCond");
    if (markCompletedTour && !user.completedTour) {
      const completedTour = new Date().toISOString();
      const updatedUser = await profilesClient.updateUserProfile(user.id, {
        ...user,
        completedTour,
      });
      userStore.updateData({ user: updatedUser });
    }
    webStore.setAppLastFetchTimestamp(new Date().toISOString());
    userStore.loginUser({ user });
    trackEvent(AnalyticsEvents.USER_LOGGED_IN);
    trackEvent(AnalyticsEvents.EOLAS_SESSION_START);
    promiseCallbacks?.resolve();
  } catch (error) {
    eolasLogger.error(new Error("Failure after successful authentication"), { error });
    logout();
    promiseCallbacks?.reject(error);
  } finally {
    promiseCallbacks = null;
  }
};

export function storeClearup() {
  contentDbStore.clearStore();
  localSearchStore.clearStore();
  userStore.clearStore();
  sectionStore.clearStore();
  myFavouritesStore.clearStore();
  webStore.setAppLastFetchTimestamp(null);
}

export const logout = async (isSoftMode = false) => {
  try {
    await authenticationClient.signOut(isSoftMode);
  } catch (error) {
    eolasLogger.error(error);
  }
};

export const onLogout = async () => {
  try {
    setSentryUser(null);
    resetAnalytics();
    storeClearup();
  } catch (error) {
    eolasLogger.error(error);
  }
};

export async function createAccount({ email, password, givenName, familyName }: SignUpData) {
  return authenticationClient.signUp({ email, password, givenName, familyName });
}

export async function resetPasswordSendCode(email: string) {
  return authenticationClient.forgotPassword({ email });
}

export async function verifyEmail(email: string, code: string) {
  return authenticationClient.confirmSignUp({ email, code });
}

export async function resendVerificationCode(email: string) {
  return authenticationClient.resendSignUp({ email });
}

export async function forgotPasswordSubmit(
  email: string,
  password: string,
  confirmationCode: string,
) {
  return authenticationClient.forgotPasswordSubmit({
    email,
    code: confirmationCode,
    newPassword: password,
  });
}

export const loginWithToken = async (response: LoginWithTokenResponse) => {
  addBreadcrumb({
    category: "SessionContext",
    message: "loginWithToken",
  });
  const { email, password } = response;

  await login({ email, password });

  trackEvent(AnalyticsEvents.USER_LOGGED_IN);
};
