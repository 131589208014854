import { Parser, ProcessNodeDefinitions } from "html-to-react";
import { Element } from "html-react-parser";
import * as React from "react";
import { makeDefaultEolasProcessingInstructions } from "./makeHtmlToReactNodeParser.config";

export type PossibleNode = Element | null | undefined;

export type ProcessingInstruction = {
  shouldProcessNode: (node: PossibleNode) => boolean;
  processNode: (node: Element, children: React.ReactNode, index?: number) => unknown;
};

export type MakeHtmlToReactNodeParserParams =
  | {
      mode: "defaultEolasStyles";
      overrideProcessingInstructions?: ProcessingInstruction[];
    }
  | { mode: "specifyAllInstructions"; processingInstructions: ProcessingInstruction[] };

export const makeHtmlToReactNodeParser = (params: MakeHtmlToReactNodeParserParams) => {
  const processNodeDefinitions = ProcessNodeDefinitions();
  let allProcessingInstructions = [
    {
      shouldProcessNode: (node: PossibleNode) => {
        if (!node) {
          return false;
        }
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  if (params.mode === "defaultEolasStyles") {
    allProcessingInstructions = [
      ...(params.overrideProcessingInstructions ?? []),
      ...makeDefaultEolasProcessingInstructions(),
      ...allProcessingInstructions,
    ];
  }

  if (params.mode === "specifyAllInstructions") {
    allProcessingInstructions = [...params.processingInstructions, ...allProcessingInstructions];
  }

  const convertHtmlToReactComponents = (htmlString: string | undefined) => {
    if (!htmlString) {
      console.error("No HTML string given to convertHtmlToReactComponents");
      return null;
    }
    const htmlToReactParser = Parser();
    const components = htmlToReactParser.parseWithInstructions(
      htmlString,
      () => true,
      allProcessingInstructions,
    );

    return components;
  };

  return { convertHtmlToReactComponents };
};

export const makeShouldProcessNode = ({
  tagName,
  className,
}: {
  tagName?: string;
  className?: string;
}): ProcessingInstruction["shouldProcessNode"] => {
  return (node) => {
    if (!node) {
      return false;
    }
    let shouldProcessNode = false;
    if (tagName) {
      shouldProcessNode = node.name === tagName;
    }
    if ((tagName && shouldProcessNode && className) || (!tagName && className)) {
      shouldProcessNode = node.attribs?.["class"] === className;
    }
    return shouldProcessNode;
  };
};

export const makeProcessNode = ({
  tagName,
  classNameToAdd,
  attributesToAdd = [],
  onClick,
}: {
  tagName: string;
  classNameToAdd: string;
  attributesToAdd?: string[];
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}): ProcessingInstruction["processNode"] => {
  return (element, children, index) => {
    const attributes: { [key: string]: string } = {};
    let mergedClassName = classNameToAdd;

    for (const attribute of attributesToAdd) {
      if (element.attribs[attribute]) {
        if (attribute === "class") {
          mergedClassName += ` ${element.attribs[attribute]}`;
        } else {
          attributes[attribute] = element.attribs[attribute];
        }
      }
    }
    if (element.attribs.target === "_blank") {
      attributes.target = "_blank";
    }

    if (element.attribs.href) {
      return React.createElement(
        tagName,
        {
          key: index,
          className: mergedClassName.trim(),
          href: element.attribs.href,
          onClick: onClick,
          ...attributes,
        },
        children,
      );
    }

    return React.createElement(
      tagName,
      { key: index, className: mergedClassName.trim(), onClick, ...attributes },
      children,
    );
  };
};
