import {
  EolasBaseFile,
  EolasFile,
  isDefined,
  isEolasFile,
  Maybe,
  MedusaItem,
  NoAccessContentItemResponse,
  NoAccessSectionResponse,
  OrganisationLevelSection,
  SectionNonAppsync,
  sectionStore,
} from "@eolas-medical/core";
import { findMedusaItemAndSection } from "Components/MasterSearch/helpers";
import { useFetchContentItemById } from "modules/contentRepository/data/useFetchContentItemById";
import { useFetchContentSectionById } from "modules/contentRepository/data/useFetchContentSectionById";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { useLocation } from "react-router-dom";
import { getSearchParams } from "Utilities/helpers";

const mapOldMedusaFile = (file: EolasFile) => {
  let medusaItem: MedusaItem | undefined;
  let medusaSubsectionId: string | undefined;
  // Example of description for legacy medusa files: "Paeds - Intravenous"
  const descriptionSplit = file.description?.split(" ");
  const isValidLegacyMedusaFileDescription = descriptionSplit && descriptionSplit.length >= 3;
  const medusaLegacyFileType = isValidLegacyMedusaFileDescription ? descriptionSplit[1] : undefined;
  const medusaFileRoute = isValidLegacyMedusaFileDescription ? descriptionSplit[2] : undefined;
  // Example of file info for legacy medusa files: "mono:1234"
  const infoSplit = file.info?.split(":");
  const monographNo = infoSplit?.length === 2 ? +infoSplit[1] : undefined;

  if (medusaFileRoute && medusaLegacyFileType && monographNo) {
    const medusaFileType = medusaLegacyFileType === "Paeds" ? "paediatric" : "adult";
    sectionStore.medusaSectionsWithItems.forEach((s) => {
      const item = !medusaItem
        ? s.items.find((i: MedusaItem) => {
            return (
              i.monographNo === monographNo &&
              i.route === medusaFileRoute &&
              i.type === medusaFileType
            );
          })
        : undefined;
      if (item) {
        medusaSubsectionId = s.id;
        medusaItem = item;
      }
    });
  }

  return { medusaItem, medusaSubsectionId };
};

export const useGetSpaceOrgOwnerIds = (): {
  spaceId: string | null;
  organisationId: string | null;
  didError: boolean;
  isLoading: boolean;
  medusaItem?: MedusaItem;
} => {
  const location = useLocation();
  const deeplinkRoot = location.pathname.split("/")[1];

  const {
    fileId,
    checklistId,
    sectionId,
    medusaItemId,
    organisationId: pathOrganisationId,
  } = getSearchParams({
    searchString: location.search,
    paramNameList: ["fileId", "checklistId", "sectionId", "medusaItemId", "organisationId"],
  });

  const isMedusaLegacyDeepLink =
    location.pathname.split("/")[3]?.split("?")[0] === OrganisationLevelSection.medusaMedications &&
    fileId !== null;

  const isMedusaNewDeepLink =
    location.pathname.split("/")[3]?.split("?")[0] === OrganisationLevelSection.medusaMedications &&
    medusaItemId !== null;

  const localSection = sectionId ? sectionStore.getSection(sectionId) : undefined;
  const { file: localFile } = useGetFile({ id: fileId });
  const localChecklist = checklistId ? sectionStore.getSection(checklistId) : undefined;

  const doesLocalSectionExist = isDefined(localSection?.id);
  const doesLocalChecklistExist = isDefined(localChecklist?.id);

  const isFetchSectionEnabled =
    isDefined(sectionId) && isDeeplinkRoot(deeplinkRoot) && !doesLocalSectionExist;
  const isFetchContentItemEnabled =
    isDefined(fileId) && !localFile && (isDeeplinkRoot(deeplinkRoot) || isMedusaLegacyDeepLink);
  const isFetchChecklistEnabled =
    isDefined(checklistId) && isDeeplinkRoot(deeplinkRoot) && !doesLocalSectionExist;

  const {
    contentItem,
    isLoading: isFetchingContentItem,
    isError: isContentItemError,
  } = useFetchContentItemById({
    contentItemId: fileId ?? "",
    isEnabled: isFetchContentItemEnabled,
  });

  const {
    contentSection,
    isLoading: isFetchingContentSection,
    isError: isContentSectionError,
  } = useFetchContentSectionById({
    sectionId: checklistId || sectionId || "",
    isEnabled: isFetchChecklistEnabled || isFetchSectionEnabled,
  });

  if (!isDeeplinkRoot(deeplinkRoot)) {
    return { spaceId: null, organisationId: null, didError: true, isLoading: false };
  }

  const file = localFile ?? contentItem;
  const checklist = checklistId
    ? doesLocalChecklistExist
      ? localChecklist
      : contentSection ?? null
    : null;
  const section = sectionId
    ? doesLocalSectionExist
      ? localSection
      : contentSection ?? null
    : null;
  const isError = isContentItemError || isContentSectionError;
  const isLoading = isFetchingContentItem || isFetchingContentSection;

  const spaceId =
    deeplinkRoot === "spaces"
      ? getOwnerIdFromEntities({ item: file ?? section ?? checklist })
      : null;

  let organisationId =
    deeplinkRoot === "organisation" ? getOwnerIdFromEntities({ item: file ?? section }) : null;

  let medusaItem: MedusaItem | undefined;
  if (isMedusaNewDeepLink) {
    organisationId = pathOrganisationId;
    const { maybeMedusaItem } = findMedusaItemAndSection(medusaItemId);
    if (maybeMedusaItem) {
      medusaItem = maybeMedusaItem;
    }
  }

  if (isMedusaLegacyDeepLink && isEolasFile(file)) {
    const res = mapOldMedusaFile(file);
    if (res.medusaItem) medusaItem = res.medusaItem;
  }

  return { spaceId, organisationId, didError: isError, isLoading: isLoading ?? false, medusaItem };
};

const isDeeplinkRoot = (deeplinkRoot: string): deeplinkRoot is "organisation" | "spaces" => {
  return deeplinkRoot === "organisation" || deeplinkRoot === "spaces";
};

const getOwnerIdFromEntities = ({
  item,
}: {
  item: Maybe<
    EolasBaseFile | SectionNonAppsync | NoAccessContentItemResponse | NoAccessSectionResponse
  >;
}): string | null => item?.ownerID ?? null;
