import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import {
  AnalyticsEvents,
  AppLevelSection,
  ChecklistRecord,
  DeeplinkResolvedPayload,
  EolasFile,
  FileExtensionMediaType,
  OrganisationLevelSection,
  isDefined,
  isEolasShadowCopyFile,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import { useTranslation } from "react-i18next";
import { InnerPageWrapper, PageTitle, Text, Button } from "UIKit";
import useUserSpaces from "modules/spaces/data/useUserSpaces";
import FlashcardViewer from "./components/FlashcardViewer/FlashcardViewer";
import VideoViewer from "./components/VideoViewer/VideoViewer";
import { isVideoType } from "Utilities/fileHelpers";
import EolasEditorViewer from "./components/EolasEditorViewer/EolasEditorViewer";
import { convertToLink } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications } from "Components/Notifications";
import { BaseAppRoute } from "Utilities/types";
import { LinkedDocumentsNoAccess } from "./components/LinkedDocumentsNoAccess/LinkedDocumentsNoAccess";
import { trackEvent } from "API/Analytics";
import { LinkedDocumentsLoader } from "./components/KnowledgeLinkedDocumentsNewTab/LinkedDocumentsLoader";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { useGetSpaceOrgOwnerIds } from "./hooks/useGetSpaceOrgOwnerIds";
import { NestedDsmViewer } from "shared/pages/ContentViewer/DsmViewer/DsmViewer";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { useContentDbStoreHydration } from "./hooks/useContentDbStoreHydration";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { useSignoutSpace } from "Hooks/useSignoutSpace";
import { useGetPathToSection } from "./hooks/useGetPathToSection";

const InternalLinkedDocumentsViewer = observer(
  ({
    singleChecklist,
    localFile,
    originalOfShadowCopy,
    fileId,
    pageNo,
    sectionId,
  }: {
    singleChecklist: ChecklistRecord | null;
    localFile: EolasFile | null;
    originalOfShadowCopy: EolasFile | null | undefined;
    fileId: string | null;
    pageNo: string | null;
    sectionId: string | null;
  }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { userSpaces, userSpacesLoading } = useUserSpaces(userStore.userID);
    const { isAdmin: isInAdminMode } = useSpacesContext();
    const { showNotification } = useNotifications();
    const { appID: currentSpaceId } = sectionStore;
    const {
      organisationId,
      spaceId,
      isLoading: isFetchingOwnerIds,
      didError: didFailGettingOwnerIds,
      medusaItem,
    } = useGetSpaceOrgOwnerIds();

    const { onSelectSpace, shouldAllowAccessLoading } = useSelectSpaceActions(
      undefined,
      `${location.pathname}${location.search}`,
    );

    const { signoutSpace } = useSignoutSpace({ shouldPushNewRoute: false });

    const currentSpaceName = userSpaces.find((s) => s.id === currentSpaceId)?.name;

    // Used exclusively for section deeplinks
    const {
      path: pathToSection,
      isFetching: isFetchingSectionPath,
      error: sectionPathError,
    } = useGetPathToSection({
      sectionId,
      currentSpaceName,
      isInAdminMode,
      isEnabled: isDefined(sectionId),
    });

    if (
      userSpacesLoading ||
      isFetchingOwnerIds ||
      shouldAllowAccessLoading ||
      isFetchingSectionPath
    ) {
      return <LinkedDocumentsLoader />;
    }
    if (didFailGettingOwnerIds) {
      return (
        <InnerPageWrapper>
          <div className="flex flex-col justify-start items-center flex-1 pt-16">
            <PageTitle title={t("linked_documents_viewer_error")} />
            <Text level={1}>{t("linked_documents_viewer_unexpected_state")}</Text>
          </div>
        </InnerPageWrapper>
      );
    }

    const alternativeSpace = organisationId
      ? userStore.userDepartments.find((d) => d.organisationID === organisationId)
      : userSpaces.find((s) => s?.id === spaceId && s.id !== currentSpaceId);

    const spaceOrOrgId = spaceId || organisationId;

    if (spaceId !== currentSpaceId && organisationId !== sectionStore.organisationID) {
      if (alternativeSpace) {
        // Case 1 - document in another space user has access to
        return (
          <InnerPageWrapper>
            <div
              className="flex flex-col justify-start items-center flex-1 pt-24 text-center"
              data-testid="portfolio-error-fallback"
            >
              <Text level={1} className="mb-24 text-lg">
                {t("linked_documents_viewer_not_in_correct_space", {
                  spaceName: alternativeSpace.name,
                })}
              </Text>
              <Button
                size="xl"
                onClick={async () => {
                  if (currentSpaceId) {
                    signoutSpace();
                  }
                  onSelectSpace(alternativeSpace.id);
                }}
              >
                {t("linked_documents_viewer_switch_to_space_button", {
                  spaceName: alternativeSpace.name,
                })}
              </Button>
            </div>
          </InnerPageWrapper>
        );
      } else if (spaceOrOrgId) {
        // Case 2 - user has no access to space that hosts this document
        return (
          <LinkedDocumentsNoAccess
            ownerId={spaceOrOrgId}
            onAccessGranted={(spaceId) => onSelectSpace(spaceId)}
          />
        );
      }
    }

    if (spaceId === currentSpaceId || organisationId === sectionStore.organisationID) {
      // Case 3 - user is logged in to space with access to this document
      const file = originalOfShadowCopy ?? localFile ?? null;
      const mainSectionId = file?.mainSectionID;
      const mainSectionType = mainSectionId
        ? sectionStore.getMainSectionTypeFromMainSectionID(mainSectionId)
        : null;
      const mainSectionIdentity = mainSectionId
        ? sectionStore.getMainSectionIdentityByMainSectionId(mainSectionId)
        : null;

      if (mainSectionType === AppLevelSection.howTo && currentSpaceName && fileId) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: fileId,
          type: "file",
          mainSectionType,
        });

        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${AppLevelSection.howTo}`,
              search: `?fileId=${encodeURIComponent(fileId)}`,
            }}
          />
        );
      }

      if (mainSectionType === AppLevelSection.patientLeaflets && currentSpaceName && fileId) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: fileId,
          type: "file",
          mainSectionType,
        });

        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${AppLevelSection.patientLeaflets}`,
              search: `?fileId=${fileId}`,
            }}
          />
        );
      }

      if (
        file &&
        file.parentID &&
        mainSectionType === OrganisationLevelSection.orgPatientLeaflets &&
        currentSpaceName &&
        fileId
      ) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: fileId,
          type: "file",
          mainSectionType,
        });

        const sectionId = file.parentID;
        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.organisation}/${currentSpaceName}/${OrganisationLevelSection.orgPatientLeaflets}/${sectionId}`,
              search: `?fileId=${fileId}`,
            }}
          />
        );
      }

      if (sectionId) {
        if (pathToSection) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: sectionId,
            type: "section",
            mainSectionType: "genericContentRepository",
          });
          return (
            <Redirect
              to={{
                pathname: pathToSection,
              }}
            />
          );
        }

        if (sectionPathError) {
          return (
            <InnerPageWrapper>
              <div className="flex flex-col justify-start items-center flex-1 pt-16">
                <PageTitle title={t("linked_documents_viewer_error")} />
                <Text level={1}>{t("linked_documents_viewer_unexpected_state")}</Text>
              </div>
            </InnerPageWrapper>
          );
        }
      }

      if (singleChecklist && currentSpaceName && singleChecklist.parentID) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: singleChecklist.id,
          type: "file",
          mainSectionType: AppLevelSection.checklists,
        });
        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${
                AppLevelSection.checklists
              }/${encodeURIComponent(singleChecklist.parentID)}/details/${encodeURIComponent(
                singleChecklist.id,
              )}`,
            }}
          />
        );
      }
      if (file && file.parentID && mainSectionType === OrganisationLevelSection.hospitalContacts) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType,
        });
        const sectionId = file.parentID;
        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.organisation}/${sectionStore.organisationName}/${OrganisationLevelSection.hospitalContacts}/${sectionId}`,
              search: `?fileId=${fileId}`,
            }}
          />
        );
      }
      if (file && file.parentID && mainSectionType === AppLevelSection.contacts) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType,
        });
        const sectionId = file.parentID;
        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${AppLevelSection.contacts}/${sectionId}`,
              search: `?fileId=${fileId}`,
            }}
          />
        );
      }
      if (file && mainSectionType === OrganisationLevelSection.communicationPortal) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType,
        });

        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.organisation}/${sectionStore.organisationName}/${OrganisationLevelSection.communicationPortal}`,
              search: `?fileId=${fileId}`,
            }}
          />
        );
      }

      if (file && mainSectionType === AppLevelSection.newsFeed) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType,
        });

        return (
          <Redirect
            to={{
              pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${AppLevelSection.newsFeed}`,
              search: `?fileId=${fileId}`,
            }}
          />
        );
      }

      if (file?.type === "flashcard") {
        // should always be true
        if (mainSectionType && mainSectionIdentity) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: file.id,
            type: "file",
            mainSectionType: mainSectionType ?? mainSectionIdentity,
          });
        }
        return (
          <FlashcardViewer
            mainSectionId={file.mainSectionID}
            flashcardId={file.id}
            backBehaviour="goHome"
          />
        );
      }

      if (file?.type === "dsm") {
        if (mainSectionType && mainSectionIdentity) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: file.id,
            type: "file",
            mainSectionType: mainSectionType ?? mainSectionIdentity,
          });
        }
        return (
          <NestedDsmViewer
            mainSectionId={file.mainSectionID}
            contentItemId={file.id}
            backBehaviour="goHome"
            shadowCopyContentId={null}
            fileIdOfDraftFile={null}
          />
        );
      }

      if (file?.type === "eolas-editor") {
        // should always be true
        if (mainSectionType && mainSectionIdentity) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: file.id,
            type: "file",
            mainSectionType: mainSectionType ?? mainSectionIdentity,
          });
        }
        return (
          <EolasEditorViewer
            mainSectionId={file.mainSectionID}
            contentItemId={file.id}
            backBehaviour="goHome"
          />
        );
      }

      const renderGoToUrl = ({ onClickViewLink }: { onClickViewLink: () => void }) => {
        return (
          <InnerPageWrapper>
            <div
              className="flex flex-col justify-start items-center flex-1 pt-24 text-center"
              data-testid="portfolio-error-fallback"
            >
              <Text level={1} className="mb-16 text-lg">
                {t("linked_documents_viewer_external_link_text")}
              </Text>
              <Button size="xl" onClick={onClickViewLink}>
                {t("linked_documents_viewer_external_link_button")}
              </Button>
              <Text className="mt-4" level={1}>
                {t("filePicker_or")}
              </Text>

              <Link
                to={
                  organisationId
                    ? `/${BaseAppRoute.organisation}/${sectionStore.organisationName}`
                    : `/${BaseAppRoute.spaces}`
                }
                className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-4"
              >
                {t("take_me_home")}
              </Link>
            </div>
          </InnerPageWrapper>
        );
      };

      if (medusaItem && organisationId) {
        // should always be true
        if (mainSectionType) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: medusaItem.id,
            type: "file",
            mainSectionType,
          });
        }
        return renderGoToUrl({
          onClickViewLink: async () => {
            const safeUrl = convertToLink(medusaItem.url.value);
            window.open(safeUrl, "_blank");
          },
        });
      }

      if (file?.type === "link") {
        // should always be true
        if (mainSectionType && mainSectionIdentity) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: file.id,
            type: "file",
            mainSectionType: mainSectionType ?? mainSectionIdentity,
          });
        }
        return renderGoToUrl({
          onClickViewLink: () => {
            if (file.key) {
              const safeUrl = convertToLink(file.key);
              window.open(safeUrl, "_blank");
            } else {
              errorStore.captureError({
                error: "File key is undefined or null",
                source: "user",
              });
              showNotification({
                type: "error",
                description: t("linked_documents_viewer_external_link_error"),
                autoHideTimeout: 10000,
              });
            }
          },
        });
      }
      if (file && isVideoType(file?.type as FileExtensionMediaType)) {
        // should always be true
        if (mainSectionType && mainSectionIdentity) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: file.id,
            type: "file",
            mainSectionType: mainSectionType ?? mainSectionIdentity,
          });
        }
        return <VideoViewer video={file} backBehaviour="goHome" />;
      }

      if (file) {
        // should always be true
        if (mainSectionType && mainSectionIdentity) {
          trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
            entityId: file.id,
            type: "file",
            mainSectionType: mainSectionType ?? mainSectionIdentity,
          });
        }
        return (
          <DocumentViewer
            file={file}
            backBehaviour="goHome"
            startingPage={pageNo ? parseInt(pageNo, 10) : undefined}
          />
        );
      }

      return (
        <InnerPageWrapper>
          <div
            className="flex flex-col justify-start items-center flex-1 pt-16"
            data-testid="portfolio-error-fallback"
          >
            <PageTitle title={t("linked_documents_viewer_error_no_file")} />
            <Text level={1}>{t("linked_documents_viewer_error_no_file_desc")}</Text>
            <Link
              to={
                organisationId
                  ? `/${BaseAppRoute.organisation}/${sectionStore.organisationName}`
                  : `/${BaseAppRoute.spaces}`
              }
              className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-8"
            >
              {t("take_me_home")}
            </Link>
          </div>
        </InnerPageWrapper>
      );
    }

    // Default case
    return (
      <InnerPageWrapper>
        <div className="flex flex-col justify-start items-center flex-1 pt-16">
          <PageTitle title={t("linked_documents_viewer_error")} />
          <Text level={1}>{t("linked_documents_viewer_unexpected_state")}</Text>
        </div>
      </InnerPageWrapper>
    );
  },
);

export const LinkedDocumentsViewer = observer(() => {
  const params = useGetSearchParams({
    paramList: ["fileId", "checklistId", "pageNo", "sectionId"],
  });

  const { fileId, checklistId } = params;
  const { isHydrated } = useContentDbStoreHydration();

  const { file: localFile, isFetching: isFetchingLocalFile } = useGetFile({
    id: fileId || undefined,
    options: { shouldFetchOnMountOnly: true, isEnabled: userStore.userSession.isInOrganisation },
  });

  const { data: originalOfShadowCopy } = useQuery({
    queryKey: ["getOriginalOfShadowCopy", fileId],
    enabled: Boolean(localFile && isEolasShadowCopyFile(localFile)),
    queryFn: async () => {
      if (!localFile || !isEolasShadowCopyFile(localFile)) {
        return null;
      }
      return await getOriginalFromShadowCopy(localFile);
    },
  });

  const singleChecklist = checklistId ? sectionStore.getSingleChecklistTemplate(checklistId) : null;

  if (!isHydrated || isFetchingLocalFile) {
    return <LinkedDocumentsLoader />;
  }

  return (
    <InternalLinkedDocumentsViewer
      singleChecklist={singleChecklist}
      localFile={localFile}
      originalOfShadowCopy={originalOfShadowCopy}
      {...params}
    />
  );
});
