import { InnerPageWrapper, PageTitle } from "UIKit";
import { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { NavBackButton } from "shared/components/LogicComponents";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { ContentItem } from "@eolas-medical/core";
import { useGetSpaceOrgInfo } from "../../hooks/useGetSpaceOrgInfo";
import { useLimitedAccessHelpers } from "../../hooks/useLimitedAccessHelpers";
import { useRecordMainSectionVisit } from "Hooks";
import useReorderContent from "modules/contentRepository/data/useReorderContent";
import useContentSections from "modules/contentRepository/data/useContentSections";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { ContentSection } from "modules/contentRepository/types";
import { isContentSection } from "modules/contentRepository/typeguards";
import { ContentSectionItem } from "shared/pages/ContentRepository/ContentSections/components/ContentSectionItem/ContentSectionItem";
import ContentItemInstance from "shared/pages/ContentRepository/ContentItems/components/ContentItemInstance/ContentItemInstance";
import {
  EolasVersatileListContext,
  EolasVersatileListOnDropResult,
  EolasVersatileListRenderItemProps,
} from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { useSpaceOrgSearch } from "./hooks/useSpaceOrgSearch";
import { ShareButton } from "./components/ShareButton";
import { AdminRow } from "./components/AdminRow";
import { Wrapper } from "./components/ItemWrapperComponent";

type ListContext = EolasVersatileListContext<
  // TODO: migrate back to proper types (EolasFile, Section etc)
  ContentItem | ContentSection,
  {
    shouldShowAdmin: boolean;
  }
>;

export const SpaceContentSections = observer(() => {
  const {
    isOnAdminView,
    mainSection,
    currentSubsection,
    mainSectionId,
    activeSectionId,
    isSubsection,
  } = useGetSpaceOrgInfo();

  useRecordMainSectionVisit({ mainSectionId, visitFrom: "directNavigation" });

  const limitedAccessHelpers = useLimitedAccessHelpers({ sectionId: activeSectionId });

  const shouldShowAdmin = Boolean(
    limitedAccessHelpers
      ? limitedAccessHelpers.shouldCurrentSectionAllowAdminRights && isOnAdminView
      : isOnAdminView,
  );

  const { contentSections: unfilteredChildContentSections } = useContentSections(activeSectionId);

  const childContentSections = useMemo(() => {
    if (!limitedAccessHelpers) {
      return unfilteredChildContentSections;
    }
    const { filterFnForChildren } = limitedAccessHelpers;
    return unfilteredChildContentSections.filter(({ id }) => {
      return filterFnForChildren(id);
    });
  }, [unfilteredChildContentSections, limitedAccessHelpers]);

  const {
    searchInstanceId,
    handleClearSearch,
    handleSearchClick,
    searchResults,
    isSearchActive,
    isSearchLoading,
  } = useSpaceOrgSearch({ shouldShowAdmin });

  const { reorderContent } = useReorderContent(activeSectionId);

  let title = "";

  if (currentSubsection?.name) {
    title = currentSubsection.name;
  } else if (mainSection?.name) {
    title = mainSection.name;
  }

  const listContext: ListContext = useMemo(() => {
    return { shouldShowAdmin, isInitialLoading: false };
  }, [shouldShowAdmin]);

  const onDropItem = useCallback(
    (dropResult: EolasVersatileListOnDropResult) => {
      reorderContent(dropResult);
    },
    [reorderContent],
  );

  const itemsForDisplay: (ContentItem | ContentSection)[] = isSearchActive
    ? searchResults
    : childContentSections;

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center">
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={mainSectionId}
          parentId={activeSectionId}
        />
        <PageTitle className="my-4" title={title} />
        <NavBackButton segmentsToRemove={isSubsection ? 1 : 2} modePreference="removeFromPath" />
      </div>
      <div className="flex justify-center items-center space-x-4">
        {shouldShowAdmin ? (
          <AdminRow />
        ) : (
          <ShareButton activeSectionId={activeSectionId} isSubsection={isSubsection} />
        )}
      </div>
      <div data-testid="content-section-list">
        <EolasVersatileList
          // key is needed to fix bug with Virtuoso's calc of margin between items when switching item type:
          key={isSearchActive.toString()}
          className="space-y-6"
          isSearchLoading={isSearchLoading}
          items={itemsForDisplay}
          renderItem={renderItem}
          context={listContext}
          isSearchable
          searchInstanceId={searchInstanceId}
          isDraggable={!isSearchActive ? shouldShowAdmin : false}
          onDragEnd={onDropItem}
          droppableId="content-section-list"
          searchMode="click"
          onClickSearch={handleSearchClick}
          onClearSearch={handleClearSearch}
        />
      </div>
    </InnerPageWrapper>
  );
});

const renderItem = ({
  item,
  context: { shouldShowAdmin },
}: EolasVersatileListRenderItemProps<ContentSection | ContentItem, ListContext>) => {
  if (isContentSection(item)) {
    return (
      <Wrapper item={item}>
        <ContentSectionItem
          contentSection={item}
          isAdmin={shouldShowAdmin}
          variant="titleAndIcon"
          isDraggable={shouldShowAdmin}
        />
      </Wrapper>
    );
  }
  if (item.isDraft && !shouldShowAdmin) {
    return null;
  }
  return (
    <Wrapper item={item}>
      <ContentItemInstance
        contentItem={item}
        data-testid={`content-item-${item.id}`}
        isAdminMode={shouldShowAdmin}
        isDraggable={false}
        isSearchResult
        isCopyableStatus={shouldShowAdmin ? "fromTileDropDown" : undefined}
      />
    </Wrapper>
  );
};
