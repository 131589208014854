import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import {
  EolasFile,
  AppLevelSection,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";

import { useMainSectionVisit, useSelectFile } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";

import { Modal, Button, NavButton, PageTitle, AddButton, InnerPageWrapper, SearchBox } from "UIKit";

import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import EmptyLocalList from "shared/components/Lists/EmptyLocalList/EmptyLocalList";
import { EolasFileListItem } from "Components/EolasFileList/EolasFileListItem";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

export const Guidelines: React.FC<RouteComponentProps> = observer(
  ({ history: { push }, match: { url } }) => {
    const { t } = useTranslation();
    const { onSelectFile: selectFile } = useSelectFile();
    const { activeTab } = useEolasNavigation();
    const { isShareFileEnabled, isShareModalOpen, closeShareModal, shareModal, handleOnShareFile } =
      useShareFile();

    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();

    const { isAdmin } = useSpacesContext();

    const isOrganisationTab = activeTab === "organisation";

    const mainSectionType = isOrganisationTab
      ? OrganisationLevelSection.hospitalGuidelines
      : AppLevelSection.guidelines;

    const { spaceSection } = useSpaceSection(mainSectionType);

    useMainSectionVisit({ mainSectionId: mainSectionType });

    const childReference = sectionStore.getChildReferenceByMainSectionType(mainSectionType);

    if (!childReference) {
      console.error(`No child reference found for mainSectionType: ${mainSectionType}`);
      return null;
    }

    const mainSectionOrder = sectionStore
      .getChildrenOrder(childReference.id)
      .map((child) => child.id);

    const mainSectionFileOrder = mainSectionOrder.flatMap((sectionID) => {
      return sectionStore.getChildrenOrder(sectionID);
    });

    const { files } = useGetFiles({ childrenOrder: mainSectionFileOrder });

    const { searchInput, searchResult, onSetSearchInput } = useLocalSearch<EolasFile>({
      data: files,
      keysToSearch: ["name", "description", "searchField"],
    });

    const { modal, onCloseModal, onAddSection, onEditSection, onDeleteSection } =
      useSectionManagement(spaceSection.id);

    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const handleCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      onCloseModal();
    };

    const onSelectFile = useCallback(
      (file: EolasFile) => {
        selectFile(file);
      },
      [selectFile],
    );

    const noGuidelinesFound = searchInput && searchResult.length === 0;

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t(mainSectionType)} />
          <NavButton to={url.replace(`/${mainSectionType}`, "")} />

          <SearchBox
            onChangeText={onSetSearchInput}
            placeholder={t("guidelines_search_all_placeholder")}
          />

          {isAdmin ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <AddButton onClick={onAddSection} data-testid="add-new-subSection">
                {t("addNewSection_button")}
              </AddButton>

              <Button
                size="lg"
                color="grey"
                weight="bold"
                variant="outline"
                onClick={() => push("guidelines-expired")}
              >
                {t("guidelines_manage_expired_guidelines")}
              </Button>
            </div>
          ) : null}

          {noGuidelinesFound ? (
            <EmptyLocalList isSearchResult />
          ) : searchInput.length > 1 ? (
            <div className="flex flex-col items-stretch ">
              {searchResult.map((guideline) => (
                <EolasFileListItem
                  key={guideline.id}
                  canDrag={false}
                  isAdmin={isAdmin}
                  eolasFile={guideline}
                  onSelectFile={onSelectFile}
                  onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
                  showRemoveFavouriteModal={showRemoveFavouriteModal}
                />
              ))}
            </div>
          ) : (
            <SectionList
              isAdmin={isAdmin}
              sectionID={spaceSection.id}
              onEdit={onEditSection}
              onAddItem={onAddSection}
              onDelete={onDeleteSection}
            />
          )}
        </InnerPageWrapper>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          {modal || shareModal || removeFavouriteModal}
        </Modal>
      </>
    );
  },
);
