/* eslint-disable camelcase */
import { extension } from "mime-types";
import { countries } from "countries-list";
import { addMonths, differenceInDays, format } from "date-fns";
import {
  ChecklistRecord,
  OrganisationLevelSection,
  generatePublicUrl,
  sectionStore,
  fromAwsJSON as awsJsonParser,
  hasProp,
} from "@eolas-medical/core";
import { S3GeneratorParams, AppEnv, MedusaData } from "Utilities/types";
import axios from "axios";
import React from "react";

type AppEnvTypes = "dev" | "staging" | "prod";

export const API_ENV_PATH_PREFIXS: Record<AppEnv, AppEnvTypes> = {
  [AppEnv.DEVELOPMENT]: "dev",
  [AppEnv.STAGING]: "staging",
  [AppEnv.PRODUCTION]: "prod",
};

export function formatDate(date?: Date | string, tokens = "do MMMM yyyy, HH:mm"): string {
  if (!date) return "";

  return format(new Date(date), tokens);
}

export const convertToLink = (value: string): string => {
  const trimmed = value.trim();
  const isHttp = trimmed.startsWith("https://") || trimmed.startsWith("http://");
  return isHttp ? trimmed : `https://${trimmed}`;
};

export const URL_REGEX = /(\S+\.[^/\s]+(\/\S+|\/|))/g;
const uriSchemeMatch = /:\/\//g;

export const isUrlValid = (url: string) => {
  const converted = convertToLink(url);
  const matchesOnUriSchemes = converted.match(uriSchemeMatch);
  // Should only have one URI scheme (http)
  if (matchesOnUriSchemes && matchesOnUriSchemes.length !== 1) {
    return false;
  }
  return Boolean(converted.match(URL_REGEX)?.length);
};

function addTimestampToFilename(filename: string) {
  const timestamp = new Date().getTime();
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex === -1) {
    return filename + "_" + timestamp;
  } else {
    return filename.slice(0, dotIndex) + "_" + timestamp + filename.slice(dotIndex);
  }
}

export const extractFileNameFromLegacyS3Key = (key: string) => {
  const keyArr = key.split("/");
  const fileWithTimestamp = keyArr[keyArr.length - 1] ?? "";
  const fileArrExtensionSplit = fileWithTimestamp.split(".");
  const fileExtension = fileArrExtensionSplit[fileArrExtensionSplit.length - 1] ?? "";
  const fileNameWithTimestamp = fileArrExtensionSplit[0] ?? "";
  const fileNameSplit = fileNameWithTimestamp.split("_");
  if (fileNameSplit.length > 1 && !Number.isNaN(Number(fileNameSplit[fileNameSplit.length - 1]))) {
    fileNameSplit.pop();
  }
  const fileNameCleaned = fileNameSplit.join("_");
  if (fileNameCleaned && fileExtension) {
    return `${fileNameCleaned}.${fileExtension}`;
  }
  return null;
};

export const generateS3FileKey = ({
  isPublic,
  fileName,
  fileFormat,
  mainSectionId,
  addExtension,
}: S3GeneratorParams): string => {
  const { app, organisation } = sectionStore.data;

  const fileNameTimestamped = addTimestampToFilename(fileName);

  let s3key = `public/${organisation?.id}/${app?.id}/${mainSectionId}/${fileNameTimestamped}`;

  if (isPublic) {
    s3key = `public/${organisation?.id}/${app?.id}/sharedContent/${fileNameTimestamped}`;
  }

  if (addExtension) {
    const ext = extension(fileFormat);
    return `${s3key}.${ext}`;
  }

  return s3key;
};

export const parseAwsJson = awsJsonParser;

export const generateS3PublicImageURL = (fileKey: string): string => {
  return fileKey.startsWith("https://")
    ? fileKey
    : generatePublicUrl(fileKey, process.env.REACT_APP_PUBLIC_BUCKET_URL!);
};

export const isHospital = (mainSectionId: string): boolean => {
  return mainSectionId in OrganisationLevelSection;
};

export const parseMedusaMetadata = (metadata: unknown): MedusaData => {
  if (metadata) {
    const result = parseAwsJson(metadata);
    if (hasProp(result, "medusa")) {
      return result.medusa || {};
    }
  }
  return {};
};

interface CreateSearchField {
  name?: string;
  description?: string;
  type?: string;
  date?: string;
  createdBy?: string;
  moreInfo?: string;
  keywords?: string[];
}
export const createSearchField = ({
  name = "",
  description = "",
  type = "",
  date = "",
  createdBy = "",
  moreInfo = "",
  keywords = [],
}: CreateSearchField): string => {
  const readableDate = date.length > 0 ? formatDate(date, "PPPPpppp") : "";

  let searchString = "";
  const keyWordsString = keywords?.join(" ");

  [name, description, readableDate, createdBy, type, keyWordsString, moreInfo].forEach((string) => {
    if (string.length > 0) {
      searchString += `${string.toLowerCase()} `;
    }
  });

  return searchString.trim();
};

export const licenceCost = {
  UK: "£3000",
  EU: "€3000",
  REST_WORLD: "$5000",
};

export const daysLeftForCompetency = ({
  completedDate,
  expirationInMonths,
}: {
  completedDate: string;
  expirationInMonths: string;
}): number => {
  const today: Date = new Date();

  const expireDate: string = addMonths(
    new Date(completedDate),
    Number(expirationInMonths),
  ).toISOString();
  return differenceInDays(new Date(expireDate), new Date(today));
};

export const hasExpirationLength = (expirationInMonths: string): boolean => {
  return !!Number(expirationInMonths);
};

export const generateLicenceCost = (organisationLocation: keyof typeof countries): string => {
  const countryCurrency = countries[organisationLocation].currency;
  const currencies = {
    UK: ["GBP"],
    EUROPE: [
      "EUR",
      "PLN",
      "ALL",
      "AMD",
      "AZN",
      "BAM",
      "BYN",
      "CHF",
      "CZK",
      "DKK",
      "GEL",
      "HRK",
      "HUF",
      "ISK",
      "MDL",
      "MKD",
      "NOK",
      "RON",
      "RSD",
      "RUB",
      "SEK",
      "TRY",
      "UAH",
    ],
  };

  if (currencies.UK.includes(countryCurrency)) {
    return licenceCost.UK;
  } else if (currencies.EUROPE.includes(countryCurrency)) {
    return licenceCost.EU;
  } else {
    return licenceCost.REST_WORLD;
  }
};

export const removeLastUrlPath = (url: string, segments = 1): string => {
  const doesEndWithSlash = url.endsWith("/");
  const urlWithoutTrailingSlash = doesEndWithSlash ? url.slice(0, -1) : url;
  return urlWithoutTrailingSlash
    .split("/")
    .slice(0, -1 * segments)
    .join("/");
};

export const toAwsJSON = (object: unknown): string => JSON.stringify(JSON.stringify(object));

/** @deprecated use parseAwsJson instead */
export const fromAwsJSON = (json: string) => {
  const parseOrReturn = (json: string) => (typeof json === "string" ? JSON.parse(json) : json);

  return parseOrReturn(parseOrReturn(json));
};

export const isChecklist = (object: unknown): object is ChecklistRecord => {
  return object instanceof Object && "checklistName" in object;
};

export const servicesStatusCheck = async (): Promise<boolean> => {
  // ping each service to verify user's connection status and any possible network blocks
  const PING_LIST = {
    CLICK: false,
    S3: false,
    COGNITO: false,
  };

  PING_LIST.S3 = await SendServiceCheck({
    url: `https://${process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET}.s3.eu-west-1.amazonaws.com`,
    expectedErrorStatus: 412,
  });

  PING_LIST.COGNITO = await SendServiceCheck({
    url: "https://cognito-idp.eu-west-1.amazonaws.com/",
    responseStatus: 200,
  });

  PING_LIST.CLICK = await SendServiceCheck({
    url: `${process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT}`,
    expectedErrorStatus: 400,
  });

  return Object.values(PING_LIST).every((service) => service === true);
};

type SendServiceCheckBody = {
  url: string;
  expectedErrorStatus?: number;
  responseStatus?: number;
};

const SendServiceCheck = ({ url, expectedErrorStatus, responseStatus }: SendServiceCheckBody) => {
  const abortController = new AbortController();
  return new Promise<boolean>((resolve) => {
    const timeoutID = setTimeout(() => {
      abortController.abort();
      resolve(false);
    }, 15000);
    axios
      .post(url, { signal: abortController.signal })
      .then((response) => {
        clearTimeout(timeoutID);
        resolve(responseStatus === response?.status);
      })
      .catch((error) => {
        clearTimeout(timeoutID);
        resolve(expectedErrorStatus === error?.response?.status);
      });
  });
};

export const hospitalFacilities: { [key: string]: string } = {
  hospitalsAdmin_facilities_emergency_dept: "Emergency Dept",
  hospitalsAdmin_facilities_ICU: "ICU",
  hospitalsAdmin_facilities_anaesthetics: "Anaesthetics",
  hospitalsAdmin_facilities_orthopaedics: "Orthopaedics",
  hospitalsAdmin_facilities_neurosurgery: "Neurosurgery",
  hospitalsAdmin_facilities_cardiothoracic: "Cardiothoracic",
  hospitalsAdmin_facilities_primary_pci: "Primary PCI",
  hospitalsAdmin_facilities_maxillofacial: "Maxillofacial",
  hospitalsAdmin_facilities_vascular: "Vascular",
  hospitalsAdmin_facilities_burns: "Burns",
  hospitalsAdmin_facilities_plastics: "Plastics",
  "hospitalsAdmin_facilities_emco-bypass": "ECMO/Bypass",
  hospitalsAdmin_facilities_interventional_radiology: "Interventional Radiology",
  hospitalsAdmin_facilities_paediatrics: "Paediatrics",
  "hospitalsAdmin_facilities_obstetrics-maternity": "Obstetrics/Maternity",
  hospitalsAdmin_facilities_blood: "Blood",
  "hospitalsAdmin_facilities_247-ct": "24/7 CT",
};

export const getImageUrl = (key: string): string => {
  return key.startsWith("s3://") || !key.startsWith("http") ? generateS3PublicImageURL(key) : key;
};

export const isUrlString = (text: string) => /^https:\/\//.test(text);

export const typedMemo: <T>(c: T) => T = React.memo;
