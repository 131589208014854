import { AnalyticsEvents } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { myFilesService } from "../client/myFiles.client";
import myFilesKeys from "./myFiles.queryKeys";

const useDeleteFile = (fileId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, error } = useMutation<unknown, Error>(
    async () => myFilesService.deleteFile(fileId),
    {
      onSuccess: () => {
        trackEvent(AnalyticsEvents.MYFILES_FILE_DELETE, { fileId });

        queryClient.invalidateQueries({
          queryKey: myFilesKeys.allFilesList(),
        });
        queryClient.invalidateQueries({
          queryKey: myFilesKeys.filesBySectionList(),
        });
      },
    },
  );

  return {
    deleteFile: mutate,
    deletingFile: isLoading,
    deleteFileSuccessful: isSuccess,
    deleteFileError: error?.message,
  };
};

export default useDeleteFile;
