import { useMutation } from "@tanstack/react-query";
import { ContentItem, contentClient } from "@eolas-medical/core";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

interface UseDeleteContentItemProps<T, R> {
  mainSectionId: string;
  onSuccess: (params?: T) => Promise<R>;
}

const useDeleteContentItem = <T, R>({
  mainSectionId,
  onSuccess,
}: UseDeleteContentItemProps<T, R>) => {
  const deleteContentItem = async (contentItem: ContentItem) => {
    /**
     * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
     */
    const file: any = await contentDbStore.getItem(contentItem.id);
    await contentClient.deleteContentItem({
      mainSectionId,
      itemId: contentItem.id,
    });
    return file;
  };

  const { mutate, isLoading, isSuccess, error, reset } = useMutation(deleteContentItem, {
    onSuccess: async (file) => {
      if (file) {
        intermediateUpdateForUi({ type: "file", action: "delete", file });
      }
      await onSuccess();
      reset();
    },
  });

  return {
    deleteContentItem: mutate,
    deletingContentItem: isLoading,
    deleteContentItemSuccess: isSuccess,
    deleteContentItemError: error instanceof Error ? error.message : "",
  };
};

export default useDeleteContentItem;
