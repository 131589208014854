import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FileTileWrapper, FileTileProps, DefaultFileControls } from "./shared";
import { FavouriteToggle } from "UIKit/Button";
import { ShareFileButton } from "./shared/ShareFileButton";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { AppLevelSection, OrganisationLevelSection } from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";

export const ContactFileTile: React.FC<FileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  onSelectFile = () => {
    return;
  },
  onShareFile,
  showRemoveFavouriteModal,
  ...handlers
}: FileTileProps) => {
  const { activeTab } = useEolasNavigation();
  const isOrganisationTab = activeTab === "organisation";

  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);
  const shouldShowShareButton = !isAdmin && onShareFile;
  const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;
  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1 justify-around">
        <span className="font-semibold text-lg line-clamp-2">{eolasFile.name}</span>
        <span className="text-sm line-clamp-2">{eolasFile.description}</span>
      </div>

      {shouldShowFavouriteToggle || shouldShowShareButton ? (
        <div className="w-fit self-start flex flex-col items-center justify-start h-full">
          {shouldShowFavouriteToggle ? (
            <FavouriteToggle
              entityId={eolasFile.id}
              isLoading={!!isMarkingAsFavourite}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: eolasFile.id,
                      favouriteId,
                      title: eolasFile.name,
                      entityType: FavouriteEntityType.FILE,
                      originForAnalytics: isOrganisationTab
                        ? OrganisationLevelSection.hospitalContacts
                        : AppLevelSection.contacts,
                    })
                  : handleAddFavourite({
                      item: eolasFile,
                      entityType: FavouriteEntityType.FILE,
                    })
              }
            />
          ) : null}
          {shouldShowShareButton ? (
            <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
          ) : null}
        </div>
      ) : null}
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
