import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";

import { FormElement, Input } from "UIKit";
import { ConfirmPasswordICon, PadlockIcon } from "Assets";
import ShowPasswordIcon from "Pages/Authentication/components/ShowPasswordIcon/ShowPasswordIcon";

interface PasswordInputProps {
  id?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  control: Control<{ password: string }>;
  autoFocus?: boolean;
  "data-testid"?: string;
  isConfirmPassword?: boolean;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  id = "password",
  className = "",
  placeholder,
  control,
  autoFocus = false,
  "data-testid": dataTestId,
  isConfirmPassword = false,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  return (
    <FormElement id={id} required control={control} className={className} label={label}>
      {(field) => (
        <>
          <Input
            {...field}
            iconLeft={isConfirmPassword ? <ConfirmPasswordICon /> : <PadlockIcon />}
            autoFocus={autoFocus}
            autoComplete="new-password"
            data-testid={dataTestId}
            placeholder={placeholder || t("login_enterPassword")}
            type={showPassword ? "text" : "password"}
            iconRight={
              <ShowPasswordIcon
                showPassword={showPassword}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            }
          />
        </>
      )}
    </FormElement>
  );
};

export default PasswordInput;
