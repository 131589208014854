import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Title,
  Text,
  ModalBody,
  FormError,
  FormElement,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";
import { MedusaStatusView } from "../MedusaStatus/MedusaStatus";
import { DisableMedusaModal } from "../DisableMedusaModal/DisableMedusaModal";
import { useMedusa } from "../../data/useMedusa";
import { MainSection } from "modules/mainSections/types";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import useToggleMainSection from "modules/mainSections/data/useToggleMainSection";

export interface EditSectionModalProps {
  section: MainSection;
  onCloseModal(): void;
}

export const EditMedusaSectionModal: React.FC<EditSectionModalProps> = observer(
  ({ section, onCloseModal }) => {
    const { t } = useTranslation();
    const { getIconComponent } = useIconComponent();
    const [showDisableModal, toggleDisableModal] = useState(false);

    const { toggleMainSection, toggleMainSectionSuccess, toggleMainSectionError } =
      useToggleMainSection();

    const { isLoading, status, control, error, cached, isSuccessful, onSubmit, onDisable } =
      useMedusa({ name: section.name, description: section.description });

    const handleDisable = () => {
      toggleMainSection({ id: section.id, disabled: !section.disabled });
    };

    const cachingState = useMemo(() => {
      if (status === "enabled") {
        return cached ? (
          <Text level={2} className="text-left mb-4">
            {t("contentManagement_medusa_caching_complete")}
          </Text>
        ) : (
          <Text level={2} className="text-left mb-4">
            {t("contentManagement_medusa_caching")}
          </Text>
        );
      }
      return (
        <Text level={1} className="text-left ml-4 mb-4">
          {t("contentManagement_medusa_body1")}
        </Text>
      );
    }, [status, cached, t]);

    if (toggleMainSectionSuccess) {
      return (
        <SuccessModal onComplete={onCloseModal} text="contentManagement_editFeature_deactivated" />
      );
    }

    if (isSuccessful) {
      return (
        <SuccessModal onComplete={onCloseModal} text="contentManagement_editFeature_updated" />
      );
    }

    return (
      <InnerModalWrapper>
        <ModalHeader className="flex-row">
          {section.iconUrl ? getIconComponent(section.iconUrl) : null}
          <Title level={5} className="ml-4">
            {t("contentManagement_editFeature")}
          </Title>
        </ModalHeader>
        <Text level={1} className="text-center capitalize mt-4">
          <MedusaStatusView status={status} />
        </Text>

        <ModalBody>
          {cachingState}
          <form className="space-y-4" onSubmit={onSubmit}>
            <FormElement
              required
              id="name"
              control={control}
              labelClassName="font-semibold"
              label={t("contentManagement_editFeature_name")}
            >
              {(field) => (
                <Input
                  {...field}
                  disabled={isLoading}
                  placeholder={t("contentManagement_editFeature_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              control={control}
              id="description"
              labelClassName="font-semibold"
              label={t("contentManagement_editFeature")}
            >
              {(field) => (
                <Input
                  {...field}
                  disabled={isLoading}
                  placeholder={t("contentManagement_editFeature_placeholder")}
                />
              )}
            </FormElement>

            <div className="flex justify-center">
              <Button
                color="blue"
                type="submit"
                variant="outline"
                disabled={isLoading}
                isLoading={isLoading}
              >
                {t("updateSection_button")}
              </Button>
            </div>

            <FormError error={error} />
            <FormError error={toggleMainSectionError} />
          </form>
        </ModalBody>
        <DisableMedusaModal
          open={showDisableModal}
          handleClose={() => toggleDisableModal(false)}
          handleDisable={() => onDisable(handleDisable)}
        />
      </InnerModalWrapper>
    );
  },
);
