import { useCustomTheme } from "Components/Navigation/hooks/useCustomTheme";
import { useEolasNavigation } from "Components/Navigation/hooks";

const useBackgroundCoverTheme = () => {
  const { customBrandColor } = useCustomTheme();
  const {
    isOnSpacesRoot,
    isOnSelectSpaceRoot,
    isOnOrganisationRoot,
    isOnKnowledgeRoot,
    isOnMeRoot,
  } = useEolasNavigation();

  const defaultCoverTheme = "space-background";

  if (customBrandColor) {
    return "linear-gradient(180deg, bg-brand -15%, rgba(236, 196, 172, 0) 35%, #FAFAFA 100%)";
  }

  if (isOnSpacesRoot || isOnSelectSpaceRoot) {
    return "bg-spaces-background";
  }

  if (isOnOrganisationRoot) {
    return "bg-organisation-background";
  }

  if (isOnKnowledgeRoot) {
    return "bg-knowledge-background";
  }

  if (isOnMeRoot) {
    return "bg-me-background";
  }

  return defaultCoverTheme;
};

export default useBackgroundCoverTheme;
