import { makeApolloClient } from "API/functions/makeApolloClient";
import { mutateApollo } from "API/functions/mutateApollo";
import envConfig from "env-config";
import { PublishFileDocument, RemoveFileDocument, UpdateFileDocument } from "./files.graphql";
import { ModifyFileInput, PublishFileInput } from "@eolas-medical/core/lib/src/graphql";

const apiUrl = `https://${envConfig.REACT_APP_API_BASE_URL}/proxy/graphql`;

export const filesClient = makeApolloClient(apiUrl);

export const publishFile = async ({
  parentID,
  input,
}: {
  parentID: string;
  input: PublishFileInput;
}) => {
  const file: File = await mutateApollo(filesClient, {
    mutation: PublishFileDocument,
    variables: { parentID, input },
  });

  return file;
};

export const updateFile = async ({
  parentID,
  input,
}: {
  parentID: string;
  input: ModifyFileInput;
}) => {
  const file: File = await mutateApollo(filesClient, {
    mutation: UpdateFileDocument,
    variables: { parentID, input },
  });

  return file;
};

export const removeFile = async ({ parentID, fileId }: { parentID: string; fileId: string }) => {
  return await mutateApollo(filesClient, {
    mutation: RemoveFileDocument,
    variables: { parentID, id: fileId },
  });
};
