import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { AppLevelSection, EolasFile, sectionStore } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { useRecordMainSectionVisit } from "Hooks";
import { NavButton, PageTitle, InnerPageWrapper, AddButton } from "UIKit";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { DeleteFileModal } from "Components";
import { useNewsFeeds } from "../../data/useNewsFeeds";
import AdminNewsFeedTile from "./components/AdminNewsFeedTite/AdminNewsFeedTile";
import {
  AddNewsItemModal,
  NewsItemStatsModal,
  NewsItemViewModal,
} from "Pages/Spaces/components/NewsItems";
import TotalOpenRates from "UIKit/TotalOpenRates/TotalOpenRates";
import { LDFlagNames } from "Utilities/types";
import ItemAudienceModal from "Pages/Spaces/pages/Space/pages/MiniApp/NewsFeed/pages/Admin/components/ItemAudienceModal/ItemAudienceModal";
import BoostOpenRatesModal from "./components/BoostOpenRatesModal";
import { useMainSectionAnalytics } from "../../hooks/useMainSectionAnalytics";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import EmptyLocalList from "shared/components/Lists/EmptyLocalList/EmptyLocalList";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { modalStore } from "Stores/ModalStore";

export const AdminNewsFeed = observer(() => {
  const { url } = useRouteMatch();
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();

  const { id: mainSectionId } = sectionStore.getChildReferenceByMainSectionType(
    AppLevelSection.newsFeed,
  ) ?? {
    id: "",
  };

  useRecordMainSectionVisit({ mainSectionId, visitFrom: "directNavigation" });

  const { spaceSection } = useSpaceSection(AppLevelSection.newsFeed);

  const { files } = useNewsFeeds();

  const { mainSectionAnalytics, isLoadingMainSectionAnalytics } = useMainSectionAnalytics({
    mainSectionId: spaceSection.id,
  });

  const showOpenRateTrendNewsFeed = flags[LDFlagNames.NEWSFEED_OPEN_RATE_TREND];
  const canShowAnalytics = flags[LDFlagNames.ANALYTICS_NEWSFEED];

  const { searchInstanceId, handleClearSearch, handleSearchClick, searchResults, isSearchActive } =
    useLocalMobxSearch({
      data: files,
      keysToSearch: ["name", "createdBy"],
    });

  const hasNewsFeed = files.length > 0;

  const canSendReminder = mainSectionAnalytics?.readRatio !== "100";

  const handleAddNewsFeed = () =>
    modalStore.openModal({
      variant: "component",
      Component: (
        <AddNewsItemModal
          onCloseModal={() => modalStore.closeModal()}
          mainSectionType={AppLevelSection.newsFeed}
        />
      ),
      name: "AddNewsItemModal",
    });

  const handleBoostOpenRatesModal = () =>
    modalStore.openModal({
      variant: "component",
      Component: <BoostOpenRatesModal />,
      name: "BoostOpenRatesModal",
    });

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("newsFeed_title")} />
        <NavButton to={url.replace(`/${AppLevelSection.newsFeed}`, "")} />
        {canShowAnalytics ? (
          <TotalOpenRates
            average={mainSectionAnalytics?.readRatio || "0"}
            onOpenRate={handleBoostOpenRatesModal}
            showOpenRateTrend={showOpenRateTrendNewsFeed}
            showBoostOpenRate={hasNewsFeed ? canSendReminder : undefined}
            isLoading={isLoadingMainSectionAnalytics}
          />
        ) : null}
        <AddButton onClick={handleAddNewsFeed} data-testid="add-new-file">
          {t(`${AppLevelSection.newsFeed}_add_new_file`)}
        </AddButton>

        <div data-testid="news-item-list">
          <EolasVersatileList
            items={isSearchActive ? searchResults : files}
            disabledSortMethods={["favourites", "dragAndDrop"]}
            renderItem={ListItem}
            isSearchable
            isSortable={!isSearchActive}
            searchInstanceId={searchInstanceId}
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            searchMode="click"
            defaultSortMethod={"date"}
            sortDateBy="createdAt"
            className="space-y-6"
            ListEmptyComponent={isSearchActive ? NoSearchResults : undefined}
          />
        </div>
      </InnerPageWrapper>
    </>
  );
});

const ListItem = ({ item }: EolasVersatileListRenderItemProps<EolasFile>) => {
  const handleClickFile = (newsItem: EolasFile) =>
    modalStore.openModal({
      variant: "component",
      Component: <NewsItemViewModal newsItem={newsItem} />,
      name: "NewsItemViewModal",
    });

  const handleDeleteFile = (newsItem: EolasFile) =>
    modalStore.openModal({
      variant: "component",
      Component: <DeleteFileModal file={newsItem} onCloseModal={modalStore.closeModal} />,
      name: "DeleteFileModal",
    });

  const handleViewChart = (newsItem: EolasFile) =>
    modalStore.openModal({
      variant: "component",
      Component: (
        <NewsItemStatsModal
          newsItem={newsItem}
          sectionType={AppLevelSection.newsFeed}
          mainSectionId={newsItem.mainSectionID}
        />
      ),
      name: "NewsItemStatsModal",
    });

  const handleViewAudience = (newsItem: EolasFile) =>
    modalStore.openModal({
      variant: "component",
      Component: <ItemAudienceModal item={newsItem} />,
      name: "ItemAudienceModal",
    });

  return (
    <AdminNewsFeedTile
      newsFeed={item}
      onClick={handleClickFile}
      onDelete={handleDeleteFile}
      onViewChart={handleViewChart}
      onViewAudience={handleViewAudience}
    />
  );
};

const NoSearchResults = () => <EmptyLocalList isSearchResult />;
