import { useMemo } from "react";
import {
  copilotClient,
  clinicalResourceToEolasFileMapper,
  CommunityLevelSection,
  sectionStore,
} from "@eolas-medical/core";
import { useInfiniteQuery, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { KnowledgeResult } from "../types";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { mapKnowledgeFiles } from "../helpers";
import { useLaunchDarkly } from "Contexts";
import { getEntityTypeBasedOnFlag } from "../functions/getEntityTypeBasedOnFlag";
import { LDFlagNames } from "Utilities/types";
import {
  EOLAS_BODY_WEIGHT_CALC_ICON_NAME,
  EOLAS_CR_CL_CALC_ICON_NAME,
  EOLAS_GENT_CALC_ICON_NAME,
} from "Pages/Knowledge/pages/ClinicalCalculators/types";

interface Result {
  id: string;
  nationalBody: string;
  title: string;
  url: string;
  answer?: string;
}
interface SearchResult {
  moreHits: boolean;
  startIdx: number;
  results: Result[];
  matchType?: MatchType;
  relatedQueries?: string[];
  excludeId?: string[] | null | undefined;
}

type MatchType = "phrase" | "best_fields";

const filterEolasCalcs = (
  file: KnowledgeResult,
  flags: {
    isEolasGentCalcEnabled: boolean;
    isEolasCreatinineCalcEnabled: boolean;
    isEolasIdealBodyWeightCalcEnabled: boolean;
  },
) => {
  const shouldFilter =
    !flags.isEolasGentCalcEnabled ||
    !flags.isEolasCreatinineCalcEnabled ||
    !flags.isEolasIdealBodyWeightCalcEnabled;
  if (file.searchSubType !== CommunityLevelSection.clinicalCalculators || !shouldFilter) {
    return true;
  }

  if (file.iconName === EOLAS_GENT_CALC_ICON_NAME && !flags.isEolasGentCalcEnabled) {
    return false;
  }

  if (file.iconName === EOLAS_CR_CL_CALC_ICON_NAME && !flags.isEolasCreatinineCalcEnabled) {
    return false;
  }

  if (
    file.iconName === EOLAS_BODY_WEIGHT_CALC_ICON_NAME &&
    !flags.isEolasIdealBodyWeightCalcEnabled
  ) {
    return false;
  }

  return true;
};

const useKnowledgeCopilotSearch = ({
  searchValue,
  knowledgeSearchDisabled,
}: {
  searchValue: string;
  knowledgeSearchDisabled?: boolean;
}) => {
  const { supportedEolasRegion } = useSupportedRegion();
  const { flags } = useLaunchDarkly();
  const entityType = getEntityTypeBasedOnFlag(flags);
  let matchType: MatchType | undefined;
  let excludeByIds: string[] | undefined;

  const queryFn = ({ pageParam = 0 }): Promise<SearchResult> => {
    return copilotClient.search({
      query: searchValue,
      startIndex: pageParam,
      entityType,
      regions: [supportedEolasRegion],
      excludeByIds,
      matchType,
      organisationId: sectionStore.organisationID ? sectionStore.organisationID : undefined,
    });
  };

  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isError,
  }: InfiniteQueryObserverResult<SearchResult, Error> = useInfiniteQuery(
    ["knowledgeCopilotSearch", { searchValue }],
    ({ pageParam = 0 }) =>
      queryFn({
        pageParam,
      }),
    {
      getNextPageParam: (lastPage) => (lastPage.moreHits ? lastPage.startIdx : undefined), // Calculate the next start index if there are more hits
      enabled: !knowledgeSearchDisabled && searchValue.length >= 2,
      retry: false,
      staleTime: 60000,
      onSuccess: () => {
        const lastPageExcludeId = infiniteData?.pages.slice(-1)[0].excludeId;

        // on subsequent requests, take the matchtype and excludeId
        // from the response and send it back in next fetching (this will help the search to be faster)

        matchType = infiniteData?.pages.slice(-1)[0].matchType;
        excludeByIds = lastPageExcludeId ?? [];
      },
    },
  );

  const isEolasGentCalcEnabled = flags[LDFlagNames.EOLAS_GENTAMICIN_CALC] ?? true;
  const isEolasCreatinineCalcEnabled = flags[LDFlagNames.EOLAS_CREATININE_CLEARANCE_CALC] ?? true;
  const isEolasIdealBodyWeightCalcEnabled = flags[LDFlagNames.EOLAS_IDEAL_BODY_WEIGHT_CALC] ?? true;

  const knowledgeFiles: KnowledgeResult[] = useMemo(() => {
    const results = infiniteData?.pages.flatMap((page) => page.results) || [];
    const mappedResults = clinicalResourceToEolasFileMapper({
      newClinicalResources: results,
    });
    const knowledgeFiles = mapKnowledgeFiles(mappedResults);
    return knowledgeFiles.filter((file) =>
      filterEolasCalcs(file, {
        isEolasGentCalcEnabled,
        isEolasCreatinineCalcEnabled,
        isEolasIdealBodyWeightCalcEnabled,
      }),
    );
  }, [
    infiniteData,
    isEolasGentCalcEnabled,
    isEolasCreatinineCalcEnabled,
    isEolasIdealBodyWeightCalcEnabled,
  ]);

  const relatedQueries: (string | undefined)[] = useMemo(() => {
    const pages = infiniteData?.pages || [];

    const hasRelatedQueriesWithSomeData = pages.some((page) => page.relatedQueries);

    const results = hasRelatedQueriesWithSomeData
      ? pages.flatMap((page) => page.relatedQueries || [])
      : [];

    return results;
  }, [infiniteData]);

  return {
    relatedQueries,
    knowledgeFiles,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isCopilotError: isError,
  };
};

export default useKnowledgeCopilotSearch;
