import { AppLevelSection, hasStringProp, sectionStore } from "@eolas-medical/core";
import { ChecklistItem } from "../pages";

export const isChecklistItem = (item: unknown): item is ChecklistItem => {
  let mainSectionType: string | null = null;
  if (hasStringProp(item, "mainSectionType")) {
    mainSectionType = item.mainSectionType;
  } else if (hasStringProp(item, "mainSectionID")) {
    // checklist item from global search does not have mainSectionType property
    mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(item.mainSectionID);
  }
  if (hasStringProp(item, "id") && mainSectionType === AppLevelSection.checklists) {
    return true;
  }
  return false;
};
