import { motion } from "framer-motion";

import { clinicalQuestionStore, eolasLogger } from "@eolas-medical/core";
import { observer } from "mobx-react-lite";
import { ProgressBar, Text, Title } from "UIKit";
import { EolasCard } from "UIKit/EolasCard/EolasCard";
import { makeHtmlToReactNodeParser } from "shared/functions/makeHtmlToReactNodeParser";
import { LoadingDots } from "./LoadingDots";
import { getResponseProcessingInstructions } from "../functions/responseProcessingInstructions";
import { ButtonRow } from "./ButtonRow";
import { useMemo, useState } from "react";
import { Control, FieldError } from "react-hook-form";
import { ClinicalQuestion } from "../hooks/useClinicalQuestionInput";
import { useQueryClient } from "@tanstack/react-query";
import { makeGetClinicalQuestionResponseQueryKey } from "modules/clinicalQuestions/data/useGetClinicalQuestionResponse";
import {
  CANCELLED_QUESTION_MESSAGE,
  INTERRUPTED_RESPONSE_MESSAGE,
} from "modules/clinicalQuestions/data/constants";
import { QuestionBar } from "./QuestionBar";
import { FollowUpQuestionCard } from "./FollowUpQuestionCard";
import { useTranslation } from "react-i18next";
import { useNotifications } from "Components/Notifications";
import { transformIdsInHtml } from "../functions/transformIdHtml";
import "../styles/Ecqs.css";

import { WarningPill } from "./WarningPill";
import { AskEolasVariants } from "Components/AskEolas/AskEolasHome";
export type ResponseCardsProps = {
  progress: number;
  control: Control<ClinicalQuestion>;
  onSubmit: () => void;
  type: AskEolasVariants;
  followUpInputError?: FieldError;
};

export const ResponseCards = observer(
  ({ progress, control, onSubmit, followUpInputError, type }: ResponseCardsProps) => {
    // only 1 follow up question allowed in v1.0
    const responseKeys = Object.keys(clinicalQuestionStore.responsesMap);
    const shouldShowQuestionBar =
      responseKeys.length === 1 && !clinicalQuestionStore.getIsResponseLoading(responseKeys[0]);
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const onFocus = () => {
      setIsFocused(true);
    };

    const unfocusInput = () => {
      setIsFocused(false);
    };

    const { showNotification } = useNotifications();
    const onSubmitFollowUp = async () => {
      const priorRequestID = Object.keys(clinicalQuestionStore.responsesMap)[0] ?? "";
      await queryClient.cancelQueries(makeGetClinicalQuestionResponseQueryKey(priorRequestID));
      const priorResponse = clinicalQuestionStore.responsesMap[priorRequestID];
      // this should not happen but better to handle it
      if (!priorResponse) {
        eolasLogger.error(new Error("Unable to find prior question to submit follow up to"), {
          priorRequestID,
          priorResponse,
        });
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("create_question_error"),
        });
        return;
      }
      let body = priorResponse.body;
      if (clinicalQuestionStore.getIsResponseLoading(priorRequestID)) {
        body = CANCELLED_QUESTION_MESSAGE;
      }
      if (clinicalQuestionStore.getIsResponseStreaming(priorRequestID)) {
        body = priorResponse.body + INTERRUPTED_RESPONSE_MESSAGE;
      }

      clinicalQuestionStore.updateResponsesMap(priorRequestID, {
        jobStatus: "FINISHED",
        body: body,
        requestID: priorRequestID,
      });

      onSubmit();
    };

    return (
      <>
        {Object.values(clinicalQuestionStore.responsesMap).map((response, index) => {
          if (!response) {
            return null;
          }
          const body = ["STREAMING", "FINISHED", "FAILED"].includes(response.jobStatus)
            ? response.body
            : undefined;
          return (
            <>
              <div key={index} className="mb-4">
                <ResponseCard
                  progress={progress}
                  body={body}
                  questionID={response?.requestID ?? null}
                  title={response.originalString}
                  shouldShowTitle={index === 0}
                  type={type}
                />
              </div>
              {index === 0 ? (
                <div className="mb-4">
                  <FollowUpQuestionCard />
                </div>
              ) : null}
            </>
          );
        })}
        {shouldShowQuestionBar ? (
          <QuestionBar
            onFocus={onFocus}
            onBlur={unfocusInput}
            isFocused={isFocused}
            fieldName="notes"
            placeholder={t("add_more_context_placeholder")}
            onSubmit={onSubmitFollowUp}
            control={control}
            errorMessage={followUpInputError?.message}
            shouldShowBorderAnimation={false}
          />
        ) : null}
      </>
    );
  },
);

export type ResponseCardProps = {
  progress: number;
  questionID: string | null;
  type: AskEolasVariants;
  body?: string;
  title?: string;
  shouldShowTitle?: boolean;
};

export const ResponseCard = observer(
  ({ progress, body = "", questionID, title, shouldShowTitle, type }: ResponseCardProps) => {
    if (!questionID) {
      return null;
    }
    const isLoading = progress <= 100 && clinicalQuestionStore.getIsResponseLoading(questionID);
    const statusUpdate = clinicalQuestionStore.getUserFacingStatus(questionID);
    const statusEnum = clinicalQuestionStore.responsesMap[questionID ?? ""]?.statusEnum;
    const shouldShowWebFallbackPill = statusEnum === "WEB_FALLBACK";
    const convertHtmlToReactComponents = useMemo(() => {
      return makeHtmlToReactNodeParser({
        mode: "defaultEolasStyles",
        overrideProcessingInstructions: getResponseProcessingInstructions(questionID),
      }).convertHtmlToReactComponents;
    }, [questionID]);

    const parsedHtml = useMemo(() => {
      if (!body) {
        return null;
      }

      const transformedHtml = transformIdsInHtml(body, questionID);
      return convertHtmlToReactComponents(transformedHtml);
    }, [body, questionID, convertHtmlToReactComponents]);

    return (
      <EolasCard elevation={2}>
        <div className="p-6 cursor-default">
          {title && shouldShowTitle ? (
            <Title level={6} className="mb-4">
              {title}
            </Title>
          ) : null}
          {parsedHtml ? (
            <>
              <WarningPill variant="general" type={type} questionID={questionID} />
              {shouldShowWebFallbackPill ? (
                <WarningPill variant="webFallback" questionID={questionID} type={type} />
              ) : null}
            </>
          ) : null}
          {isLoading ? (
            <ProgressBar
              progress={progress}
              className="mb-4 shadow-none"
              loadingStatusLabel={progress === 100 ? "spinner" : "percentage"}
            />
          ) : null}
          {statusUpdate ? (
            <motion.div
              key={statusUpdate}
              initial={{ y: "100%" }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.75 }}
              exit={{ opacity: 0, y: "100%" }}
              className="flex items-center justify-center w-full opacity-0"
            >
              <Text level={1}>{statusUpdate}</Text>
            </motion.div>
          ) : null}

          {parsedHtml}
          {clinicalQuestionStore.getIsResponseStreaming(questionID) ? (
            <div className="flex justify-center">
              <LoadingDots />
            </div>
          ) : null}
          {body ? <ButtonRow questionID={questionID} text={body} title={title} /> : null}
        </div>
      </EolasCard>
    );
  },
);
