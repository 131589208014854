import { LegacyContentSections, LegacyContentSectionsProps } from "./LegacyContentSections";
import useContentRepositoryContext from "../context/useContentRepositoryContext";
import { eolasLogger } from "@eolas-medical/core";

const ContentSections = (legacyProps: LegacyContentSectionsProps) => {
  const { contextDomain } = useContentRepositoryContext();

  if (contextDomain !== "knowledge") {
    eolasLogger.error(
      "ContentSections was used in a non-knowledge context, this should not happen",
    );
  }

  return <LegacyContentSections {...legacyProps} />;
};

export default ContentSections;
