import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getEntityIdSuffix,
  myFavouritesClient,
  myFavouritesStore,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { isDev } from "Utilities/helpers";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { makeGetMyFavouritesQueryKey } from "modules/portfolio/data/useGetMyFavourites";

type Props = {
  entityId: string | null;
  entityType: FavouriteEntityType | null;
  onSuccess?: () => void;
  onError?: () => void;
};

const mutateFavourite = async (favouriteId: string): Promise<void> => {
  return myFavouritesClient.deleteFavourite(favouriteId);
};

export const useRemoveFavourite = ({
  entityId: entityIdParam,
  entityType,
  onSuccess,
  onError,
}: Props) => {
  const { supportedEolasRegion } = useSupportedRegion();
  const queryClient = useQueryClient();
  const userId = userStore.userID;
  const communityId = sectionStore.data.community?.id;

  const { mutate, isLoading, isSuccess, error } = useMutation(mutateFavourite, {
    mutationKey: ["removeFavourite", entityIdParam],
    onSuccess: () => {
      if (!entityIdParam) {
        throw new Error("entityId is required for removing a favourite.");
      }

      let entityId = entityIdParam;

      if (entityType) {
        entityId = entityId + getEntityIdSuffix(entityType);
      }

      if (!entityType && isDev()) {
        console.warn(
          "entityType is not provided for entityId: ",
          entityId,
          " this is required for BNF and BNFC entities.",
        );
      }

      myFavouritesStore.updateMap({
        entityId: entityId,
        userAction: "remove",
      });

      queryClient.invalidateQueries(
        makeGetMyFavouritesQueryKey({ userId, supportedEolasRegion, communityId }),
      );

      if (onSuccess) {
        onSuccess();
      }
    },
    onError,
  });

  return {
    removeFavourite: mutate,
    isRemovingFavourite: isLoading,
    didRemoveFavouriteSuccess: isSuccess,
    removeFavouriteError: error,
  };
};

export default useRemoveFavourite;
