import {
  eolasLogger,
  hasProp,
  hasStringProp,
  isEolasFile,
  isEolasShadowCopyFile,
  Section,
  sectionStore,
} from "@eolas-medical/core";
import { cloneDeep } from "lodash";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { ValidFileChildren } from "shared/hooks/useGetAllContentRepository";

/**
 * Update UI before general fetch completes:
 */

export const intermediateUpdateForUi = async (
  props:
    | { file: unknown; action: "update" | "delete"; type: "file" }
    | { section: unknown; action: "update" | "delete"; type: "section" },
) => {
  if (props.type === "file") {
    const { file, action } = props;
    if (!isEolasFile(file)) {
      eolasLogger.error(new Error("Intermediate update: unable to recognise Eolas File"), { file });
      return;
    }
    const eolasFile = cloneDeep(file);
    if (action === "delete") {
      eolasFile.deleted = "true";
    }
    await contentDbStore.addItem(eolasFile);
    const existingSection = sectionStore.getSection(file.parentID);
    if (!existingSection.id) {
      return;
    }
    const newExistingParentSection = cloneDeep(existingSection);
    newExistingParentSection.childrenOrder = [...(newExistingParentSection.childrenOrder ?? [])];
    const existingChildReference = newExistingParentSection.childrenOrder.find(
      (item) => item?.id === eolasFile.id,
    );
    if (existingChildReference) {
      if (action === "delete") {
        newExistingParentSection.childrenOrder = newExistingParentSection.childrenOrder.filter(
          (item) => item?.id !== eolasFile.id,
        );
      } else {
        existingChildReference.name = eolasFile.name;
      }
    } else if (action === "update") {
      newExistingParentSection.childrenOrder = [
        {
          __typename: "ChildReference",
          id: eolasFile.id,
          name: eolasFile.name,
          type: isEolasShadowCopyFile(eolasFile)
            ? ValidFileChildren.deepLink
            : ValidFileChildren.file,
        },
        ...newExistingParentSection.childrenOrder,
      ];
    }
    sectionStore.upsertSections([newExistingParentSection]);
  }
  if (props.type === "section") {
    const { section, action } = props;
    if (!isSection(section)) {
      eolasLogger.error(new Error("Intermediate update: unable to recognise Eolas Section"), {
        section,
      });
      return;
    }
    const eolasSection = cloneDeep(section);
    if (action === "delete") {
      eolasSection.deleted = "true";
    }
    if (!eolasSection.parentID) {
      return;
    }
    const parentSection = sectionStore.getSection(eolasSection.parentID);
    if (!parentSection.id) {
      return;
    }
    const newParentSection = cloneDeep(parentSection);
    newParentSection.childrenOrder = [...(newParentSection.childrenOrder ?? [])];
    const existingChildReference = newParentSection.childrenOrder.find(
      (item) => item?.id === section.id,
    );
    if (existingChildReference) {
      if (action === "delete") {
        newParentSection.childrenOrder = newParentSection.childrenOrder.filter(
          (item) => item?.id !== section.id,
        );
      } else {
        existingChildReference.name = section.name;
      }
    } else if (action === "update") {
      newParentSection.childrenOrder = [
        {
          __typename: "ChildReference",
          id: section.id,
          name: section.name,
          type: "section",
        },
        ...newParentSection.childrenOrder,
      ];
    }
    sectionStore.upsertSections([eolasSection, newParentSection]);
  }
};

const isSection = (section: unknown): section is Section => {
  if (!hasStringProp(section, "id") || !hasStringProp(section, "mainSectionID")) {
    return false;
  }
  if (hasProp(section, "childrenOrder")) {
    return Array.isArray(section.childrenOrder) || section.childrenOrder === null;
  }
  return false;
};
