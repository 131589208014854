import { FC } from "react";
import { BodyWeightCalcResults } from "@eolas-medical/core";

import { ScaleIcon, WeightIcon } from "Assets";

import { ResultBox } from "./ResultBox";

interface BodyWeightResultProps {
  results: BodyWeightCalcResults;
}

export const BodyWeightResults: FC<BodyWeightResultProps> = ({
  results,
}: BodyWeightResultProps) => {
  const { idealBodyWeight, error, actualBodyWeight, adjustedBodyWeight } = results;

  return (
    <>
      {actualBodyWeight ? (
        <ResultBox
          title="Actual Body Weight:"
          text={`${actualBodyWeight}kg`}
          icon={<WeightIcon className="w-6 h-6 mr-4" />}
          error={error}
        />
      ) : null}

      <ResultBox
        title="Ideal Body Weight:"
        text={idealBodyWeight ? `${idealBodyWeight}kg` : "N/A"}
        icon={<ScaleIcon className="w-6 h-6 mr-4" />}
        error={error}
      />

      {adjustedBodyWeight ? (
        <ResultBox
          title="Adjusted Body Weight:"
          text={adjustedBodyWeight ? `${adjustedBodyWeight}kg` : "N/A"}
          icon={<ScaleIcon className="w-6 h-6 mr-4" />}
        />
      ) : null}
    </>
  );
};
