import { FlashCardContent, parseFlashcardFileMetadata } from "@eolas-medical/core";
import { Flashcard, InnerPageWrapper, NavButton } from "UIKit";
import { getImageUrl } from "Utilities";
import { makeHtmlToReactNodeParser } from "shared/functions/makeHtmlToReactNodeParser";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";

interface FlashcardViewer {
  flashcard: FlashCardContent;
  backBehaviour?: "goBack" | "goHome";
  shouldShowDate?: boolean;
}

const { convertHtmlToReactComponents } = makeHtmlToReactNodeParser({
  mode: "defaultEolasStyles",
});

const FlashcardViewer = ({
  flashcard,
  backBehaviour = "goBack",
  shouldShowDate = true,
}: FlashcardViewer) => {
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });

  const flashcardMetadata = flashcard?.metadata
    ? parseFlashcardFileMetadata(flashcard?.metadata)
    : undefined;

  const parsedFlashcardContent = convertHtmlToReactComponents(flashcardMetadata?.flashcardContent);

  return (
    <InnerPageWrapper>
      <div className="py-1" data-testid="flashcard-viewer">
        <div className="flex justify-center space-x-3">
          <NavButton onClick={handleGoBack} />
        </div>
      </div>

      <Flashcard
        imgSrc={getImageUrl(flashcard.url ?? "")}
        name={flashcard.name ?? ""}
        description={flashcard.description || undefined}
        createdAt={shouldShowDate ? flashcard.createdAt : undefined}
        content={parsedFlashcardContent}
      />
    </InnerPageWrapper>
  );
};

export default FlashcardViewer;
