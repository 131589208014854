import React from "react";
import { observer } from "mobx-react-lite";

import { EolasFile } from "@eolas-medical/core";

interface IncidentDetailsProps {
  incident: EolasFile;
}

// TODO: remove this component (feature has been removed)
const IncidentDetails: React.FC<IncidentDetailsProps> = ({ incident }: IncidentDetailsProps) => {
  if (incident) {
    return <></>;
  }
  return null;
};

export const MajorIncidentsList = observer(() => {
  const allIncidents: EolasFile[] = [];

  return (
    <div className="flex flex-col space-y-4">
      {allIncidents.map(
        (incident) =>
          incident.moreInfoTwo === "active" && (
            <IncidentDetails key={incident.id} incident={incident} />
          ),
      )}
    </div>
  );
});
