import { InnerModalWrapper, Button, Title, Text, IconButton } from "UIKit";
import { SelectTileList, SelectTileListContext } from "../SelectTileList/SelectTileList";
import { useTranslation } from "react-i18next";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { EolasSectionTile } from "UIKit/EolasSectionTile/EolasSectionTile";
import { ArrowLeftIcon, CaretRight } from "Assets/Icons/monocolored";
import { observer } from "mobx-react-lite";
import { EolasRadio } from "UIKit/EolasRadio/EolasRadio";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { RootIcon } from "../RootIcons";
import { hasProp } from "@eolas-medical/core";
import { useLocalChildrenList, ListItem } from "../SelectTileList/hooks/useLocalChildrenList";
import { getIconUrl } from "modules/helpers";
import {
  BulkActionItemsModalProps,
  useBulkActionItemsModal,
} from "./hooks/useBulkActionItemsModal";
import { InvalidItemsModal, InvalidItemsModalStrings } from "./components/InvalidItemsModal";
import { ItemsSuccess, ItemsSuccessStrings } from "./components/ItemsSuccess";
import { ItemsPartialSuccess, ItemsPartialSuccessStrings } from "./components/ItemsPartialSuccess";
import { BulkResponses } from "./types";

type BulkActionItemsModalStrings = ItemsSuccessStrings &
  InvalidItemsModalStrings &
  ItemsPartialSuccessStrings & {
    modalTitle: string;
    modalDescription: string;
    genericErrorMessage: string;
  };

type Props<T extends BulkResponses> = BulkActionItemsModalStrings &
  BulkActionItemsModalProps<T> & { isLoading: boolean };

export const BulkActionItemsModal = observer(
  <T extends BulkResponses>({
    invalidFilesDescription,
    allInvalidFilesDescription,
    invalidFilesTitle,
    customQueryKey,
    successMessage,
    partialSuccessErrorMessage,
    retryFailedMessage,
    modalTitle,
    modalDescription,
    genericErrorMessage,
    isLoading,
    isError,
    initialItems,
    results,
    bulkAction,
    isValidItem,
  }: Props<T>) => {
    const { t } = useTranslation();

    const {
      handleSelectionChange,
      selectedSectionId,
      isSearching,
      setIsSearching,
      handleBulkActionItems,
      shouldShowError,
      shouldShowPartialSuccess,
      shouldShowSuccess,
      retryFailedItems,
      hasRetryFailed,
      failedItems,
      disabledSectionIds,
      shouldShowInvalidItemsModal,
      onInvalidItemsContinue,
      shouldShowOrgItems,
      shouldShowSpaceItems,
      fullAccessLevel,
      orgFullAccessLevel,
      validItems,
      invalidItems,
    } = useBulkActionItemsModal<T>({
      initialItems,
      bulkAction,
      isError,
      results,
      isValidItem,
    });

    const { items, handleNavigateDown, handleNavigateUp, parentItem, searchData } =
      useLocalChildrenList({
        disabledIds: disabledSectionIds,
        leafType: "sectionWithFiles",
        shouldShowOrgItems,
        shouldShowSpaceItems,
      });

    const isAtRoot = items.length > 0 && items[0].hasParent === false ? true : false;

    const onNavigateUp = () => {
      // For this modal, we want to clear the selection when navigating
      if (selectedSectionId) {
        handleSelectionChange({});
      }
      handleNavigateUp();
    };

    if (shouldShowInvalidItemsModal) {
      return (
        <InvalidItemsModal
          onContinue={onInvalidItemsContinue}
          invalidFilesDescription={invalidFilesDescription}
          allInvalidFilesDescription={allInvalidFilesDescription}
          invalidFilesTitle={invalidFilesTitle}
          invalidItems={invalidItems}
          validItems={validItems}
        />
      );
    }

    if (shouldShowSuccess && selectedSectionId) {
      return (
        <ItemsSuccess
          destinationSectionId={selectedSectionId}
          customQueryKey={customQueryKey}
          successMessage={successMessage}
        />
      );
    }

    if (shouldShowPartialSuccess) {
      return (
        <ItemsPartialSuccess
          originalItems={validItems}
          failedItems={failedItems}
          retryFailedItems={retryFailedItems}
          isRetrying={isLoading}
          didRetryFail={hasRetryFailed}
          partialSuccessErrorMessage={partialSuccessErrorMessage}
          retryFailedMessage={retryFailedMessage}
        />
      );
    }

    const shouldShowSearch =
      orgFullAccessLevel !== "limitedAdmin" && fullAccessLevel !== "limitedAdmin";

    return (
      <InnerModalWrapper>
        <div className="flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 h-80vh w-full">
          <div>
            {isAtRoot || isSearching ? null : (
              <IconButton
                className="absolute inset-6 sm:inset-12"
                color="white"
                variant="rounded"
                onClick={onNavigateUp}
                icon={<ArrowLeftIcon className="w-5 h-5" />}
              />
            )}

            <Title level={4} className="text-center px-9 line-clamp-2">
              {parentItem ? getNameFromListItem(parentItem) : modalTitle}
            </Title>
          </div>

          <Text level={2} className="text-gray-500">
            {modalDescription}
          </Text>

          <SelectTileList
            data={items}
            selectionMode="single"
            onSelectionChange={handleSelectionChange}
            renderTile={SectionItem}
            handleNavigateDown={handleNavigateDown}
            searchKeys={["item.name"]}
            searchData={searchData}
            onClearSearch={() => setIsSearching(false)}
            onSearch={() => setIsSearching(true)}
            isSearchable={shouldShowSearch}
          />
        </div>
        <Button
          size="lg"
          color="blue"
          variant="solid"
          disabled={!selectedSectionId}
          onClick={handleBulkActionItems}
          isLoading={isLoading}
          className={"w-1/2 self-center xs:w-full absolute bottom-6 sm:bottom-12"}
        >
          {t("general_done")}
        </Button>
        {shouldShowError ? (
          <Text level={2} className="text-red-500 flex flex-col items-center p-6 w-full">
            {genericErrorMessage}
          </Text>
        ) : null}
      </InnerModalWrapper>
    );
  },
);

const SectionItem = ({
  item,
  context,
}: EolasVersatileListRenderItemProps<ListItem, SelectTileListContext<ListItem>>) => {
  const {
    handleNavigateDown,
    selectedItems,
    handleAddToSelection,
    handleRemoveFromSelection,
    handleClearSelection,
  } = context;
  const isSelected = Boolean(selectedItems && selectedItems[item.id]);
  const hasSelection = Object.keys(selectedItems ?? {}).length > 0;

  const handleToggle = () => {
    if (isSelected) {
      handleRemoveFromSelection(item.id);
      return;
    }
    handleAddToSelection(item.id, item);
  };

  const handleClick = () => {
    if (item.childrenType === "sections" || item.childrenType === "mainSections") {
      // For this modal, we want to clear the selection when navigating
      if (hasSelection) {
        handleClearSelection();
      }
      handleNavigateDown(item);
      return;
    }

    handleToggle();
  };

  if (item.childrenType === "none") {
    // Files are not supported in this modal and will never be rendered
    return null;
  }

  if (item.childrenType === "mainSections") {
    const rootItem = item.item;
    return (
      <div className="p-1">
        <EolasTile
          onClick={handleClick}
          variant="rectangular"
          primaryText={rootItem.title}
          icon={<RootIcon root={item.rootType} />}
          rightAction={<CaretRight className="h-6 w-6 text-grey-600" />}
        />
      </div>
    );
  }

  const sectionChildRef = item.item;

  return (
    <div className="p-1">
      <EolasSectionTile
        isDisabled={item.isDisabled}
        onClick={handleClick}
        variant="withChildDetails"
        title={sectionChildRef.name || ""}
        iconUrl={getIconUrl(sectionChildRef.icon || "")}
        childrenType={item.childrenType}
        rightAction={
          item.childrenType === "sections" ? (
            <CaretRight className="h-6 w-6 text-grey-600" />
          ) : (
            <EolasRadio
              isDisabled={item.isDisabled}
              variant="checkCircle"
              size="xs"
              isChecked={isSelected}
              onToggle={handleToggle}
            />
          )
        }
      />
    </div>
  );
};

const getNameFromListItem = (listItem: ListItem): string => {
  const item = listItem.item;

  if (hasProp(item, "name")) {
    return item.name;
  }

  if (hasProp(item, "title")) {
    return item.title;
  }

  return "";
};
