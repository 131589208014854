import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { WizardState } from "../types";
import { hasStringProp, NewContentType } from "@eolas-medical/core";
import { extractFileNameFromLegacyS3Key } from "Utilities";

export const useGetMediaName = (state: WizardState) => {
  const { file } = useGetFile({ id: state.fileId, options: { shouldFetchOnMountOnly: true } });

  let key: string | undefined = undefined;
  let dtoMediaName: string | undefined = undefined;
  let type: string | undefined = undefined;

  if (state.isExistingFileWithNewType) {
    key = hasStringProp(state.dto.newProperties, "key") ? state.dto.newProperties.key : undefined;
    dtoMediaName = hasStringProp(state.dto.newProperties, "mediaName")
      ? state.dto.newProperties.mediaName
      : undefined;
    type = hasStringProp(state.dto.newProperties, "mediaType")
      ? state.dto.newProperties.mediaType
      : undefined;
    if (state.dto.newType === NewContentType.DSM) {
      type = "xlsx";
    }
  } else {
    ({ key, mediaName: dtoMediaName } = state.dto);
    type = state.type;
  }

  let mediaName = `uploadedFile.${type ?? "pdf"}`;

  if (dtoMediaName) {
    mediaName = dtoMediaName;
  } else if (key && key.startsWith("public/")) {
    const fileName = extractFileNameFromLegacyS3Key(key);
    if (fileName) {
      mediaName = fileName;
    }
  } else if (state.fileId) {
    if (file && file.mediaName) {
      mediaName = file.mediaName;
    }
  }

  return mediaName;
};
