import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Button, Input, LottieWithHeader, Modal } from "UIKit";
import { noFilesFound, SearchIcon, ArrowLeftIcon, ArrowRightIcon } from "Assets";

import { useHighlightFileList } from "../hooks";
import { ChecklistRecord, EolasFile } from "@eolas-medical/core";
import { useSelectFileModal } from "Hooks";
import { isChecklist } from "Utilities";
import { HighlightChecklist, HighlightFile } from "./HighlightItem";

const arrowSize = { width: 20, height: 20 };
const filesToShow = 15;

export const FILTERS = {
  ALL: "all",
  DATE: "date",
  HIGHLIGHTED: "highlighted",
};

export const HighlightsItemList: React.FC = observer(() => {
  const [currentPage, setCurrentPage] = useState(0);
  const { modal, onSelectFile, onSelectChecklist, onCloseModal } = useSelectFileModal();

  const { t } = useTranslation();
  const { onSearch, searchInput, results, setFilter, filter } = useHighlightFileList();

  const paginatedFiles: EolasFile[] | ChecklistRecord = useMemo(() => {
    return results.slice(currentPage * filesToShow, (currentPage + 1) * filesToShow) ?? [];
  }, [currentPage, results]);

  const hasMorePages = useMemo(() => {
    return currentPage * filesToShow + filesToShow < results.length;
  }, [currentPage, results.length]);

  const tablePageDisplay = useMemo(() => {
    const to = hasMorePages ? currentPage * filesToShow + filesToShow : results.length;

    return {
      from: currentPage * filesToShow + 1,
      to,
      total: results.length,
    };
  }, [currentPage, hasMorePages, results.length]);

  return (
    <div data-testid="highlights" className="space-y-4 sm:space-y-8">
      <Input
        size="lg"
        className="input"
        value={searchInput}
        onChange={onSearch}
        iconLeft={<SearchIcon />}
        data-testid="filter-highlights-input"
        placeholder={t("SearchBarPlaceholder")}
      />

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <Button
          color="yellow"
          className="flex-1"
          data-testid="highlightedFilterButton"
          onClick={() => setFilter(FILTERS.HIGHLIGHTED)}
          variant={filter === FILTERS.HIGHLIGHTED ? "solid" : "outline"}
        >
          {t("highlights_button_highlighted")}
        </Button>

        <Button
          color="blue"
          className="flex-1"
          onClick={() => setFilter(FILTERS.ALL)}
          variant={filter === FILTERS.ALL ? "solid" : "outline"}
        >
          {t("highlights_button_all")}
        </Button>

        <Button
          color="green"
          className="flex-1"
          onClick={() => setFilter(FILTERS.DATE)}
          variant={filter === FILTERS.DATE ? "solid" : "outline"}
        >
          {t("highlights_button_recent")}
        </Button>
      </div>
      {results.length === 0 ? (
        <LottieWithHeader
          animationSize="125px"
          animation={noFilesFound}
          lottieOptions={{ loop: true }}
          text={t("general_noFilesFound")}
        />
      ) : (
        <>
          <div className="flex flex-col space-y-6 divide-y divide-grey-light">
            {paginatedFiles.map((item: EolasFile | ChecklistRecord) =>
              !isChecklist(item) ? (
                <HighlightFile file={item} key={item?.id} onClick={onSelectFile} />
              ) : (
                <HighlightChecklist checklist={item} key={item?.id} onClick={onSelectChecklist} />
              ),
            )}
          </div>

          <div className="flex justify-center items-center space-x-4">
            <Button
              size="sm"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 0}
            >
              <ArrowLeftIcon fill="white" {...arrowSize} />
            </Button>

            <span>{t("highlights_pages_files", tablePageDisplay)}</span>

            <Button
              size="sm"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={!hasMorePages}
            >
              <ArrowRightIcon fill="white" {...arrowSize} />
            </Button>
          </div>
        </>
      )}
      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </div>
  );
});
