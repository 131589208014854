import { useTranslation } from "react-i18next";
import { AppLevelSection } from "@eolas-medical/core";

import { noFilesFound } from "Assets";
import { EolasFileList } from "Components";
import { Loader, LottieWithHeader, Modal, Text } from "UIKit";

import { useSubmittedChecklists } from "../hooks";
import { observer } from "mobx-react-lite";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export interface SubmittedChecklistsProps {
  checklistID: string;
}

export const SubmittedChecklists = observer(({ checklistID }: SubmittedChecklistsProps) => {
  const { modal, onCloseModal, filterChecklists, onDelete, onSelect } = useSubmittedChecklists();
  const { isAddingChecklistFiles } = contentDbStore;
  const { t } = useTranslation();

  if (isAddingChecklistFiles) {
    return (
      <div className="w-full flex flex-col justify-center items-center">
        <Loader className="bg-transparent" />
        <Text className="text-center mt-8" level={2}>
          {t("checklists_loading")}
        </Text>
      </div>
    );
  }

  return (
    <>
      <EolasFileList
        isAdmin={true}
        defaultSort="date"
        draggable={false}
        onDelete={onDelete}
        sortDateBy="createdAt"
        className="space-y-6"
        onSelectFile={onSelect}
        listClassName="space-y-4"
        adminNoFiles={<NoFiles />}
        subSectionId={checklistID}
        filterFn={filterChecklists}
        mainSectionId={AppLevelSection.checklists}
      />

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});

const NoFiles = () => {
  const { t } = useTranslation();
  return (
    <LottieWithHeader
      animationSize="25%"
      animation={noFilesFound}
      data-testid="no-files-found"
      lottieOptions={{ loop: true }}
      text={t("general_noFilesFound")}
    />
  );
};
