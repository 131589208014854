import { Core, UI, WebViewerInstance } from "@pdftron/webviewer";
import { useTranslation } from "react-i18next";
import {
  DraftFile,
  EolasFile,
  contentClient,
  eolasLogger,
  sectionStore,
} from "@eolas-medical/core";
import { useRef, useMemo } from "react";

import { getPDFUrl } from "Hooks/useDocumentCache";
import { extractFileNameFromLegacyS3Key, isUrlString } from "Utilities";
import { debounce } from "lodash";
import { modalStore } from "Stores/ModalStore";
import { InsertLinkModal } from "Pages/FileViewer/components/DocumentViewer/components/InsertLinkModal/InsertLinkModal";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEolasNavigation } from "Components/Navigation/hooks";
import {
  isAncestorSectionIdInLimitedAccessArray,
  makeLimitedAdminSectionLookupMap,
} from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { useGetLimitedAccess } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { useUploadBlob } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useUploadBlob";
import { isEolasDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/typeguards";
import { useRefetchAppData, useRunOnMountUnmount } from "Hooks";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";
import { EolasDocumentViewerProps } from "UIKit/EolasDocumentViewer/types";
import { getFileExtensionFromString } from "Utilities/helpers";

interface LinkAnnotation extends Core.Annotations.Link {
  PageNumber: number;
  StrokeColor: Core.Annotations.Color;
  StrokeStyle: string;
  StrokeThickness: number;
  X: number;
  Y: number;
  Width: number;
  Height: number;
  addAction(event: string, action: Core.Actions.Action): void;
}

type TextPopUpButton = {
  type: "actionButton";
  onClick: () => void;
  img?: string;
  label?: string;
};

/**
 * This is a custom type for the TextPopup object from the WebViewer API.
 * The WebViewer API provides a very loose typing for this object, so we need to define it ourselves.
 */
type TextPopup = {
  add: (items: TextPopUpButton[]) => void;
} & Omit<UI.Popup, "add">;

/**
 * For use with SpaceOrg Files only
 */
export const useFileViewer = ({
  file,
  startingPage,
  versionNo,
  handleGoBack,
}: {
  handleGoBack: () => void;
  file: EolasFile | DraftFile;
  startingPage?: number;
  versionNo?: number;
}) => {
  const { key: fileKey, type, parentID, mediaName, name } = file;
  const key = isUrlString(fileKey || "") ? fileKey : decodeURIComponent(fileKey ?? "");

  const { t } = useTranslation();
  const addLinkToSelectedTextRef = useRef<(linkUrl: string) => void>();
  const latestBlob = useRef<null | File>(null);

  const webViewerRef = useRef<WebViewerInstance | null>(null);

  const { uploadBlob } = useUploadBlob();

  const { refetch } = useRefetchAppData();

  const { activeTab } = useEolasNavigation();
  const { isAdmin: isInAdminMode } = sectionStore;

  const adminStatus = useGetAdminStatus({ activeTab });

  const limitedAccess = useGetLimitedAccess({ activeTab });

  const shouldDisplayAdminOptions = useMemo(() => {
    if (!adminStatus || !isInAdminMode) {
      return false;
    }
    if (adminStatus === "admin") {
      return true;
    }

    const lookupMap = makeLimitedAdminSectionLookupMap(limitedAccess);
    return isAncestorSectionIdInLimitedAccessArray(parentID, lookupMap);
  }, [adminStatus, limitedAccess, parentID, isInAdminMode]);

  // Make editing available for pdfs only, otherwise users could overwrite other document types as pdf
  // Disabled add links option for pdfs of old versions
  const isReadOnly = !shouldDisplayAdminOptions || type !== "pdf" || Boolean(versionNo);

  const saveLatestMedia = async () => {
    if (!latestBlob.current) {
      return;
    }
    try {
      const mediaName = decodeURIComponent(file.name);

      const result = await uploadBlob({
        mainSectionId: file.mainSectionID,
        blob: latestBlob.current,
        mediaName,
      });

      if (isEolasDraftFile(file)) {
        await contentClient.updateDraftFile({
          draftFileDto: {
            key: result.type === "public" ? result.url : result.s3Key,
            mediaId: !result.isLegacy && result.type === "private" ? result.s3Key : undefined,
            mediaName,
          },
          contentId: file.fileId,
          mainSectionId: file.mainSectionID,
        });
      } else {
        const contentFile = await contentClient.updateContentItem({
          contentDto: {
            key: result.type === "public" ? result.url : result.s3Key,
            mediaId: !result.isLegacy && result.type === "private" ? result.s3Key : undefined,
            mediaName,
          },
          contentId: file.id,
          mainSectionId: file.mainSectionID,
        });
        intermediateUpdateForUi({ type: "file", file: contentFile, action: "update" });
      }
      refetch();
    } catch (error) {
      eolasLogger.error(error);
    }
    latestBlob.current = null;
  };

  const handleLinkSubmit = (link: string) => {
    if (addLinkToSelectedTextRef.current) {
      addLinkToSelectedTextRef.current(link);
    }
    modalStore.closeModal();
  };

  let fileName = mediaName && getFileExtensionFromString(mediaName) ? mediaName : "";
  if (!fileName) {
    if (key && key.startsWith("public")) {
      const extracted = extractFileNameFromLegacyS3Key(key);
      if (extracted) {
        fileName = extracted;
      }
    }
  }
  if (!fileName) {
    fileName = `${name}.${type}`;
  }

  const eolasDocumentViewerProps: EolasDocumentViewerProps = {
    reRenderBehaviour: "onceOnMount",
    fileName,
    startingPage,
    isReadOnly,
    getUrl: () => {
      if (!key) {
        return null;
      }
      if (isUrlString(key)) {
        return key;
      }

      let fileId: string;
      let draftId: string | undefined = undefined;

      if (isEolasDraftFile(file)) {
        fileId = file.fileId;
        draftId = file.id;
      } else {
        fileId = file.id;
      }
      return getPDFUrl({ fileId, key, versionNo, draftId });
    },
    onLoadFile: (WebViewerInstance) => {
      webViewerRef.current = WebViewerInstance;
      const {
        UI,
        Core: { documentViewer, annotationManager, Annotations, Actions },
      } = WebViewerInstance;

      const saveDocumentWithDebounce = debounce(async () => {
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: "application/pdf" });
        const fileBlob = new File([blob], mediaName || `${file.name}.pdf`, {
          lastModified: new Date().getTime(),
          type: "application/pdf",
        });
        latestBlob.current = fileBlob;
      }, 500);

      const newLink = (
        x: number,
        y: number,
        width: number,
        height: number,
        linkPageNumber: number,
      ): LinkAnnotation => {
        const link: Partial<LinkAnnotation> = new Annotations.Link({});
        link.PageNumber = linkPageNumber;
        link.StrokeColor = new Annotations.Color(0, 165, 228);
        link.StrokeStyle = "underline";
        link.StrokeThickness = 2;
        link.X = x;
        link.Y = y;
        link.Width = width;
        link.Height = height;

        // FIXME: Docs on AppRyse docs being super unclear and how important this feature is, we are maintaining the cast for now
        return link as LinkAnnotation;
      };

      const addLinkToSelectedText = (linkUrl: string) => {
        if (!linkUrl) return; // Check if a link is selected

        const selectedTextQuads = documentViewer.getSelectedTextQuads();
        const currentPageLinks: Core.Annotations.Annotation[] = [];
        const action = new Actions.URI({ uri: linkUrl });

        for (const pageNumber in selectedTextQuads) {
          selectedTextQuads[pageNumber].forEach(
            (quad: { x1: number; x3: number; y1: number; y3: number }) => {
              const link = newLink(
                Math.min(quad.x1, quad.x3),
                Math.min(quad.y1, quad.y3),
                Math.abs(quad.x1 - quad.x3),
                Math.abs(quad.y1 - quad.y3),
                parseInt(pageNumber),
              );

              link.addAction("U", action);

              currentPageLinks.push(link);
            },
          );
        }
        annotationManager.addAnnotations(currentPageLinks);
        let pageNumbersToDraw = currentPageLinks.map((link) => link.PageNumber);
        pageNumbersToDraw = [...new Set(pageNumbersToDraw)];
        pageNumbersToDraw.forEach((pageNumberToDraw: number) => {
          annotationManager.drawAnnotations({
            pageNumber: pageNumberToDraw,
            overrideCanvas: null,
            majorRedraw: true,
          });
        });
        saveDocumentWithDebounce();
      };

      addLinkToSelectedTextRef.current = addLinkToSelectedText;

      if (!isReadOnly) {
        const textPopUp: TextPopup = UI.textPopup;
        textPopUp.add([
          {
            type: "actionButton",
            label: t("fileViewer_add_link"),
            onClick: () =>
              modalStore.openModal({
                name: "selectLinkModal",
                variant: "component",
                Component: (
                  <InsertLinkModal onInsertLink={handleLinkSubmit} disabledIds={[file.id]} />
                ),
              }),
          },
        ]);
      }
    },
  };

  const onClickBack = () => {
    if (!latestBlob.current) {
      handleGoBack();
      return;
    } else {
      modalStore.openModal({
        icon: "warning",
        name: "confirmSaveChanges",
        variant: "dialogue",
        title: t("document_save_changes_title"),
        message: t("document_save_changes_desc"),
        onConfirmAsync: async () => {
          await saveLatestMedia();
          handleGoBack();
        },
        onDismiss: () => {
          latestBlob.current = null;
          handleGoBack();
        },
      });
    }
  };

  useRunOnMountUnmount({
    onUnmount: () => {
      saveLatestMedia();
    },
  });

  return {
    webViewerRef,
    onClickBack,
    eolasDocumentViewerProps,
  };
};
