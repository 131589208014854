import React from "react";
import { sectionStore } from "@eolas-medical/core";

import useEolasNavigation from "../context/useEolasNavigation";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";

export const useRoleSwitcher = () => {
  const { activeTab, goToOrganisationTab, goToSpaceTab } = useEolasNavigation();

  const accessLevel = useGetAdminStatus({ activeTab });

  const { onSwitchToAdmin } = useSpacesContext();

  const isOrganisationActive = activeTab === "organisation";

  const showSwitcher = isAccessLevelInAdminGroup(accessLevel);

  const switchToUser = React.useCallback(() => {
    sectionStore.setIsAdmin(false);
    onSwitchToAdmin(false);
    if (isOrganisationActive) {
      goToOrganisationTab();
      return;
    }
    goToSpaceTab();
  }, [onSwitchToAdmin, goToOrganisationTab, goToSpaceTab, isOrganisationActive]);

  const switchToAdmin = React.useCallback(() => {
    sectionStore.setIsAdmin(true);
    onSwitchToAdmin(true);
    if (isOrganisationActive) {
      goToOrganisationTab();
      return;
    }

    goToSpaceTab();
  }, [onSwitchToAdmin, goToOrganisationTab, goToSpaceTab, isOrganisationActive]);

  return {
    showSwitcher,
    isOnAdminPanel: sectionStore.isAdmin,
    switchToUser,
    switchToAdmin,
  };
};
