import React from "react";
import { twMerge } from "tailwind-merge";
import { Sizes } from "UIKit/types";

export enum PillSizes {
  EXTRA_SMALL = Sizes.EXTRA_SMALL,
  SMALL = Sizes.SMALL,
  MEDIUM = Sizes.MEDIUM,
}

export interface PillProps {
  value?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  valueClassname?: string;
  size?: Sizes;
  icon?: React.ReactNode;
}

export const Pill = ({
  value,
  disabled,
  className = "",
  valueClassname = undefined,
  onClick,
  size = Sizes.MEDIUM,
  icon,
}: PillProps) => {
  const pillClass = {
    [Sizes.SMALL]: "p-1.5",
    [Sizes.MEDIUM]: "p-2.5",
    [Sizes.EXTRA_SMALL]: "px-2 py-1",
  };

  const textClass = {
    [Sizes.SMALL]: "text-sm",
    [Sizes.MEDIUM]: "text-md",
    [Sizes.EXTRA_SMALL]: "text-xs",
  };

  const valueClass = // Done without twMerge as it always overrides text class
    valueClassname && /text/.test(valueClassname) ? valueClassname : textClass[size];

  return (
    <span
      data-testid="pill"
      className={twMerge(
        "flex items-center space-x-2 justify-center rounded-2xl text-center ",
        pillClass[size],
        className,
      )}
      onClick={() => !disabled && onClick && onClick()}
    >
      {icon && <div>{icon}</div>}
      <span className={twMerge(valueClass, valueClassname)}>{value}</span>
    </span>
  );
};
