import { useTranslation } from "react-i18next";
import { useNotifications } from "Components/Notifications";
import {
  AnalyticsEvents,
  FavouriteAddedPayload,
  MeLevelSection,
  OrganisationLevelSection,
  PossibleFavouriteOrigins,
  eolasLogger,
  favouriteEntityTypeToMainSectionTypeMapper,
  fromAwsJSON,
  getMainSectionOwnerType,
  hasStringProp,
  isMedicationMetadata,
  isNiceGuidelinesMetadata,
  isSmpcMetadata,
  myFavouritesStore,
  sectionStore,
} from "@eolas-medical/core";

import {
  CreateFavouriteBody,
  FavouriteEntityType,
} from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import {
  EntityItem,
  NonCopilotEntityItem,
  generateLinkToItem,
} from "shared/functions/generateLinkToItem";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { ActiveTab, LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import { trackEvent } from "API/Analytics";
import useMarkAsFavourite from "modules/myFavourites/data/useMarkAsFavourite";
import { KnowledgeResult, LocalResult } from "Components/MasterSearch/types";
import React from "react";
import {
  isKnowledgeSearchResult,
  isLocalSearchResult,
  isShareableMasterSearchFile,
} from "Components/MasterSearch/functions/typeguards";
import {
  isSupportedKnowledgeContentFavouriteType,
  isSupportedOrganisationContentFavouriteType,
  isSupportedSpaceContentFavouriteType,
} from "Pages/MeTab/pages/MyFavourites/functions/typeguards";
import { isSupportedFavouriteBNFMedicationType } from "modules/myFavourites/typeguards";
import {
  createEntityItemFromSearchResult,
  mainSectionOwnerTypeToActiveTab,
} from "Components/MasterSearch/hooks/useShareSearchResult/functions/helpers";
import { getFileName } from "../useShareFile";
import useUserDetails from "Hooks/useUserDetails";
import { findMedusaItemAndSection } from "Components/MasterSearch/helpers";

export interface HandleAddFavouriteProps {
  item: NonCopilotEntityItem | LocalResult | KnowledgeResult;
  entityType: FavouriteEntityType;
  mainSectionId?: string;
}

export const useAddFavourite = (entityId: string, searchResult?: LocalResult | KnowledgeResult) => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const { activeTab } = useEolasNavigation();
  const { showNotification } = useNotifications();
  const { isSharedAccount } = useUserDetails();

  const { markAsFavourite, isMarkingAsFavourite } = useMarkAsFavourite(entityId);

  const isMarkFavouriteEnabled = !!flags[LDFlagNames.MY_FAVOURITES] && !isSharedAccount;
  const favouriteId = myFavouritesStore.favouritesMap[entityId];
  const isFavourite = !!favouriteId;

  const canFavouriteSearchResult = React.useMemo(() => {
    if (!searchResult || !isMarkFavouriteEnabled) {
      return false;
    }

    const file = isLocalSearchResult(searchResult) ? searchResult.file : searchResult.knowledgeFile;

    if (isKnowledgeSearchResult(searchResult)) {
      const metadata = fromAwsJSON(searchResult.knowledgeFile.metadata);
      // We don't allow sharing of SMPC results as they are entire sections
      if (isSmpcMetadata(metadata)) {
        return false;
      }

      if (
        isMedicationMetadata(metadata) &&
        !isSupportedFavouriteBNFMedicationType(metadata.bnfSubsectionType)
      ) {
        return false;
      }
    }

    if (!file.mainSectionID) {
      return false;
    }

    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID);
    const mainSectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(
      file.mainSectionID,
    );

    const type = mainSectionIdentity === "genericContentRepository" ? "null" : mainSectionType;

    if (type === null) {
      return false;
    }

    if (isSupportedSpaceContentFavouriteType(type)) {
      return true;
    }

    if (isSupportedOrganisationContentFavouriteType(type)) {
      return true;
    }

    if (isSupportedKnowledgeContentFavouriteType(type)) {
      return true;
    }

    return false;
  }, [searchResult, isMarkFavouriteEnabled]);

  const handleAddFavourite = async ({
    item,
    entityType,
    mainSectionId: mainSectionIdParam,
  }: HandleAddFavouriteProps) => {
    let entityOwnerType: ActiveTab = activeTab;
    let mainSectionId: string | undefined = mainSectionIdParam;
    let entityItem: EntityItem | null = null;

    const { maybeMedusaItem } = findMedusaItemAndSection(item.id);
    const medusaMainSectionId = sectionStore.getChildReferenceByMainSectionType(
      OrganisationLevelSection.medusaMedications,
    )?.id;
    if (maybeMedusaItem && medusaMainSectionId) {
      const mainSection = sectionStore.getSection(medusaMainSectionId);
      const ownerType = getMainSectionOwnerType(mainSection);
      entityOwnerType = mainSectionOwnerTypeToActiveTab(ownerType);
      entityItem = maybeMedusaItem;
    }
    if (isLocalSearchResult(item) && !maybeMedusaItem) {
      if (!isShareableMasterSearchFile(item.file)) {
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("repository_item_favourite_add_error"),
        });
        eolasLogger.error(
          new Error("LocalSearchResult was missing required params when favouriting"),
          {
            item,
          },
        );
        return;
      }
      mainSectionId = item.file.mainSectionID;
      const mainSection = sectionStore.getSection(mainSectionId);
      const ownerType = getMainSectionOwnerType(mainSection);
      entityOwnerType = mainSectionOwnerTypeToActiveTab(ownerType);
      entityItem = item.file;
    }

    if (isKnowledgeSearchResult(item)) {
      const maybeEntityItem = createEntityItemFromSearchResult(item);

      if (!maybeEntityItem) {
        showNotification({
          type: "error",
          description: t("repository_item_favourite_add_error"),
          autoHideTimeout: 5000,
        });
        eolasLogger.error(new Error("Could not create entity item from knowledge result"), {
          item,
        });
        return;
      }

      entityItem = maybeEntityItem;
    }

    if (!isLocalSearchResult(item) && !isKnowledgeSearchResult(item)) {
      entityItem = item;
    }

    if (!entityItem) {
      showNotification({
        type: "error",
        description: t("repository_item_favourite_add_error"),
        autoHideTimeout: 5000,
      });
      eolasLogger.error(new Error("Entity item is not defined"), { item });
      return;
    }

    const result = generateLinkToItem(entityItem, entityOwnerType, mainSectionId);

    if (result && entityType) {
      const commonParams = {
        entityId: getEntityItemId(entityItem),
        deeplinkValue: result.link,
        entityOriginalTitle: getFileName(item),
      };
      const addFavouriteParams: CreateFavouriteBody =
        entityType === FavouriteEntityType.MEDUSA || maybeMedusaItem
          ? {
              ...commonParams,
              entityType: FavouriteEntityType.MEDUSA,
              ownerId: sectionStore.organisationID,
            }
          : { ...commonParams, entityType };

      const origin = getOriginForAnalytics(entityType, entityItem);
      trackEvent<FavouriteAddedPayload>(AnalyticsEvents.FAVOURITE_ADDED, {
        fileId: entityId.toString(),
        origin:
          isLocalSearchResult(item) || isKnowledgeSearchResult(item) ? "masterSearch" : origin,
        title: getFileName(item),
      });
      await markAsFavourite(addFavouriteParams);
    } else {
      showNotification({
        type: "error",
        description: t("repository_item_favourite_add_error"),
        autoHideTimeout: 5000,
      });
    }
  };

  return {
    isMarkingAsFavourite,
    handleAddFavourite,
    isMarkFavouriteEnabled,
    isFavourite,
    favouriteId,
    canFavouriteSearchResult,
  };
};

const getOriginForAnalytics = (
  entityType: FavouriteEntityType,
  item: EntityItem,
): Omit<PossibleFavouriteOrigins, MeLevelSection.myFavourites> => {
  if (entityType === FavouriteEntityType.FILE && hasStringProp(item, "mainSectionID")) {
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(item.mainSectionID);
    if (!mainSectionType) {
      return "unknownMainSectionType";
    }
    return mainSectionType;
  }
  const mappedType = favouriteEntityTypeToMainSectionTypeMapper(entityType);
  if (!mappedType) {
    return "unknownMainSectionType";
  }
  return mappedType;
};

const getEntityItemId = (item: EntityItem): string => {
  if (isNiceGuidelinesMetadata(item)) {
    return item.niceGuidanceId;
  }

  if (isMedicationMetadata(item)) {
    return item.bnfId;
  }

  return item.id;
};
