import { makeAutoObservable } from "mobx";

// This is typed like this to leave space for future modal variations i.e. basic dialog, alert, etc.
type ModalVariants = "component" | "dialogue";

/**
 * Default is "normal", but "stacked" exists to allow for stacking 1 modal on top of another
 * (less than ideal but sometimes the only possible UI)
 */
export type ModalLayoutType = "normal" | "stacked";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type BaseModalProps<V extends ModalVariants, P extends {}> = {
  name: string;
  variant: V;
  layoutType?: ModalLayoutType;
} & P;

export type ModalComponentProps = BaseModalProps<
  "component",
  {
    Component: React.ReactNode | (() => JSX.Element);
    isDismissible?: boolean;
    shouldRenderCloseButton?: boolean;
  }
>;
export type DialogModalIconName = "warning" | "info";

type BaseDialogOptions<T extends object> = {
  title: string;
  icon?: DialogModalIconName;
  message?: string;
  isDismissible?: boolean;
  onModalClosed?: () => void;
} & T;

type DialogOptionsWithDismissButton = BaseDialogOptions<{
  dialogType?: "withDismissButton";
  onConfirm?: () => void;
  onDismiss?: () => void;
  onConfirmAsync?: () => Promise<void | void[]>;
  confirmText?: string;
  dismissText?: string;
}>;

type DialogOptionsConfirmOnly = BaseDialogOptions<{
  dialogType: "confirmOnly";
  onConfirm?: () => void;
  onConfirmAsync?: () => Promise<void | void[]>;
  confirmText?: string;
}>;

export type ModalDialogueProps = BaseModalProps<
  "dialogue",
  DialogOptionsWithDismissButton | DialogOptionsConfirmOnly
>;

export type Modal = ModalComponentProps | ModalDialogueProps;

export type ModalState = {
  isOpen: boolean;
  isStackedModalOpen: boolean;
};

class ModalStore {
  constructor() {
    makeAutoObservable(this);
  }

  public modal: Modal | null = null;
  public stackedModal: Modal | null = null;
  public modalState: ModalState = { isOpen: false, isStackedModalOpen: false };

  openModal = (modal: Modal) => {
    if (modal.layoutType === "stacked") {
      this.stackedModal = modal;
      this.modalState.isStackedModalOpen = true;
      return;
    }
    this.modal = modal;
    this.modalState.isOpen = true;
  };

  closeModal = (type?: ModalLayoutType) => {
    if (type === "stacked") {
      this.stackedModal = null;
      this.modalState.isStackedModalOpen = false;
      return;
    }
    this.modal = null;
    this.modalState.isOpen = false;
  };
}

export const modalStore = new ModalStore();
