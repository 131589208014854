import { useCallback, useEffect } from "react";
import { VirtuosoHandle } from "react-virtuoso";
import { useLocation } from "react-router-dom";

interface UseScrollToItemParams<T> {
  items: T[];
  queryParam: "fileId" | "sectionId";
  virtuosoRef: React.RefObject<VirtuosoHandle>;
}

export function useScrollToItem<T extends { id: string }>({
  items,
  queryParam,
  virtuosoRef,
}: UseScrollToItemParams<T>) {
  const location = useLocation();

  const getFileIdFromQuery = useCallback((): string | null => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(queryParam);
  }, [location.search, queryParam]);

  useEffect(() => {
    const fileId = getFileIdFromQuery();
    if (fileId) {
      const index = items.findIndex((item) => item.id === fileId);
      if (index !== -1) {
        setTimeout(() => {
          virtuosoRef.current?.scrollToIndex({ index, align: "start", behavior: "smooth" });
        }, 500);
      }
    }
  }, [getFileIdFromQuery, items, virtuosoRef]);
}
