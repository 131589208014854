import { EolasShadowCopyContent } from "@eolas-medical/core";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { getOriginalFromShadowCopy } from "./getOriginalFromShadowCopy";
import { getPathToItemOrSection, ErrorCodes } from "./getPathToItemOrSection";

type Original404ErrorCode = "ORIGINAL_CONTENT_ITEM_NOT_FOUND";

type Params = {
  shadowItem: EolasShadowCopyContent;
  isInAdminMode: boolean;
  encodedSelectedSpaceName: string | null;
};

export const getPathToShadowCopyOriginal = async ({
  shadowItem,
  isInAdminMode,
  encodedSelectedSpaceName,
}: Params): Promise<string | { error: ErrorCodes | Original404ErrorCode }> => {
  const originalContentItem = await getOriginalFromShadowCopy(shadowItem);
  const item = originalContentItem ? mapToContentItem(originalContentItem) : null;

  if (!item) {
    return { error: "ORIGINAL_CONTENT_ITEM_NOT_FOUND" };
  }

  const result = await getPathToItemOrSection({
    id: item.id,
    isInAdminMode,
    encodedSelectedSpaceName,
  });

  return result;
};
