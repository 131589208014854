import { EnrichedFavourite, Metadata, hasProp, hasStringProp } from "@eolas-medical/core";
import { CommunityMedicationsBNFDeepLinkParams } from "deeplinking/types/contentDeeplinks/knowledgeContentDeeplinks.types";
import { EntityItem } from "shared/functions/generateLinkToItem";

// My Favourites 1.0 only supports BNF and BNFC Monographs
export const isSupportedFavouriteBNFMedicationType = (
  subsection: string,
): subsection is CommunityMedicationsBNFDeepLinkParams["subsectionType"] =>
  ["bnf", "bnfc"].includes(subsection);

export const isSmpcContentItem = (metadata: Metadata | null) => {
  if (!metadata) {
    return false;
  }
  if (hasProp(metadata, "keywords")) {
    return true;
  }
  return false;
};

export const isEnrichedFavourite = (
  item: EntityItem | EnrichedFavourite,
): item is EnrichedFavourite => {
  return hasStringProp(item, "deeplinkValue");
};
