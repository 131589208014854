import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { InnerModalWrapper, Title, Text } from "UIKit";
import { useCreateSpaceZap } from "./useCreateSpaceZap";
import { NotifiedModal } from "UIKit/Modal/modals/NotifiedModal";
import { ErrorModal } from "UIKit/Modal/modals/ErrorModal";
import { modalStore } from "Stores/ModalStore";
import { CreateSpaceZapierForm } from "./components/CreateSpaceZapierForm";

type Props = {
  entity: "space" | "organisation";
};

export const CreateSpaceZapierModal = observer(({ entity }: Props) => {
  const { t } = useTranslation();

  const { createSpaceZap, isError, isLoading, isSuccess } = useCreateSpaceZap();

  if (isSuccess) {
    return <NotifiedModal text={t("selectSpace_create_entity_success")} />;
  }

  if (isError) {
    return (
      <ErrorModal
        onClose={() => modalStore.closeModal()}
        text={t("selectSpace_create_entity_error")}
      />
    );
  }

  return (
    <InnerModalWrapper>
      <div className="flex flex-col space-y-4 p-10 bg-grey-50 max-h-80vh overflow-y-auto text-center justify-center items-center">
        <Title level={5}>{t("selectSpace_create_button", { entity })}</Title>
        <Text level={1} className="text-grey-600">
          {t("selectSpace_create_entity_description")}
        </Text>
        <CreateSpaceZapierForm
          entity={entity}
          onValidSubmit={createSpaceZap}
          isLoading={isLoading}
        />
      </div>
    </InnerModalWrapper>
  );
});
