import { AppLevelSection, clinicalQuestionStore, sectionStore } from "@eolas-medical/core";
import { useQueryClient } from "@tanstack/react-query";
import { AskEolasHome } from "Components/AskEolas/AskEolasHome";
import { useRunOnMountUnmount } from "Hooks";
import { observer } from "mobx-react-lite";
import { makeGetClinicalQuestionResponseQueryKey } from "modules/clinicalQuestions/data/useGetClinicalQuestionResponse";
import { useState } from "react";
import { useAskEolasSpacesInput } from "../hooks/useAskEolasSpacesInput";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const AskEolasSpaces = observer(() => {
  const [showNewQuestion, setShowNewQuestion] = useState(false);

  const { control, onSubmit, errors, progress, reset, isSubmitSuccessful, setValue } =
    useAskEolasSpacesInput();
  const queryClient = useQueryClient();

  useRunOnMountUnmount({
    onMount: async () => {
      for (const requestID of Object.keys(clinicalQuestionStore.responsesMap)) {
        await queryClient.cancelQueries(makeGetClinicalQuestionResponseQueryKey(requestID));
      }
      clinicalQuestionStore.resetAll();
    },
    onUnmount: () => {
      clinicalQuestionStore.resetAll();
    },
  });

  const handleResetQuestion = () => {
    setShowNewQuestion(true);
    reset();
    clinicalQuestionStore.resetAll();
  };
  const handleSubmitQuestion = async () => {
    setShowNewQuestion(false);
    onSubmit();
  };
  const mainSectionID = sectionStore.getChildReferenceByMainSectionType(
    AppLevelSection.askEolasSpaces,
  )?.id;

  return (
    <div className="px-8 pt-8">
      <FileBreadcrumbs mainSectionId={mainSectionID} shouldShowSpaceName />
      <AskEolasHome
        type="space"
        errors={errors}
        isSubmitSuccessful={isSubmitSuccessful}
        handleSubmitQuestion={handleSubmitQuestion}
        handleResetQuestion={handleResetQuestion}
        control={control}
        setValue={setValue}
        progress={progress}
        showNewQuestion={showNewQuestion}
        onSubmit={onSubmit}
        reset={reset}
      />
    </div>
  );
});
