import { QueryClient, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  eolasLogger,
  getCompletedChecklistsAndIncidentReportFiles,
  hasProp,
  Maybe,
  sectionStore,
  syncCommunityData,
  syncMyFavourites,
  syncSpaceOrgData,
  syncUserData,
  updateOwnDepartments,
  UserData,
  userStore,
} from "@eolas-medical/core";
import { getSupportedRegion } from "./useSupportedRegion";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { addRetries, stringToHash } from "Utilities/helpers";
import { webStore } from "Stores/WebStore";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import {
  addToFileDbCallback,
  clearFiles,
  removeFromFileDbCallback,
  updateMedusaSections,
  upsertChecklistsToSearchDb,
} from "Pages/Spaces/pages/Space/functions/fetchCallbacks";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const syncWebAppData = async ({
  isFromSoftLogoutState,
  getContentInParallel,
  queryClient,
}: {
  isFromSoftLogoutState: boolean;
  getContentInParallel?: boolean;
  queryClient: QueryClient;
}): Promise<SyncWebAppDataReturn> => {
  const [allUserData, spaceOrgData] = await Promise.all([
    syncAllUserData({ isFromSoftLogoutMode: isFromSoftLogoutState }),
    syncAllSpaceOrgData({ isFromSoftLogoutMode: isFromSoftLogoutState, getContentInParallel }),
  ]);
  queryClient.setQueryData(
    makeGetAllUserDataKey({ isLoggedIn: true, userData: userStore.userData }),
    allUserData,
  );
  const { isLoggedIn, isInOrganisation } = userStore.userSession;
  const spaceId =
    (isLoggedIn || isFromSoftLogoutState) && isInOrganisation ? sectionStore.appID : null;
  queryClient.setQueryData(makeSpaceOrgDataKey({ spaceId }), spaceOrgData);

  return { allUserData, spaceOrgData };
};

// Fetch data needed app-wide for a user signed in. This EXCLUDES any space / org data
export const syncAllUserData = async ({
  isFromSoftLogoutMode,
}: { isFromSoftLogoutMode?: boolean } = {}) => {
  const isLoggedIn = isFromSoftLogoutMode || userStore.userSession.isLoggedIn;
  if (!isLoggedIn || !userStore.userData || !userStore.userID) {
    return null;
  }
  const region = getSupportedRegion(userStore.userData);
  const communityId = sectionStore.data.community?.id;
  const [{ user }, { community }, , myFavouritesData] = await Promise.all([
    syncUserData(),
    syncCommunityData({ region }),
    updateOwnDepartments({ userID: userStore.userID }),
    communityId ? addRetries(() => syncMyFavourites({ region }))().catch(eolasLogger.error) : null,
  ]);

  /**
   * As this function synchronises the data directly with stores, the return value can be a hash to
   * see if the data has changed. This saves react query cache memory and duplicating data in persisted
   * storage:
   */
  const userDataHash = stringToHash(JSON.stringify({ user, community, myFavouritesData }));
  return { userDataHash };
};

// Fetch data for data relevant to being in Space / Org
export const syncAllSpaceOrgData = async ({
  isFromSoftLogoutMode,
  getContentInParallel,
}: { isFromSoftLogoutMode?: boolean; getContentInParallel?: boolean } = {}) => {
  const isLoggedIn = isFromSoftLogoutMode || userStore.userSession.isLoggedIn;
  let lastFileFetchTimestamp = contentDbStore.lastUpdatedTimestamp;
  if (
    !isLoggedIn ||
    !userStore.userSession.isInOrganisation ||
    !lastFileFetchTimestamp ||
    contentDbStore.isAddingChecklistFiles
  ) {
    return null;
  }

  const { sections, hasUpdatedFiles, medusaSectionsWithItems, appUser } = await syncSpaceOrgData({
    fetchAndReplaceAll: false,
    lastFileFetchTimestamp,
    clearFileDbCallBack: clearFiles,
    addToFileDbCallback,
    removeFileFromDbCallback: removeFromFileDbCallback,
    getContentInParallel,
  });

  webStore.setAppLastFetchTimestamp(new Date().toISOString());

  if (medusaSectionsWithItems.length) {
    await updateMedusaSections(medusaSectionsWithItems);
  }

  const { hasUpdated: hasUpdatedChecklists } = await upsertChecklistsToSearchDb(sections);

  if (hasUpdatedFiles || medusaSectionsWithItems.length || hasUpdatedChecklists) {
    if (hasUpdatedFiles) {
      contentDbStore.setLastUpdatedTimestamp(new Date().toISOString());
    }
    localSearchStore.persistDb();
  }

  if (appUser.accessLevel === "admin") {
    getCompletedChecklistsAndIncidentReportFiles({
      updatedAt: lastFileFetchTimestamp || undefined,
      onAddFile: addToFileDbCallback,
      onRemoveFile: removeFromFileDbCallback,
      getContentInParallel,
    })
      .catch(eolasLogger.error)
      .then((result) => {
        if (hasProp(result, "didReturnNewData") && result.didReturnNewData) {
          contentDbStore.setLastUpdatedTimestamp(new Date().toISOString());
        }
      });
  }

  lastFileFetchTimestamp = contentDbStore.lastUpdatedTimestamp;

  // This can again be a hash, as we are updating directly:
  const medusaDataHash = medusaSectionsWithItems.length
    ? stringToHash(JSON.stringify(medusaSectionsWithItems))
    : null;

  return {
    lastFileFetchTimestamp,
    sectionStoreTimestamp: sectionStore.timestamps.sections ?? null,
    medusaDataHash,
  };
};

export type SyncWebAppDataReturn = {
  allUserData: Awaited<ReturnType<typeof syncAllUserData>>;
  spaceOrgData: Awaited<ReturnType<typeof syncAllSpaceOrgData>>;
};

const makeGetAllUserDataKey = ({
  userData,
  isLoggedIn,
}: {
  userData: Maybe<UserData>;
  isLoggedIn: boolean;
}) => {
  return [
    "getAllUserData",
    userData && isLoggedIn ? getSupportedRegion(userData) : null,
    userData?.id && isLoggedIn ? userData.id : null,
  ];
};

const makeSpaceOrgDataKey = ({ spaceId }: { spaceId: string | null }) => {
  return ["getSpaceOrgData", spaceId];
};

export const useGetAllUserData = () => {
  const { isLoggedIn } = userStore.userSession;
  const userData = userStore.userData;

  const { isFetching, isStale, data, refetch } = useQuery({
    queryKey: makeGetAllUserDataKey({ isLoggedIn, userData }),
    queryFn: () => syncAllUserData(),
    enabled: isLoggedIn,
    staleTime: 15000,
    cacheTime: 500000,
  });

  return {
    isAllUserDataStale: isStale,
    allUserData: data ?? null,
    isAllUserDataFetching: isFetching,
    refetchAllUserData: refetch,
  };
};

export const useGetSpaceOrgData = () => {
  const { isLoggedIn, isInOrganisation } = userStore.userSession;
  const spaceId = isLoggedIn && isInOrganisation ? sectionStore.appID : null;
  const { isAddingChecklistFiles } = contentDbStore;
  const { flags } = useLaunchDarkly();
  const getContentInParallel = flags[LDFlagNames.MAIN_SECTION_CONTENT_IN_PARALLEL];

  const { isFetching, data, refetch, isStale } = useQuery({
    queryKey: makeSpaceOrgDataKey({ spaceId }),
    queryFn: () => syncAllSpaceOrgData({ getContentInParallel }),
    enabled: Boolean(spaceId) && !isAddingChecklistFiles,
    staleTime: 15000,
    cacheTime: 500000,
  });

  return {
    isSpaceOrgDataStale: isStale,
    spaceOrgData: data ?? null,
    isSpaceOrgDataFetching: isFetching,
    refetchSpaceOrgData: refetch,
  };
};

/**
 * Hook for use in App.tsx
 */
export const useAppSync = () => {
  const { refetchAllUserData } = useGetAllUserData();
  const { refetchSpaceOrgData } = useGetSpaceOrgData();

  const refetch = useCallback(
    async (type: "all" | "user" | "spaceOrg" = "spaceOrg") => {
      await Promise.all([
        type === "all" || type === "user" ? refetchAllUserData() : null,
        type === "all" || type === "spaceOrg" ? refetchSpaceOrgData() : null,
      ]);
    },
    [refetchAllUserData, refetchSpaceOrgData],
  );

  return { refetch };
};

/**
 * This is just a wrapper hook for now
 * to prevent modifying lots of other files. It should only be used for refetching space / org data
 */
export const useRefetchAppData = () => {
  const { refetch: genericRefetch } = useAppSync();

  const refetch = useCallback(async () => {
    await genericRefetch();
  }, [genericRefetch]);

  return { refetch };
};
