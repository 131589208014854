import { useCallback, useEffect, useMemo, useState } from "react";
import {
  sectionStore,
  AppLevelSection,
  EolasFile,
  hasStringProp,
  contentClient,
  eolasLogger,
  userStore,
} from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { LDFlagNames, PublishFileDTO } from "Utilities/types";
import { getBlobType, parseFileKey } from "Utilities/fileHelpers";
import { generateS3FileKey } from "Utilities/general";
import { useFileOperations, useRefetchAppData, useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { AxiosProgressEvent } from "axios";
import { getPreSignUrl } from "Pages/Spaces/functions/helpers";
import { useLaunchDarkly } from "Contexts";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

export const useDepartmentFilePicker = () => {
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { publishFile, updateFile, removeFile } = useFileOperations();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.theDepartment) ?? {
    id: "",
  };
  const { files } = useGetFiles({ sectionId: id });

  const [progress, setProgress] = useState(0);
  const [state, setState] = useState({
    url: "",
    type: "",
  });
  const [showTour, setShowTour] = useState(false);
  const [file, setFile] = useState<File | null>();

  const { error, isLoading, setRequestStatus, requestStatus, isSuccessful } = useRequestStatus();

  const { refetch } = useRefetchAppData();

  const {
    error: removeError,
    isLoading: isRemoveLoading,
    setRequestStatus: setRemoveStatus,
  } = useRequestStatus();

  const { id: parentID } = sectionStore.getChildReferenceByMainSectionType(
    AppLevelSection.theDepartment,
  )!;

  const tourFile = files.find((file) => !!file.key) as EolasFile;

  const tourFileKey = tourFile?.key ?? "";

  const fetchUrl = useCallback(() => {
    if (!tourFile || !tourFileKey) return;

    getPreSignUrl({ fileId: tourFile.id }).then((url) => {
      const { fileType } = parseFileKey(tourFileKey);
      setState({
        url: url.toString(),
        type: tourFile.mediaId && tourFile.type ? tourFile.type : fileType,
      });
    });
  }, [tourFileKey, tourFile]);

  useEffect(() => {
    fetchUrl();
  }, [fetchUrl]);

  useEffect(() => {
    if (requestStatus.status === "initial") {
      setFile(null);
      setProgress(0);
    }
  }, [requestStatus]);

  const onAddFile = async () => {
    if (!file) return;

    try {
      setRequestStatus({ status: "pending", error: "" });
      setProgress(0);
      if (useAppServicesEndpoints) {
        const key = generateS3FileKey({
          fileName: file.name,
          fileFormat: file.type,
          mainSectionId: AppLevelSection.theDepartment,
        });

        const input: PublishFileDTO = {
          key,
          type: file.type,
          name: "Department tour",
        };
        await uploadFile(key, file, (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.progress) {
            setProgress(Math.round(progressEvent.progress * 100));
          }
        });
        await publishFile({
          variables: {
            input,
            parentID,
          },
        });
      } else {
        const { mediaId, publicUrl } = await uploadFileV2({
          file,
          onUploadProgress: setProgress,
          mainSectionId: parentID,
        });

        await contentClient.addContentItem(parentID, {
          parentId: parentID,
          mediaId,
          mediaName: file.name,
          key: publicUrl || mediaId,
          type: getBlobType(file.type),
          createdBy: userStore.userID,
          name: "Department tour",
        });
      }

      refetch();
      setRequestStatus({ status: "success", error: "" });
    } catch (err) {
      eolasLogger.error(err);
      setRequestStatus({
        status: "error",
        error: hasStringProp(err, "message")
          ? err.message
          : "Unknown error when adding department tour file",
      });
    }
  };

  const onEditFile = async () => {
    if (!file) return;

    try {
      setRequestStatus({ status: "pending", error: "" });
      setProgress(0);
      if (useAppServicesEndpoints) {
        const key = generateS3FileKey({
          fileName: file.name,
          fileFormat: file.type,
          mainSectionId: AppLevelSection.theDepartment,
        });

        const input: PublishFileDTO = {
          key,
          type: file.type,
          id: tourFile.id,
          name: "Department tour",
        };
        await uploadFile(key, file, (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.progress) {
            setProgress(Math.round(progressEvent.progress * 100));
          }
        });
        await updateFile({ variables: { input, parentID } });
      } else {
        const { mediaId, publicUrl } = await uploadFileV2({
          file,
          onUploadProgress: setProgress,
          mainSectionId: parentID,
        });

        await contentClient.updateContentItem({
          contentId: tourFile.id,
          mainSectionId: parentID,
          contentDto: {
            mediaId,
            key: publicUrl || mediaId,
            mediaName: file.name,
            type: file.type === "application/pdf" ? "pdf" : "mp4",
          },
        });
      }
      refetch();
      setRequestStatus({ status: "success", error: "" });
    } catch (err) {
      eolasLogger.error(err);
      setRequestStatus({
        status: "error",
        error: hasStringProp(err, "message") ? err.message : "unknown error",
      });
    }
  };

  const onRemoveFile = async () => {
    setRemoveStatus({ status: "pending", error: "" });
    try {
      useAppServicesEndpoints
        ? await removeFile({ variables: { id: tourFile.id, parentID: tourFile.parentID } })
        : await contentClient.deleteContentItem({
            mainSectionId: tourFile.mainSectionID,
            itemId: tourFile.id,
          });

      setState({ url: "", type: "" });
      refetch();
      setRemoveStatus({ status: "success", error: "" });
    } catch (err) {
      errorStore.captureError({
        error: err instanceof Error ? err : "unknown error",
        source: "user",
        retryCallback: removeFile,
        retryParameters: { variables: { id: tourFile.id, parentID: tourFile.parentID } },
      });
      setRemoveStatus({
        status: "error",
        error: hasStringProp(err, "message") ? err.message : "unknown error",
      });
    }
  };

  const filePickerValue = useMemo(() => {
    if (file) {
      return file.name;
    } else {
      if (tourFile?.mediaName) return tourFile.mediaName;
      if (tourFileKey) {
        return parseFileKey(tourFileKey).fileName;
      }
      return "";
    }
  }, [file, tourFileKey, tourFile]);

  return {
    tourFile,
    error,
    url: state.url,
    fileType: state.type,
    progress,
    isSuccessful,
    showTour,
    isLoading,
    removeError,
    isRemoveLoading,
    filePickerValue,
    onChange: setFile,
    onAddFile,
    onEditFile,
    setShowTour,
    onRemoveFile,
    setRequestStatus,
    setRemoveStatus,
  };
};
