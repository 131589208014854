import { get } from "lodash";
import { useTranslation } from "react-i18next";
import React, { useState, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { sectionStore } from "@eolas-medical/core";

import { useUpdateApp } from "Hooks/useGraphQLRequests";
import { CheckIcon, BuildingIcon, AddIcon, SearchIcon } from "Assets";
import { Text, Button, Input, DeleteButton, FormLabel, FormFieldError } from "UIKit";

interface SelectOrganisationProps {
  onAddOrganization(): void;
}

export const SelectOrganisation: React.FC<SelectOrganisationProps> = ({
  onAddOrganization,
}: SelectOrganisationProps) => {
  const { t } = useTranslation();
  const { updateApp } = useUpdateApp();
  const { setValue, watch, formState } = useFormContext();
  const guidelineOrganisations = sectionStore.guidelinesOrganisations;

  const [info, moreInfo] = watch(["info", "moreInfo"]);
  const error = get(formState, "errors.info.message", "");

  const [searchInput, setSearchInput] = useState("");

  const setOrganization = (name: string, logo: string) => {
    setValue("info", name, { shouldValidate: true });
    setValue("moreInfo", logo, { shouldValidate: true });
  };

  const onSearchInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(value.toLowerCase());
  };

  const filteredOrganisations = useMemo(() => {
    if (!searchInput) return guidelineOrganisations;

    return guidelineOrganisations.filter((org) => org!.name.toLowerCase().includes(searchInput));
  }, [searchInput, guidelineOrganisations]);

  const onDeleteOrganisation = (orgIndex: number) => async () => {
    sectionStore.removeGuidelineOrganisation(orgIndex);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const input: any = {
      id: sectionStore.data.app?.id,
      guidelineOrganisations: sectionStore.guidelinesOrganisations,
    };

    await updateApp(input);
  };

  return (
    <div className="flex flex-col">
      <FormLabel required htmlFor="org-filter">
        {t("guidelines_addNew_selectOrganisation")}
      </FormLabel>

      <Input
        size="lg"
        value={searchInput}
        className="input mt-2"
        iconLeft={<SearchIcon />}
        onChange={onSearchInputChange}
        placeholder={t("guidelines_search_organisations")}
      />

      <div
        style={{ minHeight: "3.5rem", maxHeight: "20rem" }}
        className={`
          flex flex-col mt-4 overflow-y-auto
          divide-y divide-grey-300 rounded-md
          ${error ? "border-2 border-red-focus" : "border border-grey-300"}
        `}
      >
        {filteredOrganisations.length === 0 ? (
          <Text level={1} className="text-grey-400 text-center mt-4">
            {t("guidelines_no_organisations_found")}
          </Text>
        ) : (
          filteredOrganisations.map((org, index) => {
            if (!org) return null;

            const isSelected = info === org.name && moreInfo === org.logo;

            return (
              <div
                key={`${org.name}-${index}`}
                onClick={() => setOrganization(org.name, org.logo)}
                className={`
                  flex items-center cursor-pointer space-x-4 p-4 h-14 hover:bg-grey-300
                  ${isSelected ? "bg-blue-50" : ""}
                `}
              >
                <BuildingIcon width={24} height={24} />
                <Text level={1} className="w-full">
                  {org.name}
                </Text>

                {isSelected && <CheckIcon className="text-green" width={24} height={24} />}

                <DeleteButton onClick={onDeleteOrganisation(index)} />
              </div>
            );
          })
        )}
      </div>

      <FormFieldError className="mt-2">{error}</FormFieldError>

      <Button
        color="grey"
        onClick={onAddOrganization}
        className="self-start mt-4 w-full sm:w-min whitespace-nowrap"
      >
        <AddIcon className="mr-2" width={16} height={16} />
        {t("guidelines_addNew_add_new_organisation")}
      </Button>
    </div>
  );
};
