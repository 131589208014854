import { InnerPageWrapper, Loader, NavButton, PageTitle } from "UIKit";

import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { ContentItem, getObjectEntries } from "@eolas-medical/core";
import useFetchContentItem from "modules/contentRepository/data/useContentItem";

import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import { EolasEditorRenderer } from "./components/EolasEditorRenderer";
import { useContentVersion } from "shared/hooks/useContentVersion/useContentVersion";
import { FileViewerUnauthorizedError } from "../FileViewerUnauthorizedError/FileViewerUnauthorizedError";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { ContentItemParams } from "shared/pages/ContentRepository/context/types";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useGetDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useGetDraftFile";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { useConstructFileBreadcrumbProps } from "Pages/Spaces/hooks/useConstructFileBreadcrumbProps";

type EolasEditorGenericViewerProps = {
  contentItem: ContentItem | undefined;
  contentItemLoading: boolean;
  backBehaviour?: EolasEditorViewerProps["backBehaviour"];
  shadowCopyContentId: string | null;
  fileIdOfDraftFile: string | null;
  shouldShowBreadcrumbs?: boolean;
};

export const EolasEditorGenericViewer = ({
  contentItemLoading,
  contentItem,
  backBehaviour = "goBack",
  shadowCopyContentId,
  shouldShowBreadcrumbs,
  fileIdOfDraftFile,
}: EolasEditorGenericViewerProps) => {
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });
  const { mainSectionId, parentId, fileName } = useConstructFileBreadcrumbProps({
    id: shadowCopyContentId ? shadowCopyContentId : fileIdOfDraftFile ?? contentItem?.id ?? "",
  });
  return (
    <InnerPageWrapper className="pb-12 xl:pb-15">
      {!contentItemLoading && shouldShowBreadcrumbs ? (
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={mainSectionId}
          parentId={parentId}
          fileName={fileName}
        />
      ) : null}
      <div className="flex flex-col space-x-3 gap-2 items-center">
        <PageTitle title={contentItem?.name ?? ""} titleClass="text-2xl lg:text-4xl" />
        <NavButton onClick={handleGoBack} className="mb-3 mt-5 lg:mt-0" />
      </div>
      <EolasEditorRenderer contentItem={contentItem} contentItemLoading={contentItemLoading} />
    </InnerPageWrapper>
  );
};

type EolasEditorViewerProps = {
  mainSectionId?: string;
  contentItemId?: string;
  backBehaviour?: "goBack" | "goHome";
  shadowCopyContentId?: string;
};

const EolasEditorViewer = ({
  mainSectionId,
  contentItemId,
  backBehaviour = "goBack",
}: EolasEditorViewerProps) => {
  const {
    mainSectionId: mainSectionIdFromRoute,
    contentItemId: contentItemIdFromRoute,
    versionNo,
  } = useParamsDecoded<{
    mainSectionId: string;
    contentItemId: string;
    versionNo?: string;
  }>();

  const {
    fileIdOfDraftFile,
    shadowCopyContentId,
    shouldShowBreadcrumbs: showBreadcrumbsStr,
  } = useGetSearchParams({
    paramList: getObjectEntries(ContentItemParams).map(([, v]) => v),
  });

  const shouldShowBreadcrumbs = showBreadcrumbsStr === "true";

  return versionNo ? (
    <EolasEditorVersionViewer
      backBehaviour={backBehaviour}
      contentItemId={contentItemId ? contentItemId : contentItemIdFromRoute}
      versionNo={+versionNo}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
    />
  ) : (
    <EolasEditorFileViewer
      backBehaviour={backBehaviour}
      contentItemId={contentItemId ? contentItemId : contentItemIdFromRoute}
      mainSectionId={mainSectionId ? mainSectionId : mainSectionIdFromRoute}
      shadowCopyContentId={shadowCopyContentId}
      fileIdOfDraftFile={fileIdOfDraftFile}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
    />
  );
};

const EolasEditorFileViewer = ({
  mainSectionId,
  contentItemId,
  backBehaviour,
  shadowCopyContentId,
  fileIdOfDraftFile,
  shouldShowBreadcrumbs,
}: {
  mainSectionId: string;
  contentItemId: string;
  backBehaviour: "goBack" | "goHome";
  shadowCopyContentId: string | null;
  fileIdOfDraftFile: string | null;
  shouldShowBreadcrumbs?: boolean;
}) => {
  const { contentItem, isContentItemLoading } = useFetchContentItem({
    mainSectionId,
    contentId: fileIdOfDraftFile || contentItemId,
  });

  const { data, isFetching } = useGetDraftFile(fileIdOfDraftFile);

  const draftFile = data?.draftFile ? mapToContentItem(data.draftFile) : null;

  return (
    <EolasEditorGenericViewer
      contentItem={draftFile || contentItem}
      contentItemLoading={isContentItemLoading || isFetching}
      backBehaviour={backBehaviour}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      fileIdOfDraftFile={fileIdOfDraftFile}
    />
  );
};
const EolasEditorVersionViewer = ({
  contentItemId,
  backBehaviour,
  versionNo,
  shadowCopyContentId,
  shouldShowBreadcrumbs,
}: {
  contentItemId: string;
  backBehaviour: "goBack" | "goHome";
  versionNo: number;
  shadowCopyContentId: string | null;
  shouldShowBreadcrumbs?: boolean;
}) => {
  const { versionContentItem, isLoadingContentVersions, isUserAllowed } = useContentVersion({
    contentId: contentItemId,
    versionNo,
  });

  if (isLoadingContentVersions) return <Loader className="h-100vh" />;

  if (!isUserAllowed) return <FileViewerUnauthorizedError />;

  return (
    <EolasEditorGenericViewer
      contentItem={versionContentItem}
      contentItemLoading={isLoadingContentVersions}
      backBehaviour={backBehaviour}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      fileIdOfDraftFile={null}
    />
  );
};

export default EolasEditorViewer;
