import {
  ChecklistRecord,
  EolasFile,
  MasterSearchFile,
  OrganisationLevelSection,
  UserLocation,
  eolasLogger,
  hasStringProp,
  isEolasFile,
} from "@eolas-medical/core";
import Fuse from "fuse.js";

const { compare } = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });

export interface INameable {
  name?: string | null;
  title?: string | null;
}

export const sortByName = <T extends INameable>(a: T, b: T): number => {
  const nameA = a.name || a.title || "";
  const nameB = b.name || b.title || "";

  if (!nameA || !nameB) {
    return 0;
  }

  const nameAUpper = nameA.trim().toUpperCase();
  const nameBUpper = nameB.trim().toUpperCase();

  return compare(nameAUpper, nameBUpper);
};

export interface ICreateable {
  createdAt?: string | null;
  updatedAt?: string | null;
}

export const sortByCreatedAt = <T extends ICreateable>(a: T, b: T): number => {
  if (!a.createdAt || !b.createdAt) {
    return 0;
  }
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};

export interface IUpdateable {
  updatedAt?: string | null;
}

export const sortByUpdated = <T extends IUpdateable>(a: T, b: T): number => {
  if (!a.updatedAt || !b.updatedAt) {
    return 0;
  }
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
};

export interface IFavouritable {
  favouritedOn: string | null;
}

export const sortByFavouritedOn = <T extends IFavouritable>(a: T, b: T): number => {
  if (!a.favouritedOn || !b.favouritedOn) {
    return 0;
  }
  return new Date(b.favouritedOn).getTime() - new Date(a.favouritedOn).getTime();
};

export interface IFavorable {
  isFavourite: boolean;
}

export const sortByFavourites = <T extends IFavorable>(_a: T, b: T): number => {
  return b.isFavourite ? 1 : -1;
};

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const isAndroid = (): boolean => {
  return /Android/i.test(navigator.userAgent);
};

export const isIOS = (): boolean => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const getMobilePlatform = (): "android" | "ios" | "unknown" => {
  if (isAndroid()) {
    return "android";
  }

  if (isIOS()) {
    return "ios";
  }

  return "unknown";
};

export const isDev = (): boolean => process.env.NODE_ENV === "development";

// TODO: remove this function once the build token is no longer needed (speak to Charles)
export const isBuildTokenPresent = () => Boolean(process.env.REACT_APP_BUILD_TOKEN);

export const wait = (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const addRetries = <T>(callback: () => Promise<T>, retries = 3, delayInMs = 500) => {
  return () =>
    new Promise<T>((resolve, reject) => {
      (async () => {
        let retriesRemaining = retries;
        while (retriesRemaining > 0) {
          try {
            const result = await callback();
            retriesRemaining = 0;
            resolve(result);
          } catch (error) {
            retriesRemaining--;
            if (!retriesRemaining) {
              reject(error);
              return;
            }
            await wait(delayInMs + 100 * Math.random());
          }
        }
      })();
    });
};

export type Timeout = ReturnType<typeof setTimeout>;

export async function resolvePromiseWithTimeout<T>(asyncFunc: () => Promise<T>): Promise<unknown> {
  const timeoutRef: Timeout | null = null;

  const mainPromise = async () => {
    try {
      const returnValue = await asyncFunc();
      return returnValue;
    } finally {
      if (timeoutRef) clearTimeout(timeoutRef);
    }
  };

  const promises: Promise<T>[] = [mainPromise()];

  const result = await new Promise(function (fulfil, reject) {
    promises.forEach(function (promise) {
      promise.then(fulfil, reject);
    });
  });

  if (result === "Timeout promise resolved first") {
    throw new Error("Timeout exceeded");
  }

  return result;
}

export const stringifyNumber = (inputNumber: number): string =>
  inputNumber < 10 ? `0${inputNumber}` : `${inputNumber}`;

export const deepEquals = (x: unknown, y: unknown): boolean =>
  JSON.stringify(x) === JSON.stringify(y);

export const localeDateConverter = (isoStringDate: string | undefined): string => {
  if (!isoStringDate) {
    return "";
  }
  return new Date(isoStringDate).toLocaleDateString();
};

export const dateOnly = (inputDate = new Date()): string => {
  const year = inputDate.getFullYear();
  const month = inputDate.getMonth();
  const day = inputDate.getDate();

  return new Date(year, month, day).toISOString();
};

export const bytesToMb = (bytes: number): string => (bytes / 1024 / 1024).toFixed(1);

export const bytesToKb = (bytes: number): string => (bytes / 1024).toFixed(1);

export const formatBytes = (bytes: number): string => {
  if (bytes > 1024 * 1024) return `${bytesToMb(bytes)}MB`;

  return `${bytesToKb(bytes)}KB`;
};

export const fileIsImage = (type: string): boolean => {
  return type.startsWith("image/");
};

export const monthLookup = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const reorderList = <T>(items: T[], startIndex: number, endIndex: number): T[] => {
  if (startIndex === endIndex) return items;

  const result = [...items];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Given two list of elements of the same type, it returns a list of elements that are in both
 * @param A List of elements of type T
 * @param B List of elements of type T
 * @returns The elements that are on A and B
 */
export const findIntersection = <T extends { id: string }>(A: T[], B: T[]): T[] =>
  A.filter((a) => B.some((b) => b.id === a.id));

/**
 * Given two list A and B of elements of the same type, it return the elements A
 * that does not exist B.
 * @param A List of elements of type T
 * @param B List of elements of type T
 * @returns The elements that are on A but not on B
 */
export const findElementsNoInList = <T extends { id: string }>(A: T[], B: T[]): T[] =>
  A.filter((obj1) => !B.some((obj2) => obj1.id === obj2.id));

/**
 * Given a list objects of type T and a objectKey,
 * replaces the element in the list with the same objectKey as the new object.
 * @param list The list of objects to modify
 * @param newObj The new object that should replace an existing object in the list
 * @returns A new list with the replaced object
 */
export const replaceObject = <T extends { [key: string]: unknown }>(
  list: T[],
  newObj: T,
  objectKey: keyof T,
): T[] => {
  const index = list.findIndex((obj: T) => obj[objectKey] === newObj[objectKey]);
  const newList = [...list];
  newList[index] = newObj;
  return newList;
};

/**
 * Removes an object from an array based on a specified object key.
 * @param list The array of objects.
 * @param objectKey The key of the object property to use for filtering.
 * @param value The value of the object property to match for removal.
 * @returns A new array without the object(s) matching the specified property value.
 */
export const removeObjectFromList = <T>(list: T[], objectKey: keyof T, value: T[keyof T]): T[] =>
  list.filter((item) => item[objectKey] !== value);

export const isEmpty = <T>(arr: T[]): boolean => {
  return arr.length === 0;
};

/**
 * Converts a key to title format by capitalizing the first letter of each word and separating them with spaces.
 * @param key The key to convert to title format.
 * @returns The key in title format.
 */
export const toTitleFormat = (key: string): string => {
  // Split the key into words based on uppercase letters
  const words = key.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with spaces
  return capitalizedWords.join(" ");
};

/**
 * Splits a string by the specified ranges and returns an array of substrings.
 * Each substring is represented by an object with the substring text and a flag
 * indicating if it is part of one of the ranges.
 *
 * @param str - The input string to be split.
 * @param ranges - The numeric ranges to split the string.
 * @returns An array of substrings with corresponding match flags.
 */
export const splitStringByRanges = (
  str: string,
  ranges: readonly Fuse.RangeTuple[],
): { text: string; isMatch: boolean }[] => {
  const substrings: { text: string; isMatch: boolean }[] = [];
  let currentIndex = 0;

  ranges.forEach((range) => {
    const [start, end] = range;

    // Add the characters before the current range
    if (currentIndex < start) {
      substrings.push({
        text: str.slice(currentIndex, start),
        isMatch: false,
      });
    }

    // Add the characters in the current range
    substrings.push({
      text: str.slice(start, end + 1),
      isMatch: true,
    });

    currentIndex = end + 1;
  });

  // Add any remaining characters after the last range
  if (currentIndex < str.length) {
    substrings.push({
      text: str.slice(currentIndex),
      isMatch: false,
    });
  }

  return substrings;
};

/**
 * Converts an object with key/value pairs to an array of arrays,
 * where each inner array contains the key and value.
 *
 * @param obj - The object to convert to an array.
 * @returns An array of arrays with key-value pairs.
 */
export const objectToArray = <T extends Record<string, unknown>>(
  obj: T,
): [keyof T, T[keyof T]][] => {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
};

/**
 * Removes any () and % before encoding. Related to: https://github.com/remix-run/react-router/issues/8300
 * @param inputString
 * @returns
 */
export const removeBracketsAndPercent = (inputString?: string): string | undefined => {
  if (!inputString) return;
  // Use a regular expression to match and remove '(', ')', and '%' characters
  const stringWithoutBracketsAndPercent = inputString.replace(/[()%]/g, "");

  return stringWithoutBracketsAndPercent;
};

/**
 * Function to compare URLs based on the text after the last "/"
 * @param url1
 * @param url2
 * @returns True or false
 */
export function compareUrls(url1: string, url2: string): boolean {
  const getTextAfterLastSlash = (url: string): string => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const textAfterLastSlash1 = getTextAfterLastSlash(url1);
  const textAfterLastSlash2 = getTextAfterLastSlash(url2);

  return textAfterLastSlash1 === textAfterLastSlash2;
}

export const isValidDate = (dateString: string) => {
  return !isNaN(Date.parse(dateString));
};

export const formatLocation = ({ location }: { location?: UserLocation }) => {
  const label = `${location?.city}, ${location?.country}`;
  const value = `${location?.city},${location?.country}`;
  return { label, value };
};

export const isOrganisationLevelSection = (value: string): value is OrganisationLevelSection =>
  Object.values<string>(OrganisationLevelSection).includes(value);

/**
 * Helper function that can be used to asset that a value is never reached.
 */
export const expectNever = (_value: never): void => {
  // This function should never be called.
};

export const getSearchParams = <T extends Array<string>>({
  searchString,
  paramNameList,
}: {
  searchString: string;
  paramNameList: [...T];
}) => {
  const searchParams = new URLSearchParams(searchString);
  return paramNameList.reduce(
    (acc, current) => {
      const newAcc = { ...acc };
      newAcc[current] = searchParams.get(current);
      return newAcc;
    },
    {} as Record<(typeof paramNameList)[number], string | null>,
  );
};

export const encodeEolasUriPath = (uriStr: string): string => {
  // check if truthy
  if (uriStr) {
    // check if uriStr includes hostname
    if (uriStr.includes("http")) {
      if (isDev()) {
        console.warn(
          "encodeEolasUriPath: the supplied uri includes hostname, encodeEolasUriPath does not support encoding paths with hostname",
          uriStr,
        );
      }
      return uriStr;
    }
    // split uriStr into path and query
    const [path, query] = uriStr.split("?");
    // encode path
    const encodedPath = path
      .split("/")
      .map((part: string) => {
        return encodeURIComponent(part);
      })
      .join("/");
    // encode query
    let encodedQuery = "";
    if (query) {
      // split query into parts
      const queryParts = query.split("&");
      // encode each part
      encodedQuery = queryParts
        .map((part: string) => {
          return part
            .split("=")
            .map((subPart: string) => {
              return encodeURIComponent(subPart);
            })
            .join("=");
        })
        .join("&");
    }
    // return encoded uri
    return `${encodedPath}${encodedQuery ? `?${encodedQuery}` : ""}`;
  }
  if (isDev()) {
    console.warn("encodeEolasUriPath: the supplied uri is not truthy", uriStr);
  }
  return "";
};

export const stringBoolToBool = (value?: string | null): boolean | undefined | null => {
  if (value === "true") return true;
  if (value === "false") return false;

  if (typeof value === "string") {
    if (isDev()) {
      console.warn("stringBoolToBool: the supplied string is not 'true' or 'false'", value);
    }
    return null;
  }

  return value;
};

export const getFileExtensionFromString = (name: string) => {
  const splitName = name.split(".");
  const ext = splitName[splitName.length - 1] ?? "";
  if (!ext || splitName.length === 1) {
    return "";
  }
  return ext;
};

export const getErrorMessage = ({
  error,
  customErrorMessage,
}: {
  error: unknown;
  customErrorMessage?: string;
}) => {
  if (hasStringProp(error, "message")) return error.message;

  if (customErrorMessage) return customErrorMessage;

  return "Unknown error";
};

/**
 * Utility function that wraps Object.keys and explicitly types the keys as the type
 * rather than as string
 */
export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

/* Pass string which might be url. Returns url with https:// if valid url,
 * null if not
 */
export const sanitiseUrl = (maybeUrl: string): string | null => {
  const urlObj = getUrlObject(maybeUrl, false);
  if (!urlObj) {
    return null;
  }

  if (maybeUrl.startsWith("about:blank")) {
    return null;
  }

  if (maybeUrl.startsWith("https://")) {
    return maybeUrl;
  }
  if (maybeUrl.startsWith("http://")) {
    return maybeUrl.replace("http://", "https://");
  }

  return "https://" + maybeUrl;
};

export const getUrlObject = (urlString: string, shouldThrowError = true) => {
  let url: URL | null = null;
  try {
    url = new URL(urlString);
  } catch (error) {
    if (shouldThrowError && error instanceof Error) {
      eolasLogger.error(error);
    }
  }
  return url;
};

export const isUUID = (str: string) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};

export const isItemChanged = <T extends Record<string, unknown>>({
  item,
  editItem,
  blob,
}: {
  item: T;
  editItem: Partial<T>;
  blob?: File | null;
}) => {
  if (blob) return true;
  for (const key in editItem) {
    const itemProp = item[key];
    const editItemProp = editItem[key];
    if (Array.isArray(itemProp) && Array.isArray(editItemProp)) {
      if (itemProp.sort().join(",") !== editItemProp.sort().join(",")) return true;
    } else {
      if (
        (itemProp && editItemProp && itemProp !== editItemProp) ||
        (!itemProp && editItemProp) ||
        (itemProp && !editItemProp)
      ) {
        return true;
      }
    }
  }
  return false;
};

export const eolasFileNormaliser = (
  file: MasterSearchFile,
): {
  file: EolasFile;
  additionalData: { singleChecklist?: ChecklistRecord; mainSection?: string; parentName?: string };
} => {
  const { singleChecklist, mainSection, parentName, ...rest } = file;
  const additionalData = {
    singleChecklist,
    mainSection,
    parentName,
  };
  if (isEolasFile(file)) {
    return { file, additionalData };
  }
  eolasLogger.warn("Non-eolas file passed into eolasFileNormaliser, this should be avoided", {
    file,
  });
  return {
    file: {
      ...rest,
      id: file.id ?? "",
      name: file.name ?? "",
      mainSectionID: file.mainSectionID ?? "",
      parentID: file.parentID ?? "",
      ownerID: file.ownerID ?? "",
      sharedID: file.sharedID ?? "",
      deleted: file.deleted ?? "false",
      createdAt: file.createdAt ?? "",
      updatedAt: file.updatedAt ?? "",
    },
    additionalData,
  };
};

export function stringToHash(str: string) {
  let hash = 0;

  if (str.length === 0) return hash.toString();

  for (const char of str) {
    hash ^= char.charCodeAt(0); // Bitwise XOR operation
  }

  return hash.toString();
}

export const isEmail = (str: string) => {
  const regex = /.+@.+\..+/;
  return regex.test(str);
};
