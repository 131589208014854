import { ContentItem, EolasShadowCopyContent, SupportedFileType } from "@eolas-medical/core";
import { isContentItemMediaSupportedFileType } from "./typeguards";

export const mapContentItemToSupportedFileType = (
  item: ContentItem | EolasShadowCopyContent,
): SupportedFileType => {
  const maybeType: string | undefined = item.media?.type;
  if (maybeType === "dsm") {
    return "dsm";
  }
  if (isContentItemMediaSupportedFileType(item)) {
    return item.media.type;
  }

  if (item.type === "EOLAS_EDITOR") {
    return "eolas-editor";
  }

  if (item.type === "FLASHCARD") {
    return "flashcard";
  }

  // Default case, ensure "link" is part of SupportedFileType
  return "link";
};
