import { useState } from "react";
import { AuthenticationWizard, ForgotPasswordStep } from "Pages/Authentication/types";
import { Step, Wizard, useWizard } from "UIKit/Wizard";
import useForgotPassword from "modules/authentication/data/useForgotPassword";
import useResetPassword from "modules/authentication/data/useResetPassword";
import useCreatePasswordStep from "../CreatePasswordModal/useCreatePasswordStep";
import useVerificationCodeStep from "../VerificationCodeModal/useVerificationCodeStep";

import useSignInUser from "modules/authentication/data/useSignInUser";
import useAssistanceVerificationCode from "modules/authentication/data/useAssistanceVerificationCode";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import useValidateEmailStep from "../ValidateEmailModal/useValidateEmailStep";
import { isEmail } from "Utilities/helpers";
import { useCheckAuthFlowBehaviour } from "Pages/Authentication/hooks/useCheckAuthFlowBehaviour";

interface ForgotPasswordWizardProps {
  email?: string;
  onChangeWizard: (wizard: AuthenticationWizard, email: string) => void;
}

interface ForgotPasswordState {
  email: string;
  newPassword: string;
}

const ForgotPasswordWizard = ({ email, onChangeWizard }: ForgotPasswordWizardProps) => {
  const { forgotPassword, forgotPasswordLoading, forgotPasswordSuccess, forgotPasswordError } =
    useForgotPassword();
  const { resetPassword, resettingPassword, resetPasswordError, resetPasswordSuccess } =
    useResetPassword();

  const behaviour = useCheckAuthFlowBehaviour();

  const { signInUser } = useSignInUser();

  const [wizardState, setWizardState] = useState<ForgotPasswordState>({
    email: behaviour === "ui-direction-based" ? email || "" : "",
    newPassword: "",
  });

  const { requestAssistanceVerificationCode } = useAssistanceVerificationCode({
    email: wizardState.email,
    origin: "FORGOT_PASSWORD",
  });

  const { wizardControl, submitWizard } = useWizard();

  const handleResetPassword = (code: string) => {
    resetPassword(
      { email: wizardState.email, code, newPassword: wizardState.newPassword },
      {
        onSuccess: async () => {
          trackEvent(AnalyticsEvents.CORRECT_CODE_ENTERED_LOGIN_FLOW);
          await signInUser({ email: wizardState.email, password: wizardState.newPassword });
        },
        onError: () => {
          trackEvent(AnalyticsEvents.INCORRECT_CODE_ENTERED_LOGIN_FLOW);
        },
      },
    );
  };

  const handleSubmitForgotPassword = (newPassword: string) => {
    setWizardState((prev) => ({ ...prev, newPassword }));
    forgotPassword({ email: wizardState.email });
  };

  const handleResendCode = () => {
    requestAssistanceVerificationCode();
    trackEvent(AnalyticsEvents.DID_NOT_RECEIVE_CODE_LOGIN_FLOW);
  };

  const validateEmailStep = useValidateEmailStep({
    isLoading: false,
    canProceed: isEmail(wizardState.email),
    onSubmit: (email) => {
      setWizardState((prev) => ({ ...prev, email }));
    },
    onEnter: submitWizard,
    onChangeWizard,
    defaultEmail: email,
  });

  const createPasswordStep = useCreatePasswordStep({
    title: "resetPassword",
    isLoading: forgotPasswordLoading,
    canProceed: forgotPasswordSuccess,
    error: forgotPasswordError,
    onSubmit: handleSubmitForgotPassword,
    onEnter: submitWizard,
  });

  const verificationCodeStep = useVerificationCodeStep({
    email: wizardState.email,
    isLoading: resettingPassword,
    canProceed: resetPasswordSuccess,
    error: resetPasswordError,
    onSubmit: handleResetPassword,
    onResendCode: handleResendCode,
    onEnter: submitWizard,
  });

  let steps: Step<ForgotPasswordStep>[] = [
    validateEmailStep,
    createPasswordStep,
    verificationCodeStep,
  ];

  if (behaviour === "ui-direction-based") {
    steps = steps.filter((step) => step.name !== "validate-email");
  }

  return (
    <div className="flex md:w-200">
      <Wizard wizardControl={wizardControl} steps={steps} showSteps={false} />;
    </div>
  );
};

export default ForgotPasswordWizard;
