import {
  AnalyticsEvents,
  CommunityLevelSection,
  CommunityMedicationsSubsection,
  EolasSearchPayload,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useCallback } from "react";

export const useBnfSearchAnalytics = <T>(medicationSubSection: CommunityMedicationsSubsection) => {
  const onBnfSearch = useCallback(
    (text: string, hits?: T[]) => {
      trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
        searchString: text,
        searchType: "Item",
        hitCount: hits?.length,
        origin: CommunityLevelSection.communityMedications,
        medicationSubSection,
      });
    },
    [medicationSubSection],
  );

  return onBnfSearch;
};
