import { useState } from "react";
import { ListItem } from "Pages/Spaces/components/SelectTileList/hooks/useLocalChildrenList";
import { generateLinkToItem } from "shared/functions/generateLinkToItem";
import { eolasLogger, sectionStore } from "@eolas-medical/core";
import { getItemOwnerType } from "Utilities/fileHelpers";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";

type Props = {
  onInsertLink: (link: string) => void;
};

export const useLocalDocumentsList = ({ onInsertLink }: Props) => {
  const [selectedEntity, setSelectedEntity] = useState<{
    id: string;
    type: "section" | "file";
  } | null>(null);
  const [didError, setDidError] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState(false);

  const { file } = useGetFile({
    id: selectedEntity?.type === "file" ? selectedEntity.id : undefined,
  });

  const handleSelectionChange = (selectedIds: Record<string, ListItem>) => {
    const selectedIdsArray = Object.keys(selectedIds);
    if (selectedIdsArray.length === 1) {
      const selectedItem = selectedIds[selectedIdsArray[0]];

      if (!selectedItem) {
        eolasLogger.error(new Error("Selected item not found in list - this should never happen"), {
          selectedIds,
          selectedIdsArray,
        });
        return;
      }
      const isSelectedItemSection = selectedItem.childrenType !== "none";
      setSelectedEntity({ id: selectedItem.id, type: isSelectedItemSection ? "section" : "file" });
    } else {
      setSelectedEntity(null);
    }
  };

  const handleGenerateLink = async () => {
    if (didError) {
      setDidError(false);
    }

    if (selectedEntity) {
      const fileOrSection =
        selectedEntity.type === "section" ? sectionStore.getSection(selectedEntity.id) : file;
      const ownerType = fileOrSection ? getItemOwnerType(fileOrSection) : null;

      if (fileOrSection?.id && ownerType) {
        const result = generateLinkToItem(
          fileOrSection,
          ownerType,
          undefined,
          undefined,
          "document",
        );
        if (result) {
          onInsertLink(result.link);
        } else {
          setDidError(true);
        }
      } else {
        setDidError(true);
        eolasLogger.error(
          new Error(
            "Error generating link to document - could not determine fileOrSection or ownerType",
          ),
          { selectedEntity, ownerType, fileOrSection },
        );
      }
    }
  };

  return {
    selectedEntity,
    handleSelectionChange,
    isSearching,
    setIsSearching,
    handleGenerateLink,
    didError,
  };
};
