import { progressBarStore } from "../stores/progressBar.store";
import { eolasLogger } from "@eolas-medical/core";
import { isDev } from "Utilities/helpers";
import { EolasProgressDownloadProps, EolasProgressUploadProps } from "../types";

export const makeDownloadProgressCallback =
  (progressBarId: string): ((props: EolasProgressDownloadProps) => void) =>
  ({ totalBytesWritten, totalBytesExpectedToWrite }: EolasProgressDownloadProps) => {
    const progress = progressBarStore.progressBarMap[progressBarId] ?? 0;
    const newProgressPercentage = (totalBytesWritten / totalBytesExpectedToWrite) * 100;
    if (newProgressPercentage > 100 && isDev()) {
      eolasLogger.warn(
        "Download progress callback computed a value greater than 100%. Your maths is wrong!",
      );
    }
    const newProgressPercentageRounded = Math.round(newProgressPercentage / 10) * 10;
    if (newProgressPercentageRounded !== progress) {
      progressBarStore.updateProgress({
        id: progressBarId,
        progress: newProgressPercentageRounded,
      });
    }
  };

export const makeUploadProgressCallback =
  (progressBarId: string): ((props: EolasProgressUploadProps) => void) =>
  ({ totalBytesSent, totalBytesExpectedToSend }: EolasProgressUploadProps) => {
    const progress = progressBarStore.progressBarMap[progressBarId] ?? 0;
    const newProgressPercentage = (totalBytesSent / totalBytesExpectedToSend) * 100;
    if (newProgressPercentage > 100 && isDev()) {
      eolasLogger.warn(
        "Upload progress callback computed a value greater than 100%. Your maths is wrong!",
      );
    }
    const newProgressPercentageRounded = Math.round(newProgressPercentage / 10) * 10;
    if (newProgressPercentageRounded !== progress) {
      progressBarStore.updateProgress({
        id: progressBarId,
        progress: newProgressPercentageRounded,
      });
    }
  };
