import { EolasFile, eolasLogger, sectionStore } from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { useNotifications } from "Components/Notifications";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { useShareFile } from "shared/hooks/useShareFile";
import { modalStore } from "Stores/ModalStore";
import { FavouriteToggle } from "UIKit";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";

export const SpaceOrgShareSectionShareButton = observer(
  ({
    shouldShowShareButton,
    file,
    sectionId,
  }: {
    shouldShowShareButton: boolean;
    file: EolasFile | null;
    sectionId: string;
  }) => {
    const { t } = useTranslation();

    const { showNotification } = useNotifications();

    const { handleOnShareFile } = useShareFile({ shouldUseMobxModal: true });

    const section = sectionStore.getSection(sectionId);

    const { showRemoveFavouriteModal } = useRemoveFavouriteModal({ shouldUseMobxModal: true });

    const fileId = file?.id;
    const fileName = file?.name;

    const shouldTreatAsFileAutomatically =
      file?.name === sectionStore.getChildReferenceOfSection(sectionId)?.name;

    const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
      useAddFavourite(fileId || sectionId);

    const shouldShowFavouritesButton = isMarkFavouriteEnabled && Boolean(file);

    if ((!shouldShowFavouritesButton && !shouldShowShareButton) || !section?.id) {
      return null;
    }

    const handleShareItem = async () => {
      if (!section.id) {
        eolasLogger.error(new Error("Unable to share item"), {
          fileId,
          sectionId,
          shouldTreatAsFileAutomatically,
        });
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("share_item_link_failure"),
        });
        return;
      }
      if (file) {
        if (shouldTreatAsFileAutomatically) {
          handleOnShareFile(file);
        } else {
          modalStore.openModal({
            variant: "dialogue",
            layoutType: "stacked",
            name: "ConfirmShareModal",
            icon: "info",
            title: t("share_options_title"),
            message: t("share_options_desc", { fileName }),
            confirmText: t("share_file"),
            dismissText: t("share_section"),
            onConfirm: () => {
              handleOnShareFile(file);
            },
            onDismiss: () => {
              handleOnShareFile(section);
            },
            isDismissible: true,
          });
        }
      } else {
        handleOnShareFile(section);
      }
    };

    const handleFavouriteItem = async () => {
      if (!file) {
        return;
      }
      if (favouriteId && fileName) {
        showRemoveFavouriteModal({
          entityId: fileId || sectionId,
          favouriteId,
          title: fileName,
          entityType: FavouriteEntityType.FILE,
          originForAnalytics: "genericContentRepository",
        });
      } else {
        if (shouldTreatAsFileAutomatically) {
          handleAddFavourite({
            item: file,
            entityType: FavouriteEntityType.FILE,
          });
        } else {
          modalStore.openModal({
            variant: "dialogue",
            layoutType: "stacked",
            name: "ConfirmFavouriteModal",
            icon: "info",
            title: t("lone_file_favourite_title"),
            message: t("lone_file_favourite_desc", { fileName }),
            confirmText: t("general_yes"),
            dismissText: t("general_no"),
            onConfirmAsync: async () => {
              await handleAddFavourite({
                item: file,
                entityType: FavouriteEntityType.FILE,
              });
            },
            isDismissible: true,
          });
        }
      }
    };

    return (
      <div className="flex flex-col">
        {shouldShowShareButton ? (
          <ShareFileButton id={fileId || sectionId} onShareFile={handleShareItem} />
        ) : null}
        {shouldShowFavouritesButton && fileId && fileName ? (
          <FavouriteToggle
            isLoading={isMarkingAsFavourite}
            onClick={handleFavouriteItem}
            entityId={fileId}
          />
        ) : null}
      </div>
    );
  },
);
