import {
  AnalyticsEvents,
  ClinicalQuestionGeneralPayload,
  clinicalQuestionsClient,
  clinicalQuestionStore,
  CreateClinicalQuestionParams,
  eolasLogger,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";

const createSpaceQuestionAPI = async ({ text, notes, region }: CreateClinicalQuestionParams) => {
  const ownerID = sectionStore.appID;
  if (!ownerID) {
    throw new Error("Owner ID not found");
  }
  const response = await clinicalQuestionsClient.createClinicalQuestion({
    text,
    notes,
    userID: userStore.userID,
    region,
    returnDirectUrls: true,
    ownerID,
  });
  return response;
};

export const useCreateAskEolasSpacesQuestion = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const { mutateAsync, data } = useMutation({
    mutationFn: createSpaceQuestionAPI,
    mutationKey: ["createClinicalQuestion"],
    onSuccess: (response, args) => {
      clinicalQuestionStore.updateResponsesMap(response.requestID, {
        originalString: args.text,
        requestID: response.requestID,
        jobStatus: "PENDING",
        body: "",
      });
      if (args.text) {
        trackEvent<ClinicalQuestionGeneralPayload>(
          AnalyticsEvents.ASK_EOLAS_SPACES_QUESTION_ASKED,
          {
            query: args.text,
            questionID: response.requestID,
          },
        );
      }

      if (args.notes) {
        trackEvent<ClinicalQuestionGeneralPayload>(
          AnalyticsEvents.ASK_EOLAS_SPACES_FOLLOW_UP_ASKED,
          {
            query: `ORIGINAL QUESTION: ${args.text} | FOLLOW UP: ${args.notes}`,
          },
        );
        clinicalQuestionStore.setFollowUpString(args.notes);
      }
    },
    onError: (error, args) => {
      eolasLogger.error(new Error("Error creating question for Ask Eolas Spaces"), {
        error,
        question: args.text,
      });
      showNotification({
        type: "error",
        description: t("create_question_error"),
        autoHideTimeout: 5000,
      });
    },
  });

  return { createAskEolasSpacesQuestion: mutateAsync, data };
};
