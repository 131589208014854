import { useCallback } from "react";

import {
  MP4Icon,
  PDFIcon,
  CheckIcon,
  CircleIcon,
  DomainIcon,
  MSOfficeIcon,
  AttentionIcon,
} from "Assets";

import { CompletedFileProps } from "Utilities/types";

import { Button, FavouriteToggle } from "../Button";
import { FileTileWrapper, FileTileProps, DefaultFileControls } from "./shared";
import { DownloadFileButton } from "Components";
import { CompletedContentStatus, sectionStore } from "@eolas-medical/core";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { ShareFileButton } from "./shared/ShareFileButton";
import { observer } from "mobx-react-lite";

const iconSize = { height: 40, width: 40 };

type LeftIcon = "pdf" | "mp4" | "link" | "ms-office";

const leftIcon: Record<LeftIcon, React.ReactNode> = {
  pdf: <PDFIcon {...iconSize} />,
  mp4: <MP4Icon {...iconSize} />,
  link: <DomainIcon {...iconSize} />,
  "ms-office": <MSOfficeIcon {...iconSize} />,
};

export interface EducationPortalFileTileProps extends FileTileProps, CompletedFileProps {
  loading?: boolean;
  onMarkAsComplete(fileId: string, status: CompletedContentStatus): void;
}

export const EducationPortalFileTile = observer(
  ({
    isAdmin,
    canDrag,
    eolasFile,
    isChecked,
    isDragging,
    loading = false,
    completionLabel,
    onMarkAsComplete,
    isButtonDisabled,
    isCompleted = false,
    onSelectFile = () => {
      return;
    },
    onShareFile,
    showRemoveFavouriteModal,
    ...handlers
  }: EducationPortalFileTileProps) => {
    const onComplete = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        onMarkAsComplete(eolasFile.id, CompletedContentStatus.COMPLETE);
      },
      [eolasFile.id, onMarkAsComplete],
    );

    const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
      useAddFavourite(eolasFile.id);
    const shouldShowShareButton = !!onShareFile && !isAdmin;
    const shouldShowFavouriteToggle = !isAdmin && isMarkFavouriteEnabled;
    const shouldShowCompletedStatus = !isAdmin;
    const showCompleteStatus = isChecked ? (
      <CheckIcon className="text-green" height={20} width={20} />
    ) : (
      <CircleIcon className="text-grey-300" height={20} width={20} />
    );

    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(
      eolasFile.mainSectionID,
    );

    return (
      <FileTileWrapper
        className="h-50"
        canDrag={canDrag}
        eolasFile={eolasFile}
        isDragging={isDragging}
        onClick={() => onSelectFile(eolasFile)}
      >
        <span className="flex justify-center items-center w-min">
          {leftIcon[eolasFile.type as LeftIcon]}
        </span>
        <div className="flex flex-col flex-1 justify-between h-full">
          <div className="flex">
            <div className="flex flex-col justify-center flex-1">
              <span className="font-semibold text-lg line-clamp-1 w-4/5 break-all">
                {eolasFile.name}
              </span>
              <span className="text-grey-dark line-clamp-1 w-4/5 break-all">
                {eolasFile.description}
              </span>
            </div>
            <div className="w-fit self-start flex flex-col items-center justify-start">
              {shouldShowCompletedStatus ? (
                <div className="p-2">
                  {isCompleted ? (
                    showCompleteStatus
                  ) : (
                    <AttentionIcon className="text-red" height={20} width={20} />
                  )}
                </div>
              ) : null}
              {shouldShowFavouriteToggle ? (
                <FavouriteToggle
                  entityId={eolasFile.id}
                  onClick={() =>
                    favouriteId && showRemoveFavouriteModal
                      ? showRemoveFavouriteModal({
                          entityId: eolasFile.id,
                          favouriteId,
                          title: eolasFile.name,
                          entityType: FavouriteEntityType.FILE,
                          originForAnalytics: mainSectionType ?? undefined,
                        })
                      : handleAddFavourite({
                          item: eolasFile,
                          entityType: FavouriteEntityType.FILE,
                        })
                  }
                  isLoading={!!isMarkingAsFavourite}
                />
              ) : null}
              {(eolasFile.type !== "mp4" && eolasFile.type !== "mov") || isAdmin ? (
                <DownloadFileButton eolasFile={eolasFile} />
              ) : null}
              {shouldShowShareButton ? (
                <ShareFileButton id={eolasFile.id} onShareFile={() => onShareFile(eolasFile)} />
              ) : null}
            </div>
          </div>

          {!isAdmin ? (
            <span className="mt-2">
              <Button
                size="sm"
                color="grey"
                className="w-full"
                isLoading={loading}
                onClick={onComplete}
                disabled={isButtonDisabled}
                data-testid="mark-as-complete-button"
              >
                {completionLabel}
              </Button>
            </span>
          ) : null}
        </div>

        {isAdmin ? <DefaultFileControls eolasFile={eolasFile} {...handlers} /> : null}
      </FileTileWrapper>
    );
  },
);
