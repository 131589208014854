import {
  ChangeContentTypeBody,
  eolasLogger,
  NewContentType,
  SupportedFileType,
  userStore,
} from "@eolas-medical/core";
import { DraftStatus, SupportedPickerType, WizardState } from "../types";
import { ManageContentItemChildModalProps } from "../ManageContentItemChildModal";
import { cloneDeep } from "lodash";
import { isSupportedWizardType } from "../typeguards";

export const makeInitialWizardState = (
  props: ManageContentItemChildModalProps & { resetType?: SupportedPickerType },
): WizardState | null => {
  if (!props.file) {
    return {
      fileId: null,
      isExistingFileWithNewType: false,
      dto: { parentId: props.sectionId, name: "", createdBy: userStore.userID },
      stepName: "selectFile",
      type: "blob",
    };
  }

  const { file, draftFile, resetType } = props;

  if (resetType) {
    let existingDraftStatus: DraftStatus | undefined = undefined;
    if (draftFile) {
      existingDraftStatus = "parallel";
    } else if (file.isDraft) {
      existingDraftStatus = "unpublished";
    }
    let dto: ChangeContentTypeBody;
    switch (resetType) {
      case "blob": {
        dto = {
          newType: NewContentType.FILE,
          newProperties: { key: "", mediaType: "", keywords: [] },
        };
        break;
      }
      case "dsm": {
        dto = {
          newType: NewContentType.DSM,
          newProperties: { key: "", keywords: [], metadata: "" },
        };
        break;
      }
      case "eolas-editor": {
        dto = {
          newType: NewContentType.EOLAS_EDITOR,
          newProperties: { metadata: "", keywords: [] },
        };
        break;
      }
      case "flashcard": {
        dto = {
          newType: NewContentType.FLASHCARD,
          newProperties: { metadata: "", key: "", description: "" },
        };
        break;
      }
      case "link": {
        dto = {
          newType: NewContentType.LINK,
          newProperties: { key: "" },
        };
        break;
      }
    }
    return {
      fileId: file.id,
      dto,
      existingDraftStatus,
      stepName: "selectFile",
      draftFile,
      isExistingFileWithNewType: true,
    };
  }

  if (draftFile) {
    let type: SupportedFileType;
    if (isSupportedWizardType(draftFile.type)) {
      type = draftFile.type;
    } else {
      eolasLogger.error(new Error("makeInitialWizardState: unsupported file type"), { draftFile });
      type = "pdf";
    }
    return {
      fileId: file.id,
      dto: {
        name: draftFile.name,
        description: draftFile.description || undefined,
        mediaName: draftFile.mediaName || undefined,
        metadata: draftFile.metadata || undefined,
        expiryDate: draftFile.expiryDate || undefined,
        key: draftFile.key || undefined,
        keywords: draftFile.keywords ? [...draftFile.keywords] : undefined,
        isDraft: draftFile.isDraft || undefined,
        info: draftFile.info || undefined,
        infoTwo: draftFile.infoTwo || undefined,
        moreInfo: draftFile.moreInfo || undefined,
        moreInfoTwo: draftFile.moreInfoTwo || undefined,
      },
      stepName: "selectFile",
      existingDraftStatus: "parallel",
      draftFile: cloneDeep(draftFile),
      isExistingFileWithNewType: false,
      type,
    };
  }

  let type: SupportedFileType;
  if (isSupportedWizardType(file.type)) {
    type = file.type;
  } else {
    eolasLogger.error(new Error("makeInitialWizardState: unsupported file type"), { file });
    type = "pdf";
  }

  return {
    fileId: file.id,
    dto: {
      name: file.name,
      description: file.description || undefined,
      mediaName: file.mediaName || undefined,
      metadata: file.metadata || undefined,
      expiryDate: file.expiryDate || undefined,
      key: file.key || undefined,
      keywords: file.keywords ? [...file.keywords] : undefined,
      isDraft: file.isDraft || undefined,
      info: file.info || undefined,
      infoTwo: file.infoTwo || undefined,
      moreInfo: file.moreInfo || undefined,
      moreInfoTwo: file.moreInfoTwo || undefined,
    },
    stepName: "selectFile",
    existingDraftStatus: file.isDraft ? "unpublished" : undefined,
    isExistingFileWithNewType: false,
    type,
  };
};
