import { sectionStore } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { useGetFileCount } from "Pages/Spaces/hooks/useGetFileCount";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export const useGetLoneFile = ({
  counts,
  sectionId,
}: {
  counts: ReturnType<typeof useGetFileCount>;
  sectionId: string;
}) => {
  const { data } = useQuery({
    queryKey: ["getLoneFile", counts, sectionId],
    enabled: Boolean(counts),
    queryFn: async () => {
      if (counts?.publishedFiles !== 1) {
        return null;
      }
      const childrenOrder = sectionStore.getChildrenOrder(sectionId);
      const files = await contentDbStore.getMultipleItems(childrenOrder);
      return files.find(({ isDraft }) => !isDraft) ?? null;
    },
  });

  return data ?? null;
};
