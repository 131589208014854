import { userStore } from "@eolas-medical/core";

const useUserDetails = () => {
  const { email = "" } = userStore.userData;
  const isSharedAccount = email?.endsWith("@autologin.com") ?? false;

  return { isSharedAccount, email };
};

export default useUserDetails;
