import { useTranslation } from "react-i18next";
import { EolasMainSection } from "@eolas-medical/core";

import { ArrowLeftIcon } from "Assets";
import {
  Input,
  Title,
  Button,
  ModalBody,
  FormError,
  IconButton,
  ImageUploader,
  FormElement,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useAddGuidelineOrganisation } from "../useAddGuidelineOrganisation";

interface AddOrganisationProps {
  onClose(): void;
  mainSectionId: EolasMainSection;
}

export const AddOrganisation: React.FC<AddOrganisationProps> = ({
  onClose,
  mainSectionId,
}: AddOrganisationProps) => {
  const { t } = useTranslation();
  const { error, control, isLoading, onSubmit, onPickImage } = useAddGuidelineOrganisation(onClose);

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex-row relative">
        <IconButton
          type="button"
          onClick={onClose}
          icon={<ArrowLeftIcon />}
          className="p-2 absolute left-4 lg:left-16"
        />
        <Title level={5} className="text-center">
          {t("guidelines_addNew_addOrganisation_addNew")}
        </Title>
      </ModalHeader>

      <ModalBody className="overflow-y-auto">
        <form onSubmit={onSubmit} className={"add-file-form"}>
          <div className="flex flex-col space-y-2">
            <FormElement
              required
              id="name"
              control={control}
              label={t(`${mainSectionId}_addNew_selectOrganisation_name`)}
            >
              {(field) => (
                <Input
                  {...field}
                  data-testid="organisation-name-input"
                  placeholder={t(`${mainSectionId}_addNew_selectOrganisation_name`)}
                />
              )}
            </FormElement>

            <FormElement
              id="file"
              control={control}
              label={t("guidelines_addNew_selectOrganisation_logo")}
            >
              {({ id }, { error }) => (
                <ImageUploader id={id} error={error} onChange={onPickImage} />
              )}
            </FormElement>
          </div>

          <Button
            size="lg"
            type="submit"
            isLoading={isLoading}
            className="w-full xl:w-1/3 self-center whitespace-nowrap p-4"
          >
            {t("guidelines_addNew_addOrganisation_addNew")}
          </Button>

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
