import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATION_FILE } from "./config/i18n.constants";

const entityText = "Space";

i18n
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: "en-GB",
    backend: {
      loadPath: `/locales/{{lng}}/${TRANSLATION_FILE}`,
      ...(process.env.NODE_ENV === "development"
        ? {
            requestOptions: {
              cache: "no-store",
            },
          }
        : {}),
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === "uppercase") return value.toUpperCase();
        if (format === "lowercase") return value.toLowerCase();
        if (format === "capitalize") return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
      defaultVariables: {
        entity: entityText,
      },
    },
  });

export default i18n;
