import { motion, Variants } from "framer-motion";

const containerVariants: Variants = {
  out: {
    opacity: 0.5,
  },
  in: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

type GridContainerProps = {
  children: React.ReactNode;
};

export const GridContainer = ({ children }: GridContainerProps) => {
  return (
    <motion.div
      animate="in"
      initial="out"
      variants={containerVariants}
      className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-12 2xl:gap-16"
    >
      {children}
    </motion.div>
  );
};
