import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";

import {
  sectionStore,
  EolasFile,
  AppLevelSection,
  MajorIncidentSubSection,
  OrganisationLevelSection,
  CommunityLevelSection,
  fromAwsJSON,
  hasProp,
  eolasLogger,
} from "@eolas-medical/core";

import { generateS3PublicImageURL } from "Utilities";
import { useHighlightFile, useCompleteFile } from "Hooks";

import {
  HowToFileTile,
  ContactFileTile,
  RegularFileTile,
  IncidentFileTile,
  NewsFeedFileTile,
  HospitalFileTile,
  GuidelineFileTile,
  WellbeingFileTile,
  CompletedChecklistTile,
  IncidentReportFileTile,
  EducationPortalFileTile,
  IncidentDocumentFileTile,
  EmergencyActionCardFileTile,
  PatientLeafletFileTile,
} from "UIKit/FileTile";
import { MedusaFileTile } from "UIKit/FileTile/MedusaFileTile";
import { NationalGuidelineFileTile } from "UIKit/FileTile/NationalGuidelineFileTile";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";

export interface EolasFileListItemProps {
  isAdmin: boolean;
  canDrag?: boolean;
  isDragging?: boolean;
  eolasFile: EolasFile;
  className?: string;
  onEdit?(file: EolasFile): void;
  onDelete?(file: EolasFile): void;
  filterFn?(file: EolasFile): boolean;
  onSelectFile?(file: EolasFile): void;
  onShareFile?: (file: EolasFile) => void;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
}

/**
 * Deprecated: use EolasVersatileList instead
 */
export const EolasFileListItem: React.FC<EolasFileListItemProps> = observer(
  ({
    isDragging,
    canDrag,
    onEdit,
    isAdmin,
    onDelete,
    eolasFile,
    onSelectFile,
    className = "",
    onShareFile,
    showRemoveFavouriteModal,
  }) => {
    const { highlightLoading, toggleHighlight } = useHighlightFile();

    const {
      onCompleteFile,
      getCompletedFileProps,
      isLoading: completeFileLoading,
    } = useCompleteFile();

    const { icon: mainSectionType } = sectionStore.getMainSectionChildReference(
      eolasFile.mainSectionID!,
    )!;

    const Component = useMemo(() => {
      switch (mainSectionType) {
        case AppLevelSection.incidentReporting:
          return <IncidentReportFileTile isAdmin eolasFile={eolasFile} />;
        case AppLevelSection.majorIncidents:
          // eslint-disable-next-line no-case-declarations
          const { icon: majorIncidentType } = sectionStore
            .getChildrenOrder(eolasFile.mainSectionID as string)
            .find((ref) => ref!.id === eolasFile.parentID)!;
          if (majorIncidentType === MajorIncidentSubSection.incidents) {
            return <IncidentFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
          } else if (majorIncidentType === MajorIncidentSubSection.documents) {
            return <IncidentDocumentFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
          } else {
            return null;
          }
        case AppLevelSection.hospitalList:
          return <HospitalFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case OrganisationLevelSection.orgPatientLeaflets:
        case AppLevelSection.patientLeaflets:
          return <PatientLeafletFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.newsFeed:
          eolasLogger.warn(
            "EolasFileList is deprecated for NewsFeed, this should never happen.. investigate",
            undefined,
            false,
          );
          return null;
        case OrganisationLevelSection.communicationPortal:
          return (
            <NewsFeedFileTile isAdmin={isAdmin} eolasFile={eolasFile} shouldShowAnalytics={false} />
          );
        case AppLevelSection.clinicalPathways:
        case AppLevelSection.guidelines:
        case OrganisationLevelSection.hospitalGuidelines:
          return (
            <GuidelineFileTile
              isAdmin={isAdmin}
              eolasFile={eolasFile}
              organisationImageURL={
                eolasFile.moreInfo ? generateS3PublicImageURL(eolasFile.moreInfo!) : undefined
              }
            />
          );
        case AppLevelSection.admissionGuides:
        case AppLevelSection.howTo:
        case AppLevelSection.equipmentLocations:
          return <HowToFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.contacts:
        case OrganisationLevelSection.hospitalContacts:
          return <ContactFileTile eolasFile={eolasFile} isAdmin={isAdmin} />;
        case OrganisationLevelSection.learningManagement:
        case AppLevelSection.educationPortal:
          return (
            <EducationPortalFileTile
              isAdmin={isAdmin}
              eolasFile={eolasFile}
              loading={completeFileLoading}
              onMarkAsComplete={onCompleteFile}
              {...getCompletedFileProps(eolasFile)}
            />
          );
        case AppLevelSection.researchAndAudit:
        case AppLevelSection.wellbeing:
          return <WellbeingFileTile eolasFile={eolasFile} isAdmin={isAdmin} />;
        case AppLevelSection.emergencyActionCards:
          return <EmergencyActionCardFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.checklists: {
          const maybeChecklistMetadata = fromAwsJSON(eolasFile.metadata);
          const isComplete = isChecklistMetadata(maybeChecklistMetadata)
            ? maybeChecklistMetadata.completedChecklist.checklistSections.every((section) => {
                return section.sectionItems.every((item) => {
                  if (item.itemType === "checkbox") return item.checked;
                  return !!item.inputInfo;
                });
              })
            : false;

          const hasIssues = isChecklistMetadata(maybeChecklistMetadata)
            ? maybeChecklistMetadata.completedChecklist.checklistIssues
            : false;

          return (
            <CompletedChecklistTile
              isAdmin={isAdmin}
              eolasFile={eolasFile}
              hasIssues={hasIssues}
              status={isComplete ? "completed" : "incomplete"}
            />
          );
        }
        case OrganisationLevelSection.medusaMedications:
          return <MedusaFileTile isMedusaFile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case CommunityLevelSection.nationalGuidelines:
        case CommunityLevelSection.communityPatientLeaflets:
          return <NationalGuidelineFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.rotas:
        case AppLevelSection.surveys:
        case AppLevelSection.importantLinks:
        default:
          return <RegularFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
      }
    }, [
      mainSectionType,
      eolasFile,
      isAdmin,
      getCompletedFileProps,
      completeFileLoading,
      onCompleteFile,
    ]);

    if (!Component) return null;

    return React.cloneElement(Component, {
      canDrag,
      className,
      isDragging,
      highlightLoading,
      onEdit,
      onDelete,
      onSelectFile,
      onHighlight: toggleHighlight,
      onShareFile,
      showRemoveFavouriteModal,
    });
  },
);

// The below type and typeguard is purely used as a stop gap to remove dangerous code, and should be removed once the metadata is properly typed

type ChecklistMetadata = {
  completedChecklist: {
    checklistSections: {
      sectionItems: {
        itemType: "checkbox" | "input";
        checked?: boolean;
        inputInfo?: string;
      }[];
    }[];
    checklistIssues?: boolean;
  };
};

function isChecklistMetadata(metadata: unknown): metadata is ChecklistMetadata {
  if (hasProp(metadata, "completedChecklist")) {
    const { completedChecklist } = metadata;
    if (hasProp(completedChecklist, "checklistSections")) {
      return true;
    }
  }
  return false;
}
