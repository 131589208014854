import { BulkRequestFailure } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import contentKeys from "./content.queryKeys";
import { isPromiseFulfilledResult, isPromiseRejectedResult } from "shared/functions/typeguards";
import { useMemo } from "react";
import { useRefetchAppData } from "Hooks";
import {
  BulkResponses,
  SuccessMapping,
  ValidItem,
} from "Pages/Spaces/components/BulkActionItemsModal/types";

export type MutationParams<T extends BulkResponses> = {
  contentItems: ValidItem<T>[];
  destinationSectionId: string;
};

export type Results<T extends BulkResponses> = {
  wasCompleteFailure: boolean;
  wasCompleteSuccess: boolean;
  hasFailedItems: boolean;
  failedItems: BulkRequestFailure[];
  successfulItems: SuccessMapping<T>[];
};

const mutationFn = async <T extends BulkResponses>({
  contentItems,
  destinationSectionId,
  actionFn,
}: MutationParams<T> & {
  actionFn: (params: { contentItems: ValidItem<T>[]; destinationSectionId: string }) => Promise<T>;
}): Promise<
  { type: "flat"; data: T } | { type: "allSettled"; data: PromiseSettledResult<T>[] }
> => {
  if (contentItems.length <= 100) {
    const data = await actionFn({ contentItems, destinationSectionId });
    return { type: "flat", data };
  }

  const chunks = [];
  const chunkSize = 100;

  for (let i = 0; i < contentItems.length; i += chunkSize) {
    chunks.push(contentItems.slice(i, i + chunkSize));
  }

  const data = await Promise.allSettled(
    chunks.map((chunk) => actionFn({ contentItems: chunk, destinationSectionId })),
  );

  return { type: "allSettled", data };
};

export const useBulkActionItems = <T extends BulkResponses>({
  actionFn,
  analyticsFn,
  onActionSuccess,
}: {
  actionFn: (params: { contentItems: ValidItem<T>[]; destinationSectionId: string }) => Promise<T>;
  analyticsFn: (params: {
    successfulItems: SuccessMapping<T>[];
    destinationSectionId?: string;
  }) => void;
  onActionSuccess?: () => void;
}) => {
  const { refetch } = useRefetchAppData();

  const { mutateAsync, isError, isLoading, error, data, variables } = useMutation({
    mutationFn: (params: MutationParams<T>) => mutationFn({ ...params, actionFn }),
    mutationKey: contentKeys.copy(),
    onSuccess: (result) => {
      let items: BulkResponses = [];
      if (result.type === "allSettled") {
        const fulfilledResults = result.data
          .filter(isPromiseFulfilledResult)
          .map((result) => result.value);
        items = fulfilledResults.flat();
      } else {
        items = result.data;
      }

      const successfulItems: SuccessMapping<T>[] = items.filter(
        isSuccessfulResult,
      ) as SuccessMapping<T>[];
      const hasSuccessfulItems = successfulItems.length > 0;

      if (hasSuccessfulItems) {
        analyticsFn({ successfulItems, destinationSectionId: variables?.destinationSectionId });
        refetch();
      }
      if (onActionSuccess) {
        onActionSuccess();
      }
    },
  });

  const results: Results<T> | null = useMemo(() => {
    const requestItems = variables?.contentItems;
    if (!data || !requestItems) {
      return null;
    }

    let failedResults: BulkRequestFailure[] = [];
    let successfulResults: SuccessMapping<T>[] = [];
    let didAllChunksReject = false;

    if (data.type === "allSettled") {
      const rejectedPromises = data.data.filter(isPromiseRejectedResult);
      const fulfilledPromises = data.data.filter(isPromiseFulfilledResult);

      didAllChunksReject = rejectedPromises.length === data.data.length;
      const fulfilledResults = fulfilledPromises
        .map((result) => result.value)
        .flat() as BulkResponses;

      failedResults = fulfilledResults.filter(isFailedResult) as BulkRequestFailure[];
      successfulResults = fulfilledResults.filter(isSuccessfulResult) as SuccessMapping<T>[];
    } else {
      failedResults = data.data.filter(isFailedResult) as BulkRequestFailure[];
      successfulResults = data.data.filter(isSuccessfulResult) as SuccessMapping<T>[];
    }

    const wasCompleteFailure = didAllChunksReject || failedResults.length === data.data.length;
    const wasCompleteSuccess = successfulResults.length === data.data.length;

    const failedItems = didAllChunksReject
      ? requestItems.map(
          (item): BulkRequestFailure => ({ id: item.id, status: "error", reason: "unknownError" }),
        )
      : failedResults;

    if (wasCompleteFailure) {
      return {
        wasCompleteFailure: true,
        wasCompleteSuccess: false,
        failedItems: failedItems,
        hasFailedItems: true,
        successfulItems: [],
      };
    }

    if (wasCompleteSuccess) {
      return {
        wasCompleteFailure: false,
        wasCompleteSuccess: true,
        failedItems: [],
        hasFailedItems: false,
        successfulItems: successfulResults,
      };
    }

    return {
      wasCompleteFailure: false,
      wasCompleteSuccess: false,
      failedItems: failedItems,
      successfulItems: successfulResults,
      hasFailedItems: failedItems.length > 0,
    };
  }, [data, variables]);

  return {
    mutateAsync,
    isLoading,
    isError,
    error,
    results,
  };
};

const isSuccessfulResult = <T extends BulkResponses>(
  result: BulkRequestFailure | SuccessMapping<T>,
): result is SuccessMapping<T> => {
  return result.status === "success";
};

const isFailedResult = <T extends BulkResponses>(
  result: BulkRequestFailure | SuccessMapping<T>,
): result is BulkRequestFailure => {
  return result.status === "error";
};
