import { useTranslation } from "react-i18next";
import { AnalyticsEvents, eolasLogger } from "@eolas-medical/core";
import { useEffect, useRef, useCallback } from "react";

import { trackEvent } from "API/Analytics";
import { useNotifications, Notification } from "Components/Notifications";

const useFileNotifications = () => {
  const { t } = useTranslation();
  const notificationRef = useRef<Notification>();

  const { showNotification, hideNotification, updateNotification } = useNotifications();

  const onFileError = useCallback(
    (e: unknown) => {
      if (!notificationRef.current) {
        notificationRef.current = showNotification({
          type: "error",
          description: t("fileViewer_error"),
        });
        return;
      } else {
        updateNotification({
          type: "error",
          id: notificationRef.current.id,
          description: t("fileViewer_error"),
        });
      }
      eolasLogger.error(e);
    },
    [t, updateNotification, showNotification],
  );

  const onFileLoaded = useCallback(() => {
    if (notificationRef.current) {
      hideNotification(notificationRef.current.id);
    }
  }, [hideNotification]);

  useEffect(() => {
    notificationRef.current = showNotification({
      type: "loading",
      description: t("fileViewer_opening_pdf"),
    });
    trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_STARTED);
  }, [t, showNotification]);

  const hideCurrentNotification = useCallback(() => {
    if (notificationRef.current?.id) {
      hideNotification(notificationRef.current.id);
    }
  }, [hideNotification]);

  return {
    showNotification,
    onFileLoaded,
    onFileError,
    hideCurrentNotification,
  };
};

export default useFileNotifications;
