import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  userStore,
  AnalyticsEvents,
  contentClient,
  eolasLogger,
  sectionStore,
  hasStringProp,
  AppLevelSection,
  OrganisationLevelSection,
} from "@eolas-medical/core";
import { useCallback, useState, useMemo, useEffect } from "react";
import { fileNameRegex } from "@eolas-medical/core/lib/src/utils/models";

import { trackEvent } from "API/Analytics";
import { generateS3FileKey } from "Utilities/general";
import { useFileOperations, useRefetchAppData, useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { OnPickFileFunction, LDFlagNames, NO_EXPIRY_DATE } from "Utilities/types";

import { sectionConfigurations } from "./sectionConfigurations";
import { IAddEolasFileForm, IAddFileConfig, InputMapperResult } from "./types";
import { useLaunchDarkly } from "Contexts";
import { ensureFileType, getFileExtension, getFileName } from "Utilities/fileHelpers";
import { AxiosProgressEvent } from "axios";
import { FilePickerAllowedTypes } from "../../../../Utilities/types";
import { AnyObjectSchema } from "yup";
import { cloneDeep } from "lodash";
import { useMutation } from "@tanstack/react-query";

const getErrorMessage = ({
  addFileMutationError,
  editFileMutationError,
}: {
  addFileMutationError: unknown;
  editFileMutationError: unknown;
}) => {
  if (addFileMutationError && hasStringProp(addFileMutationError, "message")) {
    return addFileMutationError.message;
  }
  if (editFileMutationError && hasStringProp(editFileMutationError, "message")) {
    return editFileMutationError.message;
  }
  return "";
};

export const useAddEolasFile = (config: IAddFileConfig) => {
  const { publishFile, updateFile } = useFileOperations();
  const { flags } = useLaunchDarkly();
  const isGuidelineExpiryMandatory = flags[LDFlagNames.MAKE_GUIDELINE_EXPIRY_MANDATORY];
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const [step, setStep] = useState(0);

  const onAddFileMutationFn = async (payload: InputMapperResult, values: IAddEolasFileForm) => {
    if (useAppServicesEndpoints) {
      if (values.selectedFile) {
        await uploadFile(values.key, values.selectedFile, setProgress);
      }
      const { errors } = await publishFile({
        variables: payload,
      });

      if (errors && errors[0]) {
        const [{ message = "Unexpected error" }] = errors;
        throw new Error(message);
      }
    } else {
      let fileMediaId: string | undefined;
      let fileMediaName: string | undefined;
      const mainSection = sectionStore.getChildReferenceByMainSectionType(config.mainSectionID);
      const input = cloneDeep(payload.input);
      if (!mainSection) {
        throw new Error(`Main section with type ${config.mainSectionID} was not found`);
      }
      if (values.selectedFile) {
        const { publicUrl, mediaId } = await uploadFileV2({
          file: values.selectedFile,
          isPublic: config.isPublic,
          mainSectionId: mainSection.id,
        });

        input.key = publicUrl || mediaId;
        fileMediaId = mediaId;
        fileMediaName = `${getFileName(values.selectedFile)}.${getFileExtension(
          values.selectedFile,
        )}`;
      }

      const addContentItemDto = {
        ...input,
        parentId: payload.parentID,
        // This is just to avoid passing null as value
        description: input.description || undefined,
        metadata: input.metadata || undefined,
        expiryDate:
          input.expiryDate === NO_EXPIRY_DATE || input.expiryDate === null
            ? undefined
            : input.expiryDate,
        searchField: input.searchField || undefined,
        key: input.key || undefined,
        type: input.type || undefined,
        info: input.info || undefined,
        infoTwo: input.infoTwo || undefined,
        moreInfo: input.moreInfo || undefined,
        moreInfoTwo: input.moreInfoTwo || undefined,
        createdBy: userStore.userID,
        mediaId: fileMediaId,
        mediaName: fileMediaName,
      };

      if (config.mainSectionID === AppLevelSection.newsFeed && !addContentItemDto.type) {
        addContentItemDto.type = AppLevelSection.newsFeed;
      }

      if (
        config.mainSectionID === OrganisationLevelSection.communicationPortal &&
        !addContentItemDto.type
      ) {
        addContentItemDto.type = OrganisationLevelSection.communicationPortal;
      }

      await contentClient.addContentItem(mainSection.id, addContentItemDto);
    }
  };

  const onEditFileMutationFn = async (payload: InputMapperResult, values: IAddEolasFileForm) => {
    if (useAppServicesEndpoints) {
      if (values.selectedFile) {
        await uploadFile(values.key, values.selectedFile, setProgress);
      }

      const { errors } = await updateFile({
        variables: payload,
      });

      if (errors && errors[0]) {
        const [{ message = "Unexpected error" }] = errors;
        throw new Error(message);
      }
    } else {
      const mainSection = sectionStore.getChildReferenceByMainSectionType(config.mainSectionID);
      if (!mainSection) {
        throw new Error(`Main section with type ${config.mainSectionID} was not found`);
      }
      let fileMediaId: string | undefined;
      let fileMediaName: string | undefined;
      const input = cloneDeep(payload.input);
      if (values.selectedFile) {
        const { publicUrl, mediaId } = await uploadFileV2({
          file: values.selectedFile,
          isPublic: config.isPublic,
          mainSectionId: mainSection.id,
        });
        input.key = publicUrl || mediaId;
        fileMediaId = mediaId;
        fileMediaName = `${getFileName(values.selectedFile)}.${getFileExtension(
          values.selectedFile,
        )}`;
      }

      const { id, type: __, ...rest } = input;

      if (!id) throw new Error("Id of the content is needed when updating it");

      await contentClient.updateContentItem({
        mainSectionId: mainSection.id,
        contentId: id,
        contentDto: {
          ...rest,
          // This is just to avoid passing null as value
          description: rest.description || undefined,
          metadata: rest.metadata || undefined,
          expiryDate: rest.expiryDate === NO_EXPIRY_DATE ? undefined : rest.expiryDate,
          searchField: rest.searchField || undefined,
          key: rest.key || undefined,
          info: rest.info || undefined,
          infoTwo: rest.infoTwo || undefined,
          moreInfo: rest.moreInfo || undefined,
          moreInfoTwo: rest.moreInfoTwo || undefined,
          highlighted: rest.highlighted || undefined,
          mediaId: fileMediaId,
          mediaName: fileMediaName,
        },
      });
    }
  };

  const { refetch } = useRefetchAppData();

  const addFileMutation = useMutation({
    mutationFn: async (params: { payload: InputMapperResult; values: IAddEolasFileForm }) => {
      return onAddFileMutationFn(params.payload, params.values);
    },
    onError: eolasLogger.error,
    onSuccess: async () => {
      trackEvent(AnalyticsEvents.FILE_UPLOADED);
      if (typeof config.afterSubmitFunction === "function") {
        config.afterSubmitFunction();
      }
      refetch();
    },
  });

  const editFileMutation = useMutation({
    mutationFn: async (params: { payload: InputMapperResult; values: IAddEolasFileForm }) => {
      return onEditFileMutationFn(params.payload, params.values);
    },
    onError: eolasLogger.error,
    onSuccess: async () => {
      if (typeof config.afterSubmitFunction === "function") {
        config.afterSubmitFunction();
      }
      refetch();
    },
  });

  const { validationSchema, defaultValuesMapper, inputMapper } = useMemo(() => {
    const cfg = sectionConfigurations[config.mainSectionID];
    const _schema = cfg.validationSchema({
      user: userStore.userData,
      sectionID: config.subSectionId,
      mainSectionID: config.mainSectionID,
    });

    const validationSchema = Array.isArray(_schema) ? _schema[step] : _schema;

    const defaultValuesMapper = cfg.defaultValuesMapper;

    const inputMapper = cfg.createInputMapper({
      user: userStore.userData,
      sectionID: config.subSectionId,
      mainSectionID: config.mainSectionID,
    });

    return {
      inputMapper,
      validationSchema,
      defaultValuesMapper,
    };
  }, [config.mainSectionID, config.subSectionId, step]);

  const formMethods = useForm<IAddEolasFileForm>({
    mode: "all",
    defaultValues: defaultValuesMapper(config),
    resolver: yupResolver(validationSchema as AnyObjectSchema),
  });

  const { control, handleSubmit, watch, setValue, setError, formState } = formMethods;
  const { isValid, errors } = formState;

  useEffect(() => {
    if (isGuidelineExpiryMandatory) {
      setValue("hasExpiryDate", isGuidelineExpiryMandatory);
    }
  }, [isGuidelineExpiryMandatory, setValue]);

  const [fileKey, fileType, hasExpiryDate, progress, selectedFile] = watch([
    "key",
    "type",
    "hasExpiryDate",
    "progress",
    "selectedFile",
  ]);

  const setProgress = useCallback(
    (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.progress) {
        setValue("progress", Math.round(progressEvent.progress * 100));
      }
    },
    [setValue],
  );

  const onURLChange = (url: string) => {
    setValue("key", url.trim(), { shouldValidate: true });
  };

  const onPickFile: OnPickFileFunction = useCallback(
    (selectedFile, { isPublic, addExtension }) => {
      if (!selectedFile) {
        setValue("key", "", { shouldValidate: true });
        setValue("selectedFile", null);
        return;
      }

      const s3Key = generateS3FileKey({
        isPublic,
        addExtension,
        fileName: selectedFile.name,
        fileFormat: selectedFile.type,
        mainSectionId: config.mainSectionID,
      });

      setValue("key", s3Key, { shouldValidate: true });
      setValue("selectedFile", selectedFile);
    },
    [config.mainSectionID, setValue],
  );

  const onFilePickerChange = async (value: File | string, isPublic = false) => {
    if (value instanceof File) {
      const ext = getFileExtension(value);
      const isValidFileType = await ensureFileType(value, ext);
      if (!isValidFileType) {
        return setError("key", { message: "error_file_type_does_not_match_extension" });
      }

      if (value.size > 2_000_000_000) {
        return setError("key", { message: "error_file_too_big" });
      }

      if (fileNameRegex.test(value.name)) {
        onPickFile(value, {
          isPublic,
        });
      } else {
        setError("key", { message: "error_invalid_file_name" });
      }
    } else {
      onURLChange(value);
    }
  };

  const onSelectFileType = (fileType: FilePickerAllowedTypes) => {
    setValue("type", fileType, { shouldValidate: true });
    setValue("selectedFile", null, { shouldValidate: true });
    setValue("key", "", { shouldValidate: true });
  };

  const onStepBack = useCallback(() => {
    setStep((step) => Math.max(0, step - 1));
  }, [setStep]);

  const onSubmit = handleSubmit(async (values) => {
    const { eolasFile, maxSteps } = config;

    if (maxSteps) {
      if (step < maxSteps - 1) {
        setStep((step) => step + 1);
      } else {
        const inputMapperResult = inputMapper(values, selectedFile as File);

        if (eolasFile) {
          inputMapperResult.input.id = eolasFile.id;
          await editFileMutation.mutateAsync({ payload: inputMapperResult, values });
        } else {
          await addFileMutation.mutateAsync({ payload: inputMapperResult, values });
        }
      }
    } else {
      const inputMapperResult = inputMapper(values, selectedFile as File);

      if (eolasFile) {
        inputMapperResult.input.id = eolasFile.id;
        await editFileMutation.mutateAsync({ payload: inputMapperResult, values });
      } else {
        await addFileMutation.mutateAsync({ payload: inputMapperResult, values });
      }
    }
  });

  const filePickerValue = useMemo(() => {
    if (selectedFile) return `${getFileName(selectedFile)}.${getFileExtension(selectedFile)}`;
    if (!fileKey) return "";
    if (config.eolasFile?.mediaName) return `${config.eolasFile?.mediaName}`;

    if (fileType === "mp4" || fileType === "pdf" || fileType === "ms-office") {
      const slashIndex = fileKey.lastIndexOf("/");
      return fileKey.slice(slashIndex + 1);
    }
    return fileKey;
  }, [fileKey, fileType, config.eolasFile, selectedFile]);

  return {
    step,
    error: getErrorMessage({
      addFileMutationError: addFileMutation.error,
      editFileMutationError: editFileMutation.error,
    }),
    control,
    onSubmit,
    progress,
    onStepBack,
    onPickFile,
    formMethods,
    hasExpiryDate,
    filePickerValue,
    formErrors: errors,
    onSelectFileType,
    onFilePickerChange,
    isLoading: addFileMutation.isLoading || editFileMutation.isLoading,
    isFormComplete: isValid,
    filePickerType: fileType,
    isSuccessful: addFileMutation.isSuccess || editFileMutation.isSuccess,
  };
};
