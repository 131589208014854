/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { EolasFile, EolasMainSection } from "@eolas-medical/core";

import {
  Input,
  Title,
  Button,
  Textarea,
  ModalBody,
  FormError,
  FormElement,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";
import { useIsScrollable } from "Hooks";

import { SelectContactType } from "./SelectContactType";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";

export interface AddContactModalProps {
  sectionID: string;
  contact?: EolasFile;
  mainSectionID: EolasMainSection;
  onCloseModal(): void;
}

export const AddContactModal: React.FC<AddContactModalProps> = ({
  contact,
  sectionID,
  mainSectionID,
  onCloseModal,
}: AddContactModalProps) => {
  const { t } = useTranslation();

  const { error, control, onSubmit, isLoading, formMethods, isSuccessful } = useAddEolasFile({
    subSectionId: sectionID,
    mainSectionID,
    eolasFile: contact,
  });

  const { ref, ScrollHint } = useIsScrollable();

  const fileType = formMethods.watch("type");

  if (isSuccessful) {
    return (
      <SuccessModal
        onComplete={onCloseModal}
        text={t(contact ? "contacts_edit_contact_success" : "contacts_add_contact_success")}
      />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>
          {t(contact ? "contacts_modal_edit_title" : "contacts_modal_addNew_title")}
        </Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <FormProvider {...formMethods}>
          <form onSubmit={onSubmit} className="space-y-4 add-file-form">
            <div className="add-file-form__content max-h-60vh" ref={ref}>
              <SelectContactType />

              <FormElement
                required
                id="name"
                control={control}
                label={t("contacts_modal_addNew_name")}
              >
                {(field) => (
                  <Input
                    {...field}
                    size="lg"
                    data-testid="contact-name"
                    placeholder={t("contacts_modal_addNew_name_placeholder")}
                  />
                )}
              </FormElement>

              <FormElement
                required
                id="description"
                control={control}
                label={t("contacts_modal_addNew_details")}
              >
                {(field) => (
                  <Input
                    {...field}
                    size="lg"
                    data-testid="contact-details"
                    placeholder={t("contacts_modal_addNew_details_placeholder")}
                  />
                )}
              </FormElement>

              <FormElement control={control} id="moreInfo" label={t("contacts_keywords_label")}>
                {(field) => (
                  <Textarea
                    {...field}
                    disabled={isLoading}
                    data-testid="fileDescription-input"
                    placeholder={t("contacts_keywords_placeholder")}
                  />
                )}
              </FormElement>

              {(fileType as any) === "extension" && (
                <FormElement
                  id="info"
                  control={control}
                  label={t("contacts_modal_addNew_prefix_label")}
                >
                  {(field) => (
                    <Input
                      {...field}
                      size="lg"
                      data-testid="contact-prefix"
                      placeholder={t("contacts_modal_addNew_prefix_placeholder")}
                    />
                  )}
                </FormElement>
              )}
            </div>

            <Button
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
              style={{ minHeight: "3rem" }}
              className="add-file__submit-button"
            >
              {t(contact ? "contacts_update_contact_button" : "contacts_create_contact_button")}
            </Button>

            <FormError error={error} />
          </form>
        </FormProvider>
      </ModalBody>
    </InnerModalWrapper>
  );
};
