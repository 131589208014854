import { observer } from "mobx-react-lite";

import { useClinicalQuestionInput } from "./hooks/useClinicalQuestionInput";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useState } from "react";
import { AnalyticsEvents, clinicalQuestionStore, CommunityLevelSection } from "@eolas-medical/core";
import { useFeatureGuideModal } from "Components/FeatureGuideModal/hooks/useFeatureGuideModal";
import { trackEvent } from "API/Analytics";
import { AskEolasHome } from "Components/AskEolas/AskEolasHome";
import { useRunOnMountUnmount } from "Hooks";
import { useQueryClient } from "@tanstack/react-query";
import { makeGetClinicalQuestionResponseQueryKey } from "modules/clinicalQuestions/data/useGetClinicalQuestionResponse";

export const ClinicalQuestionsHome = observer(() => {
  const [showNewQuestion, setShowNewQuestion] = useState(false);
  const { control, onSubmit, errors, progress, reset, isSubmitSuccessful, setValue } =
    useClinicalQuestionInput();
  const queryClient = useQueryClient();
  const { shouldShowFeatureGuide, showFeatureGuide } = useFeatureGuideModal(
    CommunityLevelSection.clinicalQuestions,
  );
  const { flags } = useLaunchDarkly();

  useRunOnMountUnmount({
    onMount: async () => {
      if (shouldShowFeatureGuide) {
        showFeatureGuide();
      }
      for (const requestID of Object.keys(clinicalQuestionStore.responsesMap)) {
        await queryClient.cancelQueries(makeGetClinicalQuestionResponseQueryKey(requestID));
      }
      clinicalQuestionStore.resetAll();
    },
    onUnmount: () => {
      clinicalQuestionStore.resetAll();
    },
  });

  const shouldShowBrowserButton = flags[LDFlagNames.SHOW_EOLAS_CLINICAL_QUESTIONS_BROWSER];
  const handleResetQuestion = () => {
    setShowNewQuestion(true);
    reset();
    trackEvent(AnalyticsEvents.CLINICAL_QUESTION_NEW_BUTTON_CLICKED);
    clinicalQuestionStore.resetAll();
  };

  const handleSubmitQuestion = async () => {
    setShowNewQuestion(false);
    onSubmit();
  };

  return (
    <AskEolasHome
      type="knowledge"
      control={control}
      onSubmit={onSubmit}
      errors={errors}
      progress={progress}
      reset={reset}
      isSubmitSuccessful={isSubmitSuccessful}
      setValue={setValue}
      showNewQuestion={showNewQuestion}
      handleSubmitQuestion={handleSubmitQuestion}
      handleResetQuestion={handleResetQuestion}
      shouldShowBrowserButton={shouldShowBrowserButton}
    />
  );
});
