/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Lottie, { LottieProps } from "react-lottie-player";
import { twMerge } from "tailwind-merge";

interface LottieWithHeaderProps {
  text?: string;
  animation: any;
  lottieOptions?: LottieProps;
  animationSize?: string;
  className?: string;
  textClassName?: string;
  onComplete?(): void;
  onClick?(): void;
}

export const LottieWithHeader: React.FC<LottieWithHeaderProps> = ({
  text,
  onClick,
  animation,
  className = "",
  textClassName = "",
  animationSize = "20%",
  lottieOptions = {},
  onComplete = () => {
    return;
  },
  ...props
}: LottieWithHeaderProps) => {
  const defaultOptions = {
    onComplete,
    loop: false,
    play: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    ...lottieOptions,
  };

  return (
    <div
      {...props}
      onClick={onClick}
      className={twMerge("flex flex-col items-center py-4 flex-1 justify-start", className)}
    >
      {text && (
        <span className={`text-2xl font-semibold mb-4 text-center ${textClassName}`}>{text}</span>
      )}

      <Lottie
        {...defaultOptions}
        style={{
          width: animationSize,
          height: animationSize,
        }}
        onComplete={onComplete}
      />
    </div>
  );
};
