import { sectionStore } from "@eolas-medical/core";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useLaunchDarkly } from "Contexts";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useGetLimitedAccess } from "../../ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import useSpaceContentRepositoryParams from "./useSpaceContentRepositoryParams";
import { mapToContentSection } from "modules/contentRepository/helpers";

export const useGetSpaceOrgInfo = () => {
  const {
    contentRepositoryId: mainSectionId,
    subSectionId: currentSubsectionId,
    subSectionsNo,
  } = useSpaceContentRepositoryParams();
  const { isAdmin: isOnAdminView } = sectionStore;
  const { flags } = useLaunchDarkly();
  const { activeTab } = useEolasNavigation();
  const adminStatus = useGetAdminStatus({ activeTab });
  const limitedAccess = useGetLimitedAccess({ activeTab });

  const mainSectionChildRef = sectionStore.getChildReferenceOfSection(mainSectionId);
  const currentSubsectionChildRef = sectionStore.getChildReferenceOfSection(currentSubsectionId);

  const activeSectionId = currentSubsectionId || mainSectionId;

  return {
    isOnAdminView,
    flags,
    activeTab,
    adminStatus,
    limitedAccess,
    // TODO: migrate off map to content section
    mainSection: mainSectionChildRef ? mapToContentSection(mainSectionChildRef) : null,
    currentSubsection: currentSubsectionChildRef
      ? mapToContentSection(currentSubsectionChildRef)
      : null,
    mainSectionId,
    currentSubsectionId: currentSubsectionId || null,
    subSectionsNo,
    activeSectionId,
    isSubsection: Boolean(currentSubsectionId),
  };
};
