import { RouteComponentProps } from "react-router-dom";

export interface ClinicalCalculatorsRouteParams {
  id: string;
}

export const EOLAS_CALCULATOR = "Eolas Calculator";
export const EOLAS_NATIONAL_BODY = "Eolas Medical";
export const EOLAS_GENT_CALC_NAME = "Gentamicin Dosage Calculator";
export const EOLAS_BODY_WEIGHT_CALC_NAME = "Ideal Body Weight Calculator";
export const EOLAS_CR_CL_CALC_NAME = "Creatinine Clearance Calculator";
export const EOLAS_GENT_CALC_ICON_NAME = "gentamicinDose";
export const EOLAS_BODY_WEIGHT_CALC_ICON_NAME = "idealBodyWeight";
export const EOLAS_CR_CL_CALC_ICON_NAME = "creatinineClearance";

export enum CommunitySectionType {
  NATIONAL_RESOURCE = "NATIONAL_RESOURCE",
  BODY = "BODY",
}

export type ClinicalCalculatorRouteProps = RouteComponentProps<ClinicalCalculatorsRouteParams>;
