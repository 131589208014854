import { hasProp, hasStringProp } from "@eolas-medical/core";

type SmpcMetadata = {
  smpcSection: {
    parentId: string;
    id: string;
  };
};

type BnfMetadata = {
  bnfId: string;
  medicationMonographType: "BNF" | "BNFC";
};

type NiceResultMetadata = {
  niceGuidanceId: string;
  niceSectionId?: string;
  niceSubsectionId?: string;
  nicePageNo?: number;
};
export const isSmpcMetadata = (metadata: unknown): metadata is SmpcMetadata => {
  return (
    hasProp(metadata, "smpcSection") &&
    hasStringProp(metadata.smpcSection, "parentId") &&
    hasStringProp(metadata.smpcSection, "id")
  );
};

export const isBnfMetadata = (metadata: unknown): metadata is BnfMetadata => {
  return (
    hasStringProp(metadata, "bnfId") &&
    hasStringProp(metadata, "medicationMonographType") &&
    ["BNF", "BNFC"].includes(metadata.medicationMonographType)
  );
};

export const isNiceResultMetadata = (metadata: unknown): metadata is NiceResultMetadata => {
  return hasStringProp(metadata, "niceGuidanceId");
};
