import { trackEvent } from "API/Analytics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import webNotificationsKeys from "./webNotifications.queryKeys";
import { removeObjectFromList } from "Utilities/helpers";
import { AnalyticsEvents, Notification, notificationsClient } from "@eolas-medical/core";

interface QueryData {
  notifications: Notification[];
}

interface MutationContext {
  previousData: QueryData | undefined;
  deletedNotificationId: string;
}

const useDismissNotification = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<void, unknown, string, MutationContext>(
    async (deletedNotificationId) => {
      await notificationsClient.deleteNotification({ notificationId: deletedNotificationId });
    },
    {
      onMutate: async (deletedNotificationId) => {
        await queryClient.cancelQueries(webNotificationsKeys.list());

        const previousData = queryClient.getQueryData<QueryData>(webNotificationsKeys.list());

        queryClient.setQueryData<QueryData>(webNotificationsKeys.list(), (oldData) => {
          if (oldData) {
            const updatedList = removeObjectFromList<Notification>(
              oldData.notifications,
              "id",
              deletedNotificationId,
            );
            return {
              ...oldData,
              notifications: updatedList,
            };
          }
          return oldData;
        });

        return { previousData, deletedNotificationId };
      },
      onError: (error, _variables, context) => {
        if (context?.previousData) {
          queryClient.setQueryData<QueryData>(webNotificationsKeys.list(), context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(webNotificationsKeys.list());
      },
      onSuccess: (_data, deletedNotificationId, context) => {
        if (context?.previousData) {
          const deletedNotification = context.previousData.notifications.find(
            (notification) => notification.id === deletedNotificationId,
          );
          if (deletedNotification) {
            trackEvent(AnalyticsEvents.NOTIFICATION_REMOVED, {
              notificationType: deletedNotification.type,
            });
          }
        }
      },
    },
  );

  return {
    dismissNotification: mutate,
    dismissingNotification: isLoading,
  };
};

export default useDismissNotification;
