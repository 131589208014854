import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getSearchParams } from "./helpers";

export const useGetSearchParams = <T extends Array<string>>({
  paramList,
}: {
  paramList: [...T];
}) => {
  const { search } = useLocation();

  const paramsDecoded = useMemo(
    () => getSearchParams({ searchString: search, paramNameList: paramList }),
    [search, paramList],
  );

  return paramsDecoded;
};
