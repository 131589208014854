import { useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useWebNotificationMapper from "../hooks/useWebNotificationMapper";
import webNotificationsKeys from "./webNotifications.queryKeys";
import { notificationsClient } from "@eolas-medical/core";
import { WebNotification } from "../types";
import { mapToWebNotification } from "../helpers";

interface UseWebNotificationsProps {
  userId: string;
  spaceId?: string;
  organisationId?: string;
}

const useWebNotifications = ({ userId, spaceId, organisationId }: UseWebNotificationsProps) => {
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery({
    queryKey: webNotificationsKeys.list(),
    queryFn: () =>
      notificationsClient.getNotifications({ userId, departmentId: spaceId, organisationId }),
    enabled: !!userId,
    staleTime: 10000,
  });

  const notificationMapper = useWebNotificationMapper();

  const webNotifications: WebNotification[] = useMemo(() => {
    return data
      ? data.notifications
          .filter((n) => !!n.type)
          .map((notification) => {
            const webNotification = mapToWebNotification(notificationMapper, notification);
            return webNotification;
          })
      : [];
  }, [data, notificationMapper]);

  const webNotificationCount = useMemo(() => {
    return webNotifications.filter((n) => !n.isRead).length;
  }, [webNotifications]);

  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [queryClient, userId, spaceId, organisationId, refetch]);

  return {
    webNotifications: webNotifications,
    webNotificationCount,
    webNotificationsLoading: isLoading,
    refetchWebNotifications: refetch,
  };
};

export default useWebNotifications;
