import { useState } from "react";
import { AddButton, Button, Modal } from "UIKit";
import { MAX_SUBSECTIONS } from "../../../constants";
import { useGetSpaceOrgInfo } from "../../../hooks/useGetSpaceOrgInfo";
import { useTranslation } from "react-i18next";
import { ShareButton } from "./ShareButton";
import { modalStore } from "Stores/ModalStore";
import { DownloadSectionMapModal } from "Pages/Spaces/components/DownloadSectionMapModal/DownloadSectionMapModal";
import { useHistory } from "react-router-dom";
import useAddContentSection from "modules/contentRepository/data/useAddContentSection";
import { useRefetchAppData } from "Hooks";
import AddContentSectionWizard from "shared/pages/ContentRepository/ContentSections/components/AddContentSectionWizard/AddContentSectionWizard";
import CreateContentRepositoryWizard from "Pages/Spaces/pages/Space/pages/ContentManagement/components/CreateContentRepositoryWizard/CreateContentRepositoryWizard";
import { observer } from "mobx-react-lite";

export const AdminRow = observer(() => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { push, location } = useHistory();

  const { refetch } = useRefetchAppData();

  const { subSectionsNo, activeSectionId, isSubsection, mainSectionId } = useGetSpaceOrgInfo();

  const { addContentSection, addingContentSection } = useAddContentSection({
    mainSectionId,
    onSuccess: refetch,
    parentSectionId: activeSectionId,
  });

  const handleAddSection = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleManageExpired = () => {
    push(`${location.pathname}/expired-items`);
  };

  const handleDownloadSectionStructure = () => {
    modalStore.openModal({
      name: "downloadSectionMap",
      variant: "component",
      Component: <DownloadSectionMapModal mainSectionId={mainSectionId} />,
    });
  };

  return (
    <>
      <Modal open={!!showModal} onClose={handleCloseModal}>
        {subSectionsNo === MAX_SUBSECTIONS ? (
          <AddContentSectionWizard
            variant="titleAndIcon"
            onClose={handleCloseModal}
            isDescriptionRequired
          />
        ) : (
          <CreateContentRepositoryWizard
            onCreate={({ childrenType, icon, name, description }) => {
              addContentSection(
                { iconName: icon, name, description, childrenType },
                { onSuccess: handleCloseModal },
              );
            }}
            isLoading={addingContentSection}
            onClose={handleCloseModal}
            isCreatingMainSection={false}
          />
        )}
      </Modal>
      <div className="flex justify-center items-center space-x-4">
        <AddButton onClick={handleAddSection} data-testid="add-file-section">
          {t("my_files_add_section")}
        </AddButton>
        {!isSubsection ? (
          <Button variant="outline" color="white" size="lg" onClick={handleManageExpired}>
            {t("repository_manage_expired")}
          </Button>
        ) : null}
        {!isSubsection ? (
          <Button
            onClick={handleDownloadSectionStructure}
            iconLeft="DownloadIcon"
            color="white"
            size="lg"
          >
            {t("repository_content_map")}
          </Button>
        ) : null}
        <ShareButton activeSectionId={activeSectionId} isSubsection={isSubsection} />
      </div>
    </>
  );
});
