import {
  AnalyticsEvents,
  eolasLogger,
  makeFileDetailedBreadcrumbs,
  sectionStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useSpacesContext } from "modules/spaces";
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getPathToItemOrSection } from "shared/pages/ContentRepository/ContentItems/functions/getPathToItemOrSection";
import { getSearchParams } from "Utilities/helpers";

const MAXIMUM_CHARACTERS = 97;

export const useFileBreadcrumbs = ({
  mainSectionId,
  parentId,
  fileName,
}: {
  mainSectionId?: string;
  parentId?: string;
  fileName?: string;
}) => {
  const [showFullPath, setShowFullPath] = useState(false);
  const { isAdmin: isInAdminMode, encodedSelectedSpaceName } = useSpacesContext();
  const { push } = useHistory();
  const location = useLocation();
  const mainSectionName =
    sectionStore.getMainSectionChildReference(mainSectionId ?? "")?.name ?? "";
  const { fullPath, truncatedPath } = useMemo(
    () =>
      makeFileDetailedBreadcrumbs({
        mainSectionId,
        mainSectionName,
        parentId,
        fileName,
      }),
    [mainSectionId, mainSectionName, parentId, fileName],
  );

  // hide breadcrumbs if document accessed via deeplink that is not an interdocument link
  const { origin } = getSearchParams({
    searchString: location.search,
    paramNameList: ["origin"],
  });
  const shouldHideBreadcrumbs = origin === "section";

  const fullPathLength = fullPath.reduce(
    (acc, breadcrumb) => acc + Object.keys(breadcrumb)[0].length + 3,
    0,
  );

  const pathToShow =
    fullPathLength > MAXIMUM_CHARACTERS && fullPath !== truncatedPath ? truncatedPath : fullPath;

  const onShowFullPath = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowFullPath(true);
  };

  const navigateToSection = (path: string) => {
    if (path.endsWith("/")) {
      const sanitizedPath = path.slice(0, -1);
      push(sanitizedPath);
    } else {
      push(path);
    }
  };

  const onBreadcrumbClick = async (sectionId: string, clickedFromSearch: boolean) => {
    trackEvent(AnalyticsEvents.BREADCRUMB_CLICKED, {
      source: clickedFromSearch ? "search" : "sectionOrFile",
    });
    const pathToLocation = await getPathToItemOrSection({
      id: sectionId,
      isInAdminMode,
      encodedSelectedSpaceName: encodedSelectedSpaceName || null,
    });
    const canNavigateToSection = typeof pathToLocation === "string";
    if (canNavigateToSection) {
      navigateToSection(pathToLocation);
    } else {
      eolasLogger.error("Error navigating to section from breadcrumb", {
        sectionId,
        pathToLocation,
      });
    }
  };

  return {
    pathToShow,
    showFullPath,
    onShowFullPath,
    isPathTruncated: pathToShow !== fullPath,
    fullPath,
    onBreadcrumbClick,
    shouldHideBreadcrumbs,
  };
};
