import { gql, useMutation } from "@apollo/client";
import { sectionStore, contentClient, eolasLogger } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { ChildReference, LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import { getErrorMessage } from "Utilities/helpers";
import { useRefetchAppData } from "Hooks";

const REMOVE_SECTION = gql`
  mutation RemoveSection($parentID: String!, $id: String!) {
    removeSection(parentID: $parentID, id: $id)
  }
`;

export const useDeleteSection = (section: ChildReference) => {
  const [removeSection] = useMutation(REMOVE_SECTION);
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const { refetch } = useRefetchAppData();

  const { error, isLoading, setRequestStatus, isSuccessful } = useRequestStatus();

  const onDeleteSection = async () => {
    const { parentID, ownerID } = sectionStore.getSection(section.id)!;
    const isMainSection = section.type === "mainSection";

    setRequestStatus({ status: "pending", error: "" });

    if (useAppServicesEndpoints) {
      const { data, errors } = await removeSection({
        variables: {
          id: section.id,
          parentID: isMainSection ? ownerID : parentID,
        },
      });

      if (data) {
        setRequestStatus({ status: "success", error: "" });
        refetch();
      }

      if (errors && errors[0]) {
        const [error] = errors;

        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: removeSection,
          retryParameters: {
            variables: {
              id: section.id,
              parentID: isMainSection ? ownerID : parentID,
            },
          },
        });
        setRequestStatus({ status: "error", error: errorMessage });
      }
    } else {
      try {
        isMainSection
          ? await contentClient.deleteMainSection({ mainSectionId: section.id })
          : await contentClient.deleteSectionRest(section.id);
        setRequestStatus({ status: "success", error: "" });
        refetch();
      } catch (error) {
        eolasLogger.error(error);
        setRequestStatus({ status: "error", error: getErrorMessage({ error }) });
      }
    }
  };

  return {
    error,
    isLoading,
    isSuccessful,
    onDeleteSection,
  };
};
