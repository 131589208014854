import React from "react";
import { Text, Title } from "UIKit";
import { format } from "date-fns";
import styles from "./Flashcard.module.css";
import { isValidDate } from "Utilities/helpers";
import { FlashCardHeroImage } from "@eolas-medical/core";

type FlashcardProps = {
  imgSrc: string;
  name: string;
  description?: string;
  createdAt?: string;
  content: React.ReactNode;
};

const Flashcard: React.FC<FlashcardProps> = ({ imgSrc, name, description, createdAt, content }) => {
  return (
    <div className={`bg-white rounded-lg mb-12 flex flex-col flex-grow ${styles.flashcard}`}>
      {imgSrc === FlashCardHeroImage.blankImage ? null : (
        <img
          src={imgSrc}
          srcSet={imgSrc}
          alt="Flashcard Hero"
          className="w-full object-cover max-h-80 rounded-t-lg"
        />
      )}

      <div className="p-5 flex-grow">
        {createdAt && isValidDate(createdAt) ? (
          <Text level={2} className="text-grey-600">
            {format(new Date(createdAt), "do MMMM yyyy")}
          </Text>
        ) : null}

        <Title level={2}>{name}</Title>
        {description ? <Text level={1}>{description}</Text> : null}
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

export default Flashcard;
