import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import useBackgroundCoverTheme from "./useBackgroundCoverTheme";

interface BackgroundCoverProps {
  drawerExpanded: boolean;
  drawerHidden: boolean;
  children?: React.ReactNode;
}

const BackgroundCover = ({ drawerExpanded, drawerHidden }: BackgroundCoverProps) => {
  const backgroundCoverTheme = useBackgroundCoverTheme();

  let backgroundMargin = "w-full";

  if (!drawerHidden) {
    backgroundMargin = drawerExpanded
      ? "w-full lg:w-[calc(100%-theme(spacing.56)+1rem)]"
      : "w-full lg:w-[calc(100%-theme(spacing.20)+1rem)]";
  }

  const backgroundClass = twMerge(
    "h-104 overflow-y-overlay absolute bg-no-repeat bg-local",
    backgroundCoverTheme,
    backgroundMargin,
  );

  return (
    <motion.div
      layout
      className={backgroundClass}
      style={{
        right: "0",
        top: "0",
        borderBottomLeftRadius: "50% 15%",
        borderBottomRightRadius: "50% 15%",
        zIndex: "-1",
      }}
      transition={{ type: "spring", stiffness: 98, damping: 13 }}
    />
  );
};

export default BackgroundCover;
