import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { ContentItem } from "@eolas-medical/core";
import { ContentRepositoryId } from "modules/contentRepository/types";
import useReorderContent from "modules/contentRepository/data/useReorderContent";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import {
  EolasVersatileListOnDropResult,
  ListMenuAction,
  ListMenuActionEvent,
} from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { useEolasVersatileListSelection } from "UIKit/EolasVersatileList/hooks/useEolasVersatileListSelection";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { CopyItemsModal } from "Pages/Spaces/components/CopyItemsModal/CopyItemsModal";
import EmptyLocalList from "shared/components/Lists/EmptyLocalList/EmptyLocalList";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { IsCopyableStatus } from "../ContentItemInstance/types";
import { useSectionSortMethod } from "./hooks/useSectionSortMethod";
import { MoveItemsModal } from "Pages/Spaces/components/MoveItemsModal/MoveItemsModal";
import { useSpaceOrgSearch } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/pages/SpaceContentSections/hooks/useSpaceOrgSearch";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { ContentItemListContext, renderContentItems } from "./components/renderContentItems";

interface ContentItemListProps {
  contentRepositoryId: ContentRepositoryId;
  isInAdminMode: boolean;
  subSectionId?: string;
  doFlashcardsHaveSpecialties?: boolean;
  shouldDisableReorder?: boolean;
  allowedAdminActions?: ("shadowCopy" | "delete")[];
}

/*
 * TODO: refactor so this component is not used for Medication Highlights (in the knowledge tab).
 * It is difficult to maintain 2 very different domains with one component. This should only be for spaceOrg.
 * It is not obvious but this component is NOT used for knowledge content repo items, instead the paginated content item list is used.
 */

const ContentItemList = observer(
  ({
    contentRepositoryId: mainSectionId,
    subSectionId,
    isInAdminMode,
    doFlashcardsHaveSpecialties = false,
    shouldDisableReorder = false,
    allowedAdminActions = [],
  }: ContentItemListProps) => {
    const { t } = useTranslation();
    const { flags } = useLaunchDarkly();
    const {
      handleAddToSelection,
      handleClearSelection,
      handleRemoveFromSelection,
      selection,
      handleSelectAll,
    } = useEolasVersatileListSelection<ContentItem>();

    // If the subSectionId is passed use it, if it is not passed it means that the section is a main section
    const sectionId = subSectionId || mainSectionId;

    const { useContentItemsHook, useBulkDeleteItemsHook } = useContentRepositoryContext();
    const { contentItems, contentItemsLoading } = useContentItemsHook;
    const { bulkDeleteItems } = useBulkDeleteItemsHook;

    const spaceAdminLevel = useGetAdminStatus({ activeTab: "spaces" });
    const orgAdminLevel = useGetAdminStatus({ activeTab: "organisation" });
    const { activeTab } = useEolasNavigation();

    // TODO: remove this when separating out components
    const isMedicationHighlights = activeTab === "knowledge";

    const isDeleteAllowed = isInAdminMode && allowedAdminActions.includes("delete");

    let isFileSelectionEnabled = isDeleteAllowed;
    let isCopyableStatus: IsCopyableStatus | undefined = undefined;

    if (allowedAdminActions.includes("shadowCopy") && flags[LDFlagNames.SHADOW_COPYING]) {
      if (isInAdminMode) {
        isFileSelectionEnabled = true;
        isCopyableStatus = "all";
      } else {
        const isSpaceAdminViewingOrg =
          activeTab === "organisation" && isAccessLevelInAdminGroup(spaceAdminLevel);
        const isLimitedAdmin =
          spaceAdminLevel === "limitedAdmin" || orgAdminLevel === "limitedAdmin";
        if (isSpaceAdminViewingOrg || isLimitedAdmin) {
          isCopyableStatus = "fromTileDropDown";
        }
      }
    }

    const spaceOrgSearchProps = useSpaceOrgSearch({ shouldShowAdmin: isInAdminMode });

    const medHighlightsSearchProps = useLocalMobxSearch({
      mode: "fuse",
      data: contentItems,
      keysToSearch: ["name", "description", "keywords"],
    });

    const {
      searchInstanceId,
      handleClearSearch,
      handleSearchClick,
      searchResults,
      isSearchActive,
      isSearchLoading,
    } = isMedicationHighlights ? medHighlightsSearchProps : spaceOrgSearchProps;

    const shouldShowSearchResults = isSearchActive && !contentItemsLoading;
    const isDraggable = isInAdminMode && !shouldDisableReorder;

    const itemsForDisplay = useMemo(() => {
      if (shouldShowSearchResults) {
        return searchResults;
      }

      if (!isInAdminMode) {
        return contentItems.filter((item) => item.isDraft === false);
      }

      return contentItems;
    }, [searchResults, contentItems, shouldShowSearchResults, isInAdminMode]);

    const { reorderContent } = useReorderContent(sectionId);

    const onDropItem = (dropResult: EolasVersatileListOnDropResult) => {
      reorderContent(dropResult);
    };

    const listContext: ContentItemListContext = useMemo(
      () => ({
        isInitialLoading: contentItemsLoading,
        isInAdminMode,
        doFlashcardsHaveSpecialties,
        handleAddToSelection,
        handleRemoveFromSelection,
        isCopyableStatus,
        mode: "fullFeatures",
      }),
      [
        contentItemsLoading,
        isInAdminMode,
        doFlashcardsHaveSpecialties,
        handleAddToSelection,
        handleRemoveFromSelection,
        isCopyableStatus,
      ],
    );

    const handleOnClickCopyTo = (items: ContentItem[]) => {
      modalStore.openModal({
        name: "selectCopyTargetModal",
        variant: "component",
        Component: <CopyItemsModal initialItems={items} onCopySuccess={handleClearSelection} />,
      });
    };

    const handleOnClickMoveTo = (items: ContentItem[]) => {
      modalStore.openModal({
        name: "selectMoveTargetModal",
        variant: "component",
        Component: <MoveItemsModal initialItems={items} onMoveSuccess={handleClearSelection} />,
      });
    };

    const handleOnBulkDelete = (items: ContentItem[]) => {
      modalStore.openModal({
        name: "bulkDeleteConfirmation",
        variant: "dialogue",
        icon: "warning",
        title: t("repository_items_bulk_delete_title"),
        message: t("repository_items_bulk_delete_description", { count: items.length }),
        onConfirmAsync: async () => await bulkDeleteItems(items.map((item) => item.id)),
      });
    };

    const handleMenuAction = (event: ListMenuActionEvent<ContentItem>) => {
      if (event.type === "selectAll") {
        handleSelectAll(event.selectedItems);
      }

      if (event.type === "deselectAll") {
        handleClearSelection();
      }

      if (event.type === "copyTo") {
        handleOnClickCopyTo(Object.values(selection));
      }

      if (event.type === "moveTo") {
        handleOnClickMoveTo(Object.values(selection));
      }

      if (event.type === "delete") {
        handleOnBulkDelete(Object.values(selection));
      }
    };

    const listMenuActions: ListMenuAction[] = useMemo(() => {
      const actions: ListMenuAction[] = [];

      if (!isFileSelectionEnabled) {
        return actions;
      }

      if (isCopyableStatus === "all") {
        actions.push("copyTo");
        actions.push("moveTo");
      }

      if (isDeleteAllowed) {
        actions.push("delete");
      }

      if (actions.length > 1) {
        actions.push("selectAll");
      }

      return actions;
    }, [isFileSelectionEnabled, isDeleteAllowed, isCopyableStatus]);

    const { defaultSortMethod, onSortMethodChange } = useSectionSortMethod({
      sectionId,
      isInAdminMode,
    });

    if (isMedicationHighlights) {
      return (
        <div data-testid="content-item-list">
          <EolasVersatileList
            isSearchLoading={isSearchLoading}
            context={listContext}
            items={itemsForDisplay}
            isSearchable
            renderItem={renderContentItems}
            searchInstanceId={searchInstanceId}
            isDraggable={!isSearchActive ? isDraggable : false}
            onDragEnd={onDropItem}
            searchMode="click"
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            droppableId="content-item-list"
            ListEmptyComponent={isSearchActive ? NoSearchResults : undefined}
            className="space-y-6"
          />
        </div>
      );
    }

    return (
      <div data-testid="content-item-list">
        <EolasVersatileList
          isSearchLoading={isSearchLoading}
          defaultSortMethod={defaultSortMethod}
          context={listContext}
          items={itemsForDisplay}
          isSearchable
          renderItem={renderContentItems}
          searchInstanceId={searchInstanceId}
          sortDateBy="updatedAt"
          isSortable={!isSearchActive}
          disabledSortMethods={["favourites"]}
          isDraggable={!isSearchActive ? isDraggable : false}
          onDragEnd={onDropItem}
          searchMode="click"
          onClickSearch={handleSearchClick}
          onClearSearch={handleClearSearch}
          droppableId="content-item-list"
          isSelectable={listMenuActions.length > 0}
          selectedItems={selection}
          onMenuAction={handleMenuAction}
          menuActions={listMenuActions}
          ListEmptyComponent={isSearchActive ? NoSearchResults : undefined}
          className="space-y-6"
          onSortMethodChange={onSortMethodChange}
        />
      </div>
    );
  },
);

const NoSearchResults = () => <EmptyLocalList isSearchResult />;

export default ContentItemList;
