export interface ResultBoxProps {
  text: string;
  title: string;
  icon?: JSX.Element;
  details?: string[];
  detailsTitle?: string;
  detailsTitleStyle?: string;
  error?: string;
}

export const ResultBox = ({
  text,
  title,
  icon,
  error = "",
  detailsTitle = "Recommendation",
  detailsTitleStyle = "",
  details = [],
}: ResultBoxProps) => {
  return (
    <div className="mt-2 mb-4">
      <h2 className="font-bold mb-2 text-lg">{title}</h2>
      <div className="bg-grey-light rounded-lg py-2 px-4">
        <p className="flex items-center text-base">
          {icon}
          {text}
        </p>
        {error.length > 1 ? <p className="text-red text-base w-100 mt-2">{error}</p> : null}
      </div>
      {!error && details.length > 0 ? (
        <div className="mt-2">
          <div className="bg-grey-light rounded-lg py-2 px-4">
            <h2 className={`font-semibold mb text-lg ${detailsTitleStyle}`}>{detailsTitle}</h2>
            {details.map((description, index) => (
              <p key={`description_${description}_${index}`} className="text-base">
                {description}
              </p>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
