import { makeObservable, observable, action } from "mobx";
import { uniqueId } from "lodash";

class ProgressBarStore {
  constructor() {
    makeObservable(this);
  }

  @observable public progressBarMap: Partial<Record<string, number>> = {};

  @action initProgress = () => {
    const id = uniqueId();
    this.progressBarMap[id] = 0;
    return id;
  };

  @action updateProgress = ({ id, progress }: { id: string; progress: number }) => {
    if (this.progressBarMap[id] === undefined) {
      return;
    }
    this.progressBarMap[id] = progress;
  };

  @action cleanupProgress = (id: string) => {
    delete this.progressBarMap[id];
  };
}

export const progressBarStore = new ProgressBarStore();
