import { useTranslation } from "react-i18next";
import { error401, MailIcon } from "Assets";
import { InnerModalWrapper, Button, LottieWithHeader, Title, Text, ModalBody } from "UIKit";
import { observer } from "mobx-react-lite";
import { hasStringProp, sectionStore, userStore } from "@eolas-medical/core";
import useUserDetails from "Hooks/useUserDetails";
import { syncWebAppData } from "Hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logout } from "Pages/Authentication/auth.actions";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

const SoftSignOutModal = observer(() => {
  const { t } = useTranslation();
  const { isSharedAccount } = useUserDetails();
  const queryClient = useQueryClient();
  const { flags } = useLaunchDarkly();
  const getContentInParallel = flags[LDFlagNames.MAIN_SECTION_CONTENT_IN_PARALLEL];

  let spaceName = "";
  let email = "";

  if (hasStringProp(sectionStore.data.app, "name")) {
    spaceName = sectionStore.data.app.name;
  }

  if (userStore.userData.email) {
    email = userStore.userData.email;
  }

  const { isLoading, mutate: resumeSession } = useMutation({
    mutationKey: ["softSignIn"],
    mutationFn: async () => {
      await syncWebAppData({ isFromSoftLogoutState: true, queryClient, getContentInParallel });
    },
    onError: () => {
      logout();
    },
    onSuccess: () => {
      userStore.setLoggedStatus(true);
    },
  });

  return (
    <InnerModalWrapper>
      <ModalBody>
        <LottieWithHeader
          animationSize="50%"
          text=""
          animation={error401}
          lottieOptions={{ loop: true }}
        />
        <div className="flex flex-col items-center text-center mb-8">
          <Title level={5}>{t("session_expired_title_admin")}</Title>
        </div>
        <div data-testid="validate-password-modal" className="w-full">
          <div className="flex flex-col justify-center space-y-7">
            <div className="flex self-center items-center justify-center space-x-2 ml-3">
              {!isSharedAccount ? <MailIcon className="w-5 h-5" /> : null}
              <Text level={2}>
                {isSharedAccount ? t("soft_logout_continue_session", { spaceName }) : email}
              </Text>
            </div>
            <Button
              isLoading={isLoading}
              size="lg"
              type="submit"
              data-testid="sign-in-sign-in-button"
              className="self-center w-full md:w-1/2"
              onClick={() => resumeSession()}
            >
              {t("general_continue")}
            </Button>
          </div>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
});

export default SoftSignOutModal;
