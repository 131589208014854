import { ContentRepositoryId } from "modules/contentRepository/types";
import {
  ChildReference,
  ContentItem,
  FullAccessLevel,
  Maybe,
  isEolasShadowCopyFile,
  sectionStore,
} from "@eolas-medical/core";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { makeShouldIncludeSectionHelpers } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export enum ValidFileChildren {
  deepLink = "deepLink",
  file = "file",
}

export type UseGetAllContentRepositoryProps = {
  contentRepositoryId: ContentRepositoryId;
};

type IdMap = Record<string, string>;

const addToContentList = async ({
  currentSectionId,
  childrenOrder,
  finalItemList,
  finalItemIdMap,
  originalsOfShadowCopiesIdMap,
  isInAdminMode,
  shouldApplySectionFilterForTree,
  limitedAccess,
}: {
  currentSectionId: string;
  childrenOrder: ChildReference[];
  finalItemIdMap: IdMap;
  finalItemList?: Array<ContentItem>;
  originalsOfShadowCopiesIdMap: IdMap;
  isInAdminMode: boolean;
  shouldApplySectionFilterForTree: boolean;
  limitedAccess: string[];
}) => {
  let shouldIncludeSection: (sectionId: string) => boolean = () => true;
  let nextLevelShouldApplySectionFilterForTree = false;
  if (shouldApplySectionFilterForTree) {
    const { filterFnForChildren, shouldCurrentSectionAllowAdminRights } =
      makeShouldIncludeSectionHelpers(limitedAccess, currentSectionId);
    if (!shouldCurrentSectionAllowAdminRights) {
      shouldIncludeSection = filterFnForChildren;
      nextLevelShouldApplySectionFilterForTree = true;
    }
  }
  for (const child of childrenOrder) {
    if (!child) {
      continue;
    }

    if (!isValidFileChild(child.type)) {
      const nextLevelChildrenOrder = sectionStore
        .getChildrenOrder(child.id)
        .filter(({ id }) => shouldIncludeSection(id));

      await addToContentList({
        currentSectionId: child.id,
        childrenOrder: nextLevelChildrenOrder,
        finalItemList,
        finalItemIdMap,
        originalsOfShadowCopiesIdMap,
        isInAdminMode,
        shouldApplySectionFilterForTree: nextLevelShouldApplySectionFilterForTree,
        limitedAccess,
      });
      continue;
    }

    const file = await contentDbStore.getItem(child.id);

    if (!file) {
      continue;
    }

    if (child.type === ValidFileChildren.deepLink && isEolasShadowCopyFile(file)) {
      const originalFile = await getOriginalFromShadowCopy(file, false);
      if (!originalFile) {
        continue;
      }

      if (finalItemIdMap[originalFile.id] && !isInAdminMode) {
        continue;
      }

      originalsOfShadowCopiesIdMap[originalFile.id] = originalFile.id;
    }

    if (originalsOfShadowCopiesIdMap[file.id] && !isInAdminMode) {
      continue;
    }

    finalItemIdMap[file.id] = file.id;
    finalItemList?.push(mapToContentItem(file));
  }
};

export const isValidFileChild = (type: Maybe<string>): type is ValidFileChildren => {
  if (!type) {
    return false;
  }
  return Object.values<string>(ValidFileChildren).includes(type);
};

type BaseProps = {
  isInAdminMode: boolean;
  adminStatus: FullAccessLevel | null;
  sectionId: string;
  limitedAccess: string[];
};

export async function getIdMapToIncludeForContentSearch(
  props: BaseProps & { mode: "map" },
): Promise<{ map: Record<string, string> }>;
export async function getIdMapToIncludeForContentSearch(
  props: BaseProps & { mode: "list" },
): Promise<{ list: ContentItem[] }>;
export async function getIdMapToIncludeForContentSearch({
  isInAdminMode,
  adminStatus,
  limitedAccess,
  sectionId,
  mode,
}: BaseProps & {
  mode: "list" | "map";
}): Promise<{ list: ContentItem[] } | { map: Record<string, string> }> {
  let shouldApplySectionFilterForTree = isInAdminMode && adminStatus === "limitedAdmin";
  let topLevelFilterFn: (sectionId: string) => boolean = () => true;

  if (shouldApplySectionFilterForTree) {
    const { filterFnForChildren, shouldCurrentSectionAllowAdminRights } =
      makeShouldIncludeSectionHelpers(limitedAccess, sectionId);
    if (shouldCurrentSectionAllowAdminRights) {
      shouldApplySectionFilterForTree = false;
    } else {
      topLevelFilterFn = filterFnForChildren;
    }
  }

  const finalItemList: Array<ContentItem> | undefined = mode === "list" ? [] : undefined;
  const finalItemIdMap: IdMap = {};
  const originalsOfShadowCopiesIdMap: IdMap = {};

  const childrenOrder = sectionStore
    .getChildrenOrder(sectionId)
    .filter(({ id }) => topLevelFilterFn(id));

  if (!childrenOrder.length) {
    return { map: {}, list: [] };
  }

  await addToContentList({
    currentSectionId: sectionId,
    childrenOrder,
    finalItemList,
    finalItemIdMap,
    originalsOfShadowCopiesIdMap,
    isInAdminMode,
    shouldApplySectionFilterForTree,
    limitedAccess,
  });

  if (mode === "list") {
    return { list: finalItemList ?? [] };
  }

  return { map: finalItemIdMap };
}
