import { sectionStore, userStore } from "@eolas-medical/core";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useSpacesContext } from "modules/spaces";
import { useQueryClient } from "@tanstack/react-query";
import { BaseAppRoute } from "Utilities/types";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export const useSignoutSpace = ({ shouldPushNewRoute }: { shouldPushNewRoute: boolean }) => {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const { onClearSpace } = useSpacesContext();

  const signoutSpace = useCallback(() => {
    sectionStore.clearStore();
    contentDbStore.clearStore();
    localSearchStore.clearStore();
    sectionStore.setIsAdmin(false);
    userStore.signOutOrganisation();
    queryClient.clear();
    onClearSpace();
    if (shouldPushNewRoute) {
      push(`/${BaseAppRoute.selectSpace}`);
    }
  }, [push, onClearSpace, shouldPushNewRoute, queryClient]);

  return { signoutSpace };
};

export let signoutSpaceCallBack: (() => void) | null = null;

// For use in AppRoutes to allow for signoutSpace to be called from outside of react:
export const useSignoutSpaceListener = () => {
  const { signoutSpace } = useSignoutSpace({ shouldPushNewRoute: true });
  const { appID } = sectionStore;

  useEffect(() => {
    if (!appID) {
      return;
    }
    signoutSpaceCallBack = signoutSpace;
    return () => {
      signoutSpaceCallBack = null;
    };
  }, [signoutSpace, appID]);
};
