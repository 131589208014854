import {
  AnalyticsEvents,
  ClinicalQuestionReferencePayload,
  clinicalQuestionStore,
  eolasLogger,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { makeProcessNode, makeShouldProcessNode } from "shared/functions/makeHtmlToReactNodeParser";

export const getResponseProcessingInstructions = (questionID: string) => [
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "ul" }),
    processNode: makeProcessNode({
      tagName: "ul",
      classNameToAdd: "list-disc px-8 text-base",
      attributesToAdd: ["class"],
    }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "h3" }),
    processNode: makeProcessNode({
      tagName: "h3",
      classNameToAdd: "text-green-500 font-bold text-lg mt-8 mb-4",
      attributesToAdd: ["class"],
    }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "h4" }),
    processNode: makeProcessNode({
      tagName: "h4",
      classNameToAdd: "text-red-600 font-bold text-md mb-2 mt-4",
    }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "p" }),
    processNode: makeProcessNode({
      tagName: "p",
      classNameToAdd: "text-base mb-2",
      attributesToAdd: ["class"],
    }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "li" }),
    processNode: makeProcessNode({ tagName: "li", classNameToAdd: "text-base my-0.5" }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "a" }),
    processNode: makeProcessNode({
      tagName: "a",
      classNameToAdd: "text-base text-blue-500 hover:text-blue-700 underline",
      attributesToAdd: ["id", "data-text-highlight"],
      onClick: (event) => {
        if (
          event.currentTarget.href.includes("clinicalQuestions") &&
          event.currentTarget.href.includes("ref-")
        ) {
          trackEvent<ClinicalQuestionReferencePayload>(
            AnalyticsEvents.CLINICAL_QUESTION_JUMP_LINK_CLICKED,
            { questionID },
          );
        } else {
          const highlightText = event.currentTarget.getAttribute("data-text-highlight");
          if (highlightText) {
            clinicalQuestionStore.setTextToHighlight(highlightText);
          }
          trackEvent<ClinicalQuestionReferencePayload>(
            AnalyticsEvents.CLINICAL_QUESTION_REFERENCE_CLICKED,
            {
              questionID,
              url: event.currentTarget.href,
            },
          );
        }
      },
    }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "button" }),
    processNode: makeProcessNode({
      tagName: "button",
      classNameToAdd: "open caret",

      onClick: (event) => {
        if (event.currentTarget.classList.contains("closed")) {
          event.currentTarget.classList.remove("closed");
          event.currentTarget.classList.add("open");
        } else {
          event.currentTarget.classList.remove("open");
          event.currentTarget.classList.add("closed");
        }
        const parentElement = event.currentTarget.parentElement;
        if (parentElement?.classList.contains("ref-title-container")) {
          const snippets = parentElement.parentElement?.querySelectorAll(".snippet-box");
          snippets?.forEach((snippet) => snippet.classList.toggle("hidden"));
        } else if (
          parentElement?.classList.contains("reasoning-title-container") ||
          parentElement?.classList.contains("useful-links-title-container")
        ) {
          parentElement?.nextElementSibling?.classList.toggle("hidden");
        } else {
          eolasLogger.error("Unable to collapse element in Ask Eolas response", { parentElement });
        }
      },
    }),
  },
];
