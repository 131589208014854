import { motion, Variants } from "framer-motion";
import {
  AppLevelSection,
  OrganisationLevelSection,
  sectionStore,
  userStore,
} from "@eolas-medical/core";

import { CaretRight } from "Assets/Icons";

import { Title, Text } from "../Typography";
import { useMemo } from "react";

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

export interface ClientMainSectionTileProps {
  title: string;
  description: string;
  id: AppLevelSection | OrganisationLevelSection;
  Icon?: React.ReactNode;
  disabled?: boolean;
}

export const ClientMainSectionTile: React.FC<ClientMainSectionTileProps> = ({
  id,
  title,
  description,
  Icon,
  disabled,
}: ClientMainSectionTileProps) => {
  const section = sectionStore.getChildReferenceByMainSectionType(id);
  const commsFileChildrenOrder = section && sectionStore.getChildrenOrder(section.id);

  const attentionBorder = useMemo(() => {
    const isCommsTile = [
      AppLevelSection.newsFeed,
      OrganisationLevelSection.communicationPortal,
    ].includes(id);

    if (isCommsTile && commsFileChildrenOrder) {
      const allItemsRead = commsFileChildrenOrder.every((element) =>
        userStore.isFileCompleted(element.id),
      );
      return allItemsRead ? undefined : "bg-red-50";
    }
  }, [commsFileChildrenOrder, id]);

  const renderTile = () => (
    <>
      {!disabled ? (
        <div
          className={`
          flex items-center justify-center rounded-full
          h-14 w-14 lg:h-24 lg:w-24
          bg-grey-100 group-hover:bg-blue-50
        `}
        >
          {Icon}
        </div>
      ) : null}

      <div className="hidden lg:flex flex-col items-center space-y-4 mt-4 text-center">
        <Title level={5} className="line-clamp-2" title={title}>
          {title}
        </Title>

        <Text level={1} className="line-clamp-2 text-grey-700 font-semibold" title={description}>
          {description}
        </Text>
      </div>

      <div className="flex lg:hidden flex-col justify-center flex-1 space-y-1">
        <Text level={1} className="line-clamp-1 font-bold">
          {title}
        </Text>

        <Text level={3} className="line-clamp-2 text-grey-700 font-normal">
          {description}
        </Text>
      </div>

      <div className="flex lg:hidden justify-center items-center h-7 w-7 rounded-full bg-grey-100">
        <CaretRight className="h-6 w-6 text-blue-500" />
      </div>
    </>
  );

  if (!disabled) {
    return (
      <motion.div
        variants={childrenVariants}
        whileHover={{ scale: 1.03 }}
        data-testid={`mainSectionButton-${id}`}
        transition={{ type: "spring", stiffness: 98, damping: 10 }}
        className={`
        flex lg:flex-col items-center justify-center rounded-lg lg:rounded-xl
        space-x-4 lg:space-x-0
        px-4
        bg-white tile-border group h-24 lg:h-72 hover:shadow-md
        ${attentionBorder}
      `}
      >
        {renderTile()}
      </motion.div>
    );
  }

  return (
    <div
      className={`
        flex lg:flex-col items-center justify-center rounded-lg lg:rounded-xl
        space-x-4 lg:space-x-0
        px-4
        bg-white group h-24 lg:h-72
      `}
    >
      {renderTile()}
    </div>
  );
};
