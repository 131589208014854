import { useRef, useState } from "react";
import Smartlook from "smartlook-client";

import {
  userStore,
  sectionStore,
  profilesClient,
  AnalyticsEvents,
  getFilesByMainSectionsAndUpdatedAt,
  contentClient,
  authenticationClient,
  eolasLogger,
  spacesClient,
  getCompletedChecklistsAndIncidentReportFiles,
  getMedusaSections,
} from "@eolas-medical/core";
import { useLaunchDarkly, useSentry } from "Contexts";
import { trackEvent } from "API/Analytics";
import { FindSpaceStep, SearchSpaceResult } from "../types";
import { useRequestAccess, useShouldAllowAccess } from "modules/spaces/data";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { addRetries } from "Utilities/helpers";
import {
  AccessRequestStatus,
  LimitedDataObject,
  ShouldAllowUserAccessResponse,
} from "@eolas-medical/core/lib/src/clients/access/access.types";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { useNavigateToSpace } from "./useNavigateToSpace";
import { useMutation } from "@tanstack/react-query";
import {
  addToFileDbCallback,
  removeFromFileDbCallback,
  updateMedusaSections,
  upsertChecklistsToSearchDb,
} from "../../Space/functions/fetchCallbacks";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { useSignoutSpace } from "Hooks/useSignoutSpace";
import {
  closeWholeAppLoaderModal,
  openWholeAppLoaderModal,
} from "shared/functions/openWholeAppLoaderModal";
import { useTranslation } from "react-i18next";
import { LDFlagNames } from "Utilities/types";
import { progressBarStore } from "UIKit/EolasProgressBar/stores/progressBar.store";
import { makeDownloadProgressCallback } from "UIKit/EolasProgressBar/functions/makeProgressCallback";

type AccessDeniedResponse = {
  result: "denied";
  status: AccessRequestStatus.NO_APP_ACCESS | AccessRequestStatus.NO_TRUSTED_EMAIL;
  data: LimitedDataObject;
  didError: false;
};

type AccessGrantedResponse = {
  result: "granted";
  status:
    | AccessRequestStatus.EXISTING_APP_USER
    | AccessRequestStatus.APP_USER_CREATED
    | AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED;
  didError: false;
};

type AccessPendingResponse = {
  result: "pending";
  status: AccessRequestStatus.ACCESS_REQUESTED;
  data: LimitedDataObject;
  didError: false;
};

type AccessResponse = AccessDeniedResponse | AccessGrantedResponse | AccessPendingResponse;

export type OnSelectSpaceResponse = AccessResponse | { result: "denied"; didError: true };

export const useSelectSpaceActions = (initialState?: FindSpaceStep, forwardUrl?: string) => {
  const { userID = "" } = userStore;
  const { addBreadcrumb } = useSentry();
  const { onSelectSpace: onSelectSpaceAction } = useSpacesContext();
  const { navigateToSpace } = useNavigateToSpace();
  const { signoutSpace } = useSignoutSpace({ shouldPushNewRoute: !forwardUrl });
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const getContentInParallel = flags[LDFlagNames.MAIN_SECTION_CONTENT_IN_PARALLEL];

  const { shouldAllowUserAccess, shouldAllowAccessLoading, userAccess, error } =
    useShouldAllowAccess();

  const { requestAccess, requestingAccess, requestAccessSuccessful } = useRequestAccess(userID);

  const [selectSpaceStep, setSelectSpaceStep] = useState<FindSpaceStep>(
    initialState ?? "select-space",
  );

  const selectedAppRef = useRef<SearchSpaceResult>({ appID: "", name: "", orgID: "" });

  const onAllowAccessSuccess = async (
    res: ShouldAllowUserAccessResponse,
  ): Promise<OnSelectSpaceResponse> => {
    const { status, data } = res;
    let progressBarId: string | null = null;

    if (
      status === AccessRequestStatus.EXISTING_APP_USER ||
      status === AccessRequestStatus.APP_USER_CREATED ||
      status === AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED
    ) {
      progressBarId = getContentInParallel ? progressBarStore.initProgress() : null;

      openWholeAppLoaderModal({ text: t("longLoadingLoginIn"), progressBarId });

      const { appUser, sections, app, organisation } = data;

      if (app && appUser) {
        addBreadcrumb({
          category: "Select Organisation",
          message: "Selecting the app and updating the store...",
        });

        try {
          await authenticationClient.getToken({ forceRefresh: true });
          sectionStore.setStoreFromApi({ app, organisation, sections }, true);

          const [refreshedProfile, completedContent, orgUserData, medusaSectionsWithItems] =
            await addRetries(() =>
              Promise.all([
                profilesClient.getUserProfile(userStore.userID!),
                contentClient.getCompletedContentItems({
                  userId: userStore.userID,
                  spaceId: app.id,
                  organisationId: app.organisationID,
                }),
                spacesClient.getOrgSpaceUserInfo({ spaceId: app.id }),
                getMedusaSections(organisation),
              ]),
            )();

          // REST endpoint returns legacy content which can cause errors, so remove:
          delete appUser.completedFiles;
          delete appUser.completedFilesMap;

          userStore.updateData({
            orgUserData: orgUserData ?? undefined,
            appUser,
            user: refreshedProfile,
            completedContent,
          });

          await localSearchStore.initialiseDb({ isNew: true });

          await getFilesByMainSectionsAndUpdatedAt({
            updatedAt: undefined,
            onAddFile: addToFileDbCallback,
            onRemoveFile: removeFromFileDbCallback,
            getContentInParallel,
            onProgress: progressBarId ? makeDownloadProgressCallback(progressBarId) : undefined,
          });

          await upsertChecklistsToSearchDb(sections);

          if (medusaSectionsWithItems.length) {
            await updateMedusaSections(medusaSectionsWithItems);
            sectionStore.setStoreFromApi({
              medusaSectionsWithItems,
            });
          }

          await localSearchStore.persistDb();

          contentDbStore.setLastUpdatedTimestamp(new Date().toISOString());

          userStore.signInOrganisation({});

          if (process.env.REACT_APP_SENTRY_ENABLED !== "false" && refreshedProfile.id) {
            Smartlook.identify(refreshedProfile.id, { displayName: appUser.id });
          }
          onSelectSpaceAction(app);
          navigateToSpace(app.name, forwardUrl);

          if (appUser.accessLevel === "admin") {
            contentDbStore.setIsAddingChecklistFiles(true);
            getCompletedChecklistsAndIncidentReportFiles({
              updatedAt: undefined,
              onAddFile: addToFileDbCallback,
              onRemoveFile: removeFromFileDbCallback,
              getContentInParallel,
            })
              .then((result) => {
                if (result) {
                  contentDbStore.setLastUpdatedTimestamp(new Date().toISOString());
                }
              })
              .catch(eolasLogger.error)
              .finally(() => {
                contentDbStore.setIsAddingChecklistFiles(false);
              });
          }

          trackEvent(AnalyticsEvents.EOLAS_SESSION_START);
          return { result: "granted", status, didError: false };
        } catch (error: unknown) {
          signoutSpace();
          eolasLogger.error(new Error("Error when signing into space"), { error });
          return { result: "denied", didError: true };
        } finally {
          closeWholeAppLoaderModal();
          if (progressBarId) {
            progressBarStore.cleanupProgress(progressBarId);
          }
        }
      }
    }

    if (status === AccessRequestStatus.NO_APP_ACCESS) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "No app access",
      });

      setSelectSpaceStep("request-access");
      return { result: "denied", status, data: res.limitedData, didError: false };
    }

    if (status === AccessRequestStatus.ACCESS_REQUESTED) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "App request access sent",
      });

      setSelectSpaceStep("request-sent");
      return { result: "pending", status, data: res.limitedData, didError: false };
    }

    if (status === AccessRequestStatus.NO_TRUSTED_EMAIL) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "App request access sent",
      });

      setSelectSpaceStep("no-trusted-email");
      return { result: "denied", status, data: res.limitedData, didError: false };
    }

    return { result: "denied", didError: true };
  };

  const { mutateAsync: signIntoSpace, isLoading: isLoadingSignIntoSpace } = useMutation({
    mutationFn: onAllowAccessSuccess,
    mutationKey: ["fetchSpaceDataAndSignIntoSpace"],
  });

  const onSelectSpace = async (spaceId: string): Promise<OnSelectSpaceResponse> => {
    addBreadcrumb({
      category: "Select Organisation",
      message: "Selecting organisation",
    });

    return new Promise<OnSelectSpaceResponse>((resolve) => {
      shouldAllowUserAccess(spaceId, {
        onSuccess: async (data) => {
          const res = await signIntoSpace(data);
          resolve(res);
        },
        onError: () => {
          resolve({ result: "denied", didError: true });
        },
      });
    });
  };

  const onRequestAccess = async (spaceId: string, requestText?: string) => {
    addBreadcrumb({
      category: "Select Organisation",
      message: `Requesting access to organisation: ${selectedAppRef.current.appID}`,
    });

    trackEvent(AnalyticsEvents.ORGANISATION_REQUEST_ACCESS);
    if (userID.length) {
      requestAccess(
        { requestText, spaceId },
        {
          onSuccess: () => {
            setSelectSpaceStep("request-sent");
          },
        },
      );
    }
  };

  return {
    error,
    requestingAccess,
    requestAccessSuccessful,
    shouldAllowAccessLoading: shouldAllowAccessLoading || isLoadingSignIntoSpace,
    selectedAppRef,
    selectSpaceStep,
    userAccess,
    setSelectSpaceStep,
    onSelectSpace,
    onRequestAccess,
    shouldAllowUserAccess,
  };
};
