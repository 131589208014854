import { useFileOperations, useRefetchAppData } from "Hooks";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { DeleteModal, DeleteModalProps } from "UIKit";
import { contentClient, EolasFile } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export interface DeleteFileModalProps extends Partial<DeleteModalProps> {
  file: EolasFile;
  onCloseModal(): void;
}

export const DeleteFileModal: React.FC<DeleteFileModalProps> = ({
  file,
  onCloseModal,
  ...props
}: DeleteFileModalProps) => {
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();
  const { removeFile } = useFileOperations();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const onDelete = () => {
    setRequestStatus({ status: "pending", error: "" });

    const removeFilePromise = useAppServicesEndpoints
      ? removeFile({
          variables: {
            id: file.id,
            parentID: file.parentID,
          },
        })
      : contentClient.deleteContentItem({
          mainSectionId: file.mainSectionID,
          itemId: file.id,
        });

    removeFilePromise
      .then(async () => {
        refetch();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: removeFile,
          retryParameters: {
            variables: {
              id: file.id,
              parentID: file.parentID,
            },
          },
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  };

  return (
    <DeleteModal
      error={error}
      onDelete={onDelete}
      isLoading={isLoading}
      isSuccessful={isSuccessful}
      onCloseModal={onCloseModal}
      title={file.name as string}
      {...props}
    />
  );
};
