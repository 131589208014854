import { useCallback } from "react";
import {
  AnalyticsEvents,
  EolasFile,
  OrganisationLevelSection,
  ShadowCopyOpenedPayload,
  isEolasFile,
  isEolasShadowCopyFile,
} from "@eolas-medical/core";
import { useSelectFile, useSelectFileModal } from "Hooks";
import { SearchResult } from "../types";
import { isKnowledgeSearchResult, isLocalSearchResult } from "../functions/typeguards";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { trackEvent } from "API/Analytics";
import { useMedusaFile } from "Pages/Spaces/pages/Space/pages/MiniApp/MedusaMedications/useMedusaFile";

interface UseSelectResult {
  modal: React.ReactNode;
  onSelectResult: (result: SearchResult) => void;
  onCloseModal: () => void;
}

const useSelectResult = (): UseSelectResult => {
  const { modal, onSelectFile, onCloseModal } = useSelectFileModal({
    from: "masterSearch",
  });
  const { onSelectFile: selectFile } = useSelectFile();
  const { onSelectMedusaFile } = useMedusaFile();

  const onSelectResult = useCallback(
    async (result: SearchResult) => {
      if (isLocalSearchResult(result)) {
        if (isEolasFile(result.file) && isEolasShadowCopyFile(result.file)) {
          const original = await getOriginalFromShadowCopy(result.file);
          if (original) {
            trackEvent<ShadowCopyOpenedPayload>(AnalyticsEvents.SHADOW_COPY_OPENED, {
              origin: "masterSearch",
              fileId: result.file.id,
            });
            onSelectFile(original, result.file.id);
            return;
          }
        }
        const {
          iconName,
          file: { id, key, name },
        } = result;

        if (iconName === OrganisationLevelSection.medusaMedications) {
          if (!id || !key || !name) {
            throw new Error(
              "Missing required fields for handling click on medusa result, this should never happen",
            );
          }
          onSelectMedusaFile({ id, name, url: key });
        } else {
          onSelectFile(result.file as EolasFile);
        }
      }

      if (isKnowledgeSearchResult(result)) {
        if (result.searchSubType === "clinicalCalculators") {
          // TODO: when merging new calculators use onSelectFile
          selectFile(result.knowledgeFile);
          return;
        }
        onSelectFile(result.knowledgeFile);
      }
    },
    [onSelectFile, selectFile, onSelectMedusaFile],
  );

  return {
    modal,
    onSelectResult,
    onCloseModal,
  };
};

export default useSelectResult;
