import React, { useMemo } from "react";
import { Variants, motion } from "framer-motion";
import {
  Dropdown,
  DropdownOption,
  FileTypeItemIcon,
  IconButton,
  Text,
  Title,
  FavouriteToggle,
} from "UIKit";
import {
  AddFileCheckIcon,
  ClockIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  EditMonoIcon,
  LinkIcon,
  MoveIcon,
  OperationsIcon,
  RedirectIcon,
  SendOutlineIcon,
  UnpublishIcon,
} from "Assets";
import { format, isValid, parseISO } from "date-fns";
import { getImageUrl } from "Utilities";
import { Action, LDFlagNames } from "Utilities/types";
import {
  CommunityLevelSection,
  ContentItem,
  EolasFile,
  FlashCardHeroImage,
  SupportedFileType,
  isBlobContentItem,
  isFlashCardContentItem,
  sectionStore,
} from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { isSmpcContentItem } from "modules/myFavourites/typeguards";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";
import { SelectFileToggleProps } from "UIKit/Button/SelectFileToggle";
import { EolasFileTile } from "UIKit/EolasFileTile/EolasFileTile";
import { useToolTip } from "Hooks/useToolTip";
import { isShadowCopyContentItem } from "../../functions/typeguards";
import { useLaunchDarkly } from "Contexts";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { AdminFileTile } from "UIKit/EolasFileTile/EolasFileTile.types";

interface ContentItemTileProps {
  eolasFile: EolasFile | null;
  item: ContentItem;
  isInAdminMode: boolean;
  contentType: SupportedFileType;
  description?: string | null;
  date?: string | null;
  "data-testid"?: string;
  isDraggable?: boolean;
  isDragging?: boolean;
  dateLabel?: string;
  onShare?: () => void;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onShowVersions?: () => void;
  onDownload?: () => void;
  onPublishDraft: () => void;
  onCopy?: () => void;
  onMove?: () => void;
  onGoToOriginal?: () => void;
  onUnpublish?: () => void;
  shouldAllowFavourite?: boolean;
  shouldTruncateDescription?: boolean;
  shouldShowDate?: boolean;
  imageUrl: string | null;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
  isSearchResult?: boolean;
}

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const ICON_SIZE = 16;

const ContentItemTile = ({
  eolasFile,
  isInAdminMode,
  contentType,
  description,
  date,
  "data-testid": dataTestId,
  isDraggable,
  isDragging,
  dateLabel = "Date",
  onClick,
  onDelete,
  onShowVersions,
  onEdit,
  onDownload,
  imageUrl,
  onPublishDraft,
  onShare,
  showRemoveFavouriteModal,
  onUnpublish,
  item,
  isSelected,
  onCheckboxClick,
  shouldAllowFavourite,
  onCopy,
  onMove,
  onGoToOriginal,
  isSearchResult = false,
  shouldTruncateDescription = true,
  shouldShowDate = true,
}: ContentItemTileProps & Partial<SelectFileToggleProps>): JSX.Element => {
  const { name, id, isDraft: isUnpublished } = item;
  const { t } = useTranslation();
  const { makeToolTipDataAttr } = useToolTip();
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick && onClick();
  };
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(id);
  const { flags } = useLaunchDarkly();
  const { activeTab } = useEolasNavigation();

  const shouldShowFavouriteToggle = isMarkFavouriteEnabled && shouldAllowFavourite;
  const isItemShadowCopy = isShadowCopyContentItem(item);

  const canEdit = Boolean(onEdit && (contentType !== "dsm" || flags[LDFlagNames.DSM_EDITING]));

  let status: AdminFileTile["status"] = isUnpublished ? "unpublished" : "published";

  if (eolasFile && eolasFile.hasDrafts) {
    status = "parallelDraft";
  }

  const handleActionMenuSelect = (option: DropdownOption) => {
    if (option.value === "delete") {
      onDelete && onDelete();
    }

    if (option.value === "edit") {
      onEdit && onEdit();
    }

    if (option.value === "download") {
      onDownload && onDownload();
    }

    if (option.value === "publish") {
      onPublishDraft();
    }

    if (option.value === "copy") {
      onCopy && onCopy();
    }

    if (option.value === "move") {
      onMove && onMove();
    }

    if (option.value === "goToOriginal") {
      onGoToOriginal && onGoToOriginal();
    }

    if (option.value === "versions") {
      onShowVersions && onShowVersions();
    }

    if (option.value === "share") {
      onShare?.();
    }

    if (option.value === "unpublish") {
      onUnpublish?.();
    }
  };

  const menuOptions = useMemo(() => {
    const options: DropdownOption[] = [];

    if (canEdit) {
      options.push({
        label:
          status === "parallelDraft" || status === "unpublished"
            ? t("content_item_edit_draft")
            : Action.EDIT,
        value: "edit",
        icon: <EditMonoIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: isItemShadowCopy,
        disabledReason: t("content_item_shadow_copy_disabled_edit_tooltip"),
      });
    }

    if (isInAdminMode && onShare) {
      options.push({
        label: t("component_eolasVersatileList_create_link"),
        value: "share",
        icon: <SendOutlineIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: status === "unpublished",
        disabledReason: t("content_item_disabled_share_tooltip"),
      });
    }

    if (isInAdminMode && status === "unpublished") {
      options.push({
        label: t("content_item_publish"),
        value: "publish",
        icon: <AddFileCheckIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (onCopy) {
      options.push({
        label: t("component_eolasVersatileList_create_shortcut"),
        value: "copy",
        icon: <CopyIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: isItemShadowCopy || status === "unpublished",
        disabledReason: t("content_item_shadow_copy_disabled_copy_tooltip"),
      });
    }

    if (onMove) {
      options.push({
        label: t("component_eolasVersatileList_move_to"),
        value: "move",
        icon: <MoveIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (isItemShadowCopy && onGoToOriginal) {
      options.push({
        label: t("content_item_options_go_to_original"),
        value: "goToOriginal",
        icon: <RedirectIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (isBlobContentItem(item) && onDownload) {
      options.push({
        label: Action.DOWNLOAD,
        value: "download",
        icon: <DownloadIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }
    if (
      onShowVersions &&
      flags[LDFlagNames.VERSION_HISTORY] &&
      (activeTab === "spaces" || activeTab === "organisation")
    ) {
      options.push({
        label: t("version_history"),
        value: "versions",
        icon: <ClockIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: isItemShadowCopy,
      });
    }

    if (
      isInAdminMode &&
      eolasFile &&
      !isItemShadowCopy &&
      (status === "published" || status === "parallelDraft")
    ) {
      options.push({
        label: t("content_item_unpublish"),
        value: "unpublish",
        icon: <UnpublishIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (onDelete) {
      options.push({
        label:
          status === "parallelDraft" || status === "unpublished"
            ? t("content_item_delete_draft")
            : Action.DELETE,
        value: "delete",
        icon: <DeleteIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    return options;
  }, [
    onDelete,
    onDownload,
    onShowVersions,
    isInAdminMode,
    t,
    onCopy,
    onMove,
    isItemShadowCopy,
    onGoToOriginal,
    flags,
    activeTab,
    canEdit,
    onShare,
    status,
    eolasFile,
    item,
  ]);

  const parsedDate = !!date && date !== "null" ? parseISO(date) : undefined;

  const favouriteOrigin = isSmpcContentItem(item.metadata)
    ? CommunityLevelSection.communityMedications
    : sectionStore.getMainSectionIdentityByMainSectionId(item.mainSectionId);

  if (isInAdminMode) {
    const shouldShowExpiryDate =
      !!parsedDate && isValid(parsedDate) && !isFlashCardContentItem(item);

    return (
      <motion.div variants={childrenVariants} data-testid={dataTestId}>
        <EolasFileTile
          variant="admin"
          onClick={handleClick}
          title={name}
          expiryDate={shouldShowExpiryDate ? parsedDate : undefined}
          iconComponent={
            <div className="flex items-center justify-center h-full w-full">
              <FileTypeItemIcon type={contentType} className="w-12 h-12" />
            </div>
          }
          isDraggable={isDraggable}
          status={status}
          rightLabelTagTooltipText={{
            parallelDraft: t("content_item_parallel_draft_tooltip"),
            unpublished: t("content_item_unpublished_tooltip"),
            published: t("content_item_published_tooltip"),
          }}
          isSelected={isSelected}
          onToggleSelect={onCheckboxClick}
          menuActions={menuOptions}
          onMenuActionSelect={handleActionMenuSelect}
          isShadowCopy={isItemShadowCopy}
          isSearchResult={isSearchResult}
          mainSectionId={item.mainSectionId}
          parentSectionId={item.parentId}
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={`grid grid-cols-12 transition-all rounded-lg shadow-sm ${
        onClick ? "cursor-pointer" : ""
      } ${isDragging ? "tile-border__dragging" : "tile-border"}`}
      // FIXME: For some reason tailwind isn't applying min-h-40 to the div??
      style={{ minHeight: "10rem" }}
      onClick={handleClick}
      data-testid={dataTestId}
    >
      <div className="row-start-1 col-start-1 col-end-4 lg:col-end-3 flex bg-blue-50 rounded-l-lg relative">
        {imageUrl && imageUrl !== FlashCardHeroImage.blankImage ? (
          <img
            src={getImageUrl(imageUrl)}
            srcSet={getImageUrl(imageUrl)}
            alt=""
            className="w-full h-full object-cover rounded-l-lg"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <FileTypeItemIcon type={contentType} className="w-12 h-12" />
          </div>
        )}
      </div>

      <div
        className={`row-start-1 col-start-4 ${
          !isInAdminMode ? "col-end-12" : "col-end-10"
        } lg:col-start-3 flex flex-col justify-between py-5 pl-5 pr-0`}
      >
        <div>
          <div className="flex flex-row items-center gap-2">
            <div className="flex flex-col">
              {isSearchResult ? (
                <FileBreadcrumbs
                  shouldShowSpaceName={false}
                  mainSectionId={item.mainSectionId}
                  parentId={item.parentId}
                />
              ) : null}
              <Title className="line-clamp-2" level={9}>
                {name}
              </Title>
            </div>
            {isItemShadowCopy ? (
              <LinkIcon
                className="h-5 w-5 text-grey-600"
                {...makeToolTipDataAttr({
                  text: t("component_admin_file_tile_shadow_copy_tooltip"),
                })}
              />
            ) : null}
          </div>
          {description ? (
            <Text
              level={1}
              className={`text-grey-700 ${shouldTruncateDescription ? "line-clamp-2" : ""}`}
            >
              {description}
            </Text>
          ) : null}
        </div>

        {shouldShowDate && parsedDate && isValid(parsedDate) ? (
          <Text level={1} className="font-bold text-xs">
            {dateLabel}
            <span className="font-semibold ml-3">
              {format(new Date(parsedDate), "do MMMM yyyy")}
            </span>
          </Text>
        ) : null}
      </div>
      <div className="row-start-1 row-end-2 col-start-13 flex p-5 flex-col items-end justify-start">
        {menuOptions.length > 0 ? (
          <Dropdown
            dropdownToggle={
              <IconButton
                type="button"
                variant="rounded"
                icon={<OperationsIcon />}
                className="bg-transparent"
              />
            }
            options={menuOptions}
            onSelect={handleActionMenuSelect}
          />
        ) : null}
        {showRemoveFavouriteModal && shouldShowFavouriteToggle ? (
          <div className="self-end">
            <FavouriteToggle
              isLoading={isMarkingAsFavourite}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: id,
                      favouriteId,
                      title: name,
                      entityType: isSmpcContentItem(item.metadata)
                        ? FavouriteEntityType.SMPC_CONTENT
                        : FavouriteEntityType.FILE,
                      originForAnalytics: favouriteOrigin ?? undefined,
                    })
                  : handleAddFavourite({
                      item,
                      entityType: isSmpcContentItem(item.metadata)
                        ? FavouriteEntityType.SMPC_CONTENT
                        : FavouriteEntityType.FILE,
                    })
              }
              entityId={id}
            />
          </div>
        ) : null}

        {onShare ? (
          <div className="self-end">
            <ShareFileButton id={id} onShareFile={onShare} />
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default ContentItemTile;
