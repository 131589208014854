import { Route, RouteProps } from "react-router-dom";
import { AppLevelSection } from "@eolas-medical/core";

import { BaseAppRoute, LDFlagNames } from "Utilities/types";
import ManageUsers from "./pages/ManageUsers/ManageUsers";
import { AdminMeetTheTeam } from "./pages/MiniApp/MeetTheTeam";
import { HighlightsAdmin } from "./pages/MiniApp/Highlights";
import { TheDepartment } from "./pages/MiniApp/TheDepartment";
import { SimpleSection } from "./pages/SimpleSections";
import { Wellbeing, WellbeingSection } from "./pages/Wellbeing";
import { AdminIncidentReporting } from "./pages/MiniApp/IncidentReporting";
import { GuidelinesSection, Guidelines } from "./pages/Guidelines";
import { DynamicPage, adminDynamicPageData } from "./pages/DynamicPages";
import { AdminContactsPage, AdminContactsSection } from "./pages/MiniApp/Contacts";
import { ContentManagement } from "./pages/ContentManagement";
import { ClinicalPathways, ClinicalPathwaySection } from "./pages/ClinicalPathways";
import { ResearchAndAudit, ResearchAndAuditSection } from "./pages/ResearchAndAudit";
import { EducationPortalSection, EducationalPortal } from "./pages/MiniApp/EducationalPortal";
import {
  AdminEditHospital,
  AdminHospitalsList,
  AdminAddNewHospital,
} from "./pages/MiniApp/Hospitals";
import {
  MajorIncidents,
  AdminIncidents,
  MajorIncidentsDocuments,
} from "./pages/MiniApp/MajorIncidents";

import { SpaceRoute } from "../../types";
import { ClinicalDocuments, ClinicalDocumentSections } from "./pages/ClinicalDocuments";
import { PolicyDocuments, PolicyDocumentsSection } from "./pages/PolicyDocuments";

import SpaceAdmin from "Pages/Spaces/pages/Space/SpaceAdmin";
import { ChecklistsAdminRoutes } from "./pages/MiniApp/Checklists/ChecklistsAdminRoutes";
import Chat from "./pages/MiniApp/Chat/Chat";
import { useLaunchDarkly } from "Contexts";
import SpaceContentRepository from "./pages/SpaceContentRepository/SpaceContentRepository";
import { ExpiredFilesPage } from "Pages/Spaces/components/ExpiredFiles";
import { AdminNewsFeed } from "./pages/MiniApp/NewsFeed/pages/Admin/AdminNewsFeed";
import ActivityFeed from "./pages/MiniApp/ActivityFeed/ActivityFeed";
import { PatientLeaflets } from "./pages/MiniApp/PatientLeaflets";
import { AskEolasSpaces } from "./pages/MiniApp/AskEolasSpaces/pages/AskEolasSpaces";

export const adminSpaceRoutes: Record<SpaceRoute, string> = {
  admissionGuides: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.admissionGuides}`,
  allMajorIncidents: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.majorIncidents}/all`,
  contacts: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.contacts}`,
  contactsSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.contacts}/:sectionID`,
  contentManagement: `/${BaseAppRoute.spaces}/:spaceName/content-management`,
  highlights: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.highlights}`,
  patientLeaflets: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.patientLeaflets}`,
  clinicalPathways: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.clinicalPathways}`,
  clinicalPathwaysSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.clinicalPathways}/:sectionID`,
  educationPortal: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.educationPortal}`,
  educationPortalSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.educationPortal}/:sectionID`,
  howTo: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.howTo}`,
  equipmentLocations: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.equipmentLocations}`,
  expiredPathways: `/${BaseAppRoute.spaces}/:spaceName/content-management/expired-pathways`,
  hospitalList: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.hospitalList}`,
  addHospital: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.hospitalList}/add`,
  editHospital: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.hospitalList}/edit/:hospitalId`,
  newsFeed: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.newsFeed}`,
  majorIncidents: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.majorIncidents}`,
  majorIncidentDocuments: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.majorIncidents}/documents`,
  incidentReporting: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.incidentReporting}`,
  meetTheTeam: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.meetTheTeam}`,
  guidelines: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.guidelines}`,
  guidelinesSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.guidelines}/:sectionID`,
  clinicalDocuments: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.clinicalDocuments}`,
  clinicalDocumentsSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.clinicalDocuments}/:sectionID`,
  policyDocuments: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.policyDocuments}`,
  policyDocumentSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.policyDocuments}/:sectionID`,
  guidelinesManageExpired: `/${BaseAppRoute.spaces}/:spaceName/content-management/guidelines-expired`,
  policyDocumentsManageExpired: `/${BaseAppRoute.spaces}/:spaceName/content-management/policy-documents-expired`,
  clinicalDocumentsManageExpired: `/${BaseAppRoute.spaces}/:spaceName/content-management/clinical-documents-expired`,
  rotas: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.rotas}`,
  emergencyActionCards: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.emergencyActionCards}`,
  importantLinks: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.importantLinks}`,
  surveys: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.surveys}`,
  theDepartment: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.theDepartment}`,
  wellbeing: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.wellbeing}`,
  wellbeingSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.wellbeing}/:sectionID`,
  researchAndAudit: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.researchAndAudit}`,
  researchAndAuditSection: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.researchAndAudit}/:sectionID`,
  pushNotificationCenter: `/${BaseAppRoute.spaces}/:spaceName/push-notifications`,
  checklists: "",
  addMajorIncident: "",
  completedChecklist: "",
  channels: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.channels}`,
  contentRepository: `/${BaseAppRoute.spaces}/:spaceName/content-management/content-repository/:contentRepositoryId`,
  activityFeed: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.activityFeed}`,
  askEolasSpaces: `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.askEolasSpaces}`,
};

export const SpaceAdminRoutes = () => {
  const { flags } = useLaunchDarkly();
  const showContentRepository = !!flags[LDFlagNames.GENERIC_CONTENT_REPOSITORY];

  return (
    <>
      <Route exact path={"/"} component={SpaceAdmin} />
      <Route exact path={`/${BaseAppRoute.spaces}/:spaceName`} component={SpaceAdmin} />

      <ChecklistsAdminRoutes />

      {showContentRepository ? (
        <Route path={adminSpaceRoutes.contentRepository} component={SpaceContentRepository} />
      ) : null}

      <Route exact path={adminSpaceRoutes.contentManagement} component={ContentManagement} />

      <Route
        exact
        path={adminSpaceRoutes.rotas}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={adminDynamicPageData.rotas} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.emergencyActionCards}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={adminDynamicPageData.emergencyActionCards} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.admissionGuides}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={adminDynamicPageData.admissionGuides} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.importantLinks}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={adminDynamicPageData.importantLinks} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.surveys}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={adminDynamicPageData.surveys} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.highlights}
        render={(routeProps: RouteProps) => (
          <HighlightsAdmin {...routeProps} mainSectionID={AppLevelSection.highlights} />
        )}
      />

      <Route exact path={adminSpaceRoutes.contacts} component={AdminContactsPage} />
      <Route exact path={adminSpaceRoutes.contactsSection} component={AdminContactsSection} />

      <Route exact path={adminSpaceRoutes.hospitalList} component={AdminHospitalsList} />
      <Route exact path={adminSpaceRoutes.addHospital} component={AdminAddNewHospital} />
      <Route exact path={adminSpaceRoutes.editHospital} component={AdminEditHospital} />

      <Route exact path={adminSpaceRoutes.newsFeed} component={AdminNewsFeed} />

      <Route exact path={adminSpaceRoutes.majorIncidents} component={MajorIncidents} />
      <Route exact path={adminSpaceRoutes.allMajorIncidents} component={AdminIncidents} />
      <Route
        exact
        component={MajorIncidentsDocuments}
        path={adminSpaceRoutes.majorIncidentDocuments}
      />

      <Route exact component={AdminIncidentReporting} path={adminSpaceRoutes.incidentReporting} />

      <Route exact component={EducationalPortal} path={adminSpaceRoutes.educationPortal} />
      <Route
        exact
        component={EducationPortalSection}
        path={adminSpaceRoutes.educationPortalSection}
      />

      <Route exact path={adminSpaceRoutes.guidelines} component={Guidelines} />

      <Route exact component={GuidelinesSection} path={adminSpaceRoutes.guidelinesSection} />

      <Route exact path={adminSpaceRoutes.clinicalDocuments} component={ClinicalDocuments} />
      <Route
        exact
        path={adminSpaceRoutes.clinicalDocumentsSection}
        component={ClinicalDocumentSections}
      />

      <Route exact path={adminSpaceRoutes.policyDocuments} component={PolicyDocuments} />
      <Route
        exact
        path={adminSpaceRoutes.policyDocumentSection}
        component={PolicyDocumentsSection}
      />

      <Route
        exact
        path={adminSpaceRoutes.guidelinesManageExpired}
        render={(routeProps: RouteProps) => (
          <ExpiredFilesPage mainSection={AppLevelSection.guidelines} {...routeProps} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.policyDocumentsManageExpired}
        render={(routeProps: RouteProps) => (
          <ExpiredFilesPage mainSection={AppLevelSection.policyDocuments} {...routeProps} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.clinicalDocumentsManageExpired}
        render={(routeProps: RouteProps) => (
          <ExpiredFilesPage mainSection={AppLevelSection.clinicalDocuments} {...routeProps} />
        )}
      />

      <Route exact path={adminSpaceRoutes.meetTheTeam} component={AdminMeetTheTeam} />

      <Route
        exact
        path={adminSpaceRoutes.howTo}
        render={(routeProps: RouteProps) => (
          <SimpleSection {...routeProps} mainSectionId={AppLevelSection.howTo} />
        )}
      />

      <Route
        exact
        path={adminSpaceRoutes.equipmentLocations}
        render={(routeProps: RouteProps) => (
          <SimpleSection {...routeProps} mainSectionId={AppLevelSection.equipmentLocations} />
        )}
      />

      <Route exact component={ClinicalPathways} path={adminSpaceRoutes.clinicalPathways} />

      <Route
        exact
        path={adminSpaceRoutes.expiredPathways}
        render={(routeProps: RouteProps) => (
          <ExpiredFilesPage mainSection={AppLevelSection.clinicalPathways} {...routeProps} />
        )}
      />

      <Route
        exact
        component={ClinicalPathwaySection}
        path={adminSpaceRoutes.clinicalPathwaysSection}
      />

      <Route exact path={adminSpaceRoutes.wellbeing} component={Wellbeing} />
      <Route exact component={WellbeingSection} path={adminSpaceRoutes.wellbeingSection} />

      <Route exact path={adminSpaceRoutes.researchAndAudit} component={ResearchAndAudit} />
      <Route
        exact
        component={ResearchAndAuditSection}
        path={adminSpaceRoutes.researchAndAuditSection}
      />

      <Route exact path={adminSpaceRoutes.patientLeaflets} component={PatientLeaflets} />

      <Route exact path={adminSpaceRoutes.theDepartment} component={TheDepartment} />

      <Route
        exact
        path={`/${BaseAppRoute.spaces}/:spaceName/manageUsers`}
        component={ManageUsers}
      />

      <Route exact path={adminSpaceRoutes.channels} component={Chat} />

      <Route exact path={adminSpaceRoutes.activityFeed} component={ActivityFeed} />
      <Route exact path={adminSpaceRoutes.askEolasSpaces} component={AskEolasSpaces} />
    </>
  );
};
