import React from "react";
import { useTranslation } from "react-i18next";

import {
  Input,
  Title,
  Button,
  Textarea,
  ModalBody,
  FormError,
  FilePicker,
  ProgressBar,
  FormElement,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useIsScrollable, useAllowedFileTypes } from "Hooks";

import { useAddEolasFile } from "../useAddEolasFile";

import { AddItemModalProps } from "../types";
import { FilePickerAllowedTypes } from "Utilities/types";

export const AddItemWithAttachmentModal: React.FC<AddItemModalProps> = ({
  file,
  onCloseModal,
  subSectionId,
  mainSectionID,
  hideDescription,
  fileTypes,
  fileRequired = true,
}: AddItemModalProps) => {
  const { t } = useTranslation();
  const allowedFileTypes: FilePickerAllowedTypes[] = useAllowedFileTypes(fileTypes);

  const {
    error,
    control,
    onSubmit,
    progress,
    isLoading,
    formErrors,
    isSuccessful,
    filePickerType,
    filePickerValue,
    onSelectFileType,
    onFilePickerChange,
  } = useAddEolasFile({
    subSectionId,
    mainSectionID,
    eolasFile: file,
  });

  const { ref, ScrollHint } = useIsScrollable<HTMLDivElement>();

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>
          {t(file ? "general_edit_file_modal_title" : "general_add_file_modal_title")}
        </Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form onSubmit={onSubmit} className="add-file-form">
          <div className="space-y-2 add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              required
              id="name"
              control={control}
              labelClassName="font-semibold"
              label={t("file_name_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  disabled={isLoading}
                  placeholder={t("file_name_placeholder")}
                  data-testid="CM-addOrUpdateFileInput-text"
                />
              )}
            </FormElement>

            {!hideDescription && (
              <FormElement
                required
                control={control}
                id="description"
                labelClassName="font-semibold"
                label={t("file_content_label")}
              >
                {(field) => (
                  <Textarea
                    {...field}
                    disabled={isLoading}
                    data-testid="addOrUpdateFile-description"
                    placeholder={t("file_content_placeholder")}
                  />
                )}
              </FormElement>
            )}

            <FilePicker
              isDisabled={isLoading}
              required={fileRequired}
              value={filePickerValue}
              fileType={filePickerType}
              onChange={onFilePickerChange}
              error={formErrors.key?.message}
              onSelectType={onSelectFileType}
              allowedFileTypes={allowedFileTypes}
            />

            {isLoading && progress > 0 && <ProgressBar progress={progress} />}
          </div>

          <Button
            size="lg"
            type="submit"
            isLoading={isLoading}
            style={{ minHeight: "3rem" }}
            className="add-file__submit-button"
            data-cy="add-item-w-attachment-submit"
          >
            {t("add_item_modal_button")}
          </Button>

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
