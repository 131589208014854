import { createContext } from "react";

export interface SessionContextValue {
  clearSession: () => void;
}

const SessionContext = createContext<SessionContextValue>({
  clearSession: () => {
    return;
  },
});

export default SessionContext;
