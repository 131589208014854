import { isEolasFile, isEolasShadowCopyFile } from "@eolas-medical/core";
import { LinkIcon } from "Assets/Icons/colored";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { useRemoveSearchFavourite } from "Components/MasterSearch/hooks/useRemoveSearchFavourite";
import { LocalResult } from "Components/MasterSearch/types";
import { useMediaQuery } from "Hooks";
import { useToolTip } from "Hooks/useToolTip";
import { localSearchResultToEntityType } from "Pages/MeTab/pages/MyFavourites/functions/helpers";
import { FavouriteToggle, Text } from "UIKit";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { snakeCase } from "lodash";
import { useTranslation } from "react-i18next";
import { useAddFavourite } from "shared/hooks/useAddFavourite";

export type SearchResultItemProps = {
  icon: React.ReactNode;
  title: string;
  mainSectionName: string;
  item: LocalResult;
  subSectionId?: string;
  canShareResult?: boolean;
  onShareResult?: () => void;
};

const SearchResultItem = ({
  icon,
  title,
  item,
  canShareResult = false,
  onShareResult,
}: SearchResultItemProps) => {
  const media = useMediaQuery();
  const isMobile = media === "xs";
  const shouldShowShareBtn = canShareResult && onShareResult;
  const { makeToolTipDataAttr } = useToolTip();
  const { t } = useTranslation();

  const containerClass = isMobile ? "grid grid-cols-8" : "flex space-x-2";

  const isItemShadowCopy = isEolasFile(item.file) && isEolasShadowCopyFile(item.file);

  const {
    isFavourite,
    isMarkingAsFavourite,
    canFavouriteSearchResult,
    favouriteId,
    handleAddFavourite,
  } = useAddFavourite(item.id, item);

  const entityType = localSearchResultToEntityType(item);

  const { removeFavourite, isRemovingFavourite } = useRemoveSearchFavourite({
    entityId: item.id,
    entityType,
  });

  // TODO: Refactor this to be a variation of EolasFileTile
  return (
    <div
      className={`bg-white w-full rounded-lg p-4 cursor-pointer border border-1 hover:bg-blue-50 border-grey-300 justify-between ${containerClass}`}
      data-testid="master-search-results-item"
    >
      <div className="col-start-1 col-end-7 flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-4 h-4 mr-2" data-testid="circle-icon">
            {icon}
          </div>
          <FileBreadcrumbs
            isFromSearch
            mainSectionId={item.file.mainSectionID}
            parentId={item.file.parentID}
            shouldShowSpaceName={false}
          />
        </div>
        <div className="flex flex-row items-center gap-2 mt-4">
          <Text level={1} className="line-clamp-2 ">
            {title}
          </Text>
          {isItemShadowCopy ? (
            <LinkIcon
              className="h-5 w-5 text-grey-600"
              {...makeToolTipDataAttr({
                text: t("component_admin_file_tile_shadow_copy_tooltip"),
              })}
            />
          ) : null}
        </div>
      </div>
      <div>
        {canFavouriteSearchResult ? (
          <FavouriteToggle
            entityId={item.id}
            onClick={() =>
              isFavourite && favouriteId
                ? removeFavourite(favouriteId)
                : handleAddFavourite({
                    item: item,
                    entityType,
                  })
            }
            isLoading={isMarkingAsFavourite || isRemovingFavourite}
          />
        ) : null}
        {shouldShowShareBtn ? (
          <ShareFileButton id={snakeCase(title)} onShareFile={onShareResult} />
        ) : null}
      </div>
    </div>
  );
};

export default SearchResultItem;
