import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { AttentionIcon, CheckSlimIcon, SaveIcon, MajorIncidentIcon, UserIcon } from "Assets";
import { Text, Title } from "../Typography";
import { ControlButton, DeleteButton } from "../Button";
import { ControlsWrapper, FileTileWrapper, FileTileProps } from "./shared";
import { useToolTip } from "Hooks/useToolTip";
import { useDownloadChecklistPDF } from "Hooks";

type CompletedChecklistStatus = "completed" | "incomplete";

interface CompletedChecklistTileProps extends FileTileProps {
  hasIssues?: boolean;
  status?: CompletedChecklistStatus;
}

export const CompletedChecklistTile: React.FC<CompletedChecklistTileProps> = ({
  isAdmin,
  status,
  canDrag,
  eolasFile,
  hasIssues,
  isDragging,
  onDelete,
  onSelectFile = () => {
    return;
  },
}: CompletedChecklistTileProps) => {
  const isComplete = status === "completed";
  const { t } = useTranslation();
  const { createdAt } = eolasFile;
  const { makeToolTipDataAttr } = useToolTip();
  const { downloadPDF, isLoading } = useDownloadChecklistPDF();

  return (
    <FileTileWrapper
      eolasFile={eolasFile}
      canDrag={canDrag}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
      className={`
        space-x-0 grid grid-cols-1 sm:grid-cols-9 gap-3 sm:gap-4
      `}
    >
      <div className="flex items-center sm:col-span-3">
        <UserIcon className="h-6 w-6 sm:w-8 sm:h-8 text-darkblue-300 mr-2" />

        <Title level={8} className="font-semibold flex-1">
          {eolasFile.info ?? ""}
        </Title>
      </div>

      <Text level={2} className="text-grey-700 sm:col-span-3 font-semibold">
        {format(new Date(createdAt as string), "do MMMM yyyy, HH:mm")}
      </Text>

      <div className="flex items-center justify-end space-x-4 sm:col-span-3">
        <MajorIncidentIcon
          {...makeToolTipDataAttr({
            text: t("submittedChecklists_issue_tooltip"),
          })}
          className={`h-6 w-6 ${hasIssues ? "visible" : "invisible"}`}
        />
        <div
          className={`
          flex items-center px-2 h-8 rounded-full space-x-1
          ${isComplete ? "text-green bg-green-50" : "text-yellow-600 bg-yellow-100"}
        `}
        >
          {isComplete ? (
            <>
              <CheckSlimIcon className="h-4 w-4" />
              <Text level={3} className="font-semibold">
                {t("checklistSubmission_completed_label")}
              </Text>
            </>
          ) : (
            <>
              <AttentionIcon className="h-4 w-4" />
              <Text level={3} className="font-semibold">
                {t("checklistSubmission_incomplete_label")}
              </Text>
            </>
          )}
        </div>

        <ControlsWrapper direction="row">
          {onDelete ? (
            <DeleteButton
              onClick={() => onDelete(eolasFile)}
              data-testid="deleteCompletedChecklist"
            />
          ) : null}
          {isAdmin ? (
            <ControlButton onClick={() => downloadPDF(eolasFile?.id || "")} isLoading={isLoading}>
              <SaveIcon height={18} width={18} />
            </ControlButton>
          ) : null}
        </ControlsWrapper>
      </div>
    </FileTileWrapper>
  );
};
