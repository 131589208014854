import {
  AnalyticsEvents,
  BnfPayload,
  EolasInteractionsMessage,
  MonographType,
  makeTitle,
} from "@eolas-medical/core";
import { InnerPageWrapper, Text, Loader, ClickSearchBox } from "UIKit";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";

import DrugInteraction from "../MultiInteractions/components/DrugInteraction";
import { Strings } from "./SingleDrugInteractions.strings";
import { useGetSingleDrugInteractions } from "modules/bnf/data/useGetSingleDrugInteractions";
import { useGetBnfSearchParams } from "../../../hooks/useGetBnfSearchParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SupplementaryInfo } from "../MultiInteractions/components/SupplementaryInfo";
import { SearchSortList } from "shared/components/Lists";
import { trackEvent } from "API/Analytics";
import { useBnfSearchAnalytics } from "../../../hooks/useBnfSearchAnalytics";

const SingleDrugInteractions = ({ type }: { type: MonographType }) => {
  const { idOrSid, title: pageTitle } = useGetBnfSearchParams();

  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const {
    data: interactionData,
    isInitialLoading: interactionsListLoading,
    error: interactionsListError,
  } = useGetSingleDrugInteractions({ sid: idOrSid ?? "", type });

  const filteredInteractions = useMemo(() => {
    if (!selectedValue.trim()) return interactionData?.interactions ?? [];
    return (
      interactionData?.interactions.filter((interaction) => {
        const title = makeTitle({ currentSid: interaction.id, interaction });
        return title.toLowerCase().includes(selectedValue.trim().toLowerCase());
      }) ?? []
    );
  }, [interactionData, selectedValue]);

  const onBnfSearch = useBnfSearchAnalytics(
    type === "bnf" ? "interactions_adult" : "interactions_child",
  );

  const onClickSearch = useCallback(() => {
    onBnfSearch(searchValue);
    setSelectedValue(searchValue);
  }, [searchValue, onBnfSearch]);

  const onClearSearch = useCallback(() => {
    setSelectedValue("");
    setSearchValue("");
  }, []);

  const renderInteraction = useCallback(
    (interaction: EolasInteractionsMessage) => {
      return (
        <DrugInteraction
          key={interaction.id}
          currentSid={idOrSid ?? ""}
          interaction={interaction}
        />
      );
    },
    [idOrSid],
  );

  useEffect(() => {
    if (pageTitle) {
      trackEvent<BnfPayload>(AnalyticsEvents.BNF_INTERACTIONS_VIEWED_SINGLE_DRUG, {
        type,
        name: pageTitle,
      });
    }
  }, [pageTitle, type]);

  return (
    <InnerPageWrapper>
      <BnfHeader type={type} title={pageTitle} goBackBehaviourPreference="goBackHistory" />
      {!interactionsListLoading && !interactionsListError ? (
        <ClickSearchBox
          value={searchValue}
          onChangeText={setSearchValue}
          onClickSearch={onClickSearch}
          onClearSearch={onClearSearch}
          placeholder={Strings.searchPlaceholder}
        />
      ) : null}
      {interactionsListLoading ? (
        <Loader className="bg-grey-50" size={40} />
      ) : interactionsListError ? (
        <Text level={1} className="my-4 text-center">
          {Strings.interactionsListError}
        </Text>
      ) : (
        <div className="flex flex-col justify-center my-6 space-y-4 w-full">
          {!selectedValue ? (
            <SupplementaryInfo supplementaryInfo={interactionData?.supplementaryInfo} />
          ) : null}
          <SearchSortList
            items={filteredInteractions}
            isSearchable={false}
            renderItem={renderInteraction}
            emptyItemsLabel={Strings.noSearchResults}
          />
        </div>
      )}
    </InnerPageWrapper>
  );
};

export const SingleDrugInteractionsAdult = () => <SingleDrugInteractions type="bnf" />;
export const SingleDrugInteractionsChild = () => <SingleDrugInteractions type="bnfc" />;
