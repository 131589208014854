import { modalStore } from "Stores/ModalStore";
import { useTranslation } from "react-i18next";
import { LoginCredentials } from "Pages/Authentication/signup/types";
import { wait } from "@eolas-medical/core";

const supportArticle =
  "https://support.eolasmedical.com/i-didnt-receive-the-verification-code.-eolas-medical-help-center";

const useAssistanceVerificationCode = ({
  email,
}: Omit<LoginCredentials, "password"> & {
  origin: "SIGN_UP" | "SIGN_IN" | "FORGOT_PASSWORD";
}) => {
  const { t } = useTranslation();

  const onRequestAssistance = () => {
    modalStore.openModal({
      variant: "dialogue",
      layoutType: "stacked",
      icon: "warning",
      title: t("no_email_received"),
      message: t("no_email_received_description", { email }),
      name: "verifyAssistanceModal",
      onConfirm: () => {
        wait(200).then(() => {
          modalStore.openModal({
            variant: "dialogue",
            layoutType: "stacked",
            title: t("request_assistance_title"),
            message: t("request_assistance_description"),
            name: "verifyAssistanceConfirmModal",
            confirmText: t("request_assistance_link"),
            dismissText: t("general_cancel"),
            onConfirm: () => {
              window.open(supportArticle, "_blank");
            },
          });
        });
      },
      confirmText: t("email_correct"),
      dismissText: t("general_cancel"),
    });
  };

  return {
    requestAssistanceVerificationCode: onRequestAssistance,
    isRequestingAssistance: false,
    error: null,
  };
};

export default useAssistanceVerificationCode;
