import {
  AnalyticsEvents,
  ClinicalQuestionRatingByUserResponse,
  ClinicalQuestionRatingPayload,
  clinicalQuestionsClient,
  clinicalQuestionStore,
  eolasLogger,
  userStore,
} from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeGetUserRatingQueryKey } from "modules/clinicalQuestions/data/useGetUserRating";
import { sendZapierRequest } from "shared/functions/sendZapierRequest";
import { trackEvent } from "API/Analytics";

const BASE_URL = "https://hooks.zapier.com/hooks/catch/19312552/236zfck/";

type Args = {
  questionID: string;
  likeOrDislike: "like" | "dislike" | "neutral";
  comment?: string;
  title?: string;
};

const submitFeedback = async ({ questionID, likeOrDislike, comment }: Args) => {
  const response = await clinicalQuestionsClient.addClinicalQuestionFeedback({
    userID: userStore.userID,
    questionID,
    likeOrDislike,
    comment,
  });

  return response;
};

export const useSubmitFeedback = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError } = useMutation({
    mutationFn: submitFeedback,
    mutationKey: ["submitClinicalQuestionFeedback"],
    onMutate: async (newData) => {
      const queryKey = makeGetUserRatingQueryKey(newData.questionID);

      await queryClient.cancelQueries(queryKey);
      const prevData = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(
        queryKey,
        (oldData: ClinicalQuestionRatingByUserResponse | undefined) => {
          if (!oldData) {
            return {
              body: {
                questionID: newData.questionID,
                userID: userStore.userID,
                likeOrDislike: newData.likeOrDislike,
                comments: [],
              },
            };
          }
          return {
            ...oldData,
            body: {
              ...oldData.body,
              likeOrDislike: newData.likeOrDislike,
            },
          };
        },
      );
      return { prevData };
    },
    onError: (error, newData, context) => {
      queryClient.setQueryData(["getUserRating", newData.questionID], context?.prevData);
      eolasLogger.error(new Error("Could not submit feedback on clinical question response"), {
        error,
        newData,
      });
    },
    onSettled: (_data, _error, args) => {
      queryClient.invalidateQueries(makeGetUserRatingQueryKey(args.questionID));
    },
    onSuccess: async (_, args) => {
      if (onSuccess) {
        onSuccess();
      }
      let zapierBody: Record<string, string> = {
        title: args.title ?? "",
        questionID: args.questionID,
        notes: clinicalQuestionStore.followUpQuestion,
        userID: userStore.userID,
      };
      if (args.comment) {
        trackEvent<ClinicalQuestionRatingPayload>(AnalyticsEvents.CLINICAL_QUESTION_RATED, {
          questionID: args.questionID,
          comment: args.comment,
          query: args.title ?? "",
        });
        zapierBody = {
          ...zapierBody,
          comment: args.comment,
        };
      } else if (args.likeOrDislike === "like" || args.likeOrDislike === "dislike") {
        trackEvent<ClinicalQuestionRatingPayload>(AnalyticsEvents.CLINICAL_QUESTION_RATED, {
          questionID: args.questionID,
          likeOrDislike: args.likeOrDislike,
          query: args.title ?? "",
        });
        zapierBody = {
          ...zapierBody,
          likeOrDislike: args.likeOrDislike,
        };
      }
      try {
        await sendZapierRequest({ type: "POST", baseUrl: BASE_URL, body: zapierBody });
      } catch (error) {
        eolasLogger.error(new Error("Unable to send feedback to zapier"), { error });
      }
    },
  });

  return { isLoading, isError, submitFeedback: mutateAsync };
};
