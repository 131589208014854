import React from "react";
import { motion } from "framer-motion";

import { IconButton } from "UIKit";
import { LoaderIcon, XIcon } from "Assets";

import { Notification, NotificationType } from "./types";

const notificationStyles: Record<NotificationType, string> = {
  error: "border-red-500",
  success: "border-green-500",
  warning: "border-yellow-500",
  loading: "border-grey-500",
};

interface NotificationCardProps extends Notification {
  onHide(id: string): void;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({
  id,
  title,
  onHide,
  description,
  autoHideTimeout,
  type = "success",
}: NotificationCardProps) => {
  const notificationClassName = notificationStyles[type];

  React.useEffect(() => {
    if (autoHideTimeout) {
      setTimeout(onHide, autoHideTimeout, id);
    }
  }, [id, autoHideTimeout, onHide]);

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.25 }}
      exit={{ opacity: 0, x: "100%" }}
      className={`
        flex items-center shadow-sm border-l-4 bg-white
        rounded-sm pl-4 py-2 opacity-0
        ${notificationClassName}
      `}
    >
      {type === "loading" && (
        <div className="mr-2">
          <LoaderIcon className="w-6 h-6 animate-spin-slow" />
        </div>
      )}

      <div className="flex flex-col flex-1">
        <span className="font-semibold text-lg">{title}</span>
        <span className="text-base">{description}</span>
      </div>

      <div className="self-start mx-2">
        <IconButton onClick={() => onHide(id)} icon={<XIcon />} size="xs" />
      </div>
    </motion.div>
  );
};
