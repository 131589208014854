/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiClient, ApiClientParams } from "@eolas-medical/core";
import envConfig from "env-config";
import { API_ENV_PATH_PREFIXS } from "Utilities";

export interface LoginTokenItem {
  id: string;
  email: string;
  appID: string;
  userID: string;
}

export interface LoginWithTokenResponse {
  AuthenticationResult: {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    RefreshToken: string;
  };
  userID: string;
  appID: string;
  ChallengeParameters: any;
  email: string;
  password: string;
}

class LoginTokenService extends ApiClient {
  private getPath(useAppServicesEndpoints: boolean) {
    return useAppServicesEndpoints
      ? `/auth-service-${API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV]}`
      : "/auth/v1";
  }

  public async loginWithToken({
    token,
    useAppServicesEndpoints,
  }: {
    token: string;
    useAppServicesEndpoints: boolean;
  }) {
    return useAppServicesEndpoints
      ? this.http.post<LoginWithTokenResponse>(
          `${this.getPath(useAppServicesEndpoints)}/token/login`,
          { token },
        )
      : this.http.post<LoginWithTokenResponse>(
          `${this.getPath(useAppServicesEndpoints)}/tokens/login`,
          { token },
        );
  }

  public async listTokens({
    appID,
    useAppServicesEndpoints,
  }: {
    appID: string;
    useAppServicesEndpoints: boolean;
  }) {
    return useAppServicesEndpoints
      ? this.http.post<Array<LoginTokenItem>>(
          `${this.getPath(useAppServicesEndpoints)}/token/list`,
          { appID },
        )
      : this.http.get<Array<LoginTokenItem>>(
          `${this.getPath(useAppServicesEndpoints)}/tokens?spaceId=${encodeURIComponent(appID)}`,
        );
  }

  public async createToken({
    appID,
    given_name,
    family_name,
    useAppServicesEndpoints,
  }: {
    appID: string;
    given_name: string;
    family_name: string;
    useAppServicesEndpoints: boolean;
  }) {
    return useAppServicesEndpoints
      ? this.http.post<{ token: string }>(`${this.getPath(useAppServicesEndpoints)}/token/create`, {
          appID,
          given_name,
          family_name,
        })
      : this.http.post<{ token: string }>(`${this.getPath(useAppServicesEndpoints)}/tokens`, {
          spaceId: appID,
          givenName: given_name,
          familyName: family_name,
        });
  }

  public async deleteToken({
    token,
    useAppServicesEndpoints,
  }: {
    token: string;
    useAppServicesEndpoints: boolean;
  }) {
    return useAppServicesEndpoints
      ? this.http.delete(`${this.getPath(useAppServicesEndpoints)}/token/delete`, {
          data: { token },
        })
      : this.http.delete(
          `${this.getPath(useAppServicesEndpoints)}/tokens/${encodeURIComponent(token)}`,
        );
  }
}

export let loginTokenService: LoginTokenService;

export const initialiseLoginTokenService = (params: ApiClientParams) => {
  loginTokenService = new LoginTokenService(params);
};
