/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { sectionStore } from "@eolas-medical/core";

import { useS3FileUpload, useS3FileUploadV2, useUpdateApp } from "Hooks";
import { useRequestStatus, generateS3FileKey } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

interface AddGuidelineOrganisationForm {
  file: any;
  id: string;
  key: string;
  name: string;
  progress: number;
  abbreviation: string;
}

const defaultValues: AddGuidelineOrganisationForm = {
  id: "",
  key: "",
  name: "",
  file: null,
  progress: 0,
  abbreviation: "string",
};

const schema = yup.object().shape({
  name: yup.string().required("error_guidelines_organisation_name_required"),
});

export const useAddGuidelineOrganisation = (onClose: () => void) => {
  const { updateApp } = useUpdateApp();
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const formMethods = useForm<AddGuidelineOrganisationForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, setValue, formState } = formMethods;

  const onPickImage = (image: File | null) => {
    setValue("file", image, { shouldValidate: true });
  };

  const onSubmit = handleSubmit(async ({ name, file }) => {
    setRequestStatus({ status: "pending", error: "" });
    let key = "";
    try {
      if (file) {
        if (useAppServicesEndpoints) {
          key = generateS3FileKey({
            isPublic: true,
            fileName: name,
            addExtension: true,
            fileFormat: file.type,
          });
          await uploadFile(key, file);
        } else {
          const { publicUrl } = await uploadFileV2({
            file,
            isPublic: true,
          });

          if (!publicUrl) throw Error("publicUrl missing when adding guideline organisation");

          key = publicUrl;
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sectionStore.addGuidelinesOrganisations({
        name,
        logo: key,
      });

      const input = {
        id: sectionStore.data.app?.id,
        guidelineOrganisations: sectionStore.guidelinesOrganisations,
      };
      await updateApp(input);

      setRequestStatus({ status: "success", error: "" });
      onClose();
    } catch (error: any) {
      const errorMessage = errorStore.captureError({ error, source: "network" });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  });

  return {
    error,
    control,
    onSubmit,
    isLoading,
    formMethods,
    onPickImage,
    formErrors: formState.errors,
  };
};
