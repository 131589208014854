import { useQueryClient, useMutation } from "@tanstack/react-query";
import { myFilesService } from "../client/myFiles.client";
import { FilePatchDto } from "../types";
import myFilesKeys from "./myFiles.queryKeys";

const useDragFile = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<unknown, Error, FilePatchDto>(
    async ({ fileId, prevFileId }: FilePatchDto) => {
      return myFilesService.patchFile({ fileId, prevFileId: prevFileId || "null" });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: myFilesKeys.allFilesList(),
        });
      },
    },
  );

  return {
    dragFile: mutate,
    draggingFile: isLoading,
  };
};

export default useDragFile;
