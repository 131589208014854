import ReactPlayer from "react-player";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";

import { sectionStore, AppLevelSection } from "@eolas-medical/core";

import { AddButton, Button, Modal } from "UIKit";

import { openWindow } from "API/app.actions";
import { FilePickerAllowedTypes, LDFlagNames } from "Utilities/types";
import { UploadFileModal } from "Pages/Spaces/components/UploadFileModal";

import { useDepartmentFilePicker } from "../hooks";
import { useLaunchDarkly } from "Contexts";
import { useSelectFile } from "Hooks";

export const DepartmentTourPicker = observer(() => {
  const [modal, setModal] = useState<React.ReactNode>(null);
  const isAdmin = sectionStore.isAdmin;
  const { flags } = useLaunchDarkly();

  const { t } = useTranslation();
  const {
    url,
    error,
    tourFile,
    fileType,
    showTour,
    progress,
    isLoading,
    filePickerValue,
    isSuccessful,
    removeError,
    isRemoveLoading,
    setShowTour,
    onRemoveFile,
    onChange,
    onAddFile,
    onEditFile,
    setRequestStatus,
  } = useDepartmentFilePicker();

  const { onSelectFile } = useSelectFile();

  const toggleUploadModal = useCallback(() => {
    setRequestStatus({ status: "initial", error: "" });
    setModal(!modal);
  }, [modal, setRequestStatus]);

  const openPDF = useCallback(() => {
    // this flag can be removed once lgacy code has been removed
    if (flags[LDFlagNames.PDF_TRON_ENABLED]) {
      onSelectFile(tourFile);
    } else {
      // this is LEGACY code - rmeove once PDF TRON is fully operationsal
      openWindow(url);
    }
  }, [flags, onSelectFile, tourFile, url]);

  const Content = useMemo(() => {
    if (url && !isAdmin) {
      return !showTour ? (
        <Button
          color="blue"
          className="self-center mb-8"
          onClick={() => (fileType === "pdf" ? openPDF() : setShowTour(true))}
        >
          {t("theDepartment_take_department_tour")}
        </Button>
      ) : (
        <div className="flex flex-col mb-8">
          <ReactPlayer controls url={url} width="100%" height="100%" />

          <div className="flex justify-between items-center mt-4">
            <span className="font-semibold">{t("theDepartment_tour_name")}</span>
            <Button color="grey" weight="regular" onClick={() => setShowTour(false)}>
              {t("theDepartment_hide_department_tour")}
            </Button>
          </div>
        </div>
      );
    }

    if (url) {
      return (
        <div className="flex flex-col mb-4">
          <div className="flex items-center space-x-4 mb-4">
            <Button
              color="grey"
              weight="regular"
              variant="outline"
              isLoading={isLoading}
              className="w-full"
              onClick={toggleUploadModal}
            >
              {t("theDepartment_edit_tour")}
            </Button>

            <Button
              color="grey"
              weight="regular"
              variant="outline"
              onClick={onRemoveFile}
              className="w-full"
              isLoading={isRemoveLoading}
            >
              {t(
                fileType === "pdf"
                  ? "theDepartment_remove_tour_file"
                  : "theDepartment_remove_tour_video",
              )}
            </Button>
          </div>

          {fileType === "pdf" ? (
            <Button color="blue" className="self-center mb-8" onClick={() => openPDF()}>
              {t("theDepartment_take_department_tour")}
            </Button>
          ) : (
            <ReactPlayer controls url={url} width="100%" height="100%" />
          )}

          {removeError && <span className="text-red text-sm text-center mt-4">{removeError}</span>}
          {error && <span className="text-red text-sm text-center mt-4">{error}</span>}
        </div>
      );
    }

    if (isAdmin) {
      return (
        <AddButton
          isLoading={isLoading}
          onClick={toggleUploadModal}
          className="w-full sm:w-1/2 xl:w-1/3"
        >
          {t("theDepartment_add_tour_button")}
        </AddButton>
      );
    }

    return null;
  }, [
    url,
    error,
    isAdmin,
    showTour,
    fileType,
    isLoading,
    removeError,
    isRemoveLoading,
    t,
    openPDF,
    setShowTour,
    onRemoveFile,
    toggleUploadModal,
  ]);

  return (
    <>
      {Content}
      <Modal open={!!modal} onClose={toggleUploadModal}>
        <UploadFileModal
          fileType={fileType as FilePickerAllowedTypes}
          error={error || ""}
          progress={progress}
          isLoading={isLoading}
          handleFileChange={onChange}
          isSuccessful={isSuccessful}
          pickerValue={filePickerValue}
          onCloseModal={toggleUploadModal}
          allowedFileTypes={["mp4", "pdf"]}
          mainSectionID={AppLevelSection.theDepartment}
          handleSubmit={() => (url ? onEditFile() : onAddFile())}
        />
      </Modal>
    </>
  );
});
