import { Text } from "UIKit";
import { useFileBreadcrumbs } from "./hooks/useFileBreadcrumbs";
import { useTranslation } from "react-i18next";
import { BaseAppRoute } from "Utilities/types";
import { useHistory } from "react-router-dom";
import { useSpacesContext } from "modules/spaces";
import { useToolTip } from "Hooks/useToolTip";
import { useMediaQuery } from "Hooks";

const BREADCRUMB_JOIN = " > ";
const MAX_NAME_LENGTH = 40;

const truncateName = (name: string) =>
  name.length > MAX_NAME_LENGTH ? `${name.slice(0, MAX_NAME_LENGTH)}...` : name;

export const FileBreadcrumbs = ({
  mainSectionId,
  parentId,
  shouldShowSpaceName,
  fileName,
  isFromSearch,
}: {
  mainSectionId?: string;
  parentId?: string;
  shouldShowSpaceName: boolean;
  fileName?: string;
  isFromSearch?: boolean;
}) => {
  const {
    isPathTruncated,
    onShowFullPath,
    pathToShow,
    showFullPath,
    fullPath,
    onBreadcrumbClick,
    shouldHideBreadcrumbs,
  } = useFileBreadcrumbs({
    mainSectionId,
    parentId,
    fileName,
  });

  const { encodedSelectedSpaceName, selectedSpace } = useSpacesContext();
  const { push } = useHistory();
  const { makeToolTipDataAttr } = useToolTip();
  const { t } = useTranslation();
  const mediaBreakpoint = useMediaQuery();

  if (shouldHideBreadcrumbs) {
    return null;
  }

  const containerStyle = mediaBreakpoint === "md" ? "w-70vw" : "w-50vw";

  const renderBreadcrumbs = (breadcrumbs: { [name: string]: string }[]) => (
    <div className="text-grey-600 font-semibold">
      {shouldShowSpaceName && selectedSpace ? (
        <>
          <Text
            level={1}
            className="cursor-pointer hover:underline"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              event.stopPropagation();
              push(`/${BaseAppRoute.spaces}/${encodedSelectedSpaceName}`);
            }}
          >
            {selectedSpace.name}
          </Text>
          {breadcrumbs ? <Text level={1}>{BREADCRUMB_JOIN}</Text> : null}
        </>
      ) : null}
      {breadcrumbs.map((breadcrumb, index) => {
        const [name, sectionId] = Object.entries(breadcrumb)[0];
        const nameToShow = truncateName(name);
        const isEllipsis = name === "..." && isPathTruncated && !showFullPath;
        const isFinalBreadcrumb = index === breadcrumbs.length - 1;
        const textClassName = `${!isFinalBreadcrumb ? "cursor-pointer hover:underline" : ""} ${
          isFinalBreadcrumb ? "text-grey-800" : ""
        }`;

        const handleClick = (event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          if (isEllipsis) {
            onShowFullPath(event);
          } else {
            onBreadcrumbClick(sectionId, Boolean(isFromSearch));
          }
        };

        return (
          <Text level={1} key={sectionId || index}>
            <Text
              level={1}
              {...(isEllipsis ? makeToolTipDataAttr({ text: t("click_to_expand") }) : null)}
              className={textClassName}
              onClick={handleClick}
            >
              {nameToShow}
            </Text>
            {!isFinalBreadcrumb ? " > " : null}
          </Text>
        );
      })}
    </div>
  );

  return (
    <div className={!isFromSearch ? containerStyle : ""}>
      {!showFullPath ? renderBreadcrumbs(pathToShow) : renderBreadcrumbs(fullPath)}
    </div>
  );
};
