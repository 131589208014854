import React from "react";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents, eolasLogger } from "@eolas-medical/core";
import { openWindow } from "Hooks";

export const useMedusaFile = () => {
  const disabledRef = React.useRef(false);

  const onSelectMedusaFile = React.useCallback(
    async ({ name, id, url }: { name: string; id: string; url: string }) => {
      // disable the handler while the request is pending
      if (disabledRef.current) return;

      trackEvent(AnalyticsEvents.MEDUSA_MONOGRAPH_VIEWED);

      disabledRef.current = true;

      eolasLogger.log(`Opening Medusa File: ${name}`, {
        id,
        category: "Medusa Files",
      });
      openWindow(url);

      disabledRef.current = false;
    },
    [],
  );

  return {
    onSelectMedusaFile,
  };
};
