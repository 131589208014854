import { ControlButton, FileTileProps } from "UIKit";
import { useDownloadFile } from "./hooks";
import { isFileExtensionMediaType } from "modules/contentRepository/helpers";
import { DownloadIcon } from "Assets";

interface DownloadFileButtonProps extends Omit<FileTileProps, "isAdmin"> {
  textClass?: string;
}

export const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  eolasFile,
}: DownloadFileButtonProps) => {
  const { saveFile } = useDownloadFile(eolasFile);

  const isDownloadable = eolasFile?.type ? isFileExtensionMediaType(eolasFile?.type) : false;

  return isDownloadable ? (
    <ControlButton
      className="bg-transparent border-none"
      size="xs"
      type="submit"
      onClick={saveFile}
    >
      <DownloadIcon width={18} height={18} />
    </ControlButton>
  ) : null;
};
