import { useState, useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { AppLevelSection } from "@eolas-medical/core";

import { Input, Modal, Button, PageTitle, NavButton, FormElement, InnerPageWrapper } from "UIKit";

import { AddContactsSection, SelectFacilitiesSection, DeleteHospitalModal } from "../components";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";

const labelStyle = "mb-1 text-lg font-semibold";
const smallLabelStyles = "mb-1 text-md font-semibold";

export const AdminEditHospital: React.FC<RouteComponentProps<{ hospitalId: string }>> = ({
  history: { push },
  match: {
    url,
    params: { hospitalId: encodedHospitalID = "" },
  },
}: RouteComponentProps<{ hospitalId: string }>) => {
  const hospitalId = decodeURIComponent(encodedHospitalID);

  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { file: hospital } = useGetFile({ id: hospitalId });

  const goBack = useCallback(() => {
    push(url.replace(/\/edit.*$/gi, ""));
  }, [url, push]);

  const onCloseModal = () => {
    setModal(null);
  };

  const { control, formMethods, onSubmit, isLoading, isFormComplete, error } = useAddEolasFile({
    eolasFile: hospital || undefined,
    afterSubmitFunction: goBack,
    mainSectionID: AppLevelSection.hospitalList,
  });

  const deleteHospital = () => {
    setModal(<DeleteHospitalModal hospital={hospital} onCloseModal={() => goBack()} />);
  };

  if (!hospital) return null;

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("hospitalsAdmin_edit_hospital_title")} />
        <NavButton to={url.replace(/\/edit.*$/gi, "")} />

        <Button color="red" variant="outline" onClick={deleteHospital} className="self-center">
          {t("hospitalsAdmin_delete_hospital_button")}
        </Button>

        <FormProvider {...formMethods}>
          <form className="flex flex-col items-stretch pb-8 space-y-4" onSubmit={onSubmit}>
            <FormElement
              required
              id="name"
              control={control}
              labelClassName={labelStyle}
              label={t("hospitalsAdmin_hospital_name_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  data-testid="hospitalNameInput"
                  placeholder={t("hospitalsAdmin_hospital_name_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              control={control}
              id="description"
              labelClassName={labelStyle}
              label={t("hospitalsAdmin_hospital_address_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  data-testid="hospitalAddressInput"
                  placeholder={t("hospitalsAdmin_hospital_address_placeholder")}
                />
              )}
            </FormElement>

            <AddContactsSection hospital={hospital} />

            <span className={`${labelStyle}`}>
              {t("hospitalsAdmin_landing_site_section_title")}
            </span>

            <div className="flex flex-col items-stretch p-4 space-y-3 bg-grey-light rounded-lg">
              <FormElement
                required
                control={control}
                id="moreInfo.coordinates"
                labelClassName={smallLabelStyles}
                label={t("hospitalsAdmin_landing_coordinates_label")}
              >
                {(field) => (
                  <Input
                    {...field}
                    className="bg-white"
                    data-testid="landingSiteCoordsInput"
                    placeholder={t("hospitalsAdmin_landing_coordinates_placeholder")}
                  />
                )}
              </FormElement>

              <FormElement
                required
                control={control}
                id="moreInfo.lighting"
                labelClassName={smallLabelStyles}
                label={t("hospitalsAdmin_landing_lighting_label")}
              >
                {(field) => (
                  <Input
                    {...field}
                    className="bg-white"
                    data-testid="landingSiteLightingInput"
                    placeholder={t("hospitalsAdmin_landing_lighting_placeholder")}
                  />
                )}
              </FormElement>

              <FormElement
                required
                control={control}
                id="moreInfo.transfer"
                labelClassName={smallLabelStyles}
                label={t("hospitalsAdmin_landing_transfer_label")}
              >
                {(field) => (
                  <Input
                    {...field}
                    className="bg-white"
                    data-testid="landingSiteTransferInput"
                    placeholder={t("hospitalsAdmin_landing_transfer_placeholder")}
                  />
                )}
              </FormElement>
            </div>

            <SelectFacilitiesSection customSections={JSON.parse(hospital.moreInfoTwo!)} />

            {isFormComplete ? (
              <Button
                color="blue"
                type="submit"
                variant="outline"
                isLoading={isLoading}
                className="self-center"
              >
                {t("hospitalsAdmin_edit_hospital_button")}
              </Button>
            ) : null}

            {error ? <span className="text-red text-center">{error}</span> : null}
          </form>
        </FormProvider>
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};
