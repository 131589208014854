import { AppLevelSection, sectionStore } from "@eolas-medical/core";
import { TeamMember } from "../types";
import { useMemo } from "react";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

interface UseTeamMembers {
  teamMembers: TeamMember[];
}

const useTeamMembers = (): UseTeamMembers => {
  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.meetTheTeam) ?? {
    id: "",
  };

  const overrideChildrenOrder = sectionStore.getChildrenOrder(id);

  const { files } = useGetFiles({ sectionId: id, overrideChildrenOrder });

  const teamMembers: TeamMember[] = useMemo(() => {
    return files.map((f) => ({
      id: f.id,
      name: f.name || "",
      role: f.description || "",
      bio: f.infoTwo,
      photoUrl: f.key,
      createdAt: f.createdAt,
      updatedAt: f.updatedAt,
      isHighlighted: false,
    }));
  }, [files]);

  return { teamMembers };
};

export default useTeamMembers;
