import { InnerModalWrapper, ModalBody, ModalHeader, SuccessModal, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import TeamMemberForm from "../TeamMemberForm/TeamMemberForm";
import useAddTeamMember from "../../../../data/useAddTeamMember";
import { useCallback } from "react";
import { TeamMemberFormValues } from "../../hooks/useTeamMemberForm";

interface AddTeamMemberProps {
  onCloseModal: () => void;
}

const AddTeamMemberModal: React.FC<AddTeamMemberProps> = ({ onCloseModal }: AddTeamMemberProps) => {
  const { t } = useTranslation();
  const { addTeamMember, addingTeamMember, addTeamMemberSuccessful, addTeamMemberError } =
    useAddTeamMember();

  const handleSubmitTeamMember = useCallback(
    ({ name, role, bio, photo }: TeamMemberFormValues) => {
      addTeamMember({ name, role, bio, photo });
    },
    [addTeamMember],
  );

  if (addTeamMemberSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text={"meetTheTeam_add_staff_success"} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("meetTheTeam_add_modal_title")}</Title>
      </ModalHeader>
      <ModalBody>
        <TeamMemberForm
          isLoading={addingTeamMember}
          error={addTeamMemberError}
          onSubmit={handleSubmitTeamMember}
        />
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default AddTeamMemberModal;
