import { ChildReference, ClinicalCalculatorsSubSection, hasStringProp } from "@eolas-medical/core";

type EolasCalculatorChildReference = ChildReference & { icon: ClinicalCalculatorsSubSection };

export const isClinicalCalculatorsSubSectionType = (
  arg: string,
): arg is ClinicalCalculatorsSubSection => {
  return Object.values<string>(ClinicalCalculatorsSubSection).includes(arg);
};

export const isChildRefEolasCalculator = (
  ref: ChildReference,
): ref is EolasCalculatorChildReference => {
  return hasStringProp(ref, "icon") && isClinicalCalculatorsSubSectionType(ref.icon);
};
