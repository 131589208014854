import React, { useEffect, useRef } from "react";
import { Grid, AutoSizer, WindowScroller } from "react-virtualized";
import { useTranslation } from "react-i18next";
import { CaretUpIcon, noFilesFound } from "Assets";
import { IconButton } from "UIKit/Button";
import { useScrollToTop } from "./useScrollToTop";
import { LottieWithHeader } from "UIKit/LottieWithHeader";

interface VirtualGridProps<T> {
  items: T[];
  emptyListLabel?: string;
  renderItem(item: T): React.ReactNode;
}

export const VirtualGrid = <T extends { id: string }>({
  items,
  emptyListLabel,
  renderItem,
}: VirtualGridProps<T>) => {
  const { t } = useTranslation();
  const { scrollToTop, goToTop } = useScrollToTop();
  const gridRef = useRef<Grid>(null);

  const renderGridCell = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    key: string;
    rowIndex: number;
    style: React.CSSProperties;
  }) => {
    const index = rowIndex * getColumnCount() + columnIndex;
    if (index >= items.length) {
      return null;
    }
    return (
      <div key={key} style={style} className="p-4">
        {renderItem(items[index])}
      </div>
    );
  };

  const getColumnCount = () => {
    if (window.innerWidth >= 1536) {
      return 5;
    } else if (window.innerWidth >= 1280) {
      return 4;
    } else if (window.innerWidth >= 1024) {
      return 3;
    } else if (window.innerWidth >= 640) {
      return 2;
    }
    return 1;
  };

  const getTotalHeight = () => {
    const rowCount = Math.ceil(items.length / getColumnCount());
    const rowHeight = 300;
    return rowCount * rowHeight;
  };

  const handleResize = () => {
    if (gridRef.current) {
      gridRef.current.recomputeGridSize();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (items.length === 0) {
    return (
      <LottieWithHeader
        animationSize="125px"
        animation={noFilesFound}
        lottieOptions={{ loop: true }}
        text={emptyListLabel ?? t("general_no_subsection")}
      />
    );
  }

  return (
    <>
      <WindowScroller>
        {({ isScrolling }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <Grid
                ref={gridRef}
                width={width}
                height={getTotalHeight()}
                rowCount={Math.ceil(items.length / getColumnCount())}
                columnCount={getColumnCount()}
                columnWidth={width / getColumnCount()}
                rowHeight={300}
                scrollToAlignment="start"
                isScrolling={isScrolling}
                cellRenderer={renderGridCell}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
      {scrollToTop && (
        <IconButton
          color="blue"
          onClick={goToTop}
          icon={<CaretUpIcon />}
          className="fixed bottom-2 right-2"
        />
      )}
    </>
  );
};
