import React, { useMemo, useCallback, useEffect } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";
import { SearchBox } from "UIKit/SearchBox/SearchBox";
import { ClickSearchBox, Loader } from "UIKit";
import { useSortOrder } from "Hooks/useSortOrder";
import { ListOrderByOption } from "Components/EolasFileList/ListOrderBy";
import { DragIcon } from "Assets";
import { DropResult } from "react-beautiful-dnd";
import { VirtualGrid, VirtualSortableList } from "../SortableList";
import EmptyLocalList from "../EmptyLocalList/EmptyLocalList";

export type SortFn = <T>(a: T, b: T) => number;

export type SearchType = "debounced" | "click";
export interface DragResult<T> {
  newPrevItem?: T;
  reorderedItem: T;
  newNextItem?: T;
  dropResult?: DropResult;
}

export interface SearchSortListItem {
  id: string;
  isDragDisabled?: boolean;
}

export type AdditionalClickSearchProps = {
  onClickSearch?: () => void;
  onClearSearch?: () => void;
  searchType: Extract<SearchType, "click">;
};

export type AdditionalDebounceSearchProps = { searchType?: Extract<SearchType, "debounced"> };

export type SearchSortListProps<T extends SearchSortListItem> = {
  items: T[];
  renderItem: (item: T, isDragging?: boolean, isSelected?: boolean) => React.ReactNode;
  className?: string;
  isSearchable?: boolean;
  isSortable?: boolean;
  emptyItemsLabel?: string;
  emptyItemsComponent?: React.ReactNode;
  isDragable?: boolean;
  defaultSort?: ListOrderByOption;
  displayMode?: "list" | "grid";
  sortDateBy?: "createdAt" | "updatedAt" | "favouritedOn";
  infoText?: string;
  hasFavourites?: boolean;
  isLoading?: boolean;
  sortFn?(first: T, second: T): number;
  onSearchInputChange?: (inputText: string) => void;
  onSortMethodChange?: (sortFn?: SortFn) => void;
  onDragEnd?: ({ newPrevItem, reorderedItem, newNextItem, dropResult }: DragResult<T>) => void;
  onClickSearch?: () => void;
  onClearSearch?: () => void;
  value?: string;
  placeholderSearchText?: string;
  hasEmptyListNavigation?: boolean;
  emptySearchPlaceholderText?: string;
} & (AdditionalClickSearchProps | AdditionalDebounceSearchProps);

/**
 * @deprecated use EolasVersatileList instead
 */
export const SearchSortList = <T extends SearchSortListItem>({
  items,
  renderItem,
  className,
  isSearchable = false,
  isSortable = false,
  emptyItemsLabel = "Nothing found",
  emptyItemsComponent = null,
  isDragable = false,
  displayMode = "list",
  sortDateBy = "updatedAt",
  defaultSort = "dragAndDrop",
  infoText,
  hasFavourites = false,
  isLoading,
  searchType,
  sortFn,
  onSearchInputChange = () => {
    return;
  },
  onSortMethodChange,
  onDragEnd = () => {
    return;
  },
  onClickSearch = () => {
    return;
  },
  onClearSearch = () => {
    return;
  },
  value,
  placeholderSearchText,
  emptySearchPlaceholderText,
  hasEmptyListNavigation = true,
}: SearchSortListProps<T>) => {
  const [SortComponent, { orderBy, sortMethod }] = useSortOrder({
    initialOrder: defaultSort,
    isDraggable: isDragable,
    sortDateBy,
    favourites: hasFavourites,
    sortFn,
  });

  /**
   * When isSortable it calls the onSortMethodChange callback
   * every time the sortMethod changes
   */
  useEffect(() => {
    if (isSortable && onSortMethodChange) {
      onSortMethodChange(sortMethod);
    }
  }, [isSortable, onSortMethodChange, sortMethod]);

  /**
   * Calls the onSearchInputChange callback
   */
  const handleSearchInputChanged = useCallback(
    (text: string) => {
      if (isSearchable && onSearchInputChange) {
        onSearchInputChange(text);
      }
    },
    [onSearchInputChange, isSearchable],
  );

  /**
   * Updated the intermediate list with a reordered version of the items
   */
  const handleDragEnd = useCallback(
    (dropResult: DropResult) => {
      const { source, destination } = dropResult;

      if (!destination) return;
      if (source.index === destination.index) return;

      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;

      const newItems = [...items];
      const [reorderedItem] = newItems.splice(sourceIndex, 1);
      newItems.splice(destinationIndex, 0, reorderedItem);

      const newIndex = newItems.indexOf(reorderedItem);
      const newPrevItem = newItems[newIndex - 1]; // returns undefined for -1
      const newNextItem = newItems[newIndex + 1];

      onDragEnd({ newPrevItem, reorderedItem, newNextItem, dropResult });
    },
    [onDragEnd, items],
  );

  const canDrag = useMemo(
    () => isDragable && items.length > 1 && orderBy === "dragAndDrop",
    [isDragable, items, orderBy],
  );

  const listEmptyComponent = () => {
    return value && value.length > 0 ? (
      <EmptyLocalList
        isSearchResult
        emptySearchPlaceholderText={emptySearchPlaceholderText}
        showGoToMainButton={hasEmptyListNavigation}
      />
    ) : (
      <EmptyLocalList
        isSearchResult={false}
        showGoToMainButton={hasEmptyListNavigation}
        emptySearchPlaceholderText={emptySearchPlaceholderText}
      />
    );
  };

  const renderList = () => {
    if (isLoading) return <Loader className="bg-transparent h-30vh" />;

    if (items?.length === 0 && emptyItemsComponent) {
      return <>{emptyItemsComponent}</>;
    }

    return (
      <>
        {isSortable && items.length > 1 && <div className="mb-6">{SortComponent}</div>}
        {canDrag && (
          <div
            className="items-center justify-center space-x-2 mb-6 hidden sm:flex"
            data-testid="drag-message"
          >
            <DragIcon width={16} height={16} className="text-grey-500" />
            <span className="text-center text-grey-mid inline">
              Click and drag to easily rearrange the order
            </span>
          </div>
        )}
        {infoText && <span className="text-center text-grey-darker mb-6">{infoText}</span>}

        <div>
          {displayMode === "grid" ? (
            <VirtualGrid items={items} renderItem={renderItem} />
          ) : (
            <VirtualSortableList
              listType="file"
              items={items}
              onDragEnd={handleDragEnd}
              droppableId="eolas-file-list"
              isDragDisabled={!canDrag}
              emptyListComponent={listEmptyComponent()}
              emptyListLabel={emptyItemsLabel}
              renderItem={renderItem}
            />
          )}
        </div>
      </>
    );
  };

  const renderSearchBox = () => {
    if (value?.length === 0 && (!items || items.length === 0)) {
      return null;
    }
    if (searchType === "click") {
      return (
        <ClickSearchBox
          value={value}
          onChangeText={handleSearchInputChanged}
          onClickSearch={onClickSearch}
          onClearSearch={onClearSearch}
          placeholder={placeholderSearchText}
          isLoading={isLoading && Boolean(value?.length)}
        />
      );
    }
    return (
      <SearchBox onChangeText={handleSearchInputChanged} placeholder={placeholderSearchText} />
    );
  };

  return (
    <div data-testid="eolas-list" className={tw(`flex flex-col space-y-6 pb-8 ${className}`)}>
      {isSearchable && renderSearchBox()}
      <div data-testid="eolas-list-items-container">{renderList()}</div>
    </div>
  );
};
