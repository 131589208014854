import { useForm } from "react-hook-form";
import CreatePasswordModal from "./CreatePasswordModal";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createEolasPasswordValidation } from "Utilities/authValidations";
import { useTranslation } from "react-i18next";

interface UseCreatePasswordStep {
  title?: "newAccount" | "resetPassword";
  canProceed: boolean;
  isLoading: boolean;
  error?: string;
  onSubmit: (password: string) => void;
  onEnter: () => void;
}

const useCreatePasswordStep = ({
  canProceed,
  isLoading,
  error,
  onSubmit,
  onEnter,
  title = "newAccount",
}: UseCreatePasswordStep): Step<"create-password"> => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    password: createEolasPasswordValidation("passwordValidation_password_required"),
    confirmPassword: createEolasPasswordValidation(
      "passwordValidation_confirm_password_required",
    ).oneOf([yup.ref("password"), null], "passwordValidation_match_required"),
  });

  const { control, handleSubmit, formState, watch, getValues, reset } = useForm({
    defaultValues: {
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitPassword = async () => {
    const password = getValues("password");
    onSubmit(password);
  };

  const handlePrev = () => {
    reset();
  };

  return {
    name: "create-password",
    title:
      title === "newAccount" ? t("createAccount_password_title") : t("login_changePassword_title"),
    component: (
      <CreatePasswordModal control={control} watch={watch} onSubmit={onEnter} error={error} />
    ),
    onSubmit: handleSubmit(handleSubmitPassword),
    onPrev: handlePrev,
    canProceed,
    backDisabled: true,
    isLoading,
    submitError: !!error || !!formState.errors.password,
  };
};

export default useCreatePasswordStep;
