import { useMutation } from "@tanstack/react-query";
import { getEntityIdSuffix, myFavouritesClient, myFavouritesStore } from "@eolas-medical/core";
import {
  CreateFavouriteBody,
  Favourite,
} from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { useTranslation } from "react-i18next";
import { useNotifications } from "Components/Notifications";

export const useMarkAsFavourite = (entityId?: string) => {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const mutateFavourite = async (favouriteDetails: CreateFavouriteBody): Promise<Favourite> => {
    return myFavouritesClient.createFavourite(favouriteDetails);
  };

  const { mutateAsync, isLoading, isSuccess, error } = useMutation(mutateFavourite, {
    mutationKey: ["createFavourite", entityId],
    onSuccess: async (favourite) => {
      const suffix = getEntityIdSuffix(favourite.entityType);
      myFavouritesStore.updateMap({
        entityId: favourite.entityId + suffix,
        favourite,
        userAction: "add",
      });

      const link = (
        <a target="_self" href="/my-profile/my-favourites" className="text-blue-500">
          {t("my_favourites_here")}
        </a>
      );

      const description = (
        <>
          {t("my_favourites_added")} {link}
        </>
      );

      showNotification({
        type: "success",
        description,
        autoHideTimeout: 5000,
      });
    },
    onError: () =>
      showNotification({
        type: "error",
        description: t("repository_item_favourite_add_error"),
        autoHideTimeout: 5000,
      }),
  });

  return {
    markAsFavourite: mutateAsync,
    isMarkingAsFavourite: isLoading,
    didMarkAsFavouriteSuccess: isSuccess,
    markAsFavouriteError: error,
  };
};

export default useMarkAsFavourite;
