import { CommunityLevelSection } from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { getMainSectionIdentity } from "modules/mainSections/helpers";

export const getNatResourceEntityTypeBasedOnMainSectionId = (mainSectionId: string) => {
  const mainSectionType = getMainSectionIdentity(mainSectionId).mainSectionType;
  switch (mainSectionType) {
    case CommunityLevelSection.clinicalCalculators:
      return FavouriteEntityType.CALCULATOR;
    case CommunityLevelSection.nationalGuidelines:
      return FavouriteEntityType.GUIDELINE;
    case CommunityLevelSection.communityPatientLeaflets:
      return FavouriteEntityType.LEAFLET;
    default:
      return FavouriteEntityType.GUIDELINE;
  }
};
