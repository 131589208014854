import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useClickOutside, useMediaQuery } from "Hooks";
import { sectionStore, userStore } from "@eolas-medical/core";

import { logout } from "Pages/Authentication/auth.actions";
import { BaseAppRoute } from "Utilities/types";

import { useRoleSwitcher } from "./useRoleSwitcher";
import { useQueryClient } from "@tanstack/react-query";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

export const useMobileDrawer = () => {
  const media = useMediaQuery();
  const { push } = useHistory();
  const drawerRef = React.useRef(null);
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = React.useState(false);
  const { isOnAdminPanel, showSwitcher, switchToAdmin, switchToUser } = useRoleSwitcher();
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const { onClearSpace } = useSpacesContext();

  const onClose = React.useCallback(() => setIsOpen(false), []);
  const onOpen = React.useCallback(() => setIsOpen(true), []);

  useClickOutside(drawerRef, onClose);

  const _switchToAdmin = React.useCallback(() => {
    switchToAdmin();
    onClose();
  }, [switchToAdmin, onClose]);

  const _switchToUser = React.useCallback(() => {
    switchToUser();
    onClose();
  }, [switchToUser, onClose]);

  const onLogout = React.useCallback(async () => {
    setIsLoggingOut(true);
    queryClient.clear();
    await logout();
    onClearSpace();
    onClose();
    push("/");
  }, [push, onClose, onClearSpace, queryClient]);

  const onSwitchOrganisation = React.useCallback(() => {
    sectionStore.clearStore();
    sectionStore.setIsAdmin(false);
    userStore.signOutOrganisation();
    queryClient.clear();
    onClearSpace();
    onClose();
    push(`/${BaseAppRoute.selectSpace}`);
  }, [push, queryClient, onClearSpace, onClose]);

  const drawerMotionProps = React.useMemo(() => {
    if (media === "md") {
      return {
        initial: { x: "300%" },
        animate: { x: "200%" },
        exit: { x: "300%" },
      };
    }

    if (media === "sm") {
      return {
        initial: { x: "200%" },
        animate: { x: "100%" },
        exit: { x: "200%" },
      };
    }

    return {
      initial: { x: "130%" },
      animate: { x: "30%" },
      exit: { x: "130%" },
    };
  }, [media]);

  return {
    isOpen,
    isOnAdminPanel,
    drawerRef,
    showSwitcher,
    drawerMotionProps,
    isLoggingOut,
    onOpen,
    onClose,
    onLogout,
    onSwitchOrganisation,
    switchToUser: _switchToUser,
    switchToAdmin: _switchToAdmin,
  };
};
