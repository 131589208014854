import { useCallback, useMemo } from "react";
import { DragResult, SearchSortList } from "shared/components/Lists";
import { useLocalDbSearch } from "shared/hooks/useLocalSearch";
import { ContentRepositoryId, ContentSection } from "modules/contentRepository/types";
import { ContentSectionItem } from "../ContentSectionItem/ContentSectionItem";
import { ContentSectionVariants } from "shared/pages/ContentRepository/types";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { LoadMore } from "UIKit";
import {
  ContentItem,
  isBlobContentItem,
  isEolasEditorContentItem,
  isFlashCardContentItem,
  isLinkContentItem,
} from "@eolas-medical/core";
import ContentItemInstance from "shared/pages/ContentRepository/ContentItems/components/ContentItemInstance/ContentItemInstance";
import { isContentSection } from "modules/contentRepository/typeguards";
import { useContentForSearch } from "shared/hooks/useContentForSearch";

interface ContentSectionListProps {
  isAdmin: boolean;
  variant: ContentSectionVariants;
  disableReorder?: boolean;
  contentRepositoryId?: ContentRepositoryId;
  currentSectionId?: string;
}

const ContentSectionList = ({
  isAdmin,
  variant,
  disableReorder,
  contentRepositoryId,
  currentSectionId,
}: ContentSectionListProps) => {
  const { useContentSectionsHook, useReorderContentHook, limitedAccessHelpers } =
    useContentRepositoryContext();
  const {
    contentSections: unfilteredContentSections,
    contentSectionsLoading,
    hasMoreSections,
    fetchMoreSections,
    isFetchingMoreSections,
  } = useContentSectionsHook;

  const { reorderContent } = useReorderContentHook;

  const contentSections = useMemo(() => {
    if (!limitedAccessHelpers) {
      return unfilteredContentSections;
    }
    const { filterFnForChildren } = limitedAccessHelpers;
    return unfilteredContentSections.filter(({ id }) => {
      return filterFnForChildren(id);
    });
  }, [unfilteredContentSections, limitedAccessHelpers]);

  const {
    data: { map: idsToInclude },
  } = useContentForSearch(currentSectionId ?? contentRepositoryId ?? "");

  const { searchInput, searchResult, onSetSearchInput } = useLocalDbSearch({
    idsToInclude,
  });

  const handleDragSection = useCallback(
    ({ dropResult }: DragResult<ContentSection>) => {
      if (dropResult && !disableReorder) {
        reorderContent(dropResult);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reorderContent],
  );

  const renderItem = useCallback(
    (contentSection: ContentSection) => {
      const isDraggable = isAdmin && searchInput?.length === 0 && !disableReorder;

      return (
        <ContentSectionItem
          contentSection={contentSection}
          isAdmin={isAdmin}
          variant={variant}
          isDraggable={isDraggable}
        />
      );
    },
    [isAdmin, searchInput, variant, disableReorder],
  );

  const renderSearchResult = useCallback(
    (searchResult: ContentItem | ContentSection) => {
      if (isContentSection(searchResult)) {
        return (
          <ContentSectionItem
            contentSection={searchResult}
            isAdmin={isAdmin}
            variant={variant}
            isDraggable={false}
          />
        );
      }

      if (
        isBlobContentItem(searchResult) ||
        isLinkContentItem(searchResult) ||
        isEolasEditorContentItem(searchResult) ||
        isFlashCardContentItem(searchResult)
      ) {
        if (searchResult.isDraft && !isAdmin) {
          return null;
        }

        return (
          <ContentItemInstance
            contentItem={searchResult}
            data-testid={`content-item-${searchResult.id}`}
            isAdminMode={isAdmin}
            isDraggable={false}
            isSearchResult
            isCopyableStatus={isAdmin ? "fromTileDropDown" : undefined}
          />
        );
      }
    },
    [isAdmin, variant],
  );

  return (
    <div data-testid="content-section-list">
      {searchInput.length > 2 ? (
        <SearchSortList<ContentItem | ContentSection>
          items={searchResult}
          isSearchable
          renderItem={renderSearchResult}
          onSearchInputChange={onSetSearchInput}
          value={searchInput}
        />
      ) : (
        <SearchSortList<ContentSection>
          items={contentSections}
          renderItem={renderItem}
          onSearchInputChange={onSetSearchInput}
          isSearchable
          isDragable={Boolean(searchInput?.length === 0 && isAdmin && !disableReorder)}
          isLoading={contentSectionsLoading}
          onDragEnd={handleDragSection}
          value={searchInput}
        />
      )}

      {hasMoreSections ? (
        <LoadMore
          isLoading={isFetchingMoreSections || false}
          onLoadMore={() => fetchMoreSections && fetchMoreSections()}
        />
      ) : null}
    </div>
  );
};

export default ContentSectionList;
