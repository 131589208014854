import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  ModalBody,
  FilePicker,
  ProgressBar,
  FilePickerProps,
  ProgressBarProps,
} from "UIKit";

import { useAllowedFileTypes } from "Hooks";
import { FilePickerAllowedTypes } from "Utilities/types";

export type ChildrenProps = FilePickerProps & ProgressBarProps;
export interface UploadFileContentProps extends ChildrenProps {
  error?: string;
  isLoading: boolean;
  onSubmit: () => void;
  allowedFileTypes: FilePickerAllowedTypes[];
  fileType?: FilePickerAllowedTypes;
}

export const UploadFileContent: React.FC<UploadFileContentProps> = ({
  allowedFileTypes,
  fileType,
  progress,
  isLoading,
  error = "",
  onSubmit,
  ...props
}: UploadFileContentProps) => {
  const [type, selectFileType] = useState<FilePickerAllowedTypes>(fileType || "mp4");
  const { t } = useTranslation();
  const fileTypes = useAllowedFileTypes(allowedFileTypes);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <ModalBody className="add-file__modal-body">
      <form onSubmit={handleSubmit} className="add-file-form">
        <div className="add-file-form__content max-h-60vh">
          <FilePicker
            {...props}
            required
            fileType={type}
            onSelectType={selectFileType}
            allowedFileTypes={fileTypes}
          />

          {isLoading && progress > 0 && <ProgressBar progress={progress} />}
        </div>

        {props.value && (
          <Button
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
            style={{ minHeight: "3rem" }}
            className="add-file__submit-button"
          >
            {t("general_submit")}
          </Button>
        )}

        {error && <span className="text-center text-red">{error}</span>}
      </form>
    </ModalBody>
  );
};
