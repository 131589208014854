import { PlacesType, DataAttribute as RTDataAttribute } from "react-tooltip";
import { getKeys } from "Utilities/helpers";

export const EOLAS_TOOLTIP_ID = "eolas-tooltip";

type DataAttribute = RTDataAttribute | "id";

type DataAttrs = {
  [key in DataAttribute]?: unknown;
};

export const useToolTip = () => {
  const makeToolTipDataAttr = ({
    text,
    place,
  }: {
    text: string;
    place?: PlacesType;
  }): Record<string, unknown> => {
    const toolTipAttrs: DataAttrs = {
      content: text,
      id: EOLAS_TOOLTIP_ID,
      place,
    };
    const attrPrefix = "data-tooltip-";

    const dataAttrs = getKeys(toolTipAttrs).reduce<Record<string, unknown>>((acc, key) => {
      acc[`${attrPrefix}${key}`] = toolTipAttrs[key];
      return acc;
    }, {});

    return dataAttrs;
  };

  return {
    makeToolTipDataAttr,
  };
};
