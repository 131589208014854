import { useRunOnMountUnmount } from "Hooks";
import { useState } from "react";
import { globalWaitForHydration } from "Utilities/appStartup";

export const useContentDbStoreHydration = () => {
  const [isContentDbStoreHydrated, setIsContentDbStoreHydrated] = useState(false);

  useRunOnMountUnmount({
    onMount: async () => {
      await globalWaitForHydration();
      setIsContentDbStoreHydrated(true);
    },
  });

  return { isHydrated: isContentDbStoreHydrated };
};
