import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { WizardState } from "../../../types";
import { hasProp } from "@eolas-medical/core";
import { useGetMediaName } from "../../../hooks/useGetMediaName";

export type BlobValues = { blob: File | undefined };

const MAX_SIZE_2_GB_IN_BYTES = 2147483648;

export const useSelectFileBlobStep = (state: WizardState) => {
  const { t } = useTranslation();

  let hasExistingMedia =
    Boolean(state.fileId) && !state.isExistingFileWithNewType && Boolean(state.dto.key);

  if (state.isExistingFileWithNewType) {
    hasExistingMedia =
      hasProp(state.dto.newProperties, "key") && Boolean(state.dto.newProperties.key);
  }

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      blob: hasExistingMedia
        ? Yup.mixed()
            .nullable()
            .test("fileSize", t("repository_less_than_2_gb"), function (value) {
              if (!value) {
                return true;
              }
              return value.size <= MAX_SIZE_2_GB_IN_BYTES;
            })
        : Yup.mixed()
            .nullable()
            .required(t("repository_file_required"))
            .test("fileSize", t("repository_less_than_2_gb"), function (value) {
              if (!value) {
                return true;
              }
              return value.size <= MAX_SIZE_2_GB_IN_BYTES;
            }),
    });
  }, [t, hasExistingMedia]);

  const { control, handleSubmit } = useForm<BlobValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: state.blob ? { blob: state.blob } : undefined,
  });

  const computedName = useGetMediaName(state);

  const mediaName = hasExistingMedia ? computedName : null;

  return { control, handleSubmit, mediaName };
};
