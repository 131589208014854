import { sectionStore } from "@eolas-medical/core";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { getOrganisationRoute, getSpaceRoute } from "Pages/Spaces/helpers";
import { Button, Text } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { observer } from "mobx-react-lite";
import useSpace from "modules/spaces/data/useSpace";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const EmptyLocalList = observer(
  ({
    isSearchResult,
    showGoToMainButton = true,
    emptySearchPlaceholderText,
  }: {
    isSearchResult: boolean;
    showGoToMainButton?: boolean;
    emptySearchPlaceholderText?: string;
  }) => {
    const { t } = useTranslation();
    const { replace } = useHistory();
    const { activeTab } = useEolasNavigation();
    const { isAdmin: isOnAdminView, appID } = sectionStore;
    const { space } = useSpace(appID);

    let locationToReplace = getSpaceRoute(space);

    if (activeTab === "knowledge") {
      locationToReplace = `/${BaseAppRoute.knowledge}`;
    }
    if (activeTab === "organisation") {
      locationToReplace = getOrganisationRoute(space);
    }

    let text = t("general_noFilesFound");

    if (isSearchResult) {
      if (emptySearchPlaceholderText) {
        text = emptySearchPlaceholderText;
      } else if (isOnAdminView) {
        text = t("general_no_results_found");
      } else {
        text = t("general_no_results_try_master_search");
      }
    }

    return (
      <div className="flex flex-col justify-around items-center h-64 mt-16">
        <Text level={1}>{text}</Text>
        {isSearchResult && showGoToMainButton && !isOnAdminView ? (
          <Button onClick={() => replace(locationToReplace)} className="self-center  mt-4">
            {t("general_no_results_go_to_main")}
          </Button>
        ) : null}
      </div>
    );
  },
);

export default EmptyLocalList;
