import {
  GeneralCategory,
  GuidanceReference,
  NiceSummaryItem,
  SummaryType,
  hasProp,
  hasStringProp,
} from "@eolas-medical/core";

export const isNiceSummaryType = (type: string): type is SummaryType => {
  return type === "json" || type === "pdf";
};

export const isNiceSummaryItem = (item: unknown): item is NiceSummaryItem => {
  return (
    hasProp(item, "keywords") &&
    hasStringProp(item, "type") &&
    isNiceSummaryType(item.type) &&
    !hasStringProp(item, "mainSectionID")
  );
};

export const isGeneralCategory = (
  item: GeneralCategory | GuidanceReference | NiceSummaryItem,
): item is GeneralCategory => {
  return hasStringProp(item, "title") && hasProp(item, "children");
};

export const isGuidanceReference = (
  item: GeneralCategory | GuidanceReference,
): item is GuidanceReference => {
  return hasStringProp(item, "guidanceId");
};
export const isGeneralCategoryArray = (
  array: Array<GeneralCategory | GuidanceReference>,
): array is GeneralCategory[] => {
  return isGeneralCategory(array[0]);
};

export const isGuidanceReferenceArray = (
  array: Array<GeneralCategory | GuidanceReference>,
): array is GuidanceReference[] => {
  return isGuidanceReference(array[0]);
};
