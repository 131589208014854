import cciLogo from "./logos/cci.png";

function CCILogo() {
  return (
    <div className="w-10 h-10">
      <img src={cciLogo} />
    </div>
  );
}

export const customLogos = {
  cci: CCILogo,
};
