import { ComponentType } from "react";
import { DraggableProvided, DropResult } from "react-beautiful-dnd";

const sortMethodsArray = ["dragAndDrop", "alphabetical", "date", "favourites"] as const;
export type SortMethods = (typeof sortMethodsArray)[number];

export const isSortMethod = (value: string): value is SortMethods => {
  return Object.values<string>(sortMethodsArray).includes(value);
};

type BaseProps<T, C extends EolasVersatileListContext<T>> = {
  /**
   * The list of items to render
   */
  items: T[];
  /**
   * The context object that will be passed to the ListEmptyComponent and the renderItem function
   */
  context?: C;
  /**
   * This should be defined outside of a Component
   * If you need additional context to be passed to this function, you can use the context prop
   * */
  renderItem: (args: EolasVersatileListRenderItemProps<T, C>) => React.ReactNode;
  /**
   * A function that returns a unique key for each item in the list
   */
  keyExtractor?: (item: T) => string;
  /**
   * If true, the list will be draggable
   */
  isDraggable?: boolean;
  /**
   * If true, the list will be searchable
   */
  isSearchable?: boolean;
  /**
   * If true, the list will be sortable
   */
  isSortable?: boolean;
  /**
   * If true, the list will be selectable
   */
  isSelectable?: boolean;
  /**
   * Specify the id of the dom element you want the list to attach its scroll references too
   */
  scrollParentId?: string;
  /**
   * The component to render when the list is empty (overrides the default empty component)
   */
  ListEmptyComponent?: ComponentType<{
    context: C;
  }>;
  /**
   * The class name to apply to the list container
   */
  className?: string;
  /**
   * If true, the list is being rendered in a modal
   * This will prevent the list from attaching the page-container as the scroll parent
   */
  isInModal?: boolean;
  /**
   * The class name to apply to the container that wraps the list, this sits outside of the scroll parent
   */
  outerContainerClassName?: string;
  /**
   * The class name to apply to the container that wraps the list items, this sits inside of the scroll parent
   */
  innerContainerClassName?: string;
};

export type DraggableListProps = {
  /**
   * The id of the droppable section this should be unique to this list
   * @required if isDraggable is true
   */
  droppableId: string;
  /**
   * A function that will be called when the user drops an item in the list
   * @required if isDraggable is true
   */
  onDragEnd: (props: EolasVersatileListOnDropResult) => void;
};

export type SearchableListProps = {
  /**
   * This component uses SearchBoxMobx to handle search functionality
   * therefore this prop is required if isSearchable is true
   * You can obtain this by using the useSearchBoxMobx hook
   * @required if isSearchable is true
   */
  searchInstanceId: string;
  /**
   * A function that will be called when a search is triggered
   * refer to the searchMode prop for when this function will be called
   * @required if isSearchable is true and searchMode is "click"
   */
  onClickSearch?: (value: string) => void;
  /**
   * A function that will be called when the user clears the search input
   */
  onClearSearch?: () => void;
  /**
   * The search mode to use, this will determine when the onSearch function will be called
   */
  searchMode?: "debounce" | "click";
  /**
   * An override for the placeholder text in the search input
   */
  searchPlaceholder?: string;
  /**
   * Can be used when search method is async to show a loading spinner
   */
  isSearchLoading?: boolean;
};

export type SortableListProps<T> = {
  /**
   * The default sort method to use
   */
  defaultSortMethod?: SortMethods;
  /**
   * A function that will be called when the user changes the sort method
   * This can be used when needing to sort the items in a custom way (e.g. via an API call)
   * Important: This function should exist outside of a component or be memoized
   */
  onSortMethodChange?: (sortMethod: SortMethods) => void;
  /**
   * An array of sort methods that should be disabled
   */
  disabledSortMethods?: SortMethods[];

  /**
   * Should be specified if the items in the list have a date property that should be used for sorting
   * when the sortMethod is set to "date"
   * Important: The selected key must be compatible with the useSortOrder hook
   * @default "updatedAt"
   *
   */
  sortDateBy?: Extract<keyof T, string>;
};

export type SelectableListProps<T> = {
  /**
   * The currently selected items
   * @required if isSelectable is true
   */
  selectedItems: Record<string, T>;
  /**
   * The available menu actions
   */
  menuActions?: ListMenuAction[];
  /**
   * A function that will be called when the user triggers a menu action
   */
  onMenuAction?: (event: ListMenuActionEvent<T>) => void;
};

export type EolasVersatileListRenderItemProps<
  T,
  C extends EolasVersatileListContext<T> = EolasVersatileListContext<T>,
> = {
  /**
   * The item to render
   */
  item: T;
  /**
   * The context object that was passed to the list
   */
  context: C;
  /**
   * If true, the item is currently being dragged
   */
  isDragging: boolean;
};

export type EolasVersatileListSortFn<T> = (a: T, b: T) => number;

/**
 * The result of a drag and drop operation
 * Refer to the react-beautiful-dnd documentation for more information
 */
export type EolasVersatileListOnDropResult = DropResult;

/**
 * @access private (not to be used outside of the EolasVersatileList component)
 */
export type DraggableItemProps<T, C extends EolasVersatileListContext<T>> = {
  provided: DraggableProvided;
  isDragging: boolean;
  item: T;
  renderItem: BaseProps<T, C>["renderItem"];
};

export type EolasVersatileListContext<T, O = unknown> = {
  /**
   * Set to true if the list is currently loading
   */
  isInitialLoading: boolean;
  /**
   * Indicates if the list is selectable or not (controlled via the isSelectable prop)
   */
  isListSelectable?: boolean;
  /**
   * Indicates if the list is draggable or not (controlled via the isDraggable prop)
   */
  isListDraggable?: boolean;
  /**
   * The custom text to display when the list is empty
   */
  listEmptyText?: string;
  /**
   * The currently selected items
   * This is only available if the list is selectable
   * The key should be the unique key of the item (You can use the keyExtractor prop to specify this)
   */
  selectedItems?: Record<string, T>;
  /**
   * The currently selected sort method
   */
  availableMenuActions?: ListMenuAction[];
} & O;

export type ListMenuAction = "selectAll" | "copyTo" | "moveTo" | "delete";

type SelectionActionEvent<T> = {
  type: "selectAll" | "deselectAll";
  selectedItems: Record<string, T>;
};

type CopyToActionEvent<T> = {
  type: "copyTo";
  selectedItems: Record<string, T>;
};

type MoveToActionEvent<T> = {
  type: "moveTo";
  selectedItems: Record<string, T>;
};

type DeleteActionEvent<T> = {
  type: "delete";
  selectedItems: Record<string, T>;
};

export type ListMenuActionEvent<T> =
  | SelectionActionEvent<T>
  | CopyToActionEvent<T>
  | MoveToActionEvent<T>
  | DeleteActionEvent<T>;

export type EolasVersatileListProps<T, C extends EolasVersatileListContext<T>> = BaseProps<T, C> &
  Partial<DraggableListProps> &
  Partial<SearchableListProps> &
  Partial<SortableListProps<T>> &
  Partial<SelectableListProps<T>>;
