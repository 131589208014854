/* In the event that a user pastes a URL from their web browser in as a link to other content, we should not allow this as
 * it will not work on mobile. They need to use a deeplink url instead
 */

export const isIncompatibleEolasUrl = (url: string): boolean => {
  const urlLowerCased = url.toLowerCase();
  if (!urlLowerCased.includes("eolasmedical.com")) {
    return false;
  }
  return !urlLowerCased.includes("/landing/");
};
