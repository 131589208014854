import { contentClient, DraftFile, EolasFile } from "@eolas-medical/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRunOnMountUnmount } from "Hooks";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";

const makeGetDraftFileQueryKey = ({
  fileId,
  timeStamp,
  file,
}: {
  fileId: string | null;
  timeStamp?: string | null;
  file: EolasFile | null;
}) => ["getDraftFile", fileId, timeStamp, file];

type UseGetDraftFileOptions = {
  shouldFetchOnMountOnly: true;
};

export const useGetDraftFile = (
  fileId: string | null,
  timeStamp?: string | null,
  options?: UseGetDraftFileOptions,
) => {
  const queryClient = useQueryClient();

  const { file } = useGetFile({ id: fileId, options });

  const timestampForQueryKey = options?.shouldFetchOnMountOnly ? null : timeStamp;

  const { data, isFetching, isError } = useQuery({
    queryKey: makeGetDraftFileQueryKey({ fileId, timeStamp: timestampForQueryKey, file }),
    queryFn: async (): Promise<
      { hasDrafts: true; draftFile: DraftFile } | { hasDrafts: false; draftFile: null }
    > => {
      if (!fileId) {
        throw new Error("useGetDraftFile: No fileId provided. This should not happen");
      }
      if (!file) {
        throw new Error("useGetDraftFile: No file found");
      }
      if (!file.hasDrafts) {
        return { hasDrafts: false, draftFile: null };
      }
      const draftFile = await contentClient.getDraftFile({
        contentId: file.id,
        mainSectionId: file.mainSectionID,
      });
      return { hasDrafts: true, draftFile };
    },
    enabled: Boolean(fileId && file),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useRunOnMountUnmount({
    onUnmount: () => {
      if (!fileId) {
        return;
      }
      queryClient.removeQueries(
        makeGetDraftFileQueryKey({ fileId, timeStamp: timestampForQueryKey, file }),
      );
    },
  });

  return { data, isFetching: !data && isFetching, isError };
};
