import {
  AnalyticsEvents,
  BulkCopyItemsResponse,
  BulkCopyItemsRestParams,
  contentClient,
  ContentItem,
  CopyItemSuccess,
  ShadowCopiesCreatedPayload,
} from "@eolas-medical/core";
import { useBulkActionItems } from "./useBulkActionItems";
import { trackEvent } from "API/Analytics";

export const useCopyItems = ({ onCopySuccess }: { onCopySuccess?: () => void }) => {
  return useBulkActionItems<BulkCopyItemsResponse>({
    actionFn: ({ destinationSectionId, contentItems }) =>
      contentClient.bulkCopyContentItems({
        targetSection: destinationSectionId,
        items: contentItems.map(mapContentItemToCopyItemParam),
      }),
    analyticsFn: ({
      successfulItems,
      destinationSectionId,
    }: {
      successfulItems: CopyItemSuccess[];
      destinationSectionId?: string;
    }) => {
      trackEvent<ShadowCopiesCreatedPayload>(AnalyticsEvents.SHADOW_COPIES_CREATED, {
        numberOfCopies: successfulItems.length,
        fileIds: successfulItems.map((item) => item.value.id),
        destinationSectionId: destinationSectionId || "",
      });
    },
    onActionSuccess: onCopySuccess,
  });
};

// Todo: This map will need to be extended to support other types of content items e.g. knowledge items
const mapContentItemToCopyItemParam = (
  contentItem: ContentItem,
): BulkCopyItemsRestParams["items"][number] => ({
  id: contentItem.id,
  type: "file",
  origin: "section",
});
