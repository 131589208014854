import React, { useReducer, useEffect } from "react";
import SpacesContext from "./spaces.context";
import { Space, SpaceRest, sectionStore, userStore } from "@eolas-medical/core";
import useSpace from "../data/useSpace";
import { removeBracketsAndPercent } from "Utilities/helpers";
import { observer } from "mobx-react-lite";

interface SpacesState {
  isAdmin: boolean;
  selectedSpaceId: string;
}

interface SelectSpaceAction {
  type: "SELECT_SPACE";
  payload: string; // Change this to the specific type you expect
}

interface ClearSpaceAction {
  type: "CLEAR_SPACE";
}

interface SwitchToAdminAction {
  type: "SWITCH_TO_ADMIN";
  payload: boolean;
}

type SpacesAction = SelectSpaceAction | ClearSpaceAction | SwitchToAdminAction;

const spacesReducer = (state: SpacesState, action: SpacesAction): SpacesState => {
  switch (action.type) {
    case "SELECT_SPACE":
      return { ...state, selectedSpaceId: action.payload || "", isAdmin: false };
    case "CLEAR_SPACE":
      return { ...state, selectedSpaceId: "", isAdmin: false };
    case "SWITCH_TO_ADMIN":
      return { ...state, isAdmin: action.payload };
    default:
      return state;
  }
};

interface SpacesProviderProps {
  children: React.ReactNode;
}

export const SpacesProvider = observer(({ children }: SpacesProviderProps) => {
  const storedState = localStorage.getItem("spacesContext");
  const initialState: SpacesState = storedState
    ? JSON.parse(storedState)
    : { isAdmin: false, selectedSpaceId: "" };

  const appId = sectionStore.appID;
  const { isLoggedIn } = userStore.userSession;

  const [state, dispatch] = useReducer(spacesReducer, initialState);

  const { space } = useSpace(isLoggedIn ? state.selectedSpaceId : null);

  const handleSelectSpace = (space: Space | SpaceRest) => {
    dispatch({ type: "SELECT_SPACE", payload: space.id });
  };

  const handleClearSpace = () => {
    dispatch({ type: "CLEAR_SPACE" });
  };

  const handleSwitchToAdmin = (isAdmin: boolean) => {
    dispatch({ type: "SWITCH_TO_ADMIN", payload: isAdmin });
  };

  useEffect(() => {
    localStorage.setItem("spacesContext", JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (!appId) {
      dispatch({ type: "CLEAR_SPACE" });
    }
  }, [appId]);

  const isInSpace = isLoggedIn && state.selectedSpaceId !== "";

  const isPublic = space?.accessType === "public";

  const encodedSelectedSpaceName = space
    ? encodeURIComponent(removeBracketsAndPercent(space.name) || "")
    : undefined;

  return (
    <SpacesContext.Provider
      value={{
        ...state,
        selectedSpace: space,
        encodedSelectedSpaceName,
        isInSpace,
        isPublic,
        onSelectSpace: handleSelectSpace,
        onSwitchToAdmin: handleSwitchToAdmin,
        onClearSpace: handleClearSpace,
      }}
    >
      {children}
    </SpacesContext.Provider>
  );
});
