import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {
  AnalyticsEvents,
  EolasSearchPayload,
  MedusaItem,
  MedusaSectionWithItems,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";
import { useMainSectionVisit } from "Hooks";
import { InnerPageWrapper, NavButton, PageTitle, Modal } from "UIKit";
import { useMedusaFile } from "../useMedusaFile";
import { organisationRoutes } from "Pages/Spaces/pages/Organisation/OrganisationRoutes";
import { BaseAppRoute, ExtractRouteParams, LDFlagNames } from "Utilities/types";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { LocalItemsList } from "shared/components/Lists";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { MedusaFileTile } from "./MedusaFileTile";
import { useLaunchDarkly } from "Contexts";
import { trackEvent } from "API/Analytics";

type Props = RouteComponentProps<ExtractRouteParams<typeof organisationRoutes.medusaFiles>>;

export const MedusaFilesPage = observer(({ match: { params }, history }: Props) => {
  const { t } = useTranslation();
  const { onSelectMedusaFile } = useMedusaFile();
  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();
  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const { flags } = useLaunchDarkly();
  const isShareMedusaItemsEnabled = isShareFileEnabled && !!flags[LDFlagNames.SHARE_MEDUSA_ITEMS];

  useMainSectionVisit({
    mainSectionId: OrganisationLevelSection.medusaMedications,
    subSectionId: params.sectionID,
  });

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;

  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, closeFavouriteModal, isFavouriteModalOpen]);

  const renderMedusaItem = useCallback(
    (medusaItem: MedusaItem) => {
      return (
        <MedusaFileTile
          handleOnShareFile={handleOnShareFile}
          isShareFileEnabled={isShareMedusaItemsEnabled}
          medusaItem={medusaItem}
          onSelectMedusaFile={onSelectMedusaFile}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      );
    },
    [onSelectMedusaFile, isShareMedusaItemsEnabled, handleOnShareFile, showRemoveFavouriteModal],
  );

  const section: MedusaSectionWithItems | undefined = sectionStore.medusaSectionsWithItems?.find(
    (s) => s.id === params.sectionID,
  );

  const goToMedusaMainSectionPath = `/${BaseAppRoute.organisation}/${sectionStore.organisationName}/${OrganisationLevelSection.medusaMedications}`;

  let shouldPaedShowDisclaimer = false;

  if (section?.items[0]) {
    const item = section.items[0];
    shouldPaedShowDisclaimer = item.route !== "Intravenous" && item.type === "paediatric";
  }

  return (
    <InnerPageWrapper>
      <Modal open={isModalOpen} onClose={onCloseModal}>
        {shareModal || removeFavouriteModal}
      </Modal>
      <FileBreadcrumbs
        shouldShowSpaceName={true}
        mainSectionId={
          sectionStore.getChildReferenceByMainSectionType(
            OrganisationLevelSection.medusaMedications,
          )?.id
        }
        parentId={params.sectionID}
      />
      {section ? (
        <>
          <PageTitle
            title={t("medusa_files_title", {
              monoType: section.name,
            })}
            titleClass="capitalize"
            subTitle={shouldPaedShowDisclaimer ? t("medusa_paed_disclaimer") : undefined}
          />
          <NavButton onClick={() => history.push(goToMedusaMainSectionPath)} />
          <LocalItemsList<MedusaItem>
            items={section.items.filter(({ deleted }) => !deleted)}
            keysToSearch={["name"]}
            renderItem={renderMedusaItem}
            searchType="click"
            onSearchAnalytics={(text, hits) => {
              trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
                searchString: text,
                hitCount: hits.length,
                origin: OrganisationLevelSection.medusaMedications,
              });
            }}
          />
        </>
      ) : null}
    </InnerPageWrapper>
  );
});
