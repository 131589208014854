import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppLevelSection, EolasFile, sectionStore } from "@eolas-medical/core";

import { EditButton } from "UIKit";
import { useSortOrder } from "Hooks/useSortOrder";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";

interface DepartmentAreaListProps {
  onEdit?(file: EolasFile): void;
}

export const DepartmentAreaList: React.FC<DepartmentAreaListProps> = observer(({ onEdit }) => {
  const [SortComponent, { sortMethod }] = useSortOrder({ initialOrder: "alphabetical" });
  const [selectedFileId, setSelectedFileId] = useState<string>("");

  const isAdmin = sectionStore.isAdmin;
  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.theDepartment) ?? {
    id: "",
  };

  const { files } = useGetFiles({ sectionId: id });

  const sortedFiles = useMemo(() => {
    // the department tour file should not appear in the department area list
    const filteredFiles = files.filter((file) => !file.key);

    if (sortMethod) {
      return filteredFiles.slice().sort(sortMethod);
    }
    return filteredFiles;
  }, [files, sortMethod]);

  const selectedArea = useMemo(
    () => files.find(({ id }) => selectedFileId === id),
    [selectedFileId, files],
  );

  if (sortedFiles.length === 0) return null;

  return (
    <>
      {sortedFiles.length > 1 ? SortComponent : null}

      <div className="grid grid-cols-3 gap-2 sm:gap-4">
        <div
          className="flex flex-col space-y-4 overflow-y-auto pr-2 sm:pr-4"
          style={{ maxHeight: "60vh" }}
        >
          {sortedFiles.map((file) => {
            const isSelected = selectedFileId === file.id;
            return (
              <div
                key={file.id}
                style={{ minHeight: "3rem" }}
                onClick={() => setSelectedFileId(file.id)}
                className={`
                  flex items-center px-2 hover:bg-grey-300 cursor-pointer rounded-lg
                  ${isSelected ? "bg-grey-200" : ""}
                `}
              >
                <span className="flex-1 font-semibold mr-1 line-clamp-5">{file.name}</span>
                {isAdmin ? (
                  <EditButton
                    onClick={() => {
                      onEdit && onEdit(file);
                    }}
                  />
                ) : null}
              </div>
            );
          })}
        </div>

        <div
          style={{ maxHeight: "60vh" }}
          className="flex items-start text-justify col-span-2 pr-2 overflow-y-auto"
        >
          {selectedArea?.description}
        </div>
      </div>
    </>
  );
});
