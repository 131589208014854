import {
  CreateSectionDto,
  AddContentItemDto as AddContentItemDtoReq,
  EolasFile,
  UpdateContentItemDto,
  AddFlashcardDto,
  ChildReference,
  ContentItem,
  ContentType,
  contentTypeValues,
  LinkContent,
  FlashCardContent,
  BlobContent,
  FileExtensionMediaType,
  isLinkContentItem,
  CreateBaseSectionDto,
  UpdateBaseSectionDto,
  CreateLinkItemDto,
  CreateBlobItemDto,
  CreateFlashcardItemDto,
  UpdateBlobItemDto,
  UpdateLinkItemDto,
  UpdateFlashcardItemDto,
  EolasEditorContent,
  EolasSupportedMiscellaneousType,
  EolasEditorMetadata,
  UpdateFlashcardDto,
  KnowledgeContentDomain,
  KnowledgeContentSection,
  isEolasShadowCopyFile,
  ResourcesManifest,
  eolasLogger,
} from "@eolas-medical/core";
import {
  AddContentSectionDto,
  EditContentSectionDto,
  ContentSection,
  AddContentItemDto,
  MapToUpdateEolasEditorDtoReturnType,
  MapToAddEolasEditorKnowledgeDtoInput,
  MapToAddEolasEditorSpaceDtoInput,
  MapToAddEolasEditorDtoReturnType,
  MapToAddFlashcardKnowledgeDtoInput,
  MapToAddFlashcardSpaceDtoInput,
  MapToAddFlashcardDtoReturnType,
  MapToUpdateFlashcardDtoReturnType,
  MapToUpdateFlashcardKnowledgeDtoInput,
  MapToUpdateFlashcardSpaceDtoInput,
  StringifiedFlashcardMetadata,
} from "./types";
import * as DOMPurify from "dompurify";
import { convertToLink, toAwsJSON } from "Utilities";
import { getIconUrl } from "modules/helpers";
import { getFileExtensionFromString, stringBoolToBool } from "Utilities/helpers";

export const isFileExtensionMediaType = (
  value?: string | null,
): value is FileExtensionMediaType => {
  if (!value) return false;
  return Object.values<string>(FileExtensionMediaType).includes(value);
};

export const mapToContentSection = (
  childReference: ChildReference | KnowledgeContentSection,
): ContentSection => {
  const routeId = /#/.test(childReference.id) ? childReference.id.split("#")[1] : childReference.id;
  return {
    ...childReference,
    routeId,
    iconName: childReference.icon || "",
    iconUrl: getIconUrl(childReference.icon || ""),
  };
};

export const mapToContentItem = (file: EolasFile): ContentItem => {
  let url: string = "";
  let type: ContentType = contentTypeValues[0];
  let expiryDate: string | undefined | null = file.expiryDate || null;

  if (expiryDate === "null") {
    expiryDate = null;
  }

  const commonData = {
    id: file.id,
    mainSectionId: file.mainSectionID,
    parentId: file.parentID,
    ownerId: file.ownerID,
    createdBy: file.createdBy || "",
    name: file.name,
    description: file.description || null,
    type,
    expiryDate,
    createdAt: file.createdAt,
    updatedAt: file.updatedAt,
    keywords: file?.keywords ? file.keywords.join(",") : undefined,
    specialty: file.specialty ? file.specialty.join(",") : undefined,
    isSponsored: stringBoolToBool(file.isSponsored),
    deletedAt: "",
    isDraft: file.isDraft ?? false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: file.metadata as any, // New metadata type differs form previous type
    deepLinkType: isEolasShadowCopyFile(file) ? file.deepLinkType : undefined,
    deepLinkUrl: isEolasShadowCopyFile(file) ? file.deepLinkUrl : undefined,
    key: file.key,
  };

  if (file.key && file.type === "link") {
    url = file.key;
    type = contentTypeValues[1];

    const linkContent: LinkContent = {
      ...commonData,
      type,
      url,
      urlTarget: "BROWSER",
    };

    return linkContent;
  }

  if (file.key && file.type === EolasSupportedMiscellaneousType.FLASHCARD) {
    url = file.key;
    type = contentTypeValues[2];

    const flashcardContent: FlashCardContent = {
      ...commonData,
      type,
      url,
    };

    return flashcardContent;
  }

  if (file.key && file.type !== "flashcard" && file.type !== "link") {
    url = file.key;
  }

  if (file.type === EolasSupportedMiscellaneousType.EOLAS_EDITOR) {
    const type = contentTypeValues[3];

    const eolasEditorContent: EolasEditorContent = {
      ...commonData,
      type,
    };

    return eolasEditorContent;
  }

  let blobType: FileExtensionMediaType;

  if (file.mediaName) {
    const fileExtension = getFileExtensionFromString(file.mediaName);
    blobType = isFileExtensionMediaType(fileExtension) ? fileExtension : FileExtensionMediaType.PDF;
  } else {
    blobType = isFileExtensionMediaType(file.type) ? file.type : FileExtensionMediaType.PDF;
  }

  if (file.type === EolasSupportedMiscellaneousType.DSM) {
    /* FIXME: this hack is due to web relying on ContentItem, which is Knowledge Content Service
     * domain which has no DSM type. The correct way to fix this is to remove the requirement for
     * Web to base everything off ContentItem when considering Space / Org content
     */
    blobType = file.type as unknown as FileExtensionMediaType;
  }

  const blobContent: BlobContent = {
    ...commonData,
    type,
    url,
    media: {
      id: file.mediaId || "",
      type: blobType,
      access: "PUBLIC",
    },
  };

  return blobContent;
};

export const mapToCreateSectionDto = (
  mainSectionId: string,
  parentId: string,
  contentSection: AddContentSectionDto,
): CreateSectionDto => {
  return {
    mainSectionId,
    parentId,
    name: contentSection.name || "",
    icon: contentSection.iconName,
    childrenType: contentSection.childrenType,
    description: contentSection.description || "",
  };
};

export const mapToUpdateSectionDto = (contentSection: EditContentSectionDto) => {
  return {
    id: contentSection.id,
    name: contentSection.name,
    icon: contentSection.iconName,
    description: contentSection.description,
    defaultSortMethod: contentSection.defaultSortMethod,
  };
};

export const mapToAddContentItemDto = async ({
  parentId,
  contentItem,
  key,
  fileType,
  mediaId,
  mediaName,
}: {
  parentId: string;
  contentItem: AddContentItemDto;
  key: string;
  fileType?: string;
  mediaId?: string;
  mediaName?: string;
}): Promise<AddContentItemDtoReq> => {
  if (contentItem.itemType === "blob" && !fileType) {
    throw new Error("FileType must be present for itemType blob");
  }

  let metadata: string | undefined;

  if (contentItem.dsmData) {
    metadata = toAwsJSON({ dsmData: contentItem.dsmData });
  }

  let addContentItemDto: AddContentItemDtoReq = {
    parentId,
    name: contentItem.name,
    description: contentItem.description,
    expiryDate: contentItem?.expiryDate ? contentItem?.expiryDate : undefined,
    key: contentItem.itemType === "link" ? convertToLink(key) : key,
    type: contentItem.itemType === "blob" ? fileType : contentItem.itemType,
    fileType,
    keywords: contentItem.keywords,
    createdBy: contentItem.createdBy,
    isDraft: contentItem.isDraft,
    metadata,
  };

  if (mediaId && mediaName) {
    addContentItemDto = { ...addContentItemDto, mediaId, mediaName };
  }

  if (key) {
    const finalKey = contentItem.itemType === "link" ? convertToLink(key) : key;
    addContentItemDto = { ...addContentItemDto, key: finalKey };
  }

  if (metadata) {
    addContentItemDto = { ...addContentItemDto, metadata };
  }

  return addContentItemDto;
};

export const mapToAddPublicContentItemDto = async ({
  parentId,
  contentItem,
  key,
  fileType,
}: {
  parentId: string;
  contentItem: AddContentItemDto;
  key: string;
  fileType?: string;
}): Promise<AddContentItemDtoReq> => {
  if (contentItem.itemType === "blob" && !fileType) {
    throw new Error("FileType must be present for itemType blob");
  }

  const addContentItemDto: AddContentItemDtoReq = {
    parentId,
    name: contentItem.name,
    description: contentItem.description,
    expiryDate: contentItem?.expiryDate ? contentItem?.expiryDate : undefined,
    key: contentItem.itemType === "link" ? convertToLink(key) : undefined,
    type: contentItem.itemType === "blob" ? fileType : contentItem.itemType,
    fileType,
    keywords: contentItem.keywords,
    createdBy: contentItem.createdBy,
    isDraft: contentItem.isDraft,
  };

  return addContentItemDto;
};

export const mapToUpdateContentItemDto = async (
  contentItem: ContentItem,
  editItem?: Partial<BlobContent | LinkContent>,
): Promise<UpdateContentItemDto> => {
  const fileUpdateDto: UpdateContentItemDto = {
    name: editItem?.name ? editItem.name : undefined,
    description: editItem?.description ? editItem.description : undefined,
    expiryDate: contentItem.expiryDate ? editItem?.expiryDate || null : editItem?.expiryDate, // null removes expiryDate
    keywords: editItem?.keywords ? editItem?.keywords.split(",") : undefined,
    key: editItem?.url ? editItem?.url : undefined,
    isDraft: editItem?.isDraft,
    summaryOfChanges: editItem?.summaryOfChanges ? editItem.summaryOfChanges : undefined,
    metadata: editItem?.metadata ? toAwsJSON(editItem.metadata) : undefined,
  };

  if (isLinkContentItem(contentItem) && editItem?.url) {
    fileUpdateDto.key = editItem.url;
  }

  return fileUpdateDto;
};

export const mapToAddFlashcardDto = <
  T extends MapToAddFlashcardKnowledgeDtoInput | MapToAddFlashcardSpaceDtoInput,
>(
  params: T,
): MapToAddFlashcardDtoReturnType<T> => {
  const {
    parentId,
    isSponsored = false,
    flashcardContent,
    name,
    description,
    specialty,
    createdBy,
    isDraft,
    resourcesManifest,
  } = params;
  const commonParams = { parentId, name, description, createdBy, isDraft };
  const metadata = {
    flashcardContent: encodeURIComponent(DOMPurify.sanitize(flashcardContent)),
    resourcesManifest: resourcesManifest ? encodeURIComponent(resourcesManifest) : undefined,
  };

  let result: AddFlashcardDto | CreateFlashcardItemDto;

  if (params.isKnowledge) {
    let mediaType: FileExtensionMediaType | undefined = undefined;

    const { mainSectionId, queriesForHit, documentIdentifiers } = params;

    if (params.fileType) {
      // TODO: create proper validation
      mediaType = params.fileType as FileExtensionMediaType;
    }
    result = {
      ...commonParams,
      mainSectionId,
      metadata,
      specialty: specialty ? specialty.join(",") : undefined,
      mediaType,
      url: params.url,
      isSponsored,
      queriesForHit,
      documentIdentifiers,
    };
  } else {
    result = {
      ...commonParams,
      key: params.key,
      specialty: specialty || [],
      metadata: toAwsJSON(metadata),
      isSponsored: isSponsored.toString(),
    };
  }
  return result as MapToAddFlashcardDtoReturnType<T>;
};

export const mapToAddEolasEditorDto = <
  T extends MapToAddEolasEditorKnowledgeDtoInput | MapToAddEolasEditorSpaceDtoInput,
>(
  params: T,
): MapToAddEolasEditorDtoReturnType<T> => {
  const {
    parentId,
    createdBy,
    name,
    description,
    eolasEditorContent,
    isDraft,
    expiryDate,
    resourcesManifest,
  } = params;
  const eolasEditorCommon = { parentId, createdBy, name, description, isDraft, expiryDate };

  let extraProps: Record<string, unknown>;

  if (params.isKnowledge) {
    extraProps = {
      mainSectionId: params.mainSectionId,
      metadata: { eolasEditorContent, resourcesManifest },
    };
  } else {
    extraProps = {
      metadata: toAwsJSON({ eolasEditorContent, resourcesManifest }),
      type: EolasSupportedMiscellaneousType.EOLAS_EDITOR,
    };
  }

  return { ...eolasEditorCommon, ...extraProps } as MapToAddEolasEditorDtoReturnType<T>;
};

export const mapToUpdateEolasEditorDto = <T extends boolean>({
  contentItem,
  isKnowledge,
}: {
  contentItem: Partial<AddContentItemDto> & { summaryOfChanges?: string };
  isKnowledge: T;
}): MapToUpdateEolasEditorDtoReturnType<T> => {
  let metadata: string | EolasEditorMetadata | undefined;
  const { eolasEditorContent, resourcesManifest, ...rest } = contentItem;

  if (eolasEditorContent) {
    if (isKnowledge) {
      metadata = { eolasEditorContent, resourcesManifest };
    } else {
      metadata = toAwsJSON({ eolasEditorContent, resourcesManifest });
    }
  }

  return {
    ...rest,
    expiryDate: contentItem.expiryDate || null, // null removes expiryDate
    metadata,
  } as MapToUpdateEolasEditorDtoReturnType<T>;
};

// New content-service methods
export const mapToNewCreateSectionDto = ({
  mainSectionId,
  parentId,
  name,
  description,
  iconName,
  domain,
}: {
  mainSectionId: string;
  parentId: string;
  name: string;
  description: string;
  iconName: string;
  domain: Exclude<KnowledgeContentDomain, "sponsoredSlots">;
}): CreateBaseSectionDto => {
  return {
    mainSectionId,
    parentId,
    name,
    icon: iconName,
    description,
    childrenType: "CONTENT",
    domain,
  };
};

export const mapToNewUpdateSectionDto = ({
  name,
  description,
  iconName,
}: {
  name?: string;
  description?: string;
  iconName?: string;
}): UpdateBaseSectionDto => {
  return {
    name,
    description,
    icon: iconName,
  };
};

export const mapToLinkItemDto = ({
  mainSectionId,
  parentId,
  name,
  url,
  createdBy,
  isDraft,
}: {
  mainSectionId: string;
  parentId: string;
  name: string;
  url: string;
  createdBy: string;
  isDraft?: boolean;
}): CreateLinkItemDto => {
  return {
    mainSectionId,
    parentId,
    name,
    url,
    createdBy,
    urlTarget: "BROWSER",
    isDraft,
  };
};

export const mapToUpdateLinkItemDto = (
  editItem?: Partial<BlobContent | LinkContent>,
): UpdateLinkItemDto => {
  const blobUpdateDto: UpdateLinkItemDto = {
    name: editItem?.name ? editItem.name : undefined,
    url: editItem?.url ? editItem.url : undefined,
    isDraft: editItem?.isDraft,
  };

  return blobUpdateDto;
};

export const mapToBlobItemDto = ({
  mainSectionId,
  parentId,
  name,
  fileType,
  createdBy,
  description,
  keywords,
  expiryDate,
  isDraft,
}: {
  mainSectionId: string;
  parentId: string;
  name: string;
  fileType: string;
  createdBy: string;
  description?: string;
  expiryDate?: string;
  keywords?: string[];
  isDraft?: boolean;
}): CreateBlobItemDto => {
  // TODO: create proper validation
  const mediaType: FileExtensionMediaType = fileType as FileExtensionMediaType;

  return {
    mainSectionId,
    parentId,
    name,
    mediaType,
    createdBy,
    description,
    keywords: keywords ? keywords.join(",") : undefined,
    expiryDate,
    isDraft,
  };
};

export const mapToUpdateBlobItemDto = (
  contentItem: BlobContent | LinkContent,
  editItem: Partial<BlobContent | LinkContent>,
  fileType?: string,
): UpdateBlobItemDto => {
  // TODO: create proper validation
  const mediaType = fileType ? (fileType as FileExtensionMediaType) : undefined;

  const blobUpdateDto: UpdateBlobItemDto = {
    name: editItem?.name ? editItem.name : undefined,
    description: editItem?.description ? editItem.description : undefined,
    expiryDate: contentItem.expiryDate ? editItem?.expiryDate || null : editItem?.expiryDate, // null removes expiryDate
    keywords: editItem?.keywords ? editItem?.keywords : undefined,
    isDraft: editItem?.isDraft,
    mediaType,
  };

  return blobUpdateDto;
};

export const mapToUpdateFlashcardDto = <
  T extends MapToUpdateFlashcardKnowledgeDtoInput | MapToUpdateFlashcardSpaceDtoInput,
>(
  params: T,
): MapToUpdateFlashcardDtoReturnType<T> => {
  const {
    name,
    description,
    flashcardContent,
    specialty,
    isSponsored = false,
    isDraft,
    resourcesManifest,
  } = params;
  const commonProps = { name, description, isDraft };
  let result: UpdateFlashcardItemDto | UpdateFlashcardDto;

  let metadata: StringifiedFlashcardMetadata | undefined = undefined;
  if (flashcardContent) {
    metadata = {
      flashcardContent: encodeURIComponent(DOMPurify.sanitize(flashcardContent)),
      resourcesManifest: resourcesManifest ? encodeURIComponent(resourcesManifest) : undefined,
    };
  }

  if (params.isKnowledge) {
    let mediaType: FileExtensionMediaType | undefined = undefined;

    if (params.fileType) {
      // TODO: create proper validation
      mediaType = params.fileType as FileExtensionMediaType;
    }
    result = {
      ...commonProps,
      metadata: metadata,
      specialty: specialty ? specialty.join(",") : undefined,
      isSponsored,
      mediaType,
      url: params.url,
      documentIdentifiers: params.documentIdentifiers,
      queriesForHit: params.queriesForHit,
    };
  } else {
    result = {
      ...commonProps,
      metadata: metadata ? toAwsJSON(metadata) : undefined,
      specialty,
      key: params.imageUrl,
      summaryOfChanges: params.summaryOfChanges,
    };
  }

  return result as MapToUpdateFlashcardDtoReturnType<T>;
};

export const createResourcesManifestFromHtml = async (
  html: string,
  heroImageUrl?: string,
): Promise<ResourcesManifest> => {
  const resourcesManifest: ResourcesManifest = {};
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const images = doc.querySelectorAll("img");

  if (heroImageUrl) {
    const fileSize = await getFileSize(heroImageUrl).catch(() => 0);
    resourcesManifest[heroImageUrl] = {
      type: "image",
      url: heroImageUrl,
      isHero: true,
      fileSize,
    };
  }

  for (const img of images) {
    const src = img.getAttribute("src");

    if (src?.includes("data:image")) {
      eolasLogger.warn("Skipping data URL image in resources manifest");
      continue;
    }

    if (src) {
      const fileSize = await getFileSize(src).catch(() => 0);
      resourcesManifest[src] = {
        type: "image",
        url: src,
        fileSize,
      };
    }
  }

  return resourcesManifest;
};

const getFileSize = (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, true);

    xhr.onreadystatechange = function () {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          const fileSize = parseInt(xhr.getResponseHeader("Content-Length") || "0");
          resolve(fileSize);
        } else {
          reject();
        }
      }
    };

    xhr.send();
  });
};
