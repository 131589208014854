import { AnalyticsEvents } from "@eolas-medical/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { trackEvent } from "API/Analytics";
import useSignInUser from "modules/authentication/data/useSignInUser";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { isEmail } from "Utilities/helpers";
import * as yup from "yup";

export const useExpressSignIn = ({ email }: { email?: string | null } = {}) => {
  const { t } = useTranslation();
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("login_requiredEmail"))
      .test("isValidEmail", t("login_invalidEmail"), function (newValue) {
        if (typeof newValue !== "string") {
          return false;
        }
        return isEmail(newValue);
      }),
    password: yup.string().required(t("login_requiredPassword")),
  });

  const { control, handleSubmit, getValues } = useForm<{ email: string; password: string }>({
    defaultValues: {
      password: "",
      email: email || "",
    },
    resolver: yupResolver(schema),
  });

  const {
    signInUser,
    isSigningIn: isLoading,
    signInError: errorMessage,
  } = useSignInUser({
    onSuccess: () => {
      trackEvent(AnalyticsEvents.CORRECT_PASSWORD_ENTERED_LOGIN_FLOW);
      modalStore.closeModal();
    },
    onError: () => {
      trackEvent(AnalyticsEvents.INCORRECT_PASSWORD_ENTERED_LOGIN_FLOW);
    },
  });

  return {
    control,
    errorMessage,
    isLoading,
    onClickSubmit: handleSubmit(({ email, password }) => {
      signInUser({ email, password });
    }),
    shouldShowPassword,
    setShouldShowPassword,
    getValues,
  };
};
