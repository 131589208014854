import { useState } from "react";

export const useModalState = () => {
  const [modal, setModal] = useState<React.ReactNode>(null);

  const closeModal = () => {
    setModal(null);
  };

  return {
    isOpen: !!modal,
    openModal: setModal,
    closeModal,
    modal,
  };
};
