import { sectionStore } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { contentDbStore } from "../stores/contentDb/contentDb.store";

export const useGetFileCount = (sectionId?: string) => {
  const section = sectionStore.getSection(sectionId ?? "");
  const isSectionPresent = Boolean(section.id);

  const { data } = useQuery({
    queryKey: ["getFileCountInSection", section],
    enabled: isSectionPresent,
    queryFn: async () => {
      const childrenOrder = sectionStore.getChildrenOrder(section.id);
      const allFiles = await contentDbStore.getMultipleItems(childrenOrder);
      const publishedFiles = allFiles.filter(({ isDraft }) => !isDraft);
      return { allFiles: allFiles.length, publishedFiles: publishedFiles.length };
    },
  });

  return data ?? null;
};
