import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { useReorder } from "Hooks";
import { ChildReference } from "Utilities/types";
import { SubSectionTile } from "UIKit";
import { LottieWithHeader } from "UIKit/LottieWithHeader";
import { upload, noFilesFound } from "Assets/LottieAnimations";
import type { IconPickerIconsType } from "Assets/Icons/iconPickerIcons";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { SortableList } from "shared/components/Lists";

export interface SectionListProps {
  sectionID: string;
  isAdmin: boolean;
  onAddItem?(): void;
  onEdit?(sectionReference: ChildReference): void;
  onDelete?(sectionReference: ChildReference): void;
}

export const SectionList: React.FC<SectionListProps> = observer(
  ({
    isAdmin,
    sectionID,
    onDelete = () => {
      return;
    },
    onEdit = () => {
      return;
    },
    onAddItem,
  }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const { childReferences: sectionReferences, isDragDisabled, onDragEnd } = useReorder(sectionID);
    const { getIconUrl } = useGetIconUrl();

    if (isAdmin && sectionReferences.length === 0) {
      return (
        <LottieWithHeader
          animation={upload}
          onClick={onAddItem}
          animationSize="125px"
          lottieOptions={{ loop: true }}
          text={t("general_create_subsection")}
        />
      );
    }

    if (!isAdmin && sectionReferences.length === 0) {
      return (
        <LottieWithHeader
          animationSize="125px"
          animation={noFilesFound}
          lottieOptions={{ loop: true }}
          text={t("general_no_subsection")}
        />
      );
    }

    return (
      <>
        {!isDragDisabled && (
          <span className="text-center text-grey-mid">{t("contentManagement_info")}</span>
        )}

        <SortableList
          onDragEnd={onDragEnd}
          droppableId={"section-list"}
          isDragDisabled={isDragDisabled}
        >
          {sectionReferences.map((sectionReference) => {
            return (
              <Link
                key={sectionReference!.id}
                to={`${pathname}/${encodeURIComponent(sectionReference!.id)}`}
              >
                <SubSectionTile
                  title={sectionReference!.name ?? ""}
                  description={sectionReference!.description ?? ""}
                  image={getIconUrl(sectionReference?.icon || "") as IconPickerIconsType}
                  onEdit={isAdmin ? () => onEdit(sectionReference!) : undefined}
                  onDelete={isAdmin ? () => onDelete(sectionReference!) : undefined}
                />
              </Link>
            );
          })}
        </SortableList>
      </>
    );
  },
);
