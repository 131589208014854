import localforage from "localforage";
import { useCallback } from "react";

interface UsePersistState<T> {
  persistState: (key: string, value: T, onSuccess?: () => void) => void;
  getPersistedState: (key: string, onSuccess?: () => void) => Promise<T | null>;
}
const usePersistState = <T>(): UsePersistState<T> => {
  const persistState = useCallback((key: string, value: unknown, onSuccess?: () => void) => {
    localforage.setItem(key, value, onSuccess);
  }, []);

  const getPersistedState = useCallback((key: string) => {
    return localforage.getItem<T>(key);
  }, []);

  return {
    persistState,
    getPersistedState,
  };
};

export default usePersistState;
