import { get } from "lodash";
import { useHistory } from "react-router-dom";

import { ArrowRightIcon, ArrowLeftIcon } from "Assets/Icons";

import { theme } from "../theme";
import { Button } from "./Button";

type NavButtonDirection = "right" | "left";

export interface NavButtonProps {
  to?: string;
  color?: string;
  className?: string;
  direction?: NavButtonDirection;
  onClick?: () => void;
}

export const NavButton = ({
  to = "/",
  onClick,
  className = "",
  direction = "left",
  color = get(theme, "colors.black.DEFAULT"),
}: NavButtonProps) => {
  const { push } = useHistory();
  const Arrow = direction === "right" ? ArrowRightIcon : ArrowLeftIcon;

  return (
    <Button
      size="md"
      color="grey"
      variant="outline"
      data-testid="go-back"
      className={`${className} self-center rounded-md`}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          push(to);
        }
      }}
    >
      <Arrow width={24} height={20} fill={color} />
    </Button>
  );
};
