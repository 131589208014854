import Avatar from "UIKit/Avatar/Avatar";
import { Loader } from "UIKit/Loader";
import React from "react";

export interface UserList {
  givenName: string;
  familyName: string;
  email: string;
  photoUrl?: string;
  isRead: boolean;
}

type AvatarListProps = {
  usersList?: UserList[];
  RightComponent?: (user: UserList) => React.ReactNode;
  isLoading: boolean;
};

function AvatarList({ usersList, RightComponent, isLoading }: AvatarListProps) {
  if (isLoading) {
    return <Loader className="bg-transparent" />;
  }
  const gridColumns = usersList?.length === 1 ? "grid-cols-1 w-1/2" : "md:grid-cols-2";

  return (
    <div
      className={`grid ${gridColumns} bg-white border border-grey-300 rounded-lg max-h-88 overflow-y-auto overflow-x-hidden`}
    >
      {usersList?.map((user: UserList) => (
        <div key={user.email} className="p-3 border-b-2 border-b-grey-300">
          <Avatar
            name={user.givenName}
            email={user.email}
            imageUrl={user.photoUrl}
            className="h-20 w-full p-2"
            rightComponent={RightComponent ? <RightComponent {...user} /> : undefined}
          />
        </div>
      ))}
    </div>
  );
}

export default AvatarList;
