import { DeleteModal } from "UIKit";

import { useFileOperations } from "Hooks";
import { errorStore } from "Stores/ErrorStore";
import { useRequestStatus } from "Utilities";
import { useTranslation } from "react-i18next";
import { EolasFile } from "@eolas-medical/core";

interface DeleteHospitalModalProps {
  hospital: EolasFile | null;
  onCloseModal(): void;
}

export const DeleteHospitalModal: React.FC<DeleteHospitalModalProps> = ({
  hospital,
  onCloseModal,
}: DeleteHospitalModalProps) => {
  const { removeFile } = useFileOperations();
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();
  const { t } = useTranslation();

  const onDelete = () => {
    setRequestStatus({ status: "pending", error: "" });

    removeFile({
      variables: {
        id: hospital?.id,
        parentID: hospital?.parentID,
      },
    })
      .then(() => {
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: removeFile,
          retryParameters: {
            variables: {
              id: hospital?.id,
              parentID: hospital?.parentID,
            },
          },
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  };

  return (
    <DeleteModal
      isLoading={isLoading}
      onDelete={onDelete}
      error={error}
      title={hospital?.name ?? ""}
      isSuccessful={isSuccessful}
      onCloseModal={onCloseModal}
      description={t("deleteModal_delete_hospital_description")}
    />
  );
};
