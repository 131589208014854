import { ApiClient, ApiClientParams } from "@eolas-medical/core";
import { FeedbackStatus } from "modules/portfolio/types";

class PortfolioService extends ApiClient {
  private path = "/portfolio/v1";

  public async deleteEvent(eventId: string) {
    return this.http.delete(`${this.path}/events/${eventId}`);
  }

  public async deleteFeedback(feedbackId: string, deleteEvent = false) {
    return this.http.delete(
      `${this.path}/feedbacks/${feedbackId}${deleteEvent ? "?deleteEvent=true" : ""}`,
    );
  }

  public async patchFeedback(feedbackId: string, status?: FeedbackStatus) {
    return this.http.patch(`${this.path}/feedbacks/${feedbackId}/status`, { status });
  }

  public async getEvent(eventId: string) {
    return this.http.get(`${this.path}/events/${eventId}`);
  }

  public async getAttachment(attachmentId: string) {
    return this.http.get(`${this.path}/attachments/${attachmentId}?includeS3DownloadUrl=true`);
  }

  public async exportEvent(eventId: string, docType: string) {
    const response = await this.http.post(`${this.path}/events/${eventId}/reports`, {
      type: docType,
    });
    return response.data;
  }

  public async exportPortfolio(ownerId: string, docType: string) {
    const response = await this.http.post(`${this.path}/reports`, {
      type: docType,
      ownerId,
    });
    return response.data;
  }
}

export let portfolioService: PortfolioService;

export const initialisePortfolioService = (params: ApiClientParams) => {
  portfolioService = new PortfolioService(params);
};
