import { Button } from "UIKit";
import {
  ExpiringPeriod,
  expiringPeriods,
} from "modules/contentRepository/data/useExpiringContentItems";
import { useTranslation } from "react-i18next";

type ExpiringPeriodFiltersProps = {
  selectedPeriod: ExpiringPeriod;
  onClickFilter: (period: ExpiringPeriod) => void;
};
const ExpiringPeriodFilters = (props: ExpiringPeriodFiltersProps) => {
  const { t } = useTranslation();

  const labels: Record<ExpiringPeriod, string> = {
    0: t("expiredPage_expired_button_label"),
    1: t("expiredPage_1month_button_label"),
    3: t("expiredPage_3month_button_label"),
    6: t("expiredPage_6month_button_label"),
    12: t("expiredPage_12month_button_label"),
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
      {expiringPeriods.map((period) => (
        <ExpirySelectionTile
          key={period.toString()}
          {...props}
          relevantSelection={period}
          label={labels[period]}
        />
      ))}
    </div>
  );
};

const ExpirySelectionTile = ({
  selectedPeriod,
  onClickFilter,
  relevantSelection,
  label,
}: ExpiringPeriodFiltersProps & { relevantSelection: ExpiringPeriod; label: string }) => {
  return (
    <Button
      className="flex-1"
      onClick={() => onClickFilter(relevantSelection)}
      variant={selectedPeriod === relevantSelection ? "solid" : "outline"}
      color={selectedPeriod === relevantSelection ? "blue" : "white"}
      size="sm"
    >
      {label}
    </Button>
  );
};

export default ExpiringPeriodFilters;
