import { useMemo } from "react";
import { addMonths } from "date-fns";
import { ContentItem, isFlashCardContentItem } from "@eolas-medical/core";
import { ContentRepositoryId } from "../types";
import { NO_EXPIRY_DATE } from "Utilities/types";
import { useGetAllContentRepository } from "shared/hooks/useGetAllContentRepository";

export const expiringPeriods = [0, 1, 3, 6, 12] as const;

export type ExpiringPeriod = (typeof expiringPeriods)[number];

interface UseExpiringContentItemsProps {
  contentRepositoryId: ContentRepositoryId;
  expiringPeriod: ExpiringPeriod;
}

interface UseExpiringContentItems {
  relevantItems: ContentItem[];
  isFetching: boolean;
}

const useExpiringContentItems = ({
  contentRepositoryId,
  expiringPeriod,
}: UseExpiringContentItemsProps): UseExpiringContentItems => {
  const {
    data: { list },
    isFetching,
  } =
    useGetAllContentRepository({
      contentRepositoryId,
      mode: "list",
      shouldDebounce: true,
    }) ?? [];

  const relevantItems: ContentItem[] = useMemo(() => {
    const today = new Date();
    const expiresIn = addMonths(new Date(), expiringPeriod);

    return list
      .filter((file) => {
        if (isFlashCardContentItem(file)) return false;
        if (!file.expiryDate || file.expiryDate === NO_EXPIRY_DATE) return false;
        const fileExpiryDate = new Date(file.expiryDate);

        if (expiringPeriod === 0) {
          return fileExpiryDate.valueOf() < today.valueOf();
        }

        return (
          fileExpiryDate.valueOf() >= today.valueOf() &&
          fileExpiryDate.valueOf() < expiresIn.valueOf()
        );
      })
      .sort((a, b) => {
        if (!a.expiryDate || a.expiryDate === NO_EXPIRY_DATE) return -1;
        if (!b.expiryDate || b.expiryDate === NO_EXPIRY_DATE) return 1;
        const aDate = new Date(a.expiryDate);
        const bDate = new Date(b.expiryDate);
        return aDate.valueOf() - bDate.valueOf();
      });
  }, [list, expiringPeriod]);

  return {
    isFetching,
    relevantItems,
  };
};

export default useExpiringContentItems;
