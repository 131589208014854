import { Core } from "@pdftron/webviewer";
import { useGetNiceItemUrl } from "modules/niceGuidelines/data/useGetNiceItemUrl";
import {
  getChildBookmark,
  navigateToGuidancePage,
  processId,
} from "modules/niceGuidelines/helpers";
import { useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { handleLinkClick } from "../functions/handleLinkClick";
import { clinicalQuestionStore, hasStringProp } from "@eolas-medical/core";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { sanitiseUrl } from "Utilities/helpers";

import { EolasDocumentViewerProps } from "UIKit/EolasDocumentViewer/types";

export const useNiceGuidelineViewer = (id: string) => {
  const { niceSectionId, niceSubsectionId, pageNo } = useGetSearchParams({
    paramList: ["niceSectionId", "niceSubsectionId", "pageNo"],
  });
  const bookmarkNames = useMemo(
    () => ({ parent: processId(niceSectionId), child: processId(niceSubsectionId) }),
    [niceSectionId, niceSubsectionId],
  );
  const pageToScrollToOnGoBack = useRef<{ guidanceId: string; page: number } | null>(null);

  const history = useHistory();

  const { url: pdfUrl, title } = useGetNiceItemUrl(id);

  let documentViewerProps: EolasDocumentViewerProps | null = null;

  if (pdfUrl) {
    let startingPage = pageNo && !Number.isNaN(pageNo) ? Number(pageNo) : undefined;
    if (pageToScrollToOnGoBack.current && id === pageToScrollToOnGoBack.current.guidanceId) {
      startingPage = pageToScrollToOnGoBack.current.page;
    }
    documentViewerProps = {
      url: pdfUrl,
      startingPage: startingPage,
      reRenderBehaviour: "onceOnMount",
      fileName: `${title}.pdf`,
      onLoadFile: (WebViewerInstance) => {
        const {
          Core: { Annotations, Actions, documentViewer },
          UI: { searchTextFull },
        } = WebViewerInstance;

        Actions.setCustomOnTriggeredHandler(
          // @ts-expect-error This seems to be a bug with the types, as docs give this as an example:
          Actions.URI,
          async (target, event, documentViewer, options) => {
            if (target instanceof Annotations.Link) {
              // Again typing is wrong:
              const action: unknown = options.action;
              if (!hasStringProp(action, "uri")) {
                return;
              }
              const targetUri = action.uri;
              const sanitisedUrl = sanitiseUrl(targetUri);
              const { outcome, guidanceNumber, subtype, bookmarkNames } =
                handleLinkClick(sanitisedUrl);
              if (outcome === "failure") {
                options.originalOnTriggered(target, event, documentViewer);
              }
              if (outcome === "success") {
                const currentPage = documentViewer.getCurrentPage();
                pageToScrollToOnGoBack.current = { guidanceId: id, page: currentPage };
                // jump links within the current document of structure 'guidance/ng1234/chapter/section-name' are not handled automatically by webviewer, therefore we handle manually here
                if (guidanceNumber === id) {
                  if (bookmarkNames) {
                    await scrollToBookmark(documentViewer, bookmarkNames);
                  } else {
                    options.originalOnTriggered(target, event, documentViewer);
                  }
                }
                if (guidanceNumber !== id) {
                  navigateToGuidancePage(history, guidanceNumber, subtype, bookmarkNames);
                }
              }
            } else {
              options.originalOnTriggered(target, event, documentViewer);
            }
          },
        );

        if (pageToScrollToOnGoBack.current?.guidanceId === id) {
          // if the IDs match, we have come back to the same guidance page, so we can reset this:
          pageToScrollToOnGoBack.current = null;
        }
        if (clinicalQuestionStore.textToHighlight.length) {
          searchTextFull(clinicalQuestionStore.textToHighlight);
        } else if (
          hasStringProp(bookmarkNames, "parent") ||
          hasStringProp(bookmarkNames, "child")
        ) {
          scrollToBookmark(documentViewer, bookmarkNames);
        }
      },
    };
  }

  return {
    documentViewerProps,
    title,
  };
};

export const scrollToBookmark = async (
  documentViewer: Core.DocumentViewer,
  bookmarkNames: { parent?: string; child?: string },
) => {
  const allBookmarks = await documentViewer.getDocument().getBookmarks();
  const bookmarkParents = allBookmarks[0].getChildren();

  if (hasStringProp(bookmarkNames, "parent")) {
    const matchParent = bookmarkParents.find((bookmark) => {
      return bookmark.getName().toLowerCase().includes(bookmarkNames.parent);
    });
    if (matchParent) {
      if (!hasStringProp(bookmarkNames, "child")) {
        documentViewer.displayBookmark(matchParent);
      } else {
        const bookmarkChildren = matchParent.getChildren();
        const matchChild = bookmarkChildren.length
          ? getChildBookmark(bookmarkChildren, bookmarkNames.child)
          : null;
        documentViewer.displayBookmark(matchChild ? matchChild : matchParent);
      }
    }
  } else if (bookmarkNames.child) {
    const matchChild = getChildBookmark(bookmarkParents, bookmarkNames.child);
    if (matchChild) {
      documentViewer.displayBookmark(matchChild);
    }
  }
};
