import { CommunityLevelSection, EolasFile, sectionStore } from "@eolas-medical/core";

import {
  EOLAS_BODY_WEIGHT_CALC_ICON_NAME,
  EOLAS_CALCULATOR,
  EOLAS_CR_CL_CALC_ICON_NAME,
  EOLAS_GENT_CALC_ICON_NAME,
} from "Pages/Knowledge/pages/ClinicalCalculators/types";

export type EolasCalcItem = EolasFile & { icon: string };
export const getEolasCalcs = ({
  isEolasGentCalcEnabled,
  isEolasCreatinineCalcEnabled,
  isEolasIdealBodyWeightCalcEnabled,
}: {
  isEolasGentCalcEnabled: boolean;
  isEolasCreatinineCalcEnabled: boolean;
  isEolasIdealBodyWeightCalcEnabled: boolean;
}) => {
  const clinicalCalcsMainsectionRef = sectionStore
    .getMainSectionList("community")
    .find(
      ({ icon, disabled }) =>
        icon === CommunityLevelSection.clinicalCalculators && disabled !== "true",
    );

  const files: EolasCalcItem[] = [];
  if (clinicalCalcsMainsectionRef) {
    const mainSectionID = clinicalCalcsMainsectionRef.id;
    const section = sectionStore.getSection(mainSectionID);
    section?.childrenOrder?.forEach((childReference) => {
      if (
        !childReference ||
        !childReference.name ||
        childReference.disabled === "true" ||
        // This is to hide eolas calculators that have been excluded for the current organisation
        (childReference.icon &&
          sectionStore.excludedEolasCalculatorIcons.includes(childReference.icon)) ||
        (childReference.icon === EOLAS_GENT_CALC_ICON_NAME && !isEolasGentCalcEnabled) ||
        (childReference.icon === EOLAS_CR_CL_CALC_ICON_NAME && !isEolasCreatinineCalcEnabled) ||
        (childReference.icon === EOLAS_BODY_WEIGHT_CALC_ICON_NAME &&
          !isEolasIdealBodyWeightCalcEnabled)
      ) {
        return;
      }
      files.push({
        id: childReference.id,
        name: childReference.name,
        description: EOLAS_CALCULATOR,
        mainSectionID,
        ownerID: sectionStore.data.community?.id ?? "",
        parentID: mainSectionID,
        searchField: childReference.name + "clinical calculator",
        sharedID: "",
        deleted: "false",
        createdAt: "",
        updatedAt: "",
        isDraft: false,
        icon: childReference.icon ?? "",
      });
    });
  }
  return files;
};
