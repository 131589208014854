import { useTranslation } from "react-i18next";
import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { useExpressSignIn } from "./hooks/useExpressSignIn";
import { FormElement, Input, Text } from "UIKit";
import { MailIcon, PadlockIcon } from "Assets";
import ShowPasswordIcon from "../ShowPasswordIcon/ShowPasswordIcon";
import useDeviceType from "Hooks/useDeviceType/useDeviceType";
import { modalStore } from "Stores/ModalStore";

export type ExpressSignInModalProps = {
  email?: string | null;
  onChangeWizard: (redirect: "forgot-password" | "confirm-email", email?: string) => void;
};

export const ExpressSignInModal = ({ email, onChangeWizard }: ExpressSignInModalProps) => {
  const { t } = useTranslation();

  const {
    isLoading,
    errorMessage,
    onClickSubmit,
    control,
    shouldShowPassword,
    setShouldShowPassword,
    getValues,
  } = useExpressSignIn({ email });

  const deviceType = useDeviceType();

  return (
    <EolasWizardModalWrapper
      header={{ title: t("landing_page_sign_in"), subTitle: t("login_enter_email_password") }}
      buttonRowProps={{
        forwardButton: {
          text: t("landing_page_sign_in"),
          onClick: onClickSubmit,
          onSubmitHtmlForm: onClickSubmit,
          isLoading,
        },
      }}
    >
      <FormElement required control={control} id="email" labelClassName="font-semibold">
        {(field) => (
          <Input
            {...field}
            autoFocus={deviceType === "desktop"}
            autoComplete="username"
            iconLeft={<MailIcon />}
            data-testid="sign-in-email"
            placeholder={t("login_enterEmail")}
          />
        )}
      </FormElement>
      <FormElement id="password" control={control} errorMessage={errorMessage}>
        {(field) => (
          <Input
            {...field}
            iconLeft={<PadlockIcon />}
            placeholder={t("login_enterPassword")}
            type={shouldShowPassword ? "text" : "password"}
            autoComplete="current-password"
            data-testid="validate-password"
            iconRight={
              <ShowPasswordIcon
                showPassword={shouldShowPassword}
                onClick={() => setShouldShowPassword((prev) => !prev)}
              />
            }
            className="mt-1"
          />
        )}
      </FormElement>
      <div className="flex items-center justify-center cursor-pointer mt-6">
        <Text
          level={1}
          className="text-blue-500"
          onClick={() => {
            modalStore.closeModal();
            onChangeWizard("forgot-password", getValues("email"));
          }}
        >
          {t("login_forgotPassword")}
        </Text>
      </div>
      <div className="flex items-center justify-center cursor-pointer mt-2">
        <Text
          level={1}
          className="text-blue-500"
          onClick={() => {
            modalStore.closeModal();
            onChangeWizard("confirm-email", getValues("email"));
          }}
        >
          {t("login_confirm_email")}
        </Text>
      </div>
    </EolasWizardModalWrapper>
  );
};
