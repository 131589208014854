import { useMemo } from "react";
import { useGetFile } from "./useGetFiles";
import { constructFileBreadcrumbProps } from "Components/FileBreadcrumbs/functions/constructBreadcrumbProps";

export const useConstructFileBreadcrumbProps = ({ id }: { id: string }) => {
  const { file } = useGetFile({ id });

  return useMemo(() => {
    return constructFileBreadcrumbProps(file);
  }, [file]);
};
