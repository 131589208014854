import { FeatureGuides, UserData, profilesClient, userStore } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";

export const useCompleteFeatureGuide = () => {
  const userID = userStore.userID;
  const completeFeatureGuide = async (featureGuide: FeatureGuides): Promise<UserData> => {
    return profilesClient.updateFeatureGuideStatus(userID, {
      target: "web",
      completedGuides: [featureGuide],
    });
  };

  const { mutateAsync } = useMutation(completeFeatureGuide, {
    mutationKey: ["completeFeatureGuide", userID],
    onSuccess: () => (response: UserData) => {
      userStore.updateData({
        user: { ...userStore.userData, featureGuides: response.featureGuides },
      });
    },
  });

  return { completeFeatureGuide: mutateAsync };
};
