import { useState } from "react";
import { Space } from "@eolas-medical/core";
import { Modal } from "UIKit";

import SpaceTile from "../../../SpaceTile/SpaceTile";
import { PreviewSpaceModal } from "../PreviewSpaceModal/PreviewSpaceModal";

export interface DiscoverSpaceItemProps {
  space: Space;
}

export const DiscoverSpaceItem: React.FC<DiscoverSpaceItemProps> = ({
  space,
}: DiscoverSpaceItemProps) => {
  const [showRequestAccess, setShowRequestAccess] = useState(false);

  const handleShowModal = () => {
    setShowRequestAccess(true);
  };

  const handleCloseModal = () => {
    setShowRequestAccess(false);
  };

  return (
    <>
      <SpaceTile
        name={space.name}
        description={space.description}
        membersCount={space.userCount}
        imageUrl={space.image?.url}
        isPrivate={space.accessType === "private"}
        specialties={space.specialty || []}
        onClick={handleShowModal}
      />
      <Modal open={showRequestAccess} onClose={handleCloseModal}>
        <PreviewSpaceModal space={space} />
      </Modal>
    </>
  );
};
