import { useTranslation } from "react-i18next";
import { Text } from "./Typography";

export const PatientInfoDisclaimer = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Text level={1} className={"text-red-500 text-center w-full my-4"}>
        {t("patient_identifiable_disclaimer")}
      </Text>
    </div>
  );
};
