import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { IssuesIcon } from "Assets";

import { DeleteButton } from "../Button";
import { FileTileWrapper, FileTileProps } from "./shared";

export interface IncidentReportFileTileProps extends FileTileProps {
  hideIcon?: boolean;
}

export const IncidentReportFileTile: React.FC<IncidentReportFileTileProps> = ({
  canDrag,
  hideIcon,
  eolasFile,
  isDragging,
  onDelete = () => {
    return;
  },
  onSelectFile = () => {
    return;
  },
}: IncidentReportFileTileProps) => {
  const { t } = useTranslation();

  const { createdAt } = eolasFile;
  const info = JSON.parse(eolasFile.info ?? "");
  const author = JSON.parse(eolasFile.moreInfo ?? "");

  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      {!hideIcon && (
        <div className="w-8">
          <IssuesIcon
            width={24}
            height={24}
            className={eolasFile.moreInfoTwo === "notResolved" ? "text-red" : "hidden"}
          />
        </div>
      )}

      <div className="flex flex-col flex-1">
        <span className="text-lg font-semibold mb-1 line-clamp-2">{eolasFile.name}</span>

        <span className="text-sm mb-2 text-grey-dark line-clamp-1">{`${t(
          "incident_reporting_reported_by",
        )}: ${author.name}`}</span>

        <span className="text-sm text-grey-dark">{`${t("incident_reporting_event_date")}: ${format(
          new Date(info.date ?? ""),
          "do MMM y, HH:mm",
        )}`}</span>

        <span className="text-sm text-grey-dark">{`${t(
          "incident_reporting_date_submitted",
        )}: ${format(new Date(createdAt as string), "do MMMM yyyy, HH:mm")}`}</span>
      </div>

      {!hideIcon && (
        <DeleteButton
          onClick={() => onDelete(eolasFile)}
          data-testid={`deleteButtonFor-${eolasFile.name}`}
        />
      )}
    </FileTileWrapper>
  );
};
