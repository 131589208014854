import { DownloadFileButton } from "Components";
import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";

export const IncidentDocumentFileTile: React.FC<FileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  onSelectFile = () => {
    return;
  },
  ...fileControls
}: FileTileProps) => {
  return (
    <FileTileWrapper
      className="h-40"
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-3 self-start">
        <span
          className="text-lg font-semibold line-clamp-1 leading-10"
          title={eolasFile.name ?? ""}
        >
          {eolasFile.name}
        </span>
      </div>

      <div className="w-fit self-start flex flex-col items-end justify-between h-full">
        <DownloadFileButton eolasFile={eolasFile} />
      </div>

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...fileControls} />}
    </FileTileWrapper>
  );
};
