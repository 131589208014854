import { isSupportedFileTypeWebOnly } from "@eolas-medical/core";
import { CaretRight } from "Assets/Icons/monocolored";
import { RootIcon } from "Pages/Spaces/components/RootIcons";
import { SelectTileListContext } from "Pages/Spaces/components/SelectTileList/SelectTileList";
import { ListItem } from "Pages/Spaces/components/SelectTileList/hooks/useLocalChildrenList";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { EolasFileTile } from "UIKit/EolasFileTile/EolasFileTile";
import { EolasRadio } from "UIKit/EolasRadio/EolasRadio";
import { EolasSectionTile } from "UIKit/EolasSectionTile/EolasSectionTile";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { FileTypeItemIcon } from "UIKit/FileTypeIcon/FileTypeIcon";
import { getIconUrl } from "modules/helpers";

export const LocalDocumentsListItem = ({
  item,
  context,
}: EolasVersatileListRenderItemProps<ListItem, SelectTileListContext<ListItem>>) => {
  const {
    handleNavigateDown,
    selectedItems,
    handleAddToSelection,
    handleRemoveFromSelection,
    handleClearSelection,
    areNavigableSectionsSelectable,
  } = context;
  const isSelected = Boolean(selectedItems && selectedItems[item.id]);
  const hasSelection = Object.keys(selectedItems ?? {}).length > 0;

  const { file } = useGetFile({ id: item.childrenType === "none" ? item.item.id : undefined });

  const handleToggle = () => {
    if (isSelected) {
      handleRemoveFromSelection(item.id);
      return;
    }
    handleAddToSelection(item.id, item);
  };

  const handleClick = () => {
    if (item.childrenType !== "none") {
      // For this modal, we want to clear the selection when navigating
      if (hasSelection) {
        handleClearSelection();
      }
      handleNavigateDown(item);
      return;
    }

    handleToggle();
  };
  if (item.childrenType === "mainSections") {
    return (
      <div className="p-1">
        <EolasTile
          onClick={handleClick}
          variant="rectangular"
          primaryText={item.item.title}
          icon={<RootIcon root={item.rootType} />}
          rightAction={<CaretRight className="h-6 w-6 text-grey-600" />}
        />
      </div>
    );
  }

  if (item.childrenType === "none") {
    if (!file) {
      return null;
    }
    return (
      <div className="p-1">
        <EolasFileTile
          variant="basic"
          title={file.name}
          isDisabled={item.isDisabled}
          iconComponent={
            <div className="flex items-center justify-center h-full w-full">
              <FileTypeItemIcon
                type={isSupportedFileTypeWebOnly(file.type) ? file.type : undefined}
                className="w-12 h-12"
              />
            </div>
          }
          onClick={handleClick}
          rightAction={
            <EolasRadio
              isDisabled={item.isDisabled}
              variant="checkCircle"
              size="xs"
              isChecked={isSelected}
              onToggle={handleToggle}
            />
          }
        />
      </div>
    );
  }

  const sectionItem = item.item;
  const sectionItemIconUrl = sectionItem.icon ? getIconUrl(sectionItem.icon) : undefined;
  const isSectionSelectable =
    areNavigableSectionsSelectable && !item.isDisabled && sectionItem.isGCR;

  return (
    <div className="p-1">
      <EolasSectionTile
        isDisabled={item.isDisabled}
        onClick={handleClick}
        variant="withChildDetails"
        title={sectionItem.name ?? ""}
        iconUrl={sectionItemIconUrl ?? ""}
        childrenType={item.childrenType}
        rightAction={<CaretRight className="h-6 w-6 text-grey-600" />}
        leftAction={
          isSectionSelectable ? (
            <EolasRadio
              isDisabled={item.isDisabled}
              variant="checkCircle"
              size="xs"
              isChecked={isSelected}
              onToggle={handleToggle}
            />
          ) : undefined
        }
      />
    </div>
  );
};
