import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const useGetClinicalCalcStatus = () => {
  const { flags } = useLaunchDarkly();
  const isEolasGentCalcEnabled = !!flags[LDFlagNames.EOLAS_GENTAMICIN_CALC];
  const isEolasCreatinineCalcEnabled = !!flags[LDFlagNames.EOLAS_CREATININE_CLEARANCE_CALC];
  const isEolasIdealBodyWeightCalcEnabled = !!flags[LDFlagNames.EOLAS_IDEAL_BODY_WEIGHT_CALC];
  return {
    isEolasCreatinineCalcEnabled,
    isEolasGentCalcEnabled,
    isEolasIdealBodyWeightCalcEnabled,
  };
};
