/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Blob = {
  __typename?: "Blob";
  getURL?: Maybe<Scalars["String"]>;
  mediaId: Scalars["String"];
  size: Scalars["Int"];
  type: Scalars["String"];
  uploadURL?: Maybe<Scalars["String"]>;
};

export type FileBySection = {
  __typename?: "FileBySection";
  files: Array<Maybe<SectionFile>>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Link = {
  __typename?: "Link";
  url: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  filesBySection: Array<Maybe<FileBySection>>;
};

export type SectionFile = {
  __typename?: "SectionFile";
  blob?: Maybe<Blob>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isFavourite?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<Link>;
  name: Scalars["String"];
  prevFileId?: Maybe<Scalars["String"]>;
  referencesCount: Scalars["Int"];
  sectionId?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  updatedAt?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type FilesBySectionQueryVariables = Exact<{ [key: string]: never }>;

export type FilesBySectionQuery = {
  __typename?: "Query";
  filesBySection: Array<{
    __typename?: "FileBySection";
    id: string;
    name: string;
    icon?: string | null;
    files: Array<{
      __typename?: "SectionFile";
      id: string;
      sectionId?: string | null;
      userId: string;
      name: string;
      type: string;
      referencesCount: number;
      isFavourite?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      prevFileId?: string | null;
      blob?: {
        __typename?: "Blob";
        mediaId: string;
        uploadURL?: string | null;
        getURL?: string | null;
        type: string;
        size: number;
      } | null;
      link?: { __typename?: "Link"; url: string } | null;
    } | null>;
  } | null>;
};

export const FilesBySectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FilesBySection" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "filesBySection" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "files" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "sectionId" } },
                      { kind: "Field", name: { kind: "Name", value: "userId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "referencesCount" } },
                      { kind: "Field", name: { kind: "Name", value: "isFavourite" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blob" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "mediaId" } },
                            { kind: "Field", name: { kind: "Name", value: "uploadURL" } },
                            { kind: "Field", name: { kind: "Name", value: "getURL" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "size" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "link" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "prevFileId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilesBySectionQuery, FilesBySectionQueryVariables>;
