import React from "react";

import { HighlightsIcon, StarIcon, StarOutlineIcon } from "Assets/Icons";

import { ButtonProps } from "./Button";
import { IconButton } from "./IconButton";

export interface HighlightButtonProps extends ButtonProps {
  isHighlighted: boolean;
  highlightText?: string;
}

export const HighlightButton: React.FC<HighlightButtonProps> = ({
  onClick,
  isLoading,
  highlightText,
  isHighlighted,
}: HighlightButtonProps) => {
  return (
    <div className="relative">
      <div
        className={`absolute -top-6 -right-10 lg:-right-20 items-center flex transition duration-500 ease-in-out bg-grey-light rounded-lg p-1 ${
          highlightText ? "opacity-100" : "opacity-0 invisible"
        }`}
      >
        <HighlightsIcon height={24} width={24} />
        <span className="font-semibold text-xs w-16">{highlightText}</span>
      </div>

      <IconButton
        size="sm"
        onClick={onClick}
        isLoading={isLoading}
        icon={isHighlighted ? <StarIcon /> : <StarOutlineIcon />}
      />
    </div>
  );
};
