import { makeHtmlStringForIFrame } from "./functions";

/**
 * This iFrame is for static HTML content- it is intentionally script free
 */
export const EolasIFrame = ({
  internalHtmlContent,
  overrideContainerClass,
  overrideCss,
}: {
  internalHtmlContent: string;
  overrideContainerClass?: string;
  overrideCss?: string;
}) => {
  return (
    <div>
      <iframe
        srcDoc={makeHtmlStringForIFrame({ htmlContent: internalHtmlContent, overrideCss })}
        className={overrideContainerClass || "w-full h-fit"}
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-downloads"
      />
    </div>
  );
};
