import {
  AppLevelSection,
  EolasFile,
  isEolasShadowCopyFile,
  sectionStore,
} from "@eolas-medical/core";
import { EolasFileSummary } from "./types";

export const makeIterationCallback = (summaries: EolasFileSummary[]) => {
  return (value: EolasFile) => {
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(value.mainSectionID);

    if (mainSectionType === AppLevelSection.checklists) {
      return;
    }

    summaries.push(mapToEolasSummaryFile(value));
  };
};

export const mapToEolasSummaryFile = (eolasFile: EolasFile): EolasFileSummary => {
  return {
    id: eolasFile.id,
    name: eolasFile.name,
    isShadowCopy: isEolasShadowCopyFile(eolasFile),
    isUnpublished: eolasFile.isDraft ?? false,
    mainSectionId: eolasFile.mainSectionID,
    ownerId: eolasFile.ownerID,
  };
};
