import {
  AppLevelSection,
  CommunityLevelSection,
  EolasMainSection,
  NationalResourceEntityType,
  OrganisationLevelSection,
  SectionIdentity,
  hasStringProp,
} from "@eolas-medical/core";
import i18n from "i18n";
import {
  ChecklistsDeepLinkParams,
  NewsFeedDeepLinkParams,
  SpaceSupportedContentDeepLinks,
} from "deeplinking/types/contentDeeplinks/spacesContentDeeplinks.types";
import { History } from "history";
import { expectNever, getSearchParams } from "Utilities/helpers";
import {
  CommunityMedicationsBNFDeepLinkParams,
  CommunityMedicationsMedHighlightsDeepLinkParams,
  CommunityMedicationsSPMCGuidelinesDeepLinkParams,
  KnowledgeClinicalCalculatorsDeepLinkParams,
  KnowledgeClinicalResourceDeepLinkParams,
  KnowledgeNationalGuidelinesDeepLinkParams,
  KnowledgeNationalPatientLeafletsDeepLinkParams,
  KnowledgeNiceDeepLinkParams,
  KnowledgeSupportedClinicalResourcesDeepLinkTypes,
  KnowledgeSupportedContentDeepLinks,
} from "deeplinking/types/contentDeeplinks/knowledgeContentDeeplinks.types";
import {
  isBNFDeeplinkParams,
  isChecklistDeeplinkParams,
  isClinicalCalculatorsDeeplinkParams,
  isCommsPortalDeepLinkParams,
  isMedHighlightsDeeplinkParams,
  isNationalGuidelinesDeeplinkParams,
  isNationalPatientLeafletsDeeplinkParams,
  isNewsFeedDeeplinkParams,
  isNiceGuidelinesDeeplinkParams,
  isSMPCGuidelineDeeplinkParams,
  isSupportedKnowledgeContentDeepLinkType,
  isSupportedOrganisationContentDeepLinkType,
  isSupportedSpaceContentDeepLinkType,
  isWikEmParams,
} from "./typeguards";
import { knowledgeRoutes } from "Pages/Knowledge";
import { DeepLinkParams } from "deeplinking/types/general.types";
import {
  CommunicationPortalDeepLinkParams,
  OrganisationSupportedContentDeepLinks,
} from "deeplinking/types/contentDeeplinks/organisationContentDeeplinks.types";

export const extractLoginToken = (searchString: string): string | null => {
  const { loginToken } = getSearchParams({
    searchString,
    paramNameList: ["loginToken"],
  });

  return loginToken;
};

export const extractNewsFeedParams = (
  searchString: string,
  type: AppLevelSection.newsFeed,
): NewsFeedDeepLinkParams | null => {
  const { fileId } = getSearchParams({
    searchString,
    paramNameList: ["fileId"],
  });

  const maybeNewsFeedParams = { fileId, type };

  if (isNewsFeedDeeplinkParams(maybeNewsFeedParams)) {
    return maybeNewsFeedParams;
  }

  return null;
};

export const extractCommsPortalParams = (
  searchString: string,
  type: OrganisationLevelSection.communicationPortal,
): CommunicationPortalDeepLinkParams | null => {
  const { fileId } = getSearchParams({
    searchString,
    paramNameList: ["fileId"],
  });

  const maybeCommsPortalParams = { type, fileId };

  if (isCommsPortalDeepLinkParams(maybeCommsPortalParams)) {
    return maybeCommsPortalParams;
  }

  return null;
};

export const extractNiceGuidelinesParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): KnowledgeNiceDeepLinkParams | null => {
  const { ...rest } = getSearchParams({
    searchString,
    paramNameList: ["niceSummaryId"],
  });

  const maybeNiceGuidelinesParams = { ...rest, type };

  if (isNiceGuidelinesDeeplinkParams(maybeNiceGuidelinesParams)) {
    return maybeNiceGuidelinesParams;
  }

  return null;
};

export const extractNationalGuidelinesParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): KnowledgeNationalGuidelinesDeepLinkParams | null => {
  const params = getSearchParams({
    searchString,
    paramNameList: ["fileId"],
  });

  const maybeNatGuidelinesParams = { ...params, type };

  if (isNationalGuidelinesDeeplinkParams(maybeNatGuidelinesParams)) {
    return maybeNatGuidelinesParams;
  }

  return null;
};

export const extractClinicalCalculatorsParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): KnowledgeClinicalCalculatorsDeepLinkParams | null => {
  const params = getSearchParams({
    searchString,
    paramNameList: ["fileId", "clinicalCalculatorsSubSectionType"],
  });

  const maybeNatGuidelinesParams = { ...params, type };

  if (isClinicalCalculatorsDeeplinkParams(maybeNatGuidelinesParams)) {
    return maybeNatGuidelinesParams;
  }

  return null;
};

export const extractNatPatientLeafletsDeeplinkParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): KnowledgeNationalPatientLeafletsDeepLinkParams | null => {
  const params = getSearchParams({
    searchString,
    paramNameList: ["fileId"],
  });

  const maybeNatPatientLeafletsParams = { ...params, type };

  if (isNationalPatientLeafletsDeeplinkParams(maybeNatPatientLeafletsParams)) {
    return maybeNatPatientLeafletsParams;
  }

  return null;
};

export const extractClinicalResourceParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): KnowledgeClinicalResourceDeepLinkParams | null => {
  const maybeNatGuidelinesParams = extractNationalGuidelinesParams(searchString, type);
  const maybeClinicalCalculatorsParams = extractClinicalCalculatorsParams(searchString, type);
  const maybeNatPatientLeafletsParams = extractNatPatientLeafletsDeeplinkParams(searchString, type);

  if (maybeNatGuidelinesParams) {
    return maybeNatGuidelinesParams;
  }

  if (maybeClinicalCalculatorsParams) {
    return maybeClinicalCalculatorsParams;
  }

  if (maybeNatPatientLeafletsParams) {
    return maybeNatPatientLeafletsParams;
  }

  return null;
};

export const extractWikEmParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
) => {
  const { ...rest } = getSearchParams({
    searchString,
    paramNameList: ["wikemId"],
  });

  const maybeWikemParams = { ...rest, type };

  if (isWikEmParams(maybeWikemParams)) {
    return maybeWikemParams;
  }

  return null;
};

export const extractSMPCParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): CommunityMedicationsSPMCGuidelinesDeepLinkParams | null => {
  const { mainsectionId, ...rest } = getSearchParams({
    searchString,
    paramNameList: ["mainsectionId", "parentId", "contentId", "ownerId"],
  });

  const maybeSMPCParams = { ...rest, mainSectionId: mainsectionId, type };

  if (isSMPCGuidelineDeeplinkParams(maybeSMPCParams)) {
    return maybeSMPCParams;
  }

  return null;
};

export const extractMedHighlightsParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): CommunityMedicationsMedHighlightsDeepLinkParams | null => {
  const { mainsectionId, ...rest } = getSearchParams({
    searchString,
    paramNameList: ["mainsectionId", "parentId", "medicationHighlightId", "ownerId"],
  });

  const maybeMedHighlightParams = { ...rest, mainSectionId: mainsectionId, type };

  if (isMedHighlightsDeeplinkParams(maybeMedHighlightParams)) {
    return maybeMedHighlightParams;
  }

  return null;
};

export const extractBNFParams = (
  searchString: string,
  type: KnowledgeSupportedContentDeepLinks,
): CommunityMedicationsBNFDeepLinkParams | null => {
  const { ...rest } = getSearchParams({
    searchString,
    paramNameList: ["bnfId", "subsectionType"],
  });

  const maybeBNFParams = { ...rest, type };

  if (isBNFDeeplinkParams(maybeBNFParams)) {
    return maybeBNFParams;
  }

  return null;
};

export const extractChecklistParams = (
  searchString: string,
  type: SpaceSupportedContentDeepLinks,
): ChecklistsDeepLinkParams | null => {
  const params = getSearchParams({
    searchString,
    paramNameList: ["checklistId", "spaceId"],
  });

  const maybeChecklistParams = { ...params, type };

  if (isChecklistDeeplinkParams(maybeChecklistParams)) {
    return maybeChecklistParams;
  }

  return null;
};

export const redirectToLinkedDocument = ({
  basePath,
  legacyType,
  history,
  searchParams,
}: {
  basePath: "/organisation" | "/spaces" | "/knowledge";
  legacyType: string | null;
  history: History<unknown>;
  searchParams: string;
}) => {
  history.replace(`${basePath}/linked-documents/${legacyType}${searchParams}`);
};

export const redirectToWikiEmComingSoon = (history: History<unknown>) => {
  history.replace(knowledgeRoutes.wikEm);
};

/**
 * Maps the main section to a display name
 * Only really used when displaying a mobile only page if the section is only on mobile
 * The number of cases is just to make the TS easier
 */
export const mapMainSectionToDisplayName = (
  mainSection: SpaceSupportedContentDeepLinks,
): string => {
  switch (mainSection) {
    case AppLevelSection.newsFeed:
      return i18n.t("newsFeed");
    case AppLevelSection.admissionGuides:
      return i18n.t("admissionGuides");
    case AppLevelSection.clinicalPathways:
      return i18n.t("clinicalPathways");
    case AppLevelSection.emergencyActionCards:
      return i18n.t("emergencyActionCards");
    case AppLevelSection.equipmentLocations:
      return i18n.t("equipmentLocations");
    case AppLevelSection.guidelines:
      return i18n.t("guidelines");
    case AppLevelSection.howTo:
      return i18n.t("howTo");
    case AppLevelSection.rotas:
      return i18n.t("rotas");
    case AppLevelSection.surveys:
      return i18n.t("surveys");
    case AppLevelSection.wellbeing:
      return i18n.t("wellbeing");
    case AppLevelSection.researchAndAudit:
      return i18n.t("researchAndAudit");
    case AppLevelSection.clinicalDocuments:
      return i18n.t("clinicalDocuments");
    case AppLevelSection.policyDocuments:
      return i18n.t("policyDocuments");
    case AppLevelSection.activityFeed:
      return i18n.t("activityFeed");
    case AppLevelSection.patientLeaflets:
      return i18n.t("patientLeaflets");
    case AppLevelSection.checklists:
      return i18n.t("checklists");
    case AppLevelSection.contacts:
      return i18n.t("contacts");
    case AppLevelSection.importantLinks:
      return i18n.t("importantLinks");
    case AppLevelSection.educationPortal:
      return i18n.t("educationPortal");
    case "null":
      return i18n.t("post_space_content_repository_title");
  }
};

export const mainSectionTypeToNatResourceEntityTypeMapper = (
  type: KnowledgeSupportedClinicalResourcesDeepLinkTypes,
): NationalResourceEntityType => {
  switch (type) {
    case CommunityLevelSection.nationalGuidelines:
      return NationalResourceEntityType.GUIDELINE;
    case CommunityLevelSection.clinicalCalculators:
      return NationalResourceEntityType.CALCULATOR;
    case CommunityLevelSection.communityPatientLeaflets:
      return NationalResourceEntityType.LEAFLET;
  }

  expectNever(type);
};

export const getDeeplinkEntityId = (searchString: string, type: string): string => {
  const allSearchParams = new URLSearchParams(searchString);
  const params = Object.fromEntries(allSearchParams.entries());

  if (isSupportedKnowledgeContentDeepLinkType(type)) {
    return getKnowledgeDeeplinkEntityId(params, type);
  }

  if (isSupportedOrganisationContentDeepLinkType(type)) {
    if (hasStringProp(params, "sectionId")) {
      return params.sectionId;
    }

    if (hasStringProp(params, "fileId")) {
      return params.fileId;
    }
    return "unknownOrganisationContentId";
  }

  if (isSupportedSpaceContentDeepLinkType(type)) {
    if (isChecklistDeeplinkParams(params)) {
      return params.checklistId;
    }

    if (hasStringProp(params, "sectionId")) {
      return params.sectionId;
    }

    if (hasStringProp(params, "fileId")) {
      return params.fileId;
    }
    return "unknownSpaceContentId";
  }

  return "unknownEntityId";
};

export const getDeeplinkEntityType = (searchString: string, type: string): "file" | "section" => {
  const allSearchParams = new URLSearchParams(searchString);
  const params = Object.fromEntries(allSearchParams.entries());

  if (isSupportedKnowledgeContentDeepLinkType(type)) {
    return "file";
  }

  if (isSupportedOrganisationContentDeepLinkType(type)) {
    if (hasStringProp(params, "sectionId")) {
      return "section";
    }

    if (hasStringProp(params, "fileId")) {
      return "file";
    }
    return "file";
  }

  if (isSupportedSpaceContentDeepLinkType(type)) {
    if (isChecklistDeeplinkParams(params)) {
      return "file";
    }

    if (hasStringProp(params, "sectionId")) {
      return "section";
    }

    if (hasStringProp(params, "fileId")) {
      return "file";
    }
    return "file";
  }

  return "file";
};

export const getDeeplinkMainSectionIdentity = (
  type:
    | SpaceSupportedContentDeepLinks
    | OrganisationSupportedContentDeepLinks
    | KnowledgeSupportedContentDeepLinks,
): EolasMainSection | SectionIdentity => {
  if (type === "null") {
    return "genericContentRepository";
  }
  return type;
};

const getKnowledgeDeeplinkEntityId = (
  paramsProp: DeepLinkParams,
  type: KnowledgeSupportedContentDeepLinks,
): string => {
  const { mainsectionId, ...rest } = paramsProp;
  const params = { ...rest, type, mainSectionId: mainsectionId };
  switch (type) {
    case CommunityLevelSection.communityMedications:
      if (isMedHighlightsDeeplinkParams(params)) {
        return params.medicationHighlightId;
      }
      if (isSMPCGuidelineDeeplinkParams(params)) {
        return params.contentId;
      }
      if (isBNFDeeplinkParams(params)) {
        return params.bnfId;
      }
      return "unknownCommunityMedicationsId";
    case CommunityLevelSection.wikiEm:
      if (isWikEmParams(params)) {
        return params.wikemId;
      }
      return "unknownWikiEmId";
    case CommunityLevelSection.niceGuidelines:
      if (isNiceGuidelinesDeeplinkParams(params)) {
        return params.niceSummaryId;
      }
      return "unknownNiceGuidelinesId";
    case CommunityLevelSection.nationalGuidelines:
      if (isNationalGuidelinesDeeplinkParams(params)) {
        return params.fileId;
      }
      return "unknownNationalGuidelinesId";
    case CommunityLevelSection.clinicalCalculators:
      if (isClinicalCalculatorsDeeplinkParams(params)) {
        return params.fileId;
      }
      return "unknownClinicalCalculatorsId";
    case CommunityLevelSection.communityPatientLeaflets:
      if (isNationalPatientLeafletsDeeplinkParams(params)) {
        return params.fileId;
      }
      return "unknownCommunityPatientLeafletsId";
  }
};
