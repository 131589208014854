export default {
  v: "5.7.1",
  fr: 24,
  ip: 0,
  op: 44,
  w: 348,
  h: 192,
  nm: "Comp 1",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Shape Layer 6",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [0.833, 0.833],
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0.167, 0.167],
                    },
                    t: 0,
                    s: [1, 1],
                  },
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [0.833, 0.833],
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0.167, 0.167],
                    },
                    t: 8,
                    s: [1.5, 1.5],
                  },
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [0.833, 0.833],
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0.167, 0.167],
                    },
                    t: 16,
                    s: [2, 2],
                  },
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [0.833, 0.833],
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0.167, 0.167],
                    },
                    t: 24,
                    s: [1.5, 1.5],
                  },
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [0.833, 0.833],
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0.167, 0.167],
                    },
                    t: 32,
                    s: [1, 1],
                  },
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [0.833, 0.833],
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0.167, 0.167],
                    },
                    t: 40,
                    s: [0.5, 0.5],
                  },
                  {
                    t: 48,
                    s: [0, 0],
                  },
                ],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.082200001735, 0.014891999376, 0.014891999376, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141176470588, 0.188235294118, 0.36862745098, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [-95.653, 3.158],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 48,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 5",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 0,
                    s: [0, 0],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 8,
                    s: [1.5, 1.5],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 16,
                    s: [4, 4],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 24,
                    s: [1.5, 1.5],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 32,
                    s: [0, 0],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 40,
                    s: [1.5, 1.5],
                  },
                  {
                    t: 48,
                    s: [4, 4],
                  },
                ],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.082200001735, 0.014891999376, 0.014891999376, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141176470588, 0.188235294118, 0.36862745098, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [156.813, 67.03],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 48,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 4",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 0,
                    s: [3, 3],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 12,
                    s: [0, 0],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 24,
                    s: [3, 3],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 36,
                    s: [0, 0],
                  },
                  {
                    t: 48,
                    s: [3, 3],
                  },
                ],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.082200001735, 0.014891999376, 0.014891999376, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141176470588, 0.188235294118, 0.36862745098, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [6.653, 72.545],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 48,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 3",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [169, 91, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 0,
                    s: [2, 2],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 12,
                    s: [3, 3],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 24,
                    s: [2, 2],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 36,
                    s: [0, 0],
                  },
                  {
                    t: 48,
                    s: [2, 2],
                  },
                ],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.082200001735, 0.014891999376, 0.014891999376, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141176470588, 0.188235294118, 0.36862745098, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [-130.874, 38.072],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 48,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Shape Layer 2",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [194, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 0,
                    s: [0, 0],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 8,
                    s: [1, 1],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 16,
                    s: [3, 3],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 24,
                    s: [1, 1],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 32,
                    s: [3, 3],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 40,
                    s: [1, 1],
                  },
                  {
                    t: 48,
                    s: [0, 0],
                  },
                ],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.082200001735, 0.014891999376, 0.014891999376, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141176470588, 0.188235294118, 0.36862745098, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [100.433, -67.621],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 48,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Shape Layer 1",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 0,
                    s: [5.855, 5.855],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 8,
                    s: [2.927, 2.927],
                  },
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 16,
                    s: [1.464, 1.464],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0, 0],
                    },
                    t: 24,
                    s: [0, 0],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 32,
                    s: [1.464, 1.464],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 40,
                    s: [2.927, 2.927],
                  },
                  {
                    t: 48,
                    s: [5.855, 5.855],
                  },
                ],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.082200001735, 0.014891999376, 0.014891999376, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141176470588, 0.188235294118, 0.36862745098, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [81.738, 9.306],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 48,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "flame 2/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 0,
              s: [174, 96, 0],
              to: [0.167, -0.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 3,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.333, -0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 6,
              s: [174, 96, 0],
              to: [-0.333, 0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 9,
              s: [173, 97, 0],
              to: [0, 0, 0],
              ti: [-0.333, 0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 12,
              s: [174, 96, 0],
              to: [0.333, -0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 15,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 18,
              s: [174, 96, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 21,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 24,
              s: [174, 96, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 27,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.333, -0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 30,
              s: [174, 96, 0],
              to: [-0.333, 0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [173, 97, 0],
              to: [0, 0, 0],
              ti: [-0.333, 0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 36,
              s: [174, 96, 0],
              to: [0.333, -0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 39,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.167, -0.167, 0],
            },
            {
              t: 45,
              s: [174, 96, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 0,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 3,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-20.014, 20.013],
                          [5.416, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 6,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.416, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 9,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-20.014, 20.013],
                          [5.416, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 12,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.416, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 15,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.416, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 18,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-20.014, 20.013],
                          [5.416, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 21,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.416, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 24,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-20.014, 20.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 27,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 30,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 33,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-20.014, 20.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 36,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 39,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-20.014, 20.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 45,
                    s: [
                      {
                        i: [
                          [16.111, -3.173],
                          [-14.199, 13.865],
                          [-3.296, -3.297],
                          [-3.47, -3.002],
                        ],
                        o: [
                          [3.176, -16.107],
                          [2.999, 3.467],
                          [3.297, 3.297],
                          [-13.861, 14.195],
                        ],
                        v: [
                          [-25.014, 25.013],
                          [5.415, -25.013],
                          [14.862, -14.864],
                          [25.014, -5.421],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.231000010173, 0.246999987434, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 21,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [279.182, 237.877],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "flame 1/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 0,
              s: [174, 96, 0],
              to: [0.167, -0.167, 0],
              ti: [0.042, -0.042, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 3,
              s: [175, 95, 0],
              to: [-0.042, 0.042, 0],
              ti: [0.417, -0.417, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 6,
              s: [173.75, 96.25, 0],
              to: [-0.417, 0.417, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 9,
              s: [172.5, 97.5, 0],
              to: [0, 0, 0],
              ti: [-0.417, 0.417, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 12,
              s: [173.75, 96.25, 0],
              to: [0.417, -0.417, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 15,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 18,
              s: [173.75, 96.25, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 21,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 24,
              s: [173.75, 96.25, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 27,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.417, -0.417, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 30,
              s: [173.75, 96.25, 0],
              to: [-0.417, 0.417, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [172.5, 97.5, 0],
              to: [0, 0, 0],
              ti: [-0.417, 0.417, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 36,
              s: [173.75, 96.25, 0],
              to: [0.417, -0.417, 0],
              ti: [-0.042, 0.042, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 39,
              s: [175, 95, 0],
              to: [0.042, -0.042, 0],
              ti: [0.167, -0.167, 0],
            },
            {
              t: 45,
              s: [174, 96, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 0,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-53.126, 53.126],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 4,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-35.828, 35.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 8,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-55.828, 55.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 12,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-35.828, 35.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 16,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-55.828, 55.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 20,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-35.828, 35.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 24,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-55.828, 55.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 28,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-35.828, 35.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 32,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-55.828, 55.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 36,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-35.828, 35.828],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 40,
                    s: [
                      {
                        i: [
                          [35.966, -7.087],
                          [-31.839, 23.527],
                          [-2.118, -2.444],
                          [-3.297, -3.297],
                          [-3.471, -3.002],
                          [-2.523, -1.97],
                        ],
                        o: [
                          [7.086, -35.966],
                          [1.966, 2.526],
                          [2.999, 3.468],
                          [3.297, 3.296],
                          [2.445, 2.118],
                          [-23.528, 31.839],
                        ],
                        v: [
                          [-53.126, 53.126],
                          [19.952, -53.125],
                          [26.073, -45.67],
                          [35.52, -35.52],
                          [45.673, -26.077],
                          [53.125, -19.952],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.231000010173, 0.246999987434, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 21,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [258.523, 258.534],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "line 1/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-12.914, 13.964],
                          [-13.621, 13.671],
                          [-13.621, 12.257],
                          [12.207, -13.573],
                          [13.621, -13.573],
                          [13.621, -12.159],
                          [-12.208, 13.671],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 3,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-30.212, 31.261],
                          [-30.919, 30.968],
                          [-30.919, 29.554],
                          [-5.09, 3.725],
                          [-3.676, 3.725],
                          [-3.676, 5.139],
                          [-29.505, 30.968],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-8.59, 9.639],
                          [-9.297, 9.346],
                          [-9.297, 7.932],
                          [16.532, -17.897],
                          [17.946, -17.897],
                          [17.946, -16.483],
                          [-7.883, 9.346],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 9,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-34.536, 35.585],
                          [-35.243, 35.292],
                          [-35.243, 33.878],
                          [-9.414, 8.049],
                          [-8, 8.049],
                          [-8, 9.463],
                          [-33.829, 35.292],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 12,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-25.887, 26.937],
                          [-26.594, 26.644],
                          [-26.594, 25.23],
                          [-0.765, -0.599],
                          [0.649, -0.599],
                          [0.649, 0.815],
                          [-25.18, 26.644],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-38.86, 39.91],
                          [-39.567, 39.617],
                          [-39.567, 38.203],
                          [-13.738, 12.374],
                          [-12.324, 12.374],
                          [-12.324, 13.788],
                          [-38.153, 39.617],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-25.887, 26.937],
                          [-26.594, 26.644],
                          [-26.594, 25.23],
                          [-0.765, -0.599],
                          [0.649, -0.599],
                          [0.649, 0.815],
                          [-25.18, 26.644],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.167,
                      y: 0,
                    },
                    t: 21,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-17.238, 18.288],
                          [-17.945, 17.995],
                          [-17.945, 16.581],
                          [7.884, -9.248],
                          [9.298, -9.248],
                          [9.298, -7.834],
                          [-16.531, 17.995],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 24,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-25.887, 26.937],
                          [-26.594, 26.644],
                          [-26.594, 25.23],
                          [-0.765, -0.599],
                          [0.649, -0.599],
                          [0.649, 0.815],
                          [-25.18, 26.644],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 27,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-38.86, 39.91],
                          [-39.567, 39.617],
                          [-39.567, 38.203],
                          [-13.738, 12.374],
                          [-12.324, 12.374],
                          [-12.324, 13.788],
                          [-38.153, 39.617],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 30,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-25.887, 26.937],
                          [-26.594, 26.644],
                          [-26.594, 25.23],
                          [-0.765, -0.599],
                          [0.649, -0.599],
                          [0.649, 0.815],
                          [-25.18, 26.644],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 33,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-34.536, 35.585],
                          [-35.243, 35.292],
                          [-35.243, 33.878],
                          [-9.414, 8.049],
                          [-8, 8.049],
                          [-8, 9.463],
                          [-33.829, 35.292],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 36,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-8.59, 9.639],
                          [-9.297, 9.346],
                          [-9.297, 7.932],
                          [16.532, -17.897],
                          [17.946, -17.897],
                          [17.946, -16.483],
                          [-7.883, 9.346],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 39,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-30.212, 31.261],
                          [-30.919, 30.968],
                          [-30.919, 29.554],
                          [-5.09, 3.725],
                          [-3.676, 3.725],
                          [-3.676, 5.139],
                          [-29.505, 30.968],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 45,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.39, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-12.914, 13.964],
                          [-13.621, 13.671],
                          [-13.621, 12.257],
                          [12.207, -13.573],
                          [13.621, -13.573],
                          [13.621, -12.159],
                          [-12.208, 13.671],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [282.048, 278.712],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "line 2/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-8.59, 9.639],
                          [-9.297, 9.346],
                          [-9.297, 7.932],
                          [16.532, -17.897],
                          [17.946, -17.897],
                          [17.946, -16.483],
                          [-7.883, 9.346],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 3,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [4.383, -3.334],
                          [3.676, -3.627],
                          [3.676, -5.041],
                          [29.505, -30.87],
                          [30.919, -30.87],
                          [30.919, -29.456],
                          [5.09, -3.627],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [21.681, -20.631],
                          [20.974, -20.924],
                          [20.974, -22.338],
                          [46.803, -48.167],
                          [48.217, -48.167],
                          [48.217, -46.753],
                          [22.388, -20.924],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 9,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [13.032, -11.982],
                          [12.325, -12.275],
                          [12.325, -13.689],
                          [38.154, -39.518],
                          [39.568, -39.518],
                          [39.568, -38.104],
                          [13.739, -12.275],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 12,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [30.33, -29.279],
                          [29.623, -29.572],
                          [29.623, -30.986],
                          [55.452, -56.815],
                          [56.866, -56.815],
                          [56.866, -55.401],
                          [31.037, -29.572],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [8.708, -7.658],
                          [8.001, -7.951],
                          [8.001, -9.365],
                          [33.83, -35.194],
                          [35.244, -35.194],
                          [35.244, -33.78],
                          [9.415, -7.951],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-4.265, 5.315],
                          [-4.972, 5.022],
                          [-4.972, 3.608],
                          [20.857, -22.221],
                          [22.271, -22.221],
                          [22.271, -20.807],
                          [-3.558, 5.022],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 21,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-8.589, 9.64],
                          [-9.296, 9.347],
                          [-9.296, 7.933],
                          [16.533, -17.896],
                          [17.947, -17.896],
                          [17.947, -16.482],
                          [-7.882, 9.347],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 24,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-4.265, 5.315],
                          [-4.972, 5.022],
                          [-4.972, 3.608],
                          [20.857, -22.221],
                          [22.271, -22.221],
                          [22.271, -20.807],
                          [-3.558, 5.022],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 27,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [8.708, -7.658],
                          [8.001, -7.951],
                          [8.001, -9.365],
                          [33.83, -35.194],
                          [35.244, -35.194],
                          [35.244, -33.78],
                          [9.415, -7.951],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 30,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [30.33, -29.279],
                          [29.623, -29.572],
                          [29.623, -30.986],
                          [55.452, -56.815],
                          [56.866, -56.815],
                          [56.866, -55.401],
                          [31.037, -29.572],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 33,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [13.032, -11.982],
                          [12.325, -12.275],
                          [12.325, -13.689],
                          [38.154, -39.518],
                          [39.568, -39.518],
                          [39.568, -38.104],
                          [13.739, -12.275],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 36,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [21.681, -20.631],
                          [20.974, -20.924],
                          [20.974, -22.338],
                          [46.803, -48.167],
                          [48.217, -48.167],
                          [48.217, -46.753],
                          [22.388, -20.924],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 39,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [4.383, -3.334],
                          [3.676, -3.627],
                          [3.676, -5.041],
                          [29.505, -30.87],
                          [30.919, -30.87],
                          [30.919, -29.456],
                          [5.09, -3.627],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 45,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.39],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-8.59, 9.639],
                          [-9.297, 9.346],
                          [-9.297, 7.932],
                          [16.532, -17.897],
                          [17.946, -17.897],
                          [17.946, -16.483],
                          [-7.883, 9.346],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [185.971, 349.037],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: "line 3/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-6.457, 7.506],
                          [-7.164, 7.213],
                          [-7.164, 5.799],
                          [5.751, -7.115],
                          [7.165, -7.115],
                          [7.165, -5.701],
                          [-5.75, 7.213],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 3,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [10.84, -9.791],
                          [10.133, -10.084],
                          [10.133, -11.498],
                          [23.048, -24.412],
                          [24.462, -24.412],
                          [24.462, -22.998],
                          [11.547, -10.084],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-15.105, 16.155],
                          [-15.812, 15.862],
                          [-15.812, 14.448],
                          [-2.897, 1.534],
                          [-1.483, 1.534],
                          [-1.483, 2.948],
                          [-14.398, 15.862],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 9,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-28.078, 29.128],
                          [-28.785, 28.835],
                          [-28.785, 27.421],
                          [-15.87, 14.507],
                          [-14.456, 14.507],
                          [-14.456, 15.921],
                          [-27.371, 28.835],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 12,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-15.105, 16.155],
                          [-15.812, 15.862],
                          [-15.812, 14.448],
                          [-2.897, 1.534],
                          [-1.483, 1.534],
                          [-1.483, 2.948],
                          [-14.398, 15.862],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-23.754, 24.804],
                          [-24.461, 24.511],
                          [-24.461, 23.097],
                          [-11.546, 10.183],
                          [-10.132, 10.183],
                          [-10.132, 11.597],
                          [-23.047, 24.511],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-41.051, 42.101],
                          [-41.758, 41.808],
                          [-41.758, 40.394],
                          [-28.843, 27.48],
                          [-27.429, 27.48],
                          [-27.429, 28.894],
                          [-40.344, 41.808],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.167,
                      y: 0,
                    },
                    t: 21,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-23.754, 24.804],
                          [-24.461, 24.511],
                          [-24.461, 23.097],
                          [-11.546, 10.183],
                          [-10.132, 10.183],
                          [-10.132, 11.597],
                          [-23.047, 24.511],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 24,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-41.051, 42.101],
                          [-41.758, 41.808],
                          [-41.758, 40.394],
                          [-28.843, 27.48],
                          [-27.429, 27.48],
                          [-27.429, 28.894],
                          [-40.344, 41.808],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 27,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-23.754, 24.804],
                          [-24.461, 24.511],
                          [-24.461, 23.097],
                          [-11.546, 10.183],
                          [-10.132, 10.183],
                          [-10.132, 11.597],
                          [-23.047, 24.511],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 30,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-15.105, 16.155],
                          [-15.812, 15.862],
                          [-15.812, 14.448],
                          [-2.897, 1.534],
                          [-1.483, 1.534],
                          [-1.483, 2.948],
                          [-14.398, 15.862],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 33,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-28.078, 29.128],
                          [-28.785, 28.835],
                          [-28.785, 27.421],
                          [-15.87, 14.507],
                          [-14.456, 14.507],
                          [-14.456, 15.921],
                          [-27.371, 28.835],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 36,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-15.105, 16.155],
                          [-15.812, 15.862],
                          [-15.812, 14.448],
                          [-2.897, 1.534],
                          [-1.483, 1.534],
                          [-1.483, 2.948],
                          [-14.398, 15.862],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 39,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [10.84, -9.791],
                          [10.133, -10.084],
                          [10.133, -11.498],
                          [23.048, -24.412],
                          [24.462, -24.412],
                          [24.462, -22.998],
                          [11.547, -10.084],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 45,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.39, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.39, -0.391],
                          [0.39, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-6.457, 7.506],
                          [-7.164, 7.213],
                          [-7.164, 5.799],
                          [5.751, -7.115],
                          [7.165, -7.115],
                          [7.165, -5.701],
                          [-5.75, 7.213],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [197.106, 299.706],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: "line 4/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [174, 96, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-6.312, 7.362],
                          [-7.019, 7.069],
                          [-7.019, 5.655],
                          [5.606, -6.97],
                          [7.02, -6.97],
                          [7.02, -5.556],
                          [-5.605, 7.069],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 3,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [0.174, 0.875],
                          [-0.533, 0.582],
                          [-0.533, -0.832],
                          [12.092, -13.457],
                          [13.506, -13.457],
                          [13.506, -12.043],
                          [0.881, 0.582],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-1.988, 3.037],
                          [-2.695, 2.744],
                          [-2.695, 1.33],
                          [9.93, -11.295],
                          [11.344, -11.295],
                          [11.344, -9.881],
                          [-1.281, 2.744],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 9,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-10.637, 16.01],
                          [-11.344, 15.717],
                          [-11.344, 14.303],
                          [1.281, 1.678],
                          [2.695, 1.678],
                          [2.695, 3.092],
                          [-9.93, 15.717],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 12,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-27.934, 33.308],
                          [-28.641, 33.015],
                          [-28.641, 31.601],
                          [-16.016, 18.976],
                          [-14.602, 18.976],
                          [-14.602, 20.39],
                          [-27.227, 33.015],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-14.961, 20.335],
                          [-15.668, 20.042],
                          [-15.668, 18.628],
                          [-3.043, 6.003],
                          [-1.629, 6.003],
                          [-1.629, 7.417],
                          [-14.254, 20.042],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-36.582, 41.956],
                          [-37.289, 41.663],
                          [-37.289, 40.249],
                          [-24.664, 27.624],
                          [-23.25, 27.624],
                          [-23.25, 29.038],
                          [-35.875, 41.663],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 21,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-19.285, 24.659],
                          [-19.992, 24.366],
                          [-19.992, 22.952],
                          [-7.367, 10.327],
                          [-5.953, 10.327],
                          [-5.953, 11.741],
                          [-18.578, 24.366],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 24,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-36.582, 41.956],
                          [-37.289, 41.663],
                          [-37.289, 40.249],
                          [-24.664, 27.624],
                          [-23.25, 27.624],
                          [-23.25, 29.038],
                          [-35.875, 41.663],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 27,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-14.961, 20.335],
                          [-15.668, 20.042],
                          [-15.668, 18.628],
                          [-3.043, 6.003],
                          [-1.629, 6.003],
                          [-1.629, 7.417],
                          [-14.254, 20.042],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 30,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-27.934, 33.308],
                          [-28.641, 33.015],
                          [-28.641, 31.601],
                          [-16.016, 18.976],
                          [-14.602, 18.976],
                          [-14.602, 20.39],
                          [-27.227, 33.015],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 33,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-10.637, 16.01],
                          [-11.344, 15.717],
                          [-11.344, 14.303],
                          [1.281, 1.678],
                          [2.695, 1.678],
                          [2.695, 3.092],
                          [-9.93, 15.717],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 36,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-1.988, 3.037],
                          [-2.695, 2.744],
                          [-2.695, 1.33],
                          [9.93, -11.295],
                          [11.344, -11.295],
                          [11.344, -9.881],
                          [-1.281, 2.744],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1,
                    },
                    o: {
                      x: 0.333,
                      y: 0,
                    },
                    t: 39,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [0.174, 0.875],
                          [-0.533, 0.582],
                          [-0.533, -0.832],
                          [12.092, -13.457],
                          [13.506, -13.457],
                          [13.506, -12.043],
                          [0.881, 0.582],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 45,
                    s: [
                      {
                        i: [
                          [0.256, 0],
                          [0.195, 0.195],
                          [-0.391, 0.391],
                          [0, 0],
                          [-0.391, -0.391],
                          [0.391, -0.391],
                          [0, 0],
                        ],
                        o: [
                          [-0.256, 0],
                          [-0.391, -0.391],
                          [0, 0],
                          [0.391, -0.391],
                          [0.391, 0.391],
                          [0, 0],
                          [-0.195, 0.195],
                        ],
                        v: [
                          [-6.312, 7.362],
                          [-7.019, 7.069],
                          [-7.019, 5.655],
                          [5.606, -6.97],
                          [7.02, -6.97],
                          [7.02, -5.556],
                          [-5.605, 7.069],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [229.968, 250.536],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: "outline/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 0,
              s: [174, 96, 0],
              to: [0.167, -0.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 3,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.333, -0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 6,
              s: [174, 96, 0],
              to: [-0.333, 0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 9,
              s: [173, 97, 0],
              to: [0, 0, 0],
              ti: [-0.333, 0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 12,
              s: [174, 96, 0],
              to: [0.333, -0.333, 0],
              ti: [-0.083, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 15,
              s: [175, 95, 0],
              to: [0.083, 0, 0],
              ti: [-0.083, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 18,
              s: [174.5, 96, 0],
              to: [0.083, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.167,
                y: 0,
              },
              t: 21,
              s: [175.5, 95, 0],
              to: [0, 0, 0],
              ti: [0.083, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 24,
              s: [174.5, 96, 0],
              to: [-0.083, 0, 0],
              ti: [0.083, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 27,
              s: [175, 95, 0],
              to: [-0.083, 0, 0],
              ti: [0.333, -0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 30,
              s: [174, 96, 0],
              to: [-0.333, 0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [173, 97, 0],
              to: [0, 0, 0],
              ti: [-0.333, 0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 36,
              s: [174, 96, 0],
              to: [0.333, -0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 39,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.167, -0.167, 0],
            },
            {
              t: 45,
              s: [174, 96, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [17.838, -7.333],
                    [-7.459, -7.46],
                    [-10.019, -3.322],
                    [-1.364, 19.353],
                  ],
                  o: [
                    [3.286, 9.954],
                    [7.534, 7.533],
                    [7.345, -17.839],
                    [-19.313, 1.355],
                  ],
                  v: [
                    [-27.857, -15.001],
                    [-11.454, 11.589],
                    [14.992, 27.944],
                    [28.111, -28.089],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.394, 0],
                    [0.1, 0.032],
                    [7.867, 7.868],
                    [3.334, 10.595],
                    [-0.478, 0.2],
                    [-20.24, 1.293],
                    [-0.202, -0.203],
                    [0.019, -0.286],
                    [7.788, -18.645],
                  ],
                  o: [
                    [-0.101, 0],
                    [-10.534, -3.34],
                    [-7.903, -7.903],
                    [-0.156, -0.493],
                    [18.643, -7.776],
                    [0.287, -0.019],
                    [0.203, 0.203],
                    [-1.302, 20.284],
                    [-0.159, 0.381],
                  ],
                  v: [
                    [15.561, 30.181],
                    [15.259, 30.134],
                    [-12.868, 13.003],
                    [-30.046, -15.272],
                    [-29.477, -16.495],
                    [29.121, -30.162],
                    [29.892, -29.871],
                    [30.182, -29.1],
                    [16.484, 29.566],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 2",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [413.759, 103.163],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 7,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.187, -0.238],
                    [-2.07, -2.389],
                    [-3.269, -3.268],
                    [-3.468, -3],
                    [-2.549, -1.99],
                    [-0.238, -0.185],
                    [0, 0],
                    [5.732, 5.731],
                    [4.902, 6.336],
                  ],
                  o: [
                    [0.185, 0.238],
                    [1.974, 2.536],
                    [2.968, 3.432],
                    [3.234, 3.235],
                    [2.37, 2.053],
                    [0.24, 0.188],
                    [0, 0],
                    [-6.363, -4.929],
                    [-5.758, -5.759],
                    [0, 0],
                  ],
                  v: [
                    [-19.819, -14.411],
                    [-19.262, -13.697],
                    [-13.169, -6.278],
                    [-3.772, 3.819],
                    [6.328, 13.214],
                    [13.741, 19.307],
                    [14.458, 19.867],
                    [19.716, 14.61],
                    [1.496, -1.448],
                    [-14.562, -19.668],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.258, 0],
                    [0.18, 0.137],
                    [0.477, 0.376],
                    [2.395, 2.074],
                    [3.268, 3.268],
                    [2.998, 3.467],
                    [1.991, 2.559],
                    [0.365, 0.48],
                    [-0.354, 0.354],
                    [0, 0],
                    [-0.288, -0.015],
                    [-0.174, -0.228],
                    [-5.969, -5.971],
                    [-6.617, -5.063],
                    [-0.019, -0.287],
                    [0.203, -0.204],
                    [0, 0],
                  ],
                  o: [
                    [-0.212, 0],
                    [-0.48, -0.364],
                    [-2.573, -2.008],
                    [-3.504, -3.031],
                    [-3.303, -3.303],
                    [-2.093, -2.415],
                    [-0.373, -0.472],
                    [-0.303, -0.399],
                    [0, 0],
                    [0.204, -0.204],
                    [0.288, 0.02],
                    [5.033, 6.588],
                    [5.941, 5.939],
                    [0.229, 0.175],
                    [0.019, 0.289],
                    [0, 0],
                    [-0.194, 0.194],
                  ],
                  v: [
                    [14.546, 22.194],
                    [13.94, 21.99],
                    [12.508, 20.88],
                    [5.02, 14.726],
                    [-5.186, 5.234],
                    [-14.682, -4.969],
                    [-20.836, -12.464],
                    [-21.943, -13.892],
                    [-21.854, -15.205],
                    [-15.172, -21.888],
                    [-14.398, -22.179],
                    [-13.671, -21.788],
                    [2.91, -2.862],
                    [21.836, 13.719],
                    [22.227, 14.446],
                    [21.935, 15.22],
                    [15.253, 21.902],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [298.523, 218.487],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [27.217, 36.663],
                    [27.227, 36.663],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.557, -4.497],
                    [-0.232, -0.298],
                    [-5.97, -5.971],
                    [-6.617, -5.062],
                    [-0.25, -0.189],
                    [-4.315, 1.891],
                    [-10.967, 8.202],
                    [-6.154, 6.154],
                    [-3.044, 43.324],
                    [30.765, -30.766],
                    [5.163, -6.914],
                    [5.469, -12.499],
                  ],
                  o: [
                    [0.226, 0.3],
                    [5.037, 6.594],
                    [5.941, 5.94],
                    [0.258, 0.197],
                    [4.499, -1.557],
                    [12.498, -5.472],
                    [6.935, -5.183],
                    [30.764, -30.763],
                    [-43.324, 3.04],
                    [-6.174, 6.173],
                    [-8.201, 10.97],
                    [-1.889, 4.298],
                  ],
                  v: [
                    [-78.814, 41.854],
                    [-78.127, 42.749],
                    [-61.542, 61.681],
                    [-42.617, 78.262],
                    [-41.858, 78.841],
                    [-28.579, 73.647],
                    [6.784, 53.041],
                    [26.51, 35.956],
                    [78.894, -78.868],
                    [-35.935, -26.488],
                    [-53.02, -6.764],
                    [-73.622, 28.604],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.109, 0],
                    [0.176, 0.132],
                    [0.397, 0.305],
                    [6.003, 6.002],
                    [5.086, 6.657],
                    [0.337, 0.453],
                    [-0.108, 0.312],
                    [-1.98, 4.506],
                    [-8.292, 11.092],
                    [-6.242, 6.241],
                    [-44.235, 2.824],
                    [-0.203, -0.204],
                    [0.019, -0.288],
                    [31.377, -31.376],
                    [7.01, -5.24],
                    [12.638, -5.533],
                    [4.721, -1.619],
                  ],
                  o: [
                    [-0.213, 0],
                    [-0.397, -0.297],
                    [-6.697, -5.123],
                    [-6.033, -6.033],
                    [-0.343, -0.442],
                    [-0.197, -0.264],
                    [1.618, -4.72],
                    [5.531, -12.638],
                    [5.22, -6.99],
                    [31.378, -31.379],
                    [0.287, -0.02],
                    [0.203, 0.203],
                    [-2.828, 44.232],
                    [-6.222, 6.221],
                    [-11.09, 8.293],
                    [-4.525, 1.982],
                    [-0.105, 0.036],
                  ],
                  v: [
                    [-42.036, 80.96],
                    [-42.635, 80.76],
                    [-43.822, 79.858],
                    [-62.956, 63.095],
                    [-79.712, 43.97],
                    [-80.734, 42.626],
                    [-80.878, 41.705],
                    [-75.454, 27.801],
                    [-54.622, -7.961],
                    [-37.349, -27.902],
                    [79.905, -80.94],
                    [80.675, -80.65],
                    [80.966, -79.879],
                    [27.924, 37.37],
                    [7.982, 54.643],
                    [-27.777, 75.479],
                    [-41.712, 80.907],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [362.975, 153.943],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 7,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.742, -5.205],
                    [-1.08, -4.374],
                    [-0.885, -4.21],
                    [-4.499, 6.797],
                    [-0.431, 9.052],
                  ],
                  o: [
                    [0.87, 4.104],
                    [1.108, 4.483],
                    [8.623, -7.039],
                    [4.577, -6.904],
                    [-10.409, 7.6],
                  ],
                  v: [
                    [-16.599, -3.37],
                    [-13.535, 9.622],
                    [-10.401, 22.932],
                    [8.863, 2.611],
                    [16.763, -22.656],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.224, 0],
                    [0.111, 0.041],
                    [0.069, 0.341],
                    [1.245, 5.041],
                    [0.898, 4.349],
                    [-0.434, 0.19],
                    [-10.968, 8.201],
                    [-0.339, -0.172],
                    [0.001, -0.379],
                    [5.124, -7.732],
                    [9.462, -7.574],
                  ],
                  o: [
                    [-0.115, 0],
                    [-0.329, -0.12],
                    [-0.91, -4.583],
                    [-1.155, -4.676],
                    [-0.095, -0.463],
                    [12.497, -5.472],
                    [0.303, -0.229],
                    [0.339, 0.171],
                    [-0.064, 10.044],
                    [-4.836, 7.305],
                    [-0.18, 0.144],
                  ],
                  v: [
                    [-11.052, 25.743],
                    [-11.393, 25.684],
                    [-12.033, 24.939],
                    [-15.475, 10.104],
                    [-18.724, -3.757],
                    [-18.145, -4.874],
                    [17.219, -25.48],
                    [18.268, -25.572],
                    [18.818, -24.674],
                    [10.531, 3.717],
                    [-10.427, 25.525],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [352.541, 232.465],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 4",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.705, 0],
                    [0, -11.706],
                    [-11.705, 0],
                    [0, 11.705],
                  ],
                  o: [
                    [-11.705, 0],
                    [0, 11.705],
                    [11.705, 0],
                    [0, -11.706],
                  ],
                  v: [
                    [0, -21.229],
                    [-21.229, 0.001],
                    [0, 21.229],
                    [21.229, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [12.809, 0],
                    [0, 12.808],
                    [-12.809, 0],
                    [0, -12.809],
                  ],
                  o: [
                    [-12.809, 0],
                    [0, -12.809],
                    [12.809, 0],
                    [0, 12.808],
                  ],
                  v: [
                    [0, 23.229],
                    [-23.229, 0.001],
                    [0, -23.229],
                    [23.229, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [370.303, 146.876],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 5",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.039, -8.622],
                    [-4.487, -1.109],
                    [-4.1, -0.869],
                    [-7.6, 10.412],
                    [6.905, -4.576],
                  ],
                  o: [
                    [4.217, 0.886],
                    [4.369, 1.081],
                    [5.203, -11.743],
                    [-9.051, 0.431],
                    [-6.796, 4.499],
                  ],
                  v: [
                    [-22.902, 10.436],
                    [-9.575, 13.574],
                    [3.403, 16.638],
                    [22.686, -16.728],
                    [-2.582, -8.829],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.391, 0],
                    [0.068, 0.014],
                    [4.671, 1.154],
                    [4.589, 0.911],
                    [0.12, 0.328],
                    [-0.219, 0.273],
                    [-7.305, 4.836],
                    [-10.043, 0.064],
                    [0, 0],
                    [-0.17, -0.337],
                    [0.227, -0.303],
                    [5.469, -12.499],
                  ],
                  o: [
                    [-0.067, 0],
                    [-4.344, -0.897],
                    [-5.046, -1.247],
                    [-0.343, -0.068],
                    [-0.12, -0.328],
                    [7.573, -9.463],
                    [7.732, -5.124],
                    [0, 0],
                    [0.377, 0],
                    [0.172, 0.339],
                    [-8.201, 10.97],
                    [-0.162, 0.369],
                  ],
                  v: [
                    [3.992, 18.783],
                    [3.789, 18.762],
                    [-10.055, 15.516],
                    [-24.908, 12.067],
                    [-25.653, 11.429],
                    [-25.494, 10.461],
                    [-3.686, -10.497],
                    [24.702, -18.782],
                    [24.709, -18.782],
                    [25.601, -18.233],
                    [25.51, -17.184],
                    [4.908, 18.184],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [284.445, 164.364],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 6",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: "rocket/07b_accelerate Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 0,
              s: [174, 96, 0],
              to: [0.167, -0.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 3,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.333, -0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 6,
              s: [174, 96, 0],
              to: [-0.333, 0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 9,
              s: [173, 97, 0],
              to: [0, 0, 0],
              ti: [-0.333, 0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 12,
              s: [174, 96, 0],
              to: [0.333, -0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 15,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 18,
              s: [174, 96, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.167,
                y: 0,
              },
              t: 21,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 24,
              s: [174, 96, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 27,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.333, -0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 30,
              s: [174, 96, 0],
              to: [-0.333, 0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [173, 97, 0],
              to: [0, 0, 0],
              ti: [-0.333, 0.333, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 36,
              s: [174, 96, 0],
              to: [0.333, -0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 39,
              s: [175, 95, 0],
              to: [0, 0, 0],
              ti: [0.167, -0.167, 0],
            },
            {
              t: 45,
              s: [174, 96, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [348, 192, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [50, 50, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.782, -2.129],
                    [0, 0],
                    [0.472, 0.372],
                    [0, 0],
                    [-1.955, 2.628],
                  ],
                  o: [
                    [0, 0],
                    [-0.475, -0.362],
                    [-1.566, -1.223],
                    [1.757, -2.36],
                    [0, 0],
                  ],
                  v: [
                    [6.913, -0.267],
                    [0.23, 6.415],
                    [-1.189, 5.315],
                    [-6.914, 0.637],
                    [-0.793, -6.414],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.238999998803, 0.33300000359, 0.638999968884, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 19,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [312.838, 233.267],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "shadow 4",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.44, -12.431],
                    [0, 0],
                    [-21.571, 17.783],
                  ],
                  o: [
                    [-1.678, -0.347],
                    [0, 0],
                    [-8.367, 11.193],
                  ],
                  v: [
                    [-7.092, 17.783],
                    [-13.624, 16.304],
                    [13.625, -17.782],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141000007181, 0.187999994615, 0.368999974868, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 19,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [295.529, 164.364],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "shadow 3",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.821, -6.26],
                    [0, 0],
                    [-11.03, 31.896],
                    [5.202, -7.85],
                  ],
                  o: [
                    [-0.572, -2.877],
                    [0, 0],
                    [-0.06, 9.543],
                    [-5.532, 8.357],
                  ],
                  v: [
                    [-13.412, 24.711],
                    [-15.456, 15.586],
                    [15.456, -24.712],
                    [7.337, 3.131],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141000007181, 0.187999994615, 0.368999974868, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 19,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [354.903, 232.497],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "shadow 2",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [32.453, -32.453],
                    [6.917, -5.17],
                    [12.424, -5.44],
                    [4.677, -1.603],
                    [0.397, 0.305],
                    [0, 0],
                    [-25.989, 111.107],
                  ],
                  o: [
                    [-6.285, 6.285],
                    [-11.196, 8.372],
                    [-4.56, 1.998],
                    [-0.397, -0.297],
                    [-2.266, -1.732],
                    [5.573, 0.986],
                    [-2.715, 42.467],
                  ],
                  v: [
                    [12.896, 36.654],
                    [-6.938, 53.833],
                    [-42.499, 74.554],
                    [-56.357, 79.951],
                    [-57.545, 79.047],
                    [-65.647, 72.276],
                    [65.647, -79.952],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.141000007181, 0.187999994615, 0.368999974868, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 19,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [377.296, 153.952],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "shadow 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.615, -5.061],
                    [0, 0],
                    [0.472, 0.372],
                    [2.445, 2.118],
                    [3.297, 3.297],
                    [2.999, 3.468],
                    [1.966, 2.527],
                    [0.362, 0.476],
                    [0, 0],
                    [-6.054, -6.055],
                  ],
                  o: [
                    [0, 0],
                    [-0.475, -0.362],
                    [-2.523, -1.97],
                    [-3.471, -3.002],
                    [-3.297, -3.297],
                    [-2.118, -2.444],
                    [-0.372, -0.472],
                    [0, 0],
                    [5.057, 6.619],
                    [6.051, 6.05],
                  ],
                  v: [
                    [21.188, 14.506],
                    [14.504, 21.188],
                    [13.085, 20.088],
                    [5.633, 13.963],
                    [-4.52, 4.52],
                    [-13.967, -5.63],
                    [-20.088, -13.085],
                    [-21.188, -14.505],
                    [-14.506, -21.187],
                    [2.162, -2.162],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.238999998803, 0.33300000359, 0.638999968884, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 37,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [298.564, 218.494],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "exhaust",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -12.276],
                    [12.277, 0],
                    [0, 12.276],
                    [-12.276, 0],
                  ],
                  o: [
                    [0, 12.276],
                    [-12.276, 0],
                    [0, -12.276],
                    [12.277, 0],
                  ],
                  v: [
                    [22.229, 0],
                    [0, 22.229],
                    [-22.228, 0],
                    [0, -22.229],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.238999998803, 0.33300000359, 0.638999968884, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 54,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [370.303, 146.876],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "window",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.811, -18.698],
                    [8.041, 8.042],
                    [3.208, 10.191],
                    [-19.887, 1.27],
                  ],
                  o: [
                    [-10.143, -3.215],
                    [-8.08, -8.08],
                    [18.676, -7.79],
                    [-1.277, 19.908],
                  ],
                  v: [
                    [15.515, 29.172],
                    [-12.208, 12.287],
                    [-29.138, -15.58],
                    [29.138, -29.172],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.231000010173, 0.246999987434, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 82,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [413.805, 103.172],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "tip",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.821, -6.26],
                    [1.863, 9.017],
                    [-11.196, 8.372],
                    [5.202, -7.85],
                  ],
                  o: [
                    [-1.76, -8.857],
                    [12.424, -5.44],
                    [-0.06, 9.543],
                    [-5.532, 8.357],
                  ],
                  v: [
                    [-11.087, 24.711],
                    [-17.781, -3.991],
                    [17.78, -24.712],
                    [9.661, 3.131],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.231000010173, 0.246999987434, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 82,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [352.578, 232.497],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "wing 2",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.44, -12.431],
                    [8.864, 1.76],
                    [-8.358, 5.532],
                    [-9.542, 0.061],
                  ],
                  o: [
                    [-9.017, -1.863],
                    [6.26, -7.822],
                    [7.849, -5.203],
                    [-8.367, 11.193],
                  ],
                  v: [
                    [3.994, 17.783],
                    [-24.711, 11.087],
                    [-3.131, -9.662],
                    [24.711, -17.782],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.231000010173, 0.246999987434, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 82,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [284.443, 164.364],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "wing 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [32.453, -32.453],
                    [6.917, -5.17],
                    [12.424, -5.44],
                    [4.677, -1.603],
                    [0.397, 0.305],
                    [6.05, 6.05],
                    [5.056, 6.619],
                    [0.333, 0.447],
                    [-1.998, 4.546],
                    [-8.368, 11.193],
                    [-6.285, 6.284],
                    [-42.471, 2.711],
                  ],
                  o: [
                    [-6.285, 6.285],
                    [-11.196, 8.372],
                    [-4.56, 1.998],
                    [-0.397, -0.297],
                    [-6.615, -5.06],
                    [-6.054, -6.055],
                    [-0.345, -0.444],
                    [1.6, -4.667],
                    [5.44, -12.431],
                    [5.167, -6.92],
                    [32.453, -32.454],
                    [-2.715, 42.467],
                  ],
                  v: [
                    [27.199, 36.654],
                    [7.365, 53.833],
                    [-28.196, 74.554],
                    [-42.054, 79.951],
                    [-43.242, 79.047],
                    [-62.267, 62.379],
                    [-78.935, 43.355],
                    [-79.95, 42.02],
                    [-74.556, 28.194],
                    [-53.839, -7.371],
                    [-36.66, -27.204],
                    [79.95, -79.952],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.991999966491, 0.991999966491, 0.991999966491, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [362.993, 153.952],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 10",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 45,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
