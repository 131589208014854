import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InnerPageWrapper, Loader, PageTitle, Text } from "UIKit";
import useExpiringContentItems, {
  ExpiringPeriod,
} from "modules/contentRepository/data/useExpiringContentItems";
import ExpiringPeriodFilters from "./components/ExpiringPeriodFilters/ExpiringPeriodFilters";
import { observer } from "mobx-react-lite";
import { NavBackButton } from "shared/components/LogicComponents";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { useGetSpaceOrgInfo } from "../../hooks/useGetSpaceOrgInfo";
import { eolasFileNormaliser } from "Utilities/helpers";
import { mapToContentItem } from "modules/contentRepository/helpers";
import {
  ContentItemListContext,
  renderContentItems,
} from "shared/pages/ContentRepository/ContentItems/components/ContentItemList/components/renderContentItems";

type ManageExpiredItemsProps = {
  contentRepositoryId: string;
};

const ManageExpiredItems = observer(({ contentRepositoryId }: ManageExpiredItemsProps) => {
  const { t } = useTranslation();

  const [expiringPeriod, setExpiringPeriod] = useState<ExpiringPeriod>(0);

  const { relevantItems, isFetching } = useExpiringContentItems({
    contentRepositoryId,
    expiringPeriod,
  });

  const { flags, activeTab } = useGetSpaceOrgInfo();

  const handleChangePeriod = (newPeriod: ExpiringPeriod) => {
    setExpiringPeriod(newPeriod);
  };

  const {
    isSearchLoading,
    isSearchActive,
    handleClearSearch,
    handleSearchClick,
    searchInstanceId,
    searchResults,
  } = useLocalMobxSearch({
    mode: "callback",
    searchCallback: async (text) => {
      const idsToInclude = relevantItems.reduce<Record<string, string>>((acc, item) => {
        return { ...acc, [item.id]: item.id };
      }, {});
      const results = await localSearchStore.search({
        shouldSearchSpace: activeTab === "spaces",
        shouldSearchOrganisation: activeTab === "organisation",
        idsToInclude,
        term: text,
        ldFlags: flags,
        isInAdminMode: true,
      });
      return results.map((item) => {
        const { file } = eolasFileNormaliser(item);
        // TODO: remove the need for this map (should be dealing with EolasFiles directly)
        return mapToContentItem(file);
      });
    },
    data: relevantItems,
  });

  const listContext: ContentItemListContext = useMemo(() => {
    return { mode: "minimalAdmin", isInAdminMode: true, isInitialLoading: isFetching };
  }, [isFetching]);

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center">
        <PageTitle className="my-4" title={t("expiredPage_title", { section: t("Items") })} />
        <NavBackButton modePreference="goBackHistory" />
        {isFetching ? null : (
          <div className="mt-6">
            <ExpiringPeriodFilters
              selectedPeriod={expiringPeriod}
              onClickFilter={handleChangePeriod}
            />
          </div>
        )}
      </div>
      <div data-testid="expired-items-list">
        {isFetching ? (
          <Loader className="p-16" />
        ) : (
          <EolasVersatileList
            context={listContext}
            className="space-y-6"
            isSearchLoading={isSearchLoading}
            items={isSearchActive ? searchResults : relevantItems}
            renderItem={renderContentItems}
            isSearchable={relevantItems.length > 3}
            searchInstanceId={searchInstanceId}
            isDraggable={false}
            searchMode="click"
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            ListEmptyComponent={isSearchActive ? undefined : NoItemsFound}
          />
        )}
      </div>
    </InnerPageWrapper>
  );
});

const NoItemsFound = () => {
  const { t } = useTranslation();
  return (
    <div className="h-32 bg-grey-200 flex justify-center items-center rounded-lg">
      <Text level={1} className="text-grey-600">
        {t("empty_section_text")}
      </Text>
    </div>
  );
};

export default ManageExpiredItems;
