import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { BulkCopyItemsResponse, ContentItem, EolasShadowCopyContent } from "@eolas-medical/core";
import { BulkActionItemsModal } from "../BulkActionItemsModal/BulkActionItemsModal";
import { useCopyItems } from "modules/contentRepository/data/useCopyItems";
import { isShadowCopyContentItem } from "shared/pages/ContentRepository/ContentItems/functions/typeguards";
import { SelectableItem, ValidItem } from "../BulkActionItemsModal/types";

type Props = {
  initialItems: SelectableItem[];
  onCopySuccess?: () => void;
};

const isCopyValidItem = (
  item: ContentItem | EolasShadowCopyContent,
): item is ValidItem<BulkCopyItemsResponse> => {
  const isDraft = item.isDraft || false;
  return !isShadowCopyContentItem(item) && !isDraft;
};

export const CopyItemsModal = observer(({ initialItems, onCopySuccess }: Props) => {
  const { t } = useTranslation();

  const { mutateAsync, isError, isLoading, results } = useCopyItems({ onCopySuccess });

  return (
    <BulkActionItemsModal
      allInvalidFilesDescription={t("copy_files_all_invalid_files_description")}
      invalidFilesDescription={t("copy_files_invalid_files_description")}
      invalidFilesTitle={t("copy_files_invalid_files_title")}
      partialSuccessErrorMessage={t("copy_files_modal_partial_success_error")}
      retryFailedMessage={t("copy_files_modal_retry_failed")}
      customQueryKey="copyItemsSuccessPathToSection"
      successMessage={t("copy_files_modal_success")}
      modalTitle={t("copy_files_modal_title")}
      modalDescription={t("copy_files_modal_description")}
      genericErrorMessage={t("copy_files_modal_error")}
      bulkAction={mutateAsync}
      isError={isError}
      isLoading={isLoading}
      initialItems={initialItems}
      results={results}
      isValidItem={isCopyValidItem}
    />
  );
});
