import { sectionStore } from "@eolas-medical/core";
import { useEffect, useState } from "react";
import {
  ErrorCodes,
  getPathToItemOrSection,
} from "shared/pages/ContentRepository/ContentItems/functions/getPathToItemOrSection";
import { removeBracketsAndPercent } from "Utilities/helpers";

type Props = {
  isInAdminMode: boolean;
  sectionId?: string | null;
  currentSpaceName?: string;
  isEnabled?: boolean;
};

export const useGetPathToSection = ({
  sectionId,
  isEnabled = true,
  isInAdminMode,
  currentSpaceName,
}: Props) => {
  const [path, setPath] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<ErrorCodes | null>(null);

  const section = sectionId ? sectionStore.getSection(sectionId) : null;

  useEffect(() => {
    if (!section || !section.id || !isEnabled || isFetching || !currentSpaceName) {
      return;
    }

    const fetchPath = async () => {
      setIsFetching(true);
      const pathToLocation = await getPathToItemOrSection({
        id: section.id,
        isInAdminMode,
        encodedSelectedSpaceName: encodeURIComponent(
          removeBracketsAndPercent(currentSpaceName) || "",
        ),
      });

      if (typeof pathToLocation === "string") {
        setPath(pathToLocation);
      } else {
        setError(pathToLocation.error);
      }
      setIsFetching(false);
    };

    fetchPath();
  }, [section, isEnabled, isInAdminMode, currentSpaceName, isFetching]);

  return { path, isFetching, error };
};
