import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { AppLevelSection, sectionStore, contentClient } from "@eolas-medical/core";
import useUploadTeamMemberPhoto from "./useUploadTeamMemberPhoto";
import { updateFile } from "clients/files/files.client";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useRefetchAppData } from "Hooks";

interface EditTeamMemberInput {
  id: string;
  name: string;
  role: string;
  bio: string;
  photo: string | File | null;
}

const useEditTeamMember = () => {
  const { uploadTeamMemberPhoto } = useUploadTeamMemberPhoto();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const handleEditTeamMember = useCallback(
    async ({ id, name, role, bio, photo }: EditTeamMemberInput) => {
      const parentID = sectionStore.getChildReferenceByMainSectionType(
        AppLevelSection.meetTheTeam,
      )!.id;

      const s3Key = await uploadTeamMemberPhoto(photo, parentID);

      return useAppServicesEndpoints
        ? updateFile({
            parentID,
            input: { id, name, description: role, infoTwo: bio, key: s3Key },
          })
        : contentClient.updateContentItem({
            mainSectionId: parentID,
            contentId: id,
            contentDto: { name, description: role, infoTwo: bio, key: s3Key || undefined },
          });
    },
    [uploadTeamMemberPhoto, useAppServicesEndpoints],
  );

  const { mutate, isLoading, isSuccess, error } = useMutation(handleEditTeamMember, {
    onSuccess: refetch,
  });

  return {
    editTeamMember: mutate,
    editingTeamMember: isLoading,
    editTeamMemberSuccessful: isSuccess,
    editTeamMemberError: error instanceof Error ? error.message : "",
  };
};

export default useEditTeamMember;
