import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasSearchPayload,
  isLinkContentItem,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { KnowledgeContentAnalyticsCallbacks } from "Pages/Knowledge/pages/KnowledgeContent";

export const sponsoredSlotsAnalyticsCallbacks: KnowledgeContentAnalyticsCallbacks = {
  onLoadContentItem: (item) => {
    const payload = {
      id: item.id,
      name: item.name || "",
      type: item.type,
    };

    if (isLinkContentItem(item)) {
      trackEvent(AnalyticsEvents.KNOWLEDGE_SPONSORED_SLOT_CONTENT_ITEM_VIEWED, {
        ...payload,
        urlTarget: item.urlTarget,
      });
      return;
    }

    trackEvent(AnalyticsEvents.KNOWLEDGE_SPONSORED_SLOT_CONTENT_ITEM_VIEWED, payload);
  },
  onClickContentSection: (section) => {
    trackEvent(AnalyticsEvents.KNOWLEDGE_SPONSORED_SLOT_CONTENT_SECTION_VIEWED, {
      id: section.id,
      name: section.name || "",
    });
  },
  onSearchContentItem: (searchValue) => {
    trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
      searchString: searchValue,
      searchType: "Item",
      origin: CommunityLevelSection.sponsoredSlots,
    });
  },
  onSearchContentSection(searchValue) {
    trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
      searchString: searchValue,
      searchType: "Section",
      origin: CommunityLevelSection.sponsoredSlots,
    });
  },
};
