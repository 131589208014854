import { useRef } from "react";

import { userStore, wait } from "@eolas-medical/core";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { errorStore } from "Stores/ErrorStore";
import {
  CreateSpaceUserOutcome,
  CreateSpaceUserReturn,
  createSpaceUserAfterInvite,
  createSpaceUserWithAccessToken,
} from "../functions/createSpaceUser";

export type AcceptInviteAutoSignIntoSpaceReturn = { departmentId: string | undefined };

export const useAutoEntryAccess = ({
  onCompletion,
}: {
  onCompletion: (outcomeData: CreateSpaceUserReturn) => void;
}) => {
  const isFirstRenderRef = useRef(true);
  const { onSelectSpace } = useSelectSpaceActions();

  const acceptInviteAutoSignIntoSpace = async (): Promise<CreateSpaceUserReturn> => {
    try {
      if (!userStore.userSession.isLoggedIn) {
        return { outcome: "error" };
      }
      const inviteId = userStore.userSession.inviteId;
      const accessLinkId = userStore.userSession.accessLinkId;
      let outcome: CreateSpaceUserOutcome | undefined = undefined;
      let spaceId: string | undefined = undefined;
      if (inviteId) {
        ({ outcome, spaceId } = await createSpaceUserAfterInvite(inviteId));
      } else if (accessLinkId) {
        ({ outcome, spaceId } = await createSpaceUserWithAccessToken(accessLinkId));
      }
      if ((outcome === "success" || outcome === "alreadyExists") && spaceId) {
        // Need to wait as sometimes shouldAllowUserAccess comes back with incorrect result if called too quickly:
        await wait(500);
        await onSelectSpace(spaceId);
        return { outcome, spaceId };
      }
      return { outcome: outcome || "error" };
    } catch (error) {
      if (error instanceof Error) {
        errorStore.captureError({ error, source: "network" });
      }
      return { outcome: "error" };
    }
  };

  if (isFirstRenderRef.current) {
    isFirstRenderRef.current = false;
    acceptInviteAutoSignIntoSpace().then((data) => {
      onCompletion(data);
    });
  }
};
