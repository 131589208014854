import { avatar } from "Assets";
import { TileControls, TileContainer, Text, Title } from "UIKit";
import { generateS3PublicImageURL } from "Utilities";

import { TeamMember } from "../../../../types";
import useHighLightTeamMember from "../../../../data/useHighlightTeamMember";

interface TeamMemberTileProps {
  teamMember: TeamMember;
  isDragging: boolean;
  onEditTeamMember: (teamMember: TeamMember) => void;
  onDeleteTeamMember: (teamMember: TeamMember) => void;
}
const TeamMemberTile = ({
  teamMember,
  isDragging,
  onDeleteTeamMember,
  onEditTeamMember,
}: TeamMemberTileProps) => {
  const { toggleHighlight, highlightLoading } = useHighLightTeamMember();

  const imgUrl = teamMember.photoUrl ? generateS3PublicImageURL(teamMember.photoUrl || "") : avatar;

  return (
    <TileContainer canDrag isDragging={isDragging} data-testid="team-member-tile">
      <img
        alt=""
        srcSet={imgUrl}
        src={imgUrl}
        className="w-24 h-24 md:w-32 md:h-32 rounded-full my-4 object-cover"
      />

      <div className="flex flex-col flex-1 space-y-2">
        <Title level={7} className="line-clamp-2">
          {teamMember.name}
        </Title>
        <Text level={1} className=" text-grey-600 line-clamp-2">
          {teamMember.role}
        </Text>
        <Text level={1} className="line-clamp-2">
          {teamMember.bio}
        </Text>
      </div>

      <TileControls<TeamMember>
        content={teamMember}
        isHighlighting={highlightLoading}
        onEdit={onEditTeamMember}
        onDelete={onDeleteTeamMember}
        onHighlight={toggleHighlight}
      />
    </TileContainer>
  );
};

export default TeamMemberTile;
