import { QueryKey, useQuery } from "@tanstack/react-query";
import {
  EnrichedFavourite,
  EolasRegions,
  sectionStore,
  syncMyFavourites,
  userStore,
} from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";

type UseGetMyFavourites = {
  myFavouritesList: EnrichedFavourite[];
  myFavouritesLoading: boolean;
};

export const useGetMyFavourites = (): UseGetMyFavourites => {
  const userId = userStore.userID;
  const communityId = sectionStore.data.community?.id;
  const { supportedEolasRegion } = useSupportedRegion();

  const { isLoading, data, ...rest } = useQuery(
    makeGetMyFavouritesQueryKey({ userId, supportedEolasRegion, communityId }),
    () => syncMyFavourites({ region: supportedEolasRegion }),
  );

  return { myFavouritesList: data ?? [], myFavouritesLoading: isLoading, ...rest };
};

type MakeKeyProps = {
  userId: string;
  supportedEolasRegion: EolasRegions;
  communityId?: string;
};

export const makeGetMyFavouritesQueryKey = ({
  userId,
  supportedEolasRegion,
  communityId,
}: MakeKeyProps): QueryKey => [
  "favouritesList",
  { userId, supportedEolasRegion, communityId: communityId ?? "" },
];
