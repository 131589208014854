import { useFileDownload } from "shared/hooks/useFileDownload";
import { useNotifications } from "Components/Notifications";
import { useDocumentCache } from "services/documentCache";
import { ContentItem, isBlobContentItem } from "@eolas-medical/core";
import { isUrlString } from "Utilities";
import { isFileExtensionMediaType, mapToContentItem } from "../helpers";
import { isShadowCopyContentItem } from "shared/pages/ContentRepository/ContentItems/functions/typeguards";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { isUUID } from "Utilities/helpers";
import { useQuery } from "@tanstack/react-query";

const useDownloadContentFile = (contentItem: ContentItem) => {
  const { showNotification, hideNotification } = useNotifications();
  const { getDocumentUrl } = useDocumentCache();

  const { data: item } = useQuery({
    queryKey: ["useDownloadContentFile", contentItem.id],
    queryFn: async () => {
      if (isShadowCopyContentItem(contentItem)) {
        const original = await getOriginalFromShadowCopy(contentItem);
        if (!original) {
          return null;
        }
        return mapToContentItem(original);
      }

      return contentItem;
    },
  });

  const getFile = async () => {
    if (!item || !isBlobContentItem(item)) {
      throw new Error("File must be of type blob");
    }

    let s3FileUrl = item.url || undefined;
    let extension: string | undefined = item.media?.type as string;

    if (s3FileUrl && !isUrlString(s3FileUrl)) {
      if (!isUUID(s3FileUrl)) extension = s3FileUrl?.split(".")?.pop();
      s3FileUrl = await getDocumentUrl({ fileId: item.id, key: item.url || "" });
    }

    return {
      fileName: item.name,
      fileUrl: s3FileUrl,
      fileExt: extension,
    };
  };

  const { downloadFile } = useFileDownload({ getFileAsync: getFile });

  const downloadContentFile = async () => {
    if (!item || !isBlobContentItem(item) || !item.url) return;

    const isDownloadable = item.media?.type ? isFileExtensionMediaType(item.media.type) : false;

    if (!isDownloadable) {
      return;
    }

    showNotification({ type: "loading", title: "Getting file", id: "download-loading" });

    downloadFile(item.url, {
      onSuccess: () => hideNotification("download-loading"),
    });
  };

  return {
    downloadContentFile,
  };
};

export default useDownloadContentFile;
