import { Route } from "react-router-dom";

import { BaseAppRoute, LDFlagNames } from "Utilities/types";

import { OrganisationRoute } from "../../types";
import { OrganisationLevelSection } from "@eolas-medical/core";
import Organisation from "./Organisation";
import { DrugMonitoring } from "../Space/pages/DrugMonitoring";
import { MedusaFilesPage, MedusaSubSections } from "../Space/pages/MiniApp/MedusaMedications";
import {
  LearningManagement,
  LearningManagementSection,
} from "../Space/pages/MiniApp/LearningManagementSystem";
import { CommunicationsPortal } from "../Space/pages/MiniApp/CommunicationsPortal";
import {
  OrganisationWellbeing,
  OrganisationWellbeingSection,
} from "../Space/pages/OrganisationWellbeing";
import { GenericGuidelines, GenericGuidelinesSection } from "../Space/pages/GenericGuidelines";
import { ClientContactsPage, ClientContactsSection } from "../Space/pages/MiniApp/Contacts";
import { Guidelines, GuidelinesSection } from "../Space/pages/Guidelines";
import { useLaunchDarkly } from "Contexts";
import SpaceContentRepository from "../Space/pages/SpaceContentRepository/SpaceContentRepository";
import { OrgPatientLeaflets } from "../Space/pages/MiniApp/PatientLeaflets";
import { OrgPatientLeafletsSection } from "../Space/pages/MiniApp/PatientLeaflets/pages/Organisation/OrgPatientLeafletsSection";

export const organisationRoutes = {
  drugMonitoring: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.drugMonitoring}`,
  hospitalContacts: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalContacts}`,
  hospitalContactsSubsection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalContacts}/:sectionID`,
  hospitalGuidelines: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalGuidelines}`,
  hospitalGuidelinesSubsection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalGuidelines}/:sectionID`,
  medusaMedications: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.medusaMedications}`,
  medusaFiles: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.medusaMedications}/:sectionID`,
  learningManagement: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.learningManagement}`,
  learningManagementSubsection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.learningManagement}/:sectionID`,
  communicationPortal: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.communicationPortal}`,
  organisationWellbeing: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.organisationWellbeing}`,
  organisationWellbeingSection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.organisationWellbeing}/:sectionID`,
  genericOrganisationGuidelines: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.genericOrganisationGuidelines}`,
  genericOrganisationGuidelinesSection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.genericOrganisationGuidelines}/:sectionID`,
  contentManagement: "",
  expiredGuidelines: "",
  contentRepository: `/${BaseAppRoute.organisation}/:organisationName/content-repository/:contentRepositoryId`,
  orgPatientLeaflets: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.orgPatientLeaflets}`,
  orgPatientLeafletsSubsection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.orgPatientLeaflets}/:sectionID`,
} as const;

// Just to make sure that the keys are checked
const _organisationRoutesCheck: Record<OrganisationRoute, string> = organisationRoutes;

export const OrganisationRoutes = () => {
  const { flags } = useLaunchDarkly();
  const showContentRepository = !!flags[LDFlagNames.GENERIC_CONTENT_REPOSITORY];

  return (
    <>
      <Route
        exact
        path={`/${BaseAppRoute.organisation}/:organisationName`}
        component={Organisation}
      />

      {showContentRepository ? (
        <Route path={organisationRoutes.contentRepository} component={SpaceContentRepository} />
      ) : null}

      <Route exact component={ClientContactsPage} path={organisationRoutes.hospitalContacts} />
      <Route
        exact
        component={ClientContactsSection}
        path={organisationRoutes.hospitalContactsSubsection}
      />

      <Route exact component={Guidelines} path={organisationRoutes.hospitalGuidelines} />
      <Route
        exact
        component={GuidelinesSection}
        path={organisationRoutes.hospitalGuidelinesSubsection}
      />

      <Route exact component={DrugMonitoring} path={organisationRoutes.drugMonitoring} />

      <Route exact component={MedusaSubSections} path={organisationRoutes.medusaMedications} />
      <Route exact component={MedusaFilesPage} path={organisationRoutes.medusaFiles} />

      <Route exact component={LearningManagement} path={organisationRoutes.learningManagement} />
      <Route
        exact
        component={LearningManagementSection}
        path={organisationRoutes.learningManagementSubsection}
      />

      <Route exact component={CommunicationsPortal} path={organisationRoutes.communicationPortal} />

      <Route
        exact
        component={OrganisationWellbeing}
        path={organisationRoutes.organisationWellbeing}
      />

      <Route
        exact
        component={OrganisationWellbeingSection}
        path={organisationRoutes.organisationWellbeingSection}
      />

      <Route
        exact
        component={GenericGuidelines}
        path={organisationRoutes.genericOrganisationGuidelines}
      />

      <Route
        exact
        component={GenericGuidelinesSection}
        path={organisationRoutes.genericOrganisationGuidelinesSection}
      />
      <Route exact component={OrgPatientLeaflets} path={organisationRoutes.orgPatientLeaflets} />
      <Route
        exact
        component={OrgPatientLeafletsSection}
        path={organisationRoutes.orgPatientLeafletsSubsection}
      />
    </>
  );
};
