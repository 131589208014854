import { memo } from "react";

import { ClinicalCalculatorsSubSection } from "@eolas-medical/core";

import { Text } from "UIKit";

export const ExplanationComponents = {
  [ClinicalCalculatorsSubSection.creatinineClearance]: () => (
    <>
      <Text level={1} className="font-bold mb-4">
        The choice of whether to use actual body weight, ideal body weight or adjusted body weight
        to calculate CrCl can depend on various factors, such as BMI or the pharmacological
        properties of the drug in consideration. Please seek advice if you are unsure.
      </Text>
      <br />
      <br />
      <CreatinineClearance />
      <br />
      <br />
      <IdealBodyWeight />
      <br />
      <br />
      <AdjustedBodyWeight />
      <br />
      <br />
      <Text level={1} className="font-bold mt-4">
        References
      </Text>
      <br />
      <Text level={1}>
        Brown DL, Masselink AJ, Lalla CD. Functional range of creatinine clearance for renal drug
        dosing: a practical solution to the controversy of which weight to use in the
        Cockcroft-Gault equation. Ann Pharmacother. 2013 Jul-Aug;47(7-8): 1039-44. doi:
        10.1345/aph.15176. Epub 2013 Jun 11. Review. PubMed MID: 23757387
      </Text>
      <br />
      <br />
      <Text level={1}>
        Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron.
        1976;16(1):31-41. PubMed PMID: 1244564.
      </Text>
    </>
  ),
  [ClinicalCalculatorsSubSection.gentamicinDose]: () => (
    <>
      <CreatinineClearance />
      <br />
      <br />
      <IdealBodyWeight />
      <br />
      <br />
      <AdjustedBodyWeight />
      <br />
      <br />
      <GentDose />
    </>
  ),
  [ClinicalCalculatorsSubSection.idealBodyWeight]: () => (
    <>
      <IdealBodyWeight top />
      <br />
      <br />
      <AdjustedBodyWeight />
      <br />
      <Text level={1}>
        Adjusted body weight is only calculated if actual body weight is more than 20% of ideal body
        weight.
      </Text>
      <br />
      <br />
      <Text level={1} className="font-bold mt-4">
        References
      </Text>
      <br />
      <Text level={1}>Devine BJ. Gentamicin therapy. Drug Intell Clin Pharm. 1974;8:650-655.</Text>
    </>
  ),
};

const AdjustedBodyWeight = memo(() => (
  <>
    <Text level={1} className="font-bold mt-4">
      Adjusted Body Weight
    </Text>
    <br />
    <Text level={1}>IBW + (0.4 x (weight - IBW))</Text>
  </>
));

const IdealBodyWeight = memo(({ top = false }: { top?: boolean }) => (
  <>
    <Text level={1} className={`font-bold ${top ? "" : "mt-4"}`}>
      Ideal Body Weight (IBW)
    </Text>
    <br />
    <Text level={1}>Female: </Text>
    <Text level={1}>45.5 + 2.3 x (inches - 60)</Text>
    <br />
    <Text level={1}>Male: </Text>
    <Text level={1}>50 + 2.3 x (inches - 60)</Text>
  </>
));

const CreatinineClearance = memo(() => (
  <>
    <Text level={1} className="font-bold">
      Creatinine Clearance
    </Text>
    <br />
    <Text level={1}>Female: </Text>
    <Text level={1}>1.04 x (140-age) x kg / Cr (umol/L)</Text>
    <br />
    <Text level={1}>Male: </Text>
    <Text level={1}>1.23 x (140-age) x kg / Cr (umol/L)</Text>
  </>
));

const GentDose = memo(() => (
  <>
    <Text level={1} className="font-bold mt-4">
      Dose (mg)
    </Text>
    <br />
    <Text level={1}>{"CrCl >= 30: "}</Text>
    <Text level={1}>5 x actual weight (or Adjusted Body Weight), up to a max dose of 500mg</Text>
    <br />
    <Text level={1}>{"CrCl > 20: "}</Text>
    <Text level={1}>3 x actual weight (or Adjusted Body Weight), up to a max dose of 300mg </Text>
    <br />
    <Text level={1}>{"CrCl < 20: "}</Text>
    <Text level={1}>Single daily dosing likely inappropriate, seek alternative advice.</Text>
    <br />
    <br />
    <Text level={1}>
      {
        "This calculator uses the patient's actual weight or adjusted body weight (ideal body weight + 40% of excess body weight) if they are overweight (> 20% IBW)."
      }
    </Text>
    <br />
    <br />
    <Text level={1}>
      It also implements cut offs based on estimated creatinine clearance (CrCl).
    </Text>
    <br />
    <br />
    <Text level={1}>
      {
        " The Cockcroft and Gault Creatinine Clearance has been validated in Gentamicin dosing when an adult patient's creatinine, ideal body weight and age are known."
      }
    </Text>
    <br />
    <br />
    <Text level={1}>
      This calculator is only for initial dosing and subsequent dosing should be based on peak and
      trough gentamicin levels.
    </Text>
    <br />
    <br />
    <Text level={1} className="font-bold mt-4">
      References
    </Text>
    <br />
    <Text level={1}>Devine BJ. Gentamicin therapy. Drug Intell Clin Pharm. 1974;8:650-655.</Text>
    <br />
    <Text level={1}>
      Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron.
      1976;16(1):31-41. PubMed PMID: 1244564.
    </Text>
  </>
));
