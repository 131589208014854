import { motion } from "framer-motion";

import { ClinicalQuestionIcon } from "Assets";
import { Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const UserPrompt = ({ exampleQuestions }: { exampleQuestions: string[] }) => {
  const { t } = useTranslation();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  useEffect(() => {
    const intervalTime = currentQuestionIndex === 0 ? 1500 : 3000;

    const interval = setInterval(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % exampleQuestions.length);
    }, intervalTime);

    return () => clearInterval(interval);
  }, [currentQuestionIndex, exampleQuestions.length]);

  return (
    <div className="flex flex-col items-center justify-center">
      <ClinicalQuestionIcon className="mb-4" />
      <Title level={7} className="text-center mb-4">
        {t("user_prompt")}
      </Title>
      <motion.div
        key={currentQuestionIndex}
        initial={{ y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.75 }}
        exit={{ opacity: 0, y: "100%" }}
        className="
          flex
          items-center
          w-full
          opacity-0
          justify-center"
      >
        <Text level={2} className="font-semibold">
          {exampleQuestions[currentQuestionIndex]}
        </Text>
      </motion.div>
    </div>
  );
};
