import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import NotificationCenter from "Components/NotificationCenter/NotificationCenter";
import { useRoleSwitcher } from "Components/Navigation/hooks";
import { DesktopRoleSwitcher } from "../DesktopRoleSwitcher/DesktopRoleSwitcher";
import { observer } from "mobx-react-lite";

const DesktopHeader = observer(() => {
  const { flags } = useLaunchDarkly();
  const { showSwitcher } = useRoleSwitcher();

  const isNotificationCenterActive = !!flags[LDFlagNames.NOTIFICATION_CENTER];

  return (
    <div className="fixed top-8 right-6 flex items-center space-x-4 z-30">
      {isNotificationCenterActive && <NotificationCenter />}
      {showSwitcher && <DesktopRoleSwitcher />}
    </div>
  );
});

export default DesktopHeader;
