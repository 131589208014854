import { Button, InnerPageWrapper, PageTitle, Text } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLinkedDocumentsNoAccess } from "./useLinkedDocumentsNoAccess";
import { LinkedDocumentsLoader } from "../KnowledgeLinkedDocumentsNewTab/LinkedDocumentsLoader";

type Props = {
  ownerId: string;
  /** The spaceId will be the admin space in the case access is request for an organisation, this fn should be memoized */
  onAccessGranted: (spaceId: string) => void;
};

export const LinkedDocumentsNoAccess = ({ ownerId, onAccessGranted }: Props) => {
  const {
    renderCondition,
    ownerName,
    ownerType,
    handleOpenAccessRequestModal,
    handleAddAnotherEmail,
    isInSpaceOrg,
    handleManualSwitchSpace,
  } = useLinkedDocumentsNoAccess({ ownerId, onAccessGranted });

  const { t } = useTranslation();

  if (renderCondition === "loading") {
    return <LinkedDocumentsLoader />;
  }

  if (renderCondition === "continue") {
    // In this scenario (signed into a diff space), we give the user the option to manually switch space when they have access
    if (isInSpaceOrg) {
      return (
        <InnerPageWrapper>
          <div className="flex flex-col justify-start items-center flex-1 pt-24 text-center">
            <Text level={1} className="mb-24 text-lg">
              {t("linked_documents_viewer_not_in_correct_space", {
                spaceName: ownerName,
              })}
            </Text>
            <Button size="xl" onClick={handleManualSwitchSpace}>
              {t("linked_documents_viewer_switch_to_space_button", {
                spaceName: ownerName,
              })}
            </Button>
          </div>
        </InnerPageWrapper>
      );
    }

    // In this scenario (not signed in to another space), we render nothing as this state lasts for a very short time before they are signed in
    // automatically
    return null;
  }

  if (renderCondition === "error") {
    return (
      <InnerPageWrapper>
        <div className="flex flex-col justify-start items-center flex-1 pt-16">
          <PageTitle title={t("linked_documents_viewer_error")} />
          <Text level={1}>{t("linked_documents_viewer_unexpected_state")}</Text>
        </div>
      </InnerPageWrapper>
    );
  }

  if (renderCondition === "access-pending") {
    return (
      <InnerPageWrapper>
        <div
          className="flex flex-col justify-start items-center flex-1 pt-16"
          data-testid="portfolio-error-fallback"
        >
          <PageTitle title={t("linked_documents_viewer_access_pending")} className="text-center" />
          <Text level={1} className="my-2 text-center">
            {t("linked_documents_viewer_access_pending_message", { spaceOrgName: ownerName })}
          </Text>

          <Link
            to={ownerType === "space" ? `/${BaseAppRoute.spaces}` : `/${BaseAppRoute.organisation}`}
            className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-8"
          >
            {t("take_me_home")}
          </Link>
        </div>
      </InnerPageWrapper>
    );
  }

  if (renderCondition === "no-access") {
    return (
      <InnerPageWrapper>
        <div className="flex flex-col justify-start items-center flex-1 pt-16">
          <PageTitle title={t("linked_documents_viewer_access_denied")} />
          <Text level={1} className="my-2 text-center">
            {t("linked_documents_viewer_no_access_request_access_message", {
              spaceOrgName: ownerName,
            })}
          </Text>

          <div className="flex flex-row items-center justify-center space-x-4">
            <Button
              color="blue"
              variant="solid"
              onClick={() => handleOpenAccessRequestModal(ownerName ?? "")}
            >
              {t("general_request_access")}
            </Button>
            <Link
              to={
                ownerType === "space" ? `/${BaseAppRoute.spaces}` : `/${BaseAppRoute.organisation}`
              }
              className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2"
            >
              {t("take_me_home")}
            </Link>
          </div>
        </div>
      </InnerPageWrapper>
    );
  }

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-start items-center flex-1 pt-16">
        <PageTitle title={t("linked_documents_viewer_access_denied")} />
        <Text level={1} className="my-2 text-center">
          {t("linked_documents_viewer_no_access_request_access_message", {
            spaceOrgName: ownerName,
          })}
        </Text>

        <div className="flex flex-row items-center justify-center space-x-4">
          <Button color="blue" variant="solid" onClick={handleAddAnotherEmail}>
            {t("general_add_email")}
          </Button>
          <Button
            color="blue"
            variant="solid"
            onClick={() => handleOpenAccessRequestModal(ownerName ?? "")}
          >
            {t("general_request_access")}
          </Button>
          <Link
            to={ownerType === "space" ? `/${BaseAppRoute.spaces}` : `/${BaseAppRoute.organisation}`}
            className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2"
          >
            {t("take_me_home")}
          </Link>
        </div>
      </div>
    </InnerPageWrapper>
  );
};
