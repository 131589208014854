import { observer } from "mobx-react-lite";
import { ReactElement } from "react";

import { progressBarStore } from "./stores/progressBar.store";
import { EolasProgressBarBaseProps } from "./types";
import { ProgressBar } from "UIKit/ProgressBar";

type EolasProgressBarProps = Omit<EolasProgressBarBaseProps, "progress"> & {
  progressBarId: string;
};

export const EolasProgressBar = observer(
  ({ progressBarId, ...rest }: EolasProgressBarProps): ReactElement => {
    const progress = progressBarStore.progressBarMap[progressBarId] ?? 0;

    return <ProgressBar {...rest} progress={progress} />;
  },
);
