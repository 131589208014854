import { eolasLogger } from "@eolas-medical/core";
import { IAllProps as EditorProps } from "@tinymce/tinymce-react";

const makeErrorCallback = (errorType: string) => (error: unknown) => {
  eolasLogger.error(new Error(`TinyMCE error: ${errorType}`), { error });
};

export const errorHandling: Required<
  Pick<
    EditorProps,
    | "onSkinLoadError"
    | "onIconsLoadError"
    | "onModelLoadError"
    | "onPluginLoadError"
    | "onThemeLoadError"
    | "onScriptsLoadError"
    | "onLanguageLoadError"
  >
> = {
  onSkinLoadError: makeErrorCallback("SkinLoadError"),
  onIconsLoadError: makeErrorCallback("IconLoadError"),
  onModelLoadError: makeErrorCallback("ModelLoadError"),
  onPluginLoadError: makeErrorCallback("PluginLoadError"),
  onThemeLoadError: makeErrorCallback("ThemeLoadError"),
  onScriptsLoadError: makeErrorCallback("ScriptsLoadError"),
  onLanguageLoadError: makeErrorCallback("LanguageLoadError"),
};
