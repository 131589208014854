import {
  AppLevelSection,
  CommunityLevelSection,
  OrganisationLevelSection,
  hasStringProp,
} from "@eolas-medical/core";
import {
  orgSupportedContentDeepLinkTypes,
  spaceSupportedContentDeepLinkTypes,
  knowledgeSupportedContentDeepLinkTypes,
  knowledgeSupportedClinicalResourcesDeepLinkTypes,
} from "deeplinking/constants";
import {
  CommunicationPortalDeepLinkParams,
  OrganisationSupportedContentDeepLinks,
} from "deeplinking/types/contentDeeplinks/organisationContentDeeplinks.types";
import {
  ChecklistsDeepLinkParams,
  NewsFeedDeepLinkParams,
  SpaceContentRepoDeepLinkParams,
  SpaceSupportedContentDeepLinks,
} from "deeplinking/types/contentDeeplinks/spacesContentDeeplinks.types";

import {
  CommunityMedicationsBNFDeepLinkParams,
  CommunityMedicationsMedHighlightsDeepLinkParams,
  CommunityMedicationsSPMCGuidelinesDeepLinkParams,
  KnowledgeNationalGuidelinesDeepLinkParams,
  KnowledgeNiceDeepLinkParams,
  KnowledgeSupportedContentDeepLinks,
  KnowledgeWikiEmDeepLinkParams,
  KnowledgeSupportedClinicalResourcesDeepLinkTypes,
  KnowledgeClinicalCalculatorsDeepLinkParams,
  KnowledgeClinicalResourceDeepLinkParams,
  KnowledgeNationalPatientLeafletsDeepLinkParams,
} from "deeplinking/types/contentDeeplinks/knowledgeContentDeeplinks.types";

/**
 * Checks whether the type property of the given params is supported for a space content deep link
 * within this version
 */
export const isSupportedSpaceContentDeepLinkType = (
  type: string,
): type is SpaceSupportedContentDeepLinks => {
  if (Object.values<string>(spaceSupportedContentDeepLinkTypes).includes(type)) {
    return true;
  }
  return false;
};

export const isSupportedSpaceContentRepoDeepLinkType = (
  type: string,
): type is SpaceContentRepoDeepLinkParams["type"] => {
  if (isSupportedSpaceContentDeepLinkType(type) && type !== AppLevelSection.newsFeed) {
    return true;
  }
  return false;
};

export const isSupportedDeeplinkType = (
  type: string,
): type is
  | SpaceSupportedContentDeepLinks
  | KnowledgeSupportedContentDeepLinks
  | OrganisationSupportedContentDeepLinks => {
  return (
    isSupportedSpaceContentDeepLinkType(type) ||
    isSupportedOrganisationContentDeepLinkType(type) ||
    isSupportedKnowledgeContentDeepLinkType(type)
  );
};

/**
 * Checks whether the type property of the given params is supported for a organisation content deep link
 * within this version
 */
export const isSupportedOrganisationContentDeepLinkType = (
  type: string,
): type is OrganisationSupportedContentDeepLinks => {
  if (Object.values<string>(orgSupportedContentDeepLinkTypes).includes(type)) {
    return true;
  }
  return false;
};

export const isSupportedKnowledgeContentDeepLinkType = (
  type: string,
): type is KnowledgeSupportedContentDeepLinks => {
  if (Object.values<string>(knowledgeSupportedContentDeepLinkTypes).includes(type)) {
    return true;
  }
  return false;
};

export const isSMPCGuidelineDeeplinkParams = (
  params: unknown,
): params is CommunityMedicationsSPMCGuidelinesDeepLinkParams =>
  hasStringProp(params, "mainSectionId") &&
  hasStringProp(params, "contentId") &&
  hasStringProp(params, "ownerId") &&
  hasStringProp(params, "parentId") &&
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.communityMedications;

export const isMedHighlightsDeeplinkParams = (
  params: unknown,
): params is CommunityMedicationsMedHighlightsDeepLinkParams =>
  hasStringProp(params, "mainSectionId") &&
  hasStringProp(params, "medicationHighlightId") &&
  hasStringProp(params, "ownerId") &&
  hasStringProp(params, "parentId") &&
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.communityMedications;

export const isWikEmParams = (params: unknown): params is KnowledgeWikiEmDeepLinkParams =>
  hasStringProp(params, "wikemId") &&
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.wikiEm;

export const isSupportedBNFMedicationType = (
  subsection: string,
): subsection is CommunityMedicationsBNFDeepLinkParams["subsectionType"] =>
  [
    "bnf",
    "bnfc",
    "guidance_adult",
    "guidance_child",
    "treatment_summaries_adult",
    "treatment_summaries_child",
  ].includes(subsection);

export const isBNFDeeplinkParams = (
  params: unknown,
): params is CommunityMedicationsBNFDeepLinkParams =>
  hasStringProp(params, "bnfId") &&
  hasStringProp(params, "subsectionType") &&
  isSupportedBNFMedicationType(params.subsectionType) &&
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.communityMedications;

export const isNiceGuidelinesDeeplinkParams = (
  params: unknown,
): params is KnowledgeNiceDeepLinkParams =>
  hasStringProp(params, "niceSummaryId") &&
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.niceGuidelines;

export const isNationalGuidelinesDeeplinkParams = (
  params: unknown,
): params is KnowledgeNationalGuidelinesDeepLinkParams =>
  hasStringProp(params, "fileId") &&
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.nationalGuidelines;

export const isClinicalCalculatorsDeeplinkParams = (
  params: unknown,
): params is KnowledgeClinicalCalculatorsDeepLinkParams =>
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.clinicalCalculators &&
  hasStringProp(params, "fileId");

export const isNationalPatientLeafletsDeeplinkParams = (
  params: unknown,
): params is KnowledgeNationalPatientLeafletsDeepLinkParams =>
  hasStringProp(params, "type") &&
  params.type === CommunityLevelSection.communityPatientLeaflets &&
  hasStringProp(params, "fileId");

/**
 * Checks if the give params match a deeplink for either
 * National guidelines
 * Clinical calculators
 * National patient leaflets
 */
export const isClinicalResourceDeeplinkParams = (
  params: unknown,
): params is KnowledgeClinicalResourceDeepLinkParams =>
  isNationalGuidelinesDeeplinkParams(params) ||
  isClinicalCalculatorsDeeplinkParams(params) ||
  isNationalPatientLeafletsDeeplinkParams(params);

export const isClinicalResourceMainSectionType = (
  type: unknown,
): type is KnowledgeSupportedClinicalResourcesDeepLinkTypes => {
  return Object.values(knowledgeSupportedClinicalResourcesDeepLinkTypes).includes(
    type as KnowledgeSupportedClinicalResourcesDeepLinkTypes,
  );
};

export const isChecklistDeeplinkParams = (params: unknown): params is ChecklistsDeepLinkParams =>
  hasStringProp(params, "checklistId") &&
  hasStringProp(params, "type") &&
  hasStringProp(params, "spaceId") &&
  params.type === AppLevelSection.checklists;

export const isNewsFeedDeeplinkParams = (params: unknown): params is NewsFeedDeepLinkParams =>
  hasStringProp(params, "fileId") &&
  hasStringProp(params, "type") &&
  params.type === AppLevelSection.newsFeed;

export const isCommsPortalDeepLinkParams = (
  params: unknown,
): params is CommunicationPortalDeepLinkParams =>
  hasStringProp(params, "fileId") &&
  hasStringProp(params, "type") &&
  params.type === OrganisationLevelSection.communicationPortal;
