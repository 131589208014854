import { GeneralCategory } from "@eolas-medical/core";
import { KnowledgeNiceDeepLinkParams } from "deeplinking/types/contentDeeplinks/knowledgeContentDeeplinks.types";
import { snakeCase } from "lodash";
import { BaseAppRoute } from "Utilities/types";

type MakeRouteParams = { currentPath: string; item: GeneralCategory };
export const makeNiceGuidelinesRoute = ({ currentPath, item }: MakeRouteParams): string => {
  const snakeCaseSection = snakeCase(item.title);

  return `${currentPath}/${snakeCaseSection}`;
};

export const constructNiceUrl = (
  typedDeeplinkParams: KnowledgeNiceDeepLinkParams & { pageNo?: number },
) => {
  const { niceSummaryId, niceSectionId, niceSubsectionId, pageNo } = typedDeeplinkParams;
  let url = `/${BaseAppRoute.knowledge}/niceGuidelines/viewer/${encodeURIComponent(niceSummaryId)}`;

  const queryParams = new URLSearchParams();

  if (niceSectionId) {
    queryParams.append("niceSectionId", encodeURIComponent(niceSectionId));
  }
  if (niceSubsectionId) {
    queryParams.append("niceSubsectionId", encodeURIComponent(niceSubsectionId));
  }
  // only append pageNo if there is no section or subsection
  if (!niceSectionId && !niceSubsectionId && pageNo) {
    queryParams.append("pageNo", encodeURIComponent(pageNo));
  }
  if (queryParams.toString()) {
    url += `?${queryParams.toString()}`;
  }
  return url;
};
