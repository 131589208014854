import { SectionData } from "./types";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "UIKit/IconPicker/types";
import ConfigureSectionStep from "./ConfigureSectionStep";
import { useSectionValidationSchema } from "./useSectionValidationSchema";
import { Step } from "../../types";

interface UseConfigureSectionStepProps<T> {
  stepName: T;
  section?: SectionData;
  title: string;
  nameInputLabel?: string;
  descriptionInputLabel?: string;
  nameInputPlaceholder?: string;
  descriptionInputPlaceholder?: string;
  iconPickerTitle?: string;
  cancelLabel?: string;
  nextLabel?: string;
  doneLabel?: string;
  iconsList?: Icon[];
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit: ({ name, iconName, description }: ConfigureSectionDto) => void;
  onEnter: () => void;
  error?: string;
  validationItems?: string[];
  requiredNameError?: string;
  requiredDescriptionError?: string;
  minLengthError?: string;
  existsError?: string;
  showDescription?: boolean;
  disableIconPicker?: boolean;
  isDescriptionRequired?: boolean;
}

interface ConfigureSectionDto {
  name: string;
  description?: string;
  iconName: string;
}

export const useConfigureSectionStep = <T extends string>({
  stepName,
  section,
  iconsList = [],
  title,
  nameInputLabel = "Section Name",
  descriptionInputLabel = "Section Description",
  nameInputPlaceholder = "Enter section name...",
  descriptionInputPlaceholder = "Enter a short description...",
  iconPickerTitle = "Select icon",
  cancelLabel = "Cancel",
  nextLabel = "Next",
  doneLabel = "Done",
  isLoading = false,
  onCancel = () => {
    return;
  },
  onEnter,
  onSubmit,
  error,
  validationItems = [],
  requiredNameError = "Section name is required",
  requiredDescriptionError = "Section description is required",
  minLengthError = "Section Name must be at least 3 characters",
  existsError = "Section with this name already exists",
  showDescription = false,
  disableIconPicker = false,
  isDescriptionRequired = true,
}: UseConfigureSectionStepProps<T>): Step<T> => {
  const [hideButtons, setHideButtons] = useState(false);
  const [showIconPicker, setShowIconPicker] = useState(false);

  const sectionValidationSchema = useSectionValidationSchema({
    validationItems,
    requiredNameError,
    requiredDescriptionError,
    minLengthError,
    existsError,
    showDescription,
    isDescriptionRequired,
  });

  const defaultValues: ConfigureSectionDto = {
    name: section?.name || "",
    iconName: section?.iconName || "",
  };

  if (showDescription) {
    defaultValues.description = section?.description || "";
  }

  const { control, formState, handleSubmit, setValue, getValues } = useForm<{
    name: string;
    description?: string;
    iconName: string;
  }>({
    defaultValues,
    resolver: yupResolver(sectionValidationSchema),
  });

  const handlePrev = () => {
    onCancel();
  };

  const handleSubmitStep = ({ name, iconName, description }: ConfigureSectionDto) => {
    if (iconName) {
      setValue("iconName", iconName);
    } else {
      setShowIconPicker(false);
    }

    const sectionData: ConfigureSectionDto = {
      name,
      iconName,
    };

    if (description) {
      sectionData.description = description;
    }

    if (disableIconPicker && name) {
      sectionData.description = description;
      sectionData.iconName = "";
      onSubmit(sectionData);
      return;
    }

    if (name && iconName) {
      onSubmit(sectionData);
    } else {
      setShowIconPicker(true);
    }
  };

  const handleSetIcon = (iconName: string) => {
    setValue("iconName", iconName);
  };

  const handleAddingIcon = (isAddingIcon: boolean) => {
    setHideButtons(isAddingIcon);
  };

  const hasIcon = !!getValues("iconName");

  return {
    name: stepName,
    title,
    component: (
      <ConfigureSectionStep
        section={section}
        control={control}
        iconsList={iconsList}
        nameInputLabel={nameInputLabel}
        descriptionInputLabel={descriptionInputLabel}
        nameInputPlaceholder={nameInputPlaceholder}
        descriptionInputPlaceholder={descriptionInputPlaceholder}
        onEnter={onEnter}
        onSetIcon={handleSetIcon}
        isLoading={formState.isSubmitting}
        iconPickerTitle={iconPickerTitle}
        showIconPicker={showIconPicker}
        showDescription={showDescription}
        onAddingIcon={handleAddingIcon}
        isDescriptionRequired={isDescriptionRequired}
      />
    ),
    onSubmit: handleSubmit(handleSubmitStep),
    backLabel: cancelLabel,
    nextLabel: hasIcon ? doneLabel : nextLabel,
    isLoading: isLoading,
    submitError: !!error,
    onPrev: handlePrev,
    hideButtons,
  };
};

export default useConfigureSectionStep;
