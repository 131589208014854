import {
  eolasLogger,
  hasProp,
  hasStringProp,
  isObject,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import localforage from "localforage";
import { logout } from "Pages/Authentication/auth.actions";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { localSearchStore } from "Stores/localSearch/localSearch.store";

export const migrateFromFileStore = async () => {
  const oldFileStore = await localforage.getItem("fileStore");
  if (!oldFileStore) {
    return;
  }
  try {
    if (userStore.userSession.isInOrganisation) {
      if (hasProp(oldFileStore, "fileMap") && isObject(oldFileStore.fileMap)) {
        const files = await contentDbStore.addMultipleItems(Object.values(oldFileStore.fileMap));
        hasStringProp(oldFileStore, "timestamp") &&
          contentDbStore.setLastUpdatedTimestamp(oldFileStore.timestamp);
        const checklistIdsAdded = sectionStore.checklistTemplatesList.map(({ id }) => id);
        await localSearchStore.initialiseDb({ isNew: true });
        await localSearchStore.updateDb({
          filesAdded: files,
          checklistIdsAdded,
          isInitialPopulation: true,
        });
        await localSearchStore.persistDb();
      }
    }
  } catch (error) {
    eolasLogger.error(new Error("Error with file migration, user logged out"), { error });
    logout();
  } finally {
    localforage.removeItem("fileStore");
  }
};
