import { RefObject } from "react";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";

import { PDF_TRON_SOURCE } from "config/pdftron.constants";
import { EolasDocumentViewerBaseProps, EolasDocumentViewerErrorCode, GetUrl } from "./types";
import { eolasLogger, hasProp, Maybe } from "@eolas-medical/core";
import { getFileExtensionFromString } from "Utilities/helpers";

export class EolasDocumentViewerError extends Error {
  code: EolasDocumentViewerErrorCode;
  constructor(message: string, code: EolasDocumentViewerErrorCode) {
    super(message);
    this.name = "EolasDocumentViewerError";
    this.code = code;
  }
}

export const initialiseEolasDocumentViewer = async ({
  ref,
  elementsToDisableOverride,
  getUrl,
  onError,
  onLoadFile,
  fileName,
  startingPage,
  isReadOnly = true,
}: {
  ref: RefObject<HTMLDivElement>;
  getUrl: GetUrl;
} & EolasDocumentViewerBaseProps): Promise<void> => {
  let WebViewerInstance: WebViewerInstance | null = null;

  const onLoadError = (error?: unknown, extraData?: Record<string, unknown>) => {
    const newError = new EolasDocumentViewerError("Failed to load document", "LOAD_ERROR");
    eolasLogger.error(newError, {
      originalError: error || null,
      fileName,
      ...(extraData ? extraData : {}),
    });
    onError?.(newError);
  };

  let blob: Blob | null = null;
  let url: Maybe<string> = null;

  try {
    url = await getUrl();
    if (!url) {
      const newError = new EolasDocumentViewerError("Failed to get URL", "GET_URL_ERROR");
      onLoadError(newError);
      return;
    }
    const response = await fetch(url);
    if (!response.ok) {
      onLoadError(new Error(response.statusText), { url });
      return;
    }
    blob = await response.blob();
  } catch (error) {
    onLoadError(error);
  }

  if (!blob) {
    return;
  }

  try {
    if (!hasProp(ref, "current") || !ref.current) {
      throw new EolasDocumentViewerError(
        "divAnchorRef does not have a current property. Have you attached the ref to an element?",
        "INIT_ERROR",
      );
    }

    WebViewerInstance = await WebViewer(
      {
        path: PDF_TRON_SOURCE,
        licenseKey: process.env.REACT_APP_PDF_TRON,
        disabledElements: elementsToDisableOverride ?? [
          // Ribbon bar:
          "default-ribbon-group",
          // Buttons in main header bar:
          "saveAsButton",
          "downloadButton",
          "settingsButton",
          "leftPanelButton",
          "notesPanelToggle",
          "annotationEditToolButton",
          // Annotate menu buttons:
          "textHighlightToolButton",
          "textUnderlineToolButton",
          "textSquigglyToolButton",
          "textStrikeoutToolButton",
          "linkButton",
          // Context menu buttons:
          "stickyToolButton",
          "highlightToolButton",
          "freeHandToolButton",
          "freeHandHighlightToolButton",
          "freeTextToolButton",
          "markInsertTextToolButton",
          "markReplaceTextToolButton",
        ],
      },
      ref.current,
    );
  } catch (error) {
    const newError = new EolasDocumentViewerError(
      "Failed to initialise EolasDocumentViewer",
      "INIT_ERROR",
    );
    eolasLogger.error(newError, { originalError: error });
    onError?.(newError);
  }

  if (!WebViewerInstance) {
    return;
  }

  const { Core } = WebViewerInstance;

  try {
    await Core.documentViewer.loadDocument(blob, {
      extension: getFileExtensionFromString(fileName) || "pdf",
    });
  } catch (error) {
    onLoadError(error, { url });
    return;
  }

  if (typeof startingPage === "number") {
    Core.documentViewer.setCurrentPage(startingPage, false);
  }

  Core.disableEmbeddedJavaScript();

  if (isReadOnly) {
    Core.documentViewer.enableReadOnlyMode();
  }

  onLoadFile?.(WebViewerInstance);
};
