import {
  AnalyticsEvents,
  BulkMoveItemsResponse,
  contentClient,
  MoveItemSuccess,
  FilesMovedPayload,
} from "@eolas-medical/core";
import { useBulkActionItems } from "./useBulkActionItems";
import { trackEvent } from "API/Analytics";

export const useMoveItems = ({ onMoveSuccess }: { onMoveSuccess?: () => void }) => {
  return useBulkActionItems<BulkMoveItemsResponse>({
    actionFn: ({ destinationSectionId, contentItems }) =>
      contentClient.bulkMoveContentItems({
        targetSection: destinationSectionId,
        items: contentItems.map(({ id }) => id),
      }),
    analyticsFn: ({
      successfulItems,
      destinationSectionId,
    }: {
      successfulItems: MoveItemSuccess[];
      destinationSectionId?: string;
    }) => {
      trackEvent<FilesMovedPayload>(AnalyticsEvents.FILES_MOVED, {
        numberOfFiles: successfulItems.length,
        fileIds: successfulItems.map((item) => item.value.id),
        destinationSectionId: destinationSectionId || "",
      });
    },
    onActionSuccess: onMoveSuccess,
  });
};
