import { Dispatch, SetStateAction, useRef } from "react";
import { ReactPlayerProps } from "react-player";

import { CompletedContentStatus, isFileCompleted, sectionStore } from "@eolas-medical/core";
import { VideoPlayer } from "Components";
import { useCompleteFile, useRunOnMountUnmount } from "Hooks";
import { observer } from "mobx-react-lite";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

export type VideoState = { name: string; url: string; fileId: string };

const VIDEO_COMPLETION_THRESHOLD = 0.75;

export const VideoComponent = observer(
  ({
    videoState: { fileId, url, name },
    setVideoState,
  }: {
    videoState: VideoState;
    setVideoState: Dispatch<SetStateAction<VideoState | null>>;
  }) => {
    const videoMarkedAsComplete = useRef(false);
    const videoRef = useRef<HTMLHeadingElement>(null);
    const { isAdmin: isOnAdminView } = sectionStore;
    const { onCompleteFile } = useCompleteFile();

    const onVideoProgress: ReactPlayerProps["onProgress"] = async (playerState) => {
      if (
        isOnAdminView ||
        videoMarkedAsComplete.current ||
        playerState.played < VIDEO_COMPLETION_THRESHOLD
      ) {
        return;
      }
      videoMarkedAsComplete.current = true;
      const file = await contentDbStore.getItem(fileId);
      if (file && !isFileCompleted(file)) {
        onCompleteFile(fileId, CompletedContentStatus.SEEN);
      }
    };

    useRunOnMountUnmount({
      onMount: () => {
        videoRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      },
    });

    return (
      <div ref={videoRef}>
        <VideoPlayer
          url={url}
          title={name}
          closeVideo={() => setVideoState(null)}
          onVideoProgress={onVideoProgress}
          isPlaying={false}
        />
      </div>
    );
  },
);
