import { NotificationBellIcon } from "Assets";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { Text } from "UIKit";
import { twMerge } from "tailwind-merge";

interface NotificationBellProps {
  notificationCount: number;
  onClick: () => void;
}

const NotificationBell = ({ notificationCount, onClick }: NotificationBellProps) => {
  const {
    isOnSpacesRoot,
    isOnSelectSpaceRoot,
    isOnMeRoot,
    isOnOrganisationRoot,
    isOnKnowledgeRoot,
  } = useEolasNavigation();

  const backgroundClass = () => {
    if (isOnSpacesRoot || isOnSelectSpaceRoot) {
      return "bg-blue-700";
    }

    if (isOnOrganisationRoot) {
      return "bg-orange-600";
    }

    if (isOnMeRoot) {
      return "bg-yellow-600";
    }

    if (isOnKnowledgeRoot) {
      return "bg-green-600";
    }

    return "bg-white bg-opacity-100 border border-grey-300";
  };

  const iconClass = () => {
    if (
      isOnSpacesRoot ||
      isOnSelectSpaceRoot ||
      isOnOrganisationRoot ||
      isOnMeRoot ||
      isOnKnowledgeRoot
    ) {
      return "text-white";
    }

    return "text-black";
  };

  return (
    <button
      className={twMerge(
        "flex items-center justify-center h-8 w-8 lg:h-12 lg:w-12 rounded-full bg-opacity-50",
        backgroundClass(),
      )}
      data-testid="notification-bell"
      onClick={onClick}
    >
      <div data-testid="notification-icon">
        <NotificationBellIcon className={twMerge("w-4 h-4 lg:w-6 lg:h-6", iconClass())} />
      </div>
      {notificationCount > 0 && (
        <div
          data-testid="notification-count"
          className="absolute top-0 left-6 lg:left-8 flex items-center justify-center border border-red-600 w-4 h-4 bg-red-600 rounded-full"
        >
          <Text level={3} className="text-white">
            {notificationCount > 99 ? "99+" : notificationCount}
          </Text>
        </div>
      )}
    </button>
  );
};

export default NotificationBell;
