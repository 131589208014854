import { QueryEnvironment, useEolasQuery } from "Hooks";
import { myFilesService } from "../client/myFiles.client";
import { MyFile } from "../types";
import myFilesKeys from "./myFiles.queryKeys";
import { useMemo } from "react";
import { DEFAULT_SECTION_ID } from "../constants";

interface useFileListProps {
  filesList: MyFile[];
  filesListLoading: boolean;
}

export const useAllFiles = (userId: string, sectionId?: string): useFileListProps => {
  const { isLoading, data } = useEolasQuery({
    queryFn: async () => myFilesService.getAllFiles(userId),
    queryKey: myFilesKeys.allFilesList(),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  });

  const filesList = useMemo(
    () =>
      data
        ? data.filter((file) =>
            sectionId === DEFAULT_SECTION_ID
              ? !file.sectionId
              : !sectionId || file.sectionId === sectionId,
          )
        : [],
    [sectionId, data],
  );

  return { filesListLoading: isLoading, filesList };
};

export default useAllFiles;
