import { getObjectEntries } from "@eolas-medical/core";

type Params<T extends string> =
  | { type?: "GET"; queryParams?: { [queryName in T]: string }; baseUrl: string }
  | { type?: "POST"; queryParams?: { [queryName in T]: string }; body: object; baseUrl: string };

export const sendZapierRequest = async <T extends string>(
  params: Params<T>,
): Promise<"SUCCESS"> => {
  const controller = new AbortController();
  const timeoutID = setTimeout(() => {
    controller.abort();
  }, 10000);

  const requestOptions: RequestInit = {
    method: params.type || "GET",
    signal: controller.signal,
  };

  if (params.type === "POST") {
    // breaks if we stipulate Content-Type header so this is omitted
    requestOptions.body = JSON.stringify(params.body);
  }

  const url = new URL(params.baseUrl);
  if (params.queryParams) {
    for (const [key, value] of getObjectEntries(params.queryParams)) {
      if (value) {
        url.searchParams.append(key, value);
      }
    }
  }

  const response = await fetch(url.toString(), requestOptions);
  if (!response.ok) {
    throw new Error(`HTTP error, status: ${response.status}`);
  }

  clearTimeout(timeoutID);
  return "SUCCESS";
};
