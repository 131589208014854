import { useMemo } from "react";
import { EolasFile, searchClient, sectionStore } from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useQuery } from "@tanstack/react-query";
import { KnowledgeResult } from "../types";
import { mapKnowledgeFiles } from "../helpers";

const useKnowledgeSearch = ({
  searchValue,
  knowledgeSearchDisabled,
}: {
  searchValue: string;
  knowledgeSearchDisabled?: boolean;
}) => {
  const { supportedEolasRegion } = useSupportedRegion();

  const queryFn = (searchInput: string): Promise<EolasFile[]> => {
    return searchClient.searchNationalResources({
      query: searchInput,
      mode: "searchbyInput",
      region: supportedEolasRegion,
      organisationId: sectionStore.organisationID,
    });
  };

  const { data: files = [], isFetching } = useQuery(
    ["knowledgeSearch", { searchValue }],
    () => queryFn(searchValue),
    {
      staleTime: 60000,
      enabled: !knowledgeSearchDisabled && searchValue.length >= 2,
    },
  );

  const knowledgeFiles: KnowledgeResult[] = useMemo(() => {
    return mapKnowledgeFiles(files);
  }, [files]);

  return { knowledgeFiles, isFetching };
};

export default useKnowledgeSearch;
