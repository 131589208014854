import { FileTypeItemIcon, IconButton } from "UIKit";
import React from "react";
import { format } from "date-fns";
import { AudienceIcon, DeleteIcon, PiechartIcon } from "Assets";
import { DownloadFileButton } from "Components";
import { motion } from "framer-motion";
import { EolasFile, SupportedFileType } from "@eolas-medical/core";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts/LaunchDarklyContext";

type AdminNewsFeedTileProps = {
  newsFeed: EolasFile;
  onClick: (newsFeed: EolasFile) => void;
  onDelete: (newsFeed: EolasFile) => void;
  onViewChart: (newsFeed: EolasFile) => void;
  onViewAudience: (newsFeed: EolasFile) => void;
};

const AdminNewsFeedTile = ({
  newsFeed,
  onClick,
  onDelete,
  onViewChart,
  onViewAudience,
}: AdminNewsFeedTileProps) => {
  const { flags } = useLaunchDarkly();
  const canShowAnalytics = flags[LDFlagNames.ANALYTICS_NEWSFEED];

  return (
    <div className="pb-4">
      <motion.div
        className="p-2 grid grid-cols-12 transition-all bg-white rounded-lg shadow-sm cursor-pointer"
        onClick={() => onClick(newsFeed)}
      >
        <div
          className="row-start-1 col-start-1 col-end-4 lg:col-end-3 flex bg-blue-50 relative"
          style={{ borderRadius: "1rem" }}
        >
          <div className="flex items-center justify-center w-full h-full p-4">
            <FileTypeItemIcon type={newsFeed.type as SupportedFileType} className="w-12 h-12" />
          </div>
        </div>
        <div className="row-start-1 col-start-4 lg:col-start-3 col-end-10 flex flex-col justify-between p-5">
          <div>
            <span className="font-semibold text-lg line-clamp-1 leading-10">{newsFeed?.name}</span>
            {newsFeed.info ? (
              <span className="text-sm font-bold text-grey-dark line-clamp-2">{newsFeed.info}</span>
            ) : null}
          </div>
          <div className="flex flex-col">
            {newsFeed.createdAt ? (
              <span className="text-sm text-grey-dark">
                {format(new Date(newsFeed.createdAt as string), "do MMMM yyyy, HH:mm")}
              </span>
            ) : null}
          </div>
        </div>
        <div className="ml-0 sm:ml-2 md:ml-6 col-start-12 xl:col-start-11 mt-3">
          <DownloadFileButton eolasFile={newsFeed} />
        </div>
        <div className="row-start-1 col-start-13 flex flex-col justify-between p-3">
          <div className="flex flex-col justify-center items-center space-y-1.5">
            <IconButton
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(newsFeed);
              }}
              icon={<DeleteIcon />}
              data-testid="delete-icon"
            />
            {canShowAnalytics ? (
              <IconButton
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onViewAudience(newsFeed);
                }}
                icon={<AudienceIcon />}
                data-testid="audience-icon"
              />
            ) : null}
            <IconButton
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onViewChart(newsFeed);
              }}
              icon={<PiechartIcon />}
              data-testid="piechart-icon"
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AdminNewsFeedTile;
