import { EolasMainSection, sectionStore } from "@eolas-medical/core";
import { SubSectionRouteParams } from "Utilities/types";
import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { SpaceSubSection } from "../../../Pages/Spaces/types";

interface UseSpaceSubSection {
  spaceSubSection: SpaceSubSection;
}

const useSpaceSubSection = (sectionId: EolasMainSection): UseSpaceSubSection => {
  const {
    params: { sectionID: encodedSectionID },
  } = useRouteMatch<SubSectionRouteParams>();
  const sectionID = decodeURIComponent(encodedSectionID);

  const subSection = useMemo(() => {
    const { id } = sectionStore.getChildReferenceByMainSectionType(sectionId)!;
    const section = sectionStore.sectionsMap[id].childrenOrder!.find(
      (child) => child?.id === sectionID,
    )!;

    return { sectionName: section?.name };
  }, [sectionID, sectionId]);

  return {
    spaceSubSection: {
      subSectionId: sectionID,
      subSectionName: subSection.sectionName || "",
      encodedSubSectionId: encodedSectionID,
    },
  };
};

export default useSpaceSubSection;
