import { authenticationClient, eolasLogger } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { useCheckAuthFlowBehaviour } from "Pages/Authentication/hooks/useCheckAuthFlowBehaviour";

const useValidateUserEmail = (type: "sign-in" | "sign-up" | "confirm-email") => {
  const behaviour = useCheckAuthFlowBehaviour();
  const { mutate, isLoading, error, isSuccess, reset } = useMutation(
    async (email: string) => {
      if (behaviour === "email-based") {
        switch (type) {
          case "sign-in":
            return { exists: true, userConfirmed: true };
          case "sign-up":
            return { exists: false, userConfirmed: false };
          case "confirm-email":
            return { exists: true, userConfirmed: false };
        }
      }
      const { exists, emailVerified } = await authenticationClient.verifyEmail(email);
      return { exists, userConfirmed: emailVerified };
    },
    {
      onError: (error: string) => {
        eolasLogger.error(error);
      },
    },
  );

  return {
    validateUserEmail: mutate,
    validatingUserEmail: isLoading,
    validateUserEmailError: error || undefined,
    validateUserEmailSuccess: isSuccess,
    resetValidateUserEmail: reset,
  };
};

export default useValidateUserEmail;
