/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from "react";

import {
  sortByCreatedAt,
  sortByFavouritedOn,
  sortByFavourites,
  sortByName,
  sortByUpdated,
} from "Utilities/helpers";
import { ListOrderBy, ListOrderByOption } from "Components/EolasFileList/ListOrderBy";
import { isSortMethod, SortMethods } from "UIKit/EolasVersatileList/EolasVersatileList.types";

export interface UserSortOrderParams {
  initialOrder: ListOrderByOption;
  isDraggable?: boolean;
  sortDateBy?: "createdAt" | "updatedAt" | "favouritedOn";
  favourites?: boolean;
  sortFn?: (a: any, b: any) => number;
  onSortMethodChange?: (sortMethod: SortMethods) => void;
}

export interface UseSortReturnParams {
  orderBy: ListOrderByOption;
  setOrder: (order: ListOrderByOption) => void;
  orderOptions: ListOrderByOption[];
  sortMethod?: (a: any, b: any) => number;
}

export type HookResponse = [JSX.Element, UseSortReturnParams];

export function useSortOrder({
  initialOrder,
  isDraggable,
  sortDateBy,
  favourites,
  sortFn,
  onSortMethodChange,
}: UserSortOrderParams): HookResponse {
  const [orderBy, setOrder] = useState<ListOrderByOption>(initialOrder);

  const dateSortFn = useMemo(() => {
    if (sortDateBy === "createdAt") {
      return sortByCreatedAt;
    }
    if (sortDateBy === "updatedAt") {
      return sortByUpdated;
    }
    if (sortDateBy === "favouritedOn") {
      return sortByFavouritedOn;
    }
  }, [sortDateBy]);

  const orderOptions = useMemo(() => {
    let options: ListOrderByOption[] = ["alphabetical", "date"];
    if (isDraggable) {
      options = ["dragAndDrop", ...options];
    }
    if (!options.includes(initialOrder)) {
      options = [initialOrder, ...options];
    }
    if (favourites) {
      options = [...options, "favourites"];
    }
    return options;
  }, [isDraggable, initialOrder, favourites]);

  const sortMethod = useMemo(() => {
    switch (orderBy) {
      case "date":
        return dateSortFn;
      case "alphabetical":
        return sortByName;
      case "recent":
        return sortByUpdated;
      case "favourites":
        return sortByFavourites;
      default:
        return sortFn;
    }
  }, [dateSortFn, orderBy, sortFn]);

  const onClickSortMethod = useCallback(
    (newOrder: ListOrderByOption) => {
      if (onSortMethodChange && isSortMethod(newOrder)) {
        onSortMethodChange(newOrder);
      }
      setOrder(newOrder);
    },
    [onSortMethodChange, setOrder],
  );

  const OrderComponent = useMemo(
    () => (
      <ListOrderBy
        selectedOption={orderBy}
        handleClick={onClickSortMethod}
        options={orderOptions}
      />
    ),
    [orderBy, orderOptions, onClickSortMethod],
  );

  return [OrderComponent, { orderBy, setOrder, orderOptions, sortMethod }];
}
