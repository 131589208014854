import { isEqual } from "lodash";
import { StepNames, WizardStateWithoutStep } from "../types";
import { makeInitialWizardState } from "./makeInitialWizardState";
import {
  EolasEditorMetadata,
  EolasSupportedMiscellaneousType,
  parseDsmMetadata,
  parseEolasEditorFileMetadata,
} from "@eolas-medical/core";
import { toAwsJSON } from "Utilities";
import { extractEditorContent } from "../components/EolasEditorComponents/EolasEditorCreator/functions/extractEditorContent";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

// Function to get final step name (or might be penultimate step name)
export const getFinalStepName = async (
  state: WizardStateWithoutStep,
): Promise<StepNames | null> => {
  if (state.fileId === null) {
    return "confirmAndUpload";
  }
  if (state.blob || state.isExistingFileWithNewType) {
    return "summaryOfChanges";
  }

  const file = await contentDbStore.getItem(state.fileId);

  if (!file) {
    return null;
  }

  const startingState = makeInitialWizardState({
    file,
    sectionId: file.parentID,
    draftFile: state.fileId && state.draftFile ? state.draftFile : null,
  });

  if (!startingState || startingState.isExistingFileWithNewType) {
    return null;
  }

  if (state.type === EolasSupportedMiscellaneousType.EOLAS_EDITOR && startingState.dto.metadata) {
    // The data is compressed, so needs changing for comparison:
    const parsed = parseEolasEditorFileMetadata(startingState.dto.metadata);
    if (parsed) {
      const editorMetadata: EolasEditorMetadata = {
        ...parsed,
        eolasEditorContent: extractEditorContent(startingState.dto.metadata),
      };
      startingState.dto.metadata = toAwsJSON(editorMetadata);
    }
  }

  if (state.type === EolasSupportedMiscellaneousType.DSM && startingState.dto.metadata) {
    // The data is compressed, so needs changing for comparison:
    const parsed = parseDsmMetadata(startingState.dto.metadata);
    if (parsed) {
      startingState.dto.metadata = toAwsJSON(parsed);
    }
  }

  if (isEqual(startingState.dto, state.dto)) {
    if (!state.existingDraftStatus && !state.nextDraftStatus) {
      return null;
    }
    return "confirmAndUpload";
  }
  return "summaryOfChanges";
};
