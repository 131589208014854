import {
  EolasFile,
  EolasShadowCopyContent,
  EolasShadowCopyFile,
  eolasLogger,
} from "@eolas-medical/core";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { getUrlObject } from "Utilities/helpers";

export const getOriginalFromShadowCopy = async (
  item: EolasShadowCopyContent | EolasShadowCopyFile,
  shouldLogError = true,
): Promise<EolasFile | null> => {
  const deeplinkUrl = getUrlObject(item.deepLinkUrl);
  if (!deeplinkUrl) {
    if (shouldLogError) {
      eolasLogger.error(
        new Error("convertShadowCopyFileToOriginal: no deeplink url found on key"),
        { item },
      );
    }
    return null;
  }
  const originalFileId = deeplinkUrl.searchParams.get("fileId");
  if (!originalFileId) {
    if (shouldLogError) {
      eolasLogger.error(new Error("convertShadowCopyFileToOriginal. Expected fileId searchParam"), {
        item,
      });
    }
    return null;
  }
  const originalFile = contentDbStore.getItem(originalFileId);
  if (!originalFile) {
    if (shouldLogError) {
      eolasLogger.error(new Error("convertShadowCopyFileToOriginal. No file found in fileStore"), {
        item,
      });
    }
    return null;
  }

  return originalFile;
};
