import React from "react";

export const Wrapper = <T extends { id: string }>({
  item: { id },
  children,
}: {
  item: T;
  children: React.ReactNode;
}) => {
  return (
    <div id={id} className="mb-4">
      {children}
    </div>
  );
};
