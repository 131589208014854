import {
  AnalyticsEvents,
  AppLevelSection,
  CompletedContentStatus,
  DraftFile,
  EolasFile,
  EolasMainSection,
  OrganisationLevelSection,
  isEolasFile,
  isFileCompleted,
  sectionStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useDownloadFile } from "Components/EolasFileList/hooks";
import { useFileViewer } from "Components/FileViewer/useFileViewer";
import { useCompleteFile, useRunOnMountUnmount } from "Hooks";
import { Button, Modal, NavButton } from "UIKit";
import { useTranslation } from "react-i18next";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { useConstructFileBreadcrumbProps } from "Pages/Spaces/hooks/useConstructFileBreadcrumbProps";
import { EolasDocumentViewer } from "UIKit/EolasDocumentViewer/EolasDocumentViewer";
import { useShareFile } from "shared/hooks/useShareFile";
import ShareFileModal from "./components/ShareFileModal";

interface DocumentViewerProps {
  file: EolasFile | DraftFile;
  backBehaviour?: "goBack" | "goHome";
  startingPage?: number;
  versionNo?: number;
  shadowCopyContentId?: string;
  originalFileId?: string | null;
  shouldShowBreadcrumbs?: boolean;
}

const fileTrackingSections = [
  AppLevelSection.educationPortal,
  AppLevelSection.newsFeed,
  OrganisationLevelSection.learningManagement,
  OrganisationLevelSection.communicationPortal,
] as EolasMainSection[];

const DocumentViewer = ({
  file,
  backBehaviour = "goBack",
  startingPage,
  versionNo,
  shadowCopyContentId,
  originalFileId,
  shouldShowBreadcrumbs,
}: DocumentViewerProps) => {
  const { t } = useTranslation();
  const { saveFile } = useDownloadFile(file);
  const { onCompleteFile } = useCompleteFile();
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });

  const mainSection = file.mainSectionID
    ? sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID)
    : null;

  const mainSectionIdentity = file.mainSectionID
    ? sectionStore.getMainSectionIdentityByMainSectionId(file.mainSectionID)
    : null;

  const { eolasDocumentViewerProps, onClickBack, webViewerRef } = useFileViewer({
    file,
    startingPage,
    versionNo,
    handleGoBack,
  });

  const { isShareFileEnabled, isShareModalOpen, handleOnShareFile, shareModal } = useShareFile({
    Modal: ShareFileModal,
  });

  useRunOnMountUnmount({
    onMount: () => {
      // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
      const idForTracking = mainSection ?? mainSectionIdentity;
      if (idForTracking) {
        trackEvent(AnalyticsEvents.OPEN_FILE, {
          mainSectionId: idForTracking,
          fileId: file.id,
          fileType: file.type || "",
          fileName: file.name,
        });
      }

      if (mainSection && !fileTrackingSections.includes(mainSection)) {
        return;
      }

      const isAdmin = sectionStore.isAdmin;
      if (isAdmin) return;

      const isCompleted = isEolasFile(document) ? isFileCompleted(document) : false;
      if (isCompleted) return;

      onCompleteFile(decodeURIComponent(file.id), CompletedContentStatus.SEEN);
    },
  });

  const { mainSectionId, parentId, fileName } = useConstructFileBreadcrumbProps({
    id: shadowCopyContentId ? shadowCopyContentId : originalFileId || file.id,
  });

  return (
    <>
      <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={handleGoBack}>
        <div className="h-100vh w-100vw">
          <div className="bg-grey-light py-1 px-2 flex flex-col justify-center gap-2">
            {shouldShowBreadcrumbs ? (
              <FileBreadcrumbs
                shouldShowSpaceName={true}
                mainSectionId={mainSectionId}
                parentId={parentId}
                fileName={fileName}
              />
            ) : null}
            <div className="flex justify-center items-center space-x-3">
              <NavButton onClick={onClickBack} />
              <Button size="sm" onClick={saveFile}>
                {t("general_download_original")}
              </Button>
              {isShareFileEnabled ? (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    if (!webViewerRef.current) {
                      return;
                    }
                    handleOnShareFile(file, {
                      totalPages: webViewerRef.current?.Core.documentViewer.getPageCount(),
                      pageNumber: webViewerRef.current?.Core.documentViewer.getCurrentPage(),
                    });
                  }}
                >
                  {t("repository_share_file")}
                </Button>
              ) : null}
            </div>
          </div>
          <div className="bg-grey-light" style={{ height: "94%" }}>
            <EolasDocumentViewer {...eolasDocumentViewerProps} />
          </div>
        </div>
        {isShareModalOpen ? shareModal : null}
      </Modal>
    </>
  );
};

export default DocumentViewer;
