import { useMediaQuery } from "Hooks";
import { useTranslation } from "react-i18next";
import { Button, Loader, Text } from "UIKit";
import { Space } from "@eolas-medical/core";

interface SpaceDetailsProps {
  onClickViewSpace?: () => void;
  onClickEditSpace: () => void;
  onClickShareSpace?: () => void;
  isAdminUser: boolean;
  isLoading?: boolean;
  isPublic: boolean;
  selectedSpace: Space;
  isOrgAdminSpace?: boolean;
}

const SpaceDetailsMobile = ({
  onClickViewSpace,
  onClickEditSpace,
  onClickShareSpace,
  isAdminUser,
  isPublic,
  isOrgAdminSpace = false,
}: SpaceDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center space-x-4">
      <Button
        color="white"
        iconLeft="InformationIcon"
        className="flex items-center justify-center h-12 w-40 rounded-3xl hover:bg-grey-50"
        onClick={isAdminUser ? onClickEditSpace : onClickViewSpace}
      >
        <Text level={2} className="text-black font-bold ml-2">
          {t(isOrgAdminSpace ? "space_details_organisation_info" : "space_details_space_info")}
        </Text>
      </Button>
      {isPublic ? (
        <Button
          color="white"
          iconLeft="ShareIcon"
          className="flex items-center justify-center h-12 rounded-3xl hover:bg-grey-50"
        >
          <Text level={2} className="text-black font-bold ml-2" onClick={onClickShareSpace}>
            {t(isOrgAdminSpace ? "space_details_share_organisation" : "space_details_share_space")}
          </Text>
        </Button>
      ) : null}
    </div>
  );
};

const SpaceDetailsDesktop = ({
  onClickEditSpace,
  onClickShareSpace,
  isAdminUser,
  isLoading = false,
  isPublic,
  selectedSpace,
  isOrgAdminSpace = false,
}: SpaceDetailsProps) => {
  const media = useMediaQuery();
  const { t } = useTranslation();
  const isSmallDevice = media === "sm";

  const imageUrl = selectedSpace.image?.url;
  const usersCount = selectedSpace.userCount || 1;
  return (
    <div
      className={`max-w-5xl w-full ${
        isOrgAdminSpace ? "bg-orange-400" : "bg-blue-400"
      } shadow-xl relative h-72 bg-cover bg-no-repeat border-4 ${
        isOrgAdminSpace ? "border-orange-300" : "border-blue-300"
      }`}
      style={{ borderRadius: "3.3rem" }}
    >
      {imageUrl ? (
        <img
          data-testid="space-image"
          className="w-full h-full object-cover rounded-3xl"
          srcSet={imageUrl}
          src={imageUrl}
          alt=""
        />
      ) : null}
      <div className="absolute bottom-0 w-full h-full bg-black opacity-30 rounded-3xl" />

      <div className="absolute inset-0 flex flex-col justify-end items-center z-10  p-6">
        {isLoading ? (
          <Loader className="bg-transparent" />
        ) : (
          <div className="flex flex-col w-full">
            <div className="text-4xl text-white font-extrabold md:text-4xl line-clamp-1">
              {selectedSpace.name || ""}
            </div>
            <div className="text-base font-bold text-left text-white">
              {usersCount}
              {usersCount > 1 ? t("space_details_members") : t("space_details_member")}
            </div>
            <div className="flex justify-between items-center h-12 gap-4">
              <div className="font-bold text-xl text-white text-left line-clamp-2 mt-4">
                {selectedSpace.description || ""}
              </div>
              <div className="flex gap-x-4 mt-4">
                {isPublic ? (
                  <Button
                    iconLeft={isSmallDevice ? null : "ShareIcon"}
                    variant="solid"
                    color="black"
                    size="sm"
                    className="h-10 rounded-3xl border-0 bg-opacity-60 hover:bg-opacity-50"
                    onClick={onClickShareSpace}
                  >
                    <Text level={2} className="ml-2 font-bold text-white">
                      {t(
                        isOrgAdminSpace
                          ? "space_details_share_organisation"
                          : "space_details_share_space",
                      )}
                    </Text>
                  </Button>
                ) : null}
                {isAdminUser ? (
                  <Button
                    iconLeft={isSmallDevice ? null : "EditPencilIcon"}
                    color="black"
                    size="sm"
                    className="rounded-3xl border-0 bg-opacity-60 hover:bg-opacity-50"
                    onClick={onClickEditSpace}
                  >
                    <Text level={2} className="ml-2 font-bold text-white">
                      {t(
                        isOrgAdminSpace
                          ? "space_details_edit_organisation"
                          : "space_details_edit_space",
                      )}
                    </Text>
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SpaceDetails = (props: SpaceDetailsProps) => {
  const media = useMediaQuery();
  const isMobile = media === "xs";

  return isMobile ? <SpaceDetailsMobile {...props} /> : <SpaceDetailsDesktop {...props} />;
};

export default SpaceDetails;
