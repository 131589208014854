import { useTranslation } from "react-i18next";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { useIsScrollable } from "Hooks";
import {
  Input,
  Button,
  Title,
  Textarea,
  ModalBody,
  FormError,
  FormElement,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useDeleteDepartmentArea } from "../hooks";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";

interface AddDepartmentAreaModalProps {
  departmentArea?: EolasFile;
  onCloseModal(): void;
}

export const AddDepartmentAreaModal: React.FC<AddDepartmentAreaModalProps> = ({
  onCloseModal,
  departmentArea,
}: AddDepartmentAreaModalProps) => {
  const { t } = useTranslation();

  const { onDelete, deleteError, isDeleteLoading, isDeleteSuccessful } =
    useDeleteDepartmentArea(departmentArea);

  const { error, control, onSubmit, isLoading, isSuccessful } = useAddEolasFile({
    eolasFile: departmentArea,
    mainSectionID: AppLevelSection.theDepartment,
  });

  const { ref, ScrollHint } = useIsScrollable();

  if (isDeleteSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text="theDepartment_delete_file_success" />;
  }

  if (isSuccessful) {
    return (
      <SuccessModal
        onComplete={onCloseModal}
        text={
          departmentArea ? "theDepartment_edit_file_success" : "theDepartment_add_new_file_success"
        }
      />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>
          {t(
            departmentArea
              ? "theDepartment_edit_area_modal_title"
              : "theDepartment_add_new_area_modal_title",
          )}
        </Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form className="add-file-form" onSubmit={onSubmit}>
          <div className="add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              required
              id="name"
              control={control}
              labelClassName="font-semibold"
              label={t("theDepartment_area_name_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  disabled={isLoading}
                  placeholder={t("theDepartment_area_name_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              control={control}
              id="description"
              labelClassName="font-semibold"
              label={t("theDepartment_area_description_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  disabled={isLoading}
                  placeholder={t("theDepartment_area_description_placeholder")}
                />
              )}
            </FormElement>
          </div>

          {!departmentArea ? (
            <>
              <Button
                size="lg"
                type="submit"
                isLoading={isLoading}
                style={{ minHeight: "3rem" }}
                className="add-file__submit-button"
              >
                {t("theDepartment_add_area_button")}
              </Button>
            </>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Button color="blue" variant="outline" isLoading={isLoading} type="submit">
                {t("theDepartment_edit_area_button")}
              </Button>
              <Button
                color="red"
                type="button"
                weight="regular"
                onClick={onDelete}
                isLoading={isDeleteLoading}
              >
                {t("theDepartment_delete_area_button")}
              </Button>
            </div>
          )}

          <FormError error={error || deleteError} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
